import React, { Component } from 'react';
import "./KeywordDashboard.css"
import KeywordOrganizationSelect from '../../../../components/KeywordOrganizationSelect/KeywordOrganizationSelect';

class KeywordDashboard extends Component {

    render() {
        return (
            <div className="KeywordDashboard">
                <h2>Keyword Dashboard</h2>
                <h3>Probably some cool charts, graphs and analytics here </h3>
                <KeywordOrganizationSelect {...this.props} placeholder="Select Organization" />
            </div>
        );
    }
}

export default KeywordDashboard;