import React, { Component, createRef } from "react";
import "./DayWeekPickerWidget.css";
import { Form, Popup, Icon, Header } from "semantic-ui-react";

export default class DayWeekPickerWidget extends Component {
  state = {
    popupOpen: false,
    weeks: "",
    days: "",
    totalDays: null
  };
  contextRef = createRef();

  componentDidMount() {
    if (this.props.currentValue) {
      let remainder = this.props.currentValue % 7;
      if (this.props.currentValue < 7) {
        this.setState({ days: this.props.currentValue });
      } else if (remainder) {
        let days = remainder;
        let weeks = (this.props.currentValue - remainder) / 7;
        this.setState({
          weeks: weeks,
          days: days
        });
      } else {
        this.setState({ weeks: (this.props.currentValue / 7) });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.weeks !== this.state.weeks) {
      this.setState({ totalDays: this.state.days + this.state.weeks * 7 });
    }
    if (prevState.days !== this.state.days) {
      this.setState({ totalDays: this.state.weeks * 7 + this.state.days });
    }
  }

  populateWeeks = numberOfWeeks => {
    let weeks = [];
    for (let i = 1; i < numberOfWeeks + 1; i++) {
      weeks.push({
        key: i,
        text: `Week ${i}`,
        value: i
      });
    }
    return weeks;
  };

  populateDays = numberOfDays => {
    let days = [];
    for (let i = 1; i < numberOfDays + 1; i++) {
      days.push({
        key: i,
        text: `Day ${i}`,
        value: i
      });
    }
    return days;
  };

  handlePopupOpen = () => {
    this.setState({ popupOpen: true });
  };

  handlePopupClose = () => {
    this.setState({ popupOpen: false });
  };

  handleSelectChange = (event, { name, value }) => {
    this.setState({
      [name]: value
    });
  };

  handleSubmit = async e => {
    e.preventDefault();
      this.handlePopupClose();
      return this.props.handleSubmit(parseInt(this.state.totalDays, 10) === 0 ? null : this.state.totalDays);
  };

  render() {
    return (
      <div ref={this.contextRef} className={`DayWeekPickerWidget ${this.props.currentValue ? "hasValue" : "noValue"}`}>
        <Popup
          on="click"
          flowing
          open={this.state.popupOpen}
          onOpen={this.handlePopupOpen}
          onClose={this.handlePopupClose}
          position="bottom right"
          context={this.contextRef}
          trigger={
            this.props.currentValue ? (
              <div className="DayWeekPickerWidget--value">
              <Icon name="calendar alternate" />
              {this.state.weeks && <div className="DayWeekPickerWidget--value__week">{`Week ${this.state.weeks}`}</div>}
              {this.state.days && <div className="DayWeekPickerWidget--value__day">{`Day ${this.state.days}`}</div>}
              </div>
            ) : (
              <div className="calendarCircle">
                <Icon name="calendar alternate" />
              </div>
            )
          }
        >
          <Form
            onSubmit={this.handleSubmit}
            className="DayWeekPickerWidget--form"
            size="tiny"
          >
            <Header as="h4">Schedule Task</Header>
            <Form.Group>
              <Form.Select
                name="weeks"
                clearable
                placeholder="Week Due"
                value={this.state.weeks}
                onChange={this.handleSelectChange}
                options={this.populateWeeks(8)}
              />
              <Form.Select
                name="days"
                clearable
                placeholder="Day Due"
                value={this.state.days}
                onChange={this.handleSelectChange}
                options={this.populateDays(5)}
              />
              <Form.Button
                size="tiny"
                type="submit"
                basic
              >
                Save
              </Form.Button>
            </Form.Group>
          </Form>
        </Popup>
      </div>
    );
  }
}
