import React, { Component } from 'react';
import './PresentationEntries.css';
import { Table } from 'semantic-ui-react';

export default class PresentationEntries extends Component {
	checkAllowedTypes = (key, data) => {
		if (key === 'id') {
			return false;
		}
		const type = typeof data;
		switch (type) {
			case 'function':
				return false;
			case 'object':
				return false;
			case 'array':
				return false;
			default:
				return true;
		}
	};
	render() {
		const EntriesComponent = this.props.entriesComponent ? this.props.entriesComponent : null;
		const entries = this.props.entries;
		return (
			<div className="PresentationEntries">
				{entries && entries[0] ? (
					<div className="PresentationEntries--content">
						{EntriesComponent ? (
							<EntriesComponent {...this.props} entries={entries} />
						) : (
							<div className="PresentationEntries--table">
								<Table>
									<Table.Header>
										<Table.Row>
											{Object.entries(entries[0])
												.filter(([ k, v ]) => this.checkAllowedTypes(k, v))
												.map(([ k, v ]) => <Table.HeaderCell key={k}>{k}</Table.HeaderCell>)}
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{entries.map((entry) => (
											<Table.Row key={entry.id}>
												{Object.entries(entry)
													.filter(([ k, v ]) => this.checkAllowedTypes(k, v))
													.map(([ k, v ]) => (
														<Table.Cell key={`${entry.id}-${k}`}>
															{typeof v === 'string' && v.startsWith('http') ? (
																<a href={v} target="_blank" rel="noopener noreferrer">
																	Link
																</a>
															) : (
																v
															)}
														</Table.Cell>
													))}
											</Table.Row>
										))}
									</Table.Body>
								</Table>
							</div>
						)}
					</div>
				) : (
					<div className="PresentationEntries--noData">
						<p>There are currently no entries available.</p>
					</div>
				)}
			</div>
		);
	}
}
