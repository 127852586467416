import React, { Component } from "react";
import "./PostingOverviewTableRow.css";
import { Placeholder, Table } from "semantic-ui-react";
import { parseFirstNameLastInitial } from "../../../../../utility";

class PostingOverviewTableRow extends Component {
  state = {};

  componentDidMount() {
    this.props.getOverdueContentApprovals(this.props.posts.account.id);
  }

  render() {
    const {
      posts,
      selectedAccountPosts,
      handleRowSelect,
      getUser,
      renderStatusClass,
      getCorrespondingAccountData,
    } = this.props;
    const correspondingAccountData = getCorrespondingAccountData(
      this.props.posts.account.id
    );
    return (
      <Table.Row
        className={
          selectedAccountPosts
            ? selectedAccountPosts.account.id === posts.account.id
              ? "selected"
              : "notSelected"
            : "notSelected"
        }
        onClick={(e) => handleRowSelect(e, posts)}
      >
        <Table.Cell className="PostingOverviewTable--account">
          {posts.account.dba_name}
        </Table.Cell>
        <Table.Cell className="PostingOverviewTable--pm">
          {getUser(posts.account.project_manager) &&
            parseFirstNameLastInitial(
              getUser(posts.account.project_manager).name
            )}
        </Table.Cell>
        <Table.Cell className="PostingOverviewTable--lead">
          {getUser(posts.account.account_lead) &&
            parseFirstNameLastInitial(getUser(posts.account.account_lead).name)}
        </Table.Cell>
        <Table.Cell
          textAlign="center"
          className={`PostingOverviewTable--finalReview`}
        >
          {correspondingAccountData &&
          correspondingAccountData.isFetchingOverdueContentApprovals &&
          !correspondingAccountData.didFetchOverdueContentApprovals ? (
            <Placeholder>
              <Placeholder.Paragraph>
                <Placeholder.Line />
              </Placeholder.Paragraph>
            </Placeholder>
          ) : correspondingAccountData.overdueContentApprovals ? (
            correspondingAccountData.overdueContentApprovals.length
          ) : (
            "0"
          )}
        </Table.Cell>
        <Table.Cell
          textAlign="center"
          className={`PostingOverviewTable--fourWeeksBack ${renderStatusClass(
            posts.scheduled.length,
            posts.fourWeeksBack.length >= 4 ? 0 : 1
          )}`}
        >
          {posts.scheduled.length}
        </Table.Cell>
        <Table.Cell
          textAlign="center"
          className={`PostingOverviewTable--fourWeeksBack ${renderStatusClass(
            posts.fourWeeksBack.length,
            4
          )}`}
        >
          {posts.fourWeeksBack.length}
        </Table.Cell>
        <Table.Cell
          textAlign="center"
          className={`PostingOverviewTable--twelveWeeksBack ${renderStatusClass(
            posts.twelveWeeksBack.length,
            12
          )}`}
        >
          {posts.twelveWeeksBack.length}
        </Table.Cell>
      </Table.Row>
    );
  }
}

export default PostingOverviewTableRow;
