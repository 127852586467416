import React from 'react';
import './ClientReportingTable.css';
import { Table, Button, Icon, Header } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

const ClientReportingTable = (props) => {
	const reports = props.reports;
	return (
		<div className="ClientReportingTable">
			{props.hasManyOrgs && (
				<Header
					as="h4"
					className="ClientReportingTable--orgHeader"
					content={props.getOrganization(props.organizationId)}
				/>
			)}
			<Table basic size="small" compact>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell width={12}>Month</Table.HeaderCell>
						<Table.HeaderCell width={4}>Report Link</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{reports.map((report) => (
						<Table.Row key={report.id}>
							<Table.Cell>
								<span className="ClientReportingTable--monthOutput">
									{props.getMonth(report.month, true)}
								</span>
							</Table.Cell>
							<Table.Cell>
								<div className="ClientReportingTable--reportLinks">
									{report.presentation && (
										<NavLink to={props.renderReportUrl(report)}>
											<Button icon size="mini" color="blue">
												<span>View Report</span>
												<Icon name="eye" />
											</Button>
										</NavLink>
									)}
									{report.report_url && (
										<a href={report.report_url} target="_blank" rel="noopener noreferrer">
											<Button color="blue" basic icon size="mini">
												<span>View Google Slide Presentation</span>
												<Icon name="google drive" />
											</Button>
										</a>
									)}
								</div>
							</Table.Cell>
						</Table.Row>
					))}
				</Table.Body>
			</Table>
		</div>
	);
};

export default ClientReportingTable;
