import React, { Component } from "react";
import "./Assignments.css";
import { contentGeneration, organizations, ui } from "../../../store/actions";
import { connect } from "react-redux";
import { Grid, Header, Dropdown, Button } from "semantic-ui-react";
import AssignmentList from "../../../components/AssignmentList/AssignmentList";
import FixedLoader from "../../../components/FixedLoader/FixedLoader";
import ArticleDetail from "../../../components/ArticleDetail/ArticleDetail";
import { Switch, Route, Link } from "react-router-dom";
import { hasPermissions, parseSearchParams } from "../../../utility";
import { matchPath } from "react-router";
import NotFound from "../../../components/NotFound/NotFound";
import AssignmentListTable from "../../../components/AssignmentListTable/AssignmentListTable";
import moment from "moment";
/*eslint eqeqeq: "off"*/

class Assignments extends Component {
  state = {
    selectedAssignment: null,
    viewingAs: null,
    isLead: false,
    menuItems: [
      {
        id: 1,
        path: `${this.props.match.path}/lead`,
        exact: true,
        menuLabel: "Managing",
        navbarName: "Managing",
        icon: null,
        component: null,
        protected: true,
        permission: hasPermissions(this.props.user, "edit_contentarticle"),
      },
    ],
  };

  componentDidMount() {
    this.props.setMenuItems(
      this.state.menuItems,
      "content-generation/assignments"
    );
    const isLead = parseSearchParams(this.props.location.search, "lead");
    isLead ? this.setState({ isLead: true }) : this.setState({ isLead: false });
    !this.props.comments[0] && this.props.fetchContentComments();
    !this.props.editorialRequirements[0] &&
      this.props.fetchEditorialRequirements();
    !this.props.contentStatuses[0] && this.props.fetchContentStatuses();
    if (hasPermissions(this.props.user) && !this.props.writers[0]) {
      this.props.fetchWriters();
    }
    if (hasPermissions(this.props.user) && !this.props.contentStaff[0]) {
      this.props.fetchContentStaff();
    }
    this.fetchArticles();
  }

  componentDidUpdate(prevProps, prevState) {
    // if the article has changed set data
    if (this.props.contentArticles !== prevProps.contentArticles) {
      if (this.state.selectedAssignment) {
        let updatedArticle = this.props.contentArticles.find(
          (article) => article.id === this.state.selectedAssignment.id
        );
        this.setState({ selectedAssignment: updatedArticle });
      }
    }
    // handle filter change
    if (this.props.componentFilters !== prevProps.componentFilters) {
      if (this.props.componentFilters.component === AssignmentList) {
        this.setState({
          componentFilters: this.props.componentFilters.filters,
        });
      }
    }
    // handle user switching filters
    if (this.props.match.params !== prevProps.match.params) {
      let writer = this.props.match.params.writer;
      let staff = this.props.match.params.user;
      writer && this.switchUser(true, writer, writer);
      staff && this.switchUser(false, staff, staff);
    }
    if (this.props.location !== prevProps.location) {
      const isLead = parseSearchParams(this.props.location.search, "lead");
      isLead
        ? this.setState({ isLead: true })
        : this.setState({ isLead: false });
    }
    // handle view logic if admin
    if (this.getStaffParams() && !this.state.viewingAs) {
      this.switchUser(this.getStaffParams().params.staff);
    } else if (
      this.props.location.pathname !== prevProps.location.pathname ||
      this.props.location.search !== prevProps.location.search
    ) {
      this.fetchArticles();
    }
    // if url changes close the form
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.handleClose();
    }
  }

  fetchArticles = () => {
    if (this.getStaffParams()) {
      return this.switchUser(this.getStaffParams().params.staff);
    } else {
      const isLead = parseSearchParams(this.props.location.search, "lead");
      if (isLead) {
        this.props.fetchContentArticles(null, true);
      } else {
        this.props.fetchContentArticles();
      }
      return this.setState({ viewingAs: null });
    }
  };

  switchUser = (staff) => {
    let writer = this.props.writers.find(
      (writer) => writer.user.username === staff || writer.user.id === staff
    );
    let contentStaff = this.props.contentStaff.find(
      (staffMember) =>
        staffMember.username === staff || staffMember.id === staff
    );
    if (writer) {
      this.props.fetchContentArticles(writer.user.id);
      return this.setState({ viewingAs: writer.user });
    } else if (contentStaff) {
      this.props.fetchContentArticles(contentStaff.id);
      return this.setState({ viewingAs: contentStaff });
    }
  };

  handleAssignmentSelect = (assignment) => {
    this.setState({ selectedAssignment: assignment });
  };

  handleClose = () => {
    this.setState({ selectedAssignment: null });
  };

  getStaffParams = () => {
    return matchPath(this.props.location.pathname, {
      path: `/content-generation/assignments/:staff`,
      exact: true,
      strict: false,
    });
  };

  handleStaffChange = (event, { name, value }) => {
    value
      ? this.props.history.push(`${this.props.match.path}/${value}`)
      : this.props.history.push(`${this.props.match.path}`);
  };

  onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  handleFilterAssignedArticles = (articles, isLead) => {
    const user = this.state.viewingAs
      ? this.state.viewingAs.id
      : this.props.user.id;
    if (isLead) {
      return articles.filter(
        (article) => article.lead && article.lead.id === user
      );
    } else {
      return articles.filter((article) => {
        if (
          article.status.uid === "writing" &&
          article.writer &&
          article.writer.user
        ) {
          if (article.writer.user.id === user) {
            return true;
          }
        } else if (
          article.status.uid === "rewrite" &&
          article.writer &&
          article.writer.user
        ) {
          if (article.writer.user.id === user) {
            return true;
          }
        } else if (article.status.uid === "editing") {
          if (article.editor.id === user) {
            return true;
          }
        } else if (article.status.uid === "reedit") {
          if (article.editor.id === user) {
            return true;
          }
        } else if (article.status.uid === "final_review") {
          if (article.final_approver.id === user) {
            return true;
          }
        } else if (article.status.uid === "ready_to_post") {
          if (article.poster.id === user) {
            return true;
          }
        } else if (article.status.uid === "post_qa") {
          if (article.lead.id === user) {
            return true;
          }
        }
        return null;
      });
    }
  };

  // renderDueDate = (article, raw) => {
  //   let dueDate = null;
  //   if (article.status.order === 3 && article.duedate_write) {
  //     dueDate = moment(article.duedate_write);
  //   } else if (article.status.order === 4 && article.duedate_rewrite) {
  //     dueDate = moment(article.duedate_rewrite);
  //   } else if (article.status.order === 5 && article.duedate_edit) {
  //     dueDate = moment(article.duedate_edit);
  //   } else if (article.status.order === 6 && article.duedate_reedit) {
  //     dueDate = moment(article.duedate_reedit);
  //   } else if (article.status.order === 7 && article.duedate_schedulepost) {
  //     dueDate = moment(article.duedate_schedulepost);
  //   }
  //   if (dueDate) {
  //     return raw ? dueDate : dueDate.format("MMM Do");
  //   }
  // };

  renderDueDate = (article, raw) => {
    let dueDate = null;
    switch (article.status.uid) {
      case "writing":
        dueDate = article.duedate_write ? moment(article.duedate_write) : null;
        break;
      case "rewrite":
        dueDate = article.duedate_rewrite
          ? moment(article.duedate_rewrite)
          : null;
        break;
      case "editing":
        dueDate = article.duedate_edit ? moment(article.duedate_edit) : null;
        break;
      case "reedit":
        dueDate = article.duedate_reedit
          ? moment(article.duedate_reedit)
          : null;
        break;
      case "final_review":
        dueDate = article.duedate_finalreview
          ? moment(article.duedate_finalreview)
          : null;
        break;
      case "ready_to_post":
        dueDate = article.duedate_schedulepost
          ? moment(article.duedate_schedulepost)
          : null;
        break;
      case "post_qa":
        dueDate = article.duedate_qapost
          ? moment(article.duedate_qapost)
          : null;
        break;
      case "scheduled":
        dueDate = article.duedate_golive
          ? moment(article.duedate_golive)
          : null;
        break;
      case "posted":
        dueDate = article.duedate_golive
          ? moment(article.duedate_golive)
          : null;
        break;
      default:
        dueDate = null;
    }
    if (dueDate) {
      return raw ? dueDate : dueDate.format("MM/DD/YYYY");
    }
  };

  renderCorrespondingDueDateType = (statusUid) => {
    switch (statusUid) {
      case "writing":
        return "duedate_write";
      case "rewrite":
        return "duedate_rewrite";
      case "editing":
        return "duedate_edit";
      case "reedit":
        return "duedate_reedit";
      case "final_review":
        return "duedate_finalreview";
      case "ready_to_post":
        return "duedate_schedulepost";
      case "post_qa":
        return "duedate_qapost";
      case "scheduled":
        return "duedate_golive";
      case "posted":
        return "duedate_golive";
      default:
        return null;
    }
  };

  render() {
    const options = [];
    const optionsResult = [];
    if (this.props.contentStaff[0] && this.props.writers[0]) {
      this.props.contentStaff.forEach((staff) =>
        options.push({
          key: staff.id,
          text: staff.name,
          value: staff.username,
        })
      );
      this.props.writers.forEach((writer) =>
        options.push({
          key: writer.user.id,
          text: writer.user.name,
          value: writer.user.username,
        })
      );
    }
    const map = new Map();
    for (const item of options) {
      if (!map.has(item.key)) {
        map.set(item.key, true);
        optionsResult.push({
          key: item.id,
          text: item.text,
          value: item.value,
        });
      }
    }
    const renderedArticles =
      this.props.contentArticles &&
      this.props.contentArticles[0] &&
      this.props.contentArticles.map((article) => ({
        ...article,
        correspondingDueDate:
          article[
            this.renderCorrespondingDueDateType(
              article.status && article.status.uid
            )
          ],
      }));
    return (
      <div className="Assignments">
        {hasPermissions(this.props.user) &&
          this.props.contentStaff[0] &&
          this.props.writers[0] && (
            <div className="Assignments--actions">
              <div className="Assignments--dropdownHolder">
                {!this.state.isLead && (
                  <Dropdown
                    name="staff"
                    search
                    placeholder="View as Staff"
                    clearable
                    onChange={this.handleStaffChange}
                    options={optionsResult}
                    selection
                    disabled={this.props.isLoadingArticles}
                    loading={this.props.isLoadingArticles}
                    value={
                      this.state.viewingAs && this.state.viewingAs.username
                    }
                  />
                )}
              </div>
              <Link
                to={`${this.props.location.pathname}${
                  !this.state.isLead ? "?lead=true" : ""
                }`}
              >
                <Button primary>
                  {this.state.isLead ? "My Assignments" : "My Lead View"}
                </Button>
              </Link>
            </div>
          )}

        <Grid>
          <Grid.Row>
            <Grid.Column width={this.state.selectedAssignment ? 11 : 16}>
              {this.props.contentArticles[0] &&
              renderedArticles &&
              !this.props.isLoadingArticles ? (
                <Switch>
                  <Route
                    path={`${this.props.match.path}/:staff`}
                    render={(props) => {
                      return this.state.viewingAs ? (
                        !this.props.isLoadingArticles &&
                        this.props.articlesLoaded ? (
                          <AssignmentListTable
                            {...props}
                            planningMonths={this.props.planningMonths}
                            renderDueDate={this.renderDueDate}
                            renderCorrespondingDueDateType={
                              this.renderCorrespondingDueDateType
                            }
                            selectedItem={
                              this.state.selectedAssignment
                                ? this.state.selectedAssignment
                                : false
                            }
                            organizations={this.props.organizations}
                            contentStatuses={this.props.contentStatuses}
                            handleSelect={this.handleAssignmentSelect}
                            // originalArticles={this.handleFilterAssignedArticles(
                            //   renderedArticles.filter(
                            //     (d) =>
                            //       d.status.order >= 2 && d.status.order <= 8
                            //   )
                            // )}
                            // tableData={this.handleFilterAssignedArticles(
                            //   renderedArticles.filter(
                            //     (d) =>
                            //       d.status.order >= 2 && d.status.order <= 8
                            //   )
                            // )}
                            originalArticles={this.handleFilterAssignedArticles(
                              renderedArticles.filter(
                                (d) => d.status.status_type === "Active"
                              )
                            )}
                            tableData={this.handleFilterAssignedArticles(
                              renderedArticles.filter(
                                (d) => d.status.status_type === "Active"
                              )
                            )}
                          />
                        ) : (
                          <FixedLoader text="Loading Assignments" />
                        )
                      ) : (
                        <NotFound />
                      );
                    }}
                  />
                  <Route
                    exact
                    path={`${this.props.match.path}`}
                    render={(props) =>
                      this.state.isLead ? (
                        <AssignmentListTable
                          {...props}
                          isLead
                          planningMonths={this.props.planningMonths}
                          renderDueDate={this.renderDueDate}
                          renderCorrespondingDueDateType={
                            this.renderCorrespondingDueDateType
                          }
                          selectedItem={
                            this.state.selectedAssignment
                              ? this.state.selectedAssignment
                              : false
                          }
                          organizations={this.props.organizations}
                          contentStatuses={this.props.contentStatuses}
                          handleSelect={this.handleAssignmentSelect}
                          originalArticles={this.handleFilterAssignedArticles(
                            renderedArticles.filter(
                              (d) => d.status.status_type === "Active"
                            ),
                            true
                          )}
                          tableData={this.handleFilterAssignedArticles(
                            renderedArticles.filter(
                              (d) => d.status.status_type === "Active"
                            ),
                            true
                          )}
                        />
                      ) : (
                        <AssignmentListTable
                          {...props}
                          planningMonths={this.props.planningMonths}
                          customerView={
                            this.props.user.default_role &&
                            this.props.user.default_role.name === "Customer"
                          }
                          organizations={this.props.organizations}
                          renderDueDate={this.renderDueDate}
                          renderCorrespondingDueDateType={
                            this.renderCorrespondingDueDateType
                          }
                          selectedItem={
                            this.state.selectedAssignment
                              ? this.state.selectedAssignment
                              : false
                          }
                          contentStatuses={this.props.contentStatuses}
                          handleSelect={this.handleAssignmentSelect}
                          originalArticles={this.handleFilterAssignedArticles(
                            renderedArticles.filter(
                              (d) => d.status.status_type === "Active"
                            )
                          )}
                          tableData={this.handleFilterAssignedArticles(
                            renderedArticles.filter(
                              (d) => d.status.status_type === "Active"
                            )
                          )}
                        />
                      )
                    }
                  />
                </Switch>
              ) : !this.props.isLoadingArticles && !this.state.viewingAs ? (
                <Header className="textCenter">No Current Assignments</Header>
              ) : (
                <FixedLoader text="Loading Assignments" />
              )}
            </Grid.Column>
            {this.state.selectedAssignment && (
              <Grid.Column width={5}>
                <ArticleDetail
                  customerView={
                    this.props.user.default_role &&
                    this.props.user.default_role.name === "Customer"
                  }
                  editorialRequirements={
                    this.props.editorialRequirements[0] &&
                    this.props.editorialRequirements.filter(
                      (req) =>
                        req.organization ===
                        this.state.selectedAssignment.client.id
                    )
                  }
                  comments={this.props.comments.filter(
                    (comment) =>
                      comment.article === this.state.selectedAssignment.id
                  )}
                  keywords={this.props.keywords}
                  fetchKeywords={this.props.fetchKeywords}
                  handleClose={this.handleClose}
                  article={this.state.selectedAssignment}
                  // toWrite={this.state.selectedAssignment.status.order === 3}
                  // toRewrite={this.state.selectedAssignment.status.order === 4}
                  // toEdit={this.state.selectedAssignment.status.order === 5}
                  // toReEdit={this.state.selectedAssignment.status.order === 6}
                  // toReview={this.state.selectedAssignment.status.order === 7}
                  // toPost={this.state.selectedAssignment.status.order === 8}
                  toWrite={
                    this.state.selectedAssignment.status.uid === "writing"
                  }
                  toRewrite={
                    this.state.selectedAssignment.status.uid === "rewrite"
                  }
                  toEdit={
                    this.state.selectedAssignment.status.uid === "editing"
                  }
                  toReEdit={
                    this.state.selectedAssignment.status.uid === "reedit"
                  }
                  toReview={
                    this.state.selectedAssignment.status.uid === "final_review"
                  }
                  toPost={
                    this.state.selectedAssignment.status.uid === "ready_to_post"
                  }
                  toQaPost={
                    this.state.selectedAssignment.status.uid === "post_qa"
                  }
                />
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    allArticles: state.contentGeneration.contentArticles,
    contentArticles: state.contentGeneration.contentArticles,
    contentStatuses: state.contentGeneration.contentStatuses,
    isLoadingArticles: state.contentGeneration.isLoadingArticles,
    articlesLoaded: state.contentGeneration.articlesLoaded,
    writers: state.contentGeneration.writers,
    contentStaff: state.contentGeneration.contentStaff,
    comments: state.contentGeneration.contentComments,
    editorialRequirements: state.organizations.editorialRequirements,
    planningMonths: state.contentGeneration.planningMonths,
    keywords: state.contentGeneration.keywords,
    isLoadingKeywords: state.contentGeneration.isLoadingKeywords,
    keywordsLoaded: state.contentGeneration.keywordsLoaded,
    errors: state.contentGeneration.errors,
    componentFilters: state.contentGeneration.componentFilters,
    organizations: state.organizations.organizations,
    isLoadingOrgs: state.organizations.isLoading,
    orgsLoaded: state.organizations.isLoaded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchContentArticles: (id, lead) => {
      return dispatch(contentGeneration.fetchContentArticles(id, lead));
    },
    fetchKeywords: (organizationId) => {
      return dispatch(contentGeneration.fetchContentKeywords(organizationId));
    },
    fetchContentComments: () => {
      return dispatch(contentGeneration.fetchContentComments());
    },
    fetchEditorialRequirements: () => {
      return dispatch(organizations.fetchEditorialRequirements());
    },
    fetchContentStatuses: () => {
      return dispatch(contentGeneration.fetchContentStatuses());
    },
    fetchContentStaff: () => {
      return dispatch(contentGeneration.fetchContentStaff());
    },
    fetchWriters: () => {
      return dispatch(contentGeneration.fetchWriters());
    },
    updateArticleStatus: (
      articleId,
      newStatus,
      message,
      args
      // scheduledDate,
      // writerDueDate,
      // postedUrl
    ) => {
      return dispatch(
        contentGeneration.updateArticleStatus(
          articleId,
          newStatus,
          message,
          args
          // scheduledDate,
          // writerDueDate,
          // postedUrl
        )
      );
    },
    setMenuItems: (items, route) => dispatch(ui.setMenuItems(items, route)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Assignments);
