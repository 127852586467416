import React from "react";
import "./DividerHeader.css";
import { Divider, Header, Icon } from 'semantic-ui-react';

const DividerHeader = props => {
  return (
    <div className="DividerHeader">
      <Divider horizontal>
        <Header as={props.size ? props.size : "h3"}>
         {props.icon &&  <Icon color={props.iconColor} name={props.icon} />}
          {props.children}
        </Header>
      </Divider>
    </div>
  );
};

export default DividerHeader;
