import * as actionTypes from '../actions/actionTypes';
import axios from '../../axios-portal';

export const addMessage = (message) => {
	return {
		type: actionTypes.ADD_MESSAGE,
		message: message
	};
};

export const removeMessage = (message, timeToWait) => {
	return {
		type: actionTypes.REMOVE_MESSAGE,
		message: message,
		timeToWait: timeToWait
	};
};

export const startOrganizationsFetch = () => {
	return {
		type: actionTypes.START_ORGANIZATIONS_FETCH
	};
};

export const setOrganizations = (organizations) => {
	return {
		type: actionTypes.SET_ORGANIZATIONS,
		organizations: organizations
	};
};

export const fetchOrganizationsFailure = (errors) => {
	return {
		type: actionTypes.FETCH_ORGANIZATIONS_FAILURE,
		errors: errors
	};
};

export const addingOrganization = () => {
	return {
		type: actionTypes.ADDING_ORGANIZATION
	};
};

export const updatingOrganization = (organizationIndex) => {
	return {
		type: actionTypes.UPDATING_ORGANIZATION,
		organizationIndex: organizationIndex
	};
};

export const addOrganizationSuccess = (organization) => {
	return {
		type: actionTypes.ADD_ORGANIZATION_SUCCESS,
		organization: organization
	};
};

export const updateOrganizationSuccess = (organization, id) => {
	return {
		type: actionTypes.UPDATE_ORGANIZATION_SUCCESS,
		organization: organization,
		id: id
	};
};

export const updateOrganizationFailure = (errors, organizationIndex) => {
	return {
		type: actionTypes.UPDATE_ORGANIZATION_FAILURE,
		organizationIndex: organizationIndex,
		errors: errors
	};
};

export const organizationFailure = (errors) => {
	return {
		type: actionTypes.ORGANIZATION_FAILURE,
		errors: errors
	};
};

export const archiveOrganizationSuccess = (id) => {
	return {
		type: actionTypes.ARCHIVE_ORGANIZATION_SUCCESS,
		id: id
	}
}

export const startCreateWhatConvertsAccount = (orgIndex) => {
	return {
		type: actionTypes.START_CREATE_WHAT_CONVERTS_ACCOUNT,
		orgIndex: orgIndex
	};
};

export const createWhatConvertsAccountSuccess = (orgIndex, organization) => {
	return {
		type: actionTypes.CREATE_WHAT_CONVERTS_ACCOUNT_SUCCESS,
		orgIndex: orgIndex,
		organization: organization
	};
};

export const createWhatConvertsAccountFailure = (orgIndex, errors) => {
	return {
		type: actionTypes.CREATE_WHAT_CONVERTS_ACCOUNT_FAILURE,
		orgIndex: orgIndex,
		errors: errors
	};
};

export const startUpdateWhatConvertsAccount = (orgIndex) => {
	return {
		type: actionTypes.START_UPDATE_WHAT_CONVERTS_ACCOUNT,
		orgIndex: orgIndex
	};
};

export const updateWhatConvertsAccountSuccess = (orgIndex, whatConvertsAccount) => {
	return {
		type: actionTypes.UPDATE_WHAT_CONVERTS_ACCOUNT_SUCCESS,
		orgIndex: orgIndex,
		whatConvertsAccount: whatConvertsAccount
	};
};

export const updateWhatConvertsAccountFailure = (orgIndex, errors) => {
	return {
		type: actionTypes.UPDATE_WHAT_CONVERTS_ACCOUNT_FAILURE,
		orgIndex: orgIndex,
		errors: errors
	};
};

export const startEditorialRequirementsFetch = () => {
	return {
		type: actionTypes.START_EDITORIAL_REQUIREMENTS_FETCH
	};
};

export const setEditorialRequirements = (editorialRequirements) => {
	return {
		type: actionTypes.SET_EDITORIAL_REQUIREMENTS,
		editorialRequirements: editorialRequirements
	};
};

export const fetchEditorialRequirementsFailure = (errors) => {
	return {
		type: actionTypes.FETCH_EDITORIAL_REQUIREMENTS_FAILURE,
		errors: errors
	};
};

export const addingEditorialRequirement = () => {
	return {
		type: actionTypes.ADDING_EDITORIAL_REQUIREMENT
	};
};

export const addEditorialRequirementSuccess = (editorialRequirement) => {
	return {
		type: actionTypes.ADD_EDITORIAL_REQUIREMENT_SUCCESS,
		editorialRequirement: editorialRequirement
	};
};

export const updateEditorialRequirementSuccess = (editorialRequirement, id) => {
	return {
		type: actionTypes.UPDATE_EDITORIAL_REQUIREMENT_SUCCESS,
		editorialRequirement: editorialRequirement,
		id: id
	};
};

export const editorialRequirementFailure = (errors) => {
	return {
		type: actionTypes.EDITORIAL_REQUIREMENT_FAILURE,
		errors: errors
	};
};

export const deleteEditorialRequirementSuccess = (id) => {
	return {
		type: actionTypes.DELETE_EDITORIAL_REQUIREMENT_SUCCESS,
		id: id
	};
};

export const fetchOrganizations = (detailed) => {
	return async (dispatch, getState) => {
		const isLoading = await getState().organizations.isLoading;
		dispatch(startOrganizationsFetch());
		!isLoading &&
			axios
				.get(`organizations${detailed ? '/?detailed=true' : '.json'}`)
				.then((response) => {
					return dispatch(setOrganizations(response.data));
				})
				.catch((err) => {
					return dispatch(fetchOrganizationsFailure(err));
				});
	};
};

export const addOrganization = (formData, detailed) => {
	let orgData = new FormData();
	return (dispatch) => {
		dispatch(addingOrganization());
		let data = {
			dba_name: formData.dbaName,
			legal_name: formData.legalName,
			business_email: formData.email,
			phone_number: formData.phone,
			street_address_1: formData.streetAddress1,
			street_address_2: formData.streetAddress2,
			city: formData.city,
			state: formData.state,
			zipcode: formData.zipcode,
			domain: formData.domain,
			logo: formData.logo ? formData.logo : null,
			report_required: formData.report_required,
			google_analytics_id: formData.google_analytics_id,
			what_converts_account:
				formData.what_converts_account && formData.what_converts_account.account_id
					? formData.what_converts_account
					: null,
			default_report_creator: formData.default_report_creator,
			default_report_approver: formData.default_report_approver,
			account_lead: formData.account_lead,
			project_manager: formData.project_manager,
			is_active: formData.is_active ? formData.is_active : true,
			contract_start_date: formData.contract_start_date,
		};

		Object.entries(data).filter(([ k, v ]) => v).forEach(([ k, v ]) => {
			if (typeof v === 'object' && k !== 'logo') {
				const vString = JSON.stringify(v);
				orgData.append(k, vString);
			} else {
				orgData.set(k, v);
			}
		});

		let message = 'Organization Added!';
		axios
			.post(`organizations${detailed ? '/?detailed=true' : '.json'}`, orgData, {
				headers: {
					'Content-Type': 'application/json'
				}
			})
			.then((response) => {
				dispatch(addMessage(message));
				setTimeout(() => dispatch(removeMessage(message)), 2500);
				return dispatch(addOrganizationSuccess(response.data));
			})
			.catch((err) => {
				dispatch(organizationFailure(err));
			});
	};
};

export const updateOrganization = (formData, id, detailed) => {
	let orgData = new FormData();
	return (dispatch, getState) => {
		const organizationIndex = getState().organizations.organizations.findIndex(
			(organization) => organization.id === id
		);
		dispatch(updatingOrganization(organizationIndex));
		let data = {
			dba_name: formData.dbaName,
			legal_name: formData.legalName,
			business_email: formData.email,
			phone_number: formData.phone,
			street_address_1: formData.streetAddress1,
			street_address_2: formData.streetAddress2,
			city: formData.city,
			state: formData.state,
			zipcode: formData.zipcode,
			domain: formData.domain,
			logo: formData.logo,
			report_required: formData.report_required,
			google_analytics_id: formData.google_analytics_id,
			what_converts_account: formData.what_converts_account ? formData.what_converts_account : null,
			default_report_creator: formData.default_report_creator,
			default_report_approver: formData.default_report_approver,
			account_lead: formData.account_lead,
			project_manager: formData.project_manager,
			is_active: formData.is_active ? formData.is_active : true,
			contract_start_date: formData.contract_start_date,
		};

		Object.entries(data).filter(([ k, v ]) => v).forEach(([ k, v ]) => {
			if (typeof v === 'object' && k !== 'logo') {
				const vString = JSON.stringify(v);
				orgData.append(k, vString);
			} else {
				orgData.set(k, v);
			}
		});

		// console.log({orgData})

		let message = 'Organization Updated!';
		axios
			.put(`organizations/${id}${detailed ? '/?detailed=true' : ''}`, orgData, {
				headers: {
					'Content-Type': 'application/json'
				}
			})
			.then((response) => {
				dispatch(addMessage(message));
				setTimeout(() => dispatch(removeMessage(message)), 2500);
				return dispatch(updateOrganizationSuccess(response.data, id));
			})
			.catch((err) => {
				dispatch(updateOrganizationFailure(err, organizationIndex));
			});
	};
};

export const archiveOrganization = (id, archive) => {
	let shouldArchive = archive === true ? false : true;
	let orgData = new FormData();

	return (dispatch, getState) => {
		let org = getState().organizations.organizations.find((org) => org.id === id);

		let data = {
			...org,
			logo: null,
			is_active: shouldArchive
		}


		Object.entries(data).filter(([ k, v ]) => v).forEach(([ k, v ]) => {
			if (typeof v === 'object' && k !== 'logo') {
				const vString = JSON.stringify(v);
				orgData.append(k, vString);
			} else {
				orgData.set(k, v);
			}
		});

		let message = shouldArchive ? 'Organization Reactivated!' : 'Organization Archived!';
		axios
			.put(`organizations/${id}/?archive=True`, orgData, {
				headers: {
					'Content-Type': 'application/json'
				}
			})
			.then((response) => {
				dispatch(addMessage(message));
				setTimeout(() => dispatch(removeMessage(message)), 2500);
				return dispatch(archiveOrganizationSuccess(id));
			})
			.catch((err) => {
				return dispatch(organizationFailure(err));
			});
	};
};

export const startGetOverdueContentApprovals = (orgId) => {
	return {
		type: actionTypes.START_GET_OVERDUE_CONTENT_APPROVALS,
		orgId: orgId
	}
}

export const getOverdueContentApprovalsSuccess = (orgId, pendingApprovals) => {
	return {
		type: actionTypes.GET_OVERDUE_CONTENT_APPROVALS_SUCCESS,
		orgId: orgId,
		pendingApprovals: pendingApprovals
	}
}

export const getOverdueContentApprovalsFailure = (orgId) => {
	return {
		type: actionTypes.GET_OVERDUE_CONTENT_APPROVALS_FAILURE,
		orgId: orgId
	}
}

export const getOverdueContentApprovals = (orgId) => {
	return (dispatch, getState) => {
		dispatch(startGetOverdueContentApprovals(orgId))
		axios
			.get(`organizations/${orgId}/get_pending_content_approvals/`)
			.then((response) => {
				return dispatch(getOverdueContentApprovalsSuccess(orgId, response.data));
			})
			.catch((err) => {
				dispatch(getOverdueContentApprovalsFailure(orgId, err));
			});
	}
}

export const createWhatConvertsAccount = (orgID, formData, message) => {
	return async (dispatch, getState) => {
		const orgIndex = await getState().organizations.organizations.findIndex((org) => org.id === orgID);
		dispatch(startCreateWhatConvertsAccount(orgIndex));
		let body = JSON.stringify({
			account_id: formData.account_id,
			report_calls: formData.report_calls,
			report_form_fills: formData.report_form_fills,
			report_chats: formData.report_chats,
			spt_account: formData.spt_account
		});

		axios
			.post(`organizations/${orgID}/create_wc_account/`, body)
			.then((response) => {
				if (message) {
					dispatch(addMessage(message));
					setTimeout(() => dispatch(removeMessage(message)), 2500);
				}
				return dispatch(createWhatConvertsAccountSuccess(orgIndex, response.data));
			})
			.catch((err) => {
				dispatch(createWhatConvertsAccountFailure(orgIndex, err));
			});
	};
};

export const updateWhatConvertsAccount = (orgID, whatConvertsAccountID, formData, message) => {
	return async (dispatch, getState) => {
		const orgIndex = await getState().organizations.organizations.findIndex((org) => org.id === orgID);
		dispatch(startUpdateWhatConvertsAccount(orgIndex));
		let body = JSON.stringify(formData);

		axios
			.post(`whatconverts-accounts/${whatConvertsAccountID}/`, body)
			.then((response) => {
				if (message) {
					dispatch(addMessage(message));
					setTimeout(() => dispatch(removeMessage(message)), 2500);
				}
				return dispatch(updateWhatConvertsAccountSuccess(orgIndex, response.data));
			})
			.catch((err) => {
				dispatch(updateWhatConvertsAccountFailure(orgIndex, err));
			});
	};
};

export const fetchEditorialRequirements = () => {
	return (dispatch) => {
		dispatch(startEditorialRequirementsFetch());
		axios
			.get('org-editorial-requirements.json')
			.then((response) => {
				return dispatch(setEditorialRequirements(response.data));
			})
			.catch((err) => {
				return dispatch(fetchEditorialRequirementsFailure(err));
			});
	};
};

export const addEditorialRequirement = (requirement, orgId) => {
	return (dispatch) => {
		dispatch(addingEditorialRequirement());
		let body = JSON.stringify({
			requirement: requirement,
			organization: orgId
		});
		let message = 'Editorial Requirement Added!';
		axios
			.post('org-editorial-requirements.json', body)
			.then((response) => {
				dispatch(addMessage(message));
				setTimeout(() => dispatch(removeMessage(message)), 2500);
				return dispatch(addEditorialRequirementSuccess(response.data));
			})
			.catch((err) => {
				dispatch(editorialRequirementFailure(err.response.data));
			});
	};
};

export const updateEditorialRequirement = (requirement, id) => {
	return (dispatch) => {
		dispatch(addingEditorialRequirement());
		let body = JSON.stringify({
			requirement: requirement
		});
		let message = 'Editorial Requirement Updated!';
		axios
			.put(`org-editorial-requirements/${id}/`, body)
			.then((response) => {
				dispatch(addMessage(message));
				setTimeout(() => dispatch(removeMessage(message)), 2500);
				return dispatch(updateEditorialRequirementSuccess(response.data, id));
			})
			.catch((err) => {
				dispatch(editorialRequirementFailure(err.response.data));
			});
	};
};

export const deleteEditorialRequirement = (id) => {
	return (dispatch, getState) => {
		let message = 'Editorial Requirement Deleted.';

		return axios
			.delete(`org-editorial-requirements/${id}/`)
			.then((response) => {
				dispatch(addMessage(message));
				setTimeout(() => dispatch(removeMessage(message)), 2500);
				return dispatch(deleteEditorialRequirementSuccess(id));
			})
			.catch((err) => {
				dispatch(editorialRequirementFailure(err));
			});
	};
};
