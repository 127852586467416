import React, { Component } from "react";
import "./PostingOverviewTable.css";
import { Placeholder, Table } from "semantic-ui-react";
import _ from "lodash";
import ActionHeader from "../../../../components/ActionHeader/ActionHeader";
import FixedLoader from "../../../../components/FixedLoader/FixedLoader";
import PostingOverviewTableRow from "./PostingOverviewTableRow/PostingOverviewTableRow";

class PostingOverviewTable extends Component {
  state = {
    column: null,
    data: this.props.accountPosts,
    direction: null,
    search: {
      isLoading: false,
      results: [],
      value: "",
    },
  };

  componentDidUpdate(prevProps, prevState) {
    this.props.accountPosts !== prevProps.accountPosts &&
      this.setState({
        data: _.sortBy(this.props.accountPosts, [this.state.column]),
      });
  }

  handleSort = (clickedColumn) => () => {
    const { column, data, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: _.sortBy(data, [clickedColumn]),
        direction: "ascending",
      });

      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  render() {
    const {
      accounts,
      renderStatusClass,
      getCorrespondingAccountData,
      getOverdueContentApprovals,
    } = this.props;
    const { column, data, direction } = this.state;

    return (
      <div className="PostingOverviewTable">
        {this.props.headerText && (
          <ActionHeader headerText={this.props.headerText}>
            {this.props.headerContent}
          </ActionHeader>
        )}
        {data && accounts ? (
          <Table basic celled compact size="small" selectable sortable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={7}>Account</Table.HeaderCell>
                <Table.HeaderCell
                  width={6}
                  sorted={column === "pm" ? direction : null}
                  onClick={this.handleSort("pm")}
                >
                  PM
                </Table.HeaderCell>
                <Table.HeaderCell
                  width={6}
                  sorted={column === "lead" ? direction : null}
                  onClick={this.handleSort("lead")}
                >
                  SEO Specialist
                </Table.HeaderCell>
                <Table.HeaderCell
                  textAlign="center"
                  sorted={column === "finalReview" ? direction : null}
                  onClick={this.handleSort("finalReview")}
                >
                  Final Review
                </Table.HeaderCell>
                <Table.HeaderCell
                  textAlign="center"
                  sorted={column === "scheduled" ? direction : null}
                  onClick={this.handleSort("scheduled")}
                >
                  Scheduled
                </Table.HeaderCell>
                <Table.HeaderCell
                  textAlign="center"
                  sorted={column === "fourWeeksBack" ? direction : null}
                  onClick={this.handleSort("fourWeeksBack")}
                >
                  Live (Last 4wks)
                </Table.HeaderCell>
                <Table.HeaderCell
                  textAlign="center"
                  sorted={column === "twelveWeeksBack" ? direction : null}
                  onClick={this.handleSort("twelveWeeksBack")}
                >
                  Live (Last 12wks)
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data[0]
                ? data.map((posts) => (
                    <PostingOverviewTableRow
                      key={posts.account.id}
                      posts={posts}
                      selectedAccountPosts={this.props.selectedAccountPosts}
                      handleRowSelect={this.props.handleRowSelect}
                      getUser={this.props.getUser}
                      renderStatusClass={renderStatusClass}
                      getCorrespondingAccountData={getCorrespondingAccountData}
                      getOverdueContentApprovals={getOverdueContentApprovals}
                    />
                  ))
                : null}
              {this.props.isLoading && !this.props.finishedLoading && (
                <Table.Row>
                  <Table.Cell className="PostingOverviewTable--account">
                    <Placeholder>
                      <Placeholder.Paragraph>
                        <Placeholder.Line length="medium" />
                      </Placeholder.Paragraph>
                    </Placeholder>
                  </Table.Cell>
                  <Table.Cell className="PostingOverviewTable--lead">
                    <Placeholder>
                      <Placeholder.Paragraph>
                        <Placeholder.Line length="very short" />
                      </Placeholder.Paragraph>
                    </Placeholder>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <Placeholder>
                      <Placeholder.Paragraph>
                        <Placeholder.Line length="medium" />
                      </Placeholder.Paragraph>
                    </Placeholder>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <Placeholder>
                      <Placeholder.Paragraph>
                        <Placeholder.Line length="medium" />
                      </Placeholder.Paragraph>
                    </Placeholder>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <Placeholder>
                      <Placeholder.Paragraph>
                        <Placeholder.Line length="medium" />
                      </Placeholder.Paragraph>
                    </Placeholder>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <Placeholder>
                      <Placeholder.Paragraph>
                        <Placeholder.Line length="medium" />
                      </Placeholder.Paragraph>
                    </Placeholder>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        ) : (
          <FixedLoader />
        )}
      </div>
    );
  }
}

export default PostingOverviewTable;
