import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/auth";
import { Redirect } from "react-router-dom";
import { Header, Form, Button, Image, Divider } from "semantic-ui-react";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";
import logo from "../../../assets/images/321-logo-no-tagline-portal.png";
import "./PasswordReset.css";

class PasswordReset extends Component {
  state = {
    password1: "",
    password2: ""
  };

  componentDidMount() {
    if (!this.props.isAuthenticated && this.props.authRedirectPath !== "/") {
      this.props.onSetAuthRedirectPath();
    }
  }

  onSubmit = async e => {
    const uid = this.props.match.params.uid;
    const token = this.props.match.params.token;
    e.preventDefault();
    await this.props.resetPassword(
      this.state.password1,
      this.state.password2,
      uid,
      token
    );
    this.props.passwordResetSuccessful ? this.props.history.push("/auth/login/") : this.setState({ password1: "", password2: "" });
  };

  render() {
    let authRedirect = null;
    if (this.props.isAuthenticated) {
      authRedirect = <Redirect to={this.props.authRedirectPath} />;
    }
    return (
      <div className="PasswordReset">
        {authRedirect}
        <div className="AuthSidebar">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.321webmarketing.com/"
          >
            <Image centered size="mini" src={logo} alt="321 web marketing" />
          </a>
        </div>
        <div className="PasswordResetForm">
          <Header as="h2">Reset Password</Header>
          <Divider />
          <Form onSubmit={this.onSubmit}>
            {this.props.errors && <ErrorHandler errors={this.props.errors} />}
            <Form.Input
              required
              label="New Password"
              type="password"
              icon="lock"
              iconPosition="left"
              placeholder="Enter Password"
              id="password1"
              name="password1"
              value={this.state.password1}
              onChange={e => this.setState({ password1: e.target.value })}
            />
            <Form.Input
              required
              label="Confirm Password"
              type="password"
              icon="lock"
              iconPosition="left"
              placeholder="Confirm New Password"
              id="password2"
              name="password2"
              value={this.state.password2}
              onChange={e => this.setState({ password2: e.target.value })}
            />
            <Button color="purple" type="submit">
              Submit
            </Button>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    errors: state.auth.errors,
    isAuthenticated: state.auth.isAuthenticated,
    authRedirectPath: state.auth.authRedirectPath,
    passwordResetSuccessful: state.auth.passwordResetSuccessful
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetPassword: (password1, password2, uid, token) => {
      return dispatch(actions.resetPassword(password1, password2, uid, token));
    },
    onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath("/"))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordReset);
