import React, { Component } from 'react';
import './Report.css';
import { connect } from 'react-redux';
import { reporting } from '../../../store/actions';
import { Loader } from 'semantic-ui-react';
import { Redirect } from "react-router-dom"
import Presentation from '../../../containers/Presentation/Presentation';
import { hasPermissions } from "../../../utility";
import queryString from 'query-string';

class Report extends Component {
	state = {
		report: null
	};

	componentDidMount() {
		// !this.props.reports[0] && this.props.fetchReports();
		this.props.reports[0] && this.setReport();
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.props.isLoading !== prevProps.isLoading ||
			this.props.planningMonthsLoading !== prevProps.planningMonthsLoading ||
			(this.props.organizationsLoading || prevProps.organizationsLoading)
		) {
			this.fetchReports();
		}
		if (!prevState.report && this.state.report) {
			this.handleConfirmViewedReport()
		}
	}

	fetchReports = async () => {
		if (!this.props.isLoading && !this.props.isLoaded && !this.props.reports[0]) {
			await this.props.fetchReports();
			return this.setReport();
		} else {
			this.setReport();
		}
	};

	setReport = async () => {
		if (this.props.match.params.reportYear && this.props.match.params.reportMonth) {
			const organization = await this.props.organizations.find(
				(org) => org.slug === this.props.match.params.organizationSlug
			);
			const planningMonth = await this.props.planningMonths.find(
				(month) =>
					month.month === Number(this.props.match.params.reportMonth) &&
					month.year === Number(this.props.match.params.reportYear)
			);

			if (organization && planningMonth) {
				const report = await this.props.reports.find(
					(report) => report.organization === organization.id && report.month === planningMonth.id
				);
				return report && this.setState({ report: report });
			}
		} else {
			const report = await this.props.reports.find(
				(report) => report.id === Number(this.props.match.params.reportID)
			);
			return report && this.setState({ report: report });
		}
	};

	renderLoadingDetails = () => {
		if (this.props.planningMonthsLoading) {
			return 'Monthly Data';
		} else if (this.props.organizationsLoading) {
			return 'Organizational Data';
		} else {
			return 'Report Details';
		}
	};

	getEntryIDSearch = () => {
		const entryIDSearch = queryString.parse(this.props.location.search);
		const entryIDSearchValue = entryIDSearch.entryID;

		return entryIDSearchValue;
	};

	handleConfirmViewedReport = async () => {
		if (this.props.location.search !== '' && this.getEntryIDSearch(this.props.location.search)) {
			const searchValues = queryString.parse(this.props.location.search);
			await this.props.confirmViewedReport(this.getEntryIDSearch(this.props.location.search));
			if (searchValues.slide) {
				return this.props.history.push({
					search: `?slide=${searchValues.slide}`
				});
			} else {
				return this.props.history.push({
					search: ``
				});
			}
		}
	};

	render() {
		return (
			<div className="Report">
				{this.props.isLoading || this.props.planningMonthsLoading || this.props.organizationsLoading ? (
					<Loader active>Loading {this.renderLoadingDetails()}</Loader>
				) : this.state.report ? (
					<Presentation
						withPDF
						presentationID={this.state.report && this.state.report.presentation}
						isLoading={this.props.isLoading}
						isLoaded={this.props.isLoaded}
						{...this.props}
					/>
				) : (
					!hasPermissions(this.props.user, 'view_monthlyreport') ? <Redirect to='/reporting/' /> : <p>Report not found.</p>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		organizations: state.organizations.organizations,
		organizationsLoading: state.organizations.isLoading,
		planningMonths: state.contentGeneration.planningMonths,
		planningMonthsLoading: state.contentGeneration.isLoadingMonths,
		reports: state.reporting.reports,
		isLoading: state.reporting.isLoading,
		isLoaded: state.reporting.isLoaded,
		errors: state.reporting.errors,
		messages: state.reporting.messages
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchReports: () => dispatch(reporting.fetchReports()),
		confirmViewedReport: (reportID) => dispatch(reporting.confirmViewedReport(reportID))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Report);
