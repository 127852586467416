import React, { Component } from "react";
import { List, Search, Transition } from "semantic-ui-react";
import _ from "lodash";
import "./OrganizationList.css";

class OrganizationList extends Component {
  state = {
    isLoading: false,
    results: [],
    value: ""
  };

  resetComponent = () =>
    this.setState({ isLoading: false, results: [], value: "" });

  handleResultSelect = (e, { result }) => {
    let selectedOrg = this.props.organizations.find(
      organization => organization.id === result.key
    );
    this.props.handleClick(selectedOrg);
    this.setState({ value: result.dba_name });
  };

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value });

    setTimeout(() => {
      if (this.state.value.length < 1) return this.resetComponent();

      const re = new RegExp(_.escapeRegExp(this.state.value), "i");
      const isMatch = result => re.test(result.dba_name);

      this.setState({
        isLoading: false,
        results: _.filter(this.props.organizations, isMatch)
      });
    }, 300);
  };

  render() {
    return (
      <div className="OrganizationList">
        <div className="OrganizationListSearch">
          <Search
            fluid
            placeholder="Search Organizations"
            loading={this.state.isLoading}
            onResultSelect={this.handleResultSelect}
            onSearchChange={_.debounce(this.handleSearchChange, 500, {
              leading: true
            })}
            results={this.state.results.map(result => ({
              key: result.id,
              title: result.dba_name
            }))}
            value={this.state.value}
          />
        </div>

        <Transition.Group as={List} className="OrganizationList--list" duration={200} link divided relaxed="very" selection verticalAlign="middle">
          {this.state.results[0]
            ? this.state.results.map(organization => {
                return (
                  <List.Item
                    key={organization.id}
                    className="OrgListItem"
                    onClick={() => this.props.handleClick(organization)}
                  >
                    <List.Content className="OrgListContent">
                      <List.Header>{organization.dba_name}</List.Header>
                      <List.Description>{organization.domain}</List.Description>
                    </List.Content>
                  </List.Item>
                );
              })
            : this.props.organizations.map(organization => {
                return (
                  <List.Item
                    key={organization.id}
                    className="OrgListItem"
                    onClick={() => this.props.handleClick(organization)}
                  >
                    <List.Content className="OrgListContent">
                      <List.Header>{organization.dba_name}</List.Header>
                      <List.Description>{organization.domain}</List.Description>
                    </List.Content>
                  </List.Item>
                );
              })}
        </Transition.Group>
      </div>
    );
  }
}

export default OrganizationList;
