import React, { Component } from "react";
import { Card, Button, Loader, Label, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { checklists } from "../../../store/actions";
import "./WebsiteBuildCard.css";
import moment from "moment";

class WebsiteBuildCard extends Component {
  state = {
    isFetchingBuildList: false,
    isFetchingDeployList: false,
    isInView: false,
    fetchedData: false,
    initialDataFetched: false
  };

  cardRef = React.createRef();

  componentDidMount() {
    let buildCard = this.cardRef.current;
    this.isInViewport(this.props.listRef, buildCard);
    this.props.listRef.addEventListener("scroll", e =>
      this.isInViewport(this.props.listRef, buildCard)
    );
  }

  componentWillUnmount() {
    this.props.listRef.removeEventListener("scroll", this.isInViewport);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.build && this.props.build !== prevProps.build) {
      this.props.fetchChecklists(this.props.build.build_checklist);
      this.props.fetchChecklists(this.props.build.deploy_checklist);
    }
    if (this.state.isInView && !this.state.fetchedData) {
      if (this.props.build.build_checklist && !this.state.isFetchingBuildList) {
        this.props.fetchChecklists(this.props.build.build_checklist);
        this.setState({ isFetchingBuildList: true });
        this.setState({ fetchedData: true });
      }
      if (
        this.props.build.deploy_checklist &&
        !this.state.isFetchingDeployList
      ) {
        this.props.fetchChecklists(this.props.build.deploy_checklist);
        this.setState({ isFetchingDeployList: true });
        this.setState({ fetchedData: true });
      }
      this.setState({ fetchedData: true });
    }
    if (
      this.props.isLoaded &&
      !this.props.isLoading &&
      (!this.state.initialDataFetched && this.state.fetchedData)
    ) {
      this.setState({ initialDataFetched: true });
    }
  }

  isInViewport(listRef, cardRef, offset = 0) {
    if (!cardRef) return false;
    const top = cardRef.getBoundingClientRect().top;
    let isInView = top + offset >= 0 && top - offset <= window.innerHeight;
    isInView
      ? this.setState({ isInView: true })
      : this.setState({ isInView: false });
    return isInView;
  }

  getPercentage = (total, value) => {
    let percentage = (value / total) * 100;
    percentage = parseFloat(percentage).toFixed(0);
    return `${percentage}%`;
  };

  getCompletePercentage = checklist => {
    if (checklist.checklist_items[0]) {
      const itemCount = checklist.checklist_items.length;
      const completedCount = checklist.checklist_items.filter(
        item => item.completed
      ).length;
      return this.getPercentage(itemCount, completedCount);
    } else {
      return "No Items";
    }
  };

  render() {
    const buildChecklist =
      this.props.checklists[0] &&
      this.props.checklists.find(
        checklist => checklist.id === this.props.build.build_checklist
      );
    const deployChecklist =
      this.props.checklists[0] &&
      this.props.checklists.find(
        checklist => checklist.id === this.props.build.deploy_checklist
      );
    const { build } = this.props;

    return (
      <div className="WebsiteBuildCard--wrapper" ref={this.cardRef}>
        <Card
          key={build.id}
          fluid
          className={`WebsiteBuildCard ${
            this.props.archives ? "archives" : ""
          }`}
        >
          <Card.Content>
            <Card.Header>
              {build.label}
              <Button
                floated="right"
                size="mini"
                basic
                onClick={() => this.props.selectForEdit(build)}
              >
                Edit Build Details
              </Button>
            </Card.Header>
            <Card.Meta>{build.organization.dba_name}</Card.Meta>
          </Card.Content>
          <Card.Content>
            <Card.Description className="WebsiteBuildCard--description__container">
              {build.start_date && (
                <div className="WebsiteBuildCard--description__item">
                  <strong className="WebsiteBuildCard--description">
                    Project Start Date
                  </strong>
                  <p>{moment(build.start_date).format("MMM Do, YYYY")}</p>
                </div>
              )}
              {build.staging_url && (
                <div className="WebsiteBuildCard--description__item">
                  <strong className="WebsiteBuildCard--description">
                    Staging Site
                  </strong>
                  <a href={build.staging_url} target="_blank" rel="noopener noreferrer">
                    View Site <Icon name="external alternate" />
                  </a>
                </div>
              )}
              {build.sitemap_link && (
                <div className="WebsiteBuildCard--description__item">
                  <strong className="WebsiteBuildCard--description">
                    Sitemap
                  </strong>
                  <a href={build.sitemap_link} target="_blank" rel="noopener noreferrer">
                    Link <Icon name="external alternate" />
                  </a>
                </div>
              )}
              {build.brand_interview_link && (
                <div className="WebsiteBuildCard--description__item">
                  <strong className="WebsiteBuildCard--description">
                    Brand Interview
                  </strong>
                  <a
                    href={build.brand_interview_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Link <Icon name="external alternate" />
                  </a>
                </div>
              )}
              {build.intake_link && (
                <div className="WebsiteBuildCard--description__item">
                  <strong className="WebsiteBuildCard--description">
                    Intake Sheet
                  </strong>
                  <a href={build.intake_link} target="_blank" rel="noopener noreferrer">
                    Link <Icon name="external alternate" />
                  </a>
                </div>
              )}
            </Card.Description>
            {build.notes && (
              <Card.Description>
                <strong className="WebsiteBuildCard--description">
                  Description
                </strong>
                <p>{build.notes}</p>
              </Card.Description>
            )}
          </Card.Content>
          <Card.Content extra>
            <div className="WebsiteBuildCard--checklistStatuses">
              <div className="WebsiteBuildCard--checklistStatus">
                {this.state.initialDataFetched ? (
                  buildChecklist ? (
                    <span
                      onClick={() =>
                        this.props.handleListSelect(buildChecklist)
                      }
                      className="WebsiteBuildCard--checklistStatus__content"
                    >
                      <span>Website Build Progress</span>{" "}
                      <Label color="teal" size="mini">
                        {this.getCompletePercentage(buildChecklist)}
                      </Label>
                    </span>
                  ) : (
                    <span className="WebsiteBuildCard--checklistStatus__content">
                      <span>Website Build Checklist Not Found</span>
                      <Label color="red" size="mini">
                        <Icon name="exclamation" />
                      </Label>
                    </span>
                  )
                ) : (
                  <Loader size="mini" inline="centered" active />
                )}
              </div>
              <div className="WebsiteBuildCard--checklistStatus">
                {this.state.initialDataFetched ? (
                  deployChecklist ? (
                    <span
                      onClick={() =>
                        this.props.handleListSelect(deployChecklist)
                      }
                      className="WebsiteBuildCard--checklistStatus__content"
                    >
                      <span>Website Deployment Progress</span>{" "}
                      <Label color="teal" size="mini">
                        {this.getCompletePercentage(deployChecklist)}
                      </Label>
                    </span>
                  ) : (
                    <span className="WebsiteBuildCard--checklistStatus__content">
                      <span>Website Deployment Checklist Not Found</span>
                      <Label color="red" size="mini">
                        <Icon name="exclamation" />
                      </Label>
                    </span>
                  )
                ) : (
                  <Loader size="mini" inline="centered" active />
                )}
              </div>
            </div>
          </Card.Content>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    websiteBuilds: state.clientOnboarding.websiteBuilds.builds,
    checklists: state.checklists.checklists.lists,
    isLoading: state.checklists.checklists.isLoading,
    isLoaded: state.checklists.checklists.isLoaded,
    isAdding: state.checklists.checklists.isAdding
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchChecklists: id => dispatch(checklists.fetchChecklists(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WebsiteBuildCard);
