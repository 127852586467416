import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import moment from "moment";
import { contentGeneration } from "../../store/actions";
import { connect } from "react-redux";
import "./ContentProjectForm.css";
import ErrorHandler from "../ErrorHandler/ErrorHandler";
import { DateInput } from "semantic-ui-calendar-react";
/*eslint eqeqeq: "off"*/

const initialState = {
  name: "",
  year: "",
  month: "",
  due_date: ""
};

class ContentProjectForm extends Component {
  state = {
    form: initialState,
    isDateValid: false,
    validationError: null
  };

  componentDidMount() {
    this.props.data && this.setData(this.props.data);
  }

  routeToNewProject = id =>
    this.props.history.push(`/content-generation/projects/${id}`);

  handleSubmit = async () => {
    if (this.props.data) {
      await this.props.updateContentProject(this.props.data.id, this.state.form);
      !this.props.errors &&
        this.props.newProject &&
        this.routeToNewProject(this.props.data.id);
    } else {
      await this.props.addContentProject(this.state.form);
      !this.props.errors &&
        this.props.newProject &&
        this.routeToNewProject(this.props.newProject.id);
    }
  };

  setData = data => {
    this.setState({
      form: {
        name: data.name,
        year: data.start_year,
        month: String(data.start_month),
        due_date: data.due_date
      }
    });
  };

  async handleChange(e) {
    await this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  }

  handleSelectChange = (event, { name, value }) => {
    this.setState({ form: { ...this.state.form, [name]: value } });
  };

  render() {
    return (
      <Form size="small" onSubmit={() => this.handleSubmit()}>
        {this.props.errors && <ErrorHandler errors={this.props.errors} />}
        <Form.Group>
          <Form.Input
            required
            fluid
            name="name"
            width={8}
            label="Project Name"
            placeholder="Enter a name for the project"
            onChange={e => this.handleChange(e)}
            value={this.state.form.name}
          />
          <Form.Field width={8}>
            <DateInput
              closable
              className="fullWidthDate"
              label="Due Date"
              icon={false}
              name="due_date"
              placeholder="Choose Date"
              dateFormat="YYYY-MM-DD"
              value={this.state.form.due_date ? this.state.form.due_date : ""}
              onChange={this.handleSelectChange}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Select
            required
            fluid
            width={8}
            name="month"
            label="Start Month"
            options={moment.months().map(month => ({
              key: moment()
                .month(month)
                .format("M"),
              text: month,
              value: moment()
                .month(month)
                .format("M")
            }))}
            placeholder="Select Month"
            onChange={this.handleSelectChange}
            value={this.state.form.month}
          />
          <Form.Input
            required
            fluid
            width={8}
            type="number"
            name="year"
            min={moment()
              .subtract(1, "years")
              .year()}
            max={moment()
              .add(5, "years")
              .year()}
            pattern="[0-9]*"
            label="Start Year"
            placeholder="Year"
            onChange={e => this.handleChange(e)}
            value={this.state.form.year}
          />
        </Form.Group>
        <Form.Button type="Submit" color="green">
          {this.props.data ? 'Update Project' : 'Add Project'}
        </Form.Button>
      </Form>
    );
  }
}

const mapStateToProps = state => {
  return {
    contentStatuses: state.contentGeneration.contentStatuses,
    errors: state.contentGeneration.errors,
    isAddingProject: state.contentGeneration.isAddingProject,
    addedProject: state.contentGeneration.addedProject,
    newProject: state.contentGeneration.newProject
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addContentProject: formData => {
      return dispatch(contentGeneration.addContentProject(formData));
    },
    updateContentProject: (id, formData) => {
      return dispatch(contentGeneration.updateContentProject(id, formData));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentProjectForm);
