import React from 'react';
import "./UserDataWriterDetails.css";

export const UserDataWriterDetails = (props) => {
	const user = props.selectedUser;
	return (
		<div className="UserDataWriterDetails">
			<div className="UserDataWriterDetailsItem">
				<span className="UserDataWriterDetailsItem__label">Cost Per Word</span>
				<span className="UserDataWriterDetailsItem__content">${user.writer.rate}</span>
			</div>
			<div className="UserDataWriterDetailsItem">
				<span className="UserDataWriterDetailsItem__label">Rating</span>
				<span className="UserDataWriterDetailsItem__content">{props.renderRating(user.writer.rating)}</span>
			</div>
		</div>
	);
};
