import React, { Component } from "react";
import { Modal, Button, Icon } from "semantic-ui-react";
import "./BasicModal.css";

export default class BasicModal extends Component {
  render() {
    return (
      <div>
        <Modal
          dimmer={this.props.inverted ? 'inverted' : true}
          open={this.props.isOpen}
          onClose={() => this.props.handleClose()}
          size={this.props.size}
        >
          <Modal.Content>
            <div className="BasicModalHeader">
              <h3 className="BasicModalHeaderTitle">
                {this.props.icon && <Icon name={this.props.icon} />}{" "}
                {this.props.title}
              </h3>{" "}
              {!this.props.cancelButton && (
                <span
                  className="ModalCloseButton"
                  onClick={() => this.props.handleClose()}
                >
                  &times;
                </span>
              )}
            </div>
            <div className="BasicModalChildren">{this.props.children}</div>
          </Modal.Content>
          {this.props.cancelButton && (
            <Modal.Actions>
              <Button color="red" onClick={() => this.props.handleClose()}>
                Cancel
              </Button>
            </Modal.Actions>
          )}
        </Modal>
      </div>
    );
  }
}
