import React, { Component } from "react";
import "./ClientDashboard.css";
import { Switch, Route } from 'react-router-dom';
import ClientContentDashboard from "../ClientContentDashboard/ClientContentDashboard";
import ClientArchiveDashboard from "../ClientArchiveDashboard/ClientArchiveDashboard";

class ClientDashboard extends Component {


  render() {
    return (
      <div className="ClientDashboard">
        <Switch>
            <Route
                
                path={`/content-generation/archives/`}
                render={(props) => <ClientArchiveDashboard {...props} />}
            />
            <Route
                
                path={`/content-generation/`}
                render={(props) => <ClientContentDashboard {...props} />}
            />
        </Switch>
      </div>
    );
  }
}


export default ClientDashboard;
