import React, { Component } from 'react';
import './ReportDetail.css';
import { Button, Icon, Select, Input } from 'semantic-ui-react';
import UserSelectWidget from '../../../../components/UserSelectWidget/UserSelectWidget';

class ReportDetail extends Component {
	state = {
		report_url: null
	};

	componentDidMount() {
		this.props.report.report_url && this.setState({ report_url: this.props.report.report_url });
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.report !== this.props.report) {
			this.setState({ report_url: this.props.report.report_url });
		}
	}

	handleCreatorUpdate = (id) => {
		this.props.handleUpdateReport('creator', id);
	};

	handleApproverUpdate = (id) => {
		this.props.handleUpdateReport('approver', id);
	};

	handleSelectChange = (event, { name, value }) => {
		this.props.handleUpdateReport(name, value);
	};

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	handleURLChange = () => {
		if (this.props.report.report_url !== this.state.report_url) {
			return this.props.handleUpdateReport('report_url', this.state.report_url);
		}
	};

	render() {
		const { report } = this.props;
		return (
			<div className="ReportDetail">
				<div className="ReportDetail--actions">
					{(report.status === 'finalReview' || report.status === 'sent') &&
					report.report_url && (
						<Button size="tiny" positive icon onClick={() => this.props.handleReportSend()}>
							{report.status === 'sent' && 'Re-'}Send Report <Icon name="send" />
						</Button>
					)}
				</div>
				<div className="ReportDetail--items">
					<div className="ReportDetail--item">
						<span className="ReportDetail--item__label">Status:</span>
						<Select
							name="status"
							className="ReportDetail--status"
							onChange={this.handleSelectChange}
							selection
							size="mini"
							value={report.status}
							options={[
								{
									key: 'backlog',
									text: 'Backlog',
									value: 'backlog'
								},
								{
									key: 'assigned',
									text: 'Assigned',
									value: 'assigned'
								},
								{
									key: 'finalReview',
									text: 'Final Review',
									value: 'finalReview'
								},
								{
									key: 'sent',
									text: 'Sent',
									value: 'sent'
								}
							]}
						/>
						{/* <span className="ReportDetail--item__detail caps">
              {decamelize(report.status, " ")}
            </span> */}
					</div>
					<div className="ReportDetail--item">
						<span className="ReportDetail--item__label">Google URL:</span>
						<div className="ReportDetail--item__inlineField">
							<Input
								className="ReportDetail--item--url"
								name="report_url"
								placeholder="Paste Google Doc URL Here"
								type="url"
								value={this.state.report_url ? this.state.report_url : ''}
								onChange={(e) => this.handleChange(e)}
								onBlur={() => this.handleURLChange()}
							/>
							{report.report_url && (
								<span className="ReportDetail--item__detail caps">
									{this.props.getReportLink(report.report_url)}
								</span>
							)}
						</div>
					</div>
					<div className="ReportDetail--item">
						<span className="ReportDetail--item__label">Client:</span>
						<span className="ReportDetail--item__detail">
							{this.props.getOrganization(report.organization)}
						</span>
					</div>
					<div className="ReportDetail--item">
						<span className="ReportDetail--item__label">Creator:</span>
						<span className="ReportDetail--item__detail">
							{report.creator ? (
								<React.Fragment>
									<span>{this.props.getStaff(report.creator)}</span>
									<UserSelectWidget
										currentUser={this.props.getStaff(report.creator, true)}
										handleSelection={this.handleCreatorUpdate}
									/>{' '}
								</React.Fragment>
							) : (
								<UserSelectWidget
									currentUser={this.props.getStaff(report.creator, true)}
									handleSelection={this.handleCreatorUpdate}
								/>
							)}
						</span>
					</div>
					<div className="ReportDetail--item">
						<span className="ReportDetail--item__label">Approver:</span>
						<span className="ReportDetail--item__detail">
							{report.approver ? (
								<React.Fragment>
									<span>{this.props.getStaff(report.approver)}</span>
									<UserSelectWidget
										currentUser={this.props.getStaff(report.approver, true)}
										handleSelection={this.handleApproverUpdate}
									/>{' '}
								</React.Fragment>
							) : (
								<UserSelectWidget
									currentUser={this.props.getStaff(report.approver, true)}
									handleSelection={this.handleApproverUpdate}
								/>
							)}
						</span>
					</div>
					{report.presentation && (
						<div className="ReportDetail--item">{this.props.renderReportButton(report.organization)}</div>
					)}
				</div>
			</div>
		);
	}
}

export default ReportDetail;
