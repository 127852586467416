import React from "react";
import "./UserAvatar.css";
import { Image } from "semantic-ui-react";

const UserAvatar = props => {
  let { user, ...rest } = props;
  const getInitials = (firstName, lastName) => {
    let firstInitial = firstName.substring(0, 1);
    let lastInitial = lastName.substring(0, 1);
    return `${firstInitial} ${lastInitial}`;
  };
  return (
    <div {...rest} className="UserAvatar">
      {user.avatar_url ? (
        <Image avatar src={user.avatar_url} alt={user.name} />
      ) : (
        <div
          style={
            user.user_preferences
              ? { background: user.user_preferences.avatar_color }
              : { background: "#888888" }
          }
          className="UserAvatar--initials"
        >
          {getInitials(user.first_name, user.last_name)}
        </div>
      )}
    </div>
  );
};

export default UserAvatar;
