import React, { Component } from "react";
import { users } from "../../store/actions";
import { connect } from "react-redux";
import PopupNotification from "../PopupNotification/PopupNotification";
import { Link } from "react-router-dom";

class ContentNotifications extends Component {
  state = {
    shouldShow: false,
    didCheck: false,
  };

  componentDidMount() {
    this.props.user &&
      !this.props.user.didFetchDueSoonAssignments &&
      !this.props.user.DueSoonAssignments &&
      this.props.fetchDueSoonAssignments(true);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.user !== prevProps.user &&
      this.props.user.dueSoonAssignments !==
        prevProps.user.dueSoonAssignments &&
      this.props.user.dueSoonAssignments
    ) {
      this.setState({ shouldShow: true, didCheck: true });
    }
    if (this.state.didCheck !== prevState.didCheck && this.state.didCheck) {
      this.checkForUpcomingAssignments();
    }
  }

  checkForUpcomingAssignments = () => {
    this.state.didCheck &&
      setInterval(async () => {
        this.setState({ shouldShow: false });
        await this.props.fetchDueSoonAssignments(true);
        this.setState({ shouldShow: true });
      }, 60 * 60 * 1000);
  };

  render() {
    const upcomingAssignments =
      this.props.user && this.props.user.dueSoonAssignments;

    return (
      <div className="ContentNotifications">
        {this.state.shouldShow &&
          upcomingAssignments &&
          upcomingAssignments.count ? (
            <PopupNotification
              withClose
              negative
              duration={1000}
              headerSize="h4"
              animation="fade left"
              top="0%"
              right="20px"
              width="300px"
              header="Attention!"
              open
            >
              <p>
                You have <strong>{upcomingAssignments.count}</strong> assignment
                {upcomingAssignments.count > 1 ? "s" : ""} that{" "}
                {upcomingAssignments.count > 1 ? "are" : "is"} almost due!
              </p>
              <p>
                Please navigate to{" "}
                <Link to="/content-generation/assignments/">
                  My Assignments
                </Link>{" "}
                and complete {upcomingAssignments.count > 1 ? "them" : "it"} soon.
              </p>
            </PopupNotification>
          ) : ''}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDueSoonAssignments: (countOnly) => {
      dispatch(users.fetchDueSoonAssignments(countOnly));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentNotifications);
