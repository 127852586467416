import React, {Component } from "react";
import { Dropdown } from "semantic-ui-react";
import { organizations } from "../../store/actions";
import { connect } from "react-redux";

class OrganizationSelect extends Component {

  handleSelect = (event, { value }) => {
    let url = `/content-generation/articles/${value}`;
    this.props.history.push(url);
  };

  render() {
    return (
      <div>
        {this.props.organizations[0] && (
          <Dropdown
            selectOnBlur={false}
            selection
            labeled
            search
            button
            loading={this.props.isLoading ? true : false}
            className="icon"
            icon={this.props.icon ? this.props.icon : 'building'}
            size="tiny"
            placeholder={this.props.placeholder}
            options={this.props.organizations.filter(org => org.is_active).map(org => ({
              key: org.id,
              text: org.dba_name,
              value: org.slug
            }))}
            onChange={this.handleSelect}
          />
        )}
      </div>
    );
  }
};

const mapStateToProps = state => {
  return {
    organizations: state.organizations.organizations,
    isAddingOrganization: state.organizations.isAddingOrganization,
    isLoading: state.organizations.isLoading,
    isLoaded: state.organizations.isLoaded,
    addedProject: state.contentGeneration.addedProject,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchOrganizations: () => {
      return dispatch(organizations.fetchOrganizations());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationSelect);
