import React from 'react';
import './WithSidebar.css';
import { Grid } from 'semantic-ui-react';

const WithSidebar = (props) => {
	let leftWidth = props.leftWidth ? props.leftWidth : 10;
	let rightWidth = props.rightWidth ? props.rightWidth : 6;
	return (
		<div className="WithSidebar">
			<Grid>
				<Grid.Row>
					<Grid.Column
						width={props.fullWidth ? props.isShowing ? leftWidth : 16 : leftWidth}
						className="WithSidebar__leftColumn"
					>
						{props.children}
					</Grid.Column>
					{props.isShowing && (
						<Grid.Column className="WithSidebar__rightColumn" width={rightWidth}>
							<div className="WithSidebar--sidebarContainer">
								{props.sidebarActions && (
									<div className="WithSidebar--sidebarActions">{props.sidebarActions}</div>
								)}
								<div className="WithSidebar--sidebarContent">{props.sidebarComponent}</div>
							</div>
						</Grid.Column>
					)}
				</Grid.Row>
			</Grid>
		</div>
	);
};

export default WithSidebar;
