import React, { Component } from "react";
import { connect } from "react-redux";
import { checklists } from "../../../store/actions";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import { Checkbox, Form, Input, TextArea } from "semantic-ui-react";

const initialState = {
  name: "",
  description: "",
  archived: false
};

class ChecklistTemplateForm extends Component {
  state = {
    data: initialState
  };

  componentDidMount() {
    this.props.selectedTemplate && this.setBuildData();
  }

  componentDidUpdate(prevProps) {
    this.props.selectedTemplate !== prevProps.selectedTemplate &&
      this.props.selectedTemplate &&
      this.setBuildData();
  }

  setTemplateData = () => {
    this.setState({
      data: {
        name: this.props.selectedTemplate.name,
        description: this.props.selectedTemplate.description,
        archived: this.props.selectedTemplate.archived
      }
    });
  };

  clearForm = () => {
    this.setState({ data: initialState });
  };

  handleChange(e) {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value
      }
    });
  }

  handleSubmit = async e => {
    e.preventDefault();
    if (this.props.selectedTemplate) {
      await this.props.updateChecklistTemplate(
        this.props.selectedTemplate.id,
        this.state.data
      );
    } else {
      await this.props.addChecklistTemplate(this.state.data);
    }
    !this.props.errors && this.props.closeForm(this.props.addingMasterBuild);
  };

  render() {
    return (
      <div className="ChecklistTemplateForm">
        {this.props.errors && <ErrorHandler errors={this.props.errors} />}
        <Form onSubmit={this.handleSubmit}>
          <Form.Field>
            <label htmlFor="name">Template Name</label>
            <Input
              id="name"
              name="name"
              placeholder="Name of new template"
              onChange={e => this.handleChange(e)}
              value={this.state.data.name ? this.state.data.name : ""}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="description">Description</label>
            <TextArea
              id="description"
              placeholder="Description of template"
              name="description"
              onChange={e => this.handleChange(e)}
              value={
                this.state.data.description ? this.state.data.description : ""
              }
            />
          </Form.Field>
          {this.props.selectedTemplate && (
            <Form.Field>
              <Checkbox
                id="archived"
                label="Archived?"
                name="archived"
                onChange={e => this.handleChange(e)}
                checked={this.state.data.archived}
              />
            </Form.Field>
          )}
          <Form.Button type="submit">
            {this.props.selectedBuild ? "Save" : "Create Template"}
          </Form.Button>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    errors: state.checklists.errors,
    messages: state.checklists.messages,
    isAddingTemplate: state.checklists.checklistTemplates.isAdding
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addChecklistTemplate: data =>
      dispatch(checklists.addChecklistTemplate(data)),
    updateChecklistTemplate: (id, data) =>
      dispatch(checklists.updateChecklistTemplate(id, data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChecklistTemplateForm);
