import React, { Component } from 'react';
import './UserSelectWidget.css';
import { Icon, Loader, Popup, Form, Header } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { users } from '../../store/actions';
import UserAvatar from '../UserAvatar/UserAvatar';

class UserSelectWidget extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedUser: this.props.currentUser ? this.props.currentUser.id : null,
			popupOpen: false
		};
		this.contextRef = React.createRef();
	}

	componentDidMount() {
		!this.props.internalUsers[0] && this.props.fetchUsers(true);
	}

	// componentDidMount(prevProps, prevState) {
	//   if (prevProps.internalUsers !== this.props.internalUsers) {

	//   }
	// }

	// handleChange(e) {
	//   let user = this.props.internalUsers.find(user => user.name === e.target.value);
	//   console.log(user)
	//   if (user) {
	//     this.setState({
	//       selectedUser: user.id,

	//     });
	//   }
	//   this.setState({
	//     selectedUser: e.target.value
	//   });
	// }

	handleSelectChange = async (event, { name, value }) => {
		await this.setState({
			selectedUser: value
		});
		this.handlePopupClose();
		return this.props.handleSelection(this.state.selectedUser);
	};

	selectCurrentUser = async () => {
		await this.setState({ selectedUser: this.props.user.id });
		this.handlePopupClose();
		return this.props.handleSelection(this.state.selectedUser);
	};

	handlePopupOpen = () => {
		this.setState({ popupOpen: true });
	};

	handlePopupClose = () => {
		this.setState({ popupOpen: false });
	};

	render() {
		return (
			<div
				ref={this.contextRef}
				className={`UserSelectWidget ${this.props.currentUser ? 'withUser' : ''} ${this.props.currentUser &&
				this.props.currentUser.avatar_url
					? 'withAvatar'
					: ''}`}
			>
				{!this.props.readOnly ? (
					<Popup
						on="click"
						flowing
						open={this.state.popupOpen}
						onOpen={this.handlePopupOpen}
						onClose={this.handlePopupClose}
						position="bottom right"
						context={this.contextRef}
						trigger={
							this.props.internalUsers[0] ? this.props.currentUser ? (
								<UserAvatar user={this.props.currentUser} />
							) : (
								<Icon name="user outline" />
							) : (
								<Loader size="tiny" inline active />
							)
						}
					>
						<div className="UserSelectWidget--form">
							<Form size="small">
								<Header className="UserSelectWidget--form__header" as="h5">
									Select Assignee
								</Header>
								<Form.Group>
									<Form.Select
										name="assignee"
										selection
										clearable
										placeholder="Select User"
										value={this.state.selectedUser ? this.state.selectedUser : ''}
										onChange={this.handleSelectChange}
										options={
											this.props.internalUsers[0] ? (
												this.props.internalUsers.map((user) => ({
													key: user.id,
													text: user.name,
													value: user.id
												}))
											) : (
												[]
											)
										}
									/>
									<span className="formOr">or</span>
									<Form.Button type="button" onClick={() => this.selectCurrentUser()} basic>
										Assign to Me
									</Form.Button>
								</Form.Group>
							</Form>
						</div>
					</Popup>
				) : this.props.currentUser ? (
					<UserAvatar user={this.props.currentUser} />
				) : (
					<Icon name="user outline" />
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		internalUsers: state.users.internalUsers,
		loading: state.users.isLoading,
		loaded: state.users.isLoaded,
		user: state.auth.user,
		users: state.users.users
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchUsers: (internal) => dispatch(users.fetchUsers(internal))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSelectWidget);
