import React, { Component } from "react";
import "./AssignmentList.css";
import { List, Header, Icon, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import AssignmentListItem from "./AssignmentListItem/AssignmentListItem";
import ActionHeader from "../ActionHeader/ActionHeader";

class AssignmentList extends Component {
  state = {
    filters: {
      writing: true,
      editing: true,
      reEditing: true,
      posting: true,
      reWriting: true,
      reviewing: true,
      completed: true,
    },
  };

  componentDidUpdate(prevProps) {
    if (this.props.componentFilters !== prevProps.componentFilters) {
      this.setState({ filters: this.props.componentFilters });
    }
  }

  render() {
    return (
      <div className="AssignmentList">
        {(this.props.articles && this.props.articles[0]) ||
        (this.props.contentArticles && this.props.contentArticles[0]) ||
        (this.props.toPost && this.props.toPost[0]) ||
        (this.props.toEdit && this.props.toEdit[0]) ||
        (this.props.toReEdit && this.props.toReEdit[0]) ||
        (this.props.toReEdit && this.props.toReview[0]) ||
        (this.props.completedArticles && this.props.completedArticles[0]) ? (
          <React.Fragment>
            {this.props.header && (
              <ActionHeader headerText={this.props.header}/>
            )}
            {/* {!this.props.readOnly && (
              <div className="buttonFilters">
                {this.props.articles[0] && (
                  <Button
                    onClick={() =>
                      this.setState({
                        filters: {
                          ...this.state.filters,
                          writing: !this.state.filters.writing
                        }
                      })
                    }
                    size="tiny"
                    basic={!this.state.filters.writing}
                  >
                    Writing
                  </Button>
                )}
                {this.props.toPost[0] && (
                  <Button
                    onClick={() =>
                      this.setState({
                        filters: {
                          ...this.state.filters,
                          posting: !this.state.filters.posting
                        }
                      })
                    }
                    size="tiny"
                    basic={!this.state.filters.posting}
                  >
                    Posting
                  </Button>
                )}
                {this.props.toEdit[0] && (
                  <Button
                    onClick={() =>
                      this.setState({
                        filters: {
                          ...this.state.filters,
                          editing: !this.state.filters.editing
                        }
                      })
                    }
                    size="tiny"
                    basic={!this.state.filters.editing}
                  >
                    Editing
                  </Button>
                )}
                {this.props.toReview[0] && (
                  <Button
                    onClick={() =>
                      this.setState({
                        filters: {
                          ...this.state.filters,
                          editing: !this.state.filters.reviewing
                        }
                      })
                    }
                    size="tiny"
                    basic={!this.state.filters.reviewing}
                  >
                    Reviewing
                  </Button>
                )}
                {this.props.completedArticles &&
                  this.props.completedArticles[0] && (
                    <Button
                      onClick={() =>
                        this.setState({
                          filters: {
                            ...this.state.filters,
                            completed: !this.state.filters.completed
                          }
                        })
                      }
                      size="tiny"
                      basic={!this.state.filters.completed}
                    >
                      Completed
                    </Button>
                  )}
              </div>
            )} */}
            <List divided relaxed>
              {this.props.articles &&
                this.props.articles[0] &&
                this.state.filters.writing && (
                  <React.Fragment>
                    <Header as="h3">
                      <Icon name="pencil" />
                      <Header.Content>
                        Writing
                        {this.props.customerView && (
                          <Header.Subheader>
                            Content currently being written
                          </Header.Subheader>
                        )}
                      </Header.Content>
                    </Header>
                    {this.props.articles.map((article) => (
                      <AssignmentListItem
                        readOnly={this.props.readOnly}
                        selected={
                          this.props.selectedAssignment.id === article.id
                        }
                        handleClose={this.props.handleClose}
                        updateArticleStatus={this.props.updateArticleStatus}
                        handleSelect={this.props.handleSelect}
                        key={article.id}
                        article={article}
                      />
                    ))}
                  </React.Fragment>
                )}
              {this.props.toRewrite &&
                this.props.toRewrite[0] &&
                this.state.filters.reWriting && (
                  <React.Fragment>
                    <Header as="h3">
                      <Icon name="pencil square" />
                      <Header.Content>
                        Re-Writing
                        {this.props.customerView && (
                          <Header.Subheader>
                            Content currently being re-written
                          </Header.Subheader>
                        )}
                      </Header.Content>
                    </Header>
                    {this.props.toRewrite.map((article) => (
                      <AssignmentListItem
                        readOnly={this.props.readOnly}
                        selected={
                          this.props.selectedAssignment.id === article.id
                        }
                        handleClose={this.props.handleClose}
                        updateArticleStatus={this.props.updateArticleStatus}
                        handleSelect={this.props.handleSelect}
                        key={article.id}
                        article={article}
                      />
                    ))}
                  </React.Fragment>
                )}
              {this.props.queuedWork && this.props.queuedWork[0] && (
                <React.Fragment>
                  <Header as="h3">
                    <Icon name="wait" />
                    <Header.Content>
                      Queued Work
                      {this.props.customerView && (
                        <Header.Subheader>
                          Content we are currently working on
                        </Header.Subheader>
                      )}
                    </Header.Content>
                  </Header>
                  {this.props.queuedWork.map((article) => (
                    <AssignmentListItem
                      readOnly={this.props.readOnly}
                      selected={this.props.selectedAssignment.id === article.id}
                      handleClose={this.props.handleClose}
                      updateArticleStatus={this.props.updateArticleStatus}
                      handleSelect={this.props.handleSelect}
                      key={article.id}
                      article={article}
                    />
                  ))}
                </React.Fragment>
              )}
              {this.props.toPost &&
                this.props.toPost[0] &&
                this.state.filters.posting && (
                  <React.Fragment>
                    <Header as="h3">
                      <Icon name="wordpress" />
                      <Header.Content>
                        Posting
                        {this.props.customerView && (
                          <Header.Subheader>
                            Content scheduled to be posted
                          </Header.Subheader>
                        )}
                      </Header.Content>
                    </Header>
                    {this.props.toPost.map((article) => (
                      <AssignmentListItem
                        readOnly={this.props.readOnly}
                        selected={
                          this.props.selectedAssignment.id === article.id
                        }
                        handleClose={this.props.handleClose}
                        updateArticleStatus={this.props.updateArticleStatus}
                        handleSelect={this.props.handleSelect}
                        key={article.id}
                        article={article}
                      />
                    ))}
                  </React.Fragment>
                )}
              {this.props.toReEdit &&
                this.props.toReEdit[0] &&
                this.state.filters.reEditing && (
                  <React.Fragment>
                    <Header as="h3">
                      <Icon name="edit" />
                      <Header.Content>
                        Re-Editing
                        {this.props.customerView && (
                          <Header.Subheader>
                            Content currently being re-edited
                          </Header.Subheader>
                        )}
                      </Header.Content>
                    </Header>
                    {this.props.toReEdit.map((article) => (
                      <AssignmentListItem
                        selected={
                          this.props.selectedAssignment.id === article.id
                        }
                        updateArticleStatus={this.props.updateArticleStatus}
                        handleClose={this.props.handleClose}
                        handleSelect={this.props.handleSelect}
                        key={article.id}
                        article={article}
                      />
                    ))}
                  </React.Fragment>
                )}
              {this.props.toEdit &&
                this.props.toEdit[0] &&
                this.state.filters.editing && (
                  <React.Fragment>
                    <Header as="h3">
                      <Icon name="edit outline" />
                      <Header.Content>
                        Editing
                        {this.props.customerView && (
                          <Header.Subheader>
                            Content currently being edited
                          </Header.Subheader>
                        )}
                      </Header.Content>
                    </Header>
                    {this.props.toEdit.map((article) => (
                      <AssignmentListItem
                        selected={
                          this.props.selectedAssignment.id === article.id
                        }
                        updateArticleStatus={this.props.updateArticleStatus}
                        handleClose={this.props.handleClose}
                        handleSelect={this.props.handleSelect}
                        key={article.id}
                        article={article}
                      />
                    ))}
                  </React.Fragment>
                )}
              {this.props.toReview &&
                this.props.toReview[0] &&
                this.state.filters.reviewing && (
                  <React.Fragment>
                    <Header as="h3">
                      <Icon name="clipboard check" />
                      <Header.Content>
                        Final Review
                        {this.props.customerView && (
                          <Header.Subheader>
                            Content that requires your approval
                          </Header.Subheader>
                        )}
                      </Header.Content>
                    </Header>
                    {this.props.toReview.map((article) => (
                      <AssignmentListItem
                        readOnly={this.props.readOnly}
                        selected={
                          this.props.selectedAssignment.id === article.id
                        }
                        handleClose={this.props.handleClose}
                        updateArticleStatus={this.props.updateArticleStatus}
                        handleSelect={this.props.handleSelect}
                        key={article.id}
                        article={article}
                      />
                    ))}
                  </React.Fragment>
                )}
              {this.props.completedArticles &&
                this.props.completedArticles[0] &&
                this.state.filters.completed && (
                  <React.Fragment>
                    <Header as="h3">
                      <Icon name="check" />
                      <Header.Content>
                        Completed
                        {this.props.customerView && (
                          <Header.Subheader>
                            Recently completed content from the past six months
                          </Header.Subheader>
                        )}
                      </Header.Content>
                    </Header>
                    {this.props.completedArticles.map((article) => (
                      <AssignmentListItem
                        readOnly={this.props.readOnly}
                        selected={
                          this.props.selectedAssignment.id === article.id
                        }
                        handleClose={this.props.handleClose}
                        handleSelect={this.props.handleSelect}
                        completed
                        key={article.id}
                        article={article}
                      />
                    ))}
                  </React.Fragment>
                )}
            </List>
          </React.Fragment>
        ) : (
          <div className="noArticles">
            <Header>No Current Assignments</Header>
            {this.props.customerView && (
              <Link to="/content-generation/">
                <Button color="blue">View Content Articles</Button>
              </Link>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default AssignmentList;
