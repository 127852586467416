import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import './ReportingMonthTitle.css';
import { Header } from 'semantic-ui-react';

class ReportingMonthTitle extends Component {
	getPlanningMonth = (monthId) => {
		const month =
			this.props.planningMonths &&
			this.props.planningMonths[0] &&
			this.props.planningMonths.find((m) => m.id === monthId);
		return month ? `${moment(month.month, 'MM').format('MMMM')} ${month.year}` : null;
	};

	renderPreviewMonth = () => {
		const currentMonth = moment().format('M');
		const currentYear = moment().format('YYYY');
		return `${moment(currentMonth, 'MM').format('MMMM')} ${currentYear}`;
	};

	render() {
		const month = this.props.data && this.props.data.month;

		return (
			<div className="ReportingMonthTitle">
				<Header
					as={this.props.as ? this.props.as : 'h2'}
					size="large"
					content={
						this.props.previewing ? (
							this.renderPreviewMonth()
						) : this.getPlanningMonth(month) ? (
							this.getPlanningMonth(month)
						) : (
							'Could not load Planning Month'
						)
					}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		planningMonths: state.contentGeneration.planningMonths,
		isLoadingMonths: state.contentGeneration.isLoadingMonths
	};
};

export default connect(mapStateToProps, null)(ReportingMonthTitle);
