import React, { Component } from "react";
import SortableTable from "../SortableTable/SortableTable";
import { contentGeneration, organizations } from "../../store/actions";
import { connect } from "react-redux";
import ActionHeader from "../ActionHeader/ActionHeader";
import { Button, Icon } from "semantic-ui-react";
import FixedLoader from "../../components/FixedLoader/FixedLoader";
/*eslint eqeqeq: "off"*/

class SingleMonthView extends Component {
  componentDidMount() {
    this.props.fetchContentArticleMonth(this.props.year, this.props.monthInt);
    !this.props.writers[0] && this.props.fetchWriters();
    !this.props.organizations[0] && this.props.fetchOrganizations();
    !this.props.comments[0] && this.props.fetchContentComments();
    !this.props.contentStatuses[0] && this.props.fetchContentStatuses();
    this.props.contentStaff && !this.props.contentStaff[0] && this.props.fetchContentStaff();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.year !== prevProps.year ||
      this.props.monthInt !== prevProps.monthInt
    ) {
      this.props.fetchContentArticleMonth(this.props.year, this.props.monthInt);
    }
  }

  render() {
    return (
      <div>
        <ActionHeader
          headerText={`Single Month View - ${this.props.month} ${
            this.props.year
          }`}
        >
          {!this.props.isAddingContent && (
            <Button
              color="green"
              icon
              labelPosition="right"
              size="medium"
              onClick={() => this.props.handleAddingContent()}
            >
              Add Content
              <Icon name="add" />
            </Button>
          )}
        </ActionHeader>
        {!this.props.isLoading && this.props.isLoaded ? (
          <SortableTable
            selectedArticles={this.props.selectedArticles}
            handleMultiSelect={this.props.handleMultiSelect}
            selectedItem={this.props.articleToEdit}
            writers={this.props.writers}
            contentStaff={this.props.contentStaff}
            organizations={this.props.organizations}
            contentStatuses={this.props.contentStatuses}
            handleSelect={this.props.selectArticle}
            multiSelectEnabled
            originalArticles={this.props.contentArticles}
            tableData={this.props.contentArticles.filter(
              contentArticle =>
                contentArticle.planning_year_month.year == this.props.year &&
                contentArticle.planning_year_month.month == this.props.monthInt
            )}
          />
        ) : (
          <FixedLoader />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contentArticles: state.contentGeneration.contentArticles,
    writers: state.contentGeneration.writers,
    contentStatuses: state.contentGeneration.contentStatuses,
    isLoading: state.contentGeneration.isLoadingArticles,
    isLoaded: state.contentGeneration.articlesLoaded,
    isAddingArticle: state.contentGeneration.isAddingArticle,
    comments: state.contentGeneration.contentComments,
    organizations: state.organizations.organizations,
    contentStaff: state.contentGeneration.contentStaff,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchContentArticles: () => {
      dispatch(contentGeneration.fetchContentArticles());
    },
    fetchContentArticleMonth: (year, month) => {
      return dispatch(contentGeneration.fetchContentArticleMonth(year, month));
    },
    fetchContentStatuses: () => {
      return dispatch(contentGeneration.fetchContentStatuses())
    },
    fetchContentComments: () => {
      return dispatch(contentGeneration.fetchContentComments());
    },
    fetchWriters: () => {
      return dispatch(contentGeneration.fetchWriters());
    },
    fetchOrganizations: () => {
      return dispatch(organizations.fetchOrganizations());
    },
    fetchContentStaff: () => dispatch(contentGeneration.fetchContentStaff())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleMonthView);
