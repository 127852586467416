import React, { Component } from 'react';
import './ScheduleMeetingButton.css';
import { Button, Icon } from 'semantic-ui-react';
import ContentPopup from '../../components/ContentPopup/ContentPopup';

class ScheduleMeetingButton extends Component {
	state = {
		didSchedule: false,
		isScheduling: false
	};

	componentDidMount() {
		const head = document.querySelector('head');
		const calendlyScript = document.createElement('script');
		calendlyScript.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
		calendlyScript.setAttribute('id', 'calendlyScript');
		head.appendChild(calendlyScript);
	}

	componentWillUnmount() {
		const calendlyScript = document.querySelector('#calendlyScript');
		calendlyScript && calendlyScript.remove();
	}

	didCloseWindow = () => {
		this.setState({ didSchedule: true, isScheduling: false });
	};

	render() {
		return (
			<div className="ScheduleMeetingButton">
				{this.props.helperText && (
					<div className="ScheduleMeetingButton--helperText">
						<p>{this.props.helperText}</p>
					</div>
				)}
				<ContentPopup
					bodyClass="ScheduleMeetingButton--popup"
					className="ScheduleMeetingButton--popup"
					didCloseWindow={this.props.didCloseWindow}
					trigger={
						<Button icon labelPosition="right" className="ScheduleMeetingButton--button" color="blue" size="large">
							Schedule Monthly Briefing Meeting <Icon name="calendar check outline" />
						</Button>
					}
					open={this.state.isScheduling}
				>
					<iframe
						title="Calendly Scheduler"
						className="ScheduleMeetingButton--iframe"
						src="https://calendly.com/makaravine/briefing-meeting"
					/>
				</ContentPopup>
			</div>
		);
	}
}

export default ScheduleMeetingButton;
