import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  masterChecklistTemplates: {
    templates: [],
    isLoading: false,
    isLoaded: false
  },
  checklistTemplates: {
    templates: [],
    isLoading: false,
    isLoaded: false,
    isAdding: false,
    newestList: null
  },
  checklistTemplateItems: {
    errors: null,
    messages: []
  },
  checklists: {
    lists: [],
    isLoading: false,
    isLoaded: false,
    isAdding: false
  },
  checklistItems: {
    errors: null,
    messages: []
  },
  errors: null,
  messages: []
};

const addMessage = (state, action) => {
  if (action.isItem) {
    return updateObject(state, {
      checklistItems: {
        ...state.checklistItems,
        messages: state.checklistItems.messages.concat(action.message)
      },
      checklistTemplateItems: {
        ...state.checklistTemplateItems,
        messages: state.checklistTemplateItems.messages.concat(action.message)
      }
    });
  } else {
    return updateObject(state, {
      messages: state.messages.concat(action.message)
    });
  }
};

const removeMessage = (state, action) => {
  if (action.isItem) {
    let checklistMessagesList = state.checklistItems.messages.slice();
    checklistMessagesList.splice(0, 1);
    let checklistTemplateMessagesList = state.checklistTemplateItems.messages.slice();
    checklistTemplateMessagesList.splice(0, 1);
    return updateObject(state, {
      checklistItems: {
        ...state.checklistItems,
        messages: checklistMessagesList
      },
      checklistTemplateItems: {
        ...state.checklistTemplateItems,
        messages: checklistTemplateMessagesList
      }
    });
  } else {
    let messagesList = state.messages.slice();
    messagesList.splice(0, 1);
    return updateObject(state, { messages: messagesList });
  }
};

// Master Checklist Templates

const startMasterChecklistTemplatesFetch = (state, action) => {
  return updateObject(state, {
    masterChecklistTemplates: {
      isLoading: true,
      isLoaded: false
    }
  });
};
const setMasterChecklistTemplates = (state, action) => {
  return updateObject(state, {
    masterChecklistTemplates: {
      templates: action.masterChecklistTemplates[0],
      isLoading: false,
      isLoaded: true
    }
  });
};
const updateMasterChecklistTemplateSuccess = (state, action) => {
  return updateObject(state, {
    masterChecklistTemplates: {
      templates: action.masterChecklistTemplates,
      isLoading: false,
      isLoaded: true
    }
  });
};
const masterChecklistTemplatesFailure = (state, action) => {
  return updateObject(state, {
    masterChecklistTemplates: {
      errors: action.errors,
      isLoading: false,
      isLoaded: false
    }
  });
};

// Checklist Templates

const startChecklistTemplatesFetch = (state, action) => {
  return updateObject(state, {
    checklistTemplates: {
      templates: state.checklistTemplates.templates,
      isLoading: true,
      isLoaded: false,
      isAdding: false
    }
  });
};

const setChecklistTemplates = (state, action) => {
  const templates = () => {
    if (action.currentIndex) {
      return state.checklistTemplates.templates.map((template, i) =>
        i === action.currentIndex ? action.checklistTemplates : template
      );
    } else {
      return state.checklistTemplates.templates.concat(
        action.checklistTemplates
      );
    }
  };
  return updateObject(state, {
    checklistTemplates: {
      templates: templates(),
      isLoading: false,
      isLoaded: true,
      isAdding: false
    }
  });
};

const startAddingChecklistTemplate = (state, action) => {
  return updateObject(state, {
    checklistTemplates: {
      ...state.checklistTemplates,
      isLoading: false,
      isAdding: true,
      newestList: null
    }
  });
};

const addChecklistTemplateSuccess = (state, action) => {
  return updateObject(state, {
    checklistTemplates: {
      templates: state.checklistTemplates.templates.concat(
        action.checklistTemplate
      ),
      newestList: action.checklistTemplate,
      isLoading: false,
      isLoaded: state.checklistTemplates.isLoaded,
      isAdding: false
    }
  });
};

const updateChecklistTemplateSuccess = (state, action) => {
  let checklistTemplateIndex = state.checklistTemplates.templates.findIndex(
    x => x.id === action.id
  );
  return updateObject(state, {
    checklistTemplates: {
      templates: state.checklistTemplates.templates.map((templates, i) =>
        i === checklistTemplateIndex ? action.checklistTemplate : templates
      )
    }
  });
};

const deleteChecklistTemplateSuccess = (state, action) => {
  const checklistTemplateIndex = state.checklistTemplates.templates.findIndex(
    x => x.id === action.id
  );
  const templates = state.checklistTemplates.templates.slice();
  templates.splice(checklistTemplateIndex, 1);
  return updateObject(state, {
    checklistTemplates: {
      templates: templates,
      isLoading: false,
      isLoaded: state.checklistTemplates.isLoaded,
      isAdding: false
    }
  });
};
const checklistTemplatesFailure = (state, action) => {
  return updateObject(state, {
    checklistTemplates: {
      templates: state.checklistTemplates.templates,
      isLoading: false,
      isLoaded: state.checklistTemplates.isLoaded,
      isAdding: false
    },
    errors: action.errors
  });
};

// Checklist Template Items

const initialMoveChecklistTemplateItem = (state, action) => {
  const checklistTemplateIndex = state.checklistTemplates.templates.findIndex(
    x => x.id === action.checklistTemplateID
  );
  const checklistTemplate = state.checklistTemplates.templates.find(
    template => template.id === action.checklistTemplateID
  );
  const checlistTemplateItemIndex = checklistTemplate.template_items.findIndex(
    x => x.id === action.item.id
  );

  const handleOrdering = (newOrder, oldOrder, itemOrder) => {
    if (oldOrder > newOrder) {
      if (itemOrder < oldOrder && itemOrder >= newOrder) {
        return itemOrder + 1;
      } else {
        return itemOrder;
      }
    } else {
      if (itemOrder <= newOrder && itemOrder > oldOrder) {
        return itemOrder - 1;
      } else {
        return itemOrder;
      }
    }
  };

  return updateObject(state, {
    checklistTemplates: {
      ...state.checklistTemplates,
      templates: state.checklistTemplates.templates.map((template, i) =>
        i === checklistTemplateIndex
          ? {
              ...template,
              template_items: template.template_items.map((item, itemIndex) =>
                itemIndex === checlistTemplateItemIndex
                  ? {
                      ...item,
                      order: action.newOrder
                    }
                  : {
                      ...item,
                      order: handleOrdering(
                        action.newOrder,
                        action.item.order,
                        item.order
                      )
                    }
              )
            }
          : template
      )
    }
  });
};

const moveChecklistTemplateItemSuccess = (state, action) => {
  const checklistTemplateIndex = state.checklistTemplates.templates.findIndex(
    x => x.id === action.checklistTemplateID
  );
  return updateObject(state, {
    checklistTemplates: {
      ...state.checklistTemplates,
      templates: state.checklistTemplates.templates.map((template, i) =>
        i === checklistTemplateIndex
          ? {
              ...template,
              template_items: action.updatedChecklistTemplateItems
            }
          : template
      )
    }
  });
};

const initialAddChecklistTemplateItemSuccess = (state, action) => {
  const checklistTemplateIndex = state.checklistTemplates.templates.findIndex(
    x => x.id === action.checklistTemplateID
  );

  let currentItems = state.checklistTemplates.templates
    .find(template => template.id === action.checklistTemplateID)
    .template_items.slice();

  action.itemIndex !== undefined
    ? currentItems.splice(action.itemIndex + 1, 0, action.item)
    : currentItems.push(action.item);

  action.item.parent &&
    currentItems
      .find(item => item.id === action.item.parent)
      .checklist_template_item_children.push(action.item.tempID);

  return updateObject(state, {
    checklistTemplates: {
      ...state.checklistTemplates,
      templates: state.checklistTemplates.templates.map((template, i) =>
        i === checklistTemplateIndex
          ? {
              ...template,
              template_items: currentItems
            }
          : template
      )
    }
  });
};

const addChecklistTemplateItemSuccess = (state, action) => {
  const checklistTemplateIndex = state.checklistTemplates.templates.findIndex(
    x => x.id === action.checklistTemplateID
  );
  return updateObject(state, {
    checklistTemplates: {
      ...state.checklistTemplates,
      templates: state.checklistTemplates.templates.map((template, i) =>
        i === checklistTemplateIndex
          ? {
              ...template,
              template_items: action.updatedItems
            }
          : template
      )
    }
  });
};

const updateChecklistTemplateItemSuccess = (state, action) => {
  const checklistTemplateIndex = state.checklistTemplates.templates.findIndex(
    x => x.id === action.checklistTemplateID
  );
  return updateObject(state, {
    checklistTemplates: {
      ...state.checklistTemplates,
      templates: state.checklistTemplates.templates.map((template, i) =>
        i === checklistTemplateIndex
          ? {
              ...template,
              template_items: action.updatedItems
            }
          : template
      )
    }
  });
};

const deleteChecklistTemplateItemSuccess = (state, action) => {
  const checklistTemplateIndex = state.checklistTemplates.templates.findIndex(
    x => x.id === action.checklistTemplateID
  );
  return updateObject(state, {
    checklistTemplates: {
      ...state.checklistTemplates,
      templates: state.checklistTemplates.templates.map((template, i) =>
        i === checklistTemplateIndex
          ? {
              ...template,
              template_items: action.updatedItems
            }
          : template
      )
    }
  });
};
const checklistTemplateItemFailure = (state, action) => {
  return updateObject(state, {
    checklistTemplates: {
      ...state.checklistTemplates,
      errors: action.errors
    }
  });
};

// Checklists

const startChecklistsFetch = (state, action) => {
  return updateObject(state, {
    checklists: {
      lists: state.checklists.lists,
      isLoading: true,
      isLoaded: false,
      isAdding: false
    }
  });
};

const setChecklists = (state, action) => {
  const templates = () => {
    if (action.currentIndex) {
      return state.checklists.lists.map((list, i) =>
        i === action.currentIndex ? action.checklists : list
      );
    } else {
      return state.checklists.lists.concat(action.checklists);
    }
  };
  return updateObject(state, {
    checklists: {
      lists: templates(),
      isLoading: false,
      isLoaded: true,
      isAdding: false
    }
  });
};
const startAddingChecklist = (state, action) => {
  return updateObject(state, {
    checklists: {
      lists: action.checklists,
      isLoading: false,
      isLoaded: state.checklists.isLoaded,
      isAdding: true
    }
  });
};
const addChecklistSuccess = (state, action) => {
  return updateObject(state, {
    checklists: {
      lists: state.checklists.lists.concat(action.checklist),
      isLoading: false,
      isLoaded: state.checklists.isLoaded,
      isAdding: false
    }
  });
};
const updateChecklistSuccess = (state, action) => {
  const checklistIndex = state.checklists.lists.findIndex(
    x => x.id === action.id
  );
  return updateObject(state, {
    checklists: {
      lists: state.checklists.lists.map((lists, i) =>
        i === checklistIndex ? action.checklist : lists
      )
    }
  });
};

const deleteChecklistSuccess = (state, action) => {
  const checklistIndex = state.checklists.lists.findIndex(
    x => x.id === action.id
  );
  const lists = state.checklists.lists.slice();
  lists.splice(checklistIndex, 1);
  return updateObject(state, {
    checklists: {
      lists: lists,
      isLoading: false,
      isLoaded: state.checklists.isLoaded,
      isAdding: false
    }
  });
};
const checklistsFailure = (state, action) => {
  return updateObject(state, {
    checklists: {
      lists: state.checklists.lists,
      isLoading: false,
      isLoaded: state.checklists.isLoaded,
      isAdding: false
    },
    errors: action.errors
  });
};

// Checklist Items

const moveChecklistItemSuccess = (state, action) => {
  const checklistIndex = state.checklists.lists.findIndex(
    x => x.id === action.checklistID
  );
  return updateObject(state, {
    checklists: {
      ...state.checklists,
      lists: state.checklists.lists.map((list, i) =>
        i === checklistIndex
          ? {
              ...list,
              checklist_items: action.updatedChecklistItems
            }
          : list
      )
    }
  });
};

const initialAddChecklistItemSuccess = (state, action) => {
  const checklistIndex = state.checklists.lists.findIndex(
    x => x.id === action.checklistID
  );

  let currentItems = state.checklists.lists
    .find(list => list.id === action.checklistID)
    .checklist_items.slice();

  action.itemIndex !== undefined
    ? currentItems.splice(action.itemIndex + 1, 0, action.item)
    : currentItems.push(action.item);

  action.item.parent &&
    currentItems
      .find(item => item.id === action.item.parent)
      .checklist_item_children.push(action.item.tempID);

  return updateObject(state, {
    checklists: {
      ...state.checklists,
      lists: state.checklists.lists.map((list, i) =>
        i === checklistIndex
          ? {
              ...list,
              checklist_items: currentItems
            }
          : list
      )
    }
  });
};

const addChecklistItemSuccess = (state, action) => {
  const checklistIndex = state.checklists.lists.findIndex(
    x => x.id === action.checklistID
  );
  return updateObject(state, {
    checklists: {
      ...state.checklists,
      lists: state.checklists.lists.map((list, i) =>
        i === checklistIndex
          ? {
              ...list,
              checklist_items: action.updatedItems
            }
          : list
      )
    }
  });
};

const updateChecklistItemSuccess = (state, action) => {
  const checklistIndex = state.checklists.lists.findIndex(
    x => x.id === action.checklistID
  );

  return updateObject(state, {
    checklists: {
      ...state.checklists,
      lists: state.checklists.lists.map((list, i) =>
        i === checklistIndex
          ? {
              ...list,
              checklist_items: action.updatedItems
            }
          : list
      )
    }
  });
};

const deleteChecklistItemSuccess = (state, action) => {
  const checklistIndex = state.checklists.lists.findIndex(
    x => x.id === action.checklistID
  );
  return updateObject(state, {
    checklists: {
      ...state.checklists,
      lists: state.checklists.lists.map((list, i) =>
        i === checklistIndex
          ? {
              ...list,
              checklist_items: action.updatedItems
            }
          : list
      )
    }
  });
};
const checklistItemFailure = (state, action) => {
  return updateObject(state, {
    checklistItems: {
      ...state.checklistItems,
      errors: action.errors
    }
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_MESSAGE:
      return addMessage(state, action);
    case actionTypes.REMOVE_MESSAGE:
      return removeMessage(state, action);
    // Master Checklist Templates
    case actionTypes.START_MASTER_CHECKLIST_TEMPLATES_FETCH:
      return startMasterChecklistTemplatesFetch(state, action);
    case actionTypes.SET_MASTER_CHECKLIST_TEMPLATES:
      return setMasterChecklistTemplates(state, action);
    case actionTypes.UPDATE_MASTER_CHECKLIST_TEMPLATE_SUCCESS:
      return updateMasterChecklistTemplateSuccess(state, action);
    case actionTypes.MASTER_CHECKLIST_TEMPLATES_FAILURE:
      return masterChecklistTemplatesFailure(state, action);
    // Checklist Templates
    case actionTypes.START_CHECKLIST_TEMPLATES_FETCH:
      return startChecklistTemplatesFetch(state, action);
    case actionTypes.SET_CHECKLIST_TEMPLATES:
      return setChecklistTemplates(state, action);
    case actionTypes.START_ADDING_CHECKLIST_TEMPLATE:
      return startAddingChecklistTemplate(state, action);
    case actionTypes.ADD_CHECKLIST_TEMPLATE_SUCCESS:
      return addChecklistTemplateSuccess(state, action);
    case actionTypes.UPDATE_CHECKLIST_TEMPLATE_SUCCESS:
      return updateChecklistTemplateSuccess(state, action);
    case actionTypes.DELETE_CHECKLIST_TEMPLATE_SUCCESS:
      return deleteChecklistTemplateSuccess(state, action);
    case actionTypes.CHECKLIST_TEMPLATES_FAILURE:
      return checklistTemplatesFailure(state, action);
    // Checklist Template Items
    case actionTypes.INITIAL_MOVE_CHECKLIST_TEMPLATE_ITEM:
      return initialMoveChecklistTemplateItem(state, action);
    case actionTypes.MOVE_CHECKLIST_TEMPLATE_ITEM_SUCCESS:
      return moveChecklistTemplateItemSuccess(state, action);
    case actionTypes.INITIAL_ADD_CHECKLIST_TEMPLATE_ITEM:
      return initialAddChecklistTemplateItemSuccess(state, action);
    case actionTypes.ADD_CHECKLIST_TEMPLATE_ITEM_SUCCESS:
      return addChecklistTemplateItemSuccess(state, action);
    case actionTypes.UPDATE_CHECKLIST_TEMPLATE_ITEM_SUCCESS:
      return updateChecklistTemplateItemSuccess(state, action);
    case actionTypes.DELETE_CHECKLIST_TEMPLATE_ITEM_SUCCESS:
      return deleteChecklistTemplateItemSuccess(state, action);
    case actionTypes.CHECKLIST_TEMPLATE_ITEM_FAILURE:
      return checklistTemplateItemFailure(state, action);
    // Checklists
    case actionTypes.START_CHECKLISTS_FETCH:
      return startChecklistsFetch(state, action);
    case actionTypes.SET_CHECKLISTS:
      return setChecklists(state, action);
    case actionTypes.START_ADDING_CHECKLIST:
      return startAddingChecklist(state, action);
    case actionTypes.ADD_CHECKLIST_SUCCESS:
      return addChecklistSuccess(state, action);
    case actionTypes.UPDATE_CHECKLIST_SUCCESS:
      return updateChecklistSuccess(state, action);
    case actionTypes.DELETE_CHECKLIST_SUCCESS:
      return deleteChecklistSuccess(state, action);
    case actionTypes.CHECKLISTS_FAILURE:
      return checklistsFailure(state, action);
    // Checklist Items
    case actionTypes.MOVE_CHECKLIST_ITEM_SUCCESS:
      return moveChecklistItemSuccess(state, action);
    case actionTypes.INITIAL_ADD_CHECKLIST_ITEM:
      return initialAddChecklistItemSuccess(state, action);
    case actionTypes.ADD_CHECKLIST_ITEM_SUCCESS:
      return addChecklistItemSuccess(state, action);
    case actionTypes.UPDATE_CHECKLIST_ITEM_SUCCESS:
      return updateChecklistItemSuccess(state, action);
    case actionTypes.DELETE_CHECKLIST_ITEM_SUCCESS:
      return deleteChecklistItemSuccess(state, action);
    case actionTypes.CHECKLIST_ITEM_FAILURE:
      return checklistItemFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
