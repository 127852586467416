import React from "react";
import { Dropdown } from "semantic-ui-react";
import "./TableFilter.css";

const TableFilter = props => {
  return (
    <div className="TableFilter">
      <Dropdown
        name={props.filterName}
        selectOnBlur={false}
        placeholder={props.placeholder}
        clearable
        autoComplete="nope"
        search
        onChange={props.handleChange}
        options={props.options}
        selection
      />
    </div>
  );
};

export default TableFilter;
