import React, { Component } from 'react';
import './WhatConvertsChart.css';
import { BarChart, CartesianGrid, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { mockData } from './mockData';
import { alternateColorOptions } from './colorOptions';
import { Table, Icon, Header, Select } from 'semantic-ui-react';

export default class WhatConvertsChart extends Component {
	state = {
		leadType: 'all'
	};

	handleSelectChange = (event, { name, value }) => {
		this.setState({
			[name]: value
		});
	};

	findUniqueDataSources = (data) => {
		let uniqueDataSources = [];
		data.forEach(d => {
			// return d.lead_counts.map(lc => {
			// 	if (!uniqueDataSources.includes(lc.lead_type)) {
			// 		return uniqueDataSources.push(lc.lead_type)
			// 	}
			// })
			return d.lead_counts.map(lc => !uniqueDataSources.includes(lc.lead_type) && uniqueDataSources.push(lc.lead_type))
		})
		return uniqueDataSources;
	}

	render() {
		const data =
			this.props.data && !this.props.previewing
				? this.props.data.what_converts_data ? this.props.data.what_converts_data : null
				: mockData;
		const formattedData =
			data &&
			data[0] &&
			data.map((d) => ({
				...d,
				...d.lead_counts
					.filter(
						(leadCount) =>
							this.props.filters && this.props.filters[0]
								? this.props.filters.includes(leadCount.lead_type)
								: leadCount
					)
					.reduce((result, source) => {
						result[source.lead_type] = Number(
							this.state.leadType === 'quotable' ? source.quotable_count : source.value
						);
						return result;
					}, {})
			}));

		// const mostLeadTypesIndex =
		// 	data &&
		// 	data[0] &&
		// 	data.reduce(function(maxI, el, i, arr) {
		// 		return el.lead_counts.length > arr[maxI].lead_counts.length ? i : maxI;
		// 	}, 0);

		// const dataSources =
		// 	data &&
		// 	data[0] &&
		// 	data[0].lead_counts
		// 		.filter(
		// 			(leadCount) =>
		// 				this.props.filters && this.props.filters[0]
		// 					? this.props.filters.includes(leadCount.lead_type)
		// 					: leadCount
		// 		)
		// 		.sort((a, b) => b.value - a.value)
		// 		.map((leads) => leads.lead_type)

		// const dataSources =
		// 	data &&
		// 	data[mostLeadTypesIndex] &&
		// 	data[mostLeadTypesIndex].lead_counts
		// 		.filter(
		// 			(leadCount) =>
		// 				this.props.filters && this.props.filters[0]
		// 					? this.props.filters.includes(leadCount.lead_type)
		// 					: leadCount
		// 		)
		// 		.sort((a, b) => b.value - a.value)
		// 		.map((leads) => leads.lead_type)

		const dataSources = data && this.findUniqueDataSources(data);

		return (
			<div className="WhatConvertsChart">
				{data ? (
					<div className="WhatConvertsChart--container">
						<div className="WhatConvertsChart--header">
							{this.props.chartDescription && <Header as="h5" content={this.props.chartDescription} />}
							<Select
								className="WhatConvertsChart--select"
								name="leadType"
								selection
								onChange={this.handleSelectChange}
								value={this.state.leadType}
								options={[
									{
										key: 'all',
										value: 'all',
										text: 'All'
									},
									{
										key: 'quotable',
										value: 'quotable',
										text: 'Quotable'
									}
								]}
							/>
						</div>
						<div className="WhatConvertsChart--chart">
							<div className="WhatConvertsChart--chartLabel">
								<span className="WhatConvertsChart--chartLabelText">Leads</span>
							</div>
							<ResponsiveContainer id="WhatConvertsChart--responsiveContainer" width="100%" height={300}>
								<BarChart data={formattedData} barGap={3}>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="month" />
									<YAxis />
									<Tooltip cursor={{ fill: 'transparent' }} />
									<Legend />
									{dataSources.map((source, i) => (
										<Bar
											stackId="x"
											key={source}
											dataKey={source}
											fill={alternateColorOptions[i]}
											fillOpacity={0.8}
											isAnimationActive={true}
										/>
									))}
								</BarChart>
							</ResponsiveContainer>
						</div>
						{!this.props.includeTable && (
							<div className="WhatConvertsChart--table">
								<Table striped celled size="small">
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>Lead Source</Table.HeaderCell>
											{formattedData.map((d) => (
												<Table.HeaderCell key={d.month}>{d.month}</Table.HeaderCell>
											))}
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{dataSources.sort((a, b) => b - a).map((source, index) => (
											<Table.Row key={source}>
												<Table.Cell>
													<div className="WhatConvertsChart--table__source">
														<div
															style={{ background: alternateColorOptions[index] }}
															className="WhatConvertsChart--colorLabel"
														/>
														<span>{source}</span>
													</div>
												</Table.Cell>
												{formattedData.map((d, i) => {
													const foundData = d.lead_counts.find(
														(lead) => lead.lead_type === source
													);
													if (foundData) {
														return (
															foundData && (
																<Table.Cell key={i} textAlign="center">
																	{this.state.leadType === 'quotable' ? (
																		foundData.quotable_count
																	) : (
																		foundData.value
																	)}
																</Table.Cell>
															)
														);
													} else {
														return (
															<Table.Cell key={i} textAlign="center">
																0
															</Table.Cell>
														);
													}
												})}
											</Table.Row>
										))}
										<Table.Row className="WhatConvertsChart--table__total">
											<Table.Cell>
												<div className="WhatConvertsChart--table__source">
													<div className="WhatConvertsChart--chartIcon">
														<Icon name="chart bar" />
													</div>
													<span>Total</span>
												</div>
											</Table.Cell>
											{formattedData.map((d, i) => {
												return (
													<Table.Cell key={i} textAlign="center">
														{this.state.leadType === 'quotable' ? (
															d.total_quotable_count
														) : (
															d.total_count
														)}
													</Table.Cell>
												);
											})}
										</Table.Row>
									</Table.Body>
								</Table>
							</div>
						)}
					</div>
				) : (
					<div className="WhatConvertsChart-noData">
						<Header as="h2">
							<Icon name="question" />
							<Header.Content>
								There doesn't appear to be any data yet.
								<Header.Subheader>
									Please check back soon to view this analytics report.
								</Header.Subheader>
							</Header.Content>
						</Header>
					</div>
				)}
			</div>
		);
	}
}
