import React, { Component } from "react";
import { connect } from "react-redux";
import { clientOnboarding, organizations } from "../../store/actions";
import { Form, Input, Select, TextArea, Checkbox } from "semantic-ui-react";
import ErrorHandler from "../ErrorHandler/ErrorHandler";
import "./WebsiteBuildForm.css";
import { DateInput } from "semantic-ui-calendar-react";

const initialState = {
  label: "",
  organization: "",
  notes: "",
  start_date: "",
  brand_interview_link: "",
  sitemap_link: "",
  intake_link: "",
  staging_url: "",
  is_active: true
};

class WebsiteBuildForm extends Component {
  state = {
    form: initialState
  };

  componentDidMount() {
    !this.props.organizations[0] && this.props.fetchOrganizations();
    this.props.selectedBuild && this.setBuildData();
  }

  componentDidUpdate(prevProps) {
    this.props.selectedBuild !== prevProps.selectedBuild &&
      this.props.selectedBuild &&
      this.setBuildData();
  }

  setBuildData = () => {
    this.setState({
      form: {
        label: this.props.selectedBuild.label,
        organization: this.props.selectedBuild.organization.id,
        notes: this.props.selectedBuild.notes,
        is_active: this.props.selectedBuild.is_active,
        start_date: this.props.selectedBuild.start_date,
        brand_interview_link: this.props.selectedBuild.brand_interview_link,
        sitemap_link: this.props.selectedBuild.sitemap_link,
        staging_url: this.props.selectedBuild.staging_url,
        intake_link: this.props.selectedBuild.intake_link
      }
    });
  };

  clearForm = () => {
    this.setState({ form: initialState });
  };

  handleChange(e) {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value
      }
    });
  }

  handleSelectChange = (event, { name, value }) => {
    this.setState({ form: { ...this.state.form, [name]: value } });
  };

  handleSubmit = async e => {
    e.preventDefault();
    if (this.props.selectedBuild) {
      await this.props.updateWebsiteBuild(
        this.props.selectedBuild.id,
        this.state.form
      );
    } else {
      await this.props.addWebsiteBuild(this.state.form);
    }
    !this.props.errors && this.props.closeForm();
  };

  render() {
    return (
      <div className="WebsiteBuildForm">
        {this.props.errors && <ErrorHandler errors={this.props.errors} />}
        <Form
          onSubmit={this.handleSubmit}
          loading={!this.props.organizations[0]}
        >
          <Form.Field>
            <label htmlFor="label">Label</label>
            <Input
              id="label"
              name="label"
              placeholder="Label for reference"
              icon="tag"
              iconPosition="left"
              onChange={e => this.handleChange(e)}
              value={this.state.form.label ? this.state.form.label : ""}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="organization">Organization</label>
            <Select
              id="organization"
              name="organization"
              placeholder="Choose an Organization"
              selection
              onChange={this.handleSelectChange}
              options={this.props.organizations.map(org => ({
                key: org.id,
                text: org.dba_name,
                value: org.id
              }))}
              value={this.state.form.organization}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="brand_interview_link">Brand Interview Link</label>
            <Input
              id="brand_interview_link"
              name="brand_interview_link"
              placeholder="Paste Brand Interview Link Here"
              type="url"
              icon="linkify"
              iconPosition="left"
              onChange={e => this.handleChange(e)}
              value={
                this.state.form.brand_interview_link
                  ? this.state.form.brand_interview_link
                  : ""
              }
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="sitemap_link">Sitemap Link</label>
            <Input
              id="sitemap_link"
              name="sitemap_link"
              placeholder="Paste Sitemap Link Here"
              type="url"
              icon="linkify"
              iconPosition="left"
              onChange={e => this.handleChange(e)}
              value={
                this.state.form.sitemap_link ? this.state.form.sitemap_link : ""
              }
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="sitemap_link">Intake Link</label>
            <Input
              id="intake_link"
              name="intake_link"
              placeholder="Paste Intake Link Here"
              type="url"
              icon="linkify"
              iconPosition="left"
              onChange={e => this.handleChange(e)}
              value={
                this.state.form.intake_link ? this.state.form.intake_link : ""
              }
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="staging_url">Staging URL</label>
            <Input
              id="staging_url"
              name="staging_url"
              placeholder="Paste Staging URL Here"
              type="url"
              icon="linkify"
              iconPosition="left"
              onChange={e => this.handleChange(e)}
              value={
                this.state.form.staging_url ? this.state.form.staging_url : ""
              }
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="notes">Notes</label>
            <TextArea
              id="notes"
              placeholder="Any notes that should be included"
              name="notes"
              onChange={e => this.handleChange(e)}
              value={this.state.form.notes ? this.state.form.notes : ""}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="start_date">Start Date</label>
            <DateInput
              id="start_date"
              closable
              name="start_date"
              placeholder="Choose Project Start Date"
              iconPosition="left"
              dateFormat="YYYY-MM-DD"
              value={
                this.state.form.start_date ? this.state.form.start_date : ""
              }
              onChange={this.handleSelectChange}
            />
          </Form.Field>
          {this.props.selectedBuild && (
            <Form.Field>
              <Checkbox
                id="is_active"
                label="Active"
                name="is_active"
                onChange={e => this.handleChange(e)}
                checked={this.state.form.is_active}
              />
            </Form.Field>
          )}
          <Form.Button type="submit">
            {this.props.selectedBuild ? "Save" : "Add Website Build"}
          </Form.Button>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    errors: state.clientOnboarding.errors,
    messages: state.clientOnboarding.messages,
    organizations: state.organizations.organizations
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addWebsiteBuild: formData =>
      dispatch(clientOnboarding.addWebsiteBuild(formData)),
    updateWebsiteBuild: (id, formData) =>
      dispatch(clientOnboarding.updateWebsiteBuild(id, formData)),
    fetchOrganizations: () => dispatch(organizations.fetchOrganizations())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WebsiteBuildForm);
