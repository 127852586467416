import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  applications: {
    apps: [],
    isLoading: false,
    isLoaded: false,
    initializingApp: false,
    errors: null,
    messages: []
  },
  childThemes: {
    themes: [],
    isLoading: false,
    isLoaded: false,
    errors: null,
    messages: []
  }
};

const addMessage = (state, action) => {
  return updateObject(state, {
    applications: {
      ...state.applications,
      messages: state.applications.messages.concat(action.message)
    }
  });
};

const removeMessage = (state, action) => {
  let messagesList = state.applications.messages.slice();
  messagesList.splice(0, 1);
  return updateObject(state, {
    applications: {
      ...state.applications,
      messages: messagesList
    }
  });
};

const setApps = (state, action) => {
  return updateObject(state, {
    applications: {
      apps: action.applications,
      isLoading: false,
      isLoaded: true
    }
  });
};

const startAppsFetch = (state, action) => {
  return updateObject(state, {
    applications: {
      ...state.applications,
      isLoading: true,
      isLoaded: false
    }
  });
};
const fetchAppsFailure = (state, action) => {
  return updateObject(state, {
    applications: {
      ...state.applications,
      errors: action.errors,
      isLoading: false,
      isLoaded: false
    }
  });
};
const updateAppSuccess = (state, action) => {
  let appIndex = state.applications.apps.findIndex(x => x.id === action.id);
  return updateObject(state, {
    applications: {
      ...state.applications,
      errors: null,
      apps: state.applications.apps.map((app, i) =>
        i === appIndex ? action.app : app
      )
    }
  });
};
const appFailure = (state, action) => {
  return updateObject(state, {
    applications: {
      ...state.applications,
      errors: action.errors
    }
  });
};

const startAppInitialization = (state, action) => {
  return updateObject(state, {
    applications: {
      ...state.applications,
      initializingApp: true
    }
  });
};
const appInitializationSuccess = (state, action) => {
  return updateObject(state, {
    applications: {
      ...state.applications,
      initializingApp: false,
      apps: state.applications.apps.concat(action.app)
    }
  });
};
const appInitializationFailure = (state, action) => {
  return updateObject(state, {
    applications: {
      ...state.applications,
      initializingApp: false,
      errors: action.errors
    }
  });
};

const startAppBackup = (state, action) => {
  let appIndex = state.applications.apps.findIndex(x => x.id === action.id);
  return updateObject(state, {
    applications: {
      ...state.applications,
      apps: state.applications.apps.map((app, i) =>
        i === appIndex
          ? {
              ...app,
              backupInProgress: true
            }
          : app
      )
    }
  });
};

const appBackupSuccess = (state, action) => {
  let appIndex = state.applications.apps.findIndex(x => x.id === action.id);
  return updateObject(state, {
    applications: {
      ...state.applications,
      apps: state.applications.apps.map((app, i) =>
        i === appIndex
          ? {
              ...app,
              backupInProgress: false
            }
          : app
      )
    }
  });
};
const appBackupFailure = (state, action) => {
  let appIndex = state.applications.apps.findIndex(x => x.id === action.id);
  return updateObject(state, {
    applications: {
      ...state.applications,
      apps: state.applications.apps.map((app, i) =>
        i === appIndex
          ? {
              ...app,
              backupInProgress: false
            }
          : app
      )
    }
  });
};

const beginAppStop = (state, action) => {
  let appIndex = state.applications.apps.findIndex(x => x.id === action.id);
  return updateObject(state, {
    applications: {
      ...state.applications,
      errors: null,
      apps: state.applications.apps.map((app, i) =>
        i === appIndex
          ? {
              ...app,
              appStopping: true
            }
          : app
      )
    }
  });
};
const appStopSuccess = (state, action) => {
  let appIndex = state.applications.apps.findIndex(x => x.id === action.id);
  return updateObject(state, {
    applications: {
      ...state.applications,
      errors: null,
      apps: state.applications.apps.map((app, i) =>
        i === appIndex
          ? {
              ...app,
              appStopping: false,
              webserverStatus: false,
              wordpressStatus: false,
              siteStatus: false
            }
          : app
      )
    }
  });
};
const appStopFailure = (state, action) => {
  let appIndex = state.applications.apps.findIndex(x => x.id === action.id);
  return updateObject(state, {
    applications: {
      ...state.applications,
      errors: action.errors,
      apps: state.applications.apps.map((app, i) =>
        i === appIndex
          ? {
              ...app,
              appStopping: false
            }
          : app
      )
    }
  });
};

const beginAppStart = (state, action) => {
  let appIndex = state.applications.apps.findIndex(x => x.id === action.id);
  return updateObject(state, {
    applications: {
      ...state.applications,
      errors: null,
      apps: state.applications.apps.map((app, i) =>
        i === appIndex
          ? {
              ...app,
              appStarting: true
            }
          : app
      )
    }
  });
};
const appStartSuccess = (state, action) => {
  let appIndex = state.applications.apps.findIndex(x => x.id === action.id);
  return updateObject(state, {
    applications: {
      ...state.applications,
      errors: null,
      apps: state.applications.apps.map((app, i) =>
        i === appIndex
          ? {
              ...app,
              appStarting: false,
              webserverStatus: true,
              wordpressStatus: true,
              websiteStatus: true
            }
          : app
      )
    }
  });
};
const appStartFailure = (state, action) => {
  let appIndex = state.applications.apps.findIndex(x => x.id === action.id);
  return updateObject(state, {
    applications: {
      ...state.applications,
      errors: action.errors,
      apps: state.applications.apps.map((app, i) =>
        i === appIndex
          ? {
              ...app,
              appStarting: false
            }
          : app
      )
    }
  });
};

const beginAppStatusCheck = (state, action) => {
  let appIndex = state.applications.apps.findIndex(x => x.id === action.id);
  return updateObject(state, {
    applications: {
      ...state.applications,
      errors: null,
      apps: state.applications.apps.map((app, i) =>
        i === appIndex
          ? {
              ...app,
              statusCheckInProgress: true
            }
          : app
      )
    }
  });
};
const appStatusCheckSuccess = (state, action) => {
  let appIndex = state.applications.apps.findIndex(x => x.id === action.id);
  return updateObject(state, {
    applications: {
      ...state.applications,
      errors: null,
      apps: state.applications.apps.map((app, i) =>
        i === appIndex
          ? {
              ...app,
              statusCheckInProgress: false,
              webserverStatus: action.webserverStatus,
              wordpressStatus: action.wordpressStatus,
              websiteStatus: action.websiteStatus
            }
          : app
      )
    }
  });
};
const appStatusCheckFailure = (state, action) => {
  let appIndex = state.applications.apps.findIndex(x => x.id === action.id);
  return updateObject(state, {
    applications: {
      ...state.applications,
      errors: action.errors,
      apps: state.applications.apps.map((app, i) =>
        i === appIndex
          ? {
              ...app,
              statusCheckInProgress: false
            }
          : app
      )
    }
  });
};

const getS3DownloadSuccess = (state, action) => {
  let appIndex = state.applications.apps.findIndex(x => x.id === action.appID);
  return updateObject(state, {
    applications: {
      ...state.applications,
      errors: null,
      apps: state.applications.apps.map((app, i) =>
        i === appIndex
          ? {
              ...app,
              codeBackups:
                action.backupType === "code"
                  ? app.codeBackups.map(backup =>
                      backup.Key === action.backupKey
                        ? {
                            ...backup,
                            downloadLink: action.link
                          }
                        : backup
                    )
                  : app.codeBackups,
              dataBackups:
                action.backupType === "database"
                  ? app.dataBackups.map(backup =>
                      backup.Key === action.backupKey
                        ? {
                            ...backup,
                            downloadLink: action.link
                          }
                        : backup
                    )
                  : app.dataBackups
            }
          : app
      )
    }
  });
};

const getS3DownloadFailure = (state, action) => {
  return updateObject(state, {
    applications: {
      ...state.applications,
      errors: action.errors,
    }
  })
}

const startDataBackupFetch = (state, action) => {
  let appIndex = state.applications.apps.findIndex(x => x.id === action.id);
  return updateObject(state, {
    applications: {
      ...state.applications,
      errors: null,
      apps: state.applications.apps.map((app, i) =>
        i === appIndex
          ? {
              ...app,
              fetchingDataBackups: true
            }
          : app
      )
    }
  });
};
const appDataBackupFetchSuccess = (state, action) => {
  let appIndex = state.applications.apps.findIndex(x => x.id === action.id);
  return updateObject(state, {
    applications: {
      ...state.applications,
      errors: null,
      apps: state.applications.apps.map((app, i) =>
        i === appIndex
          ? {
              ...app,
              fetchingDataBackups: false,
              dataBackups: action.backups
            }
          : app
      )
    }
  });
};
const appDataBackupFetchFailure = (state, action) => {
  let appIndex = state.applications.apps.findIndex(x => x.id === action.id);
  return updateObject(state, {
    applications: {
      ...state.applications,
      errors: action.errors,
      apps: state.applications.apps.map((app, i) =>
        i === appIndex
          ? {
              ...app,
              fetchingDataBackups: false
            }
          : app
      )
    }
  });
};

const startCodeBackupFetch = (state, action) => {
  let appIndex = state.applications.apps.findIndex(x => x.id === action.id);
  return updateObject(state, {
    applications: {
      ...state.applications,
      errors: null,
      apps: state.applications.apps.map((app, i) =>
        i === appIndex
          ? {
              ...app,
              fetchingCodeBackups: true
            }
          : app
      )
    }
  });
};
const appCodeBackupFetchSuccess = (state, action) => {
  let appIndex = state.applications.apps.findIndex(x => x.id === action.id);
  return updateObject(state, {
    applications: {
      ...state.applications,
      errors: null,
      apps: state.applications.apps.map((app, i) =>
        i === appIndex
          ? {
              ...app,
              fetchingCodeBackups: false,
              codeBackups: action.backups
            }
          : app
      )
    }
  });
};
const appCodeBackupFetchFailure = (state, action) => {
  let appIndex = state.applications.apps.findIndex(x => x.id === action.id);
  return updateObject(state, {
    applications: {
      ...state.applications,
      errors: action.errors,
      apps: state.applications.apps.map((app, i) =>
        i === appIndex
          ? {
              ...app,
              fetchingCodeBackups: false
            }
          : app
      )
    }
  });
};

const startDeleteApplication = (state, action) => {
  let appIndex = state.applications.apps.findIndex(x => x.id === action.id);
  return updateObject(state, {
    applications: {
      ...state.applications,
      apps: state.applications.apps.map((app, i) =>
        i === appIndex
          ? {
              ...app,
              deleteInProgress: true
            }
          : app
      )
    }
  });
};

const deleteApplicationSuccess = (state, action) => {
  let appIndex = state.applications.apps.findIndex(x => x.id === action.id);
  return updateObject(state, {
    applications: {
      ...state.applications,
      errors: action.errors,
      apps: state.applications.apps.map((app, i) =>
        i === appIndex
          ? {
              ...action.app,
              deleteInProgress: false
            }
          : app
      )
    }
  });
};

// Child Themes

const startThemeFetch = (state, action) => {
  return updateObject(state, {
    childThemes: {
      ...state.childThemes,
      isLoading: true,
      isLoaded: false
    }
  });
};
const setThemes = (state, action) => {
  return updateObject(state, {
    childThemes: {
      ...state.childThemes,
      themes: action.themes,
      isLoading: false,
      isLoaded: true,
      errors: null
    }
  });
};
const themeFailure = (state, action) => {
  return updateObject(state, {
    childThemes: {
      ...state.childThemes,
      errors: action.errors
    }
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_MESSAGE:
      return addMessage(state, action);
    case actionTypes.REMOVE_MESSAGE:
      return removeMessage(state, action);
    case actionTypes.SET_APPS:
      return setApps(state, action);
    case actionTypes.START_APPS_FETCH:
      return startAppsFetch(state, action);
    case actionTypes.FETCH_APPS_FAILURE:
      return fetchAppsFailure(state, action);
    case actionTypes.UPDATE_APP_SUCCESS:
      return updateAppSuccess(state, action);
    case actionTypes.APP_FAILURE:
      return appFailure(state, action);
    case actionTypes.START_APP_INITIALIZATION:
      return startAppInitialization(state, action);
    case actionTypes.APP_INITIALIZATION_SUCCESS:
      return appInitializationSuccess(state, action);
    case actionTypes.APP_INITIALIZATION_FAILURE:
      return appInitializationFailure(state, action);
    case actionTypes.START_APP_BACKUP:
      return startAppBackup(state, action);
    case actionTypes.APP_BACKUP_SUCCESS:
      return appBackupSuccess(state, action);
    case actionTypes.APP_BACKUP_FAILURE:
      return appBackupFailure(state, action);
    case actionTypes.BEGIN_APP_STOP:
      return beginAppStop(state, action);
    case actionTypes.APP_STOP_SUCCESS:
      return appStopSuccess(state, action);
    case actionTypes.APP_STOP_FAILURE:
      return appStopFailure(state, action);
    case actionTypes.BEGIN_APP_START:
      return beginAppStart(state, action);
    case actionTypes.APP_START_SUCCESS:
      return appStartSuccess(state, action);
    case actionTypes.APP_START_FAILURE:
      return appStartFailure(state, action);
    case actionTypes.BEGIN_APP_STATUS_CHECK:
      return beginAppStatusCheck(state, action);
    case actionTypes.APP_STATUS_CHECK_SUCCESS:
      return appStatusCheckSuccess(state, action);
    case actionTypes.APP_STATUS_CHECK_FAILURE:
      return appStatusCheckFailure(state, action);
    case actionTypes.GET_S3_DOWNLOAD_SUCCESS:
      return getS3DownloadSuccess(state, action);
    case actionTypes.GET_S3_DOWNLOAD_FAILURE:
      return getS3DownloadFailure(state, action);
    case actionTypes.BEGIN_APP_DATA_BACKUP_FETCH:
      return startDataBackupFetch(state, action);
    case actionTypes.APP_DATA_BACKUP_FETCH_SUCCESS:
      return appDataBackupFetchSuccess(state, action);
    case actionTypes.APP_DATA_BACKUP_FETCH_FAILURE:
      return appDataBackupFetchFailure(state, action);
    case actionTypes.BEGIN_APP_CODE_BACKUP_FETCH:
      return startCodeBackupFetch(state, action);
    case actionTypes.APP_CODE_BACKUP_FETCH_SUCCESS:
      return appCodeBackupFetchSuccess(state, action);
    case actionTypes.APP_CODE_BACKUP_FETCH_FAILURE:
      return appCodeBackupFetchFailure(state, action);
    case actionTypes.START_DELETE_APPLICATION:
      return startDeleteApplication(state, action);
    case actionTypes.DELETE_APPLICATION_SUCCESS:
      return deleteApplicationSuccess(state, action);
    case actionTypes.START_THEME_FETCH:
      return startThemeFetch(state, action);
    case actionTypes.SET_THEMES:
      return setThemes(state, action);
    case actionTypes.THEME_FAILURE:
      return themeFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
