import React from "react";
import { Header, Icon } from "semantic-ui-react";
import "./FormHeader.css";

const FormHeader = props => {
  return (
    <div className="FormHeader">
      <Header as={props.size ? props.size : "h4"}>
      {props.icon && (
        <Icon color={props.iconColor ? props.iconColor : "grey"}
        name={props.icon} />
      )}
      <Header.Content>{props.headerText}</Header.Content>
      </Header>
      <div className="FormHeaderActions">{props.children}</div>
    </div>
  );
};

export default FormHeader;
