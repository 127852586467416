export const mockData = [
	{
		month: 'Aug 2019',
		month_year: 2019,
		lead_counts: [
			{ value: 17, lead_type: 'Phone Call', quotable_count: 0 },
			{ value: 22, lead_type: 'Web Form', quotable_count: 0 },
			{ value: 2, lead_type: 'Text Message', quotable_count: 0 }
		],
		month_month: 8,
		total_count: 41,
		total_quotable_count: 0
	},
	{
		month: 'Sep 2019',
		month_year: 2019,
		lead_counts: [
			{ value: 14, lead_type: 'Web Form', quotable_count: 0 },
			{ value: 19, lead_type: 'Phone Call', quotable_count: 0 }
		],
		month_month: 9,
		total_count: 33,
		total_quotable_count: 0
	},
	{
		month: 'Oct 2019',
		month_year: 2019,
		lead_counts: [
			{ value: 8, lead_type: 'Web Form', quotable_count: 8 },
			{ value: 12, lead_type: 'Phone Call', quotable_count: 5 },
			{ value: 1, lead_type: 'Text Message', quotable_count: 0 }
		],
		month_month: 10,
		total_count: 21,
		total_quotable_count: 13
	},
	{
		month: 'Nov 2019',
		month_year: 2019,
		lead_counts: [
			{ value: 19, lead_type: 'Web Form', quotable_count: 0 },
			{ value: 20, lead_type: 'Phone Call', quotable_count: 0 }
		],
		month_month: 11,
		total_count: 39,
		total_quotable_count: 0
	},
	{
		month: 'Dec 2019',
		month_year: 2019,
		lead_counts: [
			{ value: 46, lead_type: 'Web Form', quotable_count: 0 },
			{ value: 22, lead_type: 'Phone Call', quotable_count: 0 },
			{ value: 1, lead_type: 'Text Message', quotable_count: 0 }
		],
		month_month: 12,
		total_count: 69,
		total_quotable_count: 0
	},
	{
		month: 'Jan 2020',
		month_year: 2020,
		lead_counts: [
			{ value: 19, lead_type: 'Phone Call', quotable_count: 0 },
			{ value: 21, lead_type: 'Web Form', quotable_count: 0 }
		],
		month_month: 1,
		total_count: 40,
		total_quotable_count: 0
	}
];
