import React, { Component } from "react";
import "./KeywordView.css";
import { Button, Icon } from "semantic-ui-react";
import ActionHeader from "../ActionHeader/ActionHeader";
import KeywordOrganizationSelect from "../../components/KeywordOrganizationSelect/KeywordOrganizationSelect";
import KeywordTable from "../KeywordTable/KeywordTable";
import FixedLoader from "../FixedLoader/FixedLoader";

class KeywordView extends Component {
  componentDidMount() {
    !this.props.keywords[0] && this.props.foundOrg && this.props.fetchKeywords(this.props.foundOrg.id);
    !this.props.organizations[0] && this.props.fetchOrganizations();
  }

  componentDidUpdate(prevProps) {
    if (this.props.foundOrg && prevProps.foundOrg && this.props.foundOrg.id !== prevProps.foundOrg.id) {
      this.props.fetchKeywords(this.props.foundOrg.id);
    } else {
      if (!prevProps.foundOrg && this.props.foundOrg && this.props.foundOrg.id) {
         if (!this.props.isLoading) {
          this.props.fetchKeywords(this.props.foundOrg.id);
         }
      }
    }
  }

  showAddButton = () => {
    if (!this.props.isAddingKeyword && !this.props.keywordToEdit) {
      return true;
    } else {
      return false;
    }
  };

  getOrganization = () => {
    if (this.props.organizations[0]) {
      let organization = this.props.organizations.find(
        org => org.slug === this.props.match.params.organizationSlug
      );
      return organization.dba_name;
    } else {
      return "";
    }
  };

  render() {
    return (
      <div className="KeywordView">
        <ActionHeader
          headerText={`Keyword Research - ${this.getOrganization()}`}
        >
          <KeywordOrganizationSelect
            {...this.props}
            placeholder="Select Organization"
          />
          {!this.props.isAddingKeyword && (
            <Button
              icon
              labelPosition="right"
              color="green"
              onClick={() => this.props.handleAddingKeyword()}
            >
              Add Keyword
              <Icon name="plus" />
            </Button>
          )}
        </ActionHeader>
        <div className="KeywordViewContent">
          {!this.props.isLoading && this.props.isLoaded ? (
            <KeywordTable
              planningMonths={this.props.planningMonths}
              handleSelect={this.props.selectKeyword}
              tableData={this.props.keywords}
            />
          ) : (
            <FixedLoader />
          )}
        </div>
      </div>
    );
  }
}

export default KeywordView;
