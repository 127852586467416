import React, { Component } from 'react';
import './PresentationCreatorComponent.css';
import { Header, Segment, Form, Button, Icon, Message } from 'semantic-ui-react';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import PresentationCreatorComponentField from './PresentationCreatorComponentField/PresentationCreatorComponentField';

export default class PresentationCreatorComponent extends Component {
	state = {
		showOptional: false,
		isValid: true
	};

	componentDidMount() {
		this.handleCheckValidity();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.componentData !== prevProps.componentData) {
			this.handleCheckValidity();
		}
	}

	handleChange = (event, results) => {
		const { name, value, type, checked } = results;
		checked === 'checkbox' && console.log('event.target.checked is: ', checked)
		this.props.handleComponentDataChange(
			name,
			type === 'checkbox' ? checked : value,
			this.props.index
		);
	};

	// handleChange(event) {
	// 	console.log('event.target.name is: ', event.target.name)
	// 	console.log('event.target.value is: ', event.target.value)
	// 	console.log('event.target.type is: ', event.target.type)
	// 	event.target.type === 'checkbox' && console.log('event.target.checked is: ', event.target.checked)
	// 	this.props.handleComponentDataChange(
	// 		event.target.name,
	// 		event.target.type === 'checkbox' ? event.target.checked : event.target.value,
	// 		this.props.index
	// 	);
	// }

	handleSelectChange = (event, { name, value }) => {
		this.props.handleComponentDataChange(name, value, this.props.index);
	};

	handleOptionalShow = () => {
		this.setState({
			showOptional: !this.state.showOptional
		});
	};

	handleCheckValidity = () => {
		this.props.component.attribute_fields.filter((field) => field.required).forEach((field) => {
			const correspondingField = this.props.componentData.fields.find(
				(dataField) => dataField.name === field.attributeName
			);

			if (correspondingField !== undefined) {
				if (correspondingField.data === null || correspondingField.data === '') {
					return this.setState({
						isValid: false
					});
				} else {
					this.setState({
						isValid: true
					});
				}
			}
		});
	};

	render() {
		const component = this.props.component;
		const hasOptionalFields =
			component.attribute_fields && component.attribute_fields.find((f) => f.required === false);
		return this.props.component ? (
			<div className="PresentationCreatorComponent">
				<Header as="h5" attached="top" className={`${this.state.isValid ? 'valid' : 'invalid'}`}>
					<div className="PresentationCreatorComponent--header">
						<span>{component.verbose_name}</span>
						<div className="PresentationCreatorComponent--header__action">
							{!this.state.isValid && (
								<Message negative size="mini">
									Please complete required fields.
								</Message>
							)}
							<ConfirmationModal
								handleConfirm={() => this.props.handleDeleteComponent(this.props.index)}
								buttonIcon="minus"
								buttonSize="mini"
								color="red"
								compact
								headerText="Delete Component?"
								buttonClass="PresentationCreatorComponent--cancelButton"
								bodyText="Are you sure you want to delete this Component?"
							/>
						</div>
					</div>
				</Header>
				{component.attribute_fields && (
					<Segment attached>
						<Form onSubmit={(e) => e.preventDefault()} size="small">
							<div className="PresentationCreatorComponent--requiredFields">
								{component.attribute_fields
									.filter((field) => !field.derivedFrom && field.required)
									.map((field, i) => {
										return (
											<PresentationCreatorComponentField
												key={i}
												field={field}
												componentData={this.props.componentData}
												handleChange={this.handleChange}
												handleSelectChange={this.handleSelectChange}
											/>
										);
									})}
							</div>
							<div className="PresentationCreatorComponent--optionalFields">
								<div className="PresentationCreatorComponent--optionalFieldsActions">
									{this.props.handleComponentMoveUp && (
										<div className="PresentationCreatorComponent--moveAction">
											<Button
												onClick={() => this.props.handleComponentMoveUp(this.props.index)}
												icon
												size="mini"
												color="teal"
												basic
											>
												<Icon name="arrow alternate circle up" />
											</Button>
										</div>
									)}
									{this.props.handleComponentMoveDown && (
										<div className="PresentationCreatorComponent--moveAction">
											<Button
												onClick={() => this.props.handleComponentMoveDown(this.props.index)}
												icon
												size="mini"
												color="teal"
												basic
											>
												<Icon name="arrow alternate circle down" />
											</Button>
										</div>
									)}
									{hasOptionalFields && (
										<Button size="mini" onClick={() => this.handleOptionalShow()}>
											{this.state.showOptional ? 'Hide' : 'Show'} Optional Fields
										</Button>
									)}
								</div>
								{this.state.showOptional && (
									<div className="PresentationCreatorComponent--optionaFieldsOutput">
										{component.attribute_fields
											.filter((field) => !field.derivedFrom && !field.required)
											.map((field, i) => {
												return (
													<PresentationCreatorComponentField
														key={i}
														field={field}
														componentData={this.props.componentData}
														handleChange={this.handleChange}
														handleSelectChange={this.handleSelectChange}
													/>
												);
											})}
									</div>
								)}
							</div>
						</Form>
					</Segment>
				)}
			</div>
		) : (
			<Segment>
				<h5>Component Not Found</h5>
			</Segment>
		);
	}
}
