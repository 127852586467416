import React, { Component } from "react";
import "./WebsiteBuilds.css";
import { Button, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { clientOnboarding } from "../../../store/actions";
import WithSidebar from "../../../hoc/WithSidebar/WithSidebar";
import WebsiteBuildForm from "../../../components/WebsiteBuildForm/WebsiteBuildForm";
import BasicModal from "../../../components/BasicModal/BasicModal";
import ActionHeader from "../../../components/ActionHeader/ActionHeader";
import WebsiteBuildList from "../../../components/WebsiteBuildList/WebsiteBuildList";
import FixedLoader from "../../../components/FixedLoader/FixedLoader";
import ChecklistMessage from "../../../components/Checklist/ChecklistMessage/ChecklistMessage";
import Checklist from "../../../components/Checklist/Checklist";

class WebsiteBuilds extends Component {
  state = {
    isAddingBuild: false,
    editingBuild: null,
    viewingBuild: null,
    selectedList: null,
    isViewingArchives: false
  };

  componentDidMount() {
    !this.props.websiteBuilds[0] && this.props.fetchWebsiteBuilds();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.selectedList &&
      prevProps.checklists !== this.props.checklists
    ) {
      let updatedList = this.props.checklists.find(
        checklist => checklist.id === this.state.selectedList.id
      );
      this.setState({ selectedList: updatedList });
    }
  }

  handleAddBuild = () => {
    this.setState({
      isAddingBuild: true,
      editingBuild: null,
      isViewingArchives: false
    });
  };

  handleEditSelect = build => {
    this.setState({ editingBuild: build });
  };

  cancelAction = () => {
    this.setState({
      editingBuild: null,
      isAddingBuild: false,
      viewingBuild: null,
      selectedList: null
    });
  };

  shouldModalShow = () => {
    if (this.state.editingBuild || this.state.isAddingBuild) {
      return true;
    } else {
      return false;
    }
  };

  handleListSelect = list => {
    return this.setState({ selectedList: list });
  };

  handleArchiveShow = () => {
    !this.props.archivedBuilds[0] && this.props.fetchArchivedWebsiteBuilds();
    this.setState({ isViewingArchives: true });
  };

  handleArchiveHide = () => {
    this.setState({ isViewingArchives: false });
  };

  render() {
    return (
      <div className="WebsiteBuilds">
        <WithSidebar
          leftWidth={8}
          rightWidth={8}
          isShowing={this.state.selectedList}
          sidebarComponent={
            <div className="WebsiteBuilds--sidebar">
              <ActionHeader
                size="h4"
                headerText={
                  this.state.selectedList && this.state.selectedList.name
                }
                icon="list alternate outline"
              >
                {this.props.checklistMessages[0] && (
                  <ChecklistMessage>
                    {this.props.checklistMessages}
                  </ChecklistMessage>
                )}
                <Button
                  size="tiny"
                  basic
                  compact
                  onClick={() => this.cancelAction()}
                >
                  Close
                </Button>
              </ActionHeader>
              <Checklist
                list={this.state.selectedList}
                items={
                  this.state.selectedList &&
                  this.state.selectedList.checklist_items
                }
              />
            </div>
          }
        >
          <div className="WebsiteBuildsMain">
            <ActionHeader headerText="Website Builds">
            <Button
                icon
                labelPosition="right"
                size="tiny"
                color="blue"
                onClick={() => this.state.isViewingArchives ? this.handleArchiveHide() : this.handleArchiveShow()}
              >
                {`View ${this.state.isViewingArchives ? "Active Builds" : "Archives"}`}
                <Icon inverted name={`${this.state.isViewingArchives ? "wrench" : "box"}`} />
              </Button>
              <Button
                icon
                labelPosition="right"
                size="tiny"
                color="green"
                onClick={() => this.handleAddBuild()}
              >
                Add New Build
                <Icon inverted name="code" />
              </Button>
            </ActionHeader>
            {this.props.isLoaded ? (
              <React.Fragment>
                {this.props.isAdding && (
                  <div className="WebsiteBuilds--addLoader">
                    <span className="WebsiteBuilds--addLoader__content">
                      Creating Website Build{" "}
                      <Icon className="bounceLoader" name="code" />
                    </span>
                  </div>
                )}
                {this.state.isViewingArchives ? (
                  <WebsiteBuildList
                    builds={this.props.archivedBuilds}
                    archives
                    loading={this.props.isLoadingArchives}
                    loaded={this.props.archivesLoaded}
                    selectForEdit={this.handleEditSelect}
                    handleListSelect={this.handleListSelect}
                  />
                ) : (
                  <WebsiteBuildList
                    builds={this.props.websiteBuilds}
                    loading={this.props.isLoading}
                    loaded={this.props.isLoaded}
                    selectForEdit={this.handleEditSelect}
                    handleListSelect={this.handleListSelect}
                  />
                )}
              </React.Fragment>
            ) : (
              <FixedLoader text="Loading Website Builds" />
            )}
          </div>
        </WithSidebar>
        <BasicModal
          inverted
          isOpen={this.shouldModalShow()}
          handleClose={() => this.cancelAction()}
          title={
            this.state.editingBuild
              ? "Edit Website Build"
              : "Add New Website Build"
          }
          size="tiny"
          icon="code"
        >
          <WebsiteBuildForm
            closeForm={this.cancelAction}
            isAddingBuild={this.state.isAddingBuild}
            selectedBuild={this.state.editingBuild}
          />
        </BasicModal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    websiteBuilds: state.clientOnboarding.websiteBuilds.builds,
    archivedBuilds: state.clientOnboarding.archivedBuilds.builds,
    checklists: state.checklists.checklists.lists,
    checklistMessages: state.checklists.messages,
    checklistsLoaded: state.checklists.checklists.isLoaded,
    checklistsLoading: state.checklists.checklists.isLoading,
    isLoading: state.clientOnboarding.websiteBuilds.isLoading,
    isLoaded: state.clientOnboarding.websiteBuilds.isLoaded,
    isLoadingArchives: state.clientOnboarding.archivedBuilds.isLoading,
    archivesLoaded: state.clientOnboarding.archivedBuilds.isLoaded,
    isAdding: state.clientOnboarding.websiteBuilds.isAdding,
    errors: state.clientOnboarding.errors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchWebsiteBuilds: () => dispatch(clientOnboarding.fetchWebsiteBuilds()),
    fetchArchivedWebsiteBuilds: () =>
      dispatch(clientOnboarding.fetchArchivedWebsiteBuilds())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WebsiteBuilds);
