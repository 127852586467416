import React from "react";
import "./PostingOverviewDetailsBlock.css";
import { Header, Placeholder, Table } from "semantic-ui-react";
import moment from "moment";

const renderLoadingRows = (count) => {
  return [...Array(count)].map((e, i) => (
    <Table.Row key={i}>
      <Table.Cell>
        <Placeholder>
          <Placeholder.Paragraph>
            <Placeholder.Line length="long" />
            <Placeholder.Line length="short" />
          </Placeholder.Paragraph>
        </Placeholder>
      </Table.Cell>
      <Table.Cell>
        <Placeholder>
          <Placeholder.Paragraph>
            <Placeholder.Line length="medium" />
          </Placeholder.Paragraph>
        </Placeholder>
      </Table.Cell>
    </Table.Row>
  ));
};

const PostingOverviewDetailsBlock = ({
  posts,
  title,
  finalReview,
  isLoading,
}) => {
  return (
    <section className="PostingOverviewDetailsBlock">
      <header className="PostingOverviewDetailsBlock--header">
        <Header as="h4" className="PostingOverviewDetailsBlock--header">
          {title}
        </Header>
      </header>
      <div className="PostingOverviewDetailsBlock--content">
        {posts && posts[0] ? (
          <Table basic size="small" compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Title</Table.HeaderCell>
                <Table.HeaderCell>
                  {finalReview ? "Scheduled Date" : "Date / Time"}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {posts.map((post, i) => (
                <Table.Row key={i}>
                  <Table.Cell>
                    {post.title &&
                      (!finalReview ? (
                        <a
                          href={post.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="PostingOverviewDetailsBlock--link"
                        >
                          {post.title.rendered}
                        </a>
                      ) : (
                        post.title
                      ))}
                  </Table.Cell>
                  <Table.Cell>
                    {finalReview
                      ? post.duedate_schedulepost
                        ? moment(post.duedate_schedulepost).format("MMM Do, YYYY")
                        : "-"
                      : moment(post.date).format("MMM Do, h:mm a")}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        ) : isLoading ? (
          <Table basic size="small" compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <Placeholder>
                    <Placeholder.Paragraph>
                      <Placeholder.Line length="short" />
                    </Placeholder.Paragraph>
                  </Placeholder>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Placeholder>
                    <Placeholder.Paragraph>
                      <Placeholder.Line length="short" />
                    </Placeholder.Paragraph>
                  </Placeholder>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>{renderLoadingRows(4)}</Table.Body>
          </Table>
        ) : (
          <p className="PostingOverviewDetailsBlock--noData">No Posts Found</p>
        )}
      </div>
    </section>
  );
};

export default PostingOverviewDetailsBlock;
