import React, { Component } from "react";
import OrganizationList from "../OrganizationList/OrganizationList";
import { Divider, Button, Icon } from "semantic-ui-react";
import FixedLoader from "../FixedLoader/FixedLoader";
import ActionHeader from "../../components/ActionHeader/ActionHeader";

class OrganizationDirectory extends Component {
  render() {
    return (
      <div>
        <ActionHeader headerText="Organization Directory">
          <Button icon labelPosition="right" size="tiny" positive onClick={() => this.props.addOrganization()}>
            Add Organization
            <Icon name="plus" />
          </Button>
        </ActionHeader>
        <Divider />
        {!this.props.isLoading ? (
          <OrganizationList
            handleClick={this.props.handleSelect}
            organizations={this.props.organizations.filter(
              org => org.is_active === true
            )}
          />
        ) : (
          <FixedLoader />
        )}
      </div>
    );
  }
}

export default OrganizationDirectory;
