import React, { Component } from 'react';
import { connect } from 'react-redux';
import { users, organizations } from '../../store/actions';
import { Form, Checkbox, Button, Transition } from 'semantic-ui-react';
import './AccountForm.css';
import FormMessage from '../../components/FormMessage/FormMessage';

const initialState = {
	user: '',
	organization: '',
	role: '',
	receive_reporting_email: false,
	receive_approval_reminders: false
};

class AccountForm extends Component {
	state = {
		form: initialState
	};

	componentDidMount() {
		!this.props.organizations[0] && this.props.fetchOrganizations();
		this.props.accountUser &&
			this.setState({
				form: {
					...this.state.form,
					user: this.props.accountUser.id
				}
			});
	}

	clearForm = () => {
		this.setState({ form: initialState });
	};

	handleChange(e) {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
			}
		});
	}

	handleSelectChange = (event, { name, value }) => {
		this.setState({ form: { ...this.state.form, [name]: value } });
	};

	handleSubmit = async (e) => {
		e.preventDefault();
		if (this.state.form.organization && this.state.form.user && this.state.form.role) {
			await this.props.addAccount(this.state.form, 'Account Successfully Added.');
			return this.props.handleCancelAddingAccount();
		}
	};

	render() {
		const allowedRoles = [ 1, 3, 5, 8 ];
		const currentOrgRoleOrganizations = this.props.accountUser.org_roles.map((role) => role.organization);
		return (
			<div className="AccountForm">
				<Transition.Group animation="slide down" duration={500}>
					{this.props.errors &&
						this.props.errors[0] &&
						this.props.errors.map((err) => <FormMessage>{err}</FormMessage>)}
				</Transition.Group>
				<Form size={this.props.size} className="AccountForm--form">
					<Form.Group widths="equal">
						<Form.Field width={5}>
							<label htmlFor="organization">Organization</label>
							<Form.Select
								id="organization"
								selection
								search
								required
								name="organization"
								placeholder="Organization"
								value={this.state.form.organization}
								onChange={this.handleSelectChange}
								options={
									this.props.organizations[0] &&
									this.props.organizations
										.filter(
											(organization) => !currentOrgRoleOrganizations.includes(organization.id)
										)
										.map((organization) => ({
											key: organization.id,
											text: organization.dba_name,
											value: organization.id
										}))
								}
							/>
						</Form.Field>
						<Form.Field width={5}>
							<label htmlFor="role">Role</label>
							<Form.Select
								id="role"
								selection
								required
								name="role"
								placeholder="Role"
								value={this.state.form.role}
								onChange={this.handleSelectChange}
								options={
									this.props.groups[0] &&
									this.props.groups
										.filter((group) => allowedRoles.includes(group.id))
										.map((group) => ({
											key: group.id,
											text: group.name,
											value: group.id
										}))
								}
							/>
						</Form.Field>
						<Form.Field width={3} className="centerCheckbox">
							<label htmlFor="receive_reporting_email">Reporting Emails</label>
							<Checkbox
								id="receive_reporting_email"
								name="receive_reporting_email"
								onChange={(e) => this.handleChange(e)}
								checked={this.state.form.receive_reporting_email}
							/>
						</Form.Field>
						<Form.Field width={3} className="centerCheckbox">
							<label htmlFor="receive_approval_reminders">Approval Emails</label>
							<Checkbox
								id="receive_approval_reminders"
								name="receive_approval_reminders"
								onChange={(e) => this.handleChange(e)}
								checked={this.state.form.receive_approval_reminders}
							/>
						</Form.Field>
					</Form.Group>
					{this.props.withActions && (
						<section className="AccountForm--actions">
							<Button onClick={(e) => this.handleSubmit(e)} positive>
								Add Account
							</Button>
							<Button onClick={() => this.props.handleCancelAddingAccount()} negative>
								Cancel
							</Button>
						</section>
					)}
				</Form>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		users: state.users.users,
		organizations: state.organizations.organizations,
		groups: state.auth.groups,
		errors: state.users.errors
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchOrganizations: () => dispatch(organizations.fetchOrganizations()),
		addAccount: (data, message) => dispatch(users.addAccount(data, message))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountForm);
