import React, { Component } from 'react';
import { users, organizations } from '../../store/actions';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import PageMessage from '../../components/PageMessage/PageMessage';
import UserDirectory from '../UserManagement/UserDirectory/UserDirectory';
import './UserManagement.css';
import { Transition } from 'semantic-ui-react';
import UserData from '../../components/UserData/UserData';
import InviteUserForm from '../../components/InviteUserForm/InviteUserForm';
import Navbar from '../../components/Navbar/Navbar';
import WithSidebar from '../../hoc/WithSidebar/WithSidebar';
import InvitationsView from './InvitationsView/InvitationsView';

class UserManagement extends Component {
	state = {
		selectedUser: null,
		isInvitingUser: false,
		enlargeSidebar: false,
		menuItems: [
			{
				id: 1,
				path: '/directory',
				navbarName: 'User Directory',
				icon: 'users',
				component: null
			},
			{
				id: 2,
				path: '/invitations',
				navbarName: 'View Invitations',
				icon: 'envelope',
				component: null
			}
		]
	};

	componentDidMount() {
		this.props.fetchUsers();
		!this.props.organizations[0] && this.props.fetchOrganizations();
	}

	componentDidUpdate(prevProps, prevState) {
		if ((this.props.users && this.props.users[0] ) && (this.state.selectedUser) && this.state.selectedUser === prevState.selectedUser) {
			let currentSelectedUser = this.props.users.find(user => user.id === this.state.selectedUser.id)
			if (this.state.selectedUser !== currentSelectedUser) {
				this.setState({selectedUser: currentSelectedUser})
			}
		}
	}

	handleInviteUser = () => {
		this.setState({ isInvitingUser: true, selectedUser: null });
	};

	cancelAction = () => {
		this.setState({
			selectedUser: null,
			isInvitingUser: false
		});
	};

	shouldSidebarShow = () => {
		if (this.state.selectedUser || this.state.isInvitingUser) {
			return true;
		} else {
			return false;
		}
	};

	handleSelect = (user) => {
		this.setState({ selectedUser: user, isInvitingUser: false });
	};

	handleReinvite = (email, organization, role, key) => {
		this.props.reinviteUser(email, organization, role, key);
	};

	handleEnlargeSidebar = () => {
		this.setState({ enlargeSidebar: true });
	};

	handleUnEnlargeSidebar = () => {
		this.setState({ enlargeSidebar: false });
	};

	render() {
		return (
			<div className="RouteContainer">
				<Navbar
					matchPath={this.props.match.path}
					routeHeader="User Management"
					menuItems={this.state.menuItems}
				/>
				<section className="UserManagement">
					<Transition.Group animation="scale" duration={500}>
						{this.props.messages[0] && <PageMessage>{this.props.messages}</PageMessage>}
					</Transition.Group>
					<WithSidebar
						leftWidth={(this.state.selectedUser || this.state.isInvitingUser) ? this.state.enlargeSidebar ? 8 : 10 : 16}
						rightWidth={(this.state.selectedUser || this.state.isInvitingUser) ? this.state.enlargeSidebar ? 8 : 6 : 0}
						isShowing={this.shouldSidebarShow()}
						sidebarComponent={
							<div className="usersSidebar">
								{this.state.selectedUser && (
									<UserData
										handleEnlargeSidebar={this.handleEnlargeSidebar}
										handleUnEnlargeSidebar={this.handleUnEnlargeSidebar}
										cancelAction={this.cancelAction}
										selectedUser={this.state.selectedUser}
									/>
								)}

								{this.state.isInvitingUser && <InviteUserForm cancelAction={this.cancelAction} />}
							</div>
						}
					>
						<Route
							path={`${this.props.match.path}/directory`}
							render={(props) => (
								<UserDirectory
									{...props}
									users={this.props.users.sort((a, b) => a.name - b.name)}
									handleInvitingUser={this.handleInviteUser}
									isInvitingUser={this.state.isInvitingUser}
									isLoading={this.props.isLoading}
									isLoaded={this.props.isLoaded}
									selectedUser={this.state.selectedUser}
									handleSelect={this.handleSelect}
								/>
							)}
						/>
						<Route
							path={`${this.props.match.path}/invitations`}
							render={(props) => (
								<InvitationsView cancelAction={this.cancelAction} {...props} />
							)}
						/>
					</WithSidebar>
				</section>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		users: state.users.users,
		organizations: state.organizations.organizations,
		groups: state.auth.groups,
		invitations: state.users.invitations,
		isLoadingInvitations: state.users.isLoadingInvitations,
		invitationsLoaded: state.users.invitationsLoaded,
		isLoading: state.users.isLoading,
		isLoaded: state.users.isLoaded,
		errors: state.users.errors,
		messages: state.users.messages
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchUsers: () => {
			dispatch(users.fetchUsers());
		},
		fetchOrganizations: () => {
			dispatch(organizations.fetchOrganizations());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
