import React, { Component } from 'react';
import { List, Loader, Search, Button } from 'semantic-ui-react';
import './ReportPresentationOrgList.css';
import ActionHeader from '../../../../components/ActionHeader/ActionHeader';
import _ from 'lodash';
import DimmedLoader from '../../../../components/DimmedLoader/DimmedLoader';
import ReportPresentationOrgListItem from './ReportPresentationOrgListItem/ReportPresentationOrgListItem';
import FormModal from '../../../../components/FormModal/FormModal';
import BulkReportPresentationUpdateForm from '../../../../forms/BulkReportPresentationUpdateForm/BulkReportPresentationUpdateForm';

class ReportPresentationOrgList extends Component {
	state = {
		isSearching: false,
		searchValue: '',
		searchResults: [],
		closeBulkUpdateModal: false
	};

	componentDidUpdate(prevProps) {
		if (this.props.isBulkUpdatingTemplates !== prevProps.isBulkUpdatingTemplates) {
			if (this.props.isBulkUpdatingTemplates === false && prevProps.isBulkUpdatingTemplates === true) {
				this.setState({ closeBulkUpdateModal: true });
				this.props.fetchSlideDeckTemplates();
			}
		}
	}

	resetSearch = () => this.setState({ isSearching: false, searchResults: [], searchValue: '' });

	handleResultSelect = (e, { result }) => {
		const organization = this.props.organizations.find((org) => org.id === result.key);
		const template =
			this.props.slideDeckTemplates[0] &&
			this.props.slideDeckTemplates.find((t) => t.id === organization.monthly_report_template);
		this.props.handleSelect(organization, template ? true : false);
	};

	handleSearchChange = (e, { value }) => {
		this.setState({ isSearching: true, searchValue: value });

		setTimeout(() => {
			if (this.state.searchValue.length < 1) return this.resetSearch();

			const re = new RegExp(_.escapeRegExp(this.state.searchValue), 'i');
			const isMatch = (result) => re.test(result.dba_name);

			this.setState({
				isSearching: false,
				searchResults: _.filter(this.props.organizations, isMatch)
			});
		}, 300);
	};

	render() {
		let organizations = this.state.searchResults[0] ? this.state.searchResults : this.props.organizations;
		const masterOrganizationReportTemplate =
			this.props.organizationContentObject &&
			this.props.slideDeckTemplates[0] &&
			this.props.slideDeckTemplates.find(
				(t) => t.is_master_template && t.content_type === this.props.organizationContentObject.id
			);
		return (
			<div className="ReportPresentationOrgList">
				<ActionHeader headerText="Presentation Templates">
					{masterOrganizationReportTemplate && (
						<FormModal
							headerText="Update Presentation Templates from Master Template"
							closeModal={this.state.closeBulkUpdateModal}
							trigger={
								<Button size="tiny" color="blue">
									Update Templates from Master
								</Button>
							}
						>
							<BulkReportPresentationUpdateForm />
						</FormModal>
					)}
					<div className="ReportingPresentationTemplates--search">
						<Search
							fluid
							size="small"
							placeholder="Search Organizations"
							loading={this.state.isSearching || !this.props.organizations[0]}
							disabled={!this.props.organizations[0]}
							onResultSelect={this.handleResultSelect}
							onSearchChange={_.debounce(this.handleSearchChange, 500, {
								leading: true
							})}
							results={this.state.searchResults.map((result) => ({
								key: result.id,
								title: result.dba_name
							}))}
							value={this.state.searchValue}
						/>
					</div>
				</ActionHeader>
				{this.props.organizations && this.props.organizations[0] ? (
					<section className="ReportingPresentationTemplates--orgList">
						{this.props.slideDecksLoading && (
							<DimmedLoader size="medium" text="Loading Presentation Templates" />
						)}
						<List divided relaxed="very">
							<ReportPresentationOrgListItem
								isMasterTemplate
								template={masterOrganizationReportTemplate}
								path={this.props.match.path}
								deleteSlideDeckTemplate={this.props.deleteSlideDeckTemplate}
							/>
							{organizations.filter((org) => org.report_required && org.is_active).map((org) => {
								const template =
									this.props.slideDeckTemplates[0] &&
									this.props.slideDeckTemplates.find((t) => t.id === org.monthly_report_template);
								return (
									org &&
									org.id && (
										<ReportPresentationOrgListItem
											key={org.id}
											org={org}
											template={template}
											path={this.props.match.path}
											deleteSlideDeckTemplate={this.props.deleteSlideDeckTemplate}
											handleCreateReportTemplateFromMaster={
												this.props.handleCreateReportTemplateFromMaster
											}
											getMostRecentReport={this.props.getMostRecentReport}
										/>
									)
								);
							})}
						</List>
					</section>
				) : this.props.organizationsLoading ? (
					<Loader active />
				) : (
					<p className="ReportingPresentationTemplates--noMatch">
						There are currently no Organizations with corresponding templates
					</p>
				)}
			</div>
		);
	}
}

export default ReportPresentationOrgList;
