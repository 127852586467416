import * as actionTypes from "../actions/actionTypes";
import axios from "../../axios-portal";

// Messages
export const addMessage = (message, negative) => {
  return {
    type: actionTypes.ADD_MESSAGE,
    message: message,
    negative: negative
  };
};

export const removeMessage = (message, timeToWait) => {
  return {
    type: actionTypes.REMOVE_MESSAGE,
    message: message,
    timeToWait: timeToWait
  };
};

// Applications
export const startAppsFetch = () => {
  return {
    type: actionTypes.START_APPS_FETCH
  };
};

export const setApps = applications => {
  return {
    type: actionTypes.SET_APPS,
    applications: applications
  };
};

export const fetchAppsFailure = errors => {
  return {
    type: actionTypes.FETCH_APPS_FAILURE,
    errors: errors
  };
};

export const updateAppSuccess = (id, app) => {
  return {
    type: actionTypes.UPDATE_APP_SUCCESS,
    id: id,
    app: app
  };
};

export const appFailure = errors => {
  return {
    type: actionTypes.APP_FAILURE,
    errors: errors
  };
};

export const startAppInitialization = buildID => {
  return {
    type: actionTypes.START_APP_INITIALIZATION,
    buildID: buildID
  };
};

export const appInitializationSuccess = app => {
  return {
    type: actionTypes.APP_INITIALIZATION_SUCCESS,
    app: app.new_application,
    message: app.response,
    githubResponse: app.github_response,
    s3Response: app.s3_response,
    route53Response: app.route53_response
  };
};

export const appInitializationFailure = errors => {
  return {
    type: actionTypes.APP_INITIALIZATION_FAILURE,
    errors: errors
  };
};

// backups
export const startAppBackup = id => {
  return {
    type: actionTypes.START_APP_BACKUP,
    id: id
  };
};

export const appBackupSuccess = (id, response) => {
  return {
    type: actionTypes.APP_BACKUP_SUCCESS,
    id: id,
    message: response.status,
    app: response.app
  };
};

export const appBackupFailure = (id, errors) => {
  return {
    type: actionTypes.APP_BACKUP_FAILURE,
    id: id,
    errors: errors
  };
};

// stopping app
export const beginAppStop = id => {
  return {
    type: actionTypes.BEGIN_APP_STOP,
    id: id
  };
};

export const appStopSuccess = (id, response) => {
  return {
    type: actionTypes.APP_STOP_SUCCESS,
    id: id,
    message: response.status
  };
};

export const appStopFailure = (id, errors) => {
  return {
    type: actionTypes.APP_STOP_FAILURE,
    id: id,
    errors: errors
  };
};

// starting app
export const beginAppStart = id => {
  return {
    type: actionTypes.BEGIN_APP_START,
    id: id
  };
};

export const appStartSuccess = (id, response) => {
  return {
    type: actionTypes.APP_START_SUCCESS,
    id: id,
    message: response.status
  };
};

export const appStartFailure = (id, errors) => {
  return {
    type: actionTypes.APP_START_FAILURE,
    id: id,
    errors: errors
  };
};

export const getS3DownloadSuccess = (
  appID,
  response,
  backupKey,
  backupType
) => {
  return {
    type: actionTypes.GET_S3_DOWNLOAD_SUCCESS,
    appID: appID,
    link: response.response,
    backupKey: backupKey,
    backupType: backupType
  };
};

export const getS3DownloadFailure = errors => {
  return {
    type: actionTypes.GET_S3_DOWNLOAD_FAILURE,
    errors: errors
  };
};

// status checks
export const beginAppStatusCheck = id => {
  return {
    type: actionTypes.BEGIN_APP_STATUS_CHECK,
    id: id
  };
};

export const appStatusCheckSuccess = (id, response) => {
  return {
    type: actionTypes.APP_STATUS_CHECK_SUCCESS,
    id: id,
    webserverStatus: response.webserver_status,
    wordpressStatus: response.wordpress_status,
    websiteStatus: response.website_status
  };
};

export const appStatusCheckFailure = (id, errors) => {
  return {
    type: actionTypes.APP_STATUS_CHECK_FAILURE,
    id: id,
    errors: errors
  };
};

// DB backup fetch
export const startDataBackupFetch = id => {
  return {
    type: actionTypes.BEGIN_APP_DATA_BACKUP_FETCH,
    id: id
  };
};

export const appDataBackupFetchSuccess = (id, response) => {
  return {
    type: actionTypes.APP_DATA_BACKUP_FETCH_SUCCESS,
    id: id,
    backups: response
  };
};

export const appDataBackupFetchFailure = (id, errors) => {
  return {
    type: actionTypes.APP_DATA_BACKUP_FETCH_FAILURE,
    id: id,
    errors: errors
  };
};

// Code backup fetch
export const startCodeBackupFetch = id => {
  return {
    type: actionTypes.BEGIN_APP_CODE_BACKUP_FETCH,
    id: id
  };
};

export const appCodeBackupFetchSuccess = (id, response) => {
  return {
    type: actionTypes.APP_CODE_BACKUP_FETCH_SUCCESS,
    id: id,
    backups: response
  };
};

export const appCodeBackupFetchFailure = (id, errors) => {
  return {
    type: actionTypes.APP_CODE_BACKUP_FETCH_FAILURE,
    id: id,
    errors: errors
  };
};

export const startDeleteApplication = id => {
  return {
    type: actionTypes.START_DELETE_APPLICATION,
    id: id
  };
};

export const deleteApplicationSuccess = (id, app) => {
  return {
    type: actionTypes.DELETE_APPLICATION_SUCCESS,
    id: id,
    app: app
  };
};

export const fetchApps = () => {
  return dispatch => {
    dispatch(startAppsFetch());
    axios
      .get("web-applications.json")
      .then(response => {
        return dispatch(setApps(response.data));
      })
      .catch(err => {
        return dispatch(fetchAppsFailure(err));
      });
  };
};

export const updateApp = (id, formData) => {
  return dispatch => {
    let body = JSON.stringify({
      active: formData.active
    });
    // let message = "Application Updated";
    axios
      .put(`web-applications/${id}/`, body)
      .then(response => {
        // dispatch(addMessage(message));
        // setTimeout(() => dispatch(removeMessage(message)), 2000);
        return dispatch(updateAppSuccess(id, response.data));
      })
      .catch(err => {
        dispatch(appFailure(err));
      });
  };
};

export const initializeApp = (buildID, childTheme) => {
  return dispatch => {
    dispatch(startAppInitialization());
    let body = JSON.stringify({
      website_build: buildID,
      child_theme: childTheme
    });
    axios
      .post(`initialize-website/`, body)
      .then(response => {
        return dispatch(appInitializationSuccess(response.data));
      })
      .catch(err => {
        console.log(err);
        dispatch(appInitializationFailure(err));
      });
  };
};

export const backupApp = id => {
  return dispatch => {
    dispatch(startAppBackup(id));
    axios
      .post(`web-applications/${id}/backup/`)
      .then(response => {
        dispatch(fetchDataBackups(id));
        dispatch(fetchCodeBackups(id));
        return dispatch(appBackupSuccess(id, response.data));
      })
      .catch(err => {
        dispatch(appFailure(err));
      });
  };
};

export const stopApp = id => {
  return dispatch => {
    dispatch(beginAppStop(id));
    axios
      .post(`web-applications/${id}/stop_server/`)
      .then(response => {
        dispatch(checkAppStatus(id));
        return dispatch(appStopSuccess(id, response.data));
      })
      .catch(err => {
        dispatch(appStopFailure(err));
      });
  };
};

export const startApp = id => {
  return dispatch => {
    dispatch(beginAppStart(id));
    axios
      .post(`web-applications/${id}/start_server/`)
      .then(response => {
        dispatch(checkAppStatus(id));
        return dispatch(appStartSuccess(id, response.data));
      })
      .catch(err => {
        dispatch(appStartFailure(err));
      });
  };
};

export const checkAppStatus = id => {
  return dispatch => {
    dispatch(beginAppStatusCheck(id));
    axios
      .get(`web-applications/${id}/status_check/`)
      .then(response => {
        return dispatch(appStatusCheckSuccess(id, response.data));
      })
      .catch(err => {
        dispatch(appStatusCheckFailure(err));
      });
  };
};

export const getS3Download = (appID, backupKey, backupType) => {
  let body = JSON.stringify({
    file_key: backupKey
  });
  return dispatch => {
    axios
      .post(`generate-s3-download/`, body)
      .then(response => {
        return dispatch(
          getS3DownloadSuccess(appID, response.data, backupKey, backupType)
        );
      })
      .catch(err => {
        dispatch(getS3DownloadFailure(err));
      });
  };
};

export const fetchDataBackups = id => {
  return dispatch => {
    dispatch(startDataBackupFetch(id));
    axios
      .get(`web-applications/${id}/list_db_backups/`)
      .then(response => {
        return dispatch(appDataBackupFetchSuccess(id, response.data));
      })
      .catch(err => {
        dispatch(appDataBackupFetchFailure(err));
      });
  };
};

export const fetchCodeBackups = id => {
  return dispatch => {
    dispatch(startCodeBackupFetch(id));
    axios
      .get(`web-applications/${id}/list_codebase_backups/`)
      .then(response => {
        return dispatch(appCodeBackupFetchSuccess(id, response.data));
      })
      .catch(err => {
        dispatch(appCodeBackupFetchFailure(err));
      });
  };
};

export const deleteApplication = id => {
  return dispatch => {
    dispatch(startDeleteApplication(id));
    axios
      .post(`web-applications/${id}/delete_and_clean/`)
      .then(response => {
        return dispatch(deleteApplicationSuccess(id, response.data));
      })
      .catch(err => {
        dispatch(appFailure(err));
      });
  };
};

export const startThemeFetch = () => {
  return {
    type: actionTypes.START_THEME_FETCH
  };
};

export const setThemes = themes => {
  return {
    type: actionTypes.SET_THEMES,
    themes: themes
  };
};

export const themeFailure = errors => {
  return {
    type: actionTypes.THEME_FAILURE,
    errors: errors
  };
};

// Child Themes
export const fetchChildThemes = () => {
  return dispatch => {
    dispatch(startThemeFetch());
    axios
      .get("wp-child-themes.json")
      .then(response => {
        return dispatch(setThemes(response.data));
      })
      .catch(err => {
        return dispatch(themeFailure(err));
      });
  };
};
