import React, { Component } from "react";
import * as actions from "../../store/actions";
import { connect } from "react-redux";
import "./UserMenu.css";
import { Icon, Popup, Dropdown } from "semantic-ui-react";

class UserMenu extends Component {
  state = {
    menuIsShowing: false
  };

  render() {
    return (
      <div className="UserMenu">
        <Popup
          size="tiny"
          inverted
          position="left center"
          hideOnScroll
          trigger={
            <Dropdown
              trigger={<Icon name="user" circular />}
              floating
              icon={null}
              direction="left"
            >
              <Dropdown.Menu>
                <Dropdown.Header as="h4">
                  {this.props.user.user_preferences &&
                    this.props.user.user_preferences.default_organization
                      .dba_name}
                </Dropdown.Header>
                <Dropdown.Header as="h6">
                  {this.props.user.title}
                </Dropdown.Header>
                <Dropdown.Divider />
                <Dropdown.Item onClick={this.props.showUserSettings}>
                  <Icon name="settings" />
                  My Profile Settings
                </Dropdown.Item>
                <Dropdown.Item onClick={this.props.logout}>
                  <Icon name="log out" /> Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          }
        >
          <Popup.Header>{this.props.user.name}</Popup.Header>
          <Popup.Content>
            {this.props.user.user_preferences &&
              this.props.user.user_preferences.default_organization.dba_name}
          </Popup.Content>
        </Popup>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    isShowing: state.auth.showUserSettings
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showUserSettings: () => {
      return dispatch(actions.auth.showUserSettings());
    },
    closeUserSettings: () => {
      return dispatch(actions.auth.closeUserSettings());
    },
    logout: () => {
      return dispatch(actions.auth.logout());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserMenu);
