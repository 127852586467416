import React from "react";
import "./ActionHeader.css";
import { Header, Icon, Label } from "semantic-ui-react";

const ActionHeader = props => {
  return (
    <div className={`ActionHeader ${props.className ? props.className : ""}`}>
      <Header as={props.size ? props.size : "h3"}>
        {props.icon && (
          <Icon
            color={props.iconColor ? props.iconColor : "grey"}
            name={props.icon}
          />
        )}
        <Header.Content>
          {props.headerText}
          {props.subheaderText && (
            <Header.Subheader>{props.subheaderText}</Header.Subheader>
          )}
          {props.count && (
            <div className="ActionHeader--count">
              <Label size={'tiny'} color="teal" horizontal>
                {props.count}
              </Label>
            </div>
          )}
        </Header.Content>
      </Header>
      <div className="ActionHeaderActions">{props.children}</div>
    </div>
  );
};

export default ActionHeader;
