import React, { Component } from "react";
import { organizations } from "../../store/actions";
import { connect } from "react-redux";
import { Form } from 'semantic-ui-react';
import ErrorHandler from "../../components/ErrorHandler/ErrorHandler";
import "./EditorialRequirementForm.css";

const initialState = {
  requirement: ""
};

class EditorialRequirementForm extends Component {
  state = {
    form: initialState
  };

  clearForm = () => {
    this.setState({ form: initialState });
  };

  handleChange(e) {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  }

  handleSubmit = async e => {
    e.preventDefault();
    await this.props.addEditorialRequirement(
      this.state.form.requirement,
      this.props.orgId
    );
    if (!this.props.errors) {
      this.clearForm();
    }
  };

  render() {
    return (
      <div className="EditorialRequirementForm">
        <Form onSubmit={this.handleSubmit}>
          {this.props.errors && <ErrorHandler errors={this.props.errors} />}
          <Form.Field>
            <Form.TextArea
              onChange={e => this.handleChange(e)}
              name="requirement"
              value={this.state.form.requirement}
            />
          </Form.Field>
          <Form.Button color="blue" size="mini" type="submit">
            Add Requirement
          </Form.Button>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    errors: state.organizations.errors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addEditorialRequirement: (requirement, orgId) => {
      return dispatch(organizations.addEditorialRequirement(requirement, orgId));
    },
    updateEditorialRequirement: (requirement, id) => {
      return dispatch(organizations.updateEditorialRequirement(requirement, id));
    },
    deleteEditorialRequirement: (id) => {
      return dispatch(organizations.deleteEditorialRequirement(id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorialRequirementForm);
