import React, { Component } from "react";
import "./ArticleChangelog.css";
import { connect } from "react-redux";
import { contentGeneration } from "../../store/actions";
import {
  Loader,
  Label,
  Header,
  Divider,
  Icon,
  Button,
} from "semantic-ui-react";
import moment from "moment";

export class ArticleChangelog extends Component {
  state = {
    show: false,
    showAll: false,
  };

  componentDidMount() {
    this.props.article &&
      this.props.fetchArticleChangelog(this.props.article.id);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.article && this.props.article.id) {
      if (this.props.article.id !== prevProps.article.id) {
        this.setState({ show: false });
        this.props.fetchArticleChangelog(this.props.article.id);
      }
    }
  }

  handleButtonToggle = () => {
    this.setState({ show: !this.state.show });
    this.props.changelog &&
      !this.props.changelog[0] &&
      this.props.fetchArticleChangelog(this.props.article.id);
  };

  render() {
    const article = this.props.article;
    const articleChangelog =
      article &&
      this.props.changelog.filter(
        (changelogEntry) => changelogEntry.article === article.id
      );

    // const originalchangelogLength = articleChangelog && articleChangelog.length;

    return (
      <div className="ArticleChangelog">
        {this.state.show ? (
          this.props.isFetchingChangelog ? (
            <Loader inline="centered" active>
              Fetching Article Changelog
            </Loader>
          ) : articleChangelog && articleChangelog[0] ? (
            <div className="ArticleChangelog--container">
              <Divider className="ArticleChangelog--divider" horizontal>
                <Header className="ArticleChangelog--header" as="h4">
                  <Icon name="list ul" />
                  Article Changelog
                </Header>
              </Divider>

              <Button
                size="small"
                type="button"
                compact
                fluid
                icon
                onClick={() => this.handleButtonToggle()}
              >
                Hide Changelog
                <Icon name="minus" />
              </Button>

              {articleChangelog &&
                articleChangelog.reverse().map((entry, i) => {
                  let labelColor = "blue";
                  if (entry.change_type === "+") {
                    labelColor = "green";
                  } else if (entry.change_type === "-") {
                    labelColor = "red";
                  }
                  return (
                    <div
                      key={`${entry.id}-${i}`}
                      className="ArticleChangelogEntry"
                    >
                      <div className="ArticleChangelogEntry--info">
                        <div className="ArticleChangelogEntry-label">
                          <Label
                            circular
                            color={labelColor ? labelColor : null}
                            size="tiny"
                            empty
                          />
                        </div>
                        <div className="ArticleChangelogEntry-content">
                          {/* <span className="ArticleChangelogEntry--name">{entry.changed_by}</span> updated the <span className="ArticleChangelogEntry--field">{entry.changed_field}</span> */}
                          <div className="ArticleChangelogEntry--event">
                            <span className="ArticleChangelogEntry--name">
                              {entry.changed_by}
                            </span>{" "}
                            updated the{" "}
                            <span className="ArticleChangelogEntry--field">
                              {entry.changed_field}
                            </span>{" "}
                          </div>
                          <div className="ArticleChangelogEntry--values">
                            <span className="ArticleChangelogEntry--prevValue">
                              {entry.previous_value}
                            </span>{" "}
                            to{" "}
                            <span className="ArticleChangelogEntry--newValue">
                              {entry.new_value}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="ArticleChangelogEntry--date">
                        <div className="ArticleChangelogEntry-date">
                          <span>
                            {moment(entry.change_date).format(
                              "MMM DD, YYYY h:mm A"
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {/* {originalchangelogLength > 8 && (
                                <div className="ArticleChangelogEntry--bottomActions">
                                    {!this.state.showAll ? (
                                        <Button
                                            as="div"
                                            size="tiny"
                                            onClick={() => this.setState({ showAll: true })}
                                            labelPosition="left"
                                        >
                                            <Label size="tiny" basic pointing="right">
                                                Showing 6 of {originalchangelogLength} Events
                                            </Label>
                                            <Button size="tiny" positive icon>
                                                <Icon name="list" />
                                                Show All Changes
                                            </Button>
                                        </Button>
                                    ) : (
                                        <Button
                                            as="div"
                                            size="tiny"
                                            onClick={() => this.setState({ showAll: false })}
                                            labelPosition="left"
                                        >
                                            <Label size="tiny" basic pointing="right">
                                                Showing All {originalchangelogLength} Changes
                                            </Label>
                                            <Button size="tiny" color="teal" icon>
                                                <Icon name="list" />
                                                Show Less
                                            </Button>
                                        </Button>
                                    )}
                                </div>
                            )} */}
            </div>
          ) : (
            <div className="ArticleChangelog--noData">
              <p>No Recorded Changes</p>
            </div>
          )
        ) : (
          <Button
            type="button"
            primary
            compact
            fluid
            onClick={() => this.handleButtonToggle()}
          >
            Show Changelog
          </Button>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    changelog: state.contentGeneration.changelog,
    isFetchingChangelog: state.contentGeneration.isFetchingChangelog,
    fetchedChangelog: state.contentGeneration.fetchedChangelog,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchArticleChangelog: (articleID) => {
      return dispatch(contentGeneration.fetchArticleChangelog(articleID));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleChangelog);
