import React, { Component } from 'react';
import './ProgressCircle.css';

export default class ProgressCircle extends Component {
	calculateFirstDegree = (progress) => {
		const output = progress <= 50 ? 90 - progress / 100 * 360 : 90;
		return output;
	};

	calculateSecondDegree = (progress) => {
		const output = progress <= 50 ? 90 : -90 + 360 * (1 - (progress / 100));
		return output;
	};

	render() {
        const { progress } = this.props;
        const backgroundColor = this.props.backgroundColor ? this.props.backgroundColor : '#fff';
        const borderColor = this.props.borderColor ? this.props.borderColor : '#028cd5';
        const size = this.props.size ? this.props.size : 100
        const borderWidth = this.props.borderWidth ? this.props.borderWidth : 5
        const textColor = this.props.textColor ? this.props.textColor : '#444'
        
        const overlayStyle = {
            width: `${size - borderWidth}px`,
            height: `${size - borderWidth}px`,
            background: backgroundColor,
            fontSize: `${(size / 2) - 2}px`,
            color: textColor,
        }

		const progressFillStyle = {
            width: `${size}px`,
            height: `${size}px`,
			backgroundImage: `linear-gradient(${this.calculateFirstDegree(progress)}deg, ${progress <= 50
				? backgroundColor
				: borderColor} 50%, transparent 50%),
             linear-gradient(${this.calculateSecondDegree(progress)}deg, ${borderColor} 50%, ${backgroundColor} 50%)`
		};

		return (
			<div className="ProgressCircle">
				<div style={progressFillStyle} className="radialProgressBar">
					<div style={overlayStyle} className="overlay">{this.props.children}</div>
				</div>
			</div>
		);
	}
}
