import React, { Component } from "react";
import "./LinkCheckerView.css";
import { utilities } from "../../../store/actions";
import { connect } from "react-redux";
import LinkCheckerOutput from "./LinkCheckerOutput/LinkCheckerOutput";
import { Header, Input, Button, Form, Icon } from "semantic-ui-react";
import DimmedLoader from "../../../components/DimmedLoader/DimmedLoader";

class LinkCheckerView extends Component {
  state = {
    checkingLinks: false,
    url: "",
    report: null
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.checks[0] && this.props.checks !== prevProps.checks) {
      let newCheck = this.props.checks.find(
        check => check.url === this.state.url
      );
      newCheck && this.setState({ report: newCheck.report });
    }
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.checkLinks(this.state.url);
  };

  clearData = () => {
    this.setState({ report: null, url: "" });
  };

  render() {
    return (
      <div className="LinkCheckerView">
        {!this.props.checkInProgress && this.state.report ? (
          <div className="LinkCheckerView--output">
            <Header className="LinkCheckerView--output__header">
              <span className="LinkCheckerView--output__header--container">
                <span className="LinkCheckerView--output__label">
                  Results for:
                </span>
                <span className="LinkCheckerView--output__url">
                  {this.state.url}
                </span>
              </span>
              <Button size="medium" onClick={() => this.clearData()} basic>
                Start New Check
              </Button>
            </Header>
            <LinkCheckerOutput url={this.state.url} data={this.state.report} />
          </div>
        ) : (
          <div className="LinkCheckerView--formContainer">
            {this.props.checkInProgress && (
              <DimmedLoader size="large" text="Link Check in Progress" interactiveText={[
                {
                    'order': 1,
                    'text': 'Searching Website',
                    'time': 6000
                },
                {
                    'order': 2,
                    'text': 'Compiling Links',
                    'time': 8000
                },
                {
                    'order': 3,
                    'text': 'Checking Status Codes',
                    'time': 10000
                },
                {
                    'order': 4,
                    'text': 'Creating Report',
                    'time': 14000
                }
              ]} />
            )}
            <Header>Enter a URL to begin check</Header>
            <Form
              onSubmit={this.handleSubmit}
              className="LinkCheckerView--form"
            >
              <Form.Field inline>
                <Input
                  id="url"
                  icon='world'
                  iconPosition='left'
                  type="url"
                  name="url"
                  value={this.state.url}
                  onChange={e => this.handleChange(e)}
                />
                <Button
                icon labelPosition='right'
                  loading={this.props.checkInProgress}
                  type="submit"
                  color="blue"
                >
                  Go
                  <Icon name="arrow right" />
                </Button>
              </Form.Field>
            </Form>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    checks: state.utilities.linkChecks.checks,
    checkInProgress: state.utilities.linkChecks.checkInProgress,
    errors: state.utilities.linkChecks.errors,
    messages: state.utilities.linkChecks.messages
  };
};

const mapDispatchToProps = dispatch => {
  return {
    checkLinks: url => dispatch(utilities.checkLinks(url))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkCheckerView);
