import React, { Component } from 'react';
import './ClientReportingDashboard.css';
import { Loader, Header, Icon, Select } from 'semantic-ui-react';
import moment from 'moment';
import ClientReportingTable from './ClientReportingTable/ClientReportingTable';
import ActionHeader from '../../../components/ActionHeader/ActionHeader';

export default class ClientReportingDashboard extends Component {
	state = {
		selectedOrganization: null,
		selectedYear: null
	};

	getMonth = (monthID, monthOnly) => {
		const pm = this.props.planningMonths[0] && this.props.planningMonths.find((month) => month.id === monthID);
		const month = pm && moment(pm.month, 'MM').format('MMMM');
		const year = pm && pm.year;
		return monthOnly ? `${month}` : `${month} ${year}`;
	};

	getOrganization = (orgID) => {
		const org = this.props.organizations[0] && this.props.organizations.find((o) => o.id === orgID);
		return org && org.dba_name;
	};

	userHasManyOrgs = () => {
		const userRoles = this.props.user.org_roles;
		if (userRoles) {
			if (userRoles.length > 1) {
				return true;
			} else {
				return false;
			}
		}
	};

	renderReportUrl = (report) => {
		const organization =
			this.props.organizations[0] && this.props.organizations.find((o) => o.id === report.organization);
		const planningMonth =
			this.props.planningMonths[0] && this.props.planningMonths.find((month) => month.id === report.month);

		if (organization && planningMonth) {
			return `/reporting/${planningMonth.year}/${planningMonth.month}/${organization.slug}`;
		}
	};

	getUniqueReportYears = (reports) => {
		const uniqueYears = [];
		reports.forEach((report) => {
			const reportPlanningMonth =
				this.props.planningMonths[0] && this.props.planningMonths.find((month) => month.id === report.month);
			const reportYear = reportPlanningMonth && reportPlanningMonth.year;
			if (reportYear && !uniqueYears.includes(reportYear)) {
				uniqueYears.push(reportYear);
			}
		});
		return uniqueYears;
	};

	getReportYear = (report) => {
		const planningMonth =
			this.props.planningMonths[0] && this.props.planningMonths.find((month) => month.id === report.month);
		return planningMonth && Number(planningMonth.year);
	};

	handleSelectChange = (event, { name, value }) => {
		this.setState({ [name]: (value === 'all' || value === '') ? null : value });
	};

	render() {
		const { isLoaded, isLoading, reports, planningMonths, organizations, user } = this.props;
		const uniqueYears = reports[0] && planningMonths[0] && this.getUniqueReportYears(reports);
		const userOrganizationOptions =
			user.org_roles &&
			user.org_roles.map((orgRole) => ({
				key: orgRole.organization,
				text: this.getOrganization(orgRole.organization),
				value: orgRole.organization
			}));
		const reportYearOptions =
			reports &&
			reports[0] &&
			this.getUniqueReportYears(reports).map((year) => ({
				key: year,
				text: year,
				value: year
			}));
		return (
			<div className="ClientReportingDashboard">
				<ActionHeader
					className="ClientReportingDashboard--header"
					size="h2"
					headerText="Monthly Report Briefings"
				>
					{(this.userHasManyOrgs() ||
						(reports && reports[0] && this.getUniqueReportYears(reports).length > 1)) && (
						<div className="ClientReportingDashboard--filters">
							<Select
								loading={isLoading}
								id="selectedYear"
								name="selectedYear"
								placeholder="Filter by Year"
								selection
								clearable
								onChange={this.handleSelectChange}
								options={
									reportYearOptions ? (
										[
											...reportYearOptions,
											{
												key: 'all',
												text: 'All',
												value: null
											}
										]
									) : (
										[]
									)
								}
								value={this.state.selectedYear}
							/>
							<Select
								loading={isLoading}
								id="selectedOrganization"
								name="selectedOrganization"
								placeholder="Filter by Organization"
								selection
								clearable
								onChange={this.handleSelectChange}
								options={
									userOrganizationOptions ? (
										[
											...userOrganizationOptions,
											{
												key: 'all',
												text: 'All',
												value: null
											}
										]
									) : (
										[]
									)
								}
								value={this.state.selectedOrganization}
							/>
						</div>
					)}
				</ActionHeader>
				{!isLoading && isLoaded && planningMonths[0] && organizations[0] ? reports[0] ? (
					<div className="ClientReportingDashboard--reportTables">
						{uniqueYears &&
							uniqueYears
								.filter(
									(year) =>
										this.state.selectedYear !== null ? year === this.state.selectedYear : year
								)
								.map((year) => (
									<div key={year} className="ClientReportingDashboard--reportYear">
										<Header as="h3" className="ClientReportingDashboard--reportYearHeader">
											<Icon size="small" name="calendar outline" />
											<Header.Content>{`${year}`}</Header.Content>
										</Header>
										{user.org_roles &&
											user.org_roles
												.filter(
													(orgRole) =>
														this.state.selectedOrganization !== null
															? orgRole.organization === this.state.selectedOrganization
															: orgRole
												)
												.map((orgRole) => {
													const orgRoleOrganization = orgRole.organization;
													const orgReports = reports.filter(
														(report) =>
															report.organization === orgRoleOrganization &&
															this.getReportYear(report) === Number(year)
													);
													return (
														<ClientReportingTable
															key={`${year}-${orgRole.organization}`}
															reports={orgReports}
															renderReportUrl={this.renderReportUrl}
															hasManyOrgs={this.userHasManyOrgs()}
															getMonth={this.getMonth}
															organizationId={orgRoleOrganization}
															getOrganization={this.getOrganization}
														/>
													);
												})}
									</div>
								))}
					</div>
				) : (
					<Header>There are no current reports for your Organization</Header>
				) : (
					<Loader active>Loading Reports</Loader>
				)}
			</div>
		);
	}
}
