import TextBlock from './dynamicComponents/TextBlock/TextBlock';
import Heading from './dynamicComponents/Heading/Heading';
import OrgLogo from './dynamicComponents/OrgLogo/OrgLogo';
import PresentationTitle from './dynamicComponents/PresentationTitle/PresentationTitle';
import ContentOverview from './dynamicComponents/ContentOverview/ContentOverview';
import GoogleAnalyticsChart from './dynamicComponents/GoogleAnalyticsChart/GoogleAnalyticsChart';
import WhatConvertsChart from './dynamicComponents/WhatConvertsChart/WhatConvertsChart';
import AhrefsScreenshot from './dynamicComponents/AhrefsScreenshot/AhrefsScreenshot';
import ReportingMonthTitle from './dynamicComponents/ReportingMonthTitle/ReportingMonthTitle';
import GoogleReviewRequest from './dynamicComponents/GoogleReviewRequest/GoogleReviewRequest';
import ScheduleMeetingButton from './dynamicComponents/ScheduleMeetingButton/ScheduleMeetingButton';

export const dynamicComponents = [
	{
		name: 'PresentationTitle',
		component: PresentationTitle,
		verbose_name: 'Presentation Title',
		attribute_fields: [
			{
				dataType: 'string',
				required: false,
				fieldName: 'Title Size',
				formElement: 'select',
				attributeName: 'as',
				choices: [ 'h1', 'h2', 'h3', 'h4', 'h5', 'h6' ]
			}
		],
		allowed: true
	},
	{
		name: 'TextBlock',
		component: TextBlock,
		verbose_name: 'Text Block',
		attribute_fields: [
			{
				dataType: 'string',
				required: true,
				fieldName: 'Content',
				formElement: 'text area',
				attributeName: 'content'
			},
			{
				dataType: 'string',
				required: false,
				fieldName: 'Text Alignment',
				formElement: 'select',
				attributeName: 'textAlign',
				choices: [ 'left', 'center', 'right' ]
			}
		],
		allowed: true
	},
	{
		name: 'Heading',
		component: Heading,
		verbose_name: 'Header',
		attribute_fields: [
			{
				dataType: 'string',
				required: true,
				fieldName: 'Content',
				formElement: 'input',
				attributeName: 'content'
			},
			{
				dataType: 'string',
				required: false,
				fieldName: 'Heading Type',
				formElement: 'select',
				attributeName: 'as',
				choices: [ 'h1', 'h2', 'h3', 'h4', 'h5', 'h6' ]
			},
			{
				dataType: 'string',
				required: false,
				fieldName: 'Text Alignment',
				formElement: 'select',
				attributeName: 'textAlign',
				choices: [ 'left', 'center', 'right' ]
			},
			{
				dataType: 'string',
				required: false,
				fieldName: 'Color',
				formElement: 'input',
				inputType: 'color',
				attributeName: 'color'
			}
		],
		allowed: true
	},
	{
		name: 'OrgLogo',
		component: OrgLogo,
		verbose_name: 'Organization Logo',
		attribute_fields: [
			{
				dataType: 'pk',
				required: true,
				fieldName: 'Organization',
				formElement: 'select',
				derivedFrom: 'organization',
				attributeName: 'organization'
			},
			{
				dataType: 'string',
				required: false,
				fieldName: 'Size',
				formElement: 'select',
				attributeName: 'size',
				choices: [ 'small', 'medium', 'large' ]
			}
		],
		allowed: true
	},
	{
		name: 'ReportingMonthTitle',
		component: ReportingMonthTitle,
		verbose_name: 'Reporting Month',
		attribute_fields: [
			{
				dataType: 'string',
				required: false,
				fieldName: 'Title Size',
				formElement: 'select',
				attributeName: 'as',
				choices: [ 'h1', 'h2', 'h3', 'h4', 'h5', 'h6' ]
			}
		],
		allowed: true
	},
	{
		name: 'ContentOverview',
		component: ContentOverview,
		verbose_name: 'Content Overview Table',
		attribute_fields: [
			{
				dataType: 'pk',
				required: true,
				fieldName: 'Organization',
				formElement: 'select',
				derivedFrom: 'organization',
				attributeName: 'organization'
			},
			{
				dataType: 'int',
				required: true,
				fieldName: 'Year',
				formElement: 'input',
				attributeName: 'year',
				inputType: 'number',
				derivedFrom: 'planningYear'
			},
			{
				dataType: 'int',
				required: true,
				fieldName: 'Month',
				formElement: 'input',
				attributeName: 'month',
				inputType: 'number',
				derivedFrom: 'planningMonth'
			},
			{
				dataType: 'string',
				required: true,
				fieldName: 'Component Header',
				formElement: 'input',
				attributeName: 'tableHeader',
				inputType: 'text'
			},
			{
				dataType: 'int',
				required: true,
				fieldName: 'Months Back',
				formElement: 'input',
				inputType: 'number',
				attributeName: 'monthsBack',
				initialValue: 0,
				helperText:
					'Adjust which month is used here. Use a negative number ex. -1 for a previous month, or a positive number for a future month.',
				additionalAttributes: {
					min: 0,
					max: 99,
					step: 1
				}
			}
		],
		allowed: true
	},
	{
		name: 'GoogleAnalyticsChart',
		component: GoogleAnalyticsChart,
		verbose_name: 'GA Chart',
		attribute_fields: [
			{
				dataType: 'pk',
				required: false,
				fieldName: 'Report',
				inputType: 'number',
				formElement: 'select',
				derivedFrom: 'report',
				attributeName: 'report'
			},
			{
				dataType: 'boolean',
				required: false,
				inputType: 'checkbox',
				fieldName: 'Include Table',
				formElement: 'checkbox',
				attributeName: 'includeTable'
			},
			{
				dataType: 'string',
				required: true,
				optional: true,
				inputType: 'text',
				fieldName: 'Chart Description',
				formElement: 'input',
				attributeName: 'chartDescription'
			}
		],
		PDFConvertToImage: true,
		allowed: true
	},
	{
		name: 'WhatConvertsChart',
		component: WhatConvertsChart,
		verbose_name: 'WhatConverts Chart',
		attribute_fields: [
			{
				dataType: 'pk',
				required: false,
				fieldName: 'Report',
				formElement: 'select',
				derivedFrom: 'report',
				attributeName: 'report'
			},
			{
				dataType: 'boolean',
				required: false,
				fieldName: 'Include Table',
				inputType: 'checkbox',
				formElement: 'checkbox',
				attributeName: 'includeTable'
			},
			{
				dataType: 'string',
				required: true,
				optional: true,
				inputType: 'text',
				fieldName: 'Chart Description',
				formElement: 'input',
				attributeName: 'chartDescription'
			},
			{
				dataType: 'string',
				required: false,
				fieldName: 'Show Only',
				formElement: 'select',
				multiple: true,
				clearable: true,
				attributeName: 'filters',
				choices: [ 'Chat', 'Email', 'Event', 'Other', 'Phone Call', 'Text Message', 'Transaction', 'Web Form' ]
			},
		],
		PDFConvertToImage: true,
		allowed: true
	},
	{
		name: 'AhrefsScreenshot',
		component: AhrefsScreenshot,
		verbose_name: 'Ahrefs Screenshot',
		attribute_fields: [
			{
				dataType: 'string',
				required: true,
				optional: true,
				inputType: 'text',
				fieldName: 'Screenshot Description',
				formElement: 'input',
				attributeName: 'screenshotDescription'
			},
			{
				dataType: 'string',
				required: true,
				fieldName: 'Screenshot Type',
				formElement: 'select',
				attributeName: 'screenshotType',
				explicitChoices: true,
				choices: [
					{
						key: 'referring_domains_screenshot',
						value: 'referring_domains_screenshot',
						text: 'Referring Domains'
					},
					{ key: 'referring_pages_screenshot', value: 'referring_pages_screenshot', text: 'Referring Pages' },
					{
						key: 'organic_keywords_screenshot',
						value: 'organic_keywords_screenshot',
						text: 'Organic Keywords'
					},
					{
						key: 'detailed_organic_keywords_screenshot',
						value: 'detailed_organic_keywords_screenshot',
						text: 'Detailed Organic Keywords'
					}
				]
			},
		],
		allowed: true
	},
	{
		name: 'GoogleReviewRequest',
		component: GoogleReviewRequest,
		verbose_name: 'Google Review Request',
		attribute_fields: [
			// {
			// 	dataType: 'boolean',
			// 	required: false,
			// 	optional: true,
			// 	inputType: 'checkbox',
			// 	fieldName: 'Use Iframe',
			// 	formElement: 'checkbox',
			// 	attributeName: 'useIframe'
			// },
			{
				dataType: 'string',
				required: false,
				optional: true,
				fieldName: 'Button Text',
				formElement: 'input',
				inputType: 'text',
				attributeName: 'buttonText',
			},
			{
				dataType: 'string',
				required: false,
				optional: true,
				inputType: 'url',
				fieldName: 'Alternate URL',
				formElement: 'input',
				attributeName: 'url'
			}
		],
		allowed: true
	},
	{
		name: 'ScheduleMeetingButton',
		component: ScheduleMeetingButton,
		verbose_name: 'Schedule Meeting',
		attribute_fields: [
			{
				dataType: 'string',
				required: false,
				optional: true,
				inputType: 'text',
				fieldName: 'Helper Text',
				formElement: 'input',
				attributeName: 'helperText'
			},
		],
		allowed: true
	}
];
