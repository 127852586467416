import React from "react";
import "./NotFound.css";
import { Header} from "semantic-ui-react";
import FourOhFour from "../../components/svgs/404";

const NotFound = () => {
  return (
    <div className="NotFound">
    <FourOhFour />
      <Header icon as="h1">
      Not Found
      <Header.Subheader>The page you're looking for does not exist.</Header.Subheader>
      </Header>
    </div>
  );
};

export default NotFound;
