import React, { Component } from "react";
import "./UserProfile.css";
import { connect } from "react-redux";
import { users } from "../../store/actions";
import { Icon, Input } from "semantic-ui-react";

class UserProfile extends Component {
  state = {
    data: {
      ...this.props.user,
      first_name: this.props.user.first_name,
      last_name: this.props.user.last_name,
      title: this.props.user.title,
      phone_number: this.props.user.phone_number,
      avatar: null,
    },
    editingField: null
  };
  
  handleChange(e) {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value
      }
    });
  }

  async handleBlur(e) {
    if (e.target.name === this.state.editingField) {
      await this.props.updateUser(this.props.user.id, this.state.data)
      this.setState({editingField: null})
    }
  }



  render() {
    const user = this.state.data;
    return (
      <div className="UserProfile">
        <div className="UserProfile__UsernameSection">
          <div className="UserProfile__Photo">
            <Icon inverted color="blue" name="user" circular />
          </div>
          <div className="UserProfile__Username">
            <strong>{user.name}</strong>
          </div>
        </div>
        {user.default_role && (
          <div className="UserProfileItem UserProfile__Role">
            <strong>Role: </strong>
            <span>{user.default_role.name}</span>
          </div>
        )}
        <div className="UserProfileItem UserProfile__Email">
          <strong>Email: </strong>
          <span>{user.email}</span>
        </div>
        <div
          onClick={() => this.setState({ editingField: "phone_number" })}
          className="UserProfileItem UserProfile__Phone isEditable"
        >
          <strong>Phone: </strong>
          {this.state.editingField === "phone_number" ? (
            <Input
              onBlur={e => this.handleBlur(e)}
              transparent
              name="phone_number"
              value={this.state.data.phone_number}
              onChange={e => this.handleChange(e)}
            />
          ) : (
            <span>{user.phone_number} <Icon color="blue" size="small" name="pencil" /></span>
          )}
        </div>
        <div onClick={() => this.setState({ editingField: "title" })} className="UserProfileItem UserProfile__Phone isEditable">
          <strong>Title: </strong>
          {this.state.editingField === "title" ? (
            <Input
            onBlur={e => this.handleBlur(e)}
              transparent
              name="title"
              value={this.state.data.title}
              onChange={e => this.handleChange(e)}
            />
          ) : (
            <span>{user.title} <Icon color="blue" size="small" name="pencil" /></span>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateUser: (id, data) => dispatch(users.updateUser(id, data))
    // updateUser: (id, firstName, lastName, title, phoneNumber) =>
    //   dispatch(users.updateUser(id, firstName, lastName, title, phoneNumber))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfile);
