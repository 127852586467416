import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    overviews: {
        data: [],
        isLoading: false,
        isLoaded: false,
        errors: null
    },
    messages: []
};

const addMessage = (state, action) => {
	return updateObject(state, {
		messages: state.messages.concat(action.message)
	});
};

const removeMessage = (state, action) => {
	let messagesList = state.messages.slice();
	messagesList.splice(0, 1);
	return updateObject(state, { messages: messagesList });
};

const startFetchAccountHealthOverviews = (state, action) => {
    return updateObject(state, {
            overviews: {
                ...state.overviews,
                isLoading: true,
                isLoaded: false,
                errors: null,
            }
        })
}
const fetchAccountHealthOverviewsSuccess = (state, action) => {
    return updateObject(state, {
            overviews: {
                ...state.overviews,
                data: state.overviews.data.concat(action.overviews),
                isLoading: false,
                isLoaded: true,
                errors: null,
            }
        })
}
const fetchAccountHealthOverviewsFailure = (state, action) => {
    return updateObject(state, {
            overviews: {
                ...state.overviews,
                isLoading: false,
                isLoaded: false,
                errors: action.errors,
            }
        })
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.ADD_MESSAGE:
			return addMessage(state, action);
		case actionTypes.REMOVE_MESSAGE:
            return removeMessage(state, action);
        case actionTypes.START_FETCH_ACCOUNT_HEALTH_OVERVIEWS:
            return startFetchAccountHealthOverviews(state, action);
        case actionTypes.FETCH_ACCOUNT_HEALTH_OVERVIEWS_SUCCESS:
            return fetchAccountHealthOverviewsSuccess(state, action);
        case actionTypes.FETCH_ACCOUNT_HEALTH_OVERVIEWS_FALURE:
            return fetchAccountHealthOverviewsFailure(state, action);
		default:
			return state;
	}
};

export default reducer;