import React from 'react';
import './Heading.css';
import { Header } from 'semantic-ui-react';

 const Heading = (props) => {
	return (
		<div className="Heading">
			<Header color={props.color} textAlign={props.textAlign} as={props.as ? props.as : 'h3'}>
				{props.content}
			</Header>
		</div>
	);
};

export default Heading;