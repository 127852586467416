import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ui, presentations } from '../../store/actions';
import './PresentationCreator.css';
import { Grid, Tab, Icon, Menu, Button, Input } from 'semantic-ui-react';
import PresentationCreatorToolbar from './PresentationCreatorToolbar/PresentationCreatorToolbar';
import PresentationCreatorSlideTemplate from './PresentationCreatorSlideTemplate/PresentationCreatorSlideTemplate';
import ActionHeader from '../../components/ActionHeader/ActionHeader';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import DimmedLoader from '../../components/DimmedLoader/DimmedLoader';
// import { Prompt } from 'react-router';
import ErrorHandler from '../../components/ErrorHandler/ErrorHandler';
import { dynamicComponents } from '../../dynamicComponentMap';
import { Switch, Route, NavLink } from 'react-router-dom';
import PresentationCreatorPreview from './PresentationCreatorPreview/PresentationCreatorPreview';
import { moveArrayItem } from '../../utility';
import PresentationEntries from './PresentationEntries/PresentationEntries';
import PresentationSettings from './PresentationSettings/PresentationSettings';

const initialState = {
	name: '',
	object_id: null,
	content_type: null,
	is_master_template: false,
	slide_templates: [
		{
			title: 'Introduction',
			components: [],
			order: 0
		}
	]
};

class PresentationCreator extends Component {
	state = {
		activeSlideIndex: 0,
		prevSlideIndex: 0,
		presentation: initialState
	};

	componentDidMount() {
		// if (this.props.presentation) {
		// 	this.props.fetchSlideTemplates(this.props.presentation.id);
		// }
		if (this.props.presentation) {
			this.setState({ presentation: this.props.presentation });
		}
		if (!this.props.presentation && this.props.isMasterTemplate && this.props.masterTemplateContentObject) {
			this.setState({
				presentation: {
					...this.state.presentation,
					name: this.props.defaultName,
					object_id: null,
					content_type: this.props.masterTemplateContentObject.id,
					is_master_template: true
				}
			});
		} else if (!this.props.presentation && this.props.defaultName) {
			this.setState({
				presentation: {
					...this.state.presentation,
					name: this.props.defaultName
				}
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.presentation && this.props.presentation !== prevProps.presentation) {
			this.setState({
				presentation: this.props.presentation
			});
		}
		if (!this.props.presentation && this.props.defaultName !== prevProps.defaultName) {
			if (this.props.primaryDerivedKey && this.props.primaryDerivedKey !== prevProps.primaryDerivedKey) {
				this.setState({
					presentation: {
						...this.state.presentation,
						name: this.props.defaultName,
						object_id: this.props.primaryDerivedKey
					}
				});
			} else {
				this.setState({
					presentation: {
						...this.state.presentation,
						name: this.props.defaultName
					}
				});
			}
		}
	}

	handlePresentationNameChange = (e) => {
		this.setState({
			presentation: {
				...this.state.presentation,
				name: e.target.value
			}
		});
	};

	handleTabChange = async (e, data) => {
		await this.setState({
			prevSlideIndex: this.state.activeSlideIndex,
			activeSlideIndex: data.activeIndex
		});
		let activeTab = data.panes[this.state.activeSlideIndex];
		if (activeTab.menuItem.key === 'addSlide') {
			const createSlideName = () => {
				const newTemplates = this.state.presentation.slide_templates.filter((template) =>
					template.title.startsWith('New Slide')
				);
				if (newTemplates && newTemplates[0]) {
					const lastTemplate = newTemplates[newTemplates.length - 1];
					const templateNumber = Number(lastTemplate.title.charAt(lastTemplate.title.length - 1));

					return `New Slide ${templateNumber + 1}`;
				} else {
					return 'New Slide 1';
				}
			};
			const findHighestOrder = () => {
				const highestOrder = Math.max.apply(
					Math,
					this.state.presentation.slide_templates.map(function(template) {
						return template.order;
					})
				);
				return highestOrder;
			};
			await this.setState({
				presentation: {
					...this.state.presentation,
					slide_templates: this.state.presentation.slide_templates.concat({
						title: createSlideName(),
						components: [],
						order: findHighestOrder() + 1
					})
				}
			});
			this.setState({
				activeSlideIndex: this.state.presentation.slide_templates.length - 1
			});
		}
	};

	handleDeleteSlide = async (index) => {
		let newSlideTemplates = this.state.presentation.slide_templates.slice();
		newSlideTemplates.splice(index, 1);
		await this.setState({
			presentation: {
				...this.state.presentation,
				slide_templates: newSlideTemplates
			}
		});
		return this.setState({
			activeSlideIndex: this.state.presentation.slide_templates.length - 1
		});
	};

	handleMoveSlideUp = (slideIndex) => {
		const foundSlide = this.state.presentation.slide_templates.find((component, i) => i === slideIndex);
		if (!foundSlide) {
			console.log('slide not found');
			return;
		}
		const handleOrdering = (newOrder, oldOrder, itemOrder) => {
			if (oldOrder > newOrder) {
				if (itemOrder < oldOrder && itemOrder >= newOrder) {
					return itemOrder + 1;
				} else {
					return itemOrder;
				}
			} else {
				if (itemOrder <= newOrder && itemOrder > oldOrder) {
					return itemOrder - 1;
				} else {
					return itemOrder;
				}
			}
		};
		this.setState({
			presentation: {
				...this.state.presentation,
				slide_templates: this.state.presentation.slide_templates.map(
					(template, i) =>
						i === slideIndex
							? {
									...template,
									order: template.order - 1
								}
							: {
									...template,
									order: handleOrdering(foundSlide.order - 1, foundSlide.order, template.order)
								}
				)
			}
		});
	};

	handleMoveSlideDown = (slideIndex) => {
		const foundSlide = this.state.presentation.slide_templates.find((component, i) => i === slideIndex);
		if (!foundSlide) {
			console.log('slide not found');
			return;
		}
		const handleOrdering = (newOrder, oldOrder, itemOrder) => {
			if (oldOrder > newOrder) {
				if (itemOrder < oldOrder && itemOrder >= newOrder) {
					return itemOrder + 1;
				} else {
					return itemOrder;
				}
			} else {
				if (itemOrder <= newOrder && itemOrder > oldOrder) {
					return itemOrder - 1;
				} else {
					return itemOrder;
				}
			}
		};
		this.setState({
			presentation: {
				...this.state.presentation,
				slide_templates: this.state.presentation.slide_templates.map(
					(template, i) =>
						i === slideIndex
							? {
									...template,
									order: template.order + 1
								}
							: {
									...template,
									order: handleOrdering(foundSlide.order + 1, foundSlide.order, template.order)
								}
				)
			}
		});
	};

	handleTitleChange = (e, index) => {
		this.setState({
			presentation: {
				...this.state.presentation,
				slide_templates: this.state.presentation.slide_templates.map(
					(template, i) =>
						i === index
							? {
									...template,
									title: e.target.value
								}
							: template
				)
			}
		});
	};

	handleComponentDataChange = (name, value, componentIndex) => {
		this.setState({
			presentation: {
				...this.state.presentation,
				slide_templates: this.state.presentation.slide_templates.map(
					(template, i) =>
						i === this.state.activeSlideIndex
							? {
									...template,
									components: template.components.map((component, index) => {
										if (index === componentIndex) {
											const foundField = component.fields.find((f) => f.name === name);
											if (foundField) {
												return {
													...component,
													fields: component.fields.map(
														(field) =>
															field.name === name
																? {
																		...field,
																		data: value
																	}
																: field
													)
												};
											} else {
												return {
													...component,
													fields: component.fields.concat({
														name: name,
														data: value
													})
												};
											}
										} else {
											return component;
										}
									})
								}
							: template
				)
			}
		});
	};

	handleAddComponent = (componentData) => {
		this.setState({
			presentation: {
				...this.state.presentation,
				slide_templates: this.state.presentation.slide_templates.map(
					(template, i) =>
						i === this.state.activeSlideIndex
							? {
									...template,
									components: template.components.concat(componentData)
								}
							: template
				)
			}
		});
	};

	handleDeleteComponent = (componentIndex) => {
		let slideTemplateComponents = this.state.presentation.slide_templates
			.find((template, i) => i === this.state.activeSlideIndex)
			.components.slice();
		slideTemplateComponents.splice(componentIndex, 1);
		this.setState({
			presentation: {
				...this.state.presentation,
				slide_templates: this.state.presentation.slide_templates.map(
					(template, i) =>
						i === this.state.activeSlideIndex
							? {
									...template,
									components: slideTemplateComponents
								}
							: template
				)
			}
		});
	};

	// TODO implement async here and make sure UI loading works
	handleCreatePresentation = async () => {
		await this.props.addSlideDeckTemplate(this.state.presentation, 'Presentation Created.');
	};

	handleUpdatePresentation = async () => {
		await this.props.updateSlideDeckTemplate(
			this.props.presentation.id,
			this.state.presentation,
			'Presentation Updated.'
		);
	};

	shouldPrompt = () => {
		if (this.props.presentation && this.props.presentation.id && this.props.updating) {
			return this.props.presentation !== this.state.presentation ? true : false;
		} else if (this.props.creating && !this.props.presentation) {
			if (
				this.state.presentation.slide_templates !== initialState.slide_templates ||
				this.state.presentation.name !== this.props.defaultName
			) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	};

	handleComponentMoveUp = (componentIndex) => {
		const currentSlide = this.state.presentation.slide_templates.find(
			(component, i) => i === this.state.activeSlideIndex
		);
		if (currentSlide && componentIndex === 0) {
			return;
		}
		this.setState({
			presentation: {
				...this.state.presentation,
				slide_templates: this.state.presentation.slide_templates.map(
					(template, i) =>
						i === this.state.activeSlideIndex
							? {
									...template,
									components: moveArrayItem(template.components, componentIndex, componentIndex - 1)
								}
							: template
				)
			}
		});
	};

	handleComponentMoveDown = (componentIndex) => {
		const currentSlide = this.state.presentation.slide_templates.find(
			(component, i) => i === this.state.activeSlideIndex
		);
		if (currentSlide && currentSlide.components.length - 1 === componentIndex) {
			return;
		}
		this.setState({
			presentation: {
				...this.state.presentation,
				slide_templates: this.state.presentation.slide_templates.map(
					(template, i) =>
						i === this.state.activeSlideIndex
							? {
									...template,
									components: moveArrayItem(template.components, componentIndex, componentIndex + 1)
								}
							: template
				)
			}
		});
	};

	render() {
		let panes = this.state.presentation.slide_templates.sort((a, b) => a.order - b.order).map((template, i) => ({
			menuItem: (
				<Menu.Item key={template.title} className="PresentationCreator--slideTab">
					<span>
						<span className="PresentationCreator--slideTab__order">{template.order + 1}. </span>
						<span className="PresentationCreator--slideTab__label">{template.title}</span>
					</span>
					<div className="PresentationCreator--slideTabActions">
						{i !== 0 && (
							<div className="PresentationCreator--slideTabActions__move">
								<Button onClick={() => this.handleMoveSlideUp(i)} size="mini" color="teal" icon basic>
									<Icon name="arrow alternate circle up" />
								</Button>
							</div>
						)}
						{i !== this.state.presentation.slide_templates.length - 1 && (
							<div className="PresentationCreator--slideTabActions__move">
								<Button onClick={() => this.handleMoveSlideDown(i)} size="mini" color="teal" icon basic>
									<Icon name="arrow alternate circle down" />
								</Button>
							</div>
						)}
						{i !== 0 && (
							<ConfirmationModal
								handleConfirm={() => this.handleDeleteSlide(i)}
								buttonIcon="minus"
								buttonSize="mini"
								color="red"
								compact
								headerText="Delete Slide?"
								buttonClass="PresentationCreator--cancelButton"
								bodyText="Are you sure you want to delete this slide?"
							/>
						)}
					</div>
				</Menu.Item>
			),
			render: () => (
				<PresentationCreatorSlideTemplate
					isActive={i === this.state.activeSlideIndex}
					index={i}
					slideTemplate={template}
					handleTitleChange={this.handleTitleChange}
					derivedKeys={{ ...this.props.derivedKeys, presentationName: this.state.presentation.name }}
					handleDeleteComponent={this.handleDeleteComponent}
					handleComponentDataChange={this.handleComponentDataChange}
					updatingPresentation={this.props.updating}
					creatingPresentation={this.props.creating}
					handleComponentMoveUp={this.handleComponentMoveUp}
					handleComponentMoveDown={this.handleComponentMoveDown}
				/>
			)
		}));
		panes = panes.concat({
			menuItem: (
				<Menu.Item key="addSlide" className="PresentationCreator--addSlide">
					<span>Add Slide</span>{' '}
					<span className="PresentationCreator--addSlide__icon">
						<Icon name="plus" color="green" circular inverted size="tiny" />
					</span>
				</Menu.Item>
			),
			render: null
		});
		return (
			<div className="PresentationCreator">
				{/* <Prompt
					when={this.shouldPrompt()}
					message="You have unsaved changes, are you sure you want to leave?"
				/> */}
				{this.props.slideDeckPresentationErrors && (
					<ErrorHandler errors={this.props.slideDeckPresentationErrors} size="tiny" />
				)}
				{this.props.isAddingSlideDeckTemplate && <DimmedLoader text="Creating Presentation..." />}
				{this.props.presentation &&
				this.props.presentation.isUpdating && <DimmedLoader size="medium" text="Updating Presentation..." />}
				<ActionHeader
					headerText={
						<Input
							transparent
							value={this.state.presentation.name}
							onChange={(e) => this.handlePresentationNameChange(e)}
							className="PresentationCreator--title-input"
						/>
					}
				>
					<Button
						onClick={
							this.props.creating ? (
								() => this.handleCreatePresentation()
							) : (
								() => this.handleUpdatePresentation()
							)
						}
						positive
						size="small"
						labelPosition="right"
						icon="check"
						content={this.props.creating ? 'Create Presentation' : 'Save Changes'}
					/>
					<Button onClick={() => this.props.cancelCreation()} size="small">
						Cancel
					</Button>
				</ActionHeader>
				<div className="PresentationCreator--main-menu">
					<Menu>
						<NavLink to={`${this.props.match.url}`}>
							<Menu.Item
								name="edit"
								active={this.props.location.pathname === `${this.props.match.url}`}
								link
							>
								<Icon name="edit outline" />
								Edit
							</Menu.Item>
						</NavLink>
						<NavLink to={`${this.props.match.url}/settings`}>
							<Menu.Item
								name="settings"
								link
								active={this.props.location.pathname === `${this.props.match.url}/settings`}
							>
								<Icon name="cogs" />
								Settings
							</Menu.Item>
						</NavLink>
						{!this.props.creating &&
						this.props.entries &&
						this.props.entries[0] && (
							<NavLink to={`${this.props.match.url}/entries`}>
								<Menu.Item
									name="entries"
									link
									active={this.props.location.pathname === `${this.props.match.url}/entries`}
								>
									<Icon name="content" />
									Entries
								</Menu.Item>
							</NavLink>
						)}
						<NavLink to={`${this.props.match.url}/preview`}>
							<Menu.Item
								name="preview"
								link
								active={this.props.location.pathname === `${this.props.match.url}/preview`}
							>
								<Icon name="eye" />
								Preview
							</Menu.Item>
						</NavLink>
					</Menu>
				</div>
				<Switch>
					<Route
						exact
						path={`${this.props.match.path}`}
						render={() => (
							<section className="PresentationCreator--main">
								<Grid>
									<Grid.Row>
										<Grid.Column width={13}>
											<section className="PresentationCreator--slidesView">
												<Tab
													grid={{ paneWidth: 11, tabWidth: 5 }}
													activeIndex={this.state.activeSlideIndex}
													onTabChange={this.handleTabChange}
													menu={{ fluid: true, vertical: true }}
													menuPosition="left"
													panes={panes}
												/>
											</section>
										</Grid.Column>
										<Grid.Column width={3}>
											<section className="PresentationCreator--toolView">
												<PresentationCreatorToolbar
													components={dynamicComponents}
													handleAddComponent={this.handleAddComponent}
													derivedKeys={{
														...this.props.derivedKeys,
														presentationName: this.state.presentation.name
													}}
												/>
											</section>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</section>
						)}
					/>
					<Route
						exact
						path={`${this.props.match.path}/settings`}
						render={() => (
							<section className="PresentationCreator--settings">
								<PresentationSettings
									primarySettingsObject={this.props.primarySettingsObject}
									settingsComponent={this.props.settingsComponent}
								/>
							</section>
						)}
					/>
					{!this.props.creating &&
					this.props.entries &&
					this.props.entries[0] && (
						<Route
							exact
							path={`${this.props.match.path}/entries`}
							render={() => (
								<section className="PresentationCreator--entries">
									<PresentationEntries
										{...this.props}
										entries={this.props.entries}
										presentation={this.state.presentation}
										entriesComponent={this.props.entriesComponent}
									/>
								</section>
							)}
						/>
					)}
					<Route
						exact
						path={`${this.props.match.path}/preview`}
						render={() => (
							<section className="PresentationCreator--preview">
								<PresentationCreatorPreview presentation={this.state.presentation} />
							</section>
						)}
					/>
				</Switch>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		slide_templates: state.presentations.slide_templates.data,
		slideTemplatesLoading: state.presentations.slide_templates.isLoading,
		slideTemplatesLoaded: state.presentations.slide_templates.isLoaded,
		slideTemplateErrors: state.presentations.slide_templates.errors,
		isAddingSlideDeckTemplate: state.presentations.slideDeckTemplates.isAdding,
		newSlideDeckTemplate: state.presentations.slideDeckTemplates.newSlideDeckTemplate,
		slideDeckPresentationErrors: state.presentations.slideDeckTemplates.errors
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchUIComponents: () => dispatch(ui.fetchUIComponents()),
		fetchSlideTemplates: (slideDeckId) => dispatch(presentations.fetchSlideTemplates(slideDeckId)),
		addSlideDeckTemplate: (data, message) => dispatch(presentations.addSlideDeckTemplate(data, message)),
		updateSlideDeckTemplate: (id, data, message) =>
			dispatch(presentations.updateSlideDeckTemplate(id, data, message))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PresentationCreator);
