import React, { Component } from 'react';
import './PresentationCreatorPreviewSlide.css';
import PresentationCreatorPreviewComponent from '../PresentationCreatorPreviewComponent/PresentationCreatorPreviewComponent';
import { Segment, Dimmer, Loader, Header } from 'semantic-ui-react';

class PresentationCreatorPreviewSlide extends Component {
	state = {
		loadingData: false
	};

	handleStartLoad = () => {
		this.setState({
			loadingData: true
		});
	};

	handleFinishLoad = () => {
		this.setState({
			loadingData: false
		});
	};

	render() {
		const slide = this.props.slide;
		return (
			<div className="PresentationCreatorPreviewSlide">
				<Segment>
					{this.state.loadingData && (
						<Dimmer active inverted>
							<Loader size="large">Loading Slide Data</Loader>
						</Dimmer>
					)}
                    <Header className="PresentationCreatorPreviewSlide--header" as='h2' content={slide.title} />
					{slide.components ? (
						slide.components.map((component, i) => (
							<PresentationCreatorPreviewComponent
                                key={i}
                                loadingData={this.props.loadingData}
								componentData={component}
								handleStartLoad={this.handleStartLoad}
								handleFinishLoad={this.handleFinishLoad}
							/>
						))
					) : (
						<p>It seems this slide does not currently have any Components</p>
					)}
				</Segment>
			</div>
		);
	}
}

export default PresentationCreatorPreviewSlide;
