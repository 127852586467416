import React from 'react';
import { Message } from 'semantic-ui-react'
import "./PageMessage.css";

const PageMessage = (props) => {
    return (
        <div className="PageMessage">
        <Message positive>
            {props.children}
        </Message>
        </div>
    );
};

export default PageMessage;