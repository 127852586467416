import React, { Component } from "react";
import "./ArticleDetail.css";
import { contentGeneration } from "../../store/actions";
import { connect } from "react-redux";
import FormHeader from "../FormHeader/FormHeader";
import { Button, Icon, Form, Loader, Header } from "semantic-ui-react";
import moment from "moment";
import ArticleDetailItem from "./ArticleDetailItem/ArticleDetailItem";
import CommentDetail from "../../components/CommentDetail/CommentDetail";
import CommentForm from "../../components/CommentForm/CommentForm";
import DividerHeader from "../DividerHeader/DividerHeader";
import EditorialRequirementsDetail from "../EditorialRequirementsDetail/EditorialRequirementsDetail";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { DateInput } from "semantic-ui-calendar-react";
import FeedbackForm from "../FeedbackForm/FeedbackForm";
import FeedbackDetail from "../FeedbackDetail/FeedbackDetail";
import { hasMultipleAccounts, hasPermissions } from "../../utility";

class ArticleDetail extends Component {
  state = {
    showRequirements: false,
    showComments: false,
    scheduledDate: "",
    postedUrl: "",
    admin_draft_url: "",
    adminDraftUrlInvalid: false,
  };

  componentDidMount() {
    !this.props.contentStatuses[0] && this.props.fetchContentStatuses();
    this.props.fetchFeedback(this.props.article.id);
    this.props.fetchArticleChangelog(this.props.article.id);
    // this.props.article &&
    //   this.props.article.client &&
    //   this.props.fetchKeywords(this.props.article.client.id);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.article && prevProps.article.id !== this.props.article.id) {
      this.props.fetchFeedback(this.props.article.id);
      // if (
      //   this.props.article.client &&
      //   this.props.article.client.id !== prevProps.article.client.id
      // ) {
      //   this.props.fetchKeywords(this.props.article.client.id);
      // }
    }
  }

  handleGoogleDocSubmit = async (isSheet) => {
    let { article } = this.props;
    await this.props.createGoogleDoc(
      article.client.dba_name,
      article.planning_year_month.year,
      article.planning_year_month.month,
      article.title,
      article.id,
      article.project ? article.project.id : null,
      isSheet ? isSheet : null
    );
  };

  handleSubmitArticle = async (isReWriting) => {
    // const status = this.props.article.status ? this.props.article.status.order : null;
    if (isReWriting) {
      await this.props.updateArticleStatus(
        this.props.article.id,
        "reedit",
        "Article Submitted for Re-Editing"
        // null,
        // null
      );
    } else {
      await this.props.updateArticleStatus(
        this.props.article.id,
        "editing",
        "Article Submitted"
        // null,
        // null
      );
    }
    return !this.props.errors && this.props.handleClose();
  };

  handlePostArticle = async () => {
    // if (this.state.admin_draft_url.includes("/wp-admin/")) {
    //   this.setState({ adminDraftUrlInvalid: false });
    //   await this.props.updateArticleStatus(
    //     this.props.article.id,
    //     "post_qa",
    //     "Posting Complete",
    //     {
    //       admin_draft_url: this.state.admin_draft_url,
    //     }
    //   );
    //   return this.props.errors && this.props.handleClose();
    // } else {
    //   this.setState({ adminDraftUrlInvalid: true });
    // }
    this.setState({ adminDraftUrlInvalid: false });
    await this.props.updateArticleStatus(
      this.props.article.id,
      "post_qa",
      "Posting Complete",
      {
        admin_draft_url: this.state.admin_draft_url,
      }
    );
    return this.props.errors && this.props.handleClose();
  };

  handleQaArticle = async () => {
    await this.props.updateArticleStatus(
      this.props.article.id,
      "scheduled",
      "Post QA Complete",
      {
        scheduled_date: this.state.scheduledDate,
        live_url: this.state.postedUrl,
      }
      // this.state.scheduledDate,
      // null,
      // this.state.postedUrl
    );
    return !this.props.errors && this.props.handleClose();
  };

  handleEditSubmit = async (approved) => {
    if (this.props.user === this.props.article.final_approver) {
      if (approved) {
        await this.props.updateArticleStatus(
          this.props.article.id,
          "ready_to_post",
          "Article Approved"
          // null,
          // null,
          // null
        );
      } else {
        await this.props.updateArticleStatus(
          this.props.article.id,
          "rewrite",
          "Article Rejected"
          // null,
          // null,
          // null
        );
      }
    } else {
      if (approved) {
        await this.props.updateArticleStatus(
          this.props.article.id,
          "final_review",
          "Article Submitted for Editing"
          // null,
          // null,
          // null
        );
      } else {
        await this.props.updateArticleStatus(
          this.props.article.id,
          "rewrite",
          "Article Submitted for Editing"
          // null,
          // null,
          // null
        );
      }
    }
    // if (approved) {
    //   if (this.props.user === this.props.article.final_approver) {
    //     await this.props.updateArticleStatus(
    //       this.props.article.id,
    //       8,
    //       "Article Approved",
    //       null,
    //       null,
    //       null
    //     );
    //   } else {
    //     await this.props.updateArticleStatus(
    //       this.props.article.id,
    //       7,
    //       "Article Approved",
    //       null,
    //       null,
    //       null
    //     );
    //   }
    // } else {
    //   await this.props.updateArticleStatus(
    //     this.props.article.id,
    //     4,
    //     "Article Rejected",
    //     null,
    //     null,
    //     null
    //   );
    // }
    return !this.props.errors && this.props.handleClose();
  };

  handleReviewSubmit = async (approved) => {
    if (approved) {
      await this.props.updateArticleStatus(
        this.props.article.id,
        "ready_to_post",
        "Article Approved"
        // null,
        // null,
        // null
      );
    } else {
      await this.props.updateArticleStatus(
        this.props.article.id,
        "rewrite",
        "Article Rejected"
        // null,
        // null,
        // null
      );
    }
    return !this.props.errors && this.props.handleClose();
  };

  handleReEditSubmit = async () => {
    await this.props.updateArticleStatus(
      this.props.article.id,
      "final_review",
      "Article Submitted for Posting"
      // null,
      // null,
      // null
    );
    return !this.props.errors && this.props.handleClose();
  };

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSelectChange = (event, { name, value }) => {
    if (this.state[name] !== value) {
      this.setState({
        [name]: value,
      });
    }
  };

  validatePostForm = () => {
    if (this.state.scheduledDate && this.state.postedUrl) {
      return true;
    } else {
      return false;
    }
  };

  filterFeedback = (feedback) => {
    let articleFeedback = feedback.filter(
      (feedback) => feedback.article === this.props.article.id
    );
    const articleOrganizationID =
      this.props.article.organization && this.props.article.organization.id;
    const articleFinalApproverID =
      this.props.article.final_approver && this.props.article.final_approver.id;

    if (
      articleOrganizationID &&
      articleFinalApproverID &&
      hasPermissions(this.props.user, "update_contentarticle")
    ) {
      let filteredFeedback = articleFeedback.filter(
        (feedback) =>
          feedback.given_by.user_preferences &&
          feedback.given_by.user_preferences.default_organization &&
          feedback.given_by.user_preferences.default_organization.id ===
            articleOrganizationID
      );
      return filteredFeedback;
    } else {
      return articleFeedback;
    }
  };

  showAccountInfo = () => {
    const user = this.props.user;
    if (this.props.customerView) {
      if (hasMultipleAccounts(user)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  shouldShowComments = () => {
    if (!this.props.customerView) {
      return true;
    } else if (this.props.comments && this.props.comments[0]) {
      return true;
    }
    return false;
  };

  render() {
    let { article } = this.props;
    const articleFeedback =
      this.props.feedback &&
      this.props.feedback
        .filter((feedback) => feedback.article === article.id)
        .filter((feedback) => feedback.given_by);

    return (
      <div className="ArticleDetail">
        <FormHeader size="h3" headerText={article.title}>
          {!this.props.customerView && (
            <Button onClick={this.props.handleClose} size="mini" compact>
              Close
            </Button>
          )}
        </FormHeader>
        <div className="inlineButtonHolder">
          {(this.props.user.writer || this.props.user.is_staff) &&
            (this.props.toWrite || this.props.toRewrite) && (
              <ConfirmationModal
                handleConfirm={(isReWriting) =>
                  this.handleSubmitArticle(isReWriting)
                }
                headerText="Submit Content?"
                headerIcon="check"
                buttonIcon="check"
                buttonText="Submit Article"
                color="green"
                compact
                buttonSize="mini"
                isReWrite={this.props.toRewrite}
                bodyText="Are you sure you're done?"
              />
            )}
          {this.props.toReEdit && (
            <div className="ArticleDetail--reEditButton">
              <ConfirmationModal
                handleConfirm={() => this.handleReEditSubmit()}
                headerText="Submit Content?"
                headerIcon="check"
                buttonIcon="check"
                buttonText="Submit for Final Review"
                color="green"
                compact
                buttonSize="mini"
                bodyText="Are you sure you're done?"
              />
            </div>
          )}
        </div>
        {this.props.toPost &&
          article.poster &&
          article.poster.id === this.props.user.id && (
            <div className="postSubmit">
              <Form onSubmit={this.handlePostArticle} size="tiny">
                <Form.Input
                  width={10}
                  icon="globe"
                  type="url"
                  iconPosition="left"
                  name="admin_draft_url"
                  value={this.state.admin_draft_url}
                  placeholder="Draft URL"
                  onChange={(e) => this.handleChange(e)}
                  error={
                    this.state.adminDraftUrlInvalid
                      ? {
                          content:
                            "Please enter a valid Wordpress draft URL containing /wp-admin",
                          pointing: "below",
                        }
                      : false
                  }
                />
                <Button
                  disabled={!this.state.admin_draft_url}
                  size="tiny"
                  positive
                  type="submit"
                >
                  Posted
                </Button>
              </Form>
            </div>
          )}
        {this.props.toQaPost &&
          article.lead &&
          article.lead.id === this.props.user.id && (
            <Form onSubmit={this.handleQaArticle} size="tiny">
              {article.admin_draft_url && (
                <ArticleDetailItem heading="Admin Draft URL">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={article.admin_draft_url}
                  >
                    View Draft Here <Icon name="external alternate" />
                  </a>
                </ArticleDetailItem>
              )}
              <Form.Field width={10}>
                <DateInput
                  className="postingDate"
                  closable
                  name="scheduledDate"
                  placeholder="Scheduled For"
                  iconPosition="left"
                  dateFormat="YYYY-MM-DD"
                  value={this.state.scheduledDate}
                  onChange={this.handleSelectChange}
                />
              </Form.Field>
              <Form.Input
                width={10}
                icon="globe"
                type="url"
                iconPosition="left"
                name="postedUrl"
                value={this.state.postedUrl}
                placeholder="Live URL"
                onChange={(e) => this.handleChange(e)}
              />
              <Button
                disabled={!this.validatePostForm()}
                size="tiny"
                positive
                type="submit"
              >
                Posted
              </Button>
            </Form>
          )}
        {this.props.toEdit &&
          article.editor &&
          article.editor.id === this.props.user.id &&
          !this.props.readOnly && (
            <div className="ArticleDetail--FeedbackForm">
              <div className="ArticleDetail--FeedbackForm__header">
                <Header as="h3" textAlign="left">
                  Provide Feedback
                </Header>
              </div>
              {/* <div className="ArticleDetail--feedbackHelperLink">
                <a
                  href="https://www.321webmarketing.com/content-faqs/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon name="question circle" />
                  <span>How to Provide the Best Feedback</span>
                </a>
              </div> */}
              <FeedbackForm
                satisfaction={true}
                approval={true}
                feedbackRequired={true}
                articleID={article.id}
                handleStatusUpdate={this.handleEditSubmit}
              />
            </div>
          )}
        {(this.props.toReview ||
          hasPermissions(this.props.user, "add_feedback")) &&
          article.status.uid === "final_review" &&
          !this.props.readOnly && (
            <div className="ArticleDetail--FeedbackForm">
              <div className="ArticleDetail--FeedbackForm__header">
                <Header as="h3" textAlign="left">
                  Provide Feedback
                </Header>
              </div>
              {/* <div className="ArticleDetail--feedbackHelperLink">
              <a
                href="https://www.321webmarketing.com/content-faqs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="question circle" />
                <span>How to Provide the Best Feedback</span>
              </a>
            </div> */}
              <FeedbackForm
                satisfaction={true}
                approval={true}
                feedbackRequired={true}
                articleID={article.id}
                handleStatusUpdate={this.handleReviewSubmit}
              />
            </div>
          )}

        <section className="ArticleDetails">
          <div className="ArticleDetailItem--container">
            <ArticleDetailItem>
              {article.google_doc ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={article.google_doc}
                >
                  <Button icon primary size="mini">
                    View Google Doc <Icon name="google drive" />
                  </Button>
                </a>
              ) : !this.props.readOnly &&
                !(
                  this.props.user.default_role &&
                  this.props.user.default_role.name === "Customer"
                ) ? (
                <Button
                  onClick={() => this.handleGoogleDocSubmit()}
                  size="mini"
                  compact
                  loading={this.props.addingDoc && !this.props.addedDoc}
                  icon
                >
                  Create Doc <Icon name="google drive" />
                </Button>
              ) : (
                <Button icon disabled primary size="mini">
                  Doc Not Available <Icon name="google drive" />
                </Button>
              )}
            </ArticleDetailItem>

            <ArticleDetailItem>
              {article.google_sheet ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={article.google_sheet}
                >
                  <Button icon color="green" size="mini">
                    View Google Sheet <Icon name="google drive" />
                  </Button>
                </a>
              ) : !this.props.readOnly &&
                !(
                  this.props.user.default_role &&
                  this.props.user.default_role.name === "Customer"
                ) ? (
                <Button
                  onClick={() => this.handleGoogleDocSubmit(true)}
                  size="mini"
                  compact
                  loading={this.props.addingDoc && !this.props.addedDoc}
                  icon
                >
                  Create Sheet <Icon name="google drive" />
                </Button>
              ) : (
                <Button icon disabled primary size="mini">
                  Sheet Not Available <Icon name="google drive" />
                </Button>
              )}
            </ArticleDetailItem>
          </div>

          {this.showAccountInfo() && (
            <ArticleDetailItem
              heading="Website"
              action={
                this.props.editorialRequirements &&
                this.props.editorialRequirements[0] && (
                  <Button
                    onClick={() =>
                      this.setState({
                        showRequirements: !this.state.showRequirements,
                      })
                    }
                    size="mini"
                    basic
                    compact
                  >{`${
                    this.state.showRequirements ? "Hide" : "View"
                  } Requirements`}</Button>
                )
              }
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={article.client.domain}
              >
                {article.client.dba_name} <Icon name="external alternate" />
              </a>
              {this.props.editorialRequirements &&
                this.props.editorialRequirements[0] &&
                this.state.showRequirements && (
                  <div className="articleDetailItem--requirements">
                    <EditorialRequirementsDetail
                      requirements={this.props.editorialRequirements}
                    />
                  </div>
                )}
            </ArticleDetailItem>
          )}
          {this.props.toEdit && article.duedate_edit && (
            <ArticleDetailItem heading="Due Date">
              <div className="dueDateHeader">
                <Icon color="grey" name="calendar" />
                <span className="dueDateHeader--date">
                  {moment(article.duedate_write).format("MMM Do")}
                </span>
              </div>
            </ArticleDetailItem>
          )}
          {/* {!this.props.toReview &&
            !this.props.readOnly &&
            !this.props.customerView &&
            this.props.article.keywords &&
            this.props.article.keywords[0] && (
              <ArticleDetailItem
                heading="Focus Keyword(s)"
                isLoading={this.props.isLoadingKeywords}
                loadingText="Loading Keywords"
              >
                {this.props.article.keywords.map((kw) => {
                  const keyword = this.props.keywords.find(
                    (keyword) => keyword.id === kw
                  );
                  if (keyword) {
                    return (
                      <div
                        key={keyword.id}
                        className="ArticleDetailItem--content--item"
                      >
                        {keyword.keyword.name}
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </ArticleDetailItem>
            )} */}
          {!this.props.customerView && article.focus_keyword_plaintext && (
            <ArticleDetailItem heading="Focus Keyword">
              {article.focus_keyword_plaintext}
            </ArticleDetailItem>
          )}
          <ArticleDetailItem heading="Type">
            {article.content_type.name}
          </ArticleDetailItem>
          {!this.props.customerView && (
            <ArticleDetailItem heading="Word Count">
              {article.min_word_count}
            </ArticleDetailItem>
          )}
          {!this.props.customerView && article.milestone && (
            <ArticleDetailItem heading="Milestone">
              {article.milestone}
            </ArticleDetailItem>
          )}
          {article.planning_year_month && article.planning_year_month.id && (
            <ArticleDetailItem heading="Planning Month">
              {moment(article.planning_year_month.month, "MM").format("MMMM")}{" "}
              {article.planning_year_month.year}
            </ArticleDetailItem>
          )}
          {article.description && !this.props.brief && (
            <ArticleDetailItem heading="Description">
              {article.description}
            </ArticleDetailItem>
          )}
        </section>

        {this.props.toWrite && (
          <div className="dueDateHeader">
            <Icon color="grey" name="calendar" />
            <span className="dueDateHeader--date">
              Due: {moment(article.duedate_write).format("MMM Do")}
            </span>
          </div>
        )}

        {articleFeedback && articleFeedback[0] && (
          <section className="articleFeedbackView">
            <DividerHeader size="h4" icon="comments" iconColor="grey">
              Current Feedback
            </DividerHeader>
            {!this.props.isLoadingFeedback ? (
              <div className="articleFeedbackView--container">
                {articleFeedback.map((feedback) => (
                  <FeedbackDetail
                    key={feedback.id}
                    feedback={feedback}
                    user={this.props.user}
                  />
                ))}
              </div>
            ) : (
              <Loader active size="small">
                Loading Feedback
              </Loader>
            )}
          </section>
        )}

        {this.shouldShowComments() && (
          <React.Fragment>
            <Button
              className="commentToggle"
              size="mini"
              compact
              fluid
              onClick={() =>
                this.setState({ showComments: !this.state.showComments })
              }
            >{`${this.state.showComments ? "Hide" : "View"} Comments`}</Button>
            {this.props.comments && this.state.showComments && (
              <section className="articleDetailCommentSection">
                <div className="ContentArticleComments">
                  {this.props.comments && (
                    <div className="contentComments">
                      {this.props.comments.map((comment) => (
                        <CommentDetail key={comment.id} comment={comment} />
                      ))}
                    </div>
                  )}
                  <div className="contentCommentInput">
                    <CommentForm articleId={this.props.article.id} />
                  </div>
                </div>
              </section>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    addingDoc: state.contentGeneration.addingDoc,
    addedDoc: state.contentGeneration.addedDoc,
    contentStatuses: state.contentGeneration.contentStatuses,
    errors: state.contentGeneration.errors,
    user: state.auth.user,
    contentStaff: state.contentGeneration.contentStaff,
    feedback: state.contentGeneration.feedback,
    isLoadingKeywords: state.contentGeneration.isLoadingKeywords,
    isLoadingFeedback: state.contentGeneration.isLoadingFeedback,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateArticleStatus: (
      articleId,
      newStatus,
      message,
      args
      // scheduledDate,
      // writerDueDate,
      // postedUrl
    ) => {
      return dispatch(
        contentGeneration.updateArticleStatus(
          articleId,
          newStatus,
          message,
          args
          // scheduledDate,
          // writerDueDate,
          // postedUrl
        )
      );
    },
    createGoogleDoc: (
      client,
      year,
      month,
      title,
      articleId,
      projectId,
      isSheet
    ) => {
      return dispatch(
        contentGeneration.createGoogleDoc(
          client,
          year,
          month,
          title,
          articleId,
          projectId,
          isSheet
        )
      );
    },
    fetchContentStatuses: () => {
      return dispatch(contentGeneration.fetchContentStatuses());
    },
    fetchFeedback: (articleID) => {
      return dispatch(contentGeneration.fetchFeedback(articleID));
    },
    fetchArticleChangelog: (articleID) => {
      return dispatch(contentGeneration.fetchArticleChangelog(articleID));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleDetail);
