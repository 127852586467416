import React from 'react'
import "./PresentationTitle.css";
import { Header } from 'semantic-ui-react';

const PresentationTitle = (props) => {
    const title = props.presentation && props.presentation.name;
    return (
        <div className="PresentationTitle">
            <Header as={props.as ? props.as : 'h1'} size="huge" content={title ? title : '{{ Presentation Name }}'} />
        </div>
    )
}

export default PresentationTitle
