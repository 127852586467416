import React, { Component } from "react";
import { connect } from "react-redux";
import { contentGeneration } from "../../store/actions";
import "./ArticleHistoryChart.css";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  Label,
  Text,
} from "recharts";
import moment from "moment";
import { Header, Grid, Button, Icon, Loader } from "semantic-ui-react";
import { ArticleHistoryChartFigure } from "./ArticleHistoryChartFigure/ArticleHistoryChartFigure";
import { Link } from "react-router-dom";

export class ArticleHistoryChart extends Component {
  calculateWeeksBack = (date) => {
    // moment.relativeTimeThreshold('h', 1);
    moment.relativeTimeThreshold("d", 999);
    moment.updateLocale("en", {
      relativeTime: {
        hh: "This Week",
        d: "This Week",
        dd: (number) => {
          if (number <= 7) {
            return `This Week`;
          } else {
            const weeks = Math.round(number / 7);
            return `${weeks}w`;
          }
        },
      },
    });

    const dateParsed = moment(date);
    const weeksBack = dateParsed.fromNow(true);

    return weeksBack;
  };

  renderCustomLabel = (value) => {
    return moment(value).format("MMM D, YYYY");
  };

  renderCustomTick = (value) => {
    return moment(value).format("M/D");
  };

  calculateDataFloor = (data, param) => {
    const result = data.reduce((prev, curr) => {
      return prev[param] < curr[param] ? prev : curr;
    });
    return result[param];
  };

  // calculateCurrentCompletedCount = (data) => {
  //   const daysBack = data.length >= 7 ? 7 : data.length
  //   const currentWeekComplete = data[data.length-1]
  //   const lastWeekComplete = data[data.length-daysBack]

  //   if (currentWeekComplete && lastWeekComplete) {
  //     const calculatedComplete = currentWeekComplete.completed - lastWeekComplete.completed
  //     console.log('calculatedComplette is: ', calculatedComplete)
  //     return calculatedComplete
  //   } else {
  //     return null
  //   }
  // }

  render() {
    const { historyData, targetHistoryStatus, targetContentType } = this.props;
    let data =
      historyData &&
      historyData[0] &&
      historyData
        // .filter((historySet) => historySet.status.order === targetHistoryStatus)
        .filter((historySet) => historySet.status.uid === targetHistoryStatus)
        .reverse();

    if (targetContentType) {
      data = data.filter(
        (historySet) => historySet.content_type && historySet.content_type.name === targetContentType
      );
    }


    const formattedData =
      data &&
      data.map((d) => ({
        ...d,
        asOfDate: {
          formatted: moment(d.as_of_date).format("MM/DD"),
          raw: d.as_of_date,
        },
        incomplete_count: d.late_count
          ? d.late_count + d.incomplete_count
          : d.incomplete_count,
        Completed: d.complete_count,
        Incomplete: d.late_count
          ? d.late_count + d.incomplete_count
          : d.incomplete_count,
        Late: d.late_count,
      }));
    const currentWeekData =
      data &&
      data
        .map((d) => ({
          ...d,
          incomplete_count: d.late_count
            ? d.late_count + d.incomplete_count
            : d.incomplete_count,
        }))
        .reverse()[0];
    const targetStatus = currentWeekData && currentWeekData.status;
    let targetStatusName = targetStatus && targetStatus.name;
    if (targetContentType) {
      targetStatusName = targetContentType
    }
    const targetStatusOffset =
      currentWeekData &&
      currentWeekData.status &&
      currentWeekData.status.due_date_day_offset;

    return (
      <div className="ArticleHistoryChart">
        {currentWeekData && currentWeekData.status ? (
          <React.Fragment>
            <div className="ArticleHistoryChart--headerArea">
              <Link to={`/content-generation/articles/list?contentStatus=${targetStatus.uid}`}><Header as="h3">{targetStatusName}</Header></Link>
              <div className="ArticleHistoryChart--ctaArea">
                <Button
                  onClick={() =>
                    this.props.articleHistorySetRefresh(
                      currentWeekData.id,
                      currentWeekData.status.uid
                    )
                  }
                  size="tiny"
                  compact
                  icon
                  // loading={
                  //   this.props.isRefreshing === currentWeekData.status.uid
                  // }
                  loading={
                    this.props.isRefreshing
                  }
                  disabled={this.props.isRefreshing}
                >
                  <Icon name="refresh" />
                </Button>
                {/* <Link
                  to={`/content-generation/articles/list?contentStatus=${targetStatus.uid}`}
                >
                  <Button size="tiny" compact>
                    View Articles
                  </Button>
                </Link> */}
              </div>
            </div>
            <div className="ArticleHistoryChart--insights">
              <Grid columns="equal">
                <Grid.Row>
                  {currentWeekData ? (
                    <Grid.Column>
                      <ArticleHistoryChartFigure
                        count={currentWeekData.late_count}
                        countType="late"
                        countTypeVerbose="Overdue"
                        offset={targetStatusOffset}
                      />
                    </Grid.Column>
                  ) : null}
                  <Grid.Column>
                    <ArticleHistoryChartFigure
                      count={currentWeekData.incomplete_count}
                      countType="incomplete"
                      countTypeVerbose="Incomplete"
                    />
                  </Grid.Column>
                  {/* <Grid.Column>
                    <ArticleHistoryChartFigure
                      count={currentWeekData.complete_count}
                      countType="complete"
                      countTypeVerbose="Complete"
                    />
                  </Grid.Column> */}
                </Grid.Row>
              </Grid>
            </div>
            <div className="ArticleHistoryChart--chart">
              <ResponsiveContainer width="100%" height={175}>
                <AreaChart
                  data={formattedData}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs>
                    <linearGradient id="late" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#F5153D" stopOpacity={0.7} />
                      <stop
                        offset="95%"
                        stopColor="#F5153D"
                        stopOpacity={0.3}
                      />
                    </linearGradient>
                    <linearGradient id="incomplete" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#14aaf5" stopOpacity={0.7} />
                      <stop
                        offset="95%"
                        stopColor="#14aaf5"
                        stopOpacity={0.3}
                      />
                    </linearGradient>
                    {/* <linearGradient id="completed" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#00bf9c" stopOpacity={0.7} />
                      <stop
                        offset="95%"
                        stopColor="#00bf9c"
                        stopOpacity={0.3}
                      />
                    </linearGradient> */}
                  </defs>
                  <YAxis
                    tick={false}
                    allowDataOverflow
                    type="number"
                    // domain={[
                    //   this.calculateDataFloor(formattedData, "Incomplete") - 100,
                    //   "auto",
                    // ]}
                    label={
                      <Text x={0} y={0} dx={25} dy={125} offset={0} angle={-90}>
                        Articles
                      </Text>
                    }
                  />
                  <XAxis
                    domain={["auto", "auto"]}
                    dataKey="asOfDate.raw"
                    tickFormatter={this.renderCustomTick}
                    tick={false}
                  >
                    <Label value="Date" position="insideBottom" offset={0} />
                  </XAxis>
                  <Tooltip labelFormatter={this.renderCustomLabel} />
                  {/* <Area
                    type="monotone"
                    dataKey="Completed"
                    stroke="#00bf9c"
                    fillOpacity={1}
                    fill="url(#completed)"
                    stackId="1"
                  /> */}
                  <Area
                    type="monotone"
                    dataKey="Incomplete"
                    stroke="#14aaf5"
                    fillOpacity={1}
                    fill="url(#incomplete)"
                    stackId="1"
                  />
                  <Area
                    type="monotone"
                    dataKey="Late"
                    stroke="#F5153D"
                    fillOpacity={1}
                    fill="url(#late)"
                    stackId="1"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </React.Fragment>
        ) : (
          <Loader active />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isRefreshing: state.contentGeneration.articleHistorySets.isRefreshing,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    articleHistorySetRefresh: (id, status) =>
      dispatch(contentGeneration.articleHistorySetRefresh(id, status)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArticleHistoryChart);
