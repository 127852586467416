import React, { Component } from "react";
import { connect } from "react-redux";
import { appManagement, clientOnboarding } from "../../store/actions";
import ErrorHandler from "../ErrorHandler/ErrorHandler";
import "./AppCreateForm.css";
import { Form, Select, Item, Loader } from "semantic-ui-react";

const initialState = {
  websiteBuild: null,
  childTheme: null
};

class AppCreateForm extends Component {
  state = {
    form: initialState
  };

  componentDidMount() {
    !this.props.websiteBuilds[0] && this.props.fetchWebsiteBuilds();
    !this.props.childThemes[0] && this.props.fetchChildThemes();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.initializingApp !== prevProps.initializingApp &&
      this.props.apps.length > prevProps.apps.length
    ) {
      this.props.closeForm();
    }
  }

  clearForm = () => {
    this.setState({ form: initialState });
  };

  handleChange(e) {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value
      }
    });
  }

  handleSelectChange = (event, { name, value }) => {
    this.setState({ form: { ...this.state.form, [name]: value } });
  };

  handleSubmit = async e => {
    e.preventDefault();
    if (this.state.form.websiteBuild && this.state.form.childTheme) {
      await this.props.initializeApp(
        this.state.form.websiteBuild,
        this.state.form.childTheme
      );
    }
  };

  render() {
    return (
      <div className="AppCreateForm">
        {this.props.errors && <ErrorHandler errors={this.props.errors} />}
        {!this.props.initializingApp ? (
          <Form onSubmit={this.handleSubmit} className="AppCreateForm--form">
            <Form.Group>
              <Form.Field width={6}>
                <label htmlFor="websiteBuild">Website Build</label>
                <Select
                  loading={!this.props.websiteBuilds[0]}
                  id="websiteBuild"
                  name="websiteBuild"
                  placeholder="Choose a Website Build"
                  selection
                  onChange={this.handleSelectChange}
                  options={this.props.websiteBuilds
                    .filter(build => !build.new_application)
                    .map(build => ({
                      key: build.id,
                      text: build.label,
                      value: build.id
                    }))}
                  value={this.state.form.websiteBuild}
                />
              </Form.Field>
              <Form.Field width={6}>
                <label htmlFor="childTheme">Child Theme</label>
                <Select
                  className="AppCreateForm--childThemeOptions"
                  selection
                  loading={!this.props.childThemes[0]}
                  closeOnChange={true}
                  placeholder="Choose a Theme"
                  onChange={this.handleSelectChange}
                  name="childTheme"
                  options={
                    this.props.childThemes[0]
                      ? this.props.childThemes.map(theme => ({
                          key: theme.id,
                          text: theme.theme_name,
                          value: theme.id,
                          content: (
                            <Item className="AppCreateForm--childThemeOption">
                              <Item.Content verticalAlign="middle">
                                {theme.theme_name}
                              </Item.Content>
                              <Item.Image
                                size="medium"
                                src={theme.screenshot}
                                alt={theme.theme_name}
                              />
                            </Item>
                          )
                        }))
                      : []
                  }
                />
              </Form.Field>
              <Form.Button
                type="submit"
                disabled={
                  !this.state.form.childTheme || !this.state.form.websiteBuild
                }
                className="inlineButton"
                positive
              >
                Save and Create
              </Form.Button>
            </Form.Group>
          </Form>
        ) : (
          <div className="AppCreateForm--initializeLoader">
            <Loader inline active>Initializing Application</Loader>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    websiteBuilds: state.clientOnboarding.websiteBuilds.builds,
    childThemes: state.appManagement.childThemes.themes,
    errors: state.appManagement.applications.errors,
    initializingApp: state.appManagement.applications.initializingApp,
    apps: state.appManagement.applications.apps
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchChildThemes: () => dispatch(appManagement.fetchChildThemes()),
    fetchWebsiteBuilds: () => dispatch(clientOnboarding.fetchWebsiteBuilds()),
    initializeApp: (buildID, childTheme) =>
      dispatch(appManagement.initializeApp(buildID, childTheme))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppCreateForm);
