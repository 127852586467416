import React, { Component } from "react";
import "./ChecklistTemplate.css";
import { List, Button, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { checklists } from "../../store/actions";
import ChecklistTemplateItem from "./ChecklistTemplateItem/ChecklistTemplateItem";

const initialNewItem = {
  name: "",
  description: "",
  department: "",
  order: null,
  parent: null,
  default_assignee: ""
};

class ChecklistTemplate extends Component {
  state = {
    selectedItem: null,
    newItem: null,
    isShifting: false,
    isMeta: false
  };

  // componentDidMount() {
  //   document.addEventListener("keydown", this._handleKeyDown);
  //   document.addEventListener("keyup", this._handleKeyUp);
  // }

  // componentDidUpdate(prevProps) {
  //   if (this.props.items !== prevProps.items && this.state.newItem) {
  //     if (
  //       this.props.items.filter(
  //         item => item.order === this.state.newItem.data.order
  //       )
  //     ) {
  //       this.cancelAddingItem();
  //     }
  //   }
  // }

  // componentWillUnmount() {
  //   document.removeEventListener("keydown", this._handleKeyDown);
  //   document.removeEventListener("keyup", this._handleKeyUp);
  // }

  // _handleKeyDown = event => {
  //   if (event.shiftKey) {
  //     this.setState({ isShifting: true });
  //   } else {
  //     this.setState({ isShifting: false });
  //   }
  //   if (event.metaKey) {
  //     this.setState({ isMeta: true });
  //   } else {
  //     this.setState({ isMeta: false });
  //   }
  // };

  // _handleKeyUp = event => {
  //   this.setState({ isShifting: false, isMeta: false });
  // };

  startAddingItem = async (
    index,
    order,
    parentIndex,
    parentID,
    itemID,
    isTab
  ) => {
    if (isTab && !parentID) {
      this.setState({
        newItem: {
          index: null,
          parentIndex: index,
          newChild: true,
          childIndex: this.findChildCount(itemID)
            ? this.findChildCount(itemID)
            : null,
          data: {
            ...initialNewItem,
            order: this.findLastOrder(itemID)
              ? this.findLastOrder(itemID)
              : null,
            parent: itemID
          }
        }
      });
    } else {
      this.setState({
        newItem: {
          index: parentIndex !== undefined ? null : index,
          parentIndex: parentIndex !== undefined ? parentIndex : null,
          childIndex: parentIndex !== undefined ? index : null,
          data: {
            ...initialNewItem,
            order: order !== undefined ? order + 1 : null,
            parent: parentID ? parentID : initialNewItem.parent
          }
        }
      });
    }
  };

  handleIsAddingItem = () => {
    this.setState({
      newItem: { ...this.state.newItem, isAdding: true, added: false }
    });
  };

  handleAddedItem = () => {
    this.setState({
      newItem: { ...this.state.newItem, isAdding: false, added: true }
    });
  };

  cancelAddingItem = () => {
    this.setState({ newItem: null });
  };

  shouldRenderChildren = children => {
    if (children[0] || (this.state.newItem && this.state.newItem.newChild)) {
      return true;
    }
  };

  findChildCount = itemID => {
    if (this.props.items[0] && itemID) {
      let parent = this.props.items.find(item => item.id === itemID);
      if (parent.checklist_template_item_children) {
        return parent.checklist_template_item_children.length + 1;
      }
    }
  };

  findLastOrder = itemID => {
    if (this.props.items[0] && itemID) {
      let childItems = this.props.items.filter(item => item.parent === itemID);
      if (childItems && childItems.length > 0) {
        return (
          this.props.items
            .filter(item => item.parent === itemID)
            .sort((a, b) => a.order - b.order)
            .slice(-1)[0].order + 1
        );
      }
    }
  };

  nextSibling = currentItem => {
    if (currentItem.parent) {
      let sibling = this.props.items
        .filter(item => item.parent === currentItem.parent)
        .sort((a, b) => a.order - b.order)
        .find(item => item.order > currentItem.order);
      return sibling ? sibling.id : null;
    } else {
      let sibling = this.props.items
        .filter(item => !item.parent)
        .sort((a, b) => a.order - b.order)
        .find(item => item.order > currentItem.order);
      return sibling ? sibling.id : null;
    }
  };

  prevSibling = currentItem => {
    if (currentItem.parent) {
      let sibling = this.props.items
        .filter(item => item.parent === currentItem.parent)
        .sort((a, b) => b.order - a.order)
        .find(item => item.order < currentItem.order);
      return sibling ? sibling.id : null;
    } else {
      let sibling = this.props.items
        .filter(item => !item.parent)
        .sort((a, b) => b.order - a.order)
        .find(item => item.order < currentItem.order);
      return sibling ? sibling.id : null;
    }
  };

  handleReOrder = (itemToMove, direction, parent) => {
    let nextHighest = this.props.items
      .sort((a, b) => a.order - b.order)
      .find(item => item.order > itemToMove.order);
    let nextHighestSibling = parent
      ? this.props.items
          .filter(item => item.parent === parent)
          .sort((a, b) => a.order - b.order)
          .find(item => item.order > itemToMove.order)
      : null;
    let nextLowest = this.props.items
      .sort((a, b) => b.order - a.order)
      .find(item => item.order < itemToMove.order);
    let nextLowestSibling = parent
      ? this.props.items
          .filter(item => item.parent === parent)
          .sort((a, b) => b.order - a.order)
          .find(item => item.order < itemToMove.order)
      : null;
    if (direction === "up") {
      if (nextLowestSibling) {
        this.props.moveChecklistItem(
          itemToMove,
          this.props.list.id,
          nextLowestSibling.order
        );
      } else {
        nextLowest !== null &&
          this.props.moveChecklistItem(
            itemToMove,
            this.props.list.id,
            nextLowest.order
          );
      }
    }
    if (direction === "down") {
      if (nextHighestSibling) {
        this.props.moveChecklistItem(
          itemToMove,
          this.props.list.id,
          nextHighestSibling.order
        );
      } else {
        nextHighest !== null &&
          this.props.moveChecklistItem(
            itemToMove,
            this.props.list.id,
            nextHighest.order
          );
      }
    }
  };

  render() {
    return (
      <div className="ChecklistTemplate">
        {/* {this.props.messages[0] && (
          <ChecklistMessage>{this.props.messages}</ChecklistMessage>
        )} */}
        {this.props.items[0] ? (
          <List ordered verticalAlign="middle">
            {this.props.items
              .filter(item => !item.parent)
              .sort((a, b) => a.order - b.order)
              .map((item, i) => {
                return (
                  <React.Fragment key={item.tempID ? item.tempID : item.id}>
                    <ChecklistTemplateItem
                      key={item.tempID ? item.tempID : item.id}
                      index={i}
                      addingNewChild={
                        this.state.newItem && this.state.newItem.newChild
                      }
                      handleReOrder={this.handleReOrder}
                      isAddingItem={this.handleIsAddingItem}
                      handleAddedItem={this.handleAddedItem}
                      startAddingItem={this.startAddingItem}
                      nextSibling={this.nextSibling}
                      prevSibling={this.prevSibling}
                      item={item}
                      list={this.props.list}
                      deleteChecklistTemplateItem={
                        this.props.deleteChecklistTemplateItem
                      }
                      updateChecklistTemplateItem={
                        this.props.updateChecklistTemplateItem
                      }
                    >
                      {item.checklist_template_item_children &&
                        item.checklist_template_item_children[0] &&
                        this.props.items
                          .filter(child =>
                            item.checklist_template_item_children.includes(
                              child.id || child.tempID
                            )
                          )
                          .sort((a, b) => a.order - b.order)
                          .map((child, childIndex) => {
                            return (
                              <React.Fragment
                                key={child.tempID ? child.tempID : child.id}
                              >
                                <ChecklistTemplateItem
                                  key={child.id ? child.id : child.tempID}
                                  child
                                  parentIndex={i}
                                  parentID={item.id}
                                  index={childIndex}
                                  handleReOrder={this.handleReOrder}
                                  isAddingItem={this.handleIsAddingItem}
                                  handleAddedItem={this.handleAddedItem}
                                  startAddingItem={this.startAddingItem}
                                  nextSibling={this.nextSibling}
                                  prevSibling={this.prevSibling}
                                  item={child}
                                  list={this.props.list}
                                  deleteChecklistTemplateItem={
                                    this.props.deleteChecklistTemplateItem
                                  }
                                  updateChecklistTemplateItem={
                                    this.props.updateChecklistTemplateItem
                                  }
                                />
                                {this.state.newItem &&
                                  (this.state.newItem.childIndex ===
                                    childIndex &&
                                    this.state.newItem.parentIndex === i) && (
                                    <ChecklistTemplateItem
                                      new
                                      child
                                      parentIndex={i}
                                      newIndex={this.state.newItem.index}
                                      startAddingItem={this.startAddingItem}
                                      isAddingItem={this.handleIsAddingItem}
                                      handleAddedItem={this.handleAddedItem}
                                      item={this.state.newItem.data}
                                      itemMeta={this.state.newItem}
                                      list={this.props.list}
                                      cancelAddingItem={this.cancelAddingItem}
                                      addChecklistTemplateItem={
                                        this.props.addChecklistTemplateItem
                                      }
                                    />
                                  )}
                              </React.Fragment>
                            );
                          })}
                      {this.state.newItem &&
                        this.state.newItem.newChild &&
                        this.state.newItem.data.parent === item.id && (
                          <ChecklistTemplateItem
                            new
                            child
                            parentIndex={i}
                            newIndex={this.state.newItem.index}
                            startAddingItem={this.startAddingItem}
                            isAddingItem={this.handleIsAddingItem}
                            handleAddedItem={this.handleAddedItem}
                            item={this.state.newItem.data}
                            itemMeta={this.state.newItem}
                            list={this.props.list}
                            cancelAddingItem={this.cancelAddingItem}
                            addChecklistTemplateItem={
                              this.props.addChecklistTemplateItem
                            }
                          />
                        )}
                    </ChecklistTemplateItem>
                    {this.state.newItem &&
                      (this.state.newItem.index === i &&
                        !this.state.newItem.parentIndex) && (
                        <ChecklistTemplateItem
                          new
                          newIndex={this.state.newItem.index}
                          item={this.state.newItem.data}
                          startAddingItem={this.startAddingItem}
                          itemMeta={this.state.newItem}
                          isAddingItem={this.handleIsAddingItem}
                          handleAddedItem={this.handleAddedItem}
                          list={this.props.list}
                          cancelAddingItem={this.cancelAddingItem}
                          addChecklistTemplateItem={
                            this.props.addChecklistTemplateItem
                          }
                        />
                      )}
                  </React.Fragment>
                );
              })}
          </List>
        ) : this.state.newItem ? (
          <List celled verticalAlign="middle">
            <ChecklistTemplateItem
              new
              newIndex={this.state.newItem.index}
              item={this.state.newItem.data}
              startAddingItem={this.startAddingItem}
              itemMeta={this.state.newItem}
              isAddingItem={this.handleIsAddingItem}
              handleAddedItem={this.handleAddedItem}
              list={this.props.list}
              cancelAddingItem={this.cancelAddingItem}
              addChecklistTemplateItem={this.props.addChecklistTemplateItem}
            />
          </List>
        ) : (
          <Button
            basic
            size="small"
            icon
            labelPosition="left"
            fluid
            onClick={() => this.startAddingItem()}
          >
            <Icon name="plus" />
            Add New Item
          </Button>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    checklistTemplates: state.checklists.checklistTemplates,
    errors: state.checklists.checklistTemplateItems.errors,
    messages: state.checklists.checklistTemplateItems.messages,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addChecklistTemplateItem: (formData, checklistTemplateID, itemIndex) =>
      dispatch(
        checklists.addChecklistTemplateItem(
          formData,
          checklistTemplateID,
          itemIndex
        )
      ),
    updateChecklistTemplateItem: (formData, id, checklistTemplateID) =>
      dispatch(
        checklists.updateChecklistTemplateItem(
          formData,
          id,
          checklistTemplateID
        )
      ),
    moveChecklistItem: (id, checklistTemplateID, newOrder) =>
      dispatch(
        checklists.moveChecklistTemplateItem(id, checklistTemplateID, newOrder)
      ),
    deleteChecklistTemplateItem: (id, checklistTemplateID) =>
      dispatch(checklists.deleteChecklistTemplateItem(id, checklistTemplateID))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChecklistTemplate);
