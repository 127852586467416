import * as actionTypes from './actionTypes';
import wordpressClient from '../../axios-wordpress';

const formatDomain = (originalDomain, endpoint, params) => {
	let domain = originalDomain;
	if (!domain.endsWith('/')) {
		domain = domain + '/';
	}
	if (!domain.startsWith('https')) {
		domain = domain.replace('http', 'https');
	}
	let url = `${domain}${endpoint}`;
	if (params) {
		Object.entries(params).forEach(([ key, value ]) => {
			url = url.concat('&', `${key}=${value}`);
		});
	}

	return url;
};

export const addMessage = (message) => {
	return {
		type: actionTypes.ADD_MESSAGE,
		message: message
	};
};

export const removeMessage = (message, timeToWait) => {
	return {
		type: actionTypes.REMOVE_MESSAGE,
		message: message,
		timeToWait: timeToWait
	};
};

export const startFetchPosts = () => {
	return {
		type: actionTypes.START_FETCH_POSTS
	};
};

export const fetchPostsSuccess = (posts) => {
	return {
		type: actionTypes.FETCH_POSTS_SUCCESS,
		posts: posts
	};
};

export const fetchPostsFailure = (errors) => {
	return {
		type: actionTypes.FETCH_POSTS_FALURE,
		errors: errors
	};
};

export const fetchPosts = (organizationURL, params) => {
	return async (dispatch, getState) => {
		let isLoading = await getState().wordpress.posts.isLoading;
		dispatch(startFetchPosts());
		let url = `${organizationURL}/wp-json/wp/v2/posts?per_page=50`;
		if (params) {
			Object.entries(params).forEach(([ key, value ]) => {
				url = url.concat('&', `${key}=${value}`);
			});
		}
		!isLoading &&
			wordpressClient
				.get(url)
				.then((response) => {
					return dispatch(fetchPostsSuccess(response.data));
				})
				.catch((err) => {
					dispatch(fetchPostsFailure(err));
				});
	};
};

export const startFetchPost = () => {
	return {
		type: actionTypes.START_FETCH_POST
	};
};

export const fetchPostSuccess = (post) => {
	return {
		type: actionTypes.FETCH_POST_SUCCESS,
		post: post
	};
};

export const fetchPostFailure = (errors) => {
	return {
		type: actionTypes.FETCH_POST_FALURE,
		errors: errors
	};
};

export const fetchPost = (organizationURL, postID, params) => {
	return async (dispatch, getState) => {
		let isLoading = await getState().wordpress.currentPost.isLoading;
		dispatch(startFetchPost());
		let url = `${organizationURL}/wp-json/wp/v2/posts/${postID}`;
		if (params) {
			Object.entries(params).forEach(([ key, value ]) => {
				url = url.concat('&', `${key}=${value}`);
			});
		}
		!isLoading &&
			wordpressClient
				.get(url)
				.then((response) => {
					return dispatch(fetchPostSuccess(response.data));
				})
				.catch((err) => {
					dispatch(fetchPostFailure(err));
				});
	};
};

// Get Bulk posts

export const startFetchBulkAccountPosts = () => {
	return {
		type: actionTypes.START_BULK_ACCOUNT_POSTS
	};
};

export const fetchSingleBulkAccountPostsSuccess = (accountData) => {
	return {
		type: actionTypes.FETCH_SINGLE_BULK_ACCOUNT_POSTS_SUCCESS,
		accountData: accountData
	};
};

export const fetchBulkAccountPostsSuccess = (accountData) => {
	return {
		type: actionTypes.FETCH_BULK_ACCOUNT_POSTS_SUCCESS,
		accountData: accountData
	};
};

export const fetchBulkAccountPostsFailure = (accountData) => {
	return {
		type: actionTypes.FETCH_BULK_ACCOUNT_POSTS_FALURE,
		accountData: accountData
	};
};

export const fetchBulkAccountPosts = (accounts, params) => {
	return async (dispatch, getState) => {
		let isLoading = await getState().wordpress.allAccountPosts.isLoading;
		dispatch(startFetchBulkAccountPosts());
		const fetchAllAccountData = (accounts) => {
			accounts.forEach((account, i) => {
				const url = formatDomain(account.domain, 'wp-json/wp/v2/posts?per_page=50', params)

				wordpressClient
					.get(url)
					.then((response) => {
						const accountResponse = {
							account: account,
							endpoint: url,
							data: Array.isArray(response.data) ? response.data : null,
							errors: Array.isArray(response.data) ? null : 'There was most likely a Duo Issue'
						};
						if (i === (accounts.length - 1)) {
							fetchSingleBulkAccountPostsSuccess(accountResponse)
							return dispatch(fetchBulkAccountPostsSuccess());
						} else {
							return dispatch(fetchSingleBulkAccountPostsSuccess(accountResponse));
						}
					})
					.catch((err) => {
						const accountResponse = {
							account: account,
							endpoint: url,
							data: null,
							errors: err.message ? err.message : err
						};

						if (i === (accounts.length - 1)) {
							fetchSingleBulkAccountPostsSuccess(accountResponse)
							return dispatch(fetchBulkAccountPostsSuccess());
						} else {
							return dispatch(fetchSingleBulkAccountPostsSuccess(accountResponse));
						}
					});

			});
		};

		!isLoading && fetchAllAccountData(accounts);
	};
};

export const asyncFetchBulkAccountPosts = (accounts, params) => {
	return async (dispatch, getState) => {
		let isLoading = await getState().wordpress.allAccountPosts.isLoading;
		dispatch(startFetchBulkAccountPosts());

		const fetchAllAccountData = async (accounts) => {
			const accountData = [];

			for (const account of accounts) {
				const url = formatDomain(account.domain, 'wp-json/wp/v2/posts?per_page=50', params)

				await wordpressClient
					.get(url)
					.then((response) => {
						const accountResponse = {
							account: account,
							endpoint: url,
							data: Array.isArray(response.data) ? response.data : null,
							errors: Array.isArray(response.data) ? null : 'There was most likely a Duo Issue'
						};
						accountData.push(accountResponse);
						dispatch(fetchSingleBulkAccountPostsSuccess(accountResponse));
					})
					.catch((err) => {
						const accountResponse = {
							account: account,
							endpoint: url,
							data: null,
							errors: err.message ? err.message : err
						};
						accountData.push(accountResponse);
						dispatch(fetchSingleBulkAccountPostsSuccess(accountResponse));
					});
			}

			return accountData;
		};

		!isLoading &&
			fetchAllAccountData(accounts)
				.then((accountData) => {
					return dispatch(fetchBulkAccountPostsSuccess());
				})
				.catch(() => {
					return dispatch(fetchBulkAccountPostsFailure('There was an error fetching posts'));
				});
	};
};

// Get Wordpress User Details

export const startFetchWPUser = () => {
	return {
		type: actionTypes.START_FETCH_WP_USER
	};
};

export const fetchWPUserSuccess = (user) => {
	return {
		type: actionTypes.FETCH_WP_USER_SUCCESS,
		user: user
	};
};

export const fetchWPUserFailure = (errors) => {
	return {
		type: actionTypes.FETCH_WP_USER_FALURE,
		errors: errors
	};
};

export const fetchWPUser = (organizationURL, params) => {
	return async (dispatch, getState) => {
		let isLoading = await getState().wordpress.currentUser.isLoading;
		dispatch(startFetchWPUser());
		let url = `${organizationURL}/wp-json/wp/v2/users/me`;
		if (params) {
			Object.entries(params).forEach(([ key, value ]) => {
				url = url.concat('&', `${key}=${value}`);
			});
		}
		!isLoading &&
			wordpressClient
				.get(url)
				.then((response) => {
					return dispatch(fetchWPUserSuccess(response.data));
				})
				.catch((err) => {
					dispatch(fetchWPUserFailure(err));
				});
	};
};
