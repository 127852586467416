import React, { Component } from "react";
import { auth } from "../../../store/actions";
import { connect } from "react-redux";
import { Menu } from "semantic-ui-react";
import OrganizationDetail from "../../../components/OrganizationDetail/OrganizationDetail";
import UserProfile from "../../../components/UserProfile/UserProfile";
import "./UserSettings.css";
import PasswordChangeForm from "../../../components/PasswordChangeForm/PasswordChangeForm";
import UserPreferences from "../../../components/UserPreferences/UserPreferences";

class UserSettings extends Component {
  state = {
    activeItem: 1,
    views: [
      {
        id: 1,
        name: "Profile",
        isShowing: true,
        component: <UserProfile user={this.props.user} />
      },
      {
        id: 2,
        name: "Preferences",
        isShowing: false,
        component: <UserPreferences />
      },
      {
        id: 3,
        name: "Organization Info",
        isShowing: false,
        component: (
          <OrganizationDetail organization={this.props.user.user_preferences.default_organization} />
        )
      },
      {
        id: 4,
        name: "Change Password",
        isShowing: false,
        component: (
          <PasswordChangeForm  />
        )
      }
    ]
  };

  handleItemClick = (e, { id }) => this.setState({ activeItem: id });

  render() {
    const { activeItem } = this.state;
    return (
      <div className="UserSettings">
        <Menu pointing secondary>
          {this.state.views.map(view => {
            return (
              <Menu.Item
                key={view.id}
                id={view.id}
                name={view.name}
                active={activeItem === view.id}
                onClick={this.handleItemClick}
              />
            );
          })}
        </Menu>
        <div className="UserSettingsContent">
          {this.state.views.map(view => {
            if (this.state.activeItem === view.id) {
              return (
                <div key={view.id} className={`UserSettingsView ${view.name}`}>
                  {view.component}
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    errors: state.auth.errors,
    isChangingPassword: state.auth.isChangingPassword,
    didChangePassword: state.auth.didUpdate,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changePassword: (oldPassword, newPassword1, newPassword2) => {
      return dispatch(
        auth.changePassword(oldPassword, newPassword1, newPassword2)
      );
    },
    cancelPasswordChange: () => {
      dispatch(auth.changePasswordCancel());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSettings);
