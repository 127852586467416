import React, { Component } from "react";
import { Progress } from "semantic-ui-react";

export default class ProgressExampleIndicating extends Component {
  state = { percent: 0 };

  componentDidMount() {
      this.interval = this.state.percent < 100 ? setInterval(() => this.increment(), 100) : clearInterval(this.interval);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  increment = () =>
    this.setState({
      percent: this.state.percent + 4
    });
    

  render() {
    return (
      <div>
        <Progress percent={this.state.percent} indicating>
            Adding Planning Month
        </Progress>
      </div>
    );
  }
}
