import React, { Component } from 'react';
import ActionHeader from '../../../components/ActionHeader/ActionHeader';
import { Button, Icon, Tab, Search } from 'semantic-ui-react';
import FixedLoader from '../../../components/FixedLoader/FixedLoader';
import UserTable from '../../../components/UserTable/UserTable';
import _ from 'lodash';
import './UserDirectory.css';

class UserDirectory extends Component {
	state = {
		isSearching: false,
		searchValue: '',
		searchResults: []
	};

	resetSearch = () => this.setState({ isSearching: false, searchResults: [], searchValue: '' });

	handleResultSelect = (e, { result }) => {
		const user = this.props.users.find((user) => user.id === result.key);
		this.props.handleSelect(user);
	};

	handleSearchChange = (e, { value }) => {
		this.setState({ isSearching: true, searchValue: value });

		setTimeout(() => {
			if (this.state.searchValue.length < 1) return this.resetSearch();

			const re = new RegExp(_.escapeRegExp(this.state.searchValue), 'i');
			const isMatch = (result) => re.test(result.name);

			this.setState({
				isSearching: false,
				searchResults: _.filter(this.props.users, isMatch)
			});
		}, 300);
	};

	render() {
		let usersData = this.state.searchResults[0] ? this.state.searchResults : this.props.users;
		const panes = [
			{
				menuItem: {
					key: 'endUsers',
					icon: 'users',
					content: 'Account Users'
				},
				render: () => (
					<Tab.Pane>
						<UserTable
							basic="very"
							selectedItem={this.props.selectedUser}
							handleSelect={this.props.handleSelect}
							tableData={usersData.filter(
								(user) =>
									!user.is_staff &&
									!user.writer &&
									(user.default_role && user.default_role.name !== 'Contractor')
							)}
						/>
					</Tab.Pane>
				)
			},
			{
				menuItem: {
					key: 'writers',
					icon: 'pencil',
					content: 'Writers'
				},
				render: () => (
					<Tab.Pane>
						<UserTable
							basic="very"
							selectedItem={this.props.selectedUser}
							handleSelect={this.props.handleSelect}
							tableData={usersData.filter((user) => user.writer && !user.is_staff)}
						/>
					</Tab.Pane>
				)
			},
			{
				menuItem: {
					key: 'contractors',
					icon: 'handshake outline',
					content: 'Contractors'
				},
				render: () => (
					<Tab.Pane>
						<UserTable
							basic="very"
							selectedItem={this.props.selectedUser}
							handleSelect={this.props.handleSelect}
							tableData={usersData.filter(
								(user) => user.default_role && user.default_role.name === 'Contractor'
							)}
						/>
					</Tab.Pane>
				)
			},
			{
				menuItem: {
					key: 'staff',
					icon: 'user circle',
					content: 'Agency Users'
				},
				render: () => (
					<Tab.Pane>
						<UserTable
							basic="very"
							selectedItem={this.props.selectedUser}
							handleSelect={this.props.handleSelect}
							tableData={usersData.filter((user) => user.is_staff)}
						/>
					</Tab.Pane>
				)
			}
		];
		return (
			<div className="UserDirectory">
				<ActionHeader headerText="User Directory">
					<section className="UserDirectory--search">
						<Search
							fluid
							size="small"
							placeholder="Search Users"
							loading={this.state.isSearching || !this.props.users[0]}
							disabled={!this.props.users[0]}
							onResultSelect={this.handleResultSelect}
							onSearchChange={_.debounce(this.handleSearchChange, 500, {
								leading: true
							})}
							results={this.state.searchResults.map((result) => ({
								key: result.id,
								title: result.name
							}))}
							value={this.state.searchValue}
						/>
					</section>
					{!this.props.isInvitingUser && (
						<Button
							color="green"
							icon
							labelPosition="right"
							size="small"
							onClick={() => this.props.handleInvitingUser()}
						>
							<Icon name="add" />
							Invite User
						</Button>
					)}
				</ActionHeader>
				<section className="UserDirectory--tabs">
					{!this.props.isLoading && this.props.isLoaded ? <Tab panes={panes} /> : <FixedLoader />}
				</section>
			</div>
		);
	}
}

export default UserDirectory;
