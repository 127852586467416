import React, { Component } from 'react';
import { organizations } from '../../store/actions';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import './Organizations.css';
import OrganizationDirectory from '../../components/OrganizationDirectory/OrganizationDirectory';
import OrganizationForm from '../../components/OrganizationForm/OrganizationForm';
import Navbar from '../../components/Navbar/Navbar';
import WithSidebar from '../../hoc/WithSidebar/WithSidebar';
import queryString from 'query-string';

class Organizations extends Component {
	state = {
		selectedOrg: null,
		isAddingOrg: false
	};

	componentDidMount() {
		this.props.fetchOrganizations();
		const orgSearchParam = this.getOrgSearchParam(this.props.location.search);
		if (orgSearchParam) {
			this.handleSetOrg(orgSearchParam);
		}
	}

	componentDidUpdate(prevProps) {
		if (
			this.props.location.search !== prevProps.location.search ||
			this.props.organizations !== prevProps.organizations
		) {
			const orgSearchParam = this.getOrgSearchParam(this.props.location.search);
			if (orgSearchParam) {
				this.handleSetOrg(orgSearchParam);
			}
		}
	}

	handleAddOrganization = () => {
		this.setState({ isAddingOrg: true, selectedOrg: null });
	};

	cancelAction = () => {
		this.props.history.push({
			search: ''
		});
		this.setState({
			selectedOrg: null,
			isAddingOrg: false
		});
	};

	shouldSidebarShow = () => {
		if (this.state.selectedOrg || this.state.isAddingOrg) {
			return true;
		} else {
			return false;
		}
	};

	handleSelect = (org) => {
		this.props.history.push({
			search: `?organization=${org.slug}`
		});
		this.setState({ selectedOrg: org });
	};

	getOrgSearchParam = () => {
		const searchParams = queryString.parse(this.props.location.search);
		const orgSearchParamValue = searchParams.organization;

		if (orgSearchParamValue) {
			return orgSearchParamValue;
		}
	};

	handleSetOrg = async (orgSearchParam) => {
		const foundOrganization =
			(await this.props.organizations[0]) &&
			this.props.organizations.find((org) => org.id === orgSearchParam || org.slug === orgSearchParam);
		if (foundOrganization) {
			return this.setState({
				selectedOrg: foundOrganization,
				isAddingOrg: false
			});
		}
	};

	render() {
		return (
			<div className="RouteContainer">
				<Navbar messages={this.props.messages} matchPath={this.props.matchPath} routeHeader="Organizations" />
				<section className="Organizations">
					<WithSidebar
						leftWidth={9}
						rightWidth={7}
						isShowing={this.shouldSidebarShow()}
						sidebarComponent={
							<div className="organizationSidebar">
								<OrganizationForm
									isAddingOrg={this.state.isAddingOrg}
									selectedOrg={this.state.selectedOrg}
									cancelAction={this.cancelAction}
									archiveOrganization={this.props.archiveOrganization}
									errors={this.props.errors}
								/>
							</div>
						}
					>
						<Route
							path={`${this.props.match.path}/directory`}
							render={(props) => (
								<OrganizationDirectory
									{...props}
									organizations={this.props.organizations}
									isLoading={this.props.isLoading}
									isLoaded={this.props.isLoaded}
									addOrganization={this.handleAddOrganization}
									selectedOrg={this.state.selectedOrg}
									handleSelect={this.handleSelect}
								/>
							)}
						/>
					</WithSidebar>
				</section>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		organizations: state.organizations.organizations,
		isLoading: state.organizations.isLoading,
		isLoaded: state.organizations.isLoaded,
		isAddingOrganization: state.organizations.isAddingOrganization,
		errors: state.organizations.errors,
		messages: state.organizations.messages
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchOrganizations: () => {
			dispatch(organizations.fetchOrganizations(true));
		},
		archiveOrganization: (id, archive) => {
			return dispatch(organizations.archiveOrganization(id, archive));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Organizations);
