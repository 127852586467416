import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  authenticatedAsGhost: false,
  hostUser: null,
  hostUserToken: null,
  isLoading: false,
  waitingOnInitialAuth: true,
  isRegistering: false,
  groups: [],
  isLoadingGroups: false,
  groupsLoaded: false,
  user: null,
  errors: {},
  authRedirectPath: "/",
  isChangingPassword: false,
  didUpdate: false,
  showUserSettings: false,
  messages: [],
  isRequestingPasswordReset: false,
  passwordResetRequested: false,
  passwordResetSuccessful: true,
  isLoggingOut: false,
};

const addMessage = (state, action) => {
  return updateObject(state, {
    messages: state.messages.concat(action.message),
  });
};

const removeMessage = (state, action) => {
  let messagesList = state.messages.slice();
  messagesList.splice(0, 1);
  return updateObject(state, { messages: messagesList });
};

const authStart = (state, action) => {
  return updateObject(state, { isLoading: true, waitingOnInitialAuth: false });
};

const registerStart = (state, action) => {
  return updateObject(state, { isRegistering: true });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    isAuthenticated: true,
    isLoading: false,
    waitingOnInitialAuth: false,
    user: action.user,
    token: action.token,
    isRegistering: false,
    isLoggingOut: false,
  });
};

const authSuccessWithGhost = (state, action) => {
  return updateObject(state, {
    isAuthenticated: true,
    isLoading: false,
    waitingOnInitialAuth: false,
    user: action.ghostUser,
    token: action.ghostUserToken,
    authenticatedAsGhost: true,
    hostUser: action.user,
    hostUserToken: action.token,
    isRegistering: false,
    isLoggingOut: false,
  });
};

const authFail = (state, action) => {
  localStorage.removeItem("token");
  return updateObject(state, {
    errors: action.errors,
    isLoading: false,
    waitingOnInitialAuth: false,
    isAuthenticated: false,
    authenticatedAsGhost: false,
    hostUser: null,
    hostUserToken: null,
    isRegistering: false,
    isLoggingOut: false,
  });
};

const startAuthLogout = (state, action) => {
  return updateObject(state, {
    isLoggingOut: true,
  });
};

const authLogout = (state, action) => {
  localStorage.removeItem("token");
  return updateObject(state, {
    token: null,
    user: null,
    authenticatedAsGhost: false,
    hostUser: null,
    hostUserToken: null,
    isAuthenticated: false,
    isLoggingOut: false,
    waitingOnInitialAuth: false,
  });
};

const authCancel = (state, action) => {
  localStorage.removeItem("token");
  return updateObject(state, {
    token: null,
    user: null,
    isAuthenticated: false,
    authenticatedAsGhost: false,
    hostUser: null,
    hostUserToken: null,
    isLoading: false,
    isLoggingOut: false,
    waitingOnInitialAuth: false,
  });
};

const updateCurrentUser = (state, action) => {
  return updateObject(state, {
    user: action.user,
  });
};

const startGroupsFetch = (state, action) => {
  return updateObject(state, {
    isLoadingGroups: true,
    groupsLoaded: false,
    errors: null,
  });
};

const setGroups = (state, action) => {
  return updateObject(state, {
    groups: action.groups,
    errors: null,
    isLoadingGroups: false,
    groupsLoaded: true,
  });
};

const groupsFailure = (state, action) => {
  return updateObject(state, {
    errors: action.errors,
    isLoadingGroups: false,
    groupsLoaded: false,
  });
};

const changePasswordStart = (state, action) => {
  return updateObject(state, { isChangingPassword: true, errors: null });
};

const changePasswordCancel = (state, action) => {
  return updateObject(state, {
    isChangingPassword: false,
    errors: null,
    didUpdate: false,
  });
};

const changePasswordSuccess = (state, action) => {
  return updateObject(state, {
    didUpdate: true,
    isChangingPassword: false,
    errors: null,
  });
};

const changePasswordFail = (state, action) => {
  return updateObject(state, { didUpdate: false, errors: action.errors });
};

const startChangePasswordRequest = (state, action) => {
  return updateObject(state, {
    passwordResetRequested: false,
    isRequestingPasswordReset: true,
  });
};

export const passwordResetRequestSuccess = (state, action) => {
  return updateObject(state, {
    passwordResetRequested: true,
    errors: null,
    isRequestingPasswordReset: false,
  });
};

export const resetPasswordRequestFailure = (state, action) => {
  return updateObject(state, {
    passwordResetRequested: false,
    errors: action.errors,
  });
};

export const resetPasswordSuccess = (state, action) => {
  return updateObject(state, {
    passwordResetSuccessful: true,
    errors: null,
  });
};

export const resetPasswordFailure = (state, action) => {
  return updateObject(state, {
    passwordResetSuccessful: false,
    errors: action.errors,
  });
};

const setAuthRedirectPath = (state, action) => {
  return updateObject(state, { authRedirectPath: action.path });
};

const updateUserPreferencesSuccess = (state, action) => {
  return updateObject(state, {
    user: {
      ...state.user,
      user_preferences: action.userPreferences,
    },
    errors: null,
  });
};

const showUserSettings = (state, action) => {
  return updateObject(state, { showUserSettings: true });
};

const closeUserSettings = (state, action) => {
  return updateObject(state, { showUserSettings: false });
};

const startFetchCurrentAssignments = (state, action) => {
  return updateObject(state, {
    user: {
      ...state.user,
      isFetchingCurrentAssignments: true,
      didFetchCurrentAssignments: false,
      currentAssignments: null,
      currentAssignmentsErrors: null,
    },
  });
};
const fetchCurrentAssignmentsSuccess = (state, action) => {
  return updateObject(state, {
    user: {
      ...state.user,
      isFetchingCurrentAssignments: false,
      didFetchCurrentAssignments: true,
      currentAssignments: action.assignments,
      currentAssignmentsErrors: null,
    },
  });
};
const fetchCurrentAssignmentsFailure = (state, action) => {
  return updateObject(state, {
    user: {
      ...state.user,
      isFetchingCurrentAssignments: false,
      didFetchCurrentAssignments: false,
      currentAssignments: null,
      currentAssignmentsErrors: action.errors,
    },
  });
};

// Current Late Assignments
const startFetchLateAssignments = (state, action) => {
  return updateObject(state, {
    user: {
      ...state.user,
      isFetchingLateAssignments: true,
      didFetchLateAssignments: false,
      LateAssignments: null,
      LateAssignmentsErrors: null,
    },
  });
};
const fetchLateAssignmentsSuccess = (state, action) => {
  return updateObject(state, {
    user: {
      ...state.user,
      isFetchingLateAssignments: false,
      didFetchLateAssignments: true,
      LateAssignments: action.assignments,
      LateAssignmentsErrors: null,
    },
  });
};
const fetchLateAssignmentsFailure = (state, action) => {
  return updateObject(state, {
    user: {
      ...state.user,
      isFetchingLateAssignments: false,
      didFetchLateAssignments: false,
      LateAssignments: null,
      LateAssignmentsErrors: action.errors,
    },
  });
};

// Current Due Soon Assignments
const startFetchDueSoonAssignments = (state, action) => {
  return updateObject(state, {
    user: {
      ...state.user,
      isFetchingDueSoonAssignments: true,
      didFetchDueSoonAssignments: false,
      dueSoonAssignments: null,
      dueSoonAssignmentsErrors: null,
    },
  });
};
const fetchDueSoonAssignmentsSuccess = (state, action) => {
  return updateObject(state, {
    user: {
      ...state.user,
      isFetchingDueSoonAssignments: false,
      didFetchDueSoonAssignments: true,
      dueSoonAssignments: action.assignments,
      dueSoonAssignmentsErrors: null,
    },
  });
};
const fetchDueSoonAssignmentsFailure = (state, action) => {
  return updateObject(state, {
    user: {
      ...state.user,
      isFetchingDueSoonAssignments: false,
      didFetchDueSoonAssignments: false,
      dueSoonAssignments: null,
      dueSoonAssignmentsErrors: action.errors,
    },
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_MESSAGE:
      return addMessage(state, action);
    case actionTypes.REMOVE_MESSAGE:
      return removeMessage(state, action);
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.REGISTER_START:
      return registerStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_SUCCESS_WITH_GHOST:
      return authSuccessWithGhost(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.START_AUTH_LOGOUT:
      return startAuthLogout(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.AUTH_CANCEL:
      return authCancel(state, action);
    case actionTypes.UPDATE_CURRENT_USER_SUCCESS:
      return updateCurrentUser(state, action);
    case actionTypes.START_GROUPS_FETCH:
      return startGroupsFetch(state, action);
    case actionTypes.SET_GROUPS:
      return setGroups(state, action);
    case actionTypes.GROUPS_FAILURE:
      return groupsFailure(state, action);
    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action);
    case actionTypes.CHANGE_PASSWORD_START:
      return changePasswordStart(state, action);
    case actionTypes.CHANGE_PASSWORD_CANCEL:
      return changePasswordCancel(state, action);
    case actionTypes.CHANGE_PASSWORD_SUCCESS:
      return changePasswordSuccess(state, action);
    case actionTypes.CHANGE_PASSWORD_FAIL:
      return changePasswordFail(state, action);
    case actionTypes.START_CHANGE_PASSWORD_REQUEST:
      return startChangePasswordRequest(state, action);
    case actionTypes.PASSWORD_RESET_REQUEST_SUCCESS:
      return passwordResetRequestSuccess(state, action);
    case actionTypes.PASSWORD_RESET_REQUEST_FAILURE:
      return resetPasswordRequestFailure(state, action);
    case actionTypes.PASSWORD_RESET_SUCCESS:
      return resetPasswordSuccess(state, action);
    case actionTypes.PASSWORD_RESET_FAILURE:
      return resetPasswordFailure(state, action);
    case actionTypes.UPDATE_CURRENT_USER_PREFERENCES_SUCCESS:
      return updateUserPreferencesSuccess(state, action);
    case actionTypes.SHOW_USER_SETTINGS:
      return showUserSettings(state, action);
    case actionTypes.CLOSE_USER_SETTINGS:
      return closeUserSettings(state, action);
    case actionTypes.START_FETCH_CURRENT_ASSIGNMENTS:
      return startFetchCurrentAssignments(state, action);
    case actionTypes.FETCH_CURRENT_ASSIGNMENTS_SUCCESS:
      return fetchCurrentAssignmentsSuccess(state, action);
    case actionTypes.FETCH_CURRENT_ASSIGNMENTS_FAILURE:
      return fetchCurrentAssignmentsFailure(state, action);
    case actionTypes.START_FETCH_LATE_ASSIGNMENTS:
      return startFetchLateAssignments(state, action);
    case actionTypes.FETCH_LATE_ASSIGNMENTS_SUCCESS:
      return fetchLateAssignmentsSuccess(state, action);
    case actionTypes.FETCH_LATE_ASSIGNMENTS_FAILURE:
      return fetchLateAssignmentsFailure(state, action);
    case actionTypes.START_FETCH_DUE_SOON_ASSIGNMENTS:
      return startFetchDueSoonAssignments(state, action);
    case actionTypes.FETCH_DUE_SOON_ASSIGNMENTS_SUCCESS:
      return fetchDueSoonAssignmentsSuccess(state, action);
    case actionTypes.FETCH_DUE_SOON_ASSIGNMENTS_FAILURE:
      return fetchDueSoonAssignmentsFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
