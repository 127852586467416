import React, { Component } from 'react';
import './PresentationCreatorPreview.css';
import PresentationCreatorPreviewSlide from './PresentationCreatorPreviewSlide/PresentationCreatorPreviewSlide';
import { Tab, Menu } from 'semantic-ui-react';

export default class PresentationCreatorPreview extends Component {
	state = {
		activeSlideIndex: 0
	};

	handleTabChange = (e, data) => {
		this.setState({
			activeSlideIndex: data.activeIndex
		});
	};

	render() {
		const presentation = this.props.presentation;
		let panes = presentation.slide_templates.map((template, i) => ({
			menuItem: (
				<Menu.Item key={template.title} className="PresentationCreatorPreview--slideTab">
					<span className="PresentationCreatorPreview--slideTab__order"> {i + 1}. </span>
					<span className="PresentationCreatorPreview--slideTab__output"> {template.title}</span>
				</Menu.Item>
			),
			render: () => (
				<PresentationCreatorPreviewSlide
					isActive={i === this.state.activeSlideIndex}
					index={i}
					slide={template}
				/>
			)
		}));
		return (
			<div className="PresentationCreatorPreview">
				<section className="PresentationCreatorPreview--slidesView">
					<Tab
						activeIndex={this.state.activeSlideIndex}
						onTabChange={this.handleTabChange}
						menu={{ fluid: true, vertical: true }}
						menuPosition="left"
						panes={panes}
					/>
				</section>
			</div>
		);
	}
}
