import React, { Component } from "react";
import "./SystemNotifications.css";
import { notifications } from "../../store/actions";
import { connect } from "react-redux";
import moment from "moment";
import { Transition } from "semantic-ui-react";

class SystemNotifications extends Component {
  componentDidMount() {
    this.props.systemNotifications &&
      !this.props.systemNotifications[0] &&
      this.props.fetchSystemNotifications();
  }

  renderActiveNotification = () => {
    if (this.props.systemNotifications && this.props.systemNotifications[0]) {
      let activeNotification = null;
      this.props.systemNotifications.forEach((notification) => {
        const liveDate = moment(notification.live_date);
        const endDate = moment(notification.end_date);
        const now = moment();

        if (liveDate < now && endDate > now) {
          activeNotification = notification;
        }
      });
      return activeNotification;
    }
  };
  render() {
    return (
      <div className="SystemNotifications">
        <Transition.Group duration={1000} animation="slide down">
          {this.props.systemNotifications &&
            this.props.systemNotifications[0] &&
            this.renderActiveNotification() && (
              <div className="SystemNotifications--container">
                <div className="SystemNotifications--render">
                  <div
                    className="SystemNotifications--output"
                    dangerouslySetInnerHTML={{
                      __html: this.renderActiveNotification().message,
                    }}
                  ></div>
                  {this.props.closeNotifications && (
                    <div onClick={() => this.props.closeNotifications() } className="SystemNotifications--closeButton">
                      &times;
                    </div>
                  )}
                </div>
              </div>
            )}
        </Transition.Group>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    systemNotifications: state.notifications.systemNotifications.data,
    systemNotificationsLoading:
      state.notifications.systemNotifications.isLoading,
    systemNotificationsLoaded: state.notifications.systemNotifications.isLoaded,
    systemNotificationErrors: state.notifications.systemNotifications.errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSystemNotifications: () =>
      dispatch(notifications.fetchSystemNotifications()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SystemNotifications);
