import React, { Component } from "react";
import { connect } from "react-redux";
import { contentGeneration, organizations } from "../../../store/actions";
import "./ClientArchiveDashboard.css";
import FixedLoader from "../../../components/FixedLoader/FixedLoader";
import { Grid } from "semantic-ui-react";
import ArticleDetail from "../../../components/ArticleDetail/ArticleDetail";
import { hasPermissions, hasRole } from "../../../utility";
import NotFound from "../../../components/NotFound/NotFound";
import ClientContentDashboardTable from "../ClientContentDashboard/ClientContentDashboardTable/ClientContentDashboardTable";

class ClientArchiveDashboard extends Component {
  state = {
    selectedAssignment: null,
  };

  componentDidMount() {
    this.props.fetchContentStatuses();
    if (
      !hasPermissions(this.props.user, "add_contentarticle") &&
      this.props.user.org_roles[0]
    ) {
        this.props.fetchClientArticles();
      // this.props.fetchOrganizationArticles(
      //   this.props.user.org_roles[0].organization
      // );
    }
    !this.props.comments[0] && this.props.fetchContentComments();
    !this.props.editorialRequirements[0] &&
      this.props.fetchEditorialRequirements();
  }

  articlesLoaded = () => {
    if (!this.props.isLoadingArticles && this.props.articlesLoaded) {
      return true;
    } else {
      return false;
    }
  };

  handleAssignmentSelect = (assignment) => {
    this.setState({ selectedAssignment: assignment });
  };

  handleClose = () => {
    this.setState({ selectedAssignment: null });
  };

  isOrganizationAdmin = () => {
    const defaultOrg =
      this.props.user.user_preferences &&
      this.props.user.user_preferences.default_organization;
    const adminRole =
      this.props.groups[0] &&
      this.props.groups.find((group) => group.name === "Staff");

    if (defaultOrg && adminRole) {
      return hasRole(this.props.user, adminRole.id, defaultOrg.id);
    } else {
      return false;
    }
  };

  hasCorrespondingAssignment = (status) => {
    const user = this.props.user;
    const selectedArticle = this.state.selectedAssignment;
    if (user && selectedArticle) {
      if (status === "writing" || status === "rewrite") {
        const writer = user.writer;
        if (writer && writer.id) {
          if (
            selectedArticle.writer &&
            selectedArticle.writer.id === writer.id
          ) {
            return true;
          }
        }
      } else if (status === "editing" || status === "reedit") {
        if (selectedArticle.editor && selectedArticle.editor.id === user.id) {
          console.log("selectedArticle.editor is: ", selectedArticle.editor);
          return true;
        }
      } else if (status === "final_review") {
        if (
          selectedArticle.final_approver &&
          selectedArticle.final_approver.id === user.id
        ) {
          return true;
        }
      } else if (
        selectedArticle.poster &&
        selectedArticle.poster.id === user.id
      ) {
        return true;
      }
    }
  };

  render() {
    return (
      <div className="ClientArchiveDashboard">
        {this.props.user.default_role &&
        this.props.user.default_role.name === "Customer" ? (
          this.props.planningMonths[0] && this.articlesLoaded() ? (
            <Grid>
              <Grid.Row>
                <Grid.Column width={11}>
                  <ClientContentDashboardTable
                    planningMonths={this.props.planningMonths}
                    selectedItem={
                      this.state.selectedAssignment
                        ? this.state.selectedAssignment
                        : false
                    }
                    contentStatuses={this.props.contentStatuses}
                    handleSelect={this.handleAssignmentSelect}
                    // originalArticles={this.props.contentArticles.filter(
                    //   (d) => d.status.order >= 2 && d.status.order <= 7
                    // )}
                    originalArticles={this.props.contentArticles.filter(
                      (d) => d.status.uid !== 'on_hold'
                    ).sort((a, b) => a.status.order - b.status.order)}
                    tableData={this.props.contentArticles
                      .filter((d) => d.status.uid !== 'on_hold')
                      .sort((a, b) => a.status.order - b.status.order)}
                    // tableData={this.props.contentArticles.sort(
                    //     (a, b) =>
                    //         a.status.order - b.status.order
                    // )}
                  />
                </Grid.Column>
                {this.state.selectedAssignment && (
                  <Grid.Column width={5}>
                    <ArticleDetail
                      brief
                      customerView={
                        this.props.user.default_role &&
                        this.props.user.default_role.name === "Customer"
                      }
                      editorialRequirements={
                        this.props.editorialRequirements[0] &&
                        this.props.editorialRequirements.filter(
                          (req) =>
                            req.organization ===
                            this.state.selectedAssignment.client.id
                        )
                      }
                      comments={this.props.comments.filter(
                        (comment) =>
                          comment.article === this.state.selectedAssignment.id
                      )}
                      keywords={this.props.keywords}
                      fetchKeywords={this.props.fetchKeywords}
                      handleClose={this.handleClose}
                      article={this.state.selectedAssignment}
                      // readOnly={!this.isOrganizationAdmin()}
                      readOnly
                      toWrite={
                        this.state.selectedAssignment.status.uid ===
                          "writing" &&
                        this.hasCorrespondingAssignment("writing")
                      }
                      toRewrite={
                        this.state.selectedAssignment.status.uid ===
                          "rewrite" &&
                        this.hasCorrespondingAssignment("rewrite")
                      }
                      toEdit={
                        this.state.selectedAssignment.status.uid ===
                          "editing" &&
                        this.hasCorrespondingAssignment("editing")
                      }
                      toReEdit={
                        this.state.selectedAssignment.status.uid === "reedit" &&
                        this.hasCorrespondingAssignment("reedit")
                      }
                      toReview={
                        this.state.selectedAssignment.status.uid ===
                          "final_review" &&
                        this.hasCorrespondingAssignment("final_review")
                      }
                      toPost={
                        this.state.selectedAssignment.status.uid ===
                          "ready_to_post" &&
                        this.hasCorrespondingAssignment("ready_to_post")
                      }
                    />
                  </Grid.Column>
                )}
              </Grid.Row>
            </Grid>
          ) : (
            <FixedLoader />
          )
        ) : (
          <NotFound />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    groups: state.auth.groups,
    contentArticles: state.contentGeneration.contentArticles,
    keywords: state.contentGeneration.keywords,
    comments: state.contentGeneration.contentComments,
    editorialRequirements: state.organizations.editorialRequirements,
    planningMonths: state.contentGeneration.planningMonths,
    contentStatuses: state.contentGeneration.contentStatuses,
    isLoadingMonths: state.contentGeneration.isLoadingMonths,
    isLoadingArticles: state.contentGeneration.isLoadingArticles,
    articlesLoaded: state.contentGeneration.articlesLoaded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchContentStatuses: () => {
      return dispatch(contentGeneration.fetchContentStatuses());
    },
    fetchClientArticles: () => {
      return dispatch(contentGeneration.fetchClientArticles());
    },
    fetchOrganizationArticles: (orgID) => {
      return dispatch(contentGeneration.fetchOrganizationArticles(orgID));
    },
    fetchKeywords: () => {
      return dispatch(contentGeneration.fetchContentKeywords());
    },
    fetchContentComments: () => {
      return dispatch(contentGeneration.fetchContentComments());
    },
    fetchEditorialRequirements: () => {
      return dispatch(organizations.fetchEditorialRequirements());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientArchiveDashboard);
