import React, { Component } from 'react';
import './Presentation.css';
import { connect } from 'react-redux';
import { presentations } from '../../store/actions';
import { Loader, Menu, Tab, Header, Button, Icon, Popup } from 'semantic-ui-react';
import PresentationSlide from './PresentationSlide/PresentationSlide';
import axios from '../../axios-portal';
import queryString from 'query-string';
import PDFView from '../PDFView/PDFView';
import { copyToClipboard, hasPermissions } from '../../utility';

class Presentation extends Component {
	state = {
		presentation: null,
		objectInstance: null,
		loadingObjectInstance: false,
		loadedObjectInstance: false,
		activeSlideIndex: 0,
		storedData: {},
		didCopyURL: false
	};

	componentDidMount() {
		this.props.presentationID && this.props.fetchSlideDecks(this.props.presentationID);
		if (this.props.location.search !== '' && this.getSlideSearch(this.props.location.search)) {
			this.setState({
				...this.state,
				activeSlideIndex: this.getSlideSearch(this.props.location.search) - 1
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.props.location.search !== ('' || null || undefined) &&
			prevProps.location.search !== this.props.location.search
		) {
			if (this.getSlideSearch(this.props.location.search)) {
				this.setState({
					...this.state,
					activeSlideIndex: this.getSlideSearch(this.props.location.search) - 1
				});
			}
		}
		if (this.props.slideDecksLoading !== prevProps.slideDecksLoading && this.props.presentations[0]) {
			this.getPresentation();
		}
		if (!prevState.presentation && this.state.presentation && this.state.presentation.object_instance) {
			this.fetchObjectInstance();
		}
	}

	fetchObjectInstance = async () => {
		await this.setState({
			loadingObjectInstance: true,
			loadedObjectInstance: false
		});
		axios
			.get(this.state.presentation.object_instance)
			.then((response) => {
				return this.setState({
					objectInstance: response.data,
					loadingObjectInstance: false,
					loadedObjectInstance: true
				});
			})
			.catch((err) => {
				return this.setState({
					objectInstance: null,
					loadingObjectInstance: false,
					loadedObjectInstance: false
				});
			});
	};

	getPresentation = async () => {
		const presentation =
			(await this.props.presentationID) &&
			this.props.presentations[0] &&
			this.props.presentations.find((presentation) => presentation.id === Number(this.props.presentationID));
		return this.setState({
			presentation: presentation
		});
	};

	handleTabChange = (e, data) => {
		this.props.history.push({
			search: `?slide=${data.activeIndex + 1}`
		});
		// this.setState({
		// 	activeSlideIndex: data.activeIndex
		// });
	};

	getSlideSearch = () => {
		const slideSearch = queryString.parse(this.props.location.search);
		const slideSearchValue = slideSearch.slide;

		return slideSearchValue;
	};

	presentationFinishedLoading = () => {
		if (
			!this.props.isLoading &&
			this.props.isLoaded &&
			(!this.props.slideDecksLoading && this.props.slideDecksLoaded) &&
			this.props.presentations[0]
		) {
			if (this.state.presentation) {
				if (this.state.presentation.object_instance) {
					if (!this.state.loadingObjectInstance && this.state.loadedObjectInstance) {
						return true;
					} else {
						return false;
					}
				}
			} else {
				return false;
			}
			return true;
		} else {
			return false;
		}
	};

	storePresentationData = (dataKey, dataValue) => {
		if (!this.state.storedData[dataKey]) {
			this.setState({
				...this.state,
				storedData: {
					...this.state.storedData,
					[dataKey]: dataValue
				}
			});
		}
	};

	copyCurrentURLToClipboard = async () => {
		const url = `${window.location.origin}${window.location.pathname}`;
		await copyToClipboard(url);
		await this.setState({ didCopyURL: true });
		setTimeout(() => this.setState({ didCopyURL: false }), 2500);
	};

	render() {
		const slides =
			this.state.presentation &&
			this.state.presentation.slides[0] &&
			this.state.presentation.slides.sort((a, b) => a.order - b.order).map((slide) => ({
				menuItem: (
					<Menu.Item key={slide.id} className="Presentation--slideMenuItem">
						<span className="Presentation--slideMenuItem__count">{slide.order + 1}. </span>
						<span className="Presentation--slideMenuItem--title">{slide.title}</span>
					</Menu.Item>
				),
				component: (
					<PresentationSlide
						key={slide.id}
						slide={slide}
						withPDF
						active={this.props.activeSlideIndex}
						presentation={this.state.presentation}
						objectInstance={this.state.objectInstance}
						loadingObjectInstance={this.state.loadingObjectInstance}
						loadedObjectInstance={this.state.loadedObjectInstance}
						storePresentationData={this.storePresentationData}
						storedData={this.state.storedData}
					/>
				),
				render: () => (
					<PresentationSlide
						key={slide.id}
						slide={slide}
						active={this.props.activeSlideIndex}
						presentation={this.state.presentation}
						objectInstance={this.state.objectInstance}
						loadingObjectInstance={this.state.loadingObjectInstance}
						loadedObjectInstance={this.state.loadedObjectInstance}
						storePresentationData={this.storePresentationData}
						storedData={this.state.storedData}
					/>
				)
			}));

		return (
			<div className="Presentation">
				{this.presentationFinishedLoading() ? this.state.presentation ? (
					<section className="Presentation--main">
						<div className={`Presentation--header${this.props.withPDF ? ' withActions' : ''}`}>
							<Header as="h1" content={this.state.presentation.name} />
							{this.props.withPDF &&
							this.state.presentation &&
							slides && (
								<div className="Presentation--headerActions">
									{hasPermissions(this.props.user) && (
										<Popup
											trigger={
												<Button
													size="small"
													onClick={() => this.copyCurrentURLToClipboard()}
													basic
													icon
												>
													Copy URL to Clipboard <Icon name="clipboard outline" />
												</Button>
											}
											on="click"
											size="small"
											open={this.state.didCopyURL}
											position="top center"
											content={
												<span className="Presentation--clipboardCopySuccessMessage">
													Copied to Clipboard! <Icon color="green" name="checkmark" />
												</span>
											}
										/>
									)}
									<PDFView
										scale={0.7}
										withPreview
										paperSize="A4"
										title={this.state.presentation.name}
										trigger={
											<Button icon size="small" color="blue">
												Download as PDF <Icon name="file pdf outline" />
											</Button>
										}
										pages={slides.map((slide) => ({
											title: slide.title,
											sections: [
												{
													name: slide.title,
													content: slide.component
												}
											]
										}))}
									/>
								</div>
							)}
						</div>
						<Tab
							activeIndex={this.state.activeSlideIndex}
							onTabChange={this.handleTabChange}
							menu={{ secondary: true, pointing: true }}
							panes={slides}
						/>
					</section>
				) : (
					<p>Report Presentation Not Found</p>
				) : (
					<Loader active>Loading Presentation...</Loader>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		presentations: state.presentations.slideDecks.data,
		errors: state.presentations.slideDecks.errors,
		slideDecksLoading: state.presentations.slideDecks.isLoading,
		slideDecksLoaded: state.presentations.slideDecks.isLoaded,
		reportsLoading: state.reporting.isLoading,
		reportsLoaded: state.reporting.isLoaded
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchSlideDecks: (id) => dispatch(presentations.fetchSlideDecks(id))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
