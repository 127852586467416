import * as actionTypes from '../actions/actionTypes';
import axios from '../../axios-portal';

export const addMessage = (message) => {
	return {
		type: actionTypes.ADD_MESSAGE,
		message: message
	};
};

export const removeMessage = (message, timeToWait) => {
	return {
		type: actionTypes.REMOVE_MESSAGE,
		message: message,
		timeToWait: timeToWait
	};
};

export const authStart = () => {
	return {
		type: actionTypes.AUTH_START
	};
};

export const startGroupsFetch = () => {
	return {
		type: actionTypes.START_GROUPS_FETCH
	};
};

export const setGroups = (groups) => {
	return {
		type: actionTypes.SET_GROUPS,
		groups: groups
	};
};

export const groupsFailure = (errors) => {
	return {
		type: actionTypes.GROUPS_FAILURE,
		errors: errors
	};
};

export const registerStart = () => {
	return {
		type: actionTypes.REGISTER_START
	};
};

export const authSuccess = (token, user) => {
	return {
		type: actionTypes.AUTH_SUCCESS,
		token: token,
		user: user
	};
};

export const authSuccessWithGhost = (token, user, ghostUserToken, ghostUser) => {
	return {
		type: actionTypes.AUTH_SUCCESS_WITH_GHOST,
		token: token,
		user: user,
		ghostUserToken: ghostUserToken,
		ghostUser: ghostUser
	};
};

export const authFail = (errors) => {
	return {
		type: actionTypes.AUTH_FAIL,
		errors: errors
	};
};

export const authCancel = () => {
	return {
		type: actionTypes.AUTH_CANCEL
	};
};

export const startAuthLogout = () => {
  return {
    type: actionTypes.START_AUTH_LOGOUT,
  }
}

export const authLogout = () => {
	return { type: actionTypes.AUTH_LOGOUT };
};

export const startChangePasswordRequest = () => {
	return {
		type: actionTypes.START_CHANGE_PASSWORD_REQUEST
	};
};

export const changePasswordSuccess = () => {
	return { type: actionTypes.CHANGE_PASSWORD_SUCCESS };
};

export const changePasswordFail = (errors) => {
	return { type: actionTypes.CHANGE_PASSWORD_FAIL, errors: errors };
};

export const passwordResetRequestSuccess = () => {
	return {
		type: actionTypes.PASSWORD_RESET_REQUEST_SUCCESS
	};
};

export const passwordResetRequestFailure = (errors) => {
	return {
		type: actionTypes.PASSWORD_RESET_REQUEST_FAILURE,
		errors: errors
	};
};

export const passwordResetSuccess = () => {
	return {
		type: actionTypes.PASSWORD_RESET_SUCCESS
	};
};

export const passwordResetFailure = (errors) => {
	return {
		type: actionTypes.PASSWORD_RESET_FAILURE,
		errors: errors
	};
};

export const showUserSettings = () => {
	return {
		type: actionTypes.SHOW_USER_SETTINGS
	};
};

export const dismissUserSettings = () => {
	return {
		type: actionTypes.CLOSE_USER_SETTINGS
	};
};

export const login = (username, password, ghostUser) => {

	return (dispatch) => {
		dispatch(authStart());

		let body = JSON.stringify({ username, password });

		return axios
			.post(`rest-auth/login${ghostUser ? `/?as=${ghostUser}` : '/'}`, body)
			.then((response) => {
				if (ghostUser) {
					localStorage.setItem('token', response.data.ghost_user_token);
          localStorage.setItem('originalUserToken', response.data.token);
          if (response.data.ghost_user_token && response.data.ghost_user) {
            dispatch(
              authSuccessWithGhost(
                response.data.token,
                response.data.user,
                response.data.ghost_user_token,
                response.data.ghost_user
              )
            );
          } else {
            dispatch(authFail('Could not log in as user'));
          }
				} else {
					localStorage.setItem('token', response.data.token);
					dispatch(authSuccess(response.data.token, response.data.user));
				}

				return response.data;
			})
			.catch((err) => {
				dispatch(authFail(err));
			});
	};
};

export const register = (username, first_name, last_name, title, phone_number, password1, password2, email, key) => {
	return (dispatch) => {
		dispatch(registerStart());

		let body = JSON.stringify({
			username,
			first_name,
			last_name,
			title,
			phone_number,
			password1,
			password2,
			email,
			key
		});

		return axios
			.post(`rest-auth/registration/`, body)
			.then((response) => {
				localStorage.setItem('token', response.data.token);
				dispatch(authSuccess(response.data.token, response.data.user));
				return response.data;
			})
			.catch((err) => {
				dispatch(authFail(err.response.data));
			});
	};
};

export const logout = () => {
	return (dispatch) => {
    dispatch(startAuthLogout())
		return axios
			.post('rest-auth/logout/')
			.then((response) => {
				dispatch(authLogout());
        localStorage.removeItem('token');
        localStorage.removeItem('originalUserToken');
				return response.data;
			})
			.catch((err) => {
				dispatch(authFail(err.response.data));
				throw err.response.data;
			});
	};
};

export const fetchGroups = () => {
	return (dispatch) => {
		dispatch(startGroupsFetch());
		axios
			.get('groups.json')
			.then((response) => {
				return dispatch(setGroups(response.data));
			})
			.catch((err) => {
				return dispatch(groupsFailure(err));
			});
	};
};

export const authCheckState = () => {
	return async (dispatch, getState) => {
    const token = await localStorage.getItem('token');
    const originalUserToken = await localStorage.getItem('originalUserToken');

    if (originalUserToken) {
      localStorage.removeItem('token');
      localStorage.removeItem('originalUserToken');
    }

    dispatch(authStart());

		if (token) {
			return axios
				.get('rest-auth/user/')
				.then((response) => {
					dispatch(authSuccess(token, response.data));
					return response.data;
				})
				.catch((error) => {
          localStorage.removeItem('token');
          originalUserToken && localStorage.removeItem('originalUserToken');
					if (error.response) {
						// The request was made and the server responded with a status code
						// that falls out of the range of 2xx
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
						dispatch(authFail(error.response.data));
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						console.log('error request', error.request, error.message);
						dispatch(authFail(error.message));
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log('Error', error.message);
						dispatch(authFail(error.message));
					}
				});
		} else {
			dispatch(authCancel());
		}
	};
};

export const changePasswordStart = () => {
	return {
		type: actionTypes.CHANGE_PASSWORD_START
	};
};

export const changePasswordCancel = () => {
	return {
		type: actionTypes.CHANGE_PASSWORD_CANCEL
	};
};

export const changePassword = (oldPassword, newPassword1, newPassword2) => {
	return (dispatch) => {
		dispatch(changePasswordStart());
		let body = JSON.stringify({
			old_password: oldPassword,
			new_password1: newPassword1,
			new_password2: newPassword2
		});
		let message = 'Password Updated!';
		return axios
			.post('rest-auth/password/change/', body)
			.then((response) => {
				dispatch(addMessage(message));
				setTimeout(() => dispatch(removeMessage(message)), 2500);
				return dispatch(changePasswordSuccess());
			})
			.catch((err) => {
				dispatch(changePasswordFail(err.response.data));
			});
	};
};

export const resetPasswordRequest = (email) => {
	return (dispatch) => {
		dispatch(startChangePasswordRequest());
		const body = JSON.stringify({
			email: email
		});
		return axios
			.post('rest-auth/password/reset/', body)
			.then((response) => {
				return dispatch(passwordResetRequestSuccess());
			})
			.catch((err) => {
				dispatch(passwordResetRequestFailure(err.response.data));
			});
	};
};

export const resetPassword = (password1, password2, uid, token) => {
	return (dispatch) => {
		const body = JSON.stringify({
			new_password1: password1,
			new_password2: password2,
			uid: uid,
			token: token
		});
		return axios
			.post('rest-auth/password/reset/confirm/', body)
			.then((response) => {
				return dispatch(passwordResetSuccess());
			})
			.catch((err) => {
				dispatch(passwordResetFailure(err.response.data));
			});
	};
};

export const setAuthRedirectPath = (path) => {
	return {
		type: actionTypes.SET_AUTH_REDIRECT_PATH,
		path: path
	};
};
