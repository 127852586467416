import React from 'react';
import './UserDataItems.css';
import { Select, Input } from 'semantic-ui-react';

export const UserDataItems = (props) => {
	const items = props.items;
	const handleBlur = (e, item) => {
		if ((item.inputData ? item.inputData : item.data) !== e.target.value) {
			const updateData = !item.parentFor ? props.selectedUser : props.selectedUser.user_preferences
			item.updateMethod(props.selectedUser.id, {
				...updateData,
				[item.for]: e.target.value
			}, 'User Updated.')
		}
	};
	const handleSelectChange = (item, name, value) => {
		if ((item.inputData ? item.inputData : item.data) !== value) {
			const updateData = !item.parentFor ? props.selectedUser : props.selectedUser.user_preferences
			item.updateMethod(props.selectedUser.id, {
				...updateData,
				[item.for]: value
			}, 'User Updated.')
		} 

	};
	return (
		<div className="UserDataItems">
			{items.map((item) => (
				<div key={item.key} className="UserDataItem">
					<span className="UserDataItem__label">{item.name}</span>
					<span className="UserDataItem__content">
						{props.isEditingUser && item.editable ? item.options ? (
							<Select
								id={item.for}
								size="small"
								className="UserDataItem--field UserDataItem--dropdown"
								name={item.for}
								defaultValue={item.inputData ? item.inputData : item.data}
								selection
								options={item.options}
								onChange={(e, data) => handleSelectChange(item, data.name, data.value)}
							/>
						) : (
							<Input
								id={item.for}
								size="small"
								className="UserDataItem--field UserDataItem--input"
								name={item.for}
								type={item.type}
								defaultValue={item.data ? item.data : ''}
								onBlur={(e) => handleBlur(e, item)}
							/>
						) : (
							item.data ? item.data : <span className="UserDataItem--noData">-</span>
						)}
					</span>
				</div>
			))}
		</div>
	);
};
