import React from "react";
import { Button, Icon, Header } from "semantic-ui-react";
import "./Unauthorized.css";

const Unauthorized = props => {
  const handleBack = () => {
    props.history.goBack();
  };
  return (
    <div className="Unauthorized">
      <div className="Unauthorized--content">
        <Header as="h1">Unauthorized Route <Icon color="red" name="ban" /></Header>
        <p>If you believe this is an error and you should have access to this page please contact <strong>support@321webmarketing.com</strong></p>
        <Button icon basic onClick={() => handleBack()}><Icon name="arrow circle left" /> Let's go Back</Button>
      </div>
    </div>
  );
};

export default Unauthorized;
