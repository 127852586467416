import React from "react";
import "./AppListItem.css";
import { Header, Icon, Label } from "semantic-ui-react";
import {Link} from "react-router-dom";
import moment from "moment"

const AppListItem = ({ app }) => {
  return (
    <div className="AppListItem">
      <div className="AppListItem--icon">
        <Icon color="grey" name="window restore outline" size="huge" />
      </div>
      <div className="AppListItem--content">
        <div className="AppListItem--content__left">
          <Header className="AppListItem--header">
            <span className="AppListItem--header__content">
              {app.site_name}
              <Link to={`/apps/${app.uuid}`} className="AppListItem--settingsButton"><Icon name="cog" /></Link>
            </span>
            <Header.Subheader className="AppListItem--header__subheader">
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="AppListItem--header__subheader--link"
                href={app.staging_url}
              >
                {" "}
                {app.staging_url}
                <Icon name="external alternate" />
              </a>
            </Header.Subheader>
          </Header>
          <div className="AppListItem--status">
            <Label basic color={app.active ? "green" : "red"}>
              {app.active ? "Active" : "Inactive"}
            </Label>
          </div>
        </div>
        <div className="AppListItem--content__right">
          <div className="AppListItem--details">
            <div className="AppListItem--details">
              <span className="AppListItem--details--header">
                <Icon name="calendar alternate" /> Created
              </span>
              <span className="AppListItem--details--content">
                {moment(app.date_created).format("MMM Do, YYYY")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppListItem;
