import * as actionTypes from "../actions/actionTypes";
import axios from "../../axios-portal";

export const addMessage = message => {
  return {
    type: actionTypes.ADD_MESSAGE,
    message: message
  };
};

export const removeMessage = (message, timeToWait) => {
  return {
    type: actionTypes.REMOVE_MESSAGE,
    message: message,
    timeToWait: timeToWait
  };
};

export const startWebsiteBuildsFetch = () => {
  return {
    type: actionTypes.START_WEBSITE_BUILDS_FETCH,
  }
}

export const setWebsiteBuilds = (websiteBuilds) => {
  return {
    type: actionTypes.SET_WEBSITE_BUILDS,
    websiteBuilds: websiteBuilds
  }
}

export const startArchivedWebsiteBuildsFetch = () => {
  return {
    type: actionTypes.START_ARCHIVED_WEBSITE_BUILDS_FETCH,
  }
}

export const setArchivedWebsiteBuilds = (websiteBuilds) => {
  return {
    type: actionTypes.SET_ARCHIVED_WEBSITE_BUILDS,
    websiteBuilds: websiteBuilds
  }
}

export const startAddingWebsiteBuild = () => {
  return {
    type: actionTypes.START_ADDING_WEBSITE_BUILD,

  }
}

export const addWebsiteBuildSuccess = (websiteBuild) => {
  return {
    type: actionTypes.ADD_WEBSITE_BUILD_SUCCESS,
    websiteBuild: websiteBuild
  }
}

export const updateWebsiteBuildSuccess = (id, websiteBuild) => {
  return {
    type: actionTypes.UPDATE_WEBSITE_BUILD_SUCCESS,
    websiteBuild: websiteBuild,
    id: id
  }
}

export const deleteWebsiteBuildSuccess = (id) => {
  return {
    type: actionTypes.DELETE_WEBSITE_BUILD_SUCCESS,
    id: id
  }
}

export const websiteBuildsFailure = (errors) => {
  return {
    type: actionTypes.WEBSITE_BUILDS_FAILURE,
    errors: errors
  }
}

export const fetchWebsiteBuilds = () => {
  return dispatch => {
    dispatch(startWebsiteBuildsFetch());
    axios
      .get("website-builds.json")
      .then(response => {
        return dispatch(setWebsiteBuilds(response.data));
      })
      .catch(err => {
        return dispatch(websiteBuildsFailure(err));
      });
  };
};

export const fetchArchivedWebsiteBuilds = () => {
  return dispatch => {
    dispatch(startArchivedWebsiteBuildsFetch());
    axios
      .get("website-builds/?archived=true")
      .then(response => {
        return dispatch(setArchivedWebsiteBuilds(response.data));
      })
      .catch(err => {
        return dispatch(websiteBuildsFailure(err));
      });
  };
};

export const addWebsiteBuild = (formData) => {
  return dispatch => {
    dispatch(startAddingWebsiteBuild());
    let body = JSON.stringify({
      label: formData.label,
      notes: formData.notes,
      organization: formData.organization,
      start_date: formData.start_date,
      brand_interview_link: formData.brand_interview_link,
      sitemap_link: formData.sitemap_link,
      staging_url: formData.staging_url,
      intake_link: formData.intake_link,
    });
    let message = "Website Build Created!";
    axios
      .post("website-builds.json", body)
      .then(response => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message)), 2500);
        return dispatch(addWebsiteBuildSuccess(response.data));
      })
      .catch(err => {
        dispatch(websiteBuildsFailure(err));
      });
  };
};

export const updateWebsiteBuild = (id, formData) => {
  return dispatch => {
    let body = JSON.stringify({
        label: formData.label,
        notes: formData.notes,
        organization: formData.organization,
        is_active: formData.is_active,
        brand_interview_link: formData.brand_interview_link,
        sitemap_link: formData.sitemap_link,
        staging_url: formData.staging_url,
        start_date: formData.start_date,
        intake_link: formData.intake_link,

    });
    let message = "Website Build Updated!";
    axios
      .put(`website-builds/${id}/`, body)
      .then(response => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message)), 2500);
        return dispatch(updateWebsiteBuildSuccess(id, response.data));
      })
      .catch(err => {
        dispatch(websiteBuildsFailure(err));
      });
  };
};

export const deleteWebsiteBuild = (id) => {
  return (dispatch, getState) => {
    let message = "Website Build Deleted."

    return axios
      .delete(`website-builds/${id}/`)
      .then(response => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message)), 2500);
        return dispatch(deleteWebsiteBuildSuccess(id));
      })
      .catch(err => {
        dispatch(websiteBuildsFailure(err));
      });
  };
}