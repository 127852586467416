import React, { Component } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import { connect } from 'react-redux';
import { organizations, reporting, contentGeneration } from '../../store/actions';
import { Route, Switch, Redirect } from 'react-router-dom';
import './Reporting.css';
import ReportingDashboard from './ReportingDashboard/ReportingDashboard';
import ReportingMonth from './ReportingMonth/ReportingMonth';
import PlanningMonthSelect from '../../components/PlanningMonthSelect/PlanningMonthSelect';
import { hasPermissions } from '../../utility';
import { ui } from '../../store/actions';
import ClientReportingDashboard from './ClientReportingDashboard/ClientReportingDashboard';
import ReportingPresentationTemplates from './ReportingPresentationTemplates/ReportingPresentationTemplates';
import Report from './Report/Report';

class Reporting extends Component {
	state = {
		menuItems: [
			{
				id: 1,
				path: `${this.props.match.path}`,
				exact: true,
				menuLabel: 'Dashboard',
				navbarName: 'Dashboard',
				icon: 'list layout',
				component: null,
				protected: true,
				permission: hasPermissions(this.props.user, 'view_monthlyreport')
			},
			{
				id: 2,
				path: `${this.props.match.path}/presentation-templates`,
				exact: true,
				menuLabel: 'Presentation Templates',
				navbarName: 'Presentation Templates',
				icon: 'chart area',
				component: null,
				protected: true,
				permission: hasPermissions(this.props.user, 'update_monthlyreport')
			},
			{
				id: 3,
				path: null,
				component: (
					<PlanningMonthSelect
						{...this.props}
						path={this.props.match.path}
						iconSize="small"
						placeholder="Select Month"
					/>
				),
				protected: true,
				permission: hasPermissions(this.props.user, 'update_monthlyreport')
			}
		]
	};
	componentDidMount() {
		this.props.setMenuItems(this.state.menuItems, 'reporting');
		!this.props.organizations[0] && this.props.fetchOrganizations();
		!this.props.reports[0] && this.props.fetchReports();
		!this.props.planningMonths[0] && this.props.fetchPlanningMonths();
		!this.props.contentStaff[0] && this.props.fetchContentStaff();
	}

	viewMonth = (month) => {
		this.props.history.push(`${this.props.match.path}/${month.year}/${month.month}`);
	};

	render() {
		return (
			<div className="RouteContainer">
				<Navbar
					messages={this.props.messages}
					matchPath={this.props.matchPath}
					routeHeader="Reporting"
					noMenuPrefix
					menuItems={this.state.menuItems}
				/>
				<div className="Reporting">
					<Switch>
						<Route
							exact
							path="/reporting"
							render={(props) =>
								hasPermissions(this.props.user, 'add_monthlyreport') ? (
									<ReportingDashboard
										{...props}
										reports={this.props.reports}
										organizations={this.props.organizations}
										planningMonths={this.props.planningMonths}
										isLoading={this.props.isLoading}
										isLoaded={this.props.isLoaded}
									/>
								) : (
									<ClientReportingDashboard
										{...props}
										user={this.props.user}
										reports={this.props.reports}
										planningMonths={this.props.planningMonths}
										organizations={this.props.organizations}
										isLoading={this.props.isLoading}
										isLoaded={this.props.isLoaded}
									/>
								)}
						/>
						{hasPermissions(this.props.user, 'add_slidedecktemplate') && (
							<Route
								path={`${this.props.match.path}/presentation-templates`}
								render={(props) => <ReportingPresentationTemplates  {...props} />}
							/>
						)}
						{hasPermissions(this.props.user, 'view_monthlyreport') && (
							<Route
								exact
								path={`${this.props.match.path}/:reportID`}
								render={(props) => <Report {...props} />}
							/>
						)}
						{hasPermissions(this.props.user, 'add_monthlyreport') && (
							<Route
								exact
								path={`${this.props.match.path}/:year/:month`}
								render={(props) => <ReportingMonth {...props} />}
							/>
						)}
						{hasPermissions(this.props.user, 'view_monthlyreport') && (
							<Route
								exact
								path={`${this.props.match.path}/:reportYear/:reportMonth/:organizationSlug`}
								render={(props) => <Report {...props} />}
							/>
						)}
						<Redirect to={`/reporting`} />
					</Switch>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		organizations: state.organizations.organizations,
		reports: state.reporting.reports,
		isLoading: state.reporting.isLoading,
		isLoaded: state.reporting.isLoaded,
		errors: state.reporting.errors,
		messages: state.reporting.messages,
		planningMonths: state.contentGeneration.planningMonths,
		contentStaff: state.contentGeneration.contentStaff
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchOrganizations: () => dispatch(organizations.fetchOrganizations()),
		fetchReports: () => dispatch(reporting.fetchReports()),
		fetchPlanningMonths: () => dispatch(contentGeneration.fetchPlanningMonths(true)),
		fetchContentStaff: () => dispatch(contentGeneration.fetchContentStaff()),
		setMenuItems: (items, route) => dispatch(ui.setMenuItems(items, route))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Reporting);
