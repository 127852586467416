import * as actionTypes from "../actions/actionTypes";
import axios from "../../axios-portal";

export const addMessage = message => {
  return {
    type: actionTypes.ADD_MESSAGE,
    message: message
  };
};

export const removeMessage = (message, timeToWait) => {
  return {
    type: actionTypes.REMOVE_MESSAGE,
    message: message,
    timeToWait: timeToWait
  };
};

export const toggleMinimize = () => {
  return {
    type: actionTypes.TOGGLE_MINIMIZE,
  }
}

export const startUserFeedbackFetch = () => {
  return {
    type: actionTypes.START_USER_FEEDBACK_FETCH
  };
};

export const setUserFeedback = userFeedback => {
  return {
    type: actionTypes.SET_USER_FEEDBACK,
    userFeedback: userFeedback
  };
};

export const fetchUserFeedbackFailure = errors => {
  return {
    type: actionTypes.FETCH_USER_FEEDBACK_FAILURE,
    errors: errors
  };
};

export const startAddingUserFeedback = (feedbackType) => {
  return {
    type: actionTypes.START_ADDING_USER_FEEDBACK,
    feedbackType: feedbackType
  }
}

export const cancelAddingUserFeedback = () => {
  return {
    type: actionTypes.CANCEL_ADDING_USER_FEEDBACK,
  }
}

export const addingUserFeedback = () => {
  return {
    type: actionTypes.ADDING_USER_FEEDBACK
  };
};

export const addUserFeedbackSuccess = userFeedback => {
  return {
    type: actionTypes.ADD_USER_FEEDBACK_SUCCESS,
    userFeedback: userFeedback
  };
};

export const updateUserFeedbackSuccess = (userFeedback, id) => {
  return {
    type: actionTypes.UPDATE_USER_FEEDBACK_SUCCESS,
    userFeedback: userFeedback,
    id: id
  };
};

export const userFeedbackFailure = errors => {
  return {
    type: actionTypes.USER_FEEDBACK_FAILURE,
    errors: errors
  };
};

export const deleteUserFeedbackSuccess = id => {
  return {
    type: actionTypes.DELETE_USER_FEEDBACK_SUCCESS,
    id: id,
  };
};


export const fetchUserFeedback = () => {
  return dispatch => {
    dispatch(startUserFeedbackFetch());
    axios
      .get("user-feedback.json")
      .then(response => {
        return dispatch(setUserFeedback(response.data));
      })
      .catch(err => {
        return dispatch(fetchUserFeedbackFailure(err));
      });
  };
};

export const addUserFeedback = (feedbackType, feedback) => {
  return dispatch => {
    dispatch(addingUserFeedback());
    let body = JSON.stringify({
      feedback_type: feedbackType,
      feedback: feedback
    });
    let message = "Feedback Given!";
    axios
      .post("user-feedback.json", body)
      .then(response => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message)), 2500);
        return dispatch(addUserFeedbackSuccess(response.data));
      })
      .catch(err => {
        dispatch(userFeedbackFailure(err.response.data));
      });
  };
};

export const updateUserFeedback = (formData ,id) => {
  return dispatch => {
    dispatch(addingUserFeedback());
    let body = JSON.stringify({
        feedback_type: formData.feedbackType,
        feedback: formData.feedback
    });
    let message = "Feedback Updated!";
    axios
      .put(`user-feedback/${id}/`, body)
      .then(response => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message)), 2500);
        return dispatch(updateUserFeedbackSuccess(response.data, id));
      })
      .catch(err => {
        dispatch(userFeedbackFailure(err.response.data));
      });
  };
};

export const deleteUserFeedback= (id) => {
  return (dispatch, getState) => {
    let message = "Feedback Deleted."

    return axios
      .delete(`user-feedback/${id}/`)
      .then(response => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message)), 2500);
        return dispatch(deleteUserFeedbackSuccess(id));
      })
      .catch(err => {
        dispatch(userFeedbackFailure(err));
      });
  };
}