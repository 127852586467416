import React, { Component } from 'react';
import './AccountHealth.css';
import { connect } from 'react-redux';
import { ui } from '../../store/actions';
import Navbar from '../../components/Navbar/Navbar';
import { hasPermissions } from '../../utility';
import { Route, Switch } from 'react-router-dom';
import AccountHealthDashboard from './AccountHealthDashboard/AccountHealthDashboard';
import PostingOverview from './PostingOverview/PostingOverview';

export class AccountHealth extends Component {
	state = {
		menuItems: [
			{
				id: 1,
				path: `${this.props.match.path}`,
				exact: true,
				menuLabel: 'Dashboard',
				navbarName: 'Dashboard',
				icon: 'list layout',
				component: null,
				protected: true,
				permission: hasPermissions(this.props.user, 'view_monthlyreport')
			},
			{
				id: 2,
				path: `${this.props.match.path}/posting-overview`,
				exact: true,
				menuLabel: 'Posting Overview',
				navbarName: 'Posting Overview',
				icon: 'calendar check outline',
				component: null,
				protected: true,
				permission: hasPermissions(this.props.user, 'edit_contentarticle')
			}
		]
	};

	componentDidMount() {
		this.props.setMenuItems(this.state.menuItems, 'account-health');
	}

	render() {
		return (
			<div className="RouteContainer">
				<Navbar
					messages={this.props.messages}
					matchPath={this.props.matchPath}
					routeHeader="Account Health"
					noMenuPrefix
					menuItems={this.state.menuItems}
				/>
				<div className="AccountHealth">
					<Switch>
						<Route
							exact
							render={(props) => <AccountHealthDashboard {...props} overviews={this.props.overviews} />}
							path={`/account-health`}
						/>
						<Route
							render={(props) => <PostingOverview {...props} />}
							path={`${this.props.match.path}/posting-overview`}
						/>
					</Switch>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		messages: state.accountHealth.messages,
		isLoadingGroups: state.auth.isLoadingGroups
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setMenuItems: (items, route) => dispatch(ui.setMenuItems(items, route))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountHealth);
