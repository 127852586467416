import axios from 'axios';

const fetchClient = () => {
    
	const defaultOptions = {
        baseURL: process.env.REACT_APP_BACKEND_URL,
        crossDomain: true,
		headers: {
			'Content-Type': 'application/json',
		}
	};

	let instance = axios.create(defaultOptions);

	instance.interceptors.request.use(function(config) {
		if (!config.headers.Authorization) {
			// const username = 'tto_portal';
			const username = 'anthony+tto_portal@321webmarketing.com';
            const password = process.env.REACT_APP_WORDPRESS_PASSWORD;
            const usernamePasswordBuffer = Buffer.from(username + ':' + password);
            const base64data = usernamePasswordBuffer.toString('base64');
            config.headers.Authorization = base64data ? `Basic ${base64data}` : '';
			return config;
		}
	});

	return instance;
};

export default fetchClient();
