import React, { Component, createRef } from "react";
import "./ChecklistItem.css";
import { Input, List, Icon, Confirm } from "semantic-ui-react";
import UserSelectWidget from "../../UserSelectWidget/UserSelectWidget";
import moment from "moment";
import DepartmentPickerWidget from "../../DepartmentPickerWidget/DepartmentPickerWidget";
import DatePickerWidget from "../../DatePickerWidget/DatePickerWidget";

export default class ChecklistItem extends Component {
  state = {
    item: this.props.item,
    deleteConfirmOpen: false,
    isHovering: false,
    transitioning: false
  };

  newItemNameRef = createRef();

  componentDidMount() {
    this.props.new && this.newItemNameRef.current.focus();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.item !== prevProps.item) {
      this.setState({ item: this.props.item, transitioning: false });
    }
    if (this.state.item.completed !== prevState.item.completed) {
      this.setState({ transitioning: true });
    }
  }

  handleUserChange = async userID => {
    await this.setState({
      item: { ...this.state.item, assignee: userID }
    });
    if (!this.props.new) {
      return this.props.updateChecklistItem(
        this.state.item,
        this.state.item.id,
        this.props.list.id
      );
    }
  };

  handleDepartmentSelection = async department => {
    await this.setState({
      item: { ...this.state.item, department: department }
    });
    if (!this.props.new) {
      return this.props.updateChecklistItem(
        this.state.item,
        this.state.item.id,
        this.props.list.id
      );
    }
  };

  handleChange(e) {
    this.setState({
      item: {
        ...this.state.item,
        [e.target.name]: e.target.value
      }
    });
  }

  handleCreate = async e => {
    if (!this.props.itemMeta.isAdding) {
      if (
        this.state.item[e.target.name] !== this.props.item[e.target.name] &&
        this.state.item.name
      ) {
        await this.props.addChecklistItem(
          this.state.item,
          this.props.list.id,
          this.props.itemMeta.index
        );
        this.props.cancelAddingItem();
      } else {
        this.props.cancelAddingItem();
      }
    }
  };

  handleUpdate = e => {
    if (this.state.item[e.target.name] !== this.props.item[e.target.name]) {
      this.props.updateChecklistItem(
        this.state.item,
        this.state.item.id,
        this.props.list.id
      );
    }
  };

  handleDueDateChange = async date => {
    await this.setState({
      item: {
        ...this.state.item,
        due: date
      }
    });
    return this.props.updateChecklistItem(
      this.state.item,
      this.state.item.id,
      this.props.list.id
    );
  };

  handleToggleComplete = async () => {
    await this.setState({
      item: {
        ...this.state.item,
        completed: !this.state.item.completed
      }
    });
    return this.props.updateChecklistItem(
      this.state.item,
      this.state.item.id,
      this.props.list.id
    );
  };

  handleKeyPress = async (e, index, order) => {
    if (e.key === "Enter") {
      if (!this.props.new) {
        this.props.startAddingItem(
          index,
          order,
          this.props.parentIndex,
          this.props.parentID,
          this.props.item.id
        );
      } else {
        if (this.state.item.name) {
          await this.props.addChecklistItem(
            this.state.item,
            this.props.list.id,
            this.props.itemMeta.childIndex !== null
              ? this.props.itemMeta.childIndex
              : this.props.itemMeta.index
          );
          await this.props.cancelAddingItem();

          this.props.startAddingItem(
            this.props.itemMeta.childIndex !== null
              ? this.props.itemMeta.childIndex + 1
              : this.props.itemMeta.index + 1,
            this.state.item.order,
            this.props.parentIndex,
            this.state.item.parent,
            this.props.item.id
          );
        } else {
          this.props.cancelAddingItem();
        }
      }
    }
  };

  handleKeyDown = (e, index, order) => {
    if (e.key === "Tab" && !this.props.new) {
      e.preventDefault();
      this.props.startAddingItem(
        index,
        order,
        this.props.parentIndex,
        this.props.parentID,
        this.props.item.id,
        true
      );
    }
    if (this.props.new && e.key === "Backspace") {
      if (!this.state.item.name) {
        this.props.cancelAddingItem();
      }
    }
  };

  handleDeleteConfirm = (id, listID) => {
    this.props.deleteChecklistItem(id, listID);
    this.setState({ deleteConfirmOpen: false });
  };

  handleHideItems = () => {
    this.setState({ isHovering: false });
  };

  handleShowItems = () => {
    this.setState({ isHovering: true });
  };

  handleShowCheckbox = () => {
    if (this.props.allItems && this.state.item.checklist_item_children[0]) {
      let children = this.state.item.checklist_item_children.map(child =>
        this.props.allItems.find(item => item.id === child)
      );
      let incompleteChildren = children.filter(child => !child.completed);
      return incompleteChildren.length > 0 ? false : true;
    } else {
      return true;
    }
  };

  handleDateStatus = date => {
    let today = moment();
    let targetDate = moment(date);
    if (targetDate > today) {
      return (
        <span className="ChecklistItem--dueDate__date onTime">
          {moment(targetDate).format("MMM Do")}
        </span>
      );
    } else if (targetDate.isSame(today, "day")) {
      return <span className="ChecklistItem--dueDate__date today">Today</span>;
    } else {
      return (
        <span className="ChecklistItem--dueDate__date late">
          {moment(targetDate).format("MMM Do")}
        </span>
      );
    }
  };

  render() {
    let { item } = this.state;
    return (
      <List.Item
        className={`ChecklistItem ${
          this.state.isHovering ? "isHovering" : ""
        } ${item.completed ? "completed" : "incomplete"} ${
          this.state.transitioning ? "isTransitioning" : "notTransitioning"
        }`}
      >
        <List.Content
          onMouseEnter={() => this.handleShowItems()}
          onMouseLeave={() => this.handleHideItems()}
          className="ChecklistItemContent"
        >
          <div className="ChecklistItemContent--left">
            {this.handleShowCheckbox() && (
              <div
                onClick={() => this.handleToggleComplete()}
                className="ChecklistItemContent--checkbox"
              >
                <Icon name="check" />
              </div>
            )}
          </div>
          <div className="ChecklistItemContent--main">
            {this.props.editable ? (
              <Input
                fluid
                required
                ref={this.props.new && this.newItemNameRef}
                placeholder="Name"
                name="name"
                onBlur={
                  !this.props.new
                    ? e => this.handleUpdate(e)
                    : e => this.handleCreate(e)
                }
                onChange={e => this.handleChange(e)}
                onKeyPress={e =>
                  this.handleKeyPress(e, this.props.index, item.order)
                }
                onKeyDown={e => this.handleKeyDown(e, this.props.index)}
                className="ChecklistItem__header"
                transparent
                value={item.name}
              />
            ) : (
              <span className="ChecklistItemContent--readOnly">
                {item.name}
              </span>
            )}
          </div>
          <div className="ChecklistItemContent--right">
            {!this.props.new && this.props.editable && (
              <React.Fragment>
                {this.state.isHovering && (
                  <React.Fragment>
                    {(this.props.prevSibling(this.props.item) ||
                      this.props.nextSibling(this.props.item)) && (
                      <div className="arrowControls">
                        {this.props.prevSibling(this.props.item) !== null && (
                          <span
                            onClick={() =>
                              this.props.handleReOrder(
                                item,
                                "up",
                                item.parent ? item.parent : null
                              )
                            }
                            className="arrowControl arrowControl--up"
                          >
                            &#8679;
                          </span>
                        )}
                        {this.props.nextSibling(this.props.item) !== null && (
                          <span
                            onClick={() =>
                              this.props.handleReOrder(
                                item,
                                "down",
                                item.parent ? item.parent : null
                              )
                            }
                            className="arrowControl arrowControl-down"
                          >
                            &#8681;
                          </span>
                        )}
                      </div>
                    )}
                    <div
                      onClick={() => this.setState({ deleteConfirmOpen: true })}
                      className="ChecklistItem--delete"
                    >
                      <Icon color="red" name="trash alternate outline" />
                    </div>
                  </React.Fragment>
                )}

                <Confirm
                  size="mini"
                  header={`Delete ${item.name} ?`}
                  content="Are you sure?"
                  open={this.state.deleteConfirmOpen}
                  onCancel={() => this.setState({ deleteConfirmOpen: false })}
                  onConfirm={() =>
                    this.handleDeleteConfirm(item.id, this.props.list.id)
                  }
                />
              </React.Fragment>
            )}
            {this.props.editable ? (
              <DepartmentPickerWidget
                department={item.department}
                handleDepartmentSelection={this.handleDepartmentSelection}
              />
            ) : item.department ? (
              <DepartmentPickerWidget
                readOnly={!this.props.editable}
                department={item.department}
                handleDepartmentSelection={this.handleDepartmentSelection}
              />
            ) : null}
            {item.due && (
              <div className="ChecklistItem--dueDate">
                {this.props.editableDate ? (
                  <React.Fragment>
                    <Icon name="calendar alternate outline" />{" "}
                    {this.handleDateStatus(item.due)}
                  </React.Fragment>
                ) : (
                  <DatePickerWidget
                    handleChange={this.handleDueDateChange}
                    date={item.due ? item.due : null}
                  />
                )}
              </div>
            )}
            {(this.props.item.assignee || this.props.editable) && (
              <UserSelectWidget
                readOnly={!this.props.editable}
                parentObject={item}
                currentUser={
                  this.props.item.assignee && this.props.item.assignee
                }
                handleSelection={this.handleUserChange}
              />
            )}
          </div>
        </List.Content>
        {((item.checklist_item_children && item.checklist_item_children[0]) ||
          this.props.addingNewChild) && (
          <List.List className="ChecklistItem--children">
            {this.props.children}
          </List.List>
        )}
      </List.Item>
    );
  }
}
