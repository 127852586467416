import React from 'react';
import { Header, Grid, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import './UtilitiesDashboard.css';

export default function UtilitiesDashboard() {
	return (
		<div className="UtilitiesDashboard">
			<div className="UtilitiesDashboard--links">
				<Grid>
					<Grid.Row>
						<Grid.Column width={4}>
							<Link to="broken-link-checker/">
								<div className="UtilitiesDashboard--link">
									<Icon color="grey" name="unlink" size="big" />
									<Header>Broken Link Checker</Header>
								</div>
							</Link>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</div>
		</div>
	);
}
