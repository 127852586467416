import React, { Component, createRef } from "react";
import "./DepartmentPickerWidget.css";
import { DEPARTMENTS } from "../../constants";
import { Form, Select, Popup } from "semantic-ui-react";

export default class DepartmentPickerWidget extends Component {
  state = {
    department: null,
    popupOpen: false
  };

  contextRef = createRef();

  componentDidMount() {
    if (this.props.department) {
      this.setState({ department: this.props.department });
    }
  }

  handlePopupOpen = () => {
    this.setState({ popupOpen: true });
  };

  handlePopupClose = () => {
    this.setState({ popupOpen: false });
  };

  handleSelectChange = async (event, { name, value }) => {
    await this.setState({
      department: value
    });
    this.handlePopupClose();
    return this.props.handleDepartmentSelection(this.state.department);
  };

  render() {
    let currentDepartment = DEPARTMENTS.find(
      d => d.value === this.state.department
    );
    return (
      <div ref={this.contextRef} className="DepartmentPickerWidget">
        {this.props.readOnly ? (
          <div
            style={
                currentDepartment && currentDepartment.color
                ? { background: currentDepartment.color }
                : { background: "#7d7d7d" }
            }
            className="DepartmentPickerWidget--departmentPill"
          >
            {currentDepartment && currentDepartment.text}
          </div>
        ) : (
          <Popup
            on="click"
            flowing
            open={this.state.popupOpen}
            onOpen={this.handlePopupOpen}
            onClose={this.handlePopupClose}
            position="bottom right"
            context={this.contextRef}
            trigger={
              this.state.department ? (
                <div
                  style={
                    currentDepartment && currentDepartment.color
                      ? { background: currentDepartment.color }
                      : { background: "#7d7d7d" }
                  }
                  className="DepartmentPickerWidget--departmentPill"
                >
                  {currentDepartment && currentDepartment.text}
                </div>
              ) : (
                <span className="DepartmentPickerWidget--placeholder">
                  Select Department
                </span>
              )
            }
          >
            <Form>
              <Form.Field className="DepartmentPickerWidget--form">
                <label>Department</label>
                <Select
                  clearable
                  placeholder="Choose Department"
                  name="department"
                  value={currentDepartment && currentDepartment ? currentDepartment.value : ""}
                  onChange={this.handleSelectChange}
                  options={DEPARTMENTS.map(department => ({
                    key: department.value,
                    text: department.text,
                    value: department.value
                  }))}
                />
              </Form.Field>
            </Form>
          </Popup>
        )}
      </div>
    );
  }
}
