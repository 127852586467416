import React, { Component } from 'react';
import { connect } from 'react-redux';
import { users } from '../../../store/actions';
import './ReportSettings.css';
import { Loader, Table, Checkbox, Header, Form, Button, Icon, Segment } from 'semantic-ui-react';

class ReportSettings extends Component {
	state = {
		addingUser: false,
		newUser: null
	};

	componentDidMount() {
		const organization = this.props.primarySettingsObject && this.props.primarySettingsObject;
		organization && this.props.fetchUsers(organization.id);
	}

	componentDidUpdate(prevProps) {
		if (this.props.primarySettingsObject !== prevProps.primarySettingsObject && this.props.primarySettingsObject) {
			this.props.fetchUsers(this.props.primarySettingsObject.id);
		}
	}

	handleStartAddUser = (organization) => {
		const initialUserData = {
			firstName: '',
			lastName: '',
			emai: '',
			organization: organization.id,
			role: 3,
			isWriter: false,
			title: '',
			phoneNumber: '',
			receive_reporting_email: true,
			sendInvite: false,
			createUser: true
		};
		this.setState({ addingUser: true, newUser: initialUserData });
	};

	handleCancelAddingUser = () => {
		this.setState({ addingUser: false, newUser: null });
	};

	handleInputChange(e) {
		this.setState({
			newUser: {
				...this.state.newUser,
				[e.target.name]: e.target.value
			}
		});
	}

	handleNewUserSubmit = async (e) => {
		e.preventDefault();
		const newUser = this.state.newUser && this.state.newUser;
		if (newUser.firstName && newUser.lastName && newUser.email) {
			await this.props.inviteUser(newUser);
			return this.handleCancelAddingUser();
		}
	};

	async handleChange(account, name, checked, type, value) {
		await this.props.updateAccount(
			account.id,
			{
				...account,
				[name]: type === 'checkbox' ? checked : value
			},
			'Account Updated.'
		);
	}

	render() {
		const allowedRoles = [ 1, 3 ];
		const reportOrganization = this.props.primarySettingsObject && this.props.primarySettingsObject;
		const reportOrganizationUsers =
			reportOrganization &&
			this.props.users.filter((user) => {
				const correspondingRoles = user.org_roles.filter(
					(r) => r.organization === reportOrganization.id && allowedRoles.includes(r.role)
				);
				return correspondingRoles && correspondingRoles[0] ? true : false;
			});

		return (
			<div className="ReportSettings">
				{!this.props.usersLoading && this.props.usersLoaded ? reportOrganizationUsers ? (
					<div className="ReportSettings--accountTableContainer">
						<Header as="h4" className="ReportSettings--header">
							Report Notifications
						</Header>
						<Table size="small" className="ReportSettings--accountTable">
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>
										<span className="ReportSettings--accountTableHeader">Name</span>
                                        <Icon name="user" />
									</Table.HeaderCell>
									<Table.HeaderCell>
										<span className="ReportSettings--accountTableHeader">Email</span>
                                        <Icon name="mail" />
									</Table.HeaderCell>
									<Table.HeaderCell textAlign="center">
										<span className="ReportSettings--accountTableHeader">Receive Notification</span>
                                        <Icon name="bell" />
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{reportOrganizationUsers.map((user) => {
									const account = user.org_roles.find(
										(role) => role.organization === reportOrganization.id
									);
									return (
										<Table.Row key={user.id}>
											<Table.Cell>
												{user.name ? user.name : `${user.first_name} ${user.last_name}`}
											</Table.Cell>
											<Table.Cell>{user.email}</Table.Cell>
											<Table.Cell
												className={`${account.isUpdating ? 'isUpdating' : 'notUpdating'}`}
												textAlign="center"
											>
												<Checkbox
													name="receive_reporting_email"
													onChange={(e, data) =>
														this.handleChange(
															account,
															data.name,
															data.checked,
															data.type,
															data.value
														)}
													className="alignMiddle"
													checked={account.receive_reporting_email}
													disabled={account.isUpdating}
												/>
											</Table.Cell>
										</Table.Row>
									);
								})}
							</Table.Body>
						</Table>
						<div className="ReportSettings--addUserButton">
							{this.state.addingUser ? (
								<Button
									size="small"
									onClick={() => this.handleCancelAddingUser()}
									type="button"
									negative
                                    fluid
                                    loading={this.props.invitingUser}
									icon
								>
									Cancel Adding User <Icon name="minus square" />
								</Button>
							) : (
								<Button
									onClick={() => this.handleStartAddUser(reportOrganization)}
									type="button"
									size="small"
                                    positive
                                    loading={this.props.invitingUser}
									fluid
									icon
								>
									Add New User <Icon name="plus square" />
								</Button>
							)}
						</div>
						{this.state.addingUser && (
							<section className="ReportSettings--addUser">
								<Segment>
									<Form
										loading={this.props.invitingUser}
										size="small"
										onSubmit={this.handleNewUserSubmit}
									>
										<Form.Group widths="equal">
											<Form.Input
												id="firstName"
												type="text"
												name="firstName"
												label="First Name"
												placeholder="First Name"
												required
												value={this.state.newUser.firstName}
												onChange={(e) => this.handleInputChange(e)}
												disabled={this.props.invitingUser}
											/>
											<Form.Input
												id="lastName"
												type="text"
												name="lastName"
												required
												label="Last Name"
												placeholder="Last Name"
												value={this.state.newUser.lastName}
												onChange={(e) => this.handleInputChange(e)}
												disabled={this.props.invitingUser}
											/>
											<Form.Input
												id="email"
												type="email"
												name="email"
												required
												label="Email"
												placeholder="Email Address"
												value={this.state.newUser.email}
												onChange={(e) => this.handleInputChange(e)}
												disabled={this.props.invitingUser}
											/>
											<Form.Button
												className="ReportSettings--submitButton"
												positive
												icon
												size="small"
												type="submit"
												disabled={this.props.invitingUser}
											>
												Add User
												<Icon name="plus" />
											</Form.Button>
										</Form.Group>
									</Form>
								</Segment>
							</section>
						)}
					</div>
				) : (
					<p>No users found</p>
				) : (
					<Loader active>Loading User Accounts</Loader>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		users: state.users.users,
		usersLoading: state.users.isLoading,
		usersLoaded: state.users.isLoaded,
		errors: state.users.errors,
		invitingUser: state.users.invitingUser
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchUsers: (organization) => dispatch(users.fetchUsers(false, organization)),
		updateAccount: (accountId, accountData) =>
			dispatch(users.updateAccount(accountId, accountData, 'Report Settings Updated')),
		inviteUser: (userData) => dispatch(users.inviteUser(userData))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportSettings);
