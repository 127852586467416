import React, { Component } from "react";
import { Icon, Menu, Sidebar, Label, Image } from "semantic-ui-react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/images/321-logo-no-tagline-portal.png";
import slackLogo from "../../assets/images/Slack_Mark_Web.png";
import { hasPermissions } from "../../utility";
import "./SidebarNav.css";
import AssignmentList from "../AssignmentList/AssignmentList";

export default class SidebarNav extends Component {
  handleHideClick = () => this.props.closeNav();
  handleShowClick = () => this.props.openNav();
  handleSidebarHide = () => this.props.closeNav();

  render() {
    const excludeAssignments = (match, location) => {
      const newRegex = "^/content-generation/assignments(/.*)?";
      const assignmentsRoute = "/content-generation/assignments";
      if (!match) {
        return false;
      }
      if (location.pathname === assignmentsRoute.match(newRegex)[0]) {
        return false;
      } else {
        return true;
      }
    };

    // const relativePathMatch = (pathname, match, location) => {
    //   if (pathname === this.props.match.path) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }

    const menuItemsPathMatch = (menuItems, path, match, location) => {
      let matchedRoute = null;
      const routes = Object.keys(menuItems);
      let doesMatch = routes.some((key) => {
        let routeKey = `/${key}`;
        if (routeKey === this.props.match.path && path === key) {
          matchedRoute = key;
          return routeKey;
        }
        return key;
      });
      return doesMatch && matchedRoute;
    };

    const isTopLevel = (path) => {
      let currentPath = path;
      if (currentPath.endsWith("/")) {
        currentPath = currentPath.slice(0, -1);
      }
      return (currentPath.match(new RegExp("/", "g")) || []).length < 2
        ? true
        : false;
    };

    const isClient = (user) => {
      return user.default_role && user.default_role.name === "Customer";
    };

    const user = this.props.user;
    return (
      <Sidebar
        as={Menu}
        inverted
        onHide={this.handleSidebarHide}
        vertical
        visible
        className="static SidebarNav"
      >
        <React.Fragment>
          <Link to="/">
            <Menu.Item className="SideBarHeaderContainer">
              <div className="SideBarHeader">
                <span className="SideBarHeader--heading">321 Portal</span>
                <img src={logo} alt="321 web marketing" />
              </div>
            </Menu.Item>
          </Link>
          {this.props.authenticatedAsGhost && this.props.hostUser && (
            <Menu.Item className="SidebarNav--ghostUserSectionContainer">
              <div className="SidebarNav--ghostUserSection">
                <span className="SidebarNav--ghostUserHostName">
                  <Icon name="user outline" /> {this.props.hostUser.username}
                </span>
                <p className="SidebarNav--ghostUserName">
                  Logged in as: <span>{this.props.user.username}</span>
                </p>
              </div>
            </Menu.Item>
          )}
          <NavLink to="/content-generation/assignments">
            <Menu.Item
              link
              className={`SidebarNav--assignments ${
                hasPermissions(user, "add_contentarticle") &&
                this.props.location.pathname ===
                  "/content-generation/assignments"
                  ? "withLeadView"
                  : ""
              }`}
            >
              {hasPermissions(user, "update_contentarticle") ? (
                <React.Fragment>
                  <Icon name="pencil alternate" />
              <span className="SidebarNav--content">My Assignments</span>
                  {this.props.location.pathname ===
                    "/content-generation/assignments" &&
                    !this.props.isLoadingArticles && (
                      <Menu.Menu className="SideBarNav--secondaryMenu">
                        {this.props.articles[0] &&
                          this.props.articles.filter(
                            (article) =>
                              article.status.uid === 'writing' &&
                              article.writer.user &&
                              article.writer.user.id === this.props.user.id
                          )[0] && (
                            <Menu.Item
                              as="span"
                              onClick={() =>
                                this.props.modifyFilters(AssignmentList, {
                                  writing: true,
                                })
                              }
                            >
                              <span>Writing</span>
                              {this.props.articles && this.props.articles[0] && (
                                <Label color="blue" horizontal size="mini">
                                  {
                                    this.props.articles.filter(
                                      (article) =>
                                        article.status.uid === 'writing' &&
                                        article.writer.user &&
                                        article.writer.user.id ===
                                          this.props.user.id
                                    ).length
                                  }
                                </Label>
                              )}
                            </Menu.Item>
                          )}
                        {this.props.articles.filter(
                          (article) =>
                            article.status.uid === 'reedit' &&
                            article.editor.id === this.props.user.id
                        )[0] && (
                          <Menu.Item
                            as="span"
                            onClick={() =>
                              this.props.modifyFilters(AssignmentList, {
                                editing: true,
                              })
                            }
                          >
                            <span>Editing</span>
                            {this.props.articles && this.props.articles[0] && (
                              <Label color="blue" horizontal size="mini">
                                {
                                  this.props.articles.filter(
                                    (article) =>
                                      article.status.uid === 'reedit' &&
                                      article.editor.id === this.props.user.id
                                  ).length
                                }
                              </Label>
                            )}
                          </Menu.Item>
                        )}
                        {this.props.articles.filter(
                          (article) =>
                            article.status.uid === 'final_review' &&
                            article.final_approver.id === this.props.user.id
                        )[0] && (
                          <Menu.Item
                            as="span"
                            onClick={() =>
                              this.props.modifyFilters(AssignmentList, {
                                reviewing: true,
                              })
                            }
                          >
                            <span>Final Review</span>
                            {this.props.articles && this.props.articles[0] && (
                              <Label color="blue" horizontal size="mini">
                                {
                                  this.props.articles.filter(
                                    (article) =>
                                      article.status.uid === 'final_review' &&
                                      article.final_approver.id ===
                                        this.props.user.id
                                  ).length
                                }
                              </Label>
                            )}
                          </Menu.Item>
                        )}
                        {this.props.articles.filter(
                          (article) =>
                            article.status.uid === 'ready_to_post' &&
                            article.poster.id === this.props.user.id
                        )[0] && (
                          <Menu.Item
                            as="span"
                            onClick={() =>
                              this.props.modifyFilters(AssignmentList, {
                                posting: true,
                              })
                            }
                          >
                            <span>Posting</span>
                            {this.props.articles && this.props.articles[0] && (
                              <Label color="blue" horizontal size="mini">
                                {
                                  this.props.articles.filter(
                                    (article) =>
                                      article.status.uid === 'ready_to_post' &&
                                      article.poster.id === this.props.user.id
                                  ).length
                                }
                              </Label>
                            )}
                          </Menu.Item>
                        )}
                      </Menu.Menu>
                    )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Icon name="pencil alternate" />
                  <span className="SidebarNav--content">{isClient(this.props.user) ? 'Requires Attention' : 'My Assignments'}</span>
                  {this.props.articles && this.props.articles[0] && (
                    <Label
                      className="SidebarNav--content__label"
                      color="blue"
                      horizontal
                      size="mini"
                    >
                      {
                        this.props.articles.filter(
                          (article) =>
                            ((article.status.uid === 'writing' ||
                              article.status.uid === 'rewrite') &&
                              article.writer.user.id === this.props.user.id) ||
                            ((article.status.uid === 'editing' ||
                              article.status.uid === 'reedit') &&
                              article.editor.id === this.props.user.id) ||
                            (article.status.uid === 'final_review' &&
                              article.final_approver.id) === this.props.user.id
                        ).length
                      }
                    </Label>
                  )}
                </React.Fragment>
              )}
            </Menu.Item>
          </NavLink>
          {hasPermissions(user, "add_contentarticle") &&
            this.props.location.pathname ===
              "/content-generation/assignments" && (
              <Menu.Menu className="SidebarNav--subMenu SidebarNav--leadView">
                <NavLink
                  isActive={(location) =>
                    location &&
                    this.props.location &&
                    location.pathname === this.props.location.pathname &&
                    this.props.location.search === "?lead"
                  }
                  to="/content-generation/assignments/?lead=true"
                >
                  <Menu.Item link>
                    <span>My Lead View</span>
                  </Menu.Item>
                </NavLink>
              </Menu.Menu>
            )}
          {hasPermissions(user, "view_accounthealth") && user.org_roles[0] && (
            <React.Fragment>
              <NavLink to="/account-health/">
                <Menu.Item link>
                  <Icon name="heart" />
                  <span className="SidebarNav--content">Account Health</span>
                </Menu.Item>
              </NavLink>

              {menuItemsPathMatch(this.props.menuItems, "account-health") && (
                <Menu.Menu className="SidebarNav--subMenu">
                  {Object.entries(this.props.menuItems).map(
                    ([key, value]) =>
                      key ===
                        menuItemsPathMatch(
                          this.props.menuItems,
                          "account-health"
                        ) &&
                      value
                        .filter((v) => v.path !== null)
                        .map((v) => (
                          <NavLink
                            key={v.id}
                            exact={isTopLevel(v.path)}
                            to={v.path}
                          >
                            <Menu.Item link>
                              <span>{v.menuLabel}</span>
                            </Menu.Item>
                          </NavLink>
                        ))
                  )}
                </Menu.Menu>
              )}
            </React.Fragment>
          )}
          {(hasPermissions(user, "add_contentarticle") ||
            user.default_role.name === "Customer" ||
            user.default_role.name === "Account Owner") && (
            <React.Fragment>
              <NavLink isActive={excludeAssignments} to="/content-generation/">
                <Menu.Item link>
                  <Icon name="pen square" />
                  <span className="SidebarNav--content">
                    Content Generation
                  </span>
                </Menu.Item>
              </NavLink>
              {/* {menuItemsPathMatch(
                this.props.menuItems,
                "content-generation"
              ) && isClient(user) && (
                <Menu.Menu className="SidebarNav--subMenu">
                   <NavLink
                      exact={isTopLevel('/content-generation/')}
                      to="/content-generation/archives/"
                    >
                      <Menu.Item link>
                        <span>Archives</span>
                      </Menu.Item>
                    </NavLink>
                </Menu.Menu>
              )} */}
            </React.Fragment>
          )}
          {hasPermissions(user, "view_websitebuild") && (
            <React.Fragment>
              <NavLink to="/client-onboarding/website-builds/">
                <Menu.Item link>
                  <Icon name="handshake" />
                  <span className="SidebarNav--content">Client Onboarding</span>
                </Menu.Item>
              </NavLink>
              {menuItemsPathMatch(
                this.props.menuItems,
                "client-onboarding"
              ) && (
                <Menu.Menu>
                  {Object.entries(this.props.menuItems).map(
                    ([key, value]) =>
                      key ===
                        menuItemsPathMatch(
                          this.props.menuItems,
                          "client-onboarding"
                        ) &&
                      value.map((v) => (
                        <NavLink
                          key={v.id}
                          exact={isTopLevel(v.path)}
                          to={v.path}
                        >
                          <Menu.Item link>
                            <span>{v.menuLabel}</span>
                          </Menu.Item>
                        </NavLink>
                      ))
                  )}
                </Menu.Menu>
              )}
            </React.Fragment>
          )}
          {hasPermissions(user) && (
            <NavLink to="/users/directory">
              <Menu.Item link>
                <Icon name="user" />
                <span className="SidebarNav--content">User Management</span>
              </Menu.Item>
            </NavLink>
          )}
          {hasPermissions(user) && (
            <NavLink to="/organizations/directory">
              <Menu.Item link>
                <Icon name="building" />
                <span className="SidebarNav--content">Organizations</span>
              </Menu.Item>
            </NavLink>
          )}
          {hasPermissions(user) && (
            <NavLink to="/apps/">
              <Menu.Item link>
                <Icon name="code" />
                <span className="SidebarNav--content">Apps</span>
              </Menu.Item>
            </NavLink>
          )}
          {hasPermissions(user) && (
            <React.Fragment>
              <NavLink to="/utilities/">
                <Menu.Item link>
                  <Icon name="wrench" />
                  <span className="SidebarNav--content">Utilities</span>
                </Menu.Item>
              </NavLink>

              {menuItemsPathMatch(this.props.menuItems, "utilities") && (
                <Menu.Menu className="SidebarNav--subMenu">
                  {Object.entries(this.props.menuItems).map(
                    ([key, value]) =>
                      key ===
                        menuItemsPathMatch(this.props.menuItems, "utilities") &&
                      value.map((v) => (
                        <NavLink
                          exact={isTopLevel(v.path)}
                          key={v.id}
                          to={v.path}
                        >
                          <Menu.Item link>
                            <span>{v.menuLabel}</span>
                          </Menu.Item>
                        </NavLink>
                      ))
                  )}
                </Menu.Menu>
              )}
            </React.Fragment>
          )}
          {hasPermissions(user, "view_monthlyreport") && user.org_roles[0] && (
            <React.Fragment>
              <NavLink
                to={`/reporting/${
                  hasPermissions(user) ? "presentation-templates" : ""
                }`}
              >
                <Menu.Item link>
                  <Icon name="chart bar outline" />
                  <span className="SidebarNav--content">Reporting</span>
                </Menu.Item>
              </NavLink>

              {menuItemsPathMatch(this.props.menuItems, "reporting") && (
                <Menu.Menu className="SidebarNav--subMenu">
                  {Object.entries(this.props.menuItems).map(
                    ([key, value]) =>
                      key ===
                        menuItemsPathMatch(this.props.menuItems, "reporting") &&
                      value
                        .filter((v) => v.path !== null)
                        .map((v) => (
                          <NavLink
                            key={v.id}
                            exact={isTopLevel(v.path)}
                            to={v.path}
                          >
                            <Menu.Item link>
                              <span>{v.menuLabel}</span>
                            </Menu.Item>
                          </NavLink>
                        ))
                  )}
                </Menu.Menu>
              )}
            </React.Fragment>
          )}
          <Menu.Item onClick={() => this.props.logout()} link>
            <Icon name="sign out" />
            <span className="SidebarNav--content">Logout</span>
          </Menu.Item>
        </React.Fragment>
        {hasPermissions(user, "add_contentarticle") && (
          <div className="SidebarNav--thirdPartyLinks">
            <a
              href="https://321webmarketing.slack.com"
              target="_blank"
              rel="noopener noreferrer"
              className="SidebarNav--slackLogo"
            >
              <Image src={slackLogo} alt="Slack Logo" />
              <span>Enter 321 Chatroom</span>
            </a>
          </div>
        )}
      </Sidebar>
    );
  }
}
