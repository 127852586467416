export const DEPARTMENTS = [
    {
        id: 1,
        text: "Web Dev",
        abbr: "WD",
        value: "webdev",
        color: "#ff01a1",
    },
    {
        id: 2,
        text: "Marketing",
        abbr: "MK",
        value: "marketing",
        color: "#206ee7",
    },
    {
        id: 3,
        text: "Biz Dev",
        abbr: "BD",
        value: "bizdev",
        color: "#ff6e01",
    },
    {
        id: 4,
        text: "Sales",
        abbr: "SD",
        value: "sales",
        color: "#d8920b",
    },
    {
        id: 5,
        text: "Team Wide",
        abbr: "TW",
        value: "team",
        color: "#c768ff",
    }
]

export const STATES = [
    {   key: 1,
        text: "Alabama",
        value: "AL"
    },
    {
        key: 2,
        text: "Alaska",
        value: "AK"
    },
    {
        key: 4,
        text: "Arizona",
        value: "AZ"
    },
    {
        key: 5,
        text: "Arkansas",
        value: "AR"
    },
    {
        key: 6,
        text: "California",
        value: "CA"
    },
    {
        key: 7,
        text: "Colorado",
        value: "CO"
    },
    {
        key: 8,
        text: "Connecticut",
        value: "CT"
    },
    {
        key: 9,
        text: "Delaware",
        value: "DE"
    },
    {
        key: 10,
        text: "District Of Columbia",
        value: "DC"
    },
    {
        key: 12,
        text: "Florida",
        value: "FL"
    },
    {
        key: 13,
        text: "Georgia",
        value: "GA"
    },
    {
        key: 15,
        text: "Hawaii",
        value: "HI"
    },
    {
        key: 16,
        text: "Idaho",
        value: "ID"
    },
    {
        key: 17,
        text: "Illinois",
        value: "IL"
    },
    {
        key: 18,
        text: "Indiana",
        value: "IN"
    },
    {
        key: 19,
        text: "Iowa",
        value: "IA"
    },
    {
        key: 20,
        text: "Kansas",
        value: "KS"
    },
    {
        key: 21,
        text: "Kentucky",
        value: "KY"
    },
    {
        key: 22,
        text: "Louisiana",
        value: "LA"
    },
    {
        key: 23,
        text: "Maine",
        value: "ME"
    },
    {
        key: 25,
        text: "Maryland",
        value: "MD"
    },
    {
        key: 26,
        text: "Massachusetts",
        value: "MA"
    },
    {
        key: 27,
        text: "Michigan",
        value: "MI"
    },
    {
        key: 28,
        text: "Minnesota",
        value: "MN"
    },
    {
        key: 29,
        text: "Mississippi",
        value: "MS"
    },
    {
        key: 30,
        text: "Missouri",
        value: "MO"
    },
    {
        key: 31,
        text: "Montana",
        value: "MT"
    },
    {
        key: 32,
        text: "Nebraska",
        value: "NE"
    },
    {
        key: 33,
        text: "Nevada",
        value: "NV"
    },
    {
        key: 34,
        text: "New Hampshire",
        value: "NH"
    },
    {
        key: 35,
        text: "New Jersey",
        value: "NJ"
    },
    {
        key: 36,
        text: "New Mexico",
        value: "NM"
    },
    {
        key: 37,
        text: "New York",
        value: "NY"
    },
    {
        key: 38,
        text: "North Carolina",
        value: "NC"
    },
    {
        key: 39,
        text: "North Dakota",
        value: "ND"
    },
    {
        key: 41,
        text: "Ohio",
        value: "OH"
    },
    {
        key: 42,
        text: "Oklahoma",
        value: "OK"
    },
    {
        key: 43,
        text: "Oregon",
        value: "OR"
    },
    {
        key: 45,
        text: "Pennsylvania",
        value: "PA"
    },
    {
        key: 47,
        text: "Rhode Island",
        value: "RI"
    },
    {
        key: 48,
        text: "South Carolina",
        value: "SC"
    },
    {
        key: 49,
        text: "South Dakota",
        value: "SD"
    },
    {
        key: 50,
        text: "Tennessee",
        value: "TN"
    },
    {
        key: 51,
        text: "Texas",
        value: "TX"
    },
    {
        key: 52,
        text: "Utah",
        value: "UT"
    },
    {
        key: 53,
        text: "Vermont",
        value: "VT"
    },
    {
        key: 54,
        text: "Virginia",
        value: "VA"
    },
    {
        key: 55,
        text: "Washington",
        value: "WA"
    },
    {
        key: 56,
        text: "West Virginia",
        value: "WV"
    },
    {
        key: 57,
        text: "Wisconsin",
        value: "WI"
    },
    {
        key: 58,
        text: "Wyoming",
        value: "WY"
    }
]