import React, { Component } from 'react';
import './AccountHealthDashboard.css';
import { connect } from 'react-redux';
import { accountHealth } from '../../../store/actions';
import AccountHealthOverviewList from '../AccountHealthOverviewList/AccountHealthOverviewList';
import { Loader } from 'semantic-ui-react';

class AccountHealthDashboard extends Component {
	componentDidMount() {
		this.props.overviews && !this.props.overviews[0] && this.props.fetchAccountHealthOverviews();
	}

	renderLoadingDetails = () => {
		if (this.props.isLoadingGroups) {
			return 'User Data';
		} else if (this.props.isLoading) {
			return 'Account Health Data';
		} else {
			return 'General Data';
		}
	};

	render() {
		return (
			<div className="AccountHealthDashboard">
				{!this.props.isLoading && this.props.isLoaded ? this.props.overviews && this.props.overviews[0] ? (
					<AccountHealthOverviewList overviews={this.props.overviews} />
				) : (
					<div class="AccountHealth--noData">
						<p>There are currently no Account Health Overviews</p>
					</div>
				) : (
					<Loader size="large" active>
						Loading {this.renderLoadingDetails()}
					</Loader>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		messages: state.accountHealth.messages,
		overviews: state.accountHealth.overviews.data,
		isLoading: state.accountHealth.overviews.isLoading,
		isLoaded: state.accountHealth.overviews.isLoaded,
		errors: state.accountHealth.overviews.errors,
		isLoadingGroups: state.auth.isLoadingGroups
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchAccountHealthOverviews: () => dispatch(accountHealth.fetchAccountHealthOverviews())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountHealthDashboard);
