import React, { Component } from 'react';
import Login from './Login/Login';
import Register from './Register/Register';
import PasswordResetRequest from './PasswordResetRequest/PasswordResetRequest';
import { Switch, Route, Redirect } from 'react-router-dom';
import './Auth.css';
import PasswordReset from './PasswordReset/PasswordReset';

class Auth extends Component {
	render() {
		return (
			<div className="AuthContainer">
				<Switch>
					<Route exact path={`${this.props.match.path}/login/admin`} render={() => <Login {...this.props} asAdmin={true} />} />
					<Route exact path={`${this.props.match.path}/login`} component={Login} />
					<Route exact path={`${this.props.match.path}/register/:key/:email`} component={Register} />
					<Route
						exact
						path={`${this.props.match.path}/request-password-reset/:email?`}
						component={PasswordResetRequest}
					/>
					<Route exact path={`${this.props.match.path}/password-reset/:uid/:token`} component={PasswordReset} />
					<Redirect to={`${this.props.match.path}/login`} />
				</Switch>
			</div>
		);
	}
}

export default Auth;
