import React, { Component } from "react";
import { users } from "../../store/actions";
import { connect } from "react-redux";
import { Form, Grid, Icon, Message } from "semantic-ui-react";
import "./UserPreferences.css";

class UserPreferences extends Component {
  state = {
    data: this.props.user.user_preferences
  };

  handleChange(e) {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value
      }
    });
  }

  handleSave = (e) => {
    e.preventDefault();
    this.props.updateUserPreferences(this.props.user.id, this.state.data)
  }

  render() {
    return (
      <div className="UserPreferences">
      {this.props.messages[0] && <Message positive size="mini">{this.props.messages}</Message>}
        <Form size="small" onSubmit={this.handleSave}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
              <Form.Field inline>
                  <label htmlFor="nick_name">Nick Name</label>
                  <input
                    placeholder="Enter a Nickname"
                    name="nick_name"
                    type="text"
                    value={this.state.data.nick_name ? this.state.data.nick_name : ''}
                    onChange={e => this.handleChange(e)}
                  />
                </Form.Field>
                <Form.Field inline>
                  <label htmlFor="avatar_color">Avatar Color</label>
                  <input
                    className="colorPicker"
                    placeholder="choose color"
                    name="avatar_color"
                    type="color"
                    value={this.state.data.avatar_color}
                    onChange={e => this.handleChange(e)}
                  />
                </Form.Field>
                <Form.Button icon labelPosition='right' positive size="small" fluid type="submit">
                Save
                <Icon name="save" />
                </Form.Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    messages: state.auth.messages,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateUserPreferences: (id, data) =>
      dispatch(users.updateUserPreferences(id, data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserPreferences);
