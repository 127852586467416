import React, { Component } from "react";
import "./TestingComponent.css";
import { connect } from "react-redux";
import { wordpress } from "../../store/actions";

export class TestingComponent extends Component {
  // componentDidMount() {
  // 	this.props.posts && !this.props.posts[0] && this.props.fetchPosts('https://321webmarketing.com', {status: '[future, publish]'});
  // 	!this.props.currentPost && this.props.fetchPost('https://321webmarketing.com', 4600);
  // 	!this.props.currentUser && this.props.fetchCurrentUser('https://321webmarketing.com');
  // }

  render() {
    return (
      <div className="RouteContainer">
        <div className="TestingComponent">
			testing
          {/* <PopupNotification
			trigger={<Button>Open</Button>}
			withClose
			headerSize="h4"
			animation="fade left"
            top="0%"
            left="84.5%"
            header="Test header"
            open
          >
            This is a PopupNotiffication
          </PopupNotification> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCurrentUser: (organizationURL, params) =>
      dispatch(wordpress.fetchWPUser(organizationURL, params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestingComponent);
