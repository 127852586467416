import React from 'react';
import './PresentationSettings.css';

const PresentationSettings = (props) => {
	const SettingsComponent = props.settingsComponent ? props.settingsComponent : null;
	return (
		<div className="PresentationSettings">
			{SettingsComponent ? (
				<SettingsComponent {...props} primarySettingsObject={props.primarySettingsObject} />
			) : (
				<p>Please choose a settingsComponent to render here.</p>
			)}
		</div>
	);
};

export default PresentationSettings;
