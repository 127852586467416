import React, { Component } from 'react';
import {
	Form,
	Select,
	Button,
	Transition,
	Icon,
	Header,
	Checkbox,
	Divider,
	Image,
	Dimmer,
	Loader,
	Input
} from 'semantic-ui-react';
import FormHeader from '../FormHeader/FormHeader';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import ErrorHandler from '../../components/ErrorHandler/ErrorHandler';
import { organizations, contentGeneration } from '../../store/actions';
import { connect } from 'react-redux';
import EditorialRequirementDetail from '../../components/EditorialRequirementsDetail/EditorialRequirementsDetail';
import DividerHeader from '../DividerHeader/DividerHeader';
import EditorialRequirementForm from '../EditorialRequirementForm/EditorialRequirementForm';
import { STATES } from '../../constants';
import { DateInput } from 'semantic-ui-calendar-react';
import './OrganizationForm.css';

const initialState = {
	dbaName: '',
	legalName: '',
	email: '',
	phone: '',
	streetAddress1: '',
	streetAddress2: '',
	city: '',
	state: '',
	zipcode: '',
	domain: '',
	logo: '',
	report_required: true,
	google_analytics_id: '',
	what_converts_account: {
		account_id: '',
		report_calls: true,
		report_form_fills: true,
		report_chats: false,
		spt_account: false
	},
	default_report_creator: '',
	default_report_approver: '',
	contract_start_date: '',
	account_lead: '',
	project_manager: ''
};

class OrganizationForm extends Component {
	state = {
		form: initialState,
		addingRequirements: false
	};

	componentDidMount() {
		if (this.props.selectedOrg) {
			this.setForm();
		}
		!this.props.editorialRequirements[0] && this.props.fetchEditorialRequirements();
		!this.props.contentStaff[0] && this.props.fetchContentStaff();
	}

	componentDidUpdate(prevProps) {
		if (this.props.selectedOrg !== prevProps.selectedOrg) {
			if (this.props.selectedOrg) {
				this.setForm();
			} else {
				this.clearForm();
			}
		}
	}

	setForm = () => {
		let org = this.props.selectedOrg;
		this.setState({
			form: {
				dbaName: org.dba_name,
				legalName: org.legal_name,
				email: org.business_email,
				phone: org.phone_number,
				streetAddress1: org.street_address_1,
				streetAddress2: org.street_address_2,
				city: org.city,
				state: org.state,
				zipcode: org.zipcode,
				domain: org.domain,
				report_required: org.report_required,
				contract_start_date: org.contract_start_date,
				google_analytics_id: org.google_analytics_id,
				what_converts_account: org.what_converts_account,
				default_report_creator: org.default_report_creator,
				default_report_approver: org.default_report_approver,
				account_lead: org.account_lead,
				project_manager: org.project_manager,
			},
			orgLogo: org.logo,
			imagePath: null
		});
	};

	clearForm = () => {
		this.setState({
			form: initialState,
			orgLogo: null,
			imagePath: null
		});
	};

	handleselectedFile = (event) => {
		if (event.target.files && event.target.files[0]) {
			this.setState({
				form: {
					...this.state.form,
					logo: event.target.files[0]
				}
			});
			this.getBase64(event.target.files[0]);
		} else {
			this.setState({
				form: {
					...this.state.form,
					logo: null
				},
				imagePath: null,
				orgLogo: this.props.selectedOrg
					? this.props.selectedOrg.logo ? this.props.selectedOrg.logo : null
					: null
			});
		}
	};

	getBase64 = (file) => {
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			this.setState({
				...this.state,
				imagePath: reader.result
			});
		};
		reader.onerror = function(error) {
			console.log('Error: ', error);
		};
	};

	handleChange(e, whatConvertsField) {
		if (whatConvertsField) {
			this.setState({
				form: {
					...this.state.form,
					what_converts_account: {
						...this.state.form.what_converts_account,
						[e.target.name]: e.target.value
					}
				}
			});
		} else {
			this.setState({
				form: {
					...this.state.form,
					[e.target.name]: e.target.value
				}
			});
		}
	}

	handleSelectChange = (event, { name, value }) => {
		this.setState({ form: { ...this.state.form, [name]: value } });
	};

	handleWhatConvertsCheckboxToggle = (e, data) => {
		const { name, checked } = data;
		this.setState({
			form: {
				...this.state.form,
				what_converts_account: {
					...this.state.form.what_converts_account,
					[name]: checked
				}
			}
		});
	};

	handleReportRequirementToggle = (e) => {
		this.setState({
			form: {
				...this.state.form,
				report_required: !this.state.form.report_required
			}
		});
	};

	handleSubmit = async (e) => {
		e.preventDefault();
		if (this.props.selectedOrg) {
			await this.props.updateOrganization(this.state.form, this.props.selectedOrg.id);
		} else if (this.props.isAddingOrg) {
			await this.props.addOrganization(this.state.form);
			return !this.props.errors && this.props.cancelAction();
		}
	};

	handleArchive = async () => {
		await this.props.archiveOrganization(this.props.selectedOrg.id, true);
		this.props.cancelAction();
	};

	renderLogo = () => {
		if (this.state.orgLogo || this.state.imagePath) {
			return this.state.imagePath ? this.state.imagePath : this.state.orgLogo;
		} else {
			return false;
		}
	};

	render() {
		const orgEditorialRequirements = this.props.selectedOrg
			? this.props.editorialRequirements.filter((req) => req.organization === this.props.selectedOrg.id)
			: null;
		return (
			<div className="OrganizationForm">
				{this.props.selectedOrg &&
				this.props.selectedOrg.isUpdating && (
					<Dimmer active inverted>
						<Loader>Updating Organization</Loader>
					</Dimmer>
				)}
				{this.props.isAddingOrganization && (
					<Dimmer active inverted>
						<Loader>Adding Organization</Loader>
					</Dimmer>
				)}
				<FormHeader headerText={this.props.selectedOrg ? 'Update Organization' : 'Add Organization'}>
					{this.props.selectedOrg && (
						<ConfirmationModal
							handleConfirm={() => this.handleArchive()}
							headerText="Archive Organization?"
							headerIcon="trash alternate"
							buttonIcon="times"
							buttonText="Archive"
							color="red"
							compact
							buttonSize="mini"
							bodyText={<div><p>Are you sure you want to deactivate this Organization?</p><p><strong>Warning: </strong>This will put all articles filed under this organization On-Hold</p></div>}
						/>
					)}
					<Button compact size="mini" onClick={() => this.props.cancelAction()}>
						Cancel
					</Button>
				</FormHeader>
				{this.props.errors && <ErrorHandler errors={this.props.errors} />}
				<Form onSubmit={this.handleSubmit} size="small">
					<Form.Field inline>
						<label>DBA Name</label>
						<Input
							required
							type="text"
							name="dbaName"
							placeholder="DBA Name"
							onChange={(e) => this.handleChange(e)}
							value={this.state.form.dbaName}
						/>
					</Form.Field>
					<Form.Field inline>
						<label>Legal Name</label>
						<Input
							required
							type="text"
							name="legalName"
							placeholder="Legal Name"
							onChange={(e) => this.handleChange(e)}
							value={this.state.form.legalName}
						/>
					</Form.Field>
					<Form.Field inline>
						<label>Business Email</label>
						<Input
							required
							icon="mail"
							iconPosition="left"
							type="email"
							name="email"
							placeholder="Business Email"
							onChange={(e) => this.handleChange(e)}
							value={this.state.form.email}
						/>
					</Form.Field>
					<Form.Field inline>
						<label>Phone</label>
						<Input
							icon="mobile"
							iconPosition="left"
							type="text"
							name="phone"
							placeholder="Phone Number"
							onChange={(e) => this.handleChange(e)}
							value={this.state.form.phone}
						/>
					</Form.Field>
					<Form.Field inline>
						<label>Website</label>
						<Input
							icon="globe"
							iconPosition="left"
							type="url"
							name="domain"
							placeholder="Website"
							onChange={(e) => this.handleChange(e)}
							value={this.state.form.domain ? this.state.form.domain : ''}
						/>
					</Form.Field>
					<Form.Field inline>
						<label>Street</label>
						<Input
							icon="map marker"
							iconPosition="left"
							type="text"
							name="streetAddress1"
							placeholder="Street"
							onChange={(e) => this.handleChange(e)}
							value={this.state.form.streetAddress1}
						/>
					</Form.Field>
					<Form.Field inline>
						<label>Street Cont.</label>
						<Input
							icon="map marker"
							iconPosition="left"
							type="text"
							name="streetAddress2"
							placeholder="Street Cont."
							onChange={(e) => this.handleChange(e)}
							value={this.state.form.streetAddress2 ? this.state.form.streetAddress2 : ''}
						/>
					</Form.Field>
					<Form.Field inline>
						<label>City</label>
						<Input
							type="text"
							name="city"
							placeholder="City"
							onChange={(e) => this.handleChange(e)}
							value={this.state.form.city}
						/>
					</Form.Field>
					<Form.Field inline>
						<label>State</label>
						<Select
							selection
							autoComplete="nope"
							search
							type="text"
							placeholder="State"
							onChange={this.handleSelectChange}
							name="state"
							options={STATES}
							value={this.state.form.state}
						/>
					</Form.Field>
					<Form.Field inline>
						<label>Zipcode</label>
						<Input
							type="number"
							min="00000"
							max="99999"
							name="zipcode"
							placeholder="Zipcode"
							onChange={(e) => this.handleChange(e)}
							value={this.state.form.zipcode}
						/>
					</Form.Field>
					<Form.Field inline>
						<label>Contract Date</label>
						<DateInput
							closable
							name="contract_start_date"
							placeholder="Choose Date"
							iconPosition="left"
							dateFormat="YYYY-MM-DD"
							value={this.state.form.contract_start_date ? this.state.form.contract_start_date : ''}
							onChange={this.handleSelectChange}
						/>
					</Form.Field>
					<Form.Field inline>
						<label>Project Manager</label>
						<Select
							selection
							loading={this.props.isContentDataLoading}
							disabled={this.props.isContentDataLoading}
							placeholder="Select Project Manager"
							onChange={this.handleSelectChange}
							name="project_manager"
							options={
								this.props.contentStaff[0] ? (
									this.props.contentStaff.filter(staff => staff.is_staff).map((staff) => ({
										key: staff.id,
										text: staff.name,
										value: staff.id
									}))
								) : (
									[]
								)
							}
							value={this.state.form.project_manager}
						/>
					</Form.Field>
					<Form.Field inline>
						<label>Account Lead</label>
						<Select
							selection
							loading={this.props.isContentDataLoading}
							disabled={this.props.isContentDataLoading}
							placeholder="Select Account Lead"
							onChange={this.handleSelectChange}
							name="account_lead"
							options={
								this.props.contentStaff[0] ? (
									this.props.contentStaff.filter(staff => staff.is_staff).map((staff) => ({
										key: staff.id,
										text: staff.name,
										value: staff.id
									}))
								) : (
									[]
								)
							}
							value={this.state.form.account_lead}
						/>
					</Form.Field>
					<Form.Field inline className="OrganizationForm--imageField">
						<label htmlFor="logo">Logo</label>
						<div className="OrganizationForm--imageFieldContainer">
							{this.renderLogo() && (
								<Image src={this.renderLogo()} alt={`${this.state.form.dbaName} logo`} size="tiny" />
							)}
							<Input
								key={this.state.orgLogo}
								id="logo"
								type="file"
								selected
								name="logo"
								accept="image/png, image/jpeg"
								onChange={this.handleselectedFile}
							/>
						</div>
					</Form.Field>
					<Divider className="OrganizationForm--divider" horizontal>
						<span>Reporting</span>
					</Divider>
					<Form.Field inline>
						<label htmlFor="report_required">Report Required</label>
						<Checkbox
							name="report_required"
							onChange={this.handleReportRequirementToggle}
							checked={this.state.form.report_required}
						/>
					</Form.Field>
					<Form.Field inline>
						<label>Google Analytics ID</label>
						<Input
							type="text"
							name="google_analytics_id"
							placeholder="Google Analytics ID"
							onChange={(e) => this.handleChange(e)}
							value={this.state.form.google_analytics_id ? this.state.form.google_analytics_id : ''}
						/>
					</Form.Field>
					<Form.Group>
						<Form.Field inline>
							<label>WhatConverts ID</label>
							<Input
								type="text"
								name="account_id"
								placeholder="WhatConverts ID"
								onChange={(e) => this.handleChange(e, true)}
								value={
									this.state.form.what_converts_account ? (
										this.state.form.what_converts_account.account_id
									) : (
										''
									)
								}
							/>
						</Form.Field>
						<Form.Field inline>
							<label>SPT Account?</label>
							<Checkbox
								type="checkbox"
								name="spt_account"
								onChange={(e, data) => this.handleWhatConvertsCheckboxToggle(e, data)}
								checked={
									this.state.form.what_converts_account ? (
										this.state.form.what_converts_account.spt_account
									) : (
										false
									)
								}
							/>
						</Form.Field>
					</Form.Group>
					<Form.Field inline>
						<label>Report Creator</label>
						<Select
							selection
							placeholder="Select Default Report Creator"
							onChange={this.handleSelectChange}
							name="default_report_creator"
							options={
								this.props.contentStaff[0] ? (
									this.props.contentStaff.map((staff) => ({
										key: staff.id,
										text: staff.name,
										value: staff.id
									}))
								) : (
									[]
								)
							}
							value={this.state.form.default_report_creator}
						/>
					</Form.Field>
					<Form.Field inline>
						<label>Report Approver</label>
						<Select
							selection
							placeholder="Select Default Report Approver"
							onChange={this.handleSelectChange}
							name="default_report_approver"
							options={
								this.props.contentStaff[0] ? (
									this.props.contentStaff.map((staff) => ({
										key: staff.id,
										text: staff.name,
										value: staff.id
									}))
								) : (
									[]
								)
							}
							value={this.state.form.default_report_approver}
						/>
					</Form.Field>
					<Form.Field className="alignRight">
						<Button type="submit" positive size="tiny">
							{this.props.selectedOrg ? 'Update Organization' : 'Add Organization'}
						</Button>
					</Form.Field>
				</Form>
				{this.props.selectedOrg && (
					<section className="OrganizationEditorialRequirements">
						{orgEditorialRequirements[0] ? (
							<div className="editorialReqs">
								<DividerHeader size="h4" icon="checkmark">
									Editorial Requirements
								</DividerHeader>
								<EditorialRequirementDetail requirements={orgEditorialRequirements} />
							</div>
						) : (
							<div className="noOrgReqs">
								<Header as="h5">No Requirements Yet.</Header>
							</div>
						)}
						<Button
							onClick={() =>
								this.setState({
									addingRequirements: !this.state.addingRequirements
								})}
							size="tiny"
							fluid
							color="teal"
							icon
							labelPosition="right"
						>
							{`${this.state.addingRequirements ? 'Done' : 'Add Requirements'}`}
							{this.state.addingRequirements ? <Icon name="minus" /> : <Icon name="plus" />}
						</Button>
						<Transition.Group duration={300} animation="slide down">
							{this.state.addingRequirements && (
								<EditorialRequirementForm orgId={this.props.selectedOrg.id} />
							)}
						</Transition.Group>
					</section>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		editorialRequirements: state.organizations.editorialRequirements,
		isLoadingEditorialRequirements: state.organizations.isLoadingEditorialRequirements,
		editorialRequirementsLoaded: state.organizations.editorialRequirementsLoaded,
		isAddingOrganization: state.organizations.isAddingOrganization,
		messages: state.organizations.messages,
		errors: state.organizations.errors,
		contentStaff: state.contentGeneration.contentStaff,
		isContentDataLoading: state.contentGeneration.isContentDataLoading
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		addOrganization: (formData) => {
			return dispatch(organizations.addOrganization(formData, true));
		},
		updateOrganization: (formData, id) => {
			return dispatch(organizations.updateOrganization(formData, id, true));
		},
		createWhatConvertsAccount: (id, formData, message) => {
			return dispatch(organizations.createWhatConvertsAccount(id, formData, message));
		},
		updateWhatConvertsAccount: (id, whatConvertsAccountID, formData, message) => {
			return dispatch(organizations.updateWhatConvertsAccount(id, whatConvertsAccountID, formData, message));
		},
		fetchEditorialRequirements: () => {
			return dispatch(organizations.fetchEditorialRequirements());
		},
		addEditorialRequirement: () => {
			return dispatch(organizations.addEditorialRequirement());
		},
		updateEditorialRequirement: () => {
			return dispatch(organizations.updateEditorialRequirement());
		},
		deleteEditorialRequirement: () => {
			return dispatch(organizations.deleteEditorialRequirement());
		},
		fetchContentStaff: () => dispatch(contentGeneration.fetchContentStaff())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationForm);
