import React, { Component } from "react";
import { connect } from "react-redux";
import { checklists } from "../../../store/actions";
import { List, Icon, Loader, Divider, Button } from "semantic-ui-react";
import WithSidebar from "../../../hoc/WithSidebar/WithSidebar";
import ActionHeader from "../../../components/ActionHeader/ActionHeader";
import "./OnboardingChecklists.css";
import ChecklistTemplate from "../../../components/ChecklistTemplate/ChecklistTemplate";
import ChecklistMessage from "../../../components/Checklist/ChecklistMessage/ChecklistMessage";
import BasicModal from "../../../components/BasicModal/BasicModal";
import ChecklistTemplateForm from "../../../components/ChecklistTemplate/ChecklistTemplateForm/ChecklistTemplateForm";

class OnboadingChecklists extends Component {
  state = {
    selectedList: null,
    isAddingTemplate: false,
    addMasterWebsiteBuild: false,
    addMasterWebsiteDeployment: false,
    templateToEdit: null
  };

  componentDidMount() {
    this.props.fetchMasterChecklistTemplates();
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if master checklists changed
    if (
      this.props.masterChecklistTemplates.templates !==
        prevProps.masterChecklistTemplates.templates &&
      this.props.masterChecklistTemplates.isLoaded
    ) {
      this.props.masterChecklistTemplates.templates.website_build && this.props.fetchChecklistTemplates(
        this.props.masterChecklistTemplates.templates.website_build
      );
      this.props.masterChecklistTemplates.templates.website_deployment && this.props.fetchChecklistTemplates(
        this.props.masterChecklistTemplates.templates.website_deployment
      );
    }
    // Update list if changed
    if (
      this.props.checklistTemplates.templates !==
      prevProps.checklistTemplates.templates
    ) {
      if (this.state.selectedList) {
        let updatedTemplate = this.props.checklistTemplates.templates.find(
          template => template.id === this.state.selectedList.id
        );
        this.setState({ selectedList: updatedTemplate });
      }
    }
    if (
      this.state.isAddingTemplate === false &&
      prevState.isAddingTemplate === true &&
      (this.state.addMasterWebsiteBuild ||
        this.state.addMasterWebsiteDeployment)
    ) {
      if (this.state.addMasterWebsiteBuild) {
        this.props.updateMasterChecklistTemplate(
          this.props.checklistTemplates.newestList.id,
          this.props.masterChecklistTemplates.templates.website_deployment
        );
        this.setState({
          addMasterWebsiteBuild: false,
          addMasterWebsiteDeployment: false
        });
      } else if (this.state.addMasterWebsiteDeployment) {
        this.props.updateMasterChecklistTemplate(
          this.props.masterChecklistTemplates.templates.website_build,
          this.props.checklistTemplates.newestList.id,
        );
        this.setState({
          addMasterWebsiteBuild: false,
          addMasterWebsiteDeployment: false
        });
      }
    }
  }

  handleListSelect = list => {
    this.setState({
      selectedList: list,
      isAddingTemplate: false
    });
  };

  handleClose = () => {
    this.setState({ selectedList: null });
  };

  isSelected = listID => {
    return this.state.selectedList && this.state.selectedList.id === listID
      ? true
      : false;
  };

  shouldModalShow = () => {
    if (this.state.isAddingTemplate || this.state.templateToEdit) {
      return true;
    } else {
      return false;
    }
  };

  handleAddChecklistTemplate = (
    masterWebsiteBuild,
    masterWebsiteDeployment
  ) => {
    this.setState({
      selectedList: null,
      isAddingTemplate: true,
      addMasterWebsiteBuild: masterWebsiteBuild ? masterWebsiteBuild : false,
      addMasterWebsiteDeployment: masterWebsiteDeployment
        ? masterWebsiteDeployment
        : false,
      templateToEdit: null
    });
  };

  closeForm = addedMasterBuild => {
    this.setState({
      selectedList: null,
      isAddingTemplate: false,
      templateToEdit: null,
      addMasterWebsiteBuild: addedMasterBuild
        ? this.state.addMasterWebsiteBuild
        : false,
      addMasterWebsiteDeployment: addedMasterBuild
        ? this.state.addMasterWebsiteDeployment
        : false
    });
  };

  render() {
    let websiteBuildChecklist = this.props.checklistTemplates.templates
      ? this.props.checklistTemplates.templates.find(
          template =>
            template.id ===
            (this.props.masterChecklistTemplates.isLoaded &&
              this.props.masterChecklistTemplates.templates.website_build)
        )
      : null;
    let websiteDeploymentChecklist = this.props.checklistTemplates.templates
      ? this.props.checklistTemplates.templates.find(
          template =>
            template.id ===
            (this.props.masterChecklistTemplates.isLoaded &&
              this.props.masterChecklistTemplates.templates.website_deployment)
        )
      : null;

    let selectedList = this.state.selectedList ? this.state.selectedList : null;
    return (
      <div className="OnboadingChecklists">
        <WithSidebar
          leftWidth={7}
          rightWidth={9}
          isShowing={this.state.selectedList}
          sidebarComponent={
            <div className="OnboadingChecklists--sidebar">
              <ActionHeader
                size="h4"
                headerText={selectedList && selectedList.name}
                icon="list alternate outline"
              >
                {this.props.templateMessages[0] && (
                  <ChecklistMessage>
                    {this.props.templateMessages}
                  </ChecklistMessage>
                )}
                <Button
                  size="tiny"
                  basic
                  compact
                  onClick={() => this.handleClose()}
                >
                  Close
                </Button>
              </ActionHeader>
              <ChecklistTemplate
                list={selectedList}
                items={selectedList && selectedList.template_items}
              />
            </div>
          }
        >
          <ActionHeader icon="list" headerText="Onboarding Checklists" />
          <Divider />
          {this.props.masterChecklistTemplates.isLoaded ? (
            <List className="OnboadingChecklists--list" selection size="large">
              {this.props.masterChecklistTemplates.templates.website_build ? (
                websiteBuildChecklist ? (
                  <List.Item
                    className={`OnboadingChecklists__item ${
                      this.isSelected(websiteBuildChecklist.id)
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => this.handleListSelect(websiteBuildChecklist)}
                  >
                    <Icon name="code" />
                    <List.Content>
                      <List.Header>Master Website Build Checklist</List.Header>
                      <List.Description>
                        {websiteBuildChecklist.template_items.length} Items
                      </List.Description>
                    </List.Content>
                  </List.Item>
                ) : (
                  <Loader inline="centered" active />
                )
              ) : (
                <List.Item
                  onClick={() => this.handleAddChecklistTemplate(true, false)}
                  className={`OnboadingChecklists__item noItem`}
                >
                  <Icon name="code" />
                  <List.Content>
                    <List.Header>
                      Add Master Website Build Checklist
                    </List.Header>
                    <List.Description>
                      Click to create new master website build checklist
                    </List.Description>
                  </List.Content>
                  <Icon size="large" name="plus" />
                </List.Item>
              )}
              {this.props.masterChecklistTemplates.templates
                .website_deployment ? (
                websiteDeploymentChecklist ? (
                  <List.Item
                    className={`OnboadingChecklists__item ${
                      this.isSelected(websiteDeploymentChecklist.id)
                        ? "selected"
                        : ""
                    }`}
                    onClick={() =>
                      this.handleListSelect(websiteDeploymentChecklist)
                    }
                  >
                    <Icon name="server" />
                    <List.Content>
                      <List.Header>
                        Master Website Deployment Checklist
                      </List.Header>
                      <List.Description>
                        {websiteDeploymentChecklist.template_items.length} Items
                      </List.Description>
                    </List.Content>
                  </List.Item>
                ) : (
                  <Loader inline="centered" active />
                )
              ) : (
                <List.Item
                  onClick={() => this.handleAddChecklistTemplate(false, true)}
                  className={`OnboadingChecklists__item noItem`}
                >
                  <Icon name="server" />
                  <List.Content>
                    <List.Header>
                      Add Master Website Deployment Checklist
                    </List.Header>
                    <List.Description>
                      Click to create new master website deployment checklist
                    </List.Description>
                  </List.Content>
                  <Icon size="large" name="plus" />
                </List.Item>
              )}
            </List>
          ) : (
            <Loader className="OnboadingChecklists--loader" active />
          )}
        </WithSidebar>
        <BasicModal
          inverted
          isOpen={this.shouldModalShow()}
          handleClose={() => this.closeForm()}
          title={
            this.state.templateToEdit
              ? "Edit Checklist Template"
              : "Add New Checklist Template"
          }
          size="tiny"
          icon="list"
        >
          <ChecklistTemplateForm
            closeForm={this.closeForm}
            addingMasterBuild={
              this.state.addMasterWebsiteBuild ||
              this.state.addMasterWebsiteDeployment
            }
            isAddingTemplate={this.state.isAddingTemplate}
            selectedTemplate={this.state.templateToEdit}
          />
        </BasicModal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    masterChecklistTemplates: state.checklists.masterChecklistTemplates,
    checklistTemplates: state.checklists.checklistTemplates,
    errors: state.checklists.errors,
    messages: state.checklists.messages,
    templateMessages: state.checklists.checklistTemplateItems.messages
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchMasterChecklistTemplates: () =>
      dispatch(checklists.fetchMasterChecklistTemplates()),
    fetchChecklistTemplates: id =>
      dispatch(checklists.fetchChecklistTemplates(id)),
    updateMasterChecklistTemplate: (websiteBuild, websiteDeployment) =>
      dispatch(
        checklists.updateMasterChecklistTemplate(
          websiteBuild,
          websiteDeployment
        )
      )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnboadingChecklists);
