import React, { Component } from 'react';
import { Header, Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';

class FormModal extends Component {
	state = { modalOpen: false };

	componentDidUpdate(prevProps) {
		if (
			prevProps.addedMonth !== this.props.addedMonth ||
			prevProps.addedProject !== this.props.addedProject ||
			this.props.closeModal !== prevProps.closeModal
		) {
			if (this.props.closeModal === false) {
				return;
			} else {
				this.setState({ modalOpen: false });
			}
		}
	}

	handleOpen = () => this.setState({ modalOpen: true });

	handleClose = () => this.setState({ modalOpen: false });

	render() {
		return (
			<Modal
				trigger={<div onClick={this.handleOpen}>{this.props.trigger ? this.props.trigger : this.props.actionTrigger}</div>}
				open={this.state.modalOpen}
				onClose={this.handleClose}
				size="small"
				closeIcon
			>
				<Header icon={this.props.headerIcon} content={this.props.headerText} />
				<Modal.Content>{this.props.children}</Modal.Content>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		addedMonth: state.contentGeneration.addedMonth,
		addedProject: state.contentGeneration.addedProject
	};
};

export default connect(mapStateToProps, null)(FormModal);
