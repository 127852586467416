import { combineReducers } from "redux";
import notifications from "./notifications";
import auth from "./auth";
import contentGeneration from "./contentGeneration";
import organizations from "./organizations";
import users from "./users";
import userFeedback from "./userFeedback";
import checklists from "./checklists";
import clientOnboarding from "./clientOnboarding";
import ui from "./ui";
import appManagement from "./appManagement";
import utilities from "./utilities";
import reporting from "./reporting";
import presentations from "./presentations";
import general from "./general";
import accountHealth from "./accountHealth";
import wordpress from "./wordpress";

const portalApp = combineReducers({
  notifications,
  auth,
  general,
  contentGeneration,
  organizations,
  users,
  userFeedback,
  checklists,
  clientOnboarding,
  ui,
  appManagement,
  utilities,
  reporting,
  presentations,
  accountHealth,
  wordpress,
});

const rootReducer = (state, action) => {
  if (action.type === "AUTH_LOGOUT") {
    state = undefined;
  }
  return portalApp(state, action);
};

export default rootReducer;
