import React, { Component } from "react";
import { auth, organizations, users } from "../../store/actions";
import { connect } from "react-redux";
import { Button, Form, Input, Select, Transition } from "semantic-ui-react";
import "./InviteUserForm.css";
import FormHeader from "../FormHeader/FormHeader";
import ErrorHandler from "../ErrorHandler/ErrorHandler";

const initialState = {
  email: "",
  organization: "",
  role: "",
  isWriter: false,
  price: null,
  firstName: "",
  lastName: "",
  title: "",
  phoneNumber: "",
  sendInvite: false,
  createUser: true
};

class InviteUserForm extends Component {
  state = {
    form: initialState
  };

  componentDidMount() {
    if (this.props.organizations) {
      !this.props.organizations[0] && this.props.fetchOrganizations();
    }
    if (this.props.groups) {
      !this.props.groups[0] && this.props.fetchGroups();
    }
  }

  clearForm = () => {
    this.setState({ form: initialState });
  };

  handleChange(e) {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  }

  handleSelectChange = (event, { name, value }) => {
    this.setState({ form: { ...this.state.form, [name]: value } });
  };

  handleSubmit = async e => {
    e.preventDefault();
    await this.props.inviteUser(this.state.form);
    !this.props.errors && this.props.cancelAction();
  };

  handleWriterToggle = () =>
    this.setState({
      form: { ...this.state.form, isWriter: !this.state.form.isWriter }
    });

    handleSendInviteToggle = () =>
    this.setState({
      form: { ...this.state.form, sendInvite: !this.state.form.sendInvite }
    });

    handleCreateUserToggle = () =>
    this.setState({
      form: { ...this.state.form, createUser: !this.state.form.createUser }
    });
  
    validateForm = () => {
      let {firstName, lastName, email, organization, role} = this.state.form;
      if (firstName && lastName && email && organization && role) {
        return true
      } else {
        return false;
      }
    }

  render() {
    return (
      <div className="InviteUserForm">
        <FormHeader headerText="Invite User">
          <Button compact size="mini" onClick={() => this.props.cancelAction()}>
            Cancel
          </Button>
        </FormHeader>
        {this.props.errors && <ErrorHandler errors={this.props.errors} />}
        <Form size="small" onSubmit={this.handleSubmit}>
          <Form.Field>
            <label>First Name</label>
            <Input
              required
              placeholder="First Name"
              onChange={e => this.handleChange(e)}
              name="firstName"
              value={this.state.form.firstName}
            />
          </Form.Field>
          <Form.Field>
            <label>Last Name</label>
            <Input
              required
              placeholder="Last Name"
              onChange={e => this.handleChange(e)}
              name="lastName"
              value={this.state.form.lastName}
            />
          </Form.Field>
          <Form.Field>
            <label>Email Address</label>
            <Input
              required
              placeholder="Email"
              onChange={e => this.handleChange(e)}
              name="email"
              value={this.state.form.email}
            />
          </Form.Field>
          <Form.Field>
            <label>Title</label>
            <Input
              placeholder="Title"
              onChange={e => this.handleChange(e)}
              name="title"
              value={this.state.form.title}
            />
          </Form.Field>
          <Form.Field>
            <label>Phone Number</label>
            <Input
              placeholder="Phone"
              onChange={e => this.handleChange(e)}
              name="phoneNumber"
              value={this.state.form.phoneNumber}
            />
          </Form.Field>
          {this.props.organizations && (
            <Form.Field>
              <label>Organization</label>
              <Select
                selection
                required
                placeholder="Choose Organization"
                onChange={this.handleSelectChange}
                name="organization"
                options={this.props.organizations.map(organization => ({
                  key: organization.id,
                  text: organization.dba_name,
                  value: organization.id
                }))}
                value={this.state.form.organization}
              />
            </Form.Field>
          )}
          {this.props.groups && (
            <Form.Field>
              <label>Role</label>
              <Select
                selection
                required
                placeholder="Choose Role"
                onChange={this.handleSelectChange}
                name="role"
                options={this.props.groups.map(group => ({
                  key: group.id,
                  text: group.name,
                  value: group.id
                }))}
                value={this.state.form.role}
              />
            </Form.Field>
          )}
          <Form.Group>
            <Form.Field>
              <label>Add as Writer?</label>
              <Form.Checkbox
                name="isWriter"
                onChange={this.handleWriterToggle}
                checked={this.state.form.isWriter}
                toggle
              />
            </Form.Field>
            <Form.Field>
              <label>Send Invite?</label>
              <Form.Checkbox
                name="sendInvite"
                onChange={this.handleSendInviteToggle}
                checked={this.state.form.sendInvite}
                toggle
              />
            </Form.Field>
            <Form.Field>
              <label>Create Account?</label>
              <Form.Checkbox
                name="createUser"
                onChange={this.handleCreateUserToggle}
                checked={this.state.form.createUser}
                toggle
              />
            </Form.Field>
          </Form.Group>
          <Transition
            animation="fade down"
            duration={300}
            visible={this.state.form.isWriter}
          >
            <Form.Field>
              <label>Rate</label>
              <Input
                type="number"
                step={0.01}
                placeholder="Cost Per Word"
                onChange={e => this.handleChange(e)}
                name="price"
                value={this.state.form.price ? this.state.form.price : ""}
              />
            </Form.Field>
          </Transition>
          <Form.Field className="alignRight">
            <Button disabled={!this.validateForm()} type="submit" positive size="tiny">
              Invite User
            </Button>
          </Form.Field>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    organizations: state.organizations.organizations,
    groups: state.auth.groups,
    errors: state.users.errors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchOrganizations: () => {
      return dispatch(organizations.fetchOrganizations());
    },
    fetchGroups: () => {
      return dispatch(auth.fetchGroups());
    },
    inviteUser: formData => {
      return dispatch(users.inviteUser(formData));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteUserForm);
