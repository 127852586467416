import React, { Component } from "react";
import "./UserFeedbackModal.css";
import { Modal, Header, Icon, Message } from "semantic-ui-react";

export default class UserFeedbackModal extends Component {
  render() {
    return (
      <div className="UserFeedbackModal">
        <Modal
          open={this.props.modalOpen}
          onClose={this.props.handleClose}
          size="tiny"
          closeIcon
          dimmer='blurring'
          className="UserFeedbackModal--modal"
        >
          <Header>
          {this.props.headerIcon && <Icon color="grey" name={this.props.headerIcon} />}
            {this.props.headerText ? this.props.headerText : "Feedback"}
          </Header>
          <Modal.Content>
            {this.props.messages[0] ? (
                this.props.messages.map((message, i) => (
                    <Message positive key={i} icon='check' header={message} content="We Appreciate your feedback" />
                ))
            ) : (
                <React.Fragment>
                    {this.props.body && <p><strong>{this.props.body}</strong></p>}
                    {this.props.children}
                </React.Fragment>
            )}
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}
