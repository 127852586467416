import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/auth';
import { Redirect, Link } from 'react-router-dom';
import { Header, Form, Button, Image, Divider, Loader, Icon, Input } from 'semantic-ui-react';
import ErrorHandler from '../../../components/ErrorHandler/ErrorHandler';
import logo from '../../../assets/images/321-logo-no-tagline-portal.png';
import './Login.css';

const initialState = {
	username: '',
	password: '',
	asUser: ''
};
export class Login extends Component {
	usernameRef = React.createRef();
	passwordRef = React.createRef();
	asUserRef = React.createRef();

	state = {
		form: initialState,
		attempts: 0,
		showAdminFields: false
	};

	componentDidMount() {
		if (
			!this.props.isAuthenticated &&
			this.props.location &&
			this.props.location.state &&
			this.props.location.state.redirectUrl
		) {
			this.props.onSetAuthRedirectPath(this.props.location.state.redirectUrl);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			((this.state.attempts && !prevState.attempts) || this.props.errors !== prevProps.errors) &&
			(!this.props.isAuthenticated || !this.props.isLoading)
		) {
			this.clearForm();
			this.setState({ showAdminFields: false });
		}
		if (this.props.asAdmin && this.state.showAdminFields && !prevState.showAdminFields) {
			setTimeout(() => this.asUserRef.current.focus(), 300);
		}
	}

	onSubmit = async (e) => {
		e.preventDefault();
		if (this.props.asAdmin && this.state.showAdminFields && this.state.form.asUser) {
			console.log('should have submitted form as user here');
			await this.props.login(this.state.form.username, this.state.form.password, this.state.form.asUser);
		} else {
			await this.props.login(this.state.form.username, this.state.form.password);
		}
		return this.setState({ attempts: this.state.attempts + 1 });
	};

	clearForm = () => {
		this.setState({ form: initialState });
	};

	handleChange(e) {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
			}
		});
	}

	handleAdminFields = () => {
		this.setState({ showAdminFields: !this.state.showAdminFields });
	};

	handleRef = (c) => {
		this.inputRef = c;
	};

	focus = () => {
		this.inputRef.focus();
	};

	render() {
		let authRedirect = null;
		if (this.props.isAuthenticated) {
			authRedirect = <Redirect to={this.props.authRedirectPath} />;
		}
		return (
			<div className={`Login${this.props.asAdmin ? ' asAdmin' : ''}`}>
				{authRedirect}
				<div className={`AuthSidebar${this.props.isLoading ? ' isLoading' : ''}`}>
					<a target="_blank" rel="noopener noreferrer" href="https://www.321webmarketing.com/">
						<Image centered size="mini" src={logo} alt="321 web marketing" />
					</a>
				</div>
				<div className="LoginForm">
					<Header as="h2">{this.props.asAdmin && 'Admin'} Login</Header>
					<Divider />
					<Form onSubmit={this.onSubmit}>
						{this.props.errors && !this.props.isLoading && <ErrorHandler errors={this.props.errors} />}
						{this.props.isLoading && (
							<Loader size="small" active>
								Authenticating
							</Loader>
						)}
						<div
							className={`LoginForm--inputs${this.state.showAdminFields
								? ' showingAdminFields'
								: ' notShowingAdminFields'}`}
						>
							<div className="LoginForm--userInputs">
								<Form.Input
									label="Username or Email"
									name="username"
									type="text"
									icon="user"
									disabled={this.props.isLoading}
									iconPosition="left"
									placeholder="Username or Email"
									id="username"
									value={this.state.form.username}
									onChange={(e) => this.handleChange(e)}
								/>
								<Form.Input
									label="Password"
									type="password"
									name="password"
									placeholder="Password"
									disabled={this.props.isLoading}
									icon="lock"
									iconPosition="left"
									id="password"
									value={this.state.form.password}
									onChange={(e) => this.handleChange(e)}
								/>
							</div>
							{this.props.asAdmin && (
								<div className={`LoginForm--adminInputs`}>
									<Form.Field>
										<label>Username or Email</label>
										<Input
											name="asUser"
											type="text"
											icon="user outline"
											disabled={this.props.isLoading}
											iconPosition="left"
											placeholder="Username or Email of User"
											id="asUser"
											value={this.state.form.asUser}
											onChange={(e) => this.handleChange(e)}
											ref={this.asUserRef}
										/>
									</Form.Field>
								</div>
							)}
						</div>
						<div className="buttonHolder">
							<div className="buttonHolder--left buttonHolder--section">
								<Button icon color={`${this.props.asAdmin ? 'blue' : 'purple'}`} type="submit">
									{this.props.asAdmin && this.state.showAdminFields ? 'Proceed' : 'Login'}
									<Icon name="sign-in" />
								</Button>
								{this.props.asAdmin && (
									<Button
										icon
										disabled={
											this.props.isLoading ||
											(!this.state.form.username || !this.state.form.password)
										}
										color={!this.state.showAdminFields ? 'blue' : 'red'}
										basic
										type="button"
										onClick={() => this.handleAdminFields()}
									>
										{this.state.showAdminFields ? 'Cancel' : 'Login as User'}
										{this.state.showAdminFields ? (
											<Icon name="cancel" />
										) : (
											<Icon name="user outline" />
										)}
									</Button>
								)}
							</div>
							<Link to="/auth/request-password-reset/">
								<Button
									className={`LoginForm--forgotPasswordButton${this.state.attempts > 2
										? ' highlight'
										: ''}`}
									basic
									type="button"
								>
									Forgot Password?
								</Button>
							</Link>
						</div>
					</Form>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		errors: state.auth.errors,
		isAuthenticated: state.auth.isAuthenticated,
		authRedirectPath: state.auth.authRedirectPath,
		isLoading: state.auth.isLoading
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		login: (username, password, ghostUser) => {
			return dispatch(actions.login(username, password, ghostUser));
		},
		onSetAuthRedirectPath: (redirectPath) => dispatch(actions.setAuthRedirectPath(redirectPath))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
