import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  linkChecks: {
    checks: [],
    checkInProgress: false,
    errors: null,
    messages: []
  }
};

const addMessage = (state, action) => {
  return updateObject(state, {
    linkChecks: {
      ...state.linkChecks,
      messages: state.linkChecks.messages.concat(action.message)
    }
  });
};

const removeMessage = (state, action) => {
  let messagesList = state.linkChecks.messages.slice();
  messagesList.splice(0, 1);
  return updateObject(state, {
    linkChecks: {
      ...state.linkChecks,
      messages: messagesList
    }
  });
};

const startLinkCheck = (state, action) => {
  return updateObject(state, {
    linkChecks: {
      ...state.linkChecks,
      errors: null,
      messages: null,
      checkInProgress: true
    }
  });
};

const linkCheckSuccess = (state, action) => {
  return updateObject(state, {
    linkChecks: {
      ...state.linkChecks,
      errors: null,
      checkInProgress: false,
      checks: state.linkChecks.checks.concat(action.report)
    }
  });
};

const linkCheckFailure = (state, action) => {
  return updateObject(state, {
    linkChecks: {
      ...state.linkChecks,
      errors: action.errors,
      checkInProgress: false
    }
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_MESSAGE:
      return addMessage(state, action);
    case actionTypes.REMOVE_MESSAGE:
      return removeMessage(state, action);
    case actionTypes.START_LINK_CHECK:
      return startLinkCheck(state, action);
    case actionTypes.LINK_CHECK_SUCCESS:
      return linkCheckSuccess(state, action);
    case actionTypes.LINK_CHECK_FAILURE:
      return linkCheckFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
