import React from 'react';
import { Message } from 'semantic-ui-react'
import "./FormMessage.css";

const FormMessage = (props) => {
    return (
        <div className="FormMessage">
        <Message size="tiny" positive>
            {props.children}
        </Message>
        </div>
    );
};

export default FormMessage;