import React, { Component } from "react";
import { Form, Select } from "semantic-ui-react";
import "./ReportBulkForm.css";

const initialState = {
  status: "",
  creator: "",
  approver: ""
};

export default class ReportBulkForm extends Component {
  state = initialState;
  handleCreatorUpdate = id => {
    this.setState({ creator: id });
  };
  handleApproverUpdate = id => {
    this.setState({ approver: id });
  };
  handleSelectChange = (event, { name, value }) => {
    this.setState({ [name]: value });
  };
  handleSubmit = e => {
      e.preventDefault();
      this.props.reports.forEach(async report => {
          await this.props.handleUpdateReport(report.id, {
              ...report,
              status: this.state.status ? this.state.status : report.status,
              creator: this.state.creator ? this.state.creator : report.creator,
              approver: this.state.approver ? this.state.approver : report.approver
          })
      })
  }
  render() {
    return (
      <div className="ReportBulkForm">
        <Form onSubmit={this.handleSubmit}>
          <Form.Field>
            <label htmlFor="status">Status</label>
            <Select
              id="status"
              name="status"
              placeholder="Select Status"
              onChange={this.handleSelectChange}
              selection
              options={[
                {
                  key: "backlog",
                  text: "Backlog",
                  value: "backlog"
                },
                {
                  key: "assigned",
                  text: "Assigned",
                  value: "assigned"
                },
                {
                  key: "finalReview",
                  text: "Final Review",
                  value: "finalReview"
                },
                {
                  key: "sent",
                  text: "Sent",
                  value: "sent"
                }
              ]}
            />
          </Form.Field>
          <Form.Field>
            <label>Creator</label>
            <Select
              id="creator"
              name="creator"
              value={this.state.creator}
              placeholder="Select Creator"
              onChange={this.handleSelectChange}
              selection
              options={
                this.props.contentStaff
                  ? this.props.contentStaff.map(staff => ({
                      key: staff.id,
                      text: staff.name,
                      value: staff.id
                    }))
                  : []
              }
            />
          </Form.Field>
          <Form.Field>
            <label>Approver</label>
            <Select
              id="approver"
              name="approver"
              value={this.state.approver}
              placeholder="Select Approver"
              onChange={this.handleSelectChange}
              selection
              options={
                this.props.contentStaff
                  ? this.props.contentStaff.map(staff => ({
                      key: staff.id,
                      text: staff.name,
                      value: staff.id
                    }))
                  : []
              }
            />
          </Form.Field>
          <Form.Button type="submit" positive>
              Accept Changes
          </Form.Button>
        </Form>
      </div>
    );
  }
}
