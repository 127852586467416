import React, { Component } from "react";
import "./LinkCheckerOutput.css";
import { Table } from "semantic-ui-react";
import _ from "lodash";

export default class LinkCheckerOutput extends Component {
  state = {
    column: null,
    data: this.props.data,
    direction: null
  };

  handleSort = clickedColumn => () => {
    const { column, data, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: _.sortBy(data, [clickedColumn]),
        direction: "ascending"
      });

      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending"
    });
  };

  getValueCount = value => {
    const items = this.state.data.filter(d => d.status === value);
    return items.length;
  };

  render() {
    const { column, data, direction } = this.state;
    let unique = [...new Set(this.state.data.map(item => item.status))];
    return (
      <div className="LinkCheckerOutput">
        <div className="LinkCheckerOutput--totals">
          {unique.map(v => (
            <div key={v} className="LinkCheckerOutput--total">
              <span className="LinkCheckerOutput--total__label">{v} Status: </span>
              <span className="LinkCheckerOutput--total__output">{this.getValueCount(v)}</span>
            </div>
          ))}
        </div>
        <Table compact sortable basic>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={column === "url" ? direction : null}
                onClick={this.handleSort("url")}
              >
                URL
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "status" ? direction : null}
                onClick={this.handleSort("status")}
              >
                Status Code
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "parent" ? direction : null}
                onClick={this.handleSort("parent")}
              >
                First Found On
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(data, ({ parent, url, status }) => (
              <Table.Row key={url} negative={status !== 200}>
                <Table.Cell>{url}</Table.Cell>
                <Table.Cell>{status}</Table.Cell>
                <Table.Cell>
                  <a href={parent} target="_blank" rel="noopener noreferrer">
                    {parent}
                  </a>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}
