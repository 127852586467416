import * as actionTypes from "./actionTypes";
import axios from "../../axios-portal";

export const addMessage = (message) => {
	return {
		type: actionTypes.ADD_MESSAGE,
		message: message
	};
};

export const removeMessage = (message, timeToWait) => {
	return {
		type: actionTypes.REMOVE_MESSAGE,
		message: message,
		timeToWait: timeToWait
	};
};

export const startFetchAccountHealthOverviews = () => {
    return {
        type: actionTypes.START_FETCH_ACCOUNT_HEALTH_OVERVIEWS,
    }
}

export const fetchAccountHealthOverviewsSuccess = (overviews) => {
    return {
        type: actionTypes.FETCH_ACCOUNT_HEALTH_OVERVIEWS_SUCCESS,
        overviews: overviews
    }
}

export const fetchAccountHealthOverviewsFailure = (errors) => {
    return {
        type: actionTypes.FETCH_ACCOUNT_HEALTH_OVERVIEWS_FALURE,
        errors: errors
    }
}

export const fetchAccountHealthOverviews = () => {
	return async (dispatch, getState) => {
		let isLoading = await getState().accountHealth.overviews.isLoading;
		dispatch(startFetchAccountHealthOverviews());
		!isLoading &&
			axios
				.get(`account-health-overviews.json`)
				.then((response) => {
					return dispatch(fetchAccountHealthOverviewsSuccess(response.data));
				})
				.catch((err) => {
					dispatch(fetchAccountHealthOverviewsFailure(err));
				});
	};
}