import React, { Component } from 'react';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import Layout from './hoc/Layout/Layout';
import Auth from './containers/Auth/Auth';
import DashBoard from './containers/DashBoard/DashBoard';
import NotFound from './components/NotFound/NotFound';
import * as actions from './store/actions/auth';

import { connect } from 'react-redux';

import './App.css';
import ContentGeneration from './routes/ContentGeneration/ContentGeneration';
import Organizations from './routes/Organizations/Organizations';
import UserManagement from './routes/UserManagement/UserManagement';
import ErrorBoundary from './containers/ErrorBoundary/ErrorBoundary';
import DimmedLoader from './components/DimmedLoader/DimmedLoader';
import ClientOnboarding from './routes/ClientOnboarding/ClientOnboarding';
import AppManagement from './routes/AppManagement/AppManagement';
import Utilities from './routes/Utilities/Utilities';
import Reporting from './routes/Reporting/Reporting';
import CacheBuster from './cacheBuster';
import AccountHealth from './routes/AccountHealth/AccountHealth';
import TestingComponent from './containers/TestingComponent/TestingComponent';

class RootContainerComponent extends Component {
	async componentDidMount() {
		const originalUserToken = await localStorage.getItem('originalUserToken');
		if (originalUserToken) {
			await localStorage.removeItem('token');
			await localStorage.removeItem('originalUserToken');
		}
		await this.props.authCheckState();
		this.props.auth.groups && !this.props.auth.groups[0] && this.props.fetchGroups();
	}

	PrivateRoute = ({ component: ChildComponent, ...rest }) => {
		return (
			<Route
				{...rest}
				render={(props) => {
					if (this.props.auth.isLoading || (!this.props.auth.isAuthenticated && this.props.auth.token)) {
						if (this.props.auth.isLoading || this.props.auth.waitingOnInitialAuth) {
							return <DimmedLoader />;
						} else {
							return (
								<Redirect
									to={{ pathname: '/auth/login', state: { redirectUrl: props.location.pathname } }}
								/>
							);
						}
					} else if (this.props.auth.isLoggingOut) {
						return <DimmedLoader text="Logging Out" />;
					} else if (!this.props.auth.isAuthenticated || !this.props.auth.token) {
						return (
							<Redirect
								to={{ pathname: '/auth/login', state: { redirectUrl: props.location.pathname } }}
							/>
						);
					} else {
						return (
							<Layout {...rest} {...props}>
								{' '}
								<ChildComponent {...rest} {...props} />
							</Layout>
						);
					}
				}}
			/>
		);
	};

	render() {
		let { PrivateRoute } = this;
		return (
			<BrowserRouter>
				<Switch>
					<PrivateRoute exact path="/" component={DashBoard} />
					<PrivateRoute protected={true} path="/organizations" component={Organizations} />
					<PrivateRoute
						protected={true}
						perms="view_contentarticle"
						path="/content-generation"
						component={ContentGeneration}
					/>
					<PrivateRoute protected={true} path="/users" component={UserManagement} />
					<PrivateRoute
						protected={true}
						perms="view_websitebuild"
						path="/client-onboarding"
						component={ClientOnboarding}
					/>
					<PrivateRoute protected={true} perms="view_webapp" path="/apps" component={AppManagement} />
					<PrivateRoute protected={true} path="/utilities" component={Utilities} />
					<PrivateRoute protected={true} perms="view_monthlyreport" path="/reporting" component={Reporting} />
					<PrivateRoute protected={true} perms="view_accounthealth" path="/account-health" component={AccountHealth} />
					<Route path="/error-testing" component={ErrorBoundary} />
					<Route path="/auth" component={Auth} />
					{process.env.REACT_APP_DEVELOPMENT && <PrivateRoute path="/testing" component={TestingComponent} /> }
					<Route component={NotFound} />
				</Switch>
			</BrowserRouter>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		authCheckState: () => {
			return dispatch(actions.authCheckState());
		},
		fetchGroups: () => {
			return dispatch(actions.fetchGroups());
		}
	};
};

let RootContainer = connect(mapStateToProps, mapDispatchToProps)(RootContainerComponent);

class App extends Component {
	render() {
		console.ignoredYellowBox = [ 'Warning: componentWillMount' ];
		return (
			<CacheBuster>
				<ErrorBoundary>
					<RootContainer />
				</ErrorBoundary>
			</CacheBuster>
		);
	}
}

export default App;
