import React, { Component } from "react";
import "./ReportingDashboard.css";
import { Loader } from "semantic-ui-react";
import ReportingDashboardTable from "../../../components/ReportingDashboardTable/ReportingDashboardTable";

export default class ReportingDashboard extends Component {
  viewMonth = month => {
    this.props.history.push(
      `${this.props.match.path}/${month.year}/${month.month}`
    );
  };
  render() {
    return (
      <div className="ReportingDashboard">
        {this.props.reports[0] && this.props.planningMonths[0] ? (
          <ReportingDashboardTable
            headerText="Monthly Report Overview"
            viewMonth={this.viewMonth}
            planningMonths={this.props.planningMonths}
            reports={this.props.reports}
          />
        ) : (
          <Loader active />
        )}
      </div>
    );
  }
}
