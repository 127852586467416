import React from 'react';
import './GoogleReviewRequest.css';
import { Button, Icon } from 'semantic-ui-react';

const GoogleReviewRequest = ({ useIframe, buttonText, url }) => {
	const reviewUrl = url
		? url
		: 'https://www.google.com/search?q=321+web+marketing&oq=321+web+marketing&aqs=chrome.0.0l2j69i60l3j69i65.3528j0j7&sourceid=chrome&ie=UTF-8#lrd=0x89b64f3b21363b43:0x80f89cbf77280477,3,,,';
	return (
		<div className="GoogleReviewRequest">
			{useIframe ? (
				<div className="GoogleReviewRequest--iFrame">
					<iframe title="GoogleReviewRequestIframe" src={reviewUrl} />
				</div>
			) : (
				<div className="GoogleReviewRequest--link">
					<a href={reviewUrl} target="_blank" rel="noopener noreferrer">
						<Button color="google plus" size="large" icon labelPosition="right">
							{buttonText ? buttonText : 'Please Leave us a Google Review'} <Icon name="google" />
						</Button>
					</a>
				</div>
			)}
		</div>
	);
};

export default GoogleReviewRequest;
