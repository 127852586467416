import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  websiteBuilds: {
    builds: [],
    isLoading: false,
    isLoaded: false,
    isAdding: false
  },
  archivedBuilds: {
    builds: [],
    isLoading: false,
    isLoaded: false,
    isAdding: false
  },
  errors: null,
  messages: []
};

const addMessage = (state, action) => {
  return updateObject(state, {
    messages: state.messages.concat(action.message)
  });
};

const removeMessage = (state, action) => {
  let messagesList = state.messages.slice();
  messagesList.splice(0, 1);
  return updateObject(state, { messages: messagesList });
};

const startWebsiteBuildsFetch = (state, action) => {
  return updateObject(state, {
    websiteBuilds: {
      builds: state.websiteBuilds.builds,
      isLoading: true,
      isLoaded: false,
      isAdding: false
    }
  });
};

const setWebsiteBuilds = (state, action) => {
  return updateObject(state, {
    websiteBuilds: {
      builds: action.websiteBuilds,
      isLoading: false,
      isLoaded: true,
      isAdding: false
    }
  });
};
const startArchivedWebsiteBuildsFetch = (state, action) => {
  return updateObject(state, {
    archivedBuilds: {
      ...state.archivedBuilds,
      isLoading: true,
      isLoaded: false,
      isAdding: false
    }
  });
};

const setArchivedWebsiteBuilds = (state, action) => {
  return updateObject(state, {
    archivedBuilds: {
      builds: action.websiteBuilds,
      isLoading: false,
      isLoaded: true,
      isAdding: false
    }
  });
};

const startAddingWebsiteBuild = (state, action) => {
  return updateObject(state, {
    websiteBuilds: {
      builds: state.websiteBuilds.builds,
      isLoading: false,
      isLoaded: state.websiteBuilds.isLoaded,
      isAdding: true
    }
  });
};
const addWebsiteBuildSuccess = (state, action) => {
  return updateObject(state, {
    websiteBuilds: {
      builds: state.websiteBuilds.builds.concat(action.websiteBuild),
      isLoading: false,
      isLoaded: state.websiteBuilds.isLoaded,
      isAdding: false
    }
  });
};
const updateWebsiteBuildSuccess = (state, action) => {
  let websiteBuildIndex = state.websiteBuilds.builds.findIndex(
    x => x.id === action.id
  );
  return updateObject(state, {
    websiteBuilds: {
      isLoading: false,
      isLoaded: state.websiteBuilds.isLoaded,
      isAdding: false,
      builds: state.websiteBuilds.builds.map((builds, i) =>
        i === websiteBuildIndex ? action.websiteBuild : builds
      )
    }
  });
};

const deleteWebsiteBuildSuccess = (state, action) => {
  const websiteBuildIndex = state.websiteBuilds.builds.findIndex(
    x => x.id === action.id
  );
  const builds = state.websiteBuilds.builds.slice();
  builds.splice(websiteBuildIndex, 1);
  return updateObject(state, {
    websiteBuilds: {
      builds: builds,
      isLoading: false,
      isLoaded: state.websiteBuilds.isLoaded,
      isAdding: false
    }
  });
};
const websiteBuildsFailure = (state, action) => {
  return updateObject(state, {
    websiteBuilds: {
      builds: state.websiteBuilds.builds,
      isLoading: false,
      isLoaded: state.websiteBuilds.isLoaded,
      isAdding: false
    },
    errors: action.errors
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_MESSAGE:
      return addMessage(state, action);
    case actionTypes.REMOVE_MESSAGE:
      return removeMessage(state, action);
    case actionTypes.START_WEBSITE_BUILDS_FETCH:
      return startWebsiteBuildsFetch(state, action);
    case actionTypes.SET_WEBSITE_BUILDS:
      return setWebsiteBuilds(state, action);
    case actionTypes.START_ARCHIVED_WEBSITE_BUILDS_FETCH:
      return startArchivedWebsiteBuildsFetch(state, action);
    case actionTypes.SET_ARCHIVED_WEBSITE_BUILDS:
      return setArchivedWebsiteBuilds(state, action);
    case actionTypes.START_ADDING_WEBSITE_BUILD:
      return startAddingWebsiteBuild(state, action);
    case actionTypes.ADD_WEBSITE_BUILD_SUCCESS:
      return addWebsiteBuildSuccess(state, action);
    case actionTypes.UPDATE_WEBSITE_BUILD_SUCCESS:
      return updateWebsiteBuildSuccess(state, action);
    case actionTypes.DELETE_WEBSITE_BUILD_SUCCESS:
      return deleteWebsiteBuildSuccess(state, action);
    case actionTypes.WEBSITE_BUILDS_FAILURE:
      return websiteBuildsFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
