import React from 'react';
import "./StatusPill.css";

const StatusPill = props => {
    return (
        <span style={{backgroundColor: props.color, color: props.textColor ? props.textColor : '#fff'}} className="StatusPill">
            {props.content}
        </span>
    );
};

export default StatusPill;