import React, { Component } from "react";
import "./AppView.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { appManagement } from "../../../store/actions";
import {
  Loader,
  Header,
  Icon,
  Label,
  Button,
  List,
  Dimmer
} from "semantic-ui-react";
import moment from "moment";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";

class AppView extends Component {
  state = {
    app:
      this.props.apps[0] &&
      this.props.apps.find(app => app.uuid === this.props.match.params.appID),
    fetchingStatus: false,
    fetchingDataBackups: false,
    fetchingCodeBackups: false,
    preparingDownload: false
  };

  componentDidMount() {
    if (this.state.app && this.state.fetchingStatus === false) {
      this.props.checkAppStatus(this.state.app.id);
      this.setState({ fetchingStatus: true });
    }
    if (this.state.app && !this.state.fetchingDataBackups) {
      this.props.fetchDataBackups(this.state.app.id);
      this.setState({ fetchingDataBackups: true });
    }
    if (this.state.app && !this.state.fetchingCodeBackups) {
      this.props.fetchCodeBackups(this.state.app.id);
      this.setState({ fetchingCodeBackups: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.apps[0] && this.props.apps !== prevProps.apps) {
      const app = this.props.apps.find(
        app => app.uuid === this.props.match.params.appID
      );
      this.setState({ app: app });
    }
    if (
      this.state.app !== prevState.app &&
      this.state.fetchingStatus === false
    ) {
      this.props.checkAppStatus(this.state.app.id);
      this.setState({ fetchingStatus: true });
    }
    if (
      this.state.app &&
      this.state.app !== prevState.app &&
      !this.state.fetchingDataBackups
    ) {
      this.props.fetchDataBackups(this.state.app.id);
      this.setState({ fetchingDataBackups: true });
    }
    if (
      this.state.app &&
      this.state.app !== prevState.app &&
      !this.state.fetchingCodeBackups
    ) {
      this.props.fetchCodeBackups(this.state.app.id);
      this.setState({ fetchingCodeBackups: true });
    }
    if (this.state.app && this.state.app !== prevState.app) {
      if (
        prevState.app &&
        this.state.app.active !== prevState.app.active
      ) {
        this.props.fetchDataBackups(this.state.app.id);
        this.setState({ fetchingDataBackups: true });
        this.props.fetchCodeBackups(this.state.app.id);
        this.setState({ fetchingCodeBackups: true });
        this.props.checkAppStatus(this.state.app.id);
        this.setState({ fetchingStatus: true });
      }
    }
    if (this.state.app && this.state.app !== prevState.app) {
      if (this.state.app.scheduled_for_deletion) {
        setTimeout(() => {
          this.props.history.push("/apps");
        }, 50);
      }
    }
  }

  formatTimeZone = date => {
    let formattedDate = date.split("D").join(" ");
    formattedDate = formattedDate.split("Z").join("");
    return formattedDate;
  };

  renderStatus = status => {
    const string = status === true ? "Online" : "Down";
    return string;
  };

  handleBackup = async () => {
    this.props.backupApp(this.state.app.id);
  };

  handleDelete = () => {
    this.props.deleteApplication(this.state.app.id);
  };

  handleDownloadLink = async (key, backupType) => {
    await this.setState({ preparingDownload: true });
    await this.props.getS3Download(this.state.app.id, key, backupType);
    !this.props.errors && this.setState({ preparingDownload: false });
  };

  toggleActive = () => {
    this.props.updateApp(this.state.app.id, {
      active: this.state.app.active ? false : true
    });
  };

  render() {
    const app = this.state.app;
    return (
      <div className="AppView">
        <div className="AppView--headerActions">
          <Link to="/apps">
            <Button size="tiny" basic color="teal">
              <Icon name="arrow left" />
              Back to Apps
            </Button>
          </Link>
        </div>
        {this.props.apps[0] ? (
          app ? (
            <section className="AppView--content">
              {this.state.app.deleteInProgress && (
                <Dimmer active inverted>
                  <Loader size="large" active inverted>
                    Deleting Application
                  </Loader>
                </Dimmer>
              )}
              {(app.appStarting || app.appStopping) && (
                <Dimmer active inverted>
                  <Loader size="medium" active inverted>
                    {app.appStarting ? "Starting" : "Stopping"} Application
                  </Loader>
                </Dimmer>
              )}
              <div className="AppView--content__header">
                <div className="AppView--content__header--left">
                  <Header as="h2">
                    <Header.Content>
                      {app.site_name}{" "}
                      <Label
                        size="tiny"
                        basic
                        color={`${app.active ? "green" : "red"}`}
                      >
                        {app.active ? "Active" : "Inactive"}
                      </Label>
                    </Header.Content>
                    <Header.Subheader>
                      {" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="AppView--siteLink"
                        href={app.staging_url}
                      >
                        {" "}
                        {app.staging_url}
                        <Icon name="external alternate" />
                      </a>
                    </Header.Subheader>
                  </Header>
                </div>
                <div className="AppView--content__header--right">
                  {app.active ? (
                    <ConfirmationModal
                      handleConfirm={() => this.toggleActive()}
                      headerText="Deactivate Application?"
                      buttonText="Deactivate App"
                      color="red"
                      basic
                      buttonSize="tiny"
                      bodyText="Are you sure you want to deactivate this App?"
                    />
                  ) : (
                    <Button
                      positive
                      icon
                      basic
                      size="tiny"
                      onClick={() => this.toggleActive()}
                    >
                      Activate App
                    </Button>
                  )}
                  {app.websiteStatus ? (
                    <ConfirmationModal
                      handleConfirm={() => this.props.stopApp(app.id)}
                      headerText="Stop Application?"
                      buttonIcon="power off"
                      buttonText="Stop App"
                      color="red"
                      buttonSize="tiny"
                      bodyText="Are you sure you want to stop this App?"
                    />
                  ) : (
                    <Button
                      positive
                      icon
                      size="tiny"
                      onClick={() => this.props.startApp(app.id)}
                    >
                      Start App <Icon name="power" />
                    </Button>
                  )}
                  <Button
                    loading={app.backupInProgress}
                    disabled={app.backupInProgress}
                    icon
                    size="tiny"
                    color="blue"
                    onClick={() => this.handleBackup()}
                  >
                    Manual Backup <Icon name="download" />
                  </Button>
                  <ConfirmationModal
                    handleConfirm={() => this.handleDelete()}
                    headerText="Delete Application?"
                    buttonIcon="trash alternate outline"
                    buttonText="Delete App"
                    color="red"
                    buttonSize="tiny"
                    bodyText="Are you sure you want to do this? This is irreversible"
                  />
                </div>
              </div>
              <div className="AppView--content__body">
                <div className="AppView--content__section AppView--content__body--left">
                  <div className="AppView--status">
                    <Header as="h4">App Status</Header>
                    <div className="AppView--status__items">
                      <div className="AppView--status__item">
                        <span className="AppView--status__item__label">
                          Wordpress:{" "}
                        </span>
                        <span className="AppView--status__item__status">
                          {app.wordpressStatus !== undefined &&
                          app.statusCheckInProgress === false ? (
                            <React.Fragment>
                              {this.renderStatus(app.wordpressStatus)}
                              {app.wordpressStatus === true ? (
                                <span className="AppView--statusIndicator up" />
                              ) : (
                                <span className="AppView--statusIndicator down" />
                              )}
                            </React.Fragment>
                          ) : (
                            <Loader size="mini" active inline />
                          )}
                        </span>
                      </div>
                      <div className="AppView--status__item">
                        <span className="AppView--status__item__label">
                          Website:{" "}
                        </span>
                        <span className="AppView--status__item__status">
                          {app.websiteStatus !== undefined &&
                          app.statusCheckInProgress === false ? (
                            <React.Fragment>
                              {this.renderStatus(app.websiteStatus)}
                              {app.websiteStatus === true ? (
                                <span className="AppView--statusIndicator up" />
                              ) : (
                                <span className="AppView--statusIndicator down" />
                              )}
                            </React.Fragment>
                          ) : (
                            <Loader size="mini" active inline />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="AppView--content__section AppView--content__body--middle">
                  <div className="AppView-backups">
                    <Header as="h4">Database Backups</Header>
                    {app.backupInProgress && (
                      <span className="AppView-backups--inProgress">
                        <Icon color="blue" loading name="spinner" /> Database
                        backup in progress...
                      </span>
                    )}
                    {app.dataBackups && app.fetchingDataBackups === false ? (
                      app.dataBackups[0] ? (
                        <div className="AppView-dataBackups">
                          <List divided relaxed>
                            {app.dataBackups.map((backup, i) => (
                              <List.Item
                                key={i}
                                className="AppView-backups__backupItem"
                              >
                                <List.Icon
                                  name="database"
                                  size="small"
                                  verticalAlign="middle"
                                />
                                <List.Content
                                  onClick={() =>
                                    this.handleDownloadLink(
                                      backup.Key,
                                      "database"
                                    )
                                  }
                                  className="AppView-backups__backupItem--content"
                                >
                                  {backup.LastModified
                                    ? moment(
                                        this.formatTimeZone(backup.LastModified)
                                      ).format("MM-DD-YY HH:MM")
                                    : backup}
                                  {backup.downloadLink && (
                                    <a
                                      href={backup.downloadLink}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="AppView-backups--downloadLink"
                                    >
                                      <Icon name="download" />
                                    </a>
                                  )}
                                </List.Content>
                              </List.Item>
                            ))}
                          </List>
                        </div>
                      ) : (
                        app.dataBackups
                      )
                    ) : (
                      <Loader size="small" active inline />
                    )}
                  </div>
                </div>
                <div className="AppView--content__section AppView--content__body--right">
                  <div className="AppView-backups">
                    <Header as="h4">Codebase Backups</Header>
                    {app.backupInProgress && (
                      <span className="AppView-backups--inProgress">
                        <Icon color="blue" loading name="spinner" /> Codebase
                        backup in progress...
                      </span>
                    )}
                    {app.codeBackups && app.fetchingCodeBackups === false ? (
                      app.codeBackups[0] ? (
                        <div className="AppView-codeBackups">
                          <List divided relaxed>
                            {app.codeBackups.map((backup, i) => (
                              <List.Item
                                key={i}
                                className="AppView-backups__backupItem"
                              >
                                <List.Icon
                                  name="code"
                                  size="small"
                                  verticalAlign="middle"
                                />
                                <List.Content
                                  onClick={() =>
                                    this.handleDownloadLink(backup.Key, "code")
                                  }
                                  className="AppView-backups__backupItem--content"
                                >
                                  {/* {backup.path ? backup.path : backup} */}
                                  {backup.LastModified
                                    ? moment(
                                        this.formatTimeZone(backup.LastModified)
                                      ).format("MM-DD-YY HH:MM")
                                    : backup}
                                  {backup.downloadLink && (
                                    <a
                                      href={backup.downloadLink}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="AppView-backups--downloadLink"
                                    >
                                      <Icon name="download" />
                                    </a>
                                  )}
                                </List.Content>
                              </List.Item>
                            ))}
                          </List>
                        </div>
                      ) : (
                        app.codeBackups
                      )
                    ) : (
                      <Loader size="small" active inline />
                    )}
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <Header>App Not Found</Header>
          )
        ) : !this.props.isLoading && this.props.isLoaded ? (
          <Header>App Not Found</Header>
        ) : (
          <Loader active inline="centered" />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    apps: state.appManagement.applications.apps,
    isLoading: state.appManagement.applications.isLoading,
    isLoaded: state.appManagement.applications.isLoaded,
    errors: state.appManagement.applications.errors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchApps: () => dispatch(appManagement.fetchApps()),
    updateApp: (id, formData) =>
      dispatch(appManagement.updateApp(id, formData)),
    checkAppStatus: id => dispatch(appManagement.checkAppStatus(id)),
    fetchDataBackups: id => dispatch(appManagement.fetchDataBackups(id)),
    fetchCodeBackups: id => dispatch(appManagement.fetchCodeBackups(id)),
    backupApp: id => dispatch(appManagement.backupApp(id)),
    deleteApplication: id => dispatch(appManagement.deleteApplication(id)),
    getS3Download: (id, key, backupType) =>
      dispatch(appManagement.getS3Download(id, key, backupType)),
    stopApp: id => dispatch(appManagement.stopApp(id)),
    startApp: id => dispatch(appManagement.startApp(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppView);
