import React, { Component } from 'react';
import './PresentationCreatorComponentField.css';
import { toPascalCase, toTitleCase } from '../../../../utility';
import { Form } from 'semantic-ui-react';

export default class PresentationCreatorComponentField extends Component {
	handleIsValid = (correspondingField) => {
		let currentValue = correspondingField && correspondingField.data;

		if (currentValue && this.props.field.required) {
			if (currentValue === null || currentValue === '') {
				return true;
			}
		}
		return false;
	};

	shouldRenderPlaceholder = (field) => {
		if (!field.additionalAttributes) {
			return true;
		} else if (field.additionalAttributes.placeholder) {
			return false;
		} else {
			return true;
		}
	};

	render() {
		const field = this.props.field;
		const componentOptions = {
			Input: Form.Input,
			Select: Form.Select,
			Radio: Form.Radio,
			TextArea: Form.TextArea,
			Checkbox: Form.Checkbox
		};
		const componentData = this.props.componentData;
		const TagName = componentOptions[toPascalCase(field.formElement)];
		const correspondingField = componentData.fields.find((dataField) => dataField.name === field.attributeName);

		return (
			<Form.Field className="PresentationCreatorComponentField">
				<label>{field.fieldName}</label>
				<TagName
					{...field.additionalAttributes}
					name={field.attributeName}
					error={this.handleIsValid(correspondingField)}
					value={
						field.inputType !== 'checkbox' && correspondingField ? correspondingField.data !== null ? (
							correspondingField.data
						) : (
							''
						) : field.initialValue ? (
							field.initialValue
						) : (
							''
						)
					}
					checked={
						field.inputType === 'checkbox' && correspondingField ? correspondingField.data !== null ? (
							correspondingField.data
						) : (
							false
						) : field.initialValue ? (
							field.initialValue
						) : (
							false
						)
					}
					onChange={
						field.choices ? (
							this.props.handleSelectChange
						) : (
							(e, results) => this.props.handleChange(e, results)
						)
					}
					multiple={field.multiple ? true : false}
					clearable={field.clearable ? field.clearable : undefined}
					selection={field.choices === undefined && null}
					required={field.required}
					type={field.inputType !== undefined ? field.inputType : 'text'}
					placeholder={
						this.shouldRenderPlaceholder(field) &&
						`${field.choices ? 'Select' : 'Input'} ${field.fieldName}`
					}
					options={
						field.choices && !field.explicitChoices ? (
							field.choices.map((choice) => ({
								key: choice,
								text: toTitleCase(choice),
								value: choice
							}))
						) : (
							field.choices
						)
					}
				/>
				{field.helperText && (
					<div className="PresentationCreatorComponentField--helperText">
						<span>{field.helperText}</span>
					</div>
				)}
			</Form.Field>
		);
	}
}
