import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  notifications: [],
  systemNotifications: {
    data: [],
		isLoading: false,
		isLoaded: false,
		errors: {}
  },
  isLoading: false,
  errors: {},
  serviceWorkerUpdated: false,
};

const setNotifications = (state, action) => {
  return updateObject(state, {
    notifications: action.notifications,
    isLoading: false,
    errors: null
  });
};

const updateServiceWorker = (state, action) => {
  return updateObject(state, {
    updateServiceWorker: action.serviceWorkerUpdated,
    notifications: state.notifications.concat(
      {
        action: 'Dismiss',
        dismissAfter: 8000,
        heading: "New Update",
        message: "There has been an update to the portal! Please refresh your browser to see the new changes."
      }
    )
  })
}

export const dismissNotification = (state, action) => {
  let notificationList = state.notifications.slice();
  notificationList.splice(action.index, 1);
  return updateObject(state, { notifications: notificationList });
}


const fetchNotificationsFailed = (state, action) => {
  return updateObject(state, { errors: action.errors });
};

const addNotificationSuccess = (state, action) => {
  return updateObject(state, { notifications: state.notifications.concat(action.notification) });
};

const updateNotificationSuccess = (state, action) => {
  let notificationList = state.notifications.slice();

  let notificationToUpdate = notificationList[action.index];
  notificationToUpdate.text = action.notification.text;
  notificationList.splice(action.index, 1, notificationToUpdate);
  return updateObject(state, { notifications: notificationList });
};

const deleteNotificationSuccess = (state, action) => {
  let notificationList = state.notifications.slice();
  notificationList.splice(action.index, 1);
  return updateObject(state, { notifications: notificationList });
};

const notificationFailure = (state, action) => {
  return updateObject(state, { errors: action.errors });
};

const startFetchSystemNotifications = (state, action) => {
  return updateObject(state, {
          systemNotifications: {
              ...state.systemNotifications,
              isLoading: true,
              isLoaded: false,
              errors: null,
          }
      })
}
const fetchSystemNotificationsSuccess = (state, action) => {
  return updateObject(state, {
          systemNotifications: {
              ...state.systemNotifications,
              data: state.systemNotifications.data.concat(action.notifications),
              isLoading: false,
              isLoaded: true,
              errors: null,
          }
      })
}
const fetchSystemNotificationsFailure = (state, action) => {
  return updateObject(state, {
          systemNotifications: {
              ...state.systemNotifications,
              isLoading: false,
              isLoaded: false,
              errors: action.errors,
          }
      })
}


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_SERVICE_WORKER:
      return updateServiceWorker(state, action);
    case actionTypes.DISMISS_NOTIFICATION:
      return dismissNotification(state, action);
    case actionTypes.ADD_NOTIFICATION_SUCCESS:
      return addNotificationSuccess(state, action);
    case actionTypes.DELETE_NOTIFICATION_SUCCESS:
      return deleteNotificationSuccess(state, action);
    case actionTypes.SET_NOTIFICATIONS:
      return setNotifications(state, action);
    case actionTypes.UPDATE_NOTIFICATION_SUCCESS:
      return updateNotificationSuccess(state, action);
    case actionTypes.FETCH_NOTIFICATIONS_FAILED:
      return fetchNotificationsFailed(state, action);
    case actionTypes.NOTIFICATION_FAILURE:
      return notificationFailure(state, action);
    case actionTypes.START_FETCH_SYSTEM_NOTIFICATIONS:
      return startFetchSystemNotifications(state, action);
    case actionTypes.FETCH_SYSTEM_NOTIFICATIONS_SUCCESS:
      return fetchSystemNotificationsSuccess(state, action);
    case actionTypes.FETCH_SYSTEM_NOTIFICATIONS_FAILURE:
      return fetchSystemNotificationsFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
