export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

export const STATES = [
  {
    key: 1,
    text: "Virginia",
    value: "VA"
  },
  {
    key: 2,
    text: "Maryland",
    value: "MD"
  },
  {
    key: 3,
    text: "DC",
    value: "DC"
  },
  {
    key: 4,
    text: "California",
    value: "CA"
  }
];


export const getRandomInt = (max) => {
  return Math.floor(Math.random() * Math.floor(max));
}