import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import "semantic-ui-css/semantic.min.css";
import * as Sentry from "@sentry/browser";
import store from "./store";
import { Provider } from "react-redux";
import {notifications} from "./store/actions"
import packageJson from '../package.json';
global.appVersion = packageJson.version;

if (!process.env.REACT_APP_DEVELOPMENT && process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN
  });
}


const onUpdate = () => {
  store.dispatch(notifications.updateServiceWorker());
};


export default ReactDOM.render(
 ( <Provider store={store}>
    <App />
  </Provider>),
  document.getElementById('root') || document.createElement('root')
);
registerServiceWorker(onUpdate);
