import React from 'react'
import "./UserPasswordManagement.css";
import PasswordChangeForm from '../../PasswordChangeForm/PasswordChangeForm';
import DimmedLoader from '../../DimmedLoader/DimmedLoader';
import { Header } from 'semantic-ui-react';

export const UserPasswordManagement = (props) => {
    let user = props.selectedUser

    return (
        <div className="UserPasswordManagement">
            {user.updatingPassword && <DimmedLoader text="Updating Password" /> }
            <section className="UserPasswordManagement-form">
                <Header as="h5">
                    Update User Password
                    <Header.Subheader>Please use this only if the client cannot update the password themselves.</Header.Subheader>
                </Header>
                <PasswordChangeForm updatingPassword={user.updatingPassword} userID={user.id} />
            </section>
        </div>
    )
}
