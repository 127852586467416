import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  users: [],
  internalUsers: [],
  invitations: [],
  accountsData: {
    accounts: [],
    isLoading: false,
    isLoaded: false,
  },
  isLoading: false,
  isLoaded: false,
  isLoadingInvitations: false,
  invitationsLoaded: false,
  errors: {},
  invitingUser: false,
  didInviteUser: false,
  didUpdateRoles: false,
  isDeletingUser: false,
  didDeleteUser: false,
  messages: [],
};

const addMessage = (state, action) => {
  return updateObject(state, {
    messages: state.messages.concat(action.message),
  });
};

const removeMessage = (state, action) => {
  let messagesList = state.messages.slice();
  messagesList.splice(0, 1);
  return updateObject(state, { messages: messagesList });
};

const startUsersFetch = (state, action) => {
  return updateObject(state, {
    isLoading: true,
    isLoaded: false,
  });
};

const setUsers = (state, action) => {
  if (action.internal) {
    return updateObject(state, {
      internalUsers: action.users,
      isLoading: false,
      isLoaded: true,
      errors: null,
    });
  } else {
    return updateObject(state, {
      users: action.users,
      isLoading: false,
      isLoaded: true,
      errors: null,
    });
  }
};

const fetchUsersFailed = (state, action) => {
  return updateObject(state, {
    errors: action.errors,
    isLoaded: false,
    isLoading: false,
  });
};

const startInviteUser = (state, action) => {
  return updateObject(state, {
    invitingUser: true,
    didInviteUser: false,
  });
};

const inviteUserSuccess = (state, action) => {
  return updateObject(state, {
    users: action.created ? state.users.concat(action.user) : state.users,
    didInviteUser: true,
    invitingUser: false,
    errors: null,
  });
};

const updateUserSuccess = (state, action) => {
  let userIndex = state.users.findIndex((x) => x.id === action.id);
  return updateObject(state, {
    users: state.users.map((user, i) => (i === userIndex ? action.user : user)),
    errors: null,
  });
};

const updateCurrentUserSuccess = (state, action) => {
  let userIndex = state.users.findIndex((x) => x.id === action.id);
  return updateObject(state, {
    users: state.users.map((user, i) => (i === userIndex ? action.user : user)),
    errors: null,
  });
};

const updateUserPreferencesSuccess = (state, action) => {
  let userIndex = action.userIndex;
  return updateObject(state, {
    users: state.users.map((user, i) =>
      i === userIndex
        ? {
            ...user,
            user_preferences: action.userPreferences,
          }
        : user
    ),
    errors: null,
  });
};

const deleteUserInProgress = (state, action) => {
	return updateObject(state, {
	  isDeletingUser: true,
	  didDeleteUser: false
	});
  };

const deleteUserSuccess = (state, action) => {
  return updateObject(state, {
	users: state.users.filter((user) => user.id !== action.userId),
	isDeletingUser: false,
	didDeleteUser: true
  });
};

const userFailure = (state, action) => {
  return updateObject(state, { errors: action.errors, invitingUser: false });
};

const startInvitationsFetch = (state, action) => {
  return updateObject(state, {
    isLoadingInvitations: true,
    invitationsLoaded: false,
  });
};

const setInvitations = (state, action) => {
  return updateObject(state, {
    invitations: action.invitations,
    isLoadingInvitations: false,
    invitationsLoaded: true,
    errors: null,
  });
};

const startFetchAccounts = (state, action) => {
  return updateObject(state, {
    accountData: {
      ...state.accountData,
      isLoading: true,
      isLoaded: false,
    },
    errors: null,
  });
};
const setAccounts = (state, action) => {
  return updateObject(state, {
    accountData: {
      ...state.accountData,
      accounts: action.accounts,
      isLoading: false,
      isLoaded: true,
    },
    errors: null,
  });
};
const fetchAccountsFailure = (state, action) => {
  return updateObject(state, {
    accountData: {
      ...state.accountData,
      isLoading: false,
      isLoaded: false,
    },
    errors: action.errors,
  });
};
const addAccountSuccess = (state, action) => {
  return updateObject(state, {
    users: state.users.map((user, i) =>
      i === action.userIndex
        ? {
            ...user,
            org_roles: user.org_roles.concat(action.account),
          }
        : user
    ),
    didUpdateRoles: true,
    errors: null,
  });
};
const addAccountFailure = (state, action) => {
  return updateObject(state, {
    accountData: {
      ...state.accountData,
    },
    errors: action.errors,
    didUpdateRoles: false,
  });
};
const startUpdateAccount = (state, action) => {
  return updateObject(state, {
    users: state.users.map((user, i) =>
      i === action.userIndex
        ? {
            ...user,
            org_roles: user.org_roles.map((role, index) =>
              index === action.accountIndex
                ? {
                    ...action.account,
                    isUpdating: true,
                  }
                : role
            ),
          }
        : user
    ),
    didUpdateRoles: false,
    errors: null,
  });
};
const updateAccountSuccess = (state, action) => {
  return updateObject(state, {
    users: state.users.map((user, i) =>
      i === action.userIndex
        ? {
            ...user,
            org_roles: user.org_roles.map((role, index) =>
              index === action.accountIndex
                ? {
                    ...action.account,
                    isUpdating: false,
                  }
                : role
            ),
          }
        : user
    ),
    didUpdateRoles: true,
    errors: null,
  });
};
const updateAccountFailure = (state, action) => {
  return updateObject(state, {
    accountData: {
      ...state.accountData,
    },
    errors: action.errors,
  });
};
const deleteAccountSuccess = (state, action) => {
  let accountIndex = state.accountData.accounts.findIndex(
    (x) => x.id === action.id
  );
  const accounts = state.accountData.accounts.slice();
  accounts.splice(accountIndex, 1);
  return updateObject(state, {
    accountData: {
      ...state.accountData,
      accounts: accounts,
    },
    errors: null,
  });
};
const deleteAccountFailure = (state, action) => {
  return updateObject(state, {
    accountData: {
      ...state.accountData,
    },
    errors: action.errors,
  });
};

// User password change
// NOTE: this should be removed eventually

const startUserPasswordChange = (state, action) => {
  return updateObject(state, {
    users: state.users.map((user, i) =>
      i === action.userIndex
        ? {
            ...user,
            updatingPassword: true,
          }
        : user
    ),
    errors: null,
  });
};
const userPasswordChangeSuccess = (state, action) => {
  return updateObject(state, {
    users: state.users.map((user, i) =>
      i === action.userIndex
        ? {
            ...user,
            updatingPassword: false,
          }
        : user
    ),
    errors: null,
  });
};
const userPasswordChangeFailure = (state, action) => {
  return updateObject(state, {
    users: state.users.map((user, i) =>
      i === action.userIndex
        ? {
            ...user,
            updatingPassword: false,
          }
        : user
    ),
    errors: action.errors,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_MESSAGE:
      return addMessage(state, action);
    case actionTypes.REMOVE_MESSAGE:
      return removeMessage(state, action);
    case actionTypes.START_INVITE_USER:
      return startInviteUser(state, action);
    case actionTypes.INVITE_USER_SUCCESS:
      return inviteUserSuccess(state, action);
    case actionTypes.START_USERS_FETCH:
	  return startUsersFetch(state, action);
	case actionTypes.DELETE_USER_IN_PROGRESS:
		return deleteUserInProgress(state, action);
    case actionTypes.DELETE_USER_SUCCESS:
      return deleteUserSuccess(state, action);
    case actionTypes.SET_USERS:
      return setUsers(state, action);
    case actionTypes.UPDATE_USER_SUCCESS:
      return updateUserSuccess(state, action);
    case actionTypes.UPDATE_CURRENT_USER_SUCCESS:
      return updateCurrentUserSuccess(state, action);
    case actionTypes.UPDATE_USER_PREFERENCES_SUCCESS:
      return updateUserPreferencesSuccess(state, action);
    case actionTypes.FETCH_USERS_FAILED:
      return fetchUsersFailed(state, action);
    case actionTypes.USER_FAILURE:
      return userFailure(state, action);
    case actionTypes.START_INVITATIONS_FETCH:
      return startInvitationsFetch(state, action);
    case actionTypes.SET_INVITATIONS:
      return setInvitations(state, action);
    case actionTypes.START_FETCH_ACCOUNTS:
      return startFetchAccounts(state, action);
    case actionTypes.SET_ACCOUNTS:
      return setAccounts(state, action);
    case actionTypes.FETCH_ACCOUNTS_FAILURE:
      return fetchAccountsFailure(state, action);
    case actionTypes.ADD_ACCOUNT_SUCCESS:
      return addAccountSuccess(state, action);
    case actionTypes.ADD_ACCOUNT_FAILURE:
      return addAccountFailure(state, action);
    case actionTypes.START_UPDATE_ACCOUNT:
      return startUpdateAccount(state, action);
    case actionTypes.UPDATE_ACCOUNT_SUCCESS:
      return updateAccountSuccess(state, action);
    case actionTypes.UPDATE_ACCOUNT_FAILRE:
      return updateAccountFailure(state, action);
    case actionTypes.DELETE_ACCOUNT_SUCCESS:
      return deleteAccountSuccess(state, action);
    case actionTypes.DELETE_ACCOUNT_FAILURE:
      return deleteAccountFailure(state, action);
    case actionTypes.START_USER_PASSWORD_CHANGE:
      return startUserPasswordChange(state, action);
    case actionTypes.USER_PASSWORD_CHANGE_SUCCESS:
      return userPasswordChangeSuccess(state, action);
    case actionTypes.USER_PASSWORD_CHANGE_FAILURE:
      return userPasswordChangeFailure(state, action);
    default:
      return state;
  }
};

export default reducer;
