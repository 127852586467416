import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";
import { contentGeneration } from "../../store/actions";
import { connect } from "react-redux";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import "./PlanningMonthForm.css";
import ErrorHandler from "../ErrorHandler/ErrorHandler";
/*eslint eqeqeq: "off"*/

const initialState = {
  year: "",
  month: "",
  milestoneOne: "",
  milestoneTwo: "",
  editorDueDateOffset: "14"
};

class PlanningMonthForm extends Component {
  state = {
    form: initialState,
    isDateValid: false,
    validationError: null
  };

  componentDidMount() {
    this.props.planningMonths && !this.props.planningMonths[0] && this.props.fetchPlanningMonths();
  }

  routeToNewMonth = () =>
    this.props.history.push(
      `/content-generation/articles/${this.state.form.year}/${
        this.state.form.month
      }`
    );

  handleSubmit = async () => {
    await this.validateDate(this.state.form.month, this.state.form.year);
    !this.state.validationErrors &&
      (await this.props.addPlanningMonth(this.state.form));
    return this.routeToNewMonth();
  };

  async handleChange(e) {
    await this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  }

  validateDate = (month, year) => {
    const planningYearMonths = this.props.planningMonths;
    planningYearMonths.map(pm => {
      if (pm.month == month && pm.year == year) {
        return this.setState({
          isDateValid: false,
          validationError: "That planning month already exists"
        });
      } else {
        return null;
      }
    });
  };

  handleSelectChange = (event, { name, value }) => {
    this.setState({ form: { ...this.state.form, [name]: value } });
  };

  render() {
    return (
      <Form size="small" onSubmit={() => this.handleSubmit()}>
        {this.props.isAddingMonth && (
          <div className="PlanningMonthFormAction">
            <ProgressBar />
          </div>
        )}
        {this.props.errors && <ErrorHandler errors={this.props.errors} />}
        <Form.Group>
          <Form.Select
            required
            fluid
            name="month"
            width={8}
            label="Month"
            options={moment.months().map(month => ({
              key: moment()
                .month(month)
                .format("M"),
              text: month,
              value: moment()
                .month(month)
                .format("M")
            }))}
            placeholder="Select Month"
            onChange={this.handleSelectChange}
            value={this.state.form.month}
          />
          <Form.Input
            required
            fluid
            type="number"
            name="year"
            min={moment()
              .subtract(1, "years")
              .year()}
            max={moment()
              .add(5, "years")
              .year()}
            pattern="[0-9]*"
            width={8}
            label="Year"
            placeholder="Year"
            onChange={e => this.handleChange(e)}
            value={this.state.form.year}
          />
        </Form.Group>
        <Form.Group>
          <Form.Field width={5}>
            <DateInput
              fluid
              closable
              name="milestoneOne"
              label="Milestone One"
              placeholder="Choose Date"
              iconPosition="left"
              dateFormat="YYYY-MM-DD"
              value={this.state.form.milestoneOne}
              onChange={this.handleSelectChange}
            />
          </Form.Field>
          <Form.Field width={5}>
            <DateInput
              fluid
              closable
              name="milestoneTwo"
              label="Milestone Two"
              placeholder="Choose Date"
              iconPosition="left"
              dateFormat="YYYY-MM-DD"
              value={this.state.form.milestoneTwo}
              onChange={this.handleSelectChange}
            />
          </Form.Field>
          <Form.Field width={5}>
            <Form.Input
              type="number"
              fluid
              name="editorDueDateOffset"
              label="Editor Due Date Offset"
              placeholder="Choose Offset"
              value={this.state.form.editorDueDateOffset}
              onChange={e => this.handleChange(e)}
            />
          </Form.Field>
        </Form.Group>
        <Form.Button type="Submit" color="green">
          Add Month
        </Form.Button>
      </Form>
    );
  }
}

const mapStateToProps = state => {
  return {
    contentStatuses: state.contentGeneration.contentStatuses,
    planningMonths: state.contentGeneration.planningMonths,
    errors: state.contentGeneration.errors,
    isAddingMonth: state.contentGeneration.isAddingMonth,
    addedMonth: state.contentGeneration.addedMonth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchPlanningMonths: () => {
      return dispatch(contentGeneration.fetchPlanningMonths());
    },
    addPlanningMonth: formData => {
      return dispatch(contentGeneration.addPlanningMonth(formData));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanningMonthForm);
