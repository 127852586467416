import React, { Component } from "react";
import "./AppManagementDirectory.css";
import AppList from "../../../components/AppList/AppList";
import { Icon, Button, Transition, Search } from "semantic-ui-react";
import _ from "lodash";
import AppCreateForm from "../../../components/AppCreateForm/AppCreateForm";

class AppManagementDirectory extends Component {
  state = {
    isAddingApp: false,
    isSearching: false,
    searchValue: '',
    searchResults: [],
  };

  resetComponent = () =>
  this.setState({ isSearching: false, searchResults: [], searchValue: "" });

  handleResultSelect = (e, { result }) => this.setState({ searchValue: result.site_name })

  handleSearchChange = (e, { value }) => {
    this.setState({ isSearching: true, searchValue: value })

    setTimeout(() => {
      if (this.state.searchValue.length < 1) return this.resetComponent()

      const re = new RegExp(_.escapeRegExp(this.state.searchValue), 'i')
      const isMatch = (result) => re.test(result.site_name)

      this.setState({
        isSearching: false,
        searchResults: _.filter(this.props.apps, isMatch),
      })
    }, 300)
  }

  handleClose = () => {
    this.setState({isAddingApp: false})
  }
  render() {
    return (
      <section className="AppManagementDirectory">
        <div className="AppManagementDirectory--header">
          <div className="AppManagementDirectory--searchBox">
          <Search
            fluid
            placeholder="Search Apps"
            loading={this.state.isSearching}
            onResultSelect={this.handleResultSelect}
            onSearchChange={_.debounce(this.handleSearchChange, 500, {
              leading: true
            })}
            results={this.state.searchResults.map(result => ({
              key: result.id,
              title: result.site_name
            }))}
            value={this.state.searchValue}
          />
            {/* <Icon size="large" name="search" />
            <Input
              id="appSearch"
              size="large"
              className="AppManagementDirectory--searchInput"
              transparent
              placeholder="Search Apps"
            /> */}
          </div>
          <div className="AppManagementDirectory--header__right">
            <Button onClick={() => this.setState({isAddingApp: !this.state.isAddingApp})} color={this.state.isAddingApp ? "red" : "teal"} icon labelPosition="right">
              {this.state.isAddingApp ? "Cancel" : "New App"}
              <Icon name={this.state.isAddingApp ? "minus" : "plus"} />
            </Button>
          </div>
        </div>
        <Transition animation="fade down" duration={400} visible={this.state.isAddingApp}>
          <div className="AppManagementDirectory--appForm">
            <AppCreateForm closeForm={this.handleClose} isAdding={this.state.isAddingApp} />
          </div>
        </Transition>
        <AppList
          apps={this.state.searchResults[0] ? this.state.searchResults : this.props.apps}
          isLoading={this.props.isLoading}
          isLoaded={this.props.isLoaded}
        />
      </section>
    );
  }
}

export default AppManagementDirectory;
