import React, { Component } from 'react';
import './ReportEmailEntries.css';
import { connect } from 'react-redux';
import { reporting } from '../../../../store/actions';
import { Loader, Label, Header, Divider, Icon, Button, Segment } from 'semantic-ui-react';
import moment from 'moment';

export class ReportEmailEntries extends Component {
	state = {
		selectedEmail: null,
		showAll: false
	};

	componentDidMount() {
		this.props.report &&
			!this.props.report.emailEntries &&
			this.props.fetchReportEmailEntries(this.props.report.id);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.report && this.props.report) {
			if (this.props.report.id !== prevProps.report.id) {
				!this.props.report.emailEntries && this.props.fetchReportEmailEntries(this.props.report.id);
			}
		}
	}

	render() {
		const report = this.props.report;
		let emailEntryEvents = report && report.emailEntries && report.emailEntries;
		const successfulViews =
			emailEntryEvents &&
			report.emailEntries.filter((e) => e.link_clicked).map((e) => ({ ...e, successfulView: true }));
		const unsubscribes =
			emailEntryEvents &&
			report.emailEntries.filter((e) => e.did_unsubscribe).map((e) => ({ ...e, unsubscribe: true }));

		emailEntryEvents = emailEntryEvents && emailEntryEvents.concat(successfulViews);
		emailEntryEvents = emailEntryEvents && emailEntryEvents.concat(unsubscribes);
		const originalEmailEntryEventsLength = emailEntryEvents && emailEntryEvents.length;
		const truncatedEvents = emailEntryEvents && emailEntryEvents.slice(0, 6);
		emailEntryEvents =
			emailEntryEvents && this.state.selectedEmail
				? emailEntryEvents.filter((e) => e.email === this.state.selectedEmail)
				: this.state.showAll ? emailEntryEvents : truncatedEvents;
		// emailEntryEvents = emailEntryEvents && emailEntryEvents.sort((a, b) => moment(a.date_sent) - moment(b.date_sent))

		return (
			<div className="ReportEmailEntries">
				{report.isFetchingEmailEntries ? (
					<Loader active>Fetching Report Entries</Loader>
				) : report.emailEntries && report.emailEntries[0] ? (
					<div className="ReportEmailEntries--container">
						<Divider className="ReportEmailEntries--divider" horizontal>
							<Header className="ReportEmailEntries--header" as="h4">
								<Icon name="mail" />
								Report Notifications
							</Header>
						</Divider>
						<div className="ReportEmailEntries--filterAreaContainer">
							<Segment>
								<div className="ReportEmailEntries--filterAreaDisplay">
									<div className="ReportEmailEntries-entryInfo">
										<span className="ReportEmailEntries-entryInfo--label">
											Report created on
										</span>{' '}
										<span className="ReportEmailEntries-entryInfo--date">
											{moment(report.date_created).format('MMM D, YYYY')}
										</span>
									</div>
									{this.state.selectedEmail ? (
										<div className="ReportEmailEntries--filterArea withFilter">
											<span className="ReportEmailEntries--filterAreaLabel">
												<Icon name="filter" />
												<span>Filtering events by: </span>
											</span>
											<span className="ReportEmailEntries--filterAreaOutput">
												{this.state.selectedEmail}
											</span>
											<span className="ReportEmailEntries--filterAreaCancel">
												<Button
													onClick={() => this.setState({ selectedEmail: null })}
													icon
													size="mini"
													compact
													negative
												>
													<Icon name="cancel" />
												</Button>
											</span>
										</div>
									) : (
										<div className="ReportEmailEntries--filterArea noFilter">
											<span className="ReportEmailEntries--filterAreaLabel">
												<Icon name="bell" />
												Viewing{' '}
												{this.state.showAll ? (
													'all'
												) : originalEmailEntryEventsLength < 6 ? (
													`All ${originalEmailEntryEventsLength}`
												) : (
													'6'
												)}{' '}
												Notification Events
											</span>
										</div>
									)}
								</div>
							</Segment>
						</div>

						{emailEntryEvents &&
							emailEntryEvents.reverse().map((entry, i) => {
								let labelColor = 'blue';
								if (entry.unsubscribe) {
									labelColor = 'red';
								} else if (entry.successfulView) {
									labelColor = 'green';
								}
								return (
									<div key={`${entry.id}-${i}`} className="ReportEmailEntry">
										<div className="ReportEmailEntry-label">
											<Label circular color={labelColor ? labelColor : null} size="small" empty />
										</div>
										<div className="ReportEmailEntry-content">
											{entry.unsubscribe ? (
												<span>
													<span
														onClick={() => this.setState({ selectedEmail: entry.email })}
														className="ReportEmailEntry-content--email"
													>
														{entry.email}
													</span>{' '}
													unsubscribed to report notifications
												</span>
											) : entry.successfulView ? (
												<span>
													<span
														onClick={() => this.setState({ selectedEmail: entry.email })}
														className="ReportEmailEntry-content--email"
													>
														{entry.email}
													</span>{' '}
													succesfully viewed report
												</span>
											) : (
												<span>
													Notification email sent to{' '}
													<span
														onClick={() => this.setState({ selectedEmail: entry.email })}
														className="ReportEmailEntry-content--email"
													>
														{entry.email}
													</span>
												</span>
											)}
										</div>
										<div className="ReportEmailEntry-date">
											{entry.unsubscribe ? (
												entry.date_unsubscribed && (
													<span>{moment(entry.date_unsubscribed).format('MMM D')}</span>
												)
											) : entry.successfulView ? (
												entry.date_first_viewed && (
													<span>{moment(entry.date_first_viewed).format('MMM D')}</span>
												)
											) : (
												<span>{moment(entry.date_sent).format('MMM D')}</span>
											)}
										</div>
									</div>
								);
							})}
						{!this.state.selectedEmail &&
						originalEmailEntryEventsLength > 8 && (
							<div className="ReportEmailEntries--bottomActions">
								{!this.state.showAll ? (
									<Button
										as="div"
										size="tiny"
										onClick={() => this.setState({ showAll: true, selectedEmail: null })}
										labelPosition="left"
									>
										<Label size="tiny" basic pointing="right">
											Showing 6 of {originalEmailEntryEventsLength} Events
										</Label>
										<Button size="tiny" positive icon>
											<Icon name="list" />
											Show All Events
										</Button>
									</Button>
								) : (
									<Button
										as="div"
										size="tiny"
										onClick={() => this.setState({ showAll: false, selectedEmail: null })}
										labelPosition="left"
									>
										<Label size="tiny" basic pointing="right">
											Showing All {originalEmailEntryEventsLength} Events
										</Label>
										<Button size="tiny" color="teal" icon>
											<Icon name="list" />
											Show Less
										</Button>
									</Button>
								)}
							</div>
						)}
					</div>
				) : (
					<div className="ReportEmailEntries--noData">
						<p>No Current Report Email Entries</p>
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchReportEmailEntries: (reportID) => dispatch(reporting.fetchReportEmailEntries(reportID))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportEmailEntries);
