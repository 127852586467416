import React, { Component } from "react";
import { contentGeneration, organizations } from "../../store/actions";
import { connect } from "react-redux";
import FormHeader from "../../components/FormHeader/FormHeader";
import { Form, Input, Button, Select } from "semantic-ui-react";
import ErrorHandler from "../ErrorHandler/ErrorHandler";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import moment from "moment";

const initialState = {
  parentKeyword: "",
  keyword: "",
  difficulty: "",
  volume: "",
  costPerClick: "",
  seoValue: "",
  businessValue: "",
  tags: [],
  planningMonths: []
};

const valueOptions = [
  {
    key: 1,
    text: "Low",
    value: "Low"
  },
  {
    key: 2,
    text: "Medium",
    value: "Medium"
  },
  {
    key: 3,
    text: "High",
    value: "High"
  },
  {
    key: 4,
    text: "Very High",
    value: "Very High"
  }
];

class KeywordForm extends Component {
  state = {
    form: initialState
  };

  componentDidMount() {
    !this.props.organizations[0] && this.props.fetchOrganizations();
    this.props.orgSlug && this.props.fetchContentKeywords(this.getOrganizationId(this.props.orgSlug));
    !this.props.contentTags[0] && this.props.fetchContentTags();
    !this.props.planningMonths[0] && this.props.fetchPlanningMonths();
    this.props.keywordToEdit && this.setKeywordData();
    this.props.currentMonth &&
      this.setState({
        form: {
          ...this.state.form,
          planningMonths: this.state.form.planningMonths.concat(
            this.props.currentMonth.id
          )
        }
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.keywordToEdit !== prevProps.keywordToEdit) {
      if (this.props.keywordToEdit) {
        this.setKeywordData();
      } else {
        this.clearForm();
      }
    }
    if (
      this.props.location &&
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      this.props.closeForm();
    }
    if (prevProps.orgSlug && this.props.orgSlug !== prevProps.orgSlug) {
      this.props.fetchContentKeywords(this.getOrganizationId(this.props.orgSlug));
    }
  }

  setKeywordData = () => {
    let kw = this.props.keywordToEdit;
    this.setState({
      form: {
        parentKeyword: kw.keyword.parent_keyword
          ? kw.keyword.parent_keyword.name
          : "",
        keyword: kw.keyword.name ? kw.keyword.name : "",
        difficulty: kw.keyword.difficulty ? kw.keyword.difficulty : "",
        volume: kw.keyword.volume ? kw.keyword.volume : kw.keyword.volume,
        costPerClick: kw.keyword.cost_per_click
          ? kw.keyword.cost_per_click
          : "",
        seoValue: kw.seo_value ? kw.seo_value : "",
        businessValue: kw.business_value ? kw.business_value : "",
        tags: kw.tag,
        planningMonths: kw.planning_months
      }
    });
  };

  clearForm = () => {
    this.setState({ form: initialState });
  };

  handleChange(e) {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  }

  handleSelectChange = (event, { name, value }) => {
    this.setState({ form: { ...this.state.form, [name]: value } });
  };

  getOrganizationId = () => {
    let orgId = this.props.organizations.find(
      org => org.slug === this.props.orgSlug
    ).id;
    return orgId;
  };

  handleSubmit = async e => {
    e.preventDefault();
    const submitData = async () => {
      if (this.props.keywordToEdit) {
        return await this.props.updateContentKeyword(
          this.state.form,
          this.props.keywordToEdit.id,
          this.props.keywordToEdit.organization.id ? this.props.keywordToEdit.organization.id : this.props.keywordToEdit.organization
        );
      } else if (this.props.addingKeyword) {
        return await this.props.addContentKeyword(
          this.state.form,
          this.props.orgSlug ? this.getOrganizationId() : this.props.orgId
        );
      }
    }
    const cleanUp = () => {
      this.clearForm();
      !this.props.embeded ? this.props.closeForm() : this.props.onSuccess();
    }
    await submitData()
    return !this.props.errors && cleanUp()
  };

  handleDeleteKeyword = async id => {
    await this.props.deleteContentKeyword(id);
    return !this.props.errors && this.props.closeForm();
  };

  render() {
    return (
      <div className={`KeywordForm ${this.props.embeded ? 'fullHeight' : ''}`}>
        {!this.props.embeded && (
          <FormHeader
            headerText={
              this.props.addingKeyword ? "Add Keyword" : "Edit Keyword"
            }
          >
            {this.props.keywordToEdit && (
              <ConfirmationModal
                handleConfirm={() =>
                  this.handleDeleteKeyword(this.props.keywordToEdit.id)
                }
                headerText="Delete Keyword?"
                headerIcon="trash alternate"
                buttonIcon="times"
                buttonText="Delete"
                color="red"
                buttonSize="mini"
                bodyText="Are you sure you want to delete this keyword?"
              />
            )}
            <Button size="mini" onClick={() => this.props.closeForm()}>
              Cancel
            </Button>
          </FormHeader>
        )}
        {this.props.errors && <ErrorHandler errors={this.props.errors} />}
        <Form onSubmit={this.handleSubmit} size="small">
          <Form.Field inline>
            <label>Parent Keyword</label>
            <Input
              required
              list="parentKeywords"
              name="parentKeyword"
              placeholder="Parent Keyword"
              value={this.state.form.parentKeyword}
              onChange={e => this.handleChange(e)}
            />

            {this.props.orgSlug ? (
              <datalist id="parentKeywords">
                {this.props.keywords
                  .filter(kw => kw.organization && kw.organization.slug === this.props.orgSlug)
                  .map(kw =>
                    kw.parentKeyword ? (
                      <option
                        key={kw.id}
                        value={kw.keyword.parent_keyword.name}
                      />
                    ) : (
                      <option key={kw.id} value="" />
                    )
                  )}
              </datalist>
            ) : (
              <datalist id="parentKeywords">
                {this.props.keywords
                  .filter(kw => kw.organization && kw.organization.id === this.props.orgId)
                  .map(kw =>
                    kw.parentKeyword ? (
                      <option
                        key={kw.id}
                        value={kw.keyword.parent_keyword.name}
                      />
                    ) : (
                      <option key={kw.id} value="" />
                    )
                  )}
              </datalist>
            )}
          </Form.Field>
          <Form.Field inline>
            <label>Keyword</label>
            <Input
              required
              name="keyword"
              placeholder="Keyword"
              value={this.state.form.keyword}
              onChange={e => this.handleChange(e)}
            />
          </Form.Field>
          <Form.Field inline>
            <label>Difficulty</label>
            <input
              name="difficulty"
              type="number"
              min={0}
              step={1}
              placeholder="Difficulty"
              value={this.state.form.difficulty}
              onChange={e => this.handleChange(e)}
            />
          </Form.Field>
          <Form.Field inline>
            <label>Volume</label>
            <input
              name="volume"
              type="number"
              min={0}
              step={1}
              placeholder="Volume"
              value={this.state.form.volume}
              onChange={e => this.handleChange(e)}
            />
          </Form.Field>
          <Form.Field inline>
            <label>Cost Per Click</label>
            <input
              name="costPerClick"
              type="number"
              placeholder="Cost Per Click"
              value={this.state.form.costPerClick}
              onChange={e => this.handleChange(e)}
            />
          </Form.Field>
          <Form.Field inline>
            <label>SEO Value</label>
            <Select
              selection
              placeholder="Choose One"
              onChange={this.handleSelectChange}
              name="seoValue"
              options={valueOptions}
              value={this.state.form.seoValue}
            />
          </Form.Field>
          <Form.Field inline>
            <label>Business Value</label>
            <Select
              selection
              placeholder="Choose One"
              onChange={this.handleSelectChange}
              name="businessValue"
              options={valueOptions}
              value={this.state.form.businessValue}
            />
          </Form.Field>
          <Form.Field inline>
            <label>Planning Months</label>
            <Select
              selection
              multiple
              placeholder="Choose Planning Months"
              onChange={this.handleSelectChange}
              name="planningMonths"
              options={this.props.planningMonths.map(ct => ({
                key: ct.id,
                text: `${moment(ct.month, "MM").format("MMMM")} ${ct.year}`,
                value: ct.id
              }))}
              value={
                this.state.form.planningMonths
                  ? this.state.form.planningMonths
                  : []
              }
            />
          </Form.Field>
          <Form.Field className="alignRight">
            <Button type="submit" positive size="tiny">
              {this.props.keywordToEdit ? "Update Keyword" : "Add Keyword"}
            </Button>
          </Form.Field>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    keywords: state.contentGeneration.keywords,
    isLoadingKeywords: state.contentGeneration.isLoadingKeywords,
    keywordsLoaded: state.contentGeneration.keywordsLoaded,
    organizations: state.organizations.organizations,
    contentTags: state.contentGeneration.contentTags,
    isLoadingOrgs: state.organizations.isLoading,
    orgsLoaded: state.organizations.isLoaded,
    planningMonths: state.contentGeneration.planningMonths,
    errors: state.contentGeneration.errors,
    messages: state.contentGeneration.messages
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchContentKeywords: (organizationId) => {
      return dispatch(contentGeneration.fetchContentKeywords(organizationId));
    },
    fetchOrganizations: () => {
      return dispatch(organizations.fetchOrganizations());
    },
    fetchContentTags: () => {
      return dispatch(contentGeneration.fetchContentTags());
    },
    fetchPlanningMonths: () => {
      return dispatch(contentGeneration.fetchPlanningMonths());
    },
    addContentKeyword: (formData, orgId) => {
      return dispatch(contentGeneration.addContentKeyword(formData, orgId));
    },
    updateContentKeyword: (formData, id, orgId) => {
      return dispatch(
        contentGeneration.updateContentKeyword(formData, id, orgId)
      );
    },
    deleteContentKeyword: id => {
      return dispatch(contentGeneration.deleteContentKeyword(id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KeywordForm);
