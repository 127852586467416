import React from 'react';
import './ArticleDetailItem.css';
import { Loader } from 'semantic-ui-react';

const ArticleDetailItem = (props) => {
	return (
		<div className="ArticleDetailItem">
			{(props.heading || props.action) && <div className="ArticleDetailItem--heading">
				{props.heading} {props.action && props.action}
			</div>}
			<div className="ArticleDetailItem--content">
				{props.isLoading ? (
					<div className="InlineLoader">
						<Loader inline active size="mini" />
						{props.loadingText && <span className="InlineLoader--loadingText">{props.loadingText}</span>}
					</div>
				) : (
					props.children
				)}
			</div>
		</div>
	);
};

export default ArticleDetailItem;
