import React, { Component } from 'react';
import './OrgLogo.css';
import { Image, Loader } from 'semantic-ui-react';
import axios from '../../axios-portal';
import ErrorHandler from '../../components/ErrorHandler/ErrorHandler';

class OrgLogo extends Component {
	state = {
		logoUrl: null,
		errors: null,
		isLoading: false,
		isLoaded: false
	};

	componentDidMount() {
		if (this.props.organization) {
			this.fetchUrl();
		} else {
			this.setState({
				errors: {
					error: 'No Organization has been provided.'
				}
			});
		}
	}

	fetchUrl = async () => {
		await this.props.handleStartLoad();
		await this.setState({
			isLoading: true,
			isLoaded: false
		});
		axios
			.get(`organizations/${this.props.organization}/`)
			.then((response) => {
				this.props.handleFinishLoad();
				return this.setState({
					logoUrl: response.data.logo,
					errors: null,
					isLoading: false,
					isLoaded: true
				});
			})
			.catch((err) => {
				this.props.handleFinishLoad();
				return this.setState({
					logoUrl: null,
					errors: err,
					isLoading: false,
					isLoaded: false
				});
			});
	};

	render() {
		return (
			<div className="OrgLogo">
				{this.state.errors ? (
					<ErrorHandler errors={this.state.errors} />
				) : this.state.isLoaded && !this.state.logoUrl ? (
					<p>This Organization does not have a logo uploaded.</p>
				) : this.state.isLoading ? (
					!this.props.handleStartLoad && <Loader active size="tiny" />
				) : (
					<Image src={this.state.logoUrl} size={this.props.size ? this.props.size : 'large'} />
				)}
			</div>
		);
	}
}

export default OrgLogo;
