import _ from "lodash";
import React, { PureComponent } from "react";
import { Table, Label, Checkbox } from "semantic-ui-react";
import "./SortableTable.css";
import StatusPill from "../../components/StatusPill/StatusPill";
import TableFilter from "../TableFilter/TableFilter";
import moment from "moment";
import { parseFirstNameLastInitial } from "../../utility";

// const initialFilters = {
//     milestone: null,
//     writer: null,
//     client: null,
//     status: null,
// };

export default class SortableTable extends PureComponent {
  state = {
    column: null,
    data: this.props.tableData,
    direction: null,
    // filters: initialFilters,
    currentIndex: null,
    isShifting: false,
    isMeta: false,
    finalReviewStaffOnly: false,
  };

  _handleKeyDown = (event) => {
    if (event.shiftKey) {
      this.setState({ isShifting: true });
    } else {
      this.setState({ isShifting: false });
    }
    if (event.metaKey) {
      this.setState({ isMeta: true });
    } else {
      this.setState({ isMeta: false });
    }
  };

  _handleKeyUp = (event) => {
    this.setState({ isShifting: false, isMeta: false });
  };

  componentDidMount() {
    document.addEventListener("keydown", this._handleKeyDown);
    document.addEventListener("keyup", this._handleKeyUp);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this._handleKeyDown);
    document.removeEventListener("keyup", this._handleKeyUp);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.originalArticles !== this.props.originalArticles ||
      prevProps.originalTemplates !== this.props.originalTemplates
    ) {
      this.setState({
        data: _.sortBy(this.props.tableData, [this.state.column]),
      });
    }
  }

  handleSort = (clickedColumn, isDate) => () => {
    const { column, data, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: isDate
          ? data.sort(
              (a, b) =>
                moment([
                  a.planning_year_month.year,
                  a.planning_year_month.month,
                  1,
                ]) -
                  moment([
                    b.planning_year_month.year,
                    b.planning_year_month.month,
                    1,
                  ]) || a.project.name - b.project.name
            )
          : _.sortBy(data, [clickedColumn]),
        direction: "ascending",
      });

      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  ifData = (data) => {
    if (this.props.tableData[0]) {
      if (data in this.props.tableData[0]) {
        return true;
      } else {
        return false;
      }
    }
  };

  handleFilter = (event, { name, value }) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [name]: value !== "" ? value : null,
      },
    });
  };

  handleToggleChange(e) {
    this.setState({
      filters: {
        ...this.state.filters,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      },
    });
  }

  clearFilters = () => {
    this.setState({ filters: null });
  };

  handleTableFilter = (data) => {
    // const filters = Object.entries(this.state.filters)
    // console.log(filters)
    // filters.forEach(([key, value]) => console.log(`${key}: ${value}`))
    //   filters.forEach( async ([key, value]) => {
    //     let keyString = `${key}`
    //     console.log('keystring', keyString)
    //     // data.map(d => console.log('key map', d[key], 'value map', value, 'evaluates', d[key] === value))
    //      data.filter(d => d[key] === value)
    //      let newData = await data.filter(d => {
    //       return Object.entries(d).forEach( async ([dataKey, dataValue]) => {
    //         //console.log('data entries', Object.entries(d))
    //         if (dataKey === key) {
    //           // console.log('dataKey:', dataKey, 'key:', key)
    //           // console.log('dataValue', dataValue, 'value:', value)
    //           await console.log('matches?', value === dataValue)
    //           return value === dataValue
    //         }
    //       })
    //     })
    //     console.log('newData', newData)
    //      console.log(data)
    //   })
    // return data
    if (this.state.filters) {
      return data.filter(
        (d) =>
          (this.state.filters.milestone
            ? d.milestone === this.state.filters.milestone
            : d) &&
          (this.state.filters.writer
            ? d.writer.id === this.state.filters.writer
            : d) &&
          (this.state.filters.editor
            ? d.editor.id === this.state.filters.editor
            : d) &&
          (this.state.filters.final_approver
            ? d.final_approver.id === this.state.filters.final_approver
            : d) &&
          (this.state.filters.poster
            ? d.poster.id === this.state.filters.poster
            : d) &&
          (this.state.filters.lead
            ? d.lead.id === this.state.filters.lead
            : d) &&
          (this.state.filters.client
            ? d.client.id === this.state.filters.client
            : d) &&
          (this.state.filters.status
            ? d.status.id === this.state.filters.status
            : d) &&
          (this.state.filters.content_type
            ? d.content_type.id === this.state.filters.content_type
            : d) &&
          (this.state.filters.month
            ? d.planning_year_month.id === this.state.filters.month
            : d) &&
          (this.state.filters.finalReviewStaffOnly
            ? d.final_approver.is_staff === true
            : d)
      );
    } else {
      return data;
    }
  };

  handleItemSelect = (e, data, i) => {
    e.preventDefault();
    if (
      e.shiftKey &&
      this.state.currentIndex !== null &&
      i !== this.state.currentIndex &&
      this.props.handleMultiSelect
    ) {
      let articles = () => {
        if (this.state.currentIndex < i) {
          return this.state.data.slice(this.state.currentIndex, i + 1);
        } else {
          return this.state.data.slice(i, this.state.currentIndex + 1);
        }
      };
      this.props.handleMultiSelect(articles());
    } else if (
      e.metaKey &&
      this.state.currentIndex !== null &&
      i !== this.state.currentIndex &&
      this.props.handleMultiSelect
    ) {
      let targetArticle = this.props.selectedArticles.find(
        (article) => article.id === data.id
      );
      targetArticle
        ? this.props.handleMultiSelect(targetArticle, true, true)
        : this.props.selectedItem
        ? this.props.handleMultiSelect([data, this.props.selectedItem], true)
        : this.props.handleMultiSelect(data, true);
    } else {
      this.setState({ currentIndex: i });
      this.props.handleSelect(data);
    }
  };

  isSelected = (id) => {
    if (this.props.selectedArticles && this.props.selectedArticles[0]) {
      if (this.props.selectedArticles.find((article) => article.id === id)) {
        return true;
      } else {
        return false;
      }
    } else if (this.props.selectedItem) {
      if (this.props.selectedItem.id === id) {
        return true;
      } else {
        return false;
      }
    }
  };

  render() {
    const { column, data, direction } = this.state;

    return (
      <div
        className={`SortableTable ${this.state.isShifting ? "shifting" : ""}`}
      >
        <div className="SortableTableFilters">
          {this.props.writers && !this.props.isStatusParam && (
            <TableFilter
              options={
                this.props.writers &&
                this.props.writers.map((writer) => ({
                  key: writer.id,
                  text: writer.user.name,
                  value: writer.id,
                }))
              }
              filterName="writer"
              placeholder="Filter Writer"
              handleChange={this.handleFilter}
            />
          )}
          {!this.props.isStatusParam && (
            <React.Fragment>
              {this.props.contentStaff &&
              !this.props.isStatusParam &&
              this.props.contentStaff[0] ? (
                <TableFilter
                  options={
                    this.props.contentStaff &&
                    this.props.contentStaff.map((staff) => ({
                      key: staff.id,
                      text: staff.name,
                      value: staff.id,
                    }))
                  }
                  filterName="editor"
                  placeholder="Filter Editor"
                  handleChange={this.handleFilter}
                />
              ) : (
                !this.isStatusParam && (
                  <TableFilter
                    options={
                      this.props.contentStaff &&
                      this.props.contentStaff.map((staff) => ({
                        key: staff.id,
                        text: staff.name,
                        value: staff.id,
                      }))
                    }
                    filterName="editor"
                    placeholder="Filter Editor"
                    handleChange={this.handleFilter}
                  />
                )
              )}
            </React.Fragment>
          )}
          {this.props.contentStaff && this.props.isStatusParam === "writing" && (
            <TableFilter
              options={
                this.props.writers &&
                this.props.writers.map((writer) => ({
                  key: writer.id,
                  text: writer.user.name,
                  value: writer.id,
                }))
              }
              filterName="writer"
              placeholder="Filter Writer"
              handleChange={this.handleFilter}
            />
          )}
          {this.props.contentStaff && this.props.isStatusParam === "editing" && (
            <TableFilter
              options={
                this.props.contentStaff &&
                this.props.contentStaff.map((staff) => ({
                  key: staff.id,
                  text: staff.name,
                  value: staff.id,
                }))
              }
              filterName="editor"
              placeholder="Filter Editor"
              handleChange={this.handleFilter}
            />
          )}
          {this.props.contentStaff &&
            (this.props.isStatusParam === "rewrite" ||
              this.props.isStatusParam === "reedit") && (
              <TableFilter
                options={
                  this.props.contentStaff &&
                  this.props.contentStaff.map((staff) => ({
                    key: staff.id,
                    text: staff.name,
                    value: staff.id,
                  }))
                }
                filterName="lead"
                placeholder="Filter Lead"
                handleChange={this.handleFilter}
              />
            )}
          {this.props.contentStaff &&
            this.props.isStatusParam === "final_review" && (
              <TableFilter
                options={
                  this.props.contentStaff &&
                  this.props.contentStaff.map((staff) => ({
                    key: staff.id,
                    text: staff.name,
                    value: staff.id,
                  }))
                }
                filterName="final_approver"
                placeholder="Filter Final Reviewer"
                handleChange={this.handleFilter}
              />
            )}
          {this.props.contentStaff &&
            this.props.isStatusParam === "ready_to_post" && (
              <TableFilter
                options={
                  this.props.contentStaff &&
                  this.props.contentStaff.map((staff) => ({
                    key: staff.id,
                    text: staff.name,
                    value: staff.id,
                  }))
                }
                filterName="poster"
                placeholder="Filter Poster"
                handleChange={this.handleFilter}
              />
            )}
          {this.props.isSingleOrganization ? (
            <TableFilter
              options={
                this.props.planningMonths &&
                this.props.planningMonths.map((month) => ({
                  key: month.id,
                  text: `${moment(month.month, "MM").format("MMMM")} ${
                    month.year
                  }`,
                  value: month.id,
                }))
              }
              filterName="month"
              placeholder="Filter Month"
              handleChange={this.handleFilter}
            />
          ) : (
            this.props.organizations && (
              <TableFilter
                options={
                  this.props.organizations &&
                  this.props.organizations.map((org) => ({
                    key: org.id,
                    text: org.dba_name,
                    value: org.id,
                  }))
                }
                filterName="client"
                placeholder="Filter Client"
                handleChange={this.handleFilter}
              />
            )
          )}
          {!this.props.isStatusParam && (
            <TableFilter
              options={
                this.props.contentStatuses &&
                this.props.contentStatuses.map((status) => ({
                  key: status.id,
                  text: status.name,
                  value: status.id,
                }))
              }
              filterName="status"
              placeholder="Filter Status"
              handleChange={this.handleFilter}
            />
          )}
          {this.props.isStatusParam && this.props.contentTypes && (
            <TableFilter
              options={
                this.props.contentTypes &&
                this.props.contentTypes.map((contentType) => ({
                  key: contentType.id,
                  text: contentType.name,
                  value: contentType.id,
                }))
              }
              filterName="content_type"
              placeholder="Filter Content Type"
              handleChange={this.handleFilter}
            />
          )}
          {!this.props.projects && !this.props.isStatusParam && (
            <TableFilter
              options={[
                {
                  key: 1,
                  text: "1",
                  value: "1",
                },
                {
                  key: 2,
                  text: "2",
                  value: "2",
                },
              ]}
              filterName="milestone"
              placeholder="Filter Milestone"
              handleChange={this.handleFilter}
            />
          )}
          {this.props.isStatusParam === "final_review" && (
            <div className="SortableTable--staffToggle">
              <Checkbox
                id="finalReviewStaffOnly"
                name="finalReviewStaffOnly"
                toggle
                label="Staff Approvers Only"
                onChange={(e) => this.handleToggleChange(e)}
                checked={
                  this.state.filters && this.state.filters.finalReviewStaffOnly
                }
              />
            </div>
          )}
        </div>
        <div className="SortableTable--Table">
          <Table size="small" compact sortable celled selectable>
            <Table.Header>
              <Table.Row>
                {this.props.isSingleOrganization ? (
                  <Table.HeaderCell
                    sorted={
                      column === "planning_year_month.id" ? direction : null
                    }
                    onClick={this.handleSort("planning_year_month.id")}
                  >
                    Month / Project
                  </Table.HeaderCell>
                ) : (
                  <Table.HeaderCell
                    sorted={column === "client.dba_name" ? direction : null}
                    onClick={this.handleSort("client.dba_name")}
                  >
                    Client
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell
                  sorted={column === "title" ? direction : null}
                  onClick={this.handleSort("title")}
                >
                  Title
                </Table.HeaderCell>
                {this.props.isStatusParam ? (
                  <React.Fragment>
                    {this.props.isStatusParam === "writing" && (
                      <Table.HeaderCell
                        sorted={
                          column === "writer.user.name" ? direction : null
                        }
                        onClick={this.handleSort("writer.user.name")}
                      >
                        Writer
                      </Table.HeaderCell>
                    )}
                    {this.props.isStatusParam === "editing" && (
                      <Table.HeaderCell
                        sorted={column === "editor.name" ? direction : null}
                        onClick={this.handleSort("editor.name")}
                      >
                        Editor
                      </Table.HeaderCell>
                    )}
                    {(this.props.isStatusParam === "rewrite" ||
                      this.props.isStatusParam === "reedit" ||
                      this.props.isStatusParam === "post_qa") && (
                      <Table.HeaderCell
                        sorted={column === "lead.name" ? direction : null}
                        onClick={this.handleSort("lead.name")}
                      >
                        Lead
                      </Table.HeaderCell>
                    )}
                    {this.props.isStatusParam === "final_review" && (
                      <Table.HeaderCell
                        sorted={
                          column === "final_approver.name" ? direction : null
                        }
                        onClick={this.handleSort("final_approver.name")}
                      >
                        Final Reviewer
                      </Table.HeaderCell>
                    )}
                    {this.props.isStatusParam === "ready_to_post" && (
                      <Table.HeaderCell
                        sorted={column === "poster.name" ? direction : null}
                        onClick={this.handleSort("poster.name")}
                      >
                        Poster
                      </Table.HeaderCell>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Table.HeaderCell
                      sorted={column === "writer.user.name" ? direction : null}
                      onClick={this.handleSort("writer.user.name")}
                    >
                      Writer
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "editor.name" ? direction : null}
                      onClick={this.handleSort("editor.name")}
                    >
                      Editor
                    </Table.HeaderCell>
                  </React.Fragment>
                )}

                {!this.props.projects && !this.props.isStatusParam && (
                  <Table.HeaderCell
                    sorted={column === "milestone" ? direction : null}
                    onClick={this.handleSort("milestone")}
                  >
                    MS
                  </Table.HeaderCell>
                )}

                <Table.HeaderCell
                  sorted={column === "content_type.name" ? direction : null}
                  onClick={this.handleSort("content_type.name")}
                >
                  Type
                </Table.HeaderCell>

                {this.ifData("status") && !this.props.isStatusParam && (
                  <Table.HeaderCell
                    sorted={column === "status.name" ? direction : null}
                    onClick={this.handleSort("status.name")}
                  >
                    Status
                  </Table.HeaderCell>
                )}

                {this.props.renderDueDate && this.props.isStatusParam && (
                  <Table.HeaderCell
                    sorted={
                      column ===
                      this.props.renderCorrespondingDueDateType(
                        this.props.isStatusParam
                      )
                        ? direction
                        : null
                    }
                    onClick={this.handleSort(
                      this.props.renderCorrespondingDueDateType(
                        this.props.isStatusParam
                      )
                    )}
                  >
                    Due Date
                  </Table.HeaderCell>
                )}

                {this.props.isStatusParam &&
                  this.props.isStatusParam === "rewrite" && (
                    <Table.HeaderCell
                      textAlign="center"
                      sorted={
                        column === "latest_feedback_rating" ? direction : null
                      }
                      onClick={this.handleSort("latest_feedback_rating")}
                    >
                      Rating
                    </Table.HeaderCell>
                  )}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.data &&
                this.handleTableFilter(data).map(
                  (d, i) =>
                    !d.archived && (
                      <Table.Row
                        className={`SortableTableRow ${
                          this.isSelected(d.id) ? "Selected" : "notSelected"
                        }`}
                        onClick={
                          this.props.multiSelectEnabled
                            ? (e) => this.props.handleSelect(e, d, data)
                            : (e) => this.handleItemSelect(e, d, i)
                        }
                        key={d.id}
                      >
                        <Table.Cell>
                          {this.props.isArticleList &&
                            d.is_late &&
                            !this.props.isStatusParam && (
                              <Label size="mini" color="red" horizontal>
                                Late
                              </Label>
                            )}
                          {this.props.isSingleOrganization ? (
                            <span>
                              {d.planning_year_month &&
                              d.planning_year_month.month ? (
                                <span>
                                  <span>
                                    {moment(
                                      d.planning_year_month.month,
                                      "MM"
                                    ).format("MMMM")}
                                  </span>{" "}
                                  <span>{d.planning_year_month.year}</span>
                                </span>
                              ) : d.project ? (
                                <span>{d.project.name}</span>
                              ) : (
                                <span>None</span>
                              )}
                            </span>
                          ) : (
                            <span>{d.client.dba_name}</span>
                          )}
                        </Table.Cell>
                        <Table.Cell>{d.title}</Table.Cell>
                        {this.props.isStatusParam ? (
                          <React.Fragment>
                            {this.props.isStatusParam === "writing" && (
                              <Table.Cell>
                                {d.writer.user &&
                                  parseFirstNameLastInitial(d.writer.user.name)}
                              </Table.Cell>
                            )}
                            {this.props.isStatusParam === "editing" && (
                              <Table.Cell>
                                {d.editor && (
                                  <span className="SortableTable--cellSpan">
                                    {parseFirstNameLastInitial(d.editor.name)}{" "}
                                    {/* {d.editor.is_staff && !this.props.isStatusParam && (
                                      <Label
                                        basic
                                        color="teal"
                                        size="mini"
                                        className="staffLabel clientOption--label"
                                      >
                                        Staff
                                      </Label>
                                    )} */}
                                  </span>
                                )}
                              </Table.Cell>
                            )}
                            {(this.props.isStatusParam === "rewrite" ||
                              this.props.isStatusParam === "reedit" ||
                              this.props.isStatusParam === "post_qa") && (
                              <Table.Cell>
                                {d.lead && (
                                  <span className="SortableTable--cellSpan">
                                    {parseFirstNameLastInitial(d.lead.name)}
                                  </span>
                                )}
                              </Table.Cell>
                            )}
                            {this.props.isStatusParam === "final_review" && (
                              <Table.Cell>
                                {d.final_approver && (
                                  <span className="SortableTable--cellSpan">
                                    {parseFirstNameLastInitial(
                                      d.final_approver.name
                                    )}{" "}
                                    {/* {d.final_approver.is_staff && !this.props.isStatusParam && (
                                      <Label
                                        basic
                                        color="teal"
                                        size="mini"
                                        className="staffLabel clientOption--label"
                                      >
                                        Staff
                                      </Label>
                                    )} */}
                                  </span>
                                )}
                              </Table.Cell>
                            )}
                            {this.props.isStatusParam === "ready_to_post" && (
                              <Table.Cell>
                                {d.poster && (
                                  <span className="SortableTable--cellSpan">
                                    {parseFirstNameLastInitial(d.poster.name)}{" "}
                                    {/* {d.poster.is_staff && !this.props.isStatusParam && (
                                      <Label
                                        basic
                                        color="teal"
                                        size="mini"
                                        className="staffLabel clientOption--label"
                                      >
                                        Staff
                                      </Label>
                                    )} */}
                                  </span>
                                )}
                              </Table.Cell>
                            )}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <Table.Cell>
                              {d.writer.user &&
                                parseFirstNameLastInitial(d.writer.user.name)}
                            </Table.Cell>
                            <Table.Cell>
                              {d.editor && (
                                <span className="SortableTable--cellSpan">
                                  {parseFirstNameLastInitial(d.editor.name)}{" "}
                                  {/* {d.editor.is_staff && !this.props.isStatusParam && (
                                    <Label
                                      basic
                                      color="teal"
                                      size="mini"
                                      className="staffLabel clientOption--label"
                                    >
                                      Staff
                                    </Label>
                                  )} */}
                                </span>
                              )}
                            </Table.Cell>
                          </React.Fragment>
                        )}
                        {!this.props.projects && !this.props.isStatusParam && (
                          <Table.Cell>{d.milestone}</Table.Cell>
                        )}
                        <Table.Cell>{d.content_type.name}</Table.Cell>
                        {this.ifData("status") && !this.props.isStatusParam && (
                          <Table.Cell textAlign="center">
                            <StatusPill
                              color={d.status.color}
                              content={d.status.name}
                            />
                          </Table.Cell>
                        )}
                        {this.props.renderDueDate && this.props.isStatusParam && (
                          <Table.Cell>
                            <span
                              className={`${d.is_late ? "isLate" : "onTime"}`}
                            >
                              {this.props.renderDueDate(d) &&
                                moment(this.props.renderDueDate(d)).format(
                                  "MM/DD/YYYY"
                                )}
                            </span>
                          </Table.Cell>
                        )}
                        {this.props.isStatusParam &&
                          this.props.isStatusParam === "rewrite" && (
                            <Table.Cell textAlign="center">
                              {d.latest_feedback_rating}
                            </Table.Cell>
                          )}
                      </Table.Row>
                    )
                )}
            </Table.Body>
          </Table>
        </div>
      </div>
    );
  }
}
