import _ from "lodash";
import React, { PureComponent } from "react";
import { Table } from "semantic-ui-react";
import "./ClientContentDashboardTable.css";
import moment from "moment";
import StatusPill from "../../../../components/StatusPill/StatusPill";

export default class ClientContentDashboardTable extends PureComponent {
  state = {
    column: null,
    data: this.props.tableData,
    direction: null,
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.originalArticles !== this.props.originalArticles ||
      prevProps.originalTemplates !== this.props.originalTemplates
    ) {
      this.setState({
        data: _.sortBy(this.props.originalArticles, [this.state.column]),
      });
    }
  }

  handleSort = (clickedColumn, isDate) => () => {
    const { column, data, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: isDate
          ? data.sort(
              (a, b) =>
                moment([
                  a.planning_year_month.year,
                  a.planning_year_month.month,
                  1,
                ]) -
                  moment([
                    b.planning_year_month.year,
                    b.planning_year_month.month,
                    1,
                  ]) || a.project.name - b.project.name
            )
          : _.sortBy(data, [clickedColumn]),
        direction: "ascending",
      });

      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };

  ifData = (data) => {
    if (this.props.tableData[0]) {
      if (data in this.props.tableData[0]) {
        return true;
      } else {
        return false;
      }
    }
  };

  isSelected = (id) => {
    if (this.props.selectedItem) {
      if (this.props.selectedItem.id === id) {
        return true;
      } else {
        return false;
      }
    }
  };

  render() {
    const { column, data, direction } = this.state;

    return (
      <div className={`ClientContentDashboardTable`}>
        <div className="ClientContentDashboardTable--Table">
          <Table size="small" compact sortable celled selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={column === "title" ? direction : null}
                  onClick={this.handleSort("title")}
                >
                  Title
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={
                    column === "planning_year_month.id" ? direction : null
                  }
                  onClick={this.handleSort("planning_year_month.id")}
                  textAlign='center'
                >
                  Planning Month
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "content_type.name" ? direction : null}
                  onClick={this.handleSort("content_type.name")}
                  textAlign='center'
                >
                  Type
                </Table.HeaderCell>
                {this.ifData("status") && (
                  <Table.HeaderCell
                    sorted={column === "status.name" ? direction : null}
                    onClick={this.handleSort("status.name")}
                    textAlign='center'
                  >
                    Status
                  </Table.HeaderCell>
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data &&
                data.map(
                  (d, i) =>
                    !d.archived && (
                      <Table.Row
                        className={`ClientContentDashboardTableRow ${
                          this.isSelected(d.id) ? "selected" : "notSelected"
                        }`}
                        onClick={(e) => this.props.handleSelect(d)}
                        key={d.id}
                      >
                        <Table.Cell>{d.title}</Table.Cell>
                        <Table.Cell textAlign='center'>
                          <span>
                            {d.planning_year_month &&
                            d.planning_year_month.month ? (
                              <span>
                                <span>
                                  {moment(
                                    d.planning_year_month.month,
                                    "MM"
                                  ).format("MMM")}
                                </span>{" "}
                                <span>{d.planning_year_month.year}</span>
                              </span>
                            ) : (
                              <span>None</span>
                            )}
                          </span>
                        </Table.Cell>
                        <Table.Cell textAlign='center'>{d.content_type.name}</Table.Cell>
                        {this.ifData("status") && (
                          <Table.Cell textAlign="center">
                            <StatusPill
                              color={d.status.color}
                              content={d.status.name}
                            />
                          </Table.Cell>
                        )}
                      </Table.Row>
                    )
                )}
            </Table.Body>
          </Table>
        </div>
      </div>
    );
  }
}
