import React, { Component } from "react";
import { organizations } from "../../store/actions";
import { connect } from "react-redux";
import { Dropdown } from "semantic-ui-react";

class KeywordOrganizationSelect extends Component {

  componentDidMount() {
    !this.props.organizations[0] && this.props.fetchOrganizations();
  }

  handleSelect = (event, { value }) => {
    let url = `/content-generation/keywords/${value}`;
    this.props.history.push(url);
  };

  render() {
    return (
      <div className="KeywordOrganizationSelect">
        {this.props.organizations[0] && (
          <Dropdown
            selectOnBlur={false}
            selection
            labeled
            button
            className="icon"
            icon="building"
            placeholder={this.props.placeholder}
            options={this.props.organizations.map(organization => ({
              key: organization.id,
              text: organization.dba_name,
              value: organization.slug
            }))}
            onChange={this.handleSelect}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    organizations: state.organizations.organizations,
    isLoadingOrgs: state.organizations.isLoading,
    orgsLoaded: state.organizations.isLoaded,
    errors: state.organizations.errors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchOrganizations: () => {
      return dispatch(organizations.fetchOrganizations());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KeywordOrganizationSelect);
