import React, { Component } from "react";
import { Button, Icon, Tab, Label } from "semantic-ui-react";
import { connect } from "react-redux";
import { users, organizations } from "../../store/actions";
import "./UserData.css";
import FormHeader from "../FormHeader/FormHeader";
import moment from "moment";
import { UserDataItems } from "./UserDataItems/UserDataItems";
import { UserDataWriterDetails } from "./UserDataWriterDetails/UserDataWriterDetails";
import UserDataAccounts from "./UserDataAccounts/UserDataAccounts";
import { UserPasswordManagement } from "./UserPasswordManagement/UserPasswordManagement";
import { hasPermissions } from "../../utility";
import UserReAssignForm from "../../forms/UserReAssignForm/UserReAssignForm";
import BasicModal from "../BasicModal/BasicModal";

class UserData extends Component {
  state = {
    isEditingUser: false,
    viewingDetails: true,
    isAttemptingDeletion: false,
    showSuccessMessage: false,
  };
  componentDidMount() {
    !this.props.organizations[0] && this.props.fetchOrganizations();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.selectedUser &&
      this.props.selectedUser.id !== prevProps.selectedUser.id
    ) {
      this.setState({ isEditingUser: false });
    }
    if (
      prevProps.reAssigningInProgress &&
      !this.props.reAssigningInProgress &&
      this.props.didReAssign
    ) {
      // this.deleteUser(this.props.selectedUser.id).then(() => {
      //   this.handleCancelAssignContent();
      //   this.props.cancelAction();
      // });
      this.setState({ showSuccessMessage: true });
      setTimeout(() => {
        this.deleteUser(this.props.selectedUser.id).then(() => {
          console.log("fire handleFinishUserDeletion here");
          this.setState({ showSuccessMessage: false });
          return this.handleFinishUserDeletion();
        });
      }, 5000)
    }
  }

  handleFinishUserDeletion = () => {
    console.log("should of changed showSuccessMessage here");
    this.handleCancelAssignContent();
    this.props.cancelAction();
  };
  deleteUser = async (userId) => {
    await this.props.deleteUser(userId);
    return true;
  };
  handleCancelEdit = () => {
    this.setState({ isEditingUser: false });
  };
  handleReAssignContent = () => {
    this.setState({ isAttemptingDeletion: true });
  };
  handleCancelAssignContent = () => {
    this.setState({ isAttemptingDeletion: false });
  };
  render() {
    const renderRating = (rating) => {
      let stars = [];
      for (let i = 0; i < rating; i++) {
        stars.push(<Icon key={i} color="blue" name="star" />);
      }
      return stars;
    };

    const handleTabChange = (e, data) => {
      let activeTab = data.panes[data.activeIndex];
      if (
        this.props.handleUnEnlargeSidebar &&
        this.props.handleEnlargeSidebar
      ) {
        if (activeTab.menuItem.key === "accounts") {
          this.setState({ viewingDetails: false, isEditingUser: false });
          this.props.handleEnlargeSidebar();
        } else if (activeTab.menuItem.key === "details") {
          this.setState({ viewingDetails: true });
          this.props.handleUnEnlargeSidebar();
        } else {
          this.setState({ viewingDetails: false, isEditingUser: false });
          this.props.handleUnEnlargeSidebar();
        }
      }
    };

    const { selectedUser } = this.props;

    const panes = [
      {
        menuItem: {
          key: "details",
          icon: "unordered list",
          content: "Details",
        },
        render: () => (
          <Tab.Pane>
            <UserDataItems
              selectedUser={this.props.selectedUser}
              isEditingUser={this.state.isEditingUser}
              items={items}
            />
          </Tab.Pane>
        ),
      },
      selectedUser.org_roles
        ? {
            menuItem: {
              key: "accounts",
              icon: "user",
              content: "Accounts",
            },
            render: () => (
              <Tab.Pane>
                <UserDataAccounts
                  accountUser={this.props.selectedUser}
                  accounts={this.props.selectedUser.org_roles}
                />
              </Tab.Pane>
            ),
          }
        : null,
      selectedUser.writer
        ? {
            menuItem: {
              key: "writerDetails",
              icon: "pencil square",
              content: "Writer Details",
            },
            render: () => (
              <Tab.Pane>
                <UserDataWriterDetails
                  selectedUser={this.props.selectedUser}
                  renderRating={renderRating}
                />
              </Tab.Pane>
            ),
          }
        : null,
      hasPermissions(this.props.user, "delete_user", true) &&
      !selectedUser.is_staff
        ? {
            menuItem: {
              key: "manage",
              icon: "cogs",
              content: "Manage",
            },
            render: () => (
              <Tab.Pane>
                <UserPasswordManagement
                  selectedUser={this.props.selectedUser}
                />
              </Tab.Pane>
            ),
          }
        : null,
    ];

    const items = [
      {
        key: "username",
        name: "Username",
        data: selectedUser.username,
        editable: false,
        for: "username",
        type: "text",
      },
      {
        key: "name",
        name: "Name",
        data: selectedUser.name,
        editable: true,
        updateMethod: this.props.updateUser,
        for: "name",
        type: "text",
      },
      {
        key: "email",
        name: "Email",
        data: selectedUser.email,
        editable: false,
        for: "email",
        type: "email",
      },
      {
        key: "phoneNumber",
        name: "Phone Number",
        data: selectedUser.phone_number,
        editable: true,
        updateMethod: this.props.updateUser,
        for: "phone_number",
        type: "tel",
      },
      {
        key: "role",
        name: "Role",
        data: selectedUser.default_role.name,
        inputData: selectedUser.default_role.id,
        editable: true,
        updateMethod: this.props.updateUser,
        for: "default_role",
        type: "text",
        options:
          this.props.groups[0] &&
          this.props.groups.map((group) => ({
            key: group.id,
            text: group.name,
            value: group.id,
          })),
      },
      {
        key: "primaryOrganization",
        name: "Primary Organization",
        data:
          selectedUser.user_preferences &&
          selectedUser.user_preferences.default_organization.dba_name,
        inputData:
          selectedUser.user_preferences &&
          selectedUser.user_preferences.default_organization.id,
        editable: false,
        updateMethod: this.props.updateUserPreferences,
        for: "default_organization",
        parentFor: "user_preferences",
        type: "text",
        options:
          this.props.organizations[0] &&
          this.props.organizations.map((organization) => ({
            key: organization.id,
            text: organization.dba_name,
            value: organization.id,
          })),
      },
      {
        key: "title",
        name: "Title",
        data: selectedUser.title,
        editable: true,
        updateMethod: this.props.updateUser,
        for: "title",
        type: "text",
      },
      {
        key: "lastLogin",
        name: "Last Login",
        data: selectedUser.last_login
          ? moment(selectedUser.last_login).format("MMMM DD, YYYY")
          : "Never",
        editable: false,
        for: "last_login",
        type: "date",
      },
    ];

    return (
      <div className="UserData">
        <FormHeader
          headerText={
            <span>
              {selectedUser.name ? selectedUser.name : "User Info"}{" "}
              {selectedUser.is_staff && (
                <Label color="blue" className="floatUp" size="tiny" horizontal>
                  Agency Staff
                </Label>
              )}
            </span>
          }
          icon="user circle"
        >
          {hasPermissions(this.props.user, "delete_user") && !selectedUser.is_staff && (
            <React.Fragment>
              <Button
                onClick={() => this.handleReAssignContent()}
                compact
                size="mini"
                negative
                icon
              >
                <Icon name="trash alternate" />
              </Button>
              <BasicModal
                title="Re-Assign Content and Delete User"
                isOpen={this.state.isAttemptingDeletion}
                handleClose={this.handleCancelAssignContent}
              >
                <UserReAssignForm
                  prevUser={selectedUser}
                  handleCancelReAssign={this.handleCancelAssignContent}
                  showSuccessMessage={this.state.showSuccessMessage}
                  withActions
                />
              </BasicModal>
            </React.Fragment>
          )}
          {this.state.viewingDetails &&
            (this.state.isEditingUser ? (
              <Button
                compact
                negative
                icon
                size="mini"
                onClick={() => this.handleCancelEdit()}
              >
                <Icon name="cancel" />
              </Button>
            ) : (
              <Button
                compact
                positive
                icon
                size="mini"
                onClick={() => this.setState({ isEditingUser: true })}
              >
                <Icon name="pencil alternate" />
              </Button>
            ))}
          <Button compact size="mini" onClick={() => this.props.cancelAction()}>
            Cancel
          </Button>
        </FormHeader>
        <section className="UserData--container">
          <Tab
            onTabChange={handleTabChange}
            className="noBorder"
            menu={{ secondary: true, pointing: true }}
            panes={panes}
          />
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    reAssigningInProgress: state.contentGeneration.reAssigningInProgress,
    didReAssign: state.contentGeneration.didReAssign,
    reAssignMessage: state.contentGeneration.reAssignMessage,
    isDeletingUser: state.users.isDeletingUser,
    didDeleteUser: state.users.didDeleteUser,
    groups: state.auth.groups,
    organizations: state.organizations.organizations,
    errors: state.auth.errors,
    contentGenerationMessages: state.contentGeneration.messages,
    contentGenerationErrors: state.contentGeneration.errors,
    messages: state.users.messages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrganizations: () => dispatch(organizations.fetchOrganizations()),
    updateUser: (id, data, message) =>
      dispatch(users.updateUser(id, data, message)),
    updateUserPreferences: (id, data, message) =>
      dispatch(users.updateUserPreferences(id, data, message)),
    deleteUser: (userId) => dispatch(users.deleteUser(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserData);
