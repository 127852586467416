import React from "react";
import "./ChecklistMessage.css";
import { Message, Icon } from "semantic-ui-react";

const ChecklistMessage = props => {
  return (
    <div className="ChecklistMessage">
      <Message icon size="small" positive>
      <Icon name="check circle outline" />
        <span className="ChecklistMessage--content">{props.children}</span>
      </Message>
    </div>
  );
};

export default ChecklistMessage;
