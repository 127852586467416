export const sortOptions = [
    {
        key: 'leads_difference_asc',
        value: 'overallLeadsDifference',
        text: 'Leads Over Time | ASC',
        asc: true,
    },
    {
        key: 'leads_difference_desc',
        value: 'overallLeadsDifference',
        text: 'Leads Over Time | DESC',
        asc: false,
    },
    {
        key: 'traffic_difference_asc',
        value: 'overallTrafficDifference',
        text: 'Traffic Over Time | ASC',
        asc: true,
    },
    {
        key: 'traffic_difference_desc',
        value: 'overallTrafficDifference',
        text: 'Traffic Over Time | DESC',
        asc: false,
    },
    {
        key: 'overdue_approvals_asc',
        value: 'overdue_approvals.length',
        text: 'Overdue Approvals | ASC',
        asc: true,
    },
    {
        key: 'overdue_approvals_desc',
        value: 'overdue_approvals.length',
        text: 'Overdue Approvals | DESC',
        asc: false,
    },
    {
        key: 'created_at_asc',
        value: 'account.properStartDate',
        text: 'Months into Contract | ASC',
        asc: true,
    },
    {
        key: 'created_at_desc',
        value: 'account.properStartDate',
        text: 'Months into Contract | DESC',
        asc: false,
    },
    {
        key: 'desktop_page_speed_data_asc',
        value: 'desktop_page_speed_data.scores.average_score',
        text: 'Desktop Score | ASC',
        asc: true,
    },
    {
        key: 'desktop_page_speed_data_desc',
        value: 'desktop_page_speed_data.scores.average_score',
        text: 'Desktop Score | DESC',
        asc: false,
    },
    {
        key: 'mobile_page_speed_data_asc',
        value: 'mobile_page_speed_data.scores.average_score',
        text: 'Mobile Score | ASC',
        asc: true,
    },
    {
        key: 'mobile_page_speed_data_desc',
        value: 'mobile_page_speed_data.scores.average_score',
        text: 'Mobile Score | DESC',
        asc: false,
    },
    {
        key: 'dba_name_asc',
        value: 'account.dba_name',
        text: 'Account Name | ASC',
        asc: true,
    },
    {
        key: 'dba_name_desc',
        value: 'account.dba_name',
        text: 'Account Name | DESC',
        asc: false,
    },
]