import * as Sentry from "@sentry/browser";
import React, { Component } from "react";
import { Button, Header, Icon } from "semantic-ui-react";
import "./ErrorBoundary.css";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    console.log(this.state);
    this.setState({ error });
    if (!process.env.REACT_APP_DEVELOPMENT) {
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
    }
  }

  render() {
    if (this.state.error) {
      //render fallback UI
      return (
        <div className="ErrorBoundary">
          <Header icon as="h1">
            <Icon name="bug" /> Whoops! Something horrible has happened, Run!!
          </Header>
          <Header.Subheader>
            Looks like we messed up. We have automatic error reporting and
            logging, but if you want to submit this yourself feel free!
          </Header.Subheader>
          <div className="ErrorBoundary--actions">
            {/* <Button size="large" icon onClick={() => this.handleBack()}>
              <Icon name="arrow circle left" /> Back to wence I came
            </Button> */}
            <Button size="large" color="blue" onClick={() => Sentry.showReportDialog()}>
              Report Feedback
            </Button>
          </div>
        </div>
      );
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
