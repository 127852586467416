import React, { Component } from "react";
import "./PopupNotification.css";
import { TransitionablePortal, Header, Icon, Segment } from "semantic-ui-react";

export default class PopupNotification extends Component {
  state = { open: false };

  componentDidMount() {
    if (this.props.open) {
      this.setState({ open: true });
    }
  }

  handleClick = () => this.setState((prevState) => ({ open: !prevState.open }));
  handleClose = () => this.setState({ open: false });

  render() {
    const { open } = this.state;
    return (
      <div className="PopupNotification">
        {this.props.trigger && (
          <div
            onClick={this.handleClick}
            className="PopupNotification--trigger"
          >
            {this.props.trigger}
          </div>
        )}
        <TransitionablePortal
          transition={{
            animation: this.props.animation ? this.props.animation : "drop",
            duration: this.props.duration ? this.props.duration : 500,
          }}
          onClose={this.handleClose}
          open={open}
        >
          <Segment
            raised={this.props.raised}
            className={`PopupNotification--body${
              this.props.positive ? " positive" : ""
            }${this.props.negative ? " negative" : ""}`}
            style={{
              right: this.props.right ? this.props.right : "55%",
              position: "fixed",
              top: this.props.top ? this.props.top : "40%",
			  zIndex: 1000,
			  width: this.props.width ? this.props.width : 'auto'
            }}
          >
            {this.props.withClose && (
              <div
                onClick={this.handleClose}
                className="PopupNotification--closeButton"
              >
                <span>&times;</span>
              </div>
            )}
            {this.props.header && (
              <Header className="PopupNotification--header" as={this.props.headerSize ? this.props.headerSize : "h3"}>
                {this.props.icon && (
                  <Icon
                    {...{
                      ...(this.props.negative && { color: "red" }),
                      ...(this.props.positive && { color: "green" }),
                    }}
                    name={this.props.icon}
                  />
                )}
                <Header.Content>{this.props.header}</Header.Content>
              </Header>
            )}
            <div className="PopupNotification--content">
              {this.props.children}
              {this.props.closeTrigger && (
                <div
                  onClick={this.handleClose}
                  className="PopupNotification--closeTrigger"
                >
                  {this.props.closeTrigger}
                </div>
              )}
            </div>
          </Segment>
        </TransitionablePortal>
      </div>
    );
  }
}
