export const mockData = [
	{
		month: 'May 2019',
		sources: [
			{
				value: '88',
				source: '(Other)'
			},
			{
				value: '195',
				source: 'Direct'
			},
			{
				value: '673',
				source: 'Organic Search'
			},
			{
				value: '51',
				source: 'Paid Search'
			},
			{
				value: '39',
				source: 'Referral'
			},
			{
				value: '24',
				source: 'Social'
			}
		],
		month_year: 2019,
		month_month: 5
	},
	{
		month: 'Jun 2019',
		sources: [
			{
				value: '63',
				source: '(Other)'
			},
			{
				value: '166',
				source: 'Direct'
			},
			{
				value: '597',
				source: 'Organic Search'
			},
			{
				value: '82',
				source: 'Paid Search'
			},
			{
				value: '37',
				source: 'Referral'
			},
			{
				value: '37',
				source: 'Social'
			}
		],
		month_year: 2019,
		month_month: 6
	},
	{
		month: 'Jul 2019',
		sources: [
			{
				value: '44',
				source: '(Other)'
			},
			{
				value: '201',
				source: 'Direct'
			},
			{
				value: '787',
				source: 'Organic Search'
			},
			{
				value: '96',
				source: 'Paid Search'
			},
			{
				value: '39',
				source: 'Referral'
			},
			{
				value: '54',
				source: 'Social'
			}
		],
		month_year: 2019,
		month_month: 7
	},
	{
		month: 'Aug 2019',
		sources: [
			{
				value: '41',
				source: '(Other)'
			},
			{
				value: '302',
				source: 'Direct'
			},
			{
				value: '828',
				source: 'Organic Search'
			},
			{
				value: '79',
				source: 'Paid Search'
			},
			{
				value: '44',
				source: 'Referral'
			},
			{
				value: '56',
				source: 'Social'
			}
		],
		month_year: 2019,
		month_month: 8
	},
	{
		month: 'Sep 2019',
		sources: [
			{
				value: '34',
				source: '(Other)'
			},
			{
				value: '180',
				source: 'Direct'
			},
			{
				value: '883',
				source: 'Organic Search'
			},
			{
				value: '95',
				source: 'Paid Search'
			},
			{
				value: '40',
				source: 'Referral'
			},
			{
				value: '43',
				source: 'Social'
			}
		],
		month_year: 2019,
		month_month: 9
	},
	{
		month: 'Oct 2019',
		sources: [
			{
				value: '30',
				source: '(Other)'
			},
			{
				value: '190',
				source: 'Direct'
			},
			{
				value: '1069',
				source: 'Organic Search'
			},
			{
				value: '34',
				source: 'Paid Search'
			},
			{
				value: '123',
				source: 'Referral'
			},
			{
				value: '37',
				source: 'Social'
			}
		],
		month_year: 2019,
		month_month: 10
	}
];
