import React from 'react';
import { PDFExport } from '@progress/kendo-react-pdf';
import { Button, Icon } from 'semantic-ui-react';
import ContentPopup from '../../components/ContentPopup/ContentPopup';
import './PDFView.css';

export class PDFView extends React.Component {
	state = {
		closed: null,
		didDownload: false
	};
	pdfExportComponent;
	PDFContainer;

	handleClose = () => {
		this.setState({ closed: true });
		setTimeout(() => this.setState({ closed: null }), 10);
	};

	handleDownload = async () => {
		await this.pdfExportComponent.save();
		return this.setState({ didDownload: true });
	};

	render() {
		const PDFActions = (
			<div className="PDFView--Actions">
				<div className="PDFView--disclaimer">
					<span>If you are having issues downloading the PDF please try Firefox or Safari.</span>
				</div>
				<Button
					className="PDFView-button"
					size="mini"
					positive
					icon
					onClick={() => {
						this.handleDownload();
					}}
				>
					Download PDF
					<Icon name="file pdf" />
				</Button>
				<Button onClick={() => this.handleClose()} size="mini" icon negative>
					Close
					<Icon name="close" />
				</Button>
			</div>
		);

		const mainView = (
			<PDFExport
				fileName={this.props.title ? this.props.title : 'PDF Export'}
				title={this.props.title ? this.props.title : 'PDF Export'}
				paperSize={this.props.paperSize ? this.props.paperSize : 'auto'}
				scale={this.props.scale ? this.props.scale : 1}
				margin="0.5cm"
				proxyURL="http://localhost:8000"
				forcePageBreak=".page-break"
				keepTogether=".keepTogether"
				ref={(component) => (this.pdfExportComponent = component)}
			>
				<div className="PDFView--content">
					{this.props.pages ? (
						this.props.pages.map((page, i) => (
							<div
								key={i}
								ref={(PDFContainer) => (this.PDFContainer = PDFContainer)}
								className={`PDFView--page${i !== 0 ? ' page-break' : ''}`}
							>
								{page.sections ? (
									page.sections.map((section, index) => (
										<div key={index} className="PDFView-pageSection">
											{section.content}
										</div>
									))
								) : (
									<div className="PDFView-pageSection">
										<p>Please add one or more sections to this page to render output</p>
									</div>
								)}
							</div>
						))
					) : (
						<div className="PDFView--page">
							<div className="PDFView-pageSection">
								<p>Please add one or more pages render output</p>
							</div>
						</div>
					)}
				</div>
			</PDFExport>
		);

		return (
			<div className="PDFView">
				{this.props.withPreview ? (
					<ContentPopup
						closed={this.state.closed}
						className="PDFView-popup"
						header="PDF Preview"
						headerActions={PDFActions}
						trigger={this.props.trigger}
					>
						{mainView}
					</ContentPopup>
				) : (
					<div className="PDFView--container">
						{PDFActions}
						{mainView}
					</div>
				)}
			</div>
		);
	}
}

export default PDFView;
