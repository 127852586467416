import React, { Component } from 'react';
import './ContentPopup.css';
import { TransitionablePortal, Header, Icon } from 'semantic-ui-react';

export default class ContentPopup extends Component {
	state = { open: false };

	componentDidMount() {
		if (this.props.open) {
			this.setState({ open: true });
		}
		if (this.props.closed) {
			this.setState({ open: false });
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.closed && this.state.open) {
			this.handleClose()
		}
	}

	handleClick = () => this.setState((prevState) => ({ open: !prevState.open }));
	handleClose = () => {
		this.setState({ open: false });
		this.props.didCloseWindow && this.props.didCloseWindow();
	};

	render() {
		const { open } = this.state;
		return (
			<div className={`ContentPopup${this.props.className ? ` ${this.props.className}` : ''}`}>
				{this.props.trigger && (
					<div onClick={this.handleClick} className="ContentPopup--trigger">
						{this.props.trigger}
					</div>
				)}
				<TransitionablePortal
					transition={{ animation: 'drop', duration: 500 }}
					onClose={this.handleClose}
					open={open}
				>
					<div
						className={`ContentPopup--body${this.props.positive ? ' positive' : ''}${this.props.negative
							? ' negative'
							: ''}${this.props.bodyClass ? ` ${this.props.bodyClass}` : ''}`}
						style={{ left: '35%', position: 'fixed', top: '10%', zIndex: 1000 }}
					>
						{this.props.header && (
							<div className="ContentPopup--headerArea">
								<Header className="ContentPopup--header" as="h3">
									{this.props.icon && (
										<Icon
											{...{
												...(this.props.negative && { color: 'red' }),
												...(this.props.positive && { color: 'green' })
											}}
											name={this.props.icon}
										/>
									)}
									<Header.Content>{this.props.header}</Header.Content>
								</Header>
								{this.props.headerActions && (
									<div className="ContentPopup--headerActions">{this.props.headerActions}</div>
								)}
							</div>
						)}
						<div className="ContentPopup--content">
							{this.props.children}
							{this.props.closeTrigger && (
								<div onClick={this.handleClose} className="ContentPopup--closeTrigger">
									{this.props.closeTrigger}
								</div>
							)}
						</div>
						{this.props.footerActions && (
							<div className="ContentPopup--footer">
								<div className="ContentPopup--footerActions">{this.props.footerActions}</div>
							</div>
						)}
					</div>
				</TransitionablePortal>
			</div>
		);
	}
}
