import React, { Component } from 'react';
import SortedTable from '../../../components/SortedTable/SortedTable';
import './ReportingMonth.css';
import { connect } from 'react-redux';
import { organizations, reporting, contentGeneration } from '../../../store/actions';
import WithSidebar from '../../../hoc/WithSidebar/WithSidebar';
import ActionHeader from '../../../components/ActionHeader/ActionHeader';
import { Button, Loader, Icon } from 'semantic-ui-react';
import moment from 'moment';
import { decamelize } from '../../../utility';
import ReportDetail from './ReportDetail/ReportDetail';
import ReportBulkForm from './ReportBulkForm/ReportBulkForm';
import { NavLink } from 'react-router-dom';
import ReportingMonthTable from './ReportingMonthTable/ReportingMonthTable';
import ReportingAdminBar from '../ReportingAdminBar/ReportingAdminBar';
import { hasPermissions } from '../../../utility';

class ReportingMonth extends Component {
	state = {
		selectedReport: null,
		planningMonth: null,
		selectedReports: [],
		betaView: false
	};

	componentDidMount() {
		this.props.planningMonths[0] &&
			this.setState({
				planningMonth: this.props.planningMonths.find(
					(pm) =>
						pm.month === Number(this.props.match.params.month) &&
						pm.year === Number(this.props.match.params.year)
				)
			});
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.props.planningMonths !== prevProps.planningMonths ||
			this.props.match.params.month !== prevProps.match.params.month
		) {
			this.setState({
				planningMonth: this.props.planningMonths.find(
					(pm) =>
						pm.month === Number(this.props.match.params.month) &&
						pm.year === Number(this.props.match.params.year)
				)
			});
		}
		if (this.props.reports !== prevProps.reports && this.state.selectedReport) {
			this.setReport();
		}
	}

	setReport = async () => {
		const updatedReport = await this.props.reports.find((report) => report.id === this.state.selectedReport.id);
		return this.setState({ selectedReport: updatedReport });
	};

	getMonth = () => {
		const month = moment(this.state.planningMonth.month, 'MM').format('MMMM');
		const year = this.state.planningMonth.year;
		return `${month} ${year}`;
	};

	getOrganization = (orgID) => {
		const org = this.props.organizations[0] && this.props.organizations.find((org) => org.id === orgID);
		return org ? org.dba_name : null;
	};

	getOrganizationSlug = (orgID) => {
		const org = this.props.organizations[0] && this.props.organizations.find((org) => org.id === orgID);
		return org ? org.slug : null;
	};

	handleHasOrgPresentationTemplate = (orgID) => {
		const org = this.props.organizations[0] && this.props.organizations.find((org) => org.id === orgID);
		if (org && org.monthly_report_template) {
			return true;
		} else {
			return false;
		}
	};

	getStaff = (userID, detail) => {
		const user = this.props.contentStaff.find((user) => user.id === userID);
		if (user) {
			return detail === true ? user : user.name;
		} else {
			return detail === true ? null : '-';
		}
	};

	getStatus = (status, raw) => {
		return raw ? decamelize(status, ' ') : <span className="reportStatus caps">{decamelize(status, ' ')}</span>;
	};

	getReportLink = (link) => {
		return link ? (
			<a href={link} className="reportLink" target="_blank" rel="noopener noreferrer">
				Link <Icon name="external" />
			</a>
		) : (
			'-'
		);
	};

	renderReportButton = (orgID) => {
		const org = this.props.organizations[0] && this.props.organizations.find((org) => org.id === orgID);
		return orgID ? (
			<NavLink to={`/reporting/${this.state.planningMonth.year}/${this.state.planningMonth.month}/${org.slug}`}>
				<Button size="mini" color="blue">
					View Report
				</Button>
			</NavLink>
		) : (
			'Report does not exist yet.'
		);
	};

	selectReport = (e, report, sortedData) => {
		let inBetween = false;
		// const reports = this.props.reports.filter(
		//   report => report.month === this.state.planningMonth.id
		// );
		if (e.shiftKey) {
			const reportsToAdd = [];
			sortedData.forEach((r) => {
				const exists = this.state.selectedReports.find((report) => report.id === r.id);
				if (r.id === report.id || r === this.state.selectedReport) {
					inBetween = !inBetween;
				}
				if (inBetween && !exists) {
					reportsToAdd.push(r);
				}
			});
			let isInArray = this.state.selectedReports.find((r) => r.id === report.id);
			!isInArray && reportsToAdd.push(report);
			reportsToAdd[0] &&
				this.setState({
					selectedReports: this.state.selectedReports.concat(reportsToAdd)
				});
		} else if (e.metaKey) {
			const foundReport =
				this.state.selectedReports[0] && this.state.selectedReports.find((r) => r.id === report.id);
			if (foundReport) {
				let updatedReports = this.state.selectedReports.filter((r) => r.id !== report.id);
				return this.setState({ selectedReports: updatedReports });
			} else {
				return this.setState({
					selectedReports: this.state.selectedReports.concat(report)
				});
			}
		} else {
			this.setState({ selectedReports: [] });
		}
		this.setState({ selectedReport: report });
	};

	handleUpdateReport = async (field, value, reportID) => {
		const _updateReportField = async () => {
			await this.setState({
				selectedReport: {
					...this.state.selectedReport,
					[field]: value
				}
			});
		};
		if (reportID) {
			const report = (await this.props.reports[0]) && this.props.reports.find((r) => r.id === reportID);

			if (report) {
				return this.props.updateReport(report.id, { ...report, [field]: value });
			}
		} else if (this.state.selectedReport) {
			if (Object.keys(this.state.selectedReport).includes(field)) {
				await _updateReportField();
				return this.props.updateReport(this.state.selectedReport.id, this.state.selectedReport);
			}
		}
	};

	handleReportSend = (reportID) => {
		if (reportID) {
			const report = this.props.reports[0] && this.props.reports.find((r) => r.id === reportID);
			this.props.sendReport(report.id);
		} else if (this.state.selectedReport) {
			if (
				this.state.selectedReport.report_url &&
				(this.state.selectedReport.status === 'finalReview' || this.state.selectedReport.status === 'sent')
			) {
				this.props.sendReport(this.state.selectedReport.id);
			}
		}
	};

	cancelAction = () => {
		this.setState({
			selectedReport: null,
			selectedReports: []
		});
	};

	render() {
		let reports =
			this.props.reports[0] &&
			this.state.planningMonth &&
			this.props.reports.filter((report) => report.month === this.state.planningMonth.id);
		return (
			<div className="ReportingMonth">
				<ActionHeader headerText={this.state.planningMonth ? `${this.getMonth()}` : 'Reporting Month View'}>
					{this.state.betaView &&
						this.state.planningMonth &&
						hasPermissions(this.props.user, 'update_monthlyreport', true) && (
							<div className="ReportingMonth--adminActions">
								<ReportingAdminBar planningMonth={this.state.planningMonth} />
							</div>
						)}
					<Button
						onClick={() => this.setState({ betaView: !this.state.betaView })}
						size="tiny"
						color="blue"
						icon
					>
						Switch to {this.state.betaView ? 'Regular View' : 'Beta View'} <Icon name="repeat" />
					</Button>
				</ActionHeader>
				{this.state.betaView ? (
					<ReportingMonthTable
						{...this.props}
						data={this.props.reports.filter((report) => report.month === this.state.planningMonth.id)}
						renderOrganization={this.getOrganization}
						renderStatus={this.getStatus}
						getOrganizationSlug={this.getOrganizationSlug}
						handleUpdateReport={this.handleUpdateReport}
						handleReportSend={this.handleReportSend}
						createReportPresentation={this.props.createReportPresentation}
						reCreateReportPresentation={this.props.reCreateReportPresentation}
						handleHasOrgPresentationTemplate={this.handleHasOrgPresentationTemplate}
					/>
				) : (
					<WithSidebar
						leftWidth={this.state.selectedReport || this.state.selectedReports[0] ? 11 : 16}
						rightWidth={this.state.selectedReport || this.state.selectedReports[0] ? 5 : 0}
						isShowing={this.state.selectedReport || this.state.selectedReports[0]}
						sidebarComponent={
							<div className="ReportingMonth--sidebar">
								<ActionHeader
									size="h4"
									headerText={
										this.state.selectedReports[0] ? (
											'Bulk Report Update'
										) : (
											this.state.selectedReport &&
											this.getOrganization(this.state.selectedReport.organization)
										)
									}
									icon="pie graph"
								>
									<Button size="tiny" basic compact onClick={() => this.cancelAction()}>
										Close
									</Button>
								</ActionHeader>
								{this.state.selectedReports[0] ? (
									<ReportBulkForm
										reports={this.state.selectedReports}
										handleUpdateReport={this.props.updateReport}
										handleReportSend={this.handleReportSend}
										contentStaff={this.props.contentStaff}
									/>
								) : (
									<ReportDetail
										report={this.state.selectedReport}
										getMonth={this.getMonth}
										getOrganization={this.getOrganization}
										getStaff={this.getStaff}
										getStatus={this.getStatus}
										getReportLink={this.getReportLink}
										handleUpdateReport={this.handleUpdateReport}
										handleReportSend={this.handleReportSend}
										renderReportButton={this.renderReportButton}
									/>
								)}
							</div>
						}
					>
						<div className="ReportingMonth--main">
							{reports && reports[0] && this.state.planningMonth ? (
								<SortedTable
									compact
									basic
									size="small"
									data={this.props.reports.filter(
										(report) => report.month === this.state.planningMonth.id
									)}
									selectedItem={this.state.selectedReport}
									selectedItems={this.state.selectedReports}
									primarySearchField="organization"
									handleSearchSelect={this.selectReport}
									handleRowSelect={this.selectReport}
									fields={{
										report_url: 'Report URL',
										status: 'Status',
										approver: 'Approver',
										creator: 'Creator',
										organization: 'Organization'
									}}
									transforms={{
										month: this.getMonth,
										organization: this.getOrganization,
										report_url: this.getReportLink,
										status: this.getStatus,
										creator: this.getStaff,
										approver: this.getStaff
									}}
								/>
							) : this.props.reportsLoading ? (
								<Loader active size="large">
									Loading Reports
								</Loader>
							) : (
								<p>No Reports for this month currently.</p>
							)}
						</div>
					</WithSidebar>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		organizations: state.organizations.organizations,
		organizationsLoading: state.organizations.isLoading,
		planningMonths: state.contentGeneration.planningMonths,
		planningMonthsLoading: state.contentGeneration.isLoadingMonths,
		reports: state.reporting.reports,
		reportsLoading: state.reporting.isLoading,
		contentStaff: state.contentGeneration.contentStaff
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchOrganizations: () => dispatch(organizations.fetchOrganizations()),
		fetchReports: () => dispatch(reporting.fetchReports()),
		fetchPlanningMonths: () => dispatch(contentGeneration.fetchPlanningMonths()),
		updateReport: (id, report) => dispatch(reporting.updateReport(id, report)),
		sendReport: (id) => dispatch(reporting.sendReport(id)),
		createReportPresentation: (id, message) => dispatch(reporting.createReportPresentation(id, message)),
		reCreateReportPresentation: (id, message) => dispatch(reporting.reCreateReportPresentation(id, message))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportingMonth);
