import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
	currentUser: {
		data: null,
		isLoading: false,
		isLoaded: false,
		errors: null
	},
	currentPost: {
		data: null,
		isLoading: false,
		isLoaded: false,
		errors: null
	},
	posts: {
		data: [],
		isLoading: false,
		isLoaded: false,
		errors: null
    },
    allAccountPosts: {
        data: [],
        isLoading: false,
		isLoaded: false,
		progressSuccess: false,
		errors: null
    },
	messages: []
};

const addMessage = (state, action) => {
	return updateObject(state, {
		messages: state.messages.concat(action.message)
	});
};

const removeMessage = (state, action) => {
	let messagesList = state.messages.slice();
	messagesList.splice(0, 1);
	return updateObject(state, { messages: messagesList });
};


// Single Account Posts
const startFetchPosts = (state, action) => {
	return updateObject(state, {
		posts: {
			...state.posts,
			isLoading: true,
			isLoaded: false,
			errors: null
		}
	});
};
const fetchPostsSuccess = (state, action) => {
	return updateObject(state, {
		posts: {
			...state.posts,
			data: state.posts.data.concat(action.posts),
			isLoading: false,
			isLoaded: true,
			errors: null
		}
	});
};
const fetchPostsFailure = (state, action) => {
	return updateObject(state, {
		posts: {
			...state.posts,
			isLoading: false,
			isLoaded: false,
			errors: action.errors
		}
	});
};

// Single Post
const startFetchPost = (state, action) => {
	return updateObject(state, {
		currentPost: {
			...state.currentPost,
			isLoading: true,
			isLoaded: false,
			errors: null
		}
	});
};
const fetchPostSuccess = (state, action) => {
	return updateObject(state, {
		currentPost: {
			...state.currentPost,
			data: action.post,
			isLoading: false,
			isLoaded: true,
			errors: null
		}
	});
};
const fetchPostFailure = (state, action) => {
	return updateObject(state, {
		currentPost: {
			...state.currentPost,
			isLoading: false,
			isLoaded: false,
			errors: action.errors
		}
	});
};

// Bulk Posts

const startFetchBulkAccountPosts = (state, action) => {
	return updateObject(state, {
		allAccountPosts: {
			...state.allAccountPosts,
			isLoading: true,
			isLoaded: false,
			errors: null
		}
	});
};

const fetchSingleBulkAccountPostsSuccess = (state, action) => {
	return updateObject(state, {
		allAccountPosts: {
			...state.allAccountPosts,
			data: state.allAccountPosts.data.concat(action.accountData),
			progressSuccess: true,
		}
	})
}

const fetchBulkAccountPostsSuccess = (state, action) => {
	return updateObject(state, {
		allAccountPosts: {
			...state.allAccountPosts,
			isLoading: false,
			isLoaded: true,
			progressSuccess: false,
			errors: null
		}
	});
};
const fetchBulkAccountPostsFailure = (state, action) => {
	return updateObject(state, {
		allAccountPosts: {
			...state.allAccountPosts,
			isLoading: false,
			isLoaded: false,
			progressSuccess: false,
			errors: action.errors
		}
	});
};

// WP user
const startFetchWPUser = (state, action) => {
	return updateObject(state, {
		currentUser: {
			...state.currentUser,
			isLoading: true,
			isLoaded: false,
			errors: null
		}
	});
};

const fetchWPUserSuccess = (state, action) => {
	return updateObject(state, {
		currentUser: {
			...state.currentUser,
			data: action.user,
			isLoading: false,
			isLoaded: true,
			errors: null
		}
	});
};
const fetchWPUserFailure = (state, action) => {
	return updateObject(state, {
		currentUser: {
			...state.currentUser,
			isLoading: false,
			isLoaded: false,
			errors: action.errors
		}
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.ADD_MESSAGE:
			return addMessage(state, action);
		case actionTypes.REMOVE_MESSAGE:
			return removeMessage(state, action);
		case actionTypes.START_FETCH_POSTS:
			return startFetchPosts(state, action);
		case actionTypes.FETCH_POSTS_SUCCESS:
			return fetchPostsSuccess(state, action);
		case actionTypes.FETCH_POSTS_FALURE:
			return fetchPostsFailure(state, action);
		case actionTypes.START_FETCH_POST:
			return startFetchPost(state, action);
		case actionTypes.FETCH_POST_SUCCESS:
			return fetchPostSuccess(state, action);
		case actionTypes.FETCH_POST_FALURE:
            return fetchPostFailure(state, action);
        case actionTypes.START_BULK_ACCOUNT_POSTS:
			return startFetchBulkAccountPosts(state, action);
		case actionTypes.FETCH_SINGLE_BULK_ACCOUNT_POSTS_SUCCESS:
			return fetchSingleBulkAccountPostsSuccess(state, action);
        case actionTypes.FETCH_BULK_ACCOUNT_POSTS_SUCCESS:
            return fetchBulkAccountPostsSuccess(state, action);
        case actionTypes.FETCH_BULK_ACCOUNT_POSTS_FALURE:
            return fetchBulkAccountPostsFailure(state, action);
		case actionTypes.START_FETCH_WP_USER:
			return startFetchWPUser(state, action);
		case actionTypes.FETCH_WP_USER_SUCCESS:
			return fetchWPUserSuccess(state, action);
		case actionTypes.FETCH_WP_USER_FALURE:
			return fetchWPUserFailure(state, action);
		default:
			return state;
	}
};

export default reducer;
