import React from "react";
import "./UpdateIndicator.css";
import { Loader } from "semantic-ui-react";

export default function UpdateIndicator() {
  return (
    <div className="UpdateIndicator">
      <div className="UpdateIndicator__content">
        <span className="updateIndicator__text">Updating...</span>
        <Loader active inverted inline size="mini" />
      </div>
    </div>
  );
}
