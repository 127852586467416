import React, { Component, createRef } from "react";
import "./ChecklistTemplateItem.css";
import { Input, List, Icon, Confirm } from "semantic-ui-react";
import UserSelectWidget from "../../UserSelectWidget/UserSelectWidget";
import DayWeekPickerWidget from "../../DayWeekPickerWidget/DayWeekPickerWidget";
import DepartmentPickerWidget from "../../DepartmentPickerWidget/DepartmentPickerWidget";

export default class ChecklistTemplateItem extends Component {
  state = {
    item: this.props.item,
    deleteConfirmOpen: false,
    isHovering: false
  };

  newItemNameRef = createRef();

  componentDidMount() {
    this.props.new && this.newItemNameRef.current.focus();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.item !== prevProps.item) {
      this.setState({ item: this.props.item });
    }
  }

  handleUserChange = async userID => {
    await this.setState({
      item: { ...this.state.item, default_assignee: userID }
    });
    if (!this.props.new) {
      return this.props.updateChecklistTemplateItem(
        this.state.item,
        this.state.item.id,
        this.props.list.id
      );
    }
  };

  handleDepartmentSelection = async department => {
    await this.setState({
      item: { ...this.state.item, department: department }
    });
    if (!this.props.new) {
      return this.props.updateChecklistTemplateItem(
        this.state.item,
        this.state.item.id,
        this.props.list.id
      );
    }
  };

  handleChange(e) {
    this.setState({
      item: {
        ...this.state.item,
        [e.target.name]: e.target.value
      }
    });
  }

  handleCreate = async e => {
    if (!this.props.itemMeta.isAdding) {
      if (
        this.state.item[e.target.name] !== this.props.item[e.target.name] &&
        this.state.item.name
      ) {
        await this.props.addChecklistTemplateItem(
          this.state.item,
          this.props.list.id,
          this.props.itemMeta.index
        );
        this.props.cancelAddingItem();
      } else {
        this.props.cancelAddingItem();
      }
    }
  };

  handleUpdate = e => {
    if (this.state.item[e.target.name] !== this.props.item[e.target.name]) {
      this.props.updateChecklistTemplateItem(
        this.state.item,
        this.state.item.id,
        this.props.list.id
      );
    }
  };

  handleKeyPress = async (e, index, order) => {
    if (e.key === "Enter") {
      if (!this.props.new) {
        this.props.startAddingItem(
          index,
          order,
          this.props.parentIndex,
          this.props.parentID,
          this.props.item.id
        );
      } else {
        if (this.state.item.name) {
          await this.props.addChecklistTemplateItem(
            this.state.item,
            this.props.list.id,
            this.props.itemMeta.childIndex !== null
              ? this.props.itemMeta.childIndex
              : this.props.itemMeta.index
          );
          await this.props.cancelAddingItem();

          this.props.startAddingItem(
            this.props.itemMeta.childIndex !== null
              ? this.props.itemMeta.childIndex + 1
              : this.props.itemMeta.index + 1,
            this.state.item.order,
            this.props.parentIndex,
            this.state.item.parent,
            this.props.item.id
          );
        } else {
          this.props.cancelAddingItem();
        }
      }
    }
  };

  handleKeyDown = (e, index, order) => {
    if (e.key === "Tab" && !this.props.new) {
      e.preventDefault();
      this.props.startAddingItem(
        index,
        order,
        this.props.parentIndex,
        this.props.parentID,
        this.props.item.id,
        true
      );
    }
    if (this.props.new && e.key === "Backspace") {
      if (!this.state.item.name) {
        this.props.cancelAddingItem();
      }
    }
  };

  handleDeleteConfirm = (id, listID) => {
    this.props.deleteChecklistTemplateItem(id, listID);
    this.setState({ deleteConfirmOpen: false });
  };

  handleHideItems = () => {
    this.setState({ isHovering: false });
  };

  handleShowItems = () => {
    this.setState({ isHovering: true });
  };

  handleDayWeekSubmit = async days => {
    await this.setState({
      item: {
        ...this.state.item,
        days_out: days
      }
    });
    if (!this.props.new) {
      return this.props.updateChecklistTemplateItem(
        this.state.item,
        this.state.item.id,
        this.props.list.id
      );
    }
  };

  render() {
    let { item } = this.state;
    return (
      <List.Item
        className={`ChecklistTemplateItem ${
          this.state.isHovering ? "isHovering" : ""
        }`}
      >
        <List.Content
          onMouseEnter={() => this.handleShowItems()}
          onMouseLeave={() => this.handleHideItems()}
          className="ChecklistTemplateItemContent"
        >
          <div className="ChecklistTemplateItemContent--left" />
          <div className="ChecklistTemplateItemContent--main">
            <Input
              fluid
              required
              ref={this.props.new && this.newItemNameRef}
              placeholder="Name"
              name="name"
              onBlur={
                !this.props.new
                  ? e => this.handleUpdate(e)
                  : e => this.handleCreate(e)
              }
              onChange={e => this.handleChange(e)}
              onKeyPress={e =>
                this.handleKeyPress(e, this.props.index, item.order)
              }
              onKeyDown={e => this.handleKeyDown(e, this.props.index)}
              className="ChecklistTemplateItem__header"
              transparent
              value={item.name}
            />
            {/* <Input
              fluid
              name="description"
              onBlur={
                !this.props.new
                  ? e => this.handleUpdate(e)
                  : e => this.handleCreate(e)
              }
              onChange={e => this.handleChange(e)}
              onKeyPress={e =>
                this.handleKeyPress(e, this.props.index, item.order)
              }
              className="ChecklistTemplateItem__description"
              transparent
              placeholder="Description"
              value={item.description}
            /> */}
          </div>
          <div className="ChecklistTemplateItemContent--right">
            {!this.props.new && (
              <React.Fragment>
                {this.state.isHovering && (
                  <React.Fragment>
                    {(this.props.prevSibling(this.props.item) ||
                      this.props.nextSibling(this.props.item)) && (
                      <div className="arrowControls">
                        {this.props.prevSibling(this.props.item) !== null && (
                          <span
                            onClick={() =>
                              this.props.handleReOrder(
                                item,
                                "up",
                                item.parent ? item.parent : null
                              )
                            }
                            className="arrowControl arrowControl--up"
                          >
                            &#8679;
                          </span>
                        )}
                        {this.props.nextSibling(this.props.item) !== null && (
                          <span
                            onClick={() =>
                              this.props.handleReOrder(
                                item,
                                "down",
                                item.parent ? item.parent : null
                              )
                            }
                            className="arrowControl arrowControl-down"
                          >
                            &#8681;
                          </span>
                        )}
                      </div>
                    )}
                    <div
                      onClick={() => this.setState({ deleteConfirmOpen: true })}
                      className="ChecklistTemplateItem--delete"
                    >
                      <Icon color="red" name="trash alternate outline" />
                    </div>
                  </React.Fragment>
                )}

                <Confirm
                  size="mini"
                  header={`Delete ${item.name} ?`}
                  content="Are you sure?"
                  open={this.state.deleteConfirmOpen}
                  onCancel={() => this.setState({ deleteConfirmOpen: false })}
                  onConfirm={() =>
                    this.handleDeleteConfirm(item.id, this.props.list.id)
                  }
                />
              </React.Fragment>
            )}
            <DepartmentPickerWidget
              department={item.department}
              handleDepartmentSelection={this.handleDepartmentSelection}
            />
            <DayWeekPickerWidget
              handleSubmit={this.handleDayWeekSubmit}
              currentValue={item.days_out ? item.days_out : null}
            />
            <UserSelectWidget
              parentObject={item}
              currentUser={
                this.props.item.default_assignee &&
                this.props.item.default_assignee
              }
              handleSelection={this.handleUserChange}
            />
          </div>
        </List.Content>
        {((item.checklist_template_item_children &&
          item.checklist_template_item_children[0]) ||
          this.props.addingNewChild) && (
          <List.List className="CheckListTemplateItem--children">
            {this.props.children}
          </List.List>
        )}
      </List.Item>
    );
  }
}
