import React, { Component } from "react";
import { TextArea, Form } from "semantic-ui-react";

export default class UserFeedbackForm extends Component {
  state = {
    form: {
      feedback: ""
    }
  };

  clearForm = () => {
    this.setState({ form: { feedback: "" } });
  };

  handleChange(e) {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  }

  handleSubmit = async e => {
    e.preventDefault();
    await this.props.addUserFeedback(
      this.props.feedbackType,
      this.state.form.feedback
    );
    !this.props.errors && this.props.handleClose(true);
  };

  render() {
    return (
      <div className="UserFeedbackForm">
        <Form onSubmit={this.handleSubmit}>
          <Form.Field>
            <TextArea
              id="feedback"
              name="feedback"
              placeholder="Leave feedback here"
              value={this.state.form.feedback}
              onChange={e => this.handleChange(e)}
            />
          </Form.Field>
          <Form.Button loading={this.props.isAdding} positive type="submit">
            Submit Feedback
          </Form.Button>
        </Form>
      </div>
    );
  }
}
