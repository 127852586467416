import React, { Component } from "react";
import "./Utilities.css";
import Navbar from "../../components/Navbar/Navbar";
import { Switch, Route } from "react-router-dom";
import LinkCheckerView from "./LinkCheckerView/LinkCheckerView";
import UtilitiesDashboard from "./UtilitiesDashboard/UtilitiesDashboard";
import { ui } from "../../store/actions";
import { connect } from "react-redux";
import {hasPermissions} from "../../utility";

class Utilities extends Component {
  state = {
    menuItems: [
      {
        id: 1,
        path: `${this.props.match.path}/broken-link-checker`,
        exact: true,
        menuLabel: "Link Checker",
        navbarName: "Broken Link Checker",
        icon: "unlink",
        component: null,
        protected: true,
        permission: hasPermissions(this.props.user, "view_websitebuild")
      }
    ]
  };
  componentDidMount() {
    this.props.setMenuItems(this.state.menuItems, "utilities");
  }
  render() {
    return (
      <div className="RouteContainer">
        <Navbar matchPath={this.props.matchPath} noMenuPrefix routeHeader="Utilities" menuItems={this.state.menuItems} />
        <section className="Utilities">
          <Switch>
            <Route
              exact
              path={`${this.props.match.path}/`}
              component={UtilitiesDashboard}
            />
            <Route
              path={`${this.props.match.path}/broken-link-checker`}
              component={LinkCheckerView}
            />
          </Switch>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setMenuItems: (items, route) => dispatch(ui.setMenuItems(items, route))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Utilities);
