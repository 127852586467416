import React from 'react'
import { Header } from 'semantic-ui-react';

const ClientOnboardingDashboard = () => {
  return (
    <div className="ClientOnboardingDashboard">
      <Header>Client Onboarding Dashboard</Header>
    </div>
  )
}

export default ClientOnboardingDashboard
