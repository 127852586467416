import * as actionTypes from "../actions/actionTypes";
import axios from "../../axios-portal";
/*eslint eqeqeq: "off"*/

export const modifyFilters = (component, filters) => {
  return {
    type: actionTypes.MODIFY_FILTERS,
    component: component,
    filters: filters,
  };
};

export const startArticleFetch = () => {
  return {
    type: actionTypes.START_ARTICLE_FETCH,
  };
};

export const startTemplateFetch = () => {
  return {
    type: actionTypes.START_TEMPLATE_FETCH,
  };
};

export const startContentDataFetch = () => {
  return {
    type: actionTypes.START_CONTENT_DATA_FETCH,
  };
};

export const startPlanningMonthFetch = () => {
  return {
    type: actionTypes.START_PLANNING_MONTH_FETCH,
  };
};

export const startAdditionalPlanningMonthFetch = () => {
  return {
    type: actionTypes.START_ADDITIONAL_PLANNING_MONTH_FETCH,
  };
};

export const startContentProjectFetch = () => {
  return {
    type: actionTypes.START_CONTENT_PROJECT_FETCH,
  };
};

export const startKeywordFetch = () => {
  return {
    type: actionTypes.START_KEYWORD_FETCH,
  };
};

export const setContentArticles = (
  contentArticles,
  year,
  month,
  planningMonth
) => {
  return {
    type: actionTypes.SET_CONTENT_ARTICLES,
    contentArticles: contentArticles,
    year: year,
    month: month,
    planningMonth: planningMonth,
  };
};

export const setProjectArticles = (contentArticles, project_id) => {
  return {
    type: actionTypes.SET_PROJECT_ARTICLES,
    contentArticles: contentArticles,
    project_id: project_id,
  };
};

export const setOrganizationArticles = (contentArticles, orgID) => {
  return {
    type: actionTypes.SET_ORGANIZATION_ARTICLES,
    contentArticles: contentArticles,
    orgID: orgID,
  };
};

export const setArticleTemplates = (articleTemplates) => {
  return {
    type: actionTypes.SET_ARTICLE_TEMPLATES,
    articleTemplates: articleTemplates,
  };
};

export const setContentTypes = (contentTypes) => {
  return {
    type: actionTypes.SET_CONTENT_TYPES,
    contentTypes: contentTypes,
  };
};
export const setContentStatuses = (contentStatuses) => {
  return {
    type: actionTypes.SET_CONTENT_STATUSES,
    contentStatuses: contentStatuses,
  };
};

export const setContentChannels = (contentChannels) => {
  return {
    type: actionTypes.SET_CONTENT_CHANNELS,
    contentChannels: contentChannels,
  };
};
export const setContentKeywords = (contentKeywords) => {
  return {
    type: actionTypes.SET_CONTENT_KEYWORDS,
    contentKeywords: contentKeywords,
  };
};

export const setPlanningMonths = (planningMonths) => {
  return {
    type: actionTypes.SET_PLANNING_MONTHS,
    planningMonths: planningMonths,
  };
};

export const setAdditionalPlanningMonths = (planningMonths) => {
  return {
    type: actionTypes.SET_ADDITIONAL_PLANNING_MONTHS,
    planningMonths: planningMonths,
  };
};

export const setContentProjects = (projects) => {
  return {
    type: actionTypes.SET_CONTENT_PROJECTS,
    projects: projects,
  };
};

export const setContentTags = (contentTags) => {
  return {
    type: actionTypes.SET_CONTENT_TAGS,
    contentTags: contentTags,
  };
};

export const setWriters = (writers) => {
  return {
    type: actionTypes.SET_WRITERS,
    writers: writers,
  };
};

export const setContentStaff = (contentStaff) => {
  return {
    type: actionTypes.SET_CONTENT_STAFF,
    contentStaff: contentStaff,
  };
};

export const startFetchApprovalStaff = () => {
  return {
    type: actionTypes.START_FETCH_APPROVAL_STAFF,
  };
};

export const setApprovalStaff = (approvalStaff) => {
  return {
    type: actionTypes.SET_APPROVAL_STAFF,
    approvalStaff: approvalStaff,
  };
};

export const fetchContentArticlesFailed = (errors) => {
  return {
    type: actionTypes.FETCH_CONTENT_ARTICLES_FAILED,
    errors: errors,
  };
};

export const addContentArticleSuccess = (contentArticle) => {
  return {
    type: actionTypes.ADD_CONTENT_ARTICLE_SUCCESS,
    contentArticle: contentArticle,
  };
};

export const updateContentArticleSuccess = (contentArticle, id) => {
  return {
    type: actionTypes.UPDATE_CONTENT_ARTICLE_SUCCESS,
    contentArticle: contentArticle,
    id: id,
  };
};

export const updateBulkContentArticleSuccess = (
  contentArticles,
  articleIndexes
) => {
  return {
    type: actionTypes.UPDATE_BULK_CONTENT_ARTICLE_SUCCESS,
    contentArticles: contentArticles,
    articleIndexes: articleIndexes,
  };
};

export const deleteContentArticleSuccess = (id) => {
  return {
    type: actionTypes.DELETE_CONTENT_ARTICLE_SUCCESS,
    id: id,
  };
};

export const addArticleTemplateSuccess = (contentTemplate) => {
  return {
    type: actionTypes.ADD_ARTICLE_TEMPLATE_SUCCESS,
    contentTemplate: contentTemplate,
  };
};

export const articleTemplateFailed = (errors) => {
  return {
    type: actionTypes.ARTICLE_TEMPLATE_FAILURE,
    errors: errors,
  };
};

export const updateArticleTemplateSuccess = (contentTemplate, id) => {
  return {
    type: actionTypes.UPDATE_ARTICLE_TEMPLATE_SUCCESS,
    contentTemplate: contentTemplate,
    id: id,
  };
};

export const deleteArticleTemplateSuccess = (id) => {
  return {
    type: actionTypes.DELETE_ARTICLE_TEMPLATE_SUCCESS,
    id: id,
  };
};

export const addContentKeywordSuccess = (contentKeyword) => {
  return {
    type: actionTypes.ADD_CONTENT_KEYWORD_SUCCESS,
    contentKeyword: contentKeyword,
  };
};

export const contentKeywordFailure = (errors) => {
  return {
    type: actionTypes.CONTENT_KEYWORD_FAILURE,
    errors: errors,
  };
};

export const updateContentKeywordSuccess = (contentKeyword, id) => {
  return {
    type: actionTypes.UPDATE_CONTENT_KEYWORD_SUCCESS,
    contentKeyword: contentKeyword,
    id: id,
  };
};

export const deleteContentKeywordSuccess = (id) => {
  return {
    type: actionTypes.DELETE_CONTENT_KEYWORD_SUCCESS,
    id: id,
  };
};

export const addPlanningMonthSuccess = (planningMonth) => {
  return {
    type: actionTypes.ADD_PLANNING_MONTH_SUCCESS,
    planningMonth: planningMonth,
  };
};

export const addContentProjectSuccess = (project) => {
  return {
    type: actionTypes.ADD_CONTENT_PROJECT_SUCCESS,
    project: project,
  };
};

export const contentDataFailure = (errors) => {
  return {
    type: actionTypes.CONTENT_DATA_FAILURE,
    errors: errors,
  };
};

export const updatePlanningMonthSuccess = (planningMonth) => {
  return {
    type: actionTypes.UPDATE_PLANNING_MONTH_SUCCESS,
    planningMonth: planningMonth,
  };
};

export const updateContentProjectSuccess = (project) => {
  return {
    type: actionTypes.UPDATE_CONTENT_PROJECT_SUCCESS,
    project: project,
  };
};

export const planningMonthFailure = (errors) => {
  return {
    type: actionTypes.PLANNING_MONTH_FAILURE,
    errors: errors,
  };
};

export const contentProjectFailure = (errors) => {
  return {
    type: actionTypes.CONTENT_PROJECT_FAILURE,
    errors: errors,
  };
};

export const articleTemplateFailure = (errors) => {
  return {
    type: actionTypes.ARTICLE_TEMPLATE_FAILURE,
    errors: errors,
  };
};

export const contentArticleFailure = (errors) => {
  return {
    type: actionTypes.CONTENT_ARTICLE_FAILURE,
    errors: errors,
  };
};

export const isAddingArticle = () => {
  return {
    type: actionTypes.IS_ADDING_ARTICLE,
  };
};

export const cancelAddingArticle = () => {
  return {
    type: actionTypes.CANCEL_ADDING_ARTICLE,
  };
};

export const isAddingTemplate = () => {
  return {
    type: actionTypes.IS_ADDING_TEMPLATE,
  };
};

export const cancelAddingTemplate = () => {
  return {
    type: actionTypes.CANCEL_ADDING_TEMPLATE,
  };
};

export const isAddingMonth = () => {
  return {
    type: actionTypes.IS_ADDING_MONTH,
  };
};

export const isAddingProject = () => {
  return {
    type: actionTypes.IS_ADDING_PROJECT,
  };
};

export const cancelAddingMonth = () => {
  return {
    type: actionTypes.CANCEL_ADDING_MONTH,
  };
};

export const cancelAddingProject = () => {
  return {
    type: actionTypes.CANCEL_ADDING_PROJECT,
  };
};

export const addMessage = (message, formMessage) => {
  return {
    type: actionTypes.ADD_MESSAGE,
    message: message,
    formMessage: formMessage,
  };
};

export const removeMessage = (message, formMessage) => {
  return {
    type: actionTypes.REMOVE_MESSAGE,
    message: message,
    formMessage: formMessage,
  };
};

export const startContentCommentFetch = () => {
  return {
    type: actionTypes.START_CONTENT_COMMENT_FETCH,
  };
};

export const setContentComments = (contentComments) => {
  return {
    type: actionTypes.SET_CONTENT_COMMENTS,
    contentComments: contentComments,
  };
};

export const addContentCommentSuccess = (contentComment) => {
  return {
    type: actionTypes.ADD_CONTENT_COMMENT_SUCCESS,
    contentComment: contentComment,
  };
};

export const contentCommentFailed = (errors) => {
  return {
    type: actionTypes.CONTENT_COMMENT_FAILURE,
    errors: errors,
  };
};

export const updateContentCommentSuccess = (contentComment, id) => {
  return {
    type: actionTypes.UPDATE_CONTENT_COMMENT_SUCCESS,
    contentComment: contentComment,
    id: id,
  };
};

export const deleteContentCommentSuccess = (id) => {
  return {
    type: actionTypes.DELETE_CONTENT_COMMENT_SUCCESS,
    id: id,
  };
};

export const startCreateGoogleDoc = () => {
  return {
    type: actionTypes.START_CREATE_GOOGLE_DOC,
  };
};

export const createGoogleDocSuccess = (doc, articleId, isSheet) => {
  return {
    type: actionTypes.CREATE_GOOGLE_DOC_SUCCESS,
    doc: doc,
    articleId: articleId,
    isSheet: isSheet
  };
};

export const googleDocFailure = (errors) => {
  return {
    type: actionTypes.GOOGLE_DOC_FAILURE,
    errors: errors,
  };
};

export const updateArticleStatusSuccess = (
  articleId,
  newStatus,
  args
  // scheduledDate,
  // writerDueDate,
  // postedUrl
) => {
  return {
    type: actionTypes.UPDATE_ARTICLE_STATUS_SUCCESS,
    articleId: articleId,
    newStatus: newStatus,
    args: args,
    // scheduledDate: scheduledDate,
    // writerDueDate: writerDueDate,
    // postedUrl: postedUrl,
  };
};

export const updateArticleStatusFailure = (errors) => {
  return {
    type: actionTypes.UPDATE_ARTICLE_STATUS_FAILURE,
    errors: errors,
  };
};

export const startFeedbackFetch = () => {
  return {
    type: actionTypes.START_FEEDBACK_FETCH,
  };
};

export const setFeedback = (feedback) => {
  return {
    type: actionTypes.SET_FEEDBACK,
    feedback: feedback,
  };
};

export const addFeedbackSuccess = (feedback) => {
  return {
    type: actionTypes.ADD_FEEDBACK_SUCCESS,
    feedback: feedback,
  };
};

export const feedbackFailed = (errors) => {
  return {
    type: actionTypes.FEEDBACK_FAILURE,
    errors: errors,
  };
};

export const updateFeedbackSuccess = (feedback, id) => {
  return {
    type: actionTypes.UPDATE_FEEDBACK_SUCCESS,
    feedback: feedback,
    id: id,
  };
};

export const deleteFeedbackSuccess = (id) => {
  return {
    type: actionTypes.DELETE_FEEDBACK_SUCCESS,
    id: id,
  };
};

export const fetchContentArticles = (id, lead) => {
  const userId = id ? id : null;
  return (dispatch) => {
    dispatch(startArticleFetch());
    let url = `content-articles/`;
    if (lead) {
      url += "?lead=true";
    } else {
      url += `?assigned=true${userId ? `&user=${userId}` : ""}`;
    }
    axios
      .get(url)
      .then((response) => {
        return dispatch(setContentArticles(response.data));
      })
      .catch((err) => {
        dispatch(fetchContentArticlesFailed(err));
      });
  };
};

export const fetchClientArticles = () => {
  return (dispatch) => {
    dispatch(startArticleFetch());
    axios
      .get(`content-articles/?client=true`)
      .then((response) => {
        return dispatch(setContentArticles(response.data));
      })
      .catch((err) => {
        dispatch(fetchContentArticlesFailed(err));
      });
  };
};

export const fetchArticleBatch = (paramKey, paramValue) => {
  return (dispatch) => {
    dispatch(startArticleFetch());
    axios
      .get(`content-articles/?${paramKey}=${paramValue}`)
      .then((response) => {
        return dispatch(setContentArticles(response.data));
      })
      .catch((err) => {
        dispatch(fetchContentArticlesFailed(err));
      });
  };
};

export const fetchContentArticleMonth = (year, month) => {
  return async (dispatch, getState) => {
    dispatch(startArticleFetch());
    const planningMonth =
      await getState().contentGeneration.contentArticles.find(
        (article) =>
          article.planning_year_month.year == year &&
          article.planning_year_month.month == month
      );
    // console.log('fetchContentArticleMonth year is: ', year)
    // console.log('fetchContentArticleMonth month is: ', month)
    // console.log('fetchContentArticleMonth planningMonths are: ', getState().contentGeneration.planningMonths)
    // const planningMonth = await getState().contentGeneration.planningMonths.find(pm => Number(pm.month) === Number(month) && Number(pm.year) === Number(year))
    //   console.log('fetchContentArticleMonth planningMonth is: ', planningMonth)
    axios
      .get(`content-articles/?year=${year}&month=${month}`)
      .then((response) => {
        return dispatch(
          setContentArticles(response.data, year, month, planningMonth)
        );
      })
      .catch((err) => {
        dispatch(fetchContentArticlesFailed(err));
      });
  };
};

export const fetchProjectArticles = (project_id) => {
  return async (dispatch, getState) => {
    dispatch(startArticleFetch());

    axios
      .get(`content-articles/?project=${project_id}`)
      .then((response) => {
        return dispatch(setProjectArticles(response.data, project_id));
      })
      .catch((err) => {
        dispatch(fetchContentArticlesFailed(err));
      });
  };
};

export const fetchOrganizationArticles = (orgID) => {
  return async (dispatch, getState) => {
    dispatch(startArticleFetch());

    axios
      .get(`content-articles/?organization=${orgID}`)
      .then((response) => {
        return dispatch(setOrganizationArticles(response.data, orgID));
      })
      .catch((err) => {
        dispatch(fetchContentArticlesFailed(err));
      });
  };
};

export const fetchArticleTemplates = () => {
  return (dispatch) => {
    dispatch(startTemplateFetch());
    axios
      .get("article-templates.json")
      .then((response) => {
        return dispatch(setArticleTemplates(response.data));
      })
      .catch((err) => {
        dispatch(articleTemplateFailed(err));
      });
  };
};

export const addContentArticle = (formData, planningMonth, project) => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      planning_year_month: planningMonth ? planningMonth : "",
      project: project ? project : "",
      title: formData.title,
      live_url: formData.liveUrl,
      admin_draft_url: formData.admin_draft_url,
      description: formData.description,
      content_type: formData.contentType,
      channels: formData.channels,
      keywords: formData.keywords,
      focus_keyword_plaintext: formData.focus_keyword_plaintext,
      status: formData.status,
      client: formData.client,
      url_1: formData.primary_url,
      anchor_1: formData.primary_anchor,
      url_2: formData.secondary_url,
      anchor_2: formData.secondary_anchor,
      writer: formData.writer,
      editor: formData.editor,
      poster: formData.poster,
      lead: formData.lead,
      final_approver: formData.final_approver,
      min_word_count: formData.wordCount,
      duedate_write: formData.duedate_write ? formData.duedate_write : null,
      duedate_rewrite: formData.duedate_rewrite
        ? formData.duedate_rewrite
        : null,
      duedate_edit: formData.duedate_edit ? formData.duedate_edit : null,
      duedate_reedit: formData.duedate_reedit ? formData.duedate_reedit : null,
      duedate_finalreview: formData.duedate_finalreview
        ? formData.duedate_finalreview
        : null,
      duedate_schedulepost: formData.duedate_schedulepost
        ? formData.duedate_schedulepost
        : null,
      duedate_qapost: formData.duedate_qapost ? formData.duedate_qapost : null,
      duedate_golive: formData.duedate_golive ? formData.duedate_golive : null,
      milestone: formData.mileStone,
    });
    let message = "Content Added!";
    return axios
      .post("content-articles.json", body)
      .then((response) => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message)), 2500);
        return dispatch(addContentArticleSuccess(response.data));
      })
      .catch((err) => {
        dispatch(contentArticleFailure(err.response ? err.response.data : err));
      });
  };
};

export const updateContentArticle = (formData) => {
  const articleId = formData.id;
  return (dispatch, getState) => {
    let body = JSON.stringify({
      id: articleId,
      planning_year_month: formData.planningYearMonth
        ? formData.planningYearMonth
        : "",
      project: formData.project,
      title: formData.title,
      live_url: formData.liveUrl,
      admin_draft_url: formData.admin_draft_url,
      description: formData.description,
      content_type: formData.contentType,
      channels: formData.channels,
      keywords: formData.keywords,
      focus_keyword_plaintext: formData.focus_keyword_plaintext,
      status: formData.status,
      client: formData.client,
      url_1: formData.primary_url,
      anchor_1: formData.primary_anchor,
      url_2: formData.secondary_url,
      anchor_2: formData.secondary_anchor,
      writer: formData.writer,
      editor: formData.editor,
      poster: formData.poster,
      lead: formData.lead,
      final_approver: formData.final_approver,
      min_word_count: formData.wordCount,
      milestone: formData.mileStone,
      google_doc: formData.googleDoc,
      google_sheet: formData.googleSheet,
      duedate_write: formData.duedate_write ? formData.duedate_write : null,
      duedate_rewrite: formData.duedate_rewrite
        ? formData.duedate_rewrite
        : null,
      duedate_edit: formData.duedate_edit ? formData.duedate_edit : null,
      duedate_reedit: formData.duedate_reedit ? formData.duedate_reedit : null,
      duedate_finalreview: formData.duedate_finalreview
        ? formData.duedate_finalreview
        : null,
      duedate_schedulepost: formData.duedate_schedulepost
        ? formData.duedate_schedulepost
        : null,
      duedate_qapost: formData.duedate_qapost ? formData.duedate_qapost : null,
      duedate_golive: formData.duedate_golive ? formData.duedate_golive : null,
      archived: formData.archived,
    });
    let message = "Content Updated!";

    return axios
      .put(`content-articles/${articleId}/`, body)
      .then((response) => {
        dispatch(addMessage(message, true));
        setTimeout(() => dispatch(removeMessage(message, true)), 1500);
        return dispatch(updateContentArticleSuccess(response.data, articleId));
      })
      .catch((err) => {
        dispatch(contentArticleFailure(err.response ? err.response.data : err));
      });
  };
};

export const updateBulkContentArticle = (articles, formData) => {
  const handleProject = (newProject, oldProject, newMonth) => {
    if (newMonth) {
      return null;
    } else if (newProject) {
      return newProject;
    } else {
      return oldProject;
    }
  };
  const handleMonth = (newMonth, oldMonth, newProject) => {
    if (newProject) {
      return null;
    } else if (newMonth) {
      return newMonth;
    } else {
      return oldMonth;
    }
  };
  return async (dispatch, getState) => {
    let body = JSON.stringify(
      articles.map((article) => ({
        id: article.id,
        planning_year_month: handleMonth(
          formData.planningYearMonth,
          article.planning_year_month.id,
          formData.project
        ),
        project: handleProject(
          formData.project,
          article.project.id,
          formData.planningYearMonth
        ),
        title: article.title,
        description: article.description,
        channels: article.channels,
        keywords: article.keywords,
        focus_keyword_plaintext: article.focus_keyword_plaintext,
        client: article.client.id,
        url_1: article.url_1,
        anchor_1: article.anchor_1,
        url_2: article.url_2,
        anchor_2: article.anchor_2,
        min_word_count: article.min_word_count,
        milestone: article.milestone,
        google_doc: article.google_doc,
        google_sheet: article.google_sheet,
        content_type: formData.contentType
          ? formData.contentType
          : article.content_type.id,
        status: formData.status ? formData.status : article.status.id,
        writer: formData.writer ? formData.writer : article.writer.id,
        editor: formData.editor ? formData.editor : article.editor.id,
        poster: formData.poster ? formData.poster : article.poster.id,
        lead: formData.lead ? formData.lead : article.lead.id,
        final_approver: formData.final_approver
          ? formData.final_approver
          : article.final_approver.id,
        duedate_write: formData.duedate_write
          ? formData.duedate_write
          : article.duedate_write,
        duedate_edit: formData.duedate_edit
          ? formData.duedate_edit
          : article.duedate_edit,
        duedate_rewrite: formData.duedate_rewrite
          ? formData.duedate_rewrite
          : article.duedate_rewrite,
        duedate_reedit: formData.duedate_reedit
          ? formData.duedate_reedit
          : article.duedate_reedit,
        duedate_schedulepost: formData.duedate_schedulepost
          ? formData.duedate_schedulepost
          : article.duedate_schedulepost,
        duedate_golive: formData.duedate_golive
          ? formData.duedate_golive
          : article.duedate_golive,
        archived: formData.archived ? formData.archived : article.archived,
      }))
    );
    let message = `${articles.length} articles updated`;

    const articleIndexes = [];

    await [
      articles.forEach((article) => {
        let index = getState().contentGeneration.contentArticles.findIndex(
          (x) => x.id === article.id
        );
        articleIndexes.push(index);
      }),
    ];

    return axios
      .post(`content-articles.json`, body)
      .then((response) => {
        dispatch(addMessage(message, false));
        setTimeout(() => dispatch(removeMessage(message, false)), 1500);
        return dispatch(
          updateBulkContentArticleSuccess(response.data, articleIndexes)
        );
      })
      .catch((err) => {
        dispatch(contentArticleFailure(err.response ? err.response.data : err));
      });
  };
};

export const toggleContentArchived = (id, archived) => {
  const articleId = id;
  return (dispatch, getState) => {
    let body = JSON.stringify({
      archived: archived,
    });
    let message = `Content ${archived ? "Archived" : "Un-Archived"}`;

    return axios
      .put(`content-articles/${articleId}/`, body)
      .then((response) => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message)), 2500);
        return dispatch(updateContentArticleSuccess(response.data, articleId));
      })
      .catch((err) => {
        return dispatch(contentArticleFailure(err));
      });
  };
};

export const deleteContentArticle = (id) => {
  return (dispatch, getState) => {
    let message = "Content Deleted.";

    return axios
      .delete(`content-articles/${id}/`)
      .then((response) => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message)), 2500);
        return dispatch(deleteContentArticleSuccess(id));
      })
      .catch((err) => {
        dispatch(contentArticleFailure(err));
      });
  };
};

export const addArticleTemplate = (formData) => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      title: formData.title,
      content_type: formData.contentType,
      content_channels: formData.channels,
      client: formData.client,
      writer: formData.writer,
      editor: formData.editor,
      poster: formData.poster,
      lead: formData.lead,
      final_approver: formData.final_approver,
      google_sheet: formData.googleSheet,
      min_word_count: formData.wordCount,
      milestone: formData.mileStone,
      selected_months: formData.selected_months,
    });
    let message = "Template Created!";
    return axios
      .post("article-templates.json", body)
      .then((response) => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message)), 2500);
        return dispatch(addArticleTemplateSuccess(response.data));
      })
      .catch((err) => {
        dispatch(
          articleTemplateFailure(err.response ? err.response.data : err)
        );
      });
  };
};

export const updateArticleTemplate = (formData) => {
  console.log("incoming formData is: ", formData);
  const templateId = formData.id;
  return (dispatch) => {
    let body = JSON.stringify({
      id: templateId,
      title: formData.title,
      content_type: formData.contentType,
      content_channels: formData.channels,
      client: formData.client,
      writer: formData.writer,
      editor: formData.editor,
      lead: formData.lead,
      poster: formData.poster,
      final_approver: formData.final_approver,
      google_sheet: formData.googleSheet,
      min_word_count: formData.wordCount,
      milestone: formData.mileStone,
      selected_months: formData.selected_months,
      archived: formData.archived,
    });
    let message = "Template Updated!";

    return axios
      .put(`article-templates/${templateId}/`, body)
      .then((response) => {
        dispatch(addMessage(message, true));
        setTimeout(() => dispatch(removeMessage(message, true)), 3000);
        return dispatch(
          updateArticleTemplateSuccess(response.data, templateId)
        );
      })
      .catch((err) => {
        dispatch(
          articleTemplateFailure(err.response ? err.response.data : err)
        );
      });
  };
};

export const toggleTemplateArchived = (id, archived) => {
  const templateId = id;
  return (dispatch, getState) => {
    let body = JSON.stringify({
      archived: archived,
    });
    let message = `Template ${archived ? "Archived" : "Un-Archived"}`;

    return axios
      .put(`article-templates/${templateId}/`, body)
      .then((response) => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message)), 2500);
        return dispatch(
          updateArticleTemplateSuccess(response.data, templateId)
        );
      })
      .catch((err) => {
        return dispatch(articleTemplateFailure(err));
      });
  };
};

export const deleteArticleTemplate = (id) => {
  return (dispatch, getState) => {
    let message = "Template Deleted.";

    return axios
      .delete(`article-templates/${id}/`)
      .then((response) => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message)), 2500);
        return dispatch(deleteArticleTemplateSuccess(id));
      })
      .catch((err) => {
        dispatch(articleTemplateFailure(err));
      });
  };
};

export const fetchContentKeywords = (organizationId) => {
  return (dispatch) => {
    dispatch(startKeywordFetch());
    axios
      .get(
        `keyword-metas${
          organizationId ? `/?organization=${organizationId}` : `.json`
        }`
      )
      .then((response) => {
        return dispatch(setContentKeywords(response.data));
      })
      .catch((err) => {
        dispatch(contentDataFailure(err.response ? err.response.data : err));
      });
  };
};

export const addContentKeyword = (formData, orgId) => {
  return (dispatch) => {
    let body = JSON.stringify({
      keyword: {
        name: formData.keyword,
        difficulty: formData.difficulty ? formData.difficulty : null,
        volume: formData.volume ? formData.volume : null,
        cost_per_click: formData.costPerClick,
        parent_keyword: {
          name: formData.parentKeyword,
        },
      },
      seo_value: formData.seoValue,
      business_value: formData.businessValue,
      tag: formData.tags,
      planning_months: formData.planningMonths,
      organization: orgId,
    });
    let message = "Keyword Added!";

    axios
      .post("keyword-metas.json", body)
      .then((response) => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message)), 3000);
        return dispatch(addContentKeywordSuccess(response.data));
      })
      .catch((err) => {
        dispatch(contentKeywordFailure(err.response ? err.response.data : err));
      });
  };
};

export const updateContentKeyword = (formData, id, orgId) => {
  return (dispatch) => {
    let body = JSON.stringify({
      keyword: {
        name: formData.keyword,
        difficulty: formData.difficulty ? formData.difficulty : null,
        volume: formData.volume ? formData.volume : null,
        cost_per_click:
          formData.costPerClick === "" ? null : formData.costPerClick,
        parent_keyword: {
          name: formData.parentKeyword,
        },
      },
      seo_value: formData.seoValue,
      business_value: formData.businessValue,
      tag: formData.tags,
      planning_months: formData.planningMonths,
      organization: orgId,
    });
    let message = "Keyword Updated!";

    axios
      .put(`keyword-metas/${id}/`, body)
      .then((response) => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message)), 3000);
        return dispatch(updateContentKeywordSuccess(response.data, id));
      })
      .catch((err) => {
        dispatch(contentKeywordFailure(err.response ? err.response.data : err));
      });
  };
};

export const deleteContentKeyword = (id) => {
  return (dispatch, getState) => {
    let message = "Keyword Deleted.";

    return axios
      .delete(`keyword-metas/${id}/`)
      .then((response) => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message)), 2500);
        return dispatch(deleteContentKeywordSuccess(id));
      })
      .catch((err) => {
        dispatch(contentKeywordFailure(err));
      });
  };
};

export const fetchContentTypes = () => {
  return (dispatch) => {
    dispatch(startContentDataFetch());
    axios
      .get("content-types.json")
      .then((response) => {
        return dispatch(setContentTypes(response.data));
      })
      .catch((err) => {
        dispatch(contentDataFailure(err));
      });
  };
};

export const fetchContentStatuses = () => {
  return (dispatch) => {
    dispatch(startContentDataFetch());
    axios
      .get("content-statuses.json")
      .then((response) => {
        return dispatch(setContentStatuses(response.data));
      })
      .catch((err) => {
        dispatch(contentDataFailure(err.response ? err.response.data : err));
      });
  };
};

export const fetchContentChannels = () => {
  return (dispatch) => {
    dispatch(startContentDataFetch());
    axios
      .get("content-channels.json")
      .then((response) => {
        return dispatch(setContentChannels(response.data));
      })
      .catch((err) => {
        dispatch(contentDataFailure(err.response ? err.response.data : err));
      });
  };
};

export const fetchContentTags = () => {
  return (dispatch) => {
    dispatch(startContentDataFetch());
    axios
      .get("content-tags.json")
      .then((response) => {
        return dispatch(setContentTags(response.data));
      })
      .catch((err) => {
        dispatch(contentDataFailure(err.response ? err.response.data : err));
      });
  };
};

export const fetchPlanningMonths = (brief) => {
  return async (dispatch, getState) => {
    const isFetching = await getState().contentGeneration.isLoadingMonths;
    if (!isFetching) {
      dispatch(startPlanningMonthFetch());
      axios
        .get(`planning-months${brief ? "/?brief=true" : ".json"}`)
        .then((response) => {
          dispatch(fetchAdditionalPlanningMonths(8));
          return dispatch(setPlanningMonths(response.data));
        })
        .catch((err) => {
          dispatch(contentDataFailure(err.response ? err.response.data : err));
        });
    }
  };
};

export const fetchAdditionalPlanningMonths = (additional) => {
  return async (dispatch, getState) => {
    const isFetching = await getState().contentGeneration
      .isLoadingAdditionalMonths;
    if (!isFetching) {
      dispatch(startAdditionalPlanningMonthFetch());
      axios
        .get(`planning-months/?additional=${additional}`)
        .then((response) => {
          return dispatch(setAdditionalPlanningMonths(response.data));
        })
        .catch((err) => {
          dispatch(contentDataFailure(err.response ? err.response.data : err));
        });
    }
  };
};

export const fetchContentProjects = () => {
  return (dispatch) => {
    dispatch(startContentProjectFetch());
    axios
      .get("content-projects.json")
      .then((response) => {
        return dispatch(setContentProjects(response.data));
      })
      .catch((err) => {
        dispatch(contentDataFailure(err.response ? err.response.data : err));
      });
  };
};

export const fetchContentStaff = () => {
  return (dispatch) => {
    dispatch(startContentDataFetch());
    axios
      .get("staff.json")
      .then((response) => {
        return dispatch(setContentStaff(response.data));
      })
      .catch((err) => {
        dispatch(contentDataFailure(err.response ? err.response.data : err));
      });
  };
};

export const fetchApprovalStaff = (orgID) => {
  return (dispatch) => {
    dispatch(startFetchApprovalStaff());
    axios
      .get(`staff/?client=${orgID}`)
      .then((response) => {
        return dispatch(setApprovalStaff(response.data));
      })
      .catch((err) => {
        dispatch(contentDataFailure(err.response ? err.response.data : err));
      });
  };
};

export const fetchWriters = () => {
  return (dispatch) => {
    dispatch(startContentDataFetch());
    axios
      .get("writers.json")
      .then((response) => {
        return dispatch(setWriters(response.data));
      })
      .catch((err) => {
        dispatch(contentDataFailure(err.response ? err.response.data : err));
      });
  };
};

export const addPlanningMonth = (formData) => {
  return (dispatch, getState) => {
    dispatch(isAddingMonth());
    let body = JSON.stringify({
      year: formData.year,
      month: formData.month,
      default_milestone_one: formData.milestoneOne,
      default_milestone_two: formData.milestoneTwo,
      editor_due_date_offset: formData.editorDueDateOffset,
    });
    let message = "Month Created!";
    return axios
      .post("planning-months.json", body)
      .then((response) => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message)), 3000);
        //dispatch(fetchContentArticleMonth(formData.year, formData.month))
        return dispatch(addPlanningMonthSuccess(response.data));
      })
      .catch((err) => {
        dispatch(planningMonthFailure(err.response ? err.response.data : err));
      });
  };
};

export const updatePlanningMonth = (formData) => {
  return (dispatch, getState) => {
    let body = JSON.stringify(formData);
    let monthId = formData.id;
    let message = "Month Updated!";

    return axios
      .put(`planning-months/${monthId}/`, body)
      .then((response) => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message)), 3000);
        return dispatch(updatePlanningMonthSuccess(response.data));
      })
      .catch((err) => {
        return dispatch(planningMonthFailure(err));
      });
  };
};

export const addContentProject = (formData) => {
  return (dispatch, getState) => {
    dispatch(isAddingProject());
    let body = JSON.stringify({
      name: formData.name,
      start_year: formData.year,
      start_month: formData.month,
      due_date: formData.due_date,
    });
    let message = "Project Created!";
    return axios
      .post("content-projects.json", body)
      .then((response) => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message)), 3000);
        return dispatch(addContentProjectSuccess(response.data));
      })
      .catch((err) => {
        dispatch(contentProjectFailure(err.response ? err.response.data : err));
      });
  };
};

export const updateContentProject = (id, formData) => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      name: formData.name,
      start_year: formData.year,
      start_month: formData.month,
      due_date: formData.due_date,
    });
    let message = "Project Updated!";

    return axios
      .put(`content-projects/${id}/`, body)
      .then((response) => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message)), 3000);
        return dispatch(updateContentProjectSuccess(response.data));
      })
      .catch((err) => {
        return dispatch(contentProjectFailure(err));
      });
  };
};

export const fetchFeedback = (articleID) => {
  return (dispatch) => {
    dispatch(startFeedbackFetch());
    axios
      .get(
        `feedback${
          articleID ? "/?detailed=true&article=" + articleID : ".json"
        }`
      )
      .then((response) => {
        return dispatch(setFeedback(response.data));
      })
      .catch((err) => {
        dispatch(feedbackFailed(err.response ? err.response.data : err));
      });
  };
};

export const startClientFeedbackFetch = () => {
  return {
    type: actionTypes.START_CLIENT_FEEDBACK_FETCH,
  };
};

export const setClientFeedback = (feedback) => {
  return {
    type: actionTypes.SET_CLIENT_FEEDBACK,
    feedback: feedback,
  };
};


export const clientFeedbackFailed = (errors) => {
  return {
    type: actionTypes.CLIENT_FEEDBACK_FAILURE,
    errors: errors,
  };
};


export const fetchClientFeedback = (clientID) => {
  return (dispatch) => {
    dispatch(startClientFeedbackFetch());
    axios
      .get(`feedback?client=${clientID}`)
      .then((response) => {
        return dispatch(setClientFeedback(response.data));
      })
      .catch((err) => {
        dispatch(clientFeedbackFailed(err.response ? err.response.data : err));
      });
  };
};

// export const fetchArticleFeedback = () => {
//   return dispatch => {
//     dispatch(startFeedbackFetch());
//     axios
//       .get("feedback.json")
//       .then(response => {
//         return dispatch(setFeedback(response.data));
//       })
//       .catch(err => {
//         dispatch(feedbackFailed(err.response ? err.response.data : err));
//       });
//   };
// };

export const addFeedback = (articleId, formData, message) => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      satisfaction: formData.satisfaction !== "" ? formData.satisfaction : null,
      feedback_body: formData.feedbackBody,
      approved: formData.approved,
      article: articleId,
    });
    return axios
      .post("feedback.json", body)
      .then((response) => {
        message && dispatch(addMessage(message));
        message && setTimeout(() => dispatch(removeMessage(message)), 2500);
        return dispatch(addFeedbackSuccess(response.data));
      })
      .catch((err) => {
        dispatch(feedbackFailed(err.response ? err.response.data : err));
      });
  };
};

export const updateFeedback = (feedback, feedbackId) => {
  return (dispatch) => {
    let body = JSON.stringify({
      satisfaction: feedback.satisfaction,
      feedback_body: feedback.body,
      approved: feedback.approved,
      article: feedback.article,
    });
    let message = "Feedback Updated";

    return axios
      .put(`feedback/${feedbackId}/`, body)
      .then((response) => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message)), 2000);
        return dispatch(updateFeedbackSuccess(response.data, feedbackId));
      })
      .catch((err) => {
        dispatch(feedbackFailed(err.response ? err.response.data : err));
      });
  };
};

export const deleteFeedback = (id) => {
  return (dispatch, getState) => {
    let message = "Feedback Deleted.";

    return axios
      .delete(`feedback/${id}/`)
      .then((response) => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message)), 2500);
        return dispatch(deleteFeedbackSuccess(id));
      })
      .catch((err) => {
        dispatch(feedbackFailed(err));
      });
  };
};

export const fetchContentComments = () => {
  return (dispatch) => {
    dispatch(startContentCommentFetch());
    axios
      .get("content-comments.json")
      .then((response) => {
        return dispatch(setContentComments(response.data));
      })
      .catch((err) => {
        dispatch(contentCommentFailed(err.response ? err.response.data : err));
      });
  };
};

export const addContentComment = (comment, articleId) => {
  return (dispatch, getState) => {
    let body = JSON.stringify({
      comment: comment,
      article: articleId,
    });
    let message = "Comment Saved!";
    return axios
      .post("content-comments.json", body)
      .then((response) => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message)), 2500);
        return dispatch(addContentCommentSuccess(response.data));
      })
      .catch((err) => {
        dispatch(contentCommentFailed(err.response ? err.response.data : err));
      });
  };
};

export const updateContentComment = (comment, commentId) => {
  return (dispatch) => {
    let body = JSON.stringify({
      comment: comment,
    });
    let message = "Comment Updated!";

    return axios
      .put(`content-comments/${commentId}/`, body)
      .then((response) => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message)), 3000);
        return dispatch(updateContentCommentSuccess(response.data, commentId));
      })
      .catch((err) => {
        dispatch(contentCommentFailed(err.response ? err.response.data : err));
      });
  };
};

export const deleteContentComment = (id) => {
  return (dispatch, getState) => {
    let message = "Comment Deleted.";

    return axios
      .delete(`content-comments/${id}/`)
      .then((response) => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message)), 2500);
        return dispatch(deleteContentCommentSuccess(id));
      })
      .catch((err) => {
        dispatch(contentCommentFailed(err));
      });
  };
};

export const updateArticleStatus = (
  articleId,
  newStatus,
  message,
  args
  // scheduledDate,
  // writerDueDate,
  // postedUrl
) => {
  return (dispatch) => {
    let body = JSON.stringify({
      articleId: articleId,
      to_status: newStatus,
      ...args,
      // ...Object.entries(dueDates).map(([k, v]) => {
      //   k: v
      // }),
      // scheduled_date: scheduledDate,
      // writer_planned_duedate: writerDueDate,
      // posted_url: postedUrl,
    });
    return axios
      .post("submit-article/", body)
      .then((response) => {
        message && dispatch(addMessage(message));
        message && setTimeout(() => dispatch(removeMessage(message)), 1500);
        return dispatch(
          updateArticleStatusSuccess(
            articleId,
            newStatus,
            args
            // scheduledDate,
            // writerDueDate,
            // postedUrl
          )
        );
      })
      .catch((err) => {
        dispatch(
          updateArticleStatusFailure(err.response ? err.response.data : err)
        );
      });
  };
};

export const createGoogleDoc = (
  client,
  year,
  month,
  title,
  articleId,
  projectId,
  isSheet,
) => {
  return (dispatch, getState) => {
    dispatch(startCreateGoogleDoc());
    let body = JSON.stringify({
      organization: client,
      year: year,
      month: month,
      title: title,
      articleId: articleId,
      projectId: projectId,
      isSheet: isSheet
    });
    console.log('createGoogleDoc is: ', body)
    let message = "Doc Created!";
    return axios
      .post("create-google-doc/", body)
      .then((response) => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message)), 3000);
        return dispatch(createGoogleDocSuccess(response.data, articleId, isSheet));
      })
      .catch((err) => {
        dispatch(googleDocFailure(err.response ? err.response.data : err));
      });
  };
};

export const startFetchArticleHistorySets = () => {
  return {
    type: actionTypes.START_FETCH_ARTICLE_HISTORY_SETS,
  };
};

export const fetchArticleHistorySetsSuccess = (articleHistorySets) => {
  return {
    type: actionTypes.FETCH_ARTICLE_HISTORY_SETS_SUCCESS,
    articleHistorySets: articleHistorySets,
  };
};

export const fetchArticleHistorySetsFailure = (errors) => {
  return {
    type: actionTypes.FETCH_ARTICLE_HISTORY_SETS_FAILURE,
    errors: errors,
  };
};

export const fetchArticleHistorySets = (weeksBack) => {
  return (dispatch) => {
    dispatch(startFetchArticleHistorySets());
    axios
      .get(
        `article-history-sets${
          weeksBack ? `/?weeksBack=${weeksBack}` : ".json"
        }`
      )
      .then((response) => {
        return dispatch(fetchArticleHistorySetsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(
          fetchArticleHistorySetsFailure(err.response ? err.response.data : err)
        );
      });
  };
};

// Refresh ArticleHistorySet
export const startArticleHistorySetRefresh = (articleHistorySetStatus) => {
  return {
    type: actionTypes.START_ARTICLE_HISTORY_SET_REFRESH,
    articleHistorySetStatus: articleHistorySetStatus,
  };
};

export const articleHistorySetRefreshSuccess = (articleHistorySet) => {
  return {
    type: actionTypes.ARTICLE_HISTORY_SET_REFRESH_SUCCESS,
    articleHistorySet: articleHistorySet,
  };
};

export const articleHistorySetRefreshFailure = (errors) => {
  return {
    type: actionTypes.ARTICLE_HISTORY_SET_REFRESH_FAILURE,
    errors: errors,
  };
};

export const articleHistorySetRefresh = (id, status) => {
  return (dispatch) => {
    dispatch(startArticleHistorySetRefresh(status));
    axios
      .post(`article-history-sets/${id}/populate_set/`)
      .then((response) => {
        return dispatch(articleHistorySetRefreshSuccess(response.data));
      })
      .catch((err) => {
        dispatch(
          articleHistorySetRefreshFailure(
            err.response ? err.response.data : err
          )
        );
      });
  };
};

export const startFetchArticles = () => {
  return {
    type: actionTypes.START_FETCH_ARTICLES,
  };
};

export const fetchArticlesSuccess = (articles) => {
  return {
    type: actionTypes.FETCH_ARTICLES_SUCCESS,
    articles: articles,
  };
};

export const fetchArticlesFailure = (errors) => {
  return {
    type: actionTypes.FETCH_ARTICLES_FAILURE,
    errors: errors,
  };
};

export const fetchArticles = (parameters) => {
  return (dispatch) => {
    dispatch(startFetchArticles());
    const queryString = () => {
      if (parameters) {
        let output = "/?";
        Object.entries(parameters).map(([k, v], i) => {
          return (output = output + `${i !== 0 ? "&" : ""}${k}=${v}`);
        });
        return output;
      } else {
        return null;
      }
    };
    axios
      .get(`content-articles${queryString() ? queryString() : ".json"}`)
      .then((response) => {
        return dispatch(fetchArticlesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchArticlesFailure(err.response ? err.response.data : err));
      });
  };
};

// Re-Assign Articles
export const startReAssignArticles = () => {
  return {
    type: actionTypes.START_REASSIGN_ARTICLES,
  };
};

export const reAssignArticlesSuccess = (resp) => {
  return {
    type: actionTypes.REASSIGN_ARTICLES_SUCCESS,
    resp: resp,
  };
};

export const reAssignArticlesFailure = (errors) => {
  return {
    type: actionTypes.REASSIGN_ARTICLES_FAILURE,
    errors: errors,
  };
};

export const reAssignArticles = (prevUserId, newUserId) => {
  return async (dispatch, getState) => {
    dispatch(startReAssignArticles());
    let body = JSON.stringify({
      prevUserId: prevUserId,
      newUserId: newUserId,
    });
    let message = "Articles Successfully Re-assigned";
    axios
      .post(`reassign-articles/`, body)
      .then((response) => {
        if (response.response) {
          dispatch(addMessage(message));
          setTimeout(() => dispatch(removeMessage(message)), 1500);
        }
        return dispatch(reAssignArticlesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(reAssignArticlesFailure(err));
      });
  };
};

export const startFetchArticleChangelog = (articleIndex, articleId) => {
  return {
    type: actionTypes.START_FETCH_ARTICLE_CHANGELOG,
    articleId: articleId,
  };
};

export const fetchArticleChangelogSuccess = (articleIndex, articleId, articleChangelog) => {
  return {
    type: actionTypes.FETCH_ARTICLE_CHANGELOG_SUCCESS,
    articleIndex: articleIndex,
    articleId: articleId,
    articleChangelog: articleChangelog,
  };
};

export const fetchArticleChangelogFailure = (errors) => {
  return {
    type: actionTypes.FETCH_ARTICLE_CHANGELOG_FAILURE,
    errors: errors,
  };
};

export const fetchArticleChangelog = (articleId) => {
  return async (dispatch, getState) => {
    const articles = await getState().contentGeneration.contentArticles;
		const articleIndex = await articles.findIndex((article) => article.id === articleId);
    dispatch(startFetchArticleChangelog(articleIndex, articleId));
    axios
      .get(`content-articles/${articleId}/get_article_changelog/`)
      .then((response) => {
        return dispatch(fetchArticleChangelogSuccess(articleIndex, articleId, response.data));
      })
      .catch((err) => {
        dispatch(
          fetchArticleChangelogFailure(err.response ? err.response.data : err)
        );
      });
  };
};
