import axios from "axios";
import store from "./store";

const fetchClient = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      "Content-Type": "application/json",
    }
  };

  let instance = axios.create(defaultOptions);

  instance.interceptors.request.use(function(config) {
    if (!config.headers.Authorization) {
      const authToken = store.getState().auth.token;
      const localToken = localStorage.getItem('token');
      let token = authToken
      if (!authToken) {
        token = localToken;
      }
      config.headers.Authorization = token ? `Token ${token}` : "";
      return config;
    }
  });

  return instance;
};

export default fetchClient();
