import React, { Component } from "react";
import "./ArticleSearchBar.css";
import { connect } from "react-redux";
import { Form, Input } from "semantic-ui-react";

const initialState = {
  search: "",
};

class ArticleSearchBar extends Component {
  state = {
    form: initialState,
  };

  handleChange(e) {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.form.search) {
      const url = `/content-generation/articles/list?title=${this.state.form.search}`;
      this.props.history.push(url);
    }
  };

  render() {
    const { isLoading } = this.props;
    return (
      <div>
        <Form className="ArticleSearchBar" onSubmit={this.handleSubmit}>
          <Input
            type="text"
            minLength="5"
            name="search"
            placeholder="Search Articles..."
            onChange={(e) => this.handleChange(e)}
            value={this.state.form.search}
            disabled={isLoading}
            loading={isLoading}
          />
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
    return {
      isLoading: state.contentGeneration.isLoadingArticles,
    };
  };

export default connect(mapStateToProps, null)(ArticleSearchBar);
