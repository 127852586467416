import React, { Component } from "react";
import SidebarNav from "../../components/SidebarNav/SidebarNav";
import { auth, contentGeneration } from "../../store/actions";
import { connect } from "react-redux";
import UserSettings from "../../containers/Auth/UserSettings/UserSettings";
import BasicModal from "../../components/BasicModal/BasicModal";
import { hasPermissions } from "../../utility";
import Unauthorized from "../../components/Unauthorized/Unauthorized";
import "./Layout.css";
import NotificationStack from "../../components/NotificationStack/NotificationStack";
import UserFeedback from "../../components/UserFeedback/UserFeedback";
import { isMobileDevice } from "../../utility";
import { Message } from "semantic-ui-react";
import SystemNotifications from "../../components/SystemNotifications/SystemNotifications";
import ContentNotifications from "../../components/ContentNotifications/ContentNotifications";

class Layout extends Component {
  state = {
    isOpen: false,
    showNotifications: true,
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ isOpen: false });
    }
  }

  toggleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  closeNav = () => {
    this.setState({ isOpen: false });
  };

  openNav = () => {
    this.setState({ isOpen: true });
  };

  closeNotifications = () => {
    this.setState({showNotifications: false})
  }

  render() {
    return (
      <React.Fragment>
        {isMobileDevice() && (
          <Message className="mobileDeviceMessage" size="small" negative>
            This is a desktop-only application, please view on your desktop
            computer
          </Message>
        )}
        <main className="MainContent">
          <SidebarNav
            {...this.props}
            user={this.props.user}
            openNav={this.openNav}
            closeNav={this.closeNav}
            toggleOpen={this.toggleOpen}
            isNavOpen={this.state.isOpen}
            logout={this.props.logout}
            modifyFilters={this.props.modifyFilters}
            articles={this.props.contentArticles}
            isLoadingArticles={this.props.isLoadingArticles}
            authenticatedAsGhost={this.props.authenticatedAsGhost}
            hostUser={this.props.hostUser}
          />
          <div className="MainContentOutput">
            {this.state.showNotifications && <SystemNotifications closeNotifications={this.closeNotifications} />}
            {this.props.protected ? (
              hasPermissions(this.props.user, this.props.perms) ? (
                this.props.children
              ) : (
                <Unauthorized {...this.props} />
              )
            ) : (
              this.props.children
            )}
          </div>
        </main>
        <BasicModal
          isOpen={this.props.isShowingSettings}
          handleClose={this.props.closeUserSettings}
          size="small"
          title="My Settings"
          icon="settings"
        >
          <UserSettings />
        </BasicModal>
        <NotificationStack />
        <UserFeedback />
        {this.props.user && <ContentNotifications />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    menuItems: state.ui.menuItems,
    didChangePassword: state.auth.didUpdate,
    isChangingPassword: state.auth.isChangingPassword,
    isShowingSettings: state.auth.showUserSettings,
    contentArticles: state.contentGeneration.contentArticles,
    isLoadingArticles: state.contentGeneration.isLoadingArticles,
    authenticatedAsGhost: state.auth.authenticatedAsGhost,
    hostUser: state.auth.hostUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(auth.logout());
    },
    changePasswordStart: () => {
      dispatch(auth.changePasswordStart());
    },
    showUserSettings: () => {
      dispatch(auth.showUserSettings());
    },
    closeUserSettings: () => {
      dispatch(auth.dismissUserSettings());
    },
    modifyFilters: (component, filters) => {
      return dispatch(contentGeneration.modifyFilters(component, filters));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
