import React, { Component } from 'react';
import './AccountOverviewFilter.css';
import { Dropdown, Form, Input, Button, Icon } from 'semantic-ui-react';

const initialFormState = {
	valueFrom: '',
	valueTo: ''
};

export class AccountOverviewFilter extends Component {
	state = {
		form: initialFormState,
		isOpen: false,
		previouslyFocusedElement: null
	};

	valueFromRef = React.createRef();
	valueToRef = React.createRef();

	componentDidMount() {
		if (this.props.correspondingFilter) {
			this.setState({
				...this.state,
				form: {
					valueFrom: this.props.correspondingFilter.min,
					valueTo: this.props.correspondingFilter.max
				}
			});
		}
	}

	// componentDidUpdate(prevProps, prevState) {
	// 	if (!prevState.isOpen && this.state.isOpen) {
	// 		this.handleInitialInputFocus()
	// 	}
	// }

	// handleInitialInputFocus = async () => {
	// 	if (this.state.isOpen && this.valueFromRef && this.valueFromRef.current) {
	// 		await this.setState({ previouslyFocusedElement: this.valueFromRef.current.props.id })
	// 		await this.valueFromRef.current.focus()
	// 		return this.setState({ isOpen: true })
	// 	}
	// }

	clearForm = () => {
		this.setState({ form: initialFormState });
	};

	handleChange(e) {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
			}
		});
	}

	handleOpen = () => {
		this.setState({ isOpen: true });
	};

	handleClose = (e) => {
		this.setState({ isOpen: false });
	};

	handleSubmit = async (e) => {
		e.preventDefault();
		await this.props.handleSubmit(
			this.props.parameterName,
			this.state.form.valueFrom,
			this.state.form.valueTo,
			this.props.helperFunction,
			this.props.paramHelper
		);
		return this.handleClose();
	};

	handleCloseForm = () => {
		this.clearForm();
		this.props.handleClearFilter(this.props.correspondingFilter.parameter);
		this.handleClose();
	};

	handleBlur = (e) => {
		setTimeout(() => {
			let focusedElement = document.activeElement;
			let previouslyFocused = this.state.previouslyFocusedElement;
			let valueTo = this.valueToRef.current;

			if (valueTo.props.id === focusedElement.id) {
				return this.setState({ previouslyFocusedElement: valueTo.props.id });
			} else if (previouslyFocused === valueTo.props.id) {
				return;
			} else {
				return this.handleClose();
			}
		}, 50);
	};

	handleInputBlur = (e) => {
		this.setState({
			previouslyFocusedElement:
				document.activeElement && document.activeElement.id ? document.activeElement.id : null
		});
	};

	render() {
		const { correspondingFilter, filterFor, buttonText } = this.props;
		return (
			<div className="AccountOverviewFilter">
				<Dropdown
					tabIndex={0}
					icon={null}
					onBlur={(e) => this.handleBlur(e)}
					open={this.state.isOpen}
					floating
					trigger={
						<Button.Group className="small">
							<Button
								basic
								color={correspondingFilter ? 'teal' : 'grey'}
								compact
								icon
								onClick={() => this.handleOpen()}
							>
								{buttonText ? buttonText : 'Filter'}
								{correspondingFilter &&
									`: ${correspondingFilter.min === (0 || "0")
										? 'Any'
										: correspondingFilter.min}-${correspondingFilter.max === Infinity
										? '∞'
										: correspondingFilter.max}`}{' '}
								<Icon name="caret down" />
							</Button>
							{correspondingFilter && (
								<Button basic color="teal" compact icon onClick={() => this.handleCloseForm()}>
									<Icon name="close" />
								</Button>
							)}
						</Button.Group>
					}
				>
					<Dropdown.Menu tabIndex={0}>
						<Dropdown.Item tabIndex={0} className="AccountOverviewFilter--contentContainer">
							<div className="AccountOverviewFilter--content">
								<Form onSubmit={this.handleSubmit} size="small">
									<Form.Field inline>
										<label>From</label>
										<Input
											id={`${filterFor}-valueFrom`}
											ref={this.valueFromRef}
											tabIndex={2}
											// required
											type="number"
											name="valueFrom"
											onChange={(e) => this.handleChange(e)}
											value={this.state.form.valueFrom}
										/>
									</Form.Field>
									<Form.Field inline>
										<label>To</label>
										<Input
											id={`${filterFor}-valueTo`}
											ref={this.valueToRef}
											onBlur={(e) => this.handleInputBlur(e)}
											tabIndex={3}
											// required
											type="number"
											name="valueTo"
											onChange={(e) => this.handleChange(e)}
											value={this.state.form.valueTo}
										/>
									</Form.Field>
									<Form.Button type="submit" tabIndex={3} color="blue" compact>
										Apply
									</Form.Button>
								</Form>
							</div>
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</div>
		);
	}
}

export default AccountOverviewFilter;
