import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { presentations, organizations, general, reporting } from '../../../store/actions';
import './ReportingPresentationTemplates.css';
import ReportPresentationOrgList from './ReportPresentationOrgList/ReportPresentationOrgList';
import ReportPresentationCreate from './ReportPresentationCreate/ReportPresentationCreate';
import { Loader } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import ErrorHandler from '../../../components/ErrorHandler/ErrorHandler';
import { hasPermissions } from '../../../utility';

class ReportingPresentationTemplates extends Component {
	componentDidMount() {
		!this.props.contentObjectTypes[0] && this.props.fetchContentObjectTypes();
		!this.props.slideDeckTemplates[0] && this.props.fetchSlideDeckTemplates();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isCreatingPresentationTemplate !== this.props.isCreatingPresentationTemplate) {
			if (
				prevProps.isCreatingPresentationTemplate === true &&
				this.props.isCreatingPresentationTemplate === false
			) {
				if (
					this.props.newPresentationTemplateOrganizationId &&
					this.props.location.pathname === prevProps.location.pathname
				) {
					this.props.history.push(
						`${this.props.match.path}/update/${this.props.newPresentationTemplateOrganizationId}`
					);
				}
			}
		}
	}

	handleOrganizationSelect = (organization, exists) => {
		if (!organization) {
			return;
		} else {
			if (exists) {
				this.props.history.push(`${this.props.match.path}/update/${organization.id}`);
			} else {
				this.props.history.push(`${this.props.match.path}/create/${organization.id}`);
			}
		}
	};

	handleCreateReportTemplateFromMaster = (orgId) => {
		if (orgId) {
			return this.props.createReportPresentationTemplateFromMaster(orgId);
		}
	};

	isLoadingData = () => {
		if (
			this.props.contentObjectTypesLoading ||
			this.props.reportsLoading ||
			this.props.organizationsLoading ||
			this.props.slideDecksLoading
		) {
			return true;
		} else {
			return false;
		}
	};

	renderLoadingStatus = () => {
		if (this.props.contentObjectTypesLoading) {
			return 'Loading Initial Data';
		}
		if (this.props.reportsLoading) {
			return 'Loading Report Data';
		} else if (this.props.organizationsLoading) {
			return 'Loading Organizational Data';
		} else {
			return 'Loading Presentation Data';
		}
	};

	buildReportLink = (report, organizationId) => {
		const organization =
			this.props.organizations[0] && this.props.organizations.find((o) => o.id === organizationId);
		const planningMonth =
			this.props.planningMonths[0] && this.props.planningMonths.find((month) => month.id === report.month);

		if (organization && planningMonth) {
			return `/reporting/${planningMonth.year}/${planningMonth.month}/${organization.slug}`;
		}
	};

	getMostRecentReport = (organizationId, returnReport) => {
		const organizationReports =
			this.props.reports[0] && this.props.reports.filter((report) => report.organization === organizationId);
		const mostRecentReport =
			organizationReports &&
			organizationReports[0] &&
			organizationReports
				.filter((report) => report.presentation)
				.sort((a, b) => a.date_created - b.date_created)[0];
		const reportLink =
			mostRecentReport && mostRecentReport.presentation && this.buildReportLink(mostRecentReport, organizationId);
		return returnReport ? mostRecentReport : reportLink ? reportLink : null;
	};

	render() {
		return (
			<div className="ReportingPresentationTemplates">
				{!hasPermissions(this.props.user) && <Redirect to='/reporting/' />}
				{this.props.presentationTemplateErrors && (
					<ErrorHandler errors={this.props.presentationTemplateErrors} />
				)}
				{!this.isLoadingData() ? (
					<section className="ReportingPresentationTemplates--main">
						<Switch>
							<Route
								exact
								path={`${this.props.match.path}`}
								render={(props) => (
									<ReportPresentationOrgList
										{...props}
										handleSelect={this.handleOrganizationSelect}
										organizations={this.props.organizations}
										organizationsLoading={this.props.organizationsLoading}
										slideDeckTemplates={this.props.slideDeckTemplates}
										deleteSlideDeckTemplate={this.props.deleteSlideDeckTemplate}
										slideDecksLoading={this.props.slideDecksLoading}
										slideDecksLoaded={this.props.slideDecksLoaded}
										organizationContentObject={
											this.props.contentObjectTypes[0] &&
											this.props.contentObjectTypes.find((cot) => cot.model === 'organization')
										}
										handleCreateReportTemplateFromMaster={this.handleCreateReportTemplateFromMaster}
										getMostRecentReport={this.getMostRecentReport}
										isBulkUpdatingTemplates={this.props.isBulkUpdatingTemplates}
										didBulkUpdateTemplates={this.props.didBulkUpdateTemplates}
										fetchSlideDeckTemplates={this.props.fetchSlideDeckTemplates}
									/>
								)}
							/>
							<Route
								path={`${this.props.match.path}/create/master`}
								render={(props) =>
									this.props.organizations[0] ? (
										<ReportPresentationCreate
											{...props}
											creating
											isMasterTemplate
											reports={this.props.reports}
											organizations={this.props.organizations}
											slideDeckTemplates={this.props.slideDeckTemplates}
											organizationContentObject={
												this.props.contentObjectTypes[0] &&
												this.props.contentObjectTypes.find(
													(cot) => cot.model === 'organization'
												)
											}
										/>
									) : (
										<Loader active>Loading Data...</Loader>
									)}
							/>
							<Route
								path={`${this.props.match.path}/update/master`}
								render={(props) =>
									this.props.organizations[0] ? (
										<ReportPresentationCreate
											{...props}
											updating
											isMasterTemplate
											reports={this.props.reports}
											organizations={this.props.organizations}
											slideDeckTemplates={this.props.slideDeckTemplates}
											organizationContentObject={
												this.props.contentObjectTypes[0] &&
												this.props.contentObjectTypes.find(
													(cot) => cot.model === 'organization'
												)
											}
										/>
									) : (
										<Loader active>Loading Data...</Loader>
									)}
							/>
							<Route
								path={`${this.props.match.path}/create/:orgId`}
								render={(props) =>
									this.props.organizations[0] ? (
										<ReportPresentationCreate
											{...props}
											creating
											reports={this.props.reports}
											organizations={this.props.organizations}
											slideDeckTemplates={this.props.slideDeckTemplates}
											organizationContentObject={
												this.props.contentObjectTypes[0] &&
												this.props.contentObjectTypes.find(
													(cot) => cot.model === 'organization'
												)
											}
										/>
									) : (
										<Loader active>Loading Data...</Loader>
									)}
							/>
							<Route
								path={`${this.props.match.path}/update/:orgId`}
								render={(props) =>
									this.props.organizations[0] ? (
										<ReportPresentationCreate
											{...props}
											updating
											reports={this.props.reports}
											organizations={this.props.organizations}
											slideDeckTemplates={this.props.slideDeckTemplates}
											organizationContentObject={
												this.props.contentObjectTypes[0] &&
												this.props.contentObjectTypes.find(
													(cot) => cot.model === 'organization'
												)
											}
										/>
									) : (
										<Loader active>Loading Data...</Loader>
									)}
							/>
							<Redirect to={this.props.match.path} />
						</Switch>
					</section>
				) : (
					<Loader active>{this.renderLoadingStatus()}</Loader>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		reports: state.reporting.reports,
		reportsLoading: state.reporting.isLoading,
		reportsLoaded: state.reporting.isLoaded,
		organizations: state.organizations.organizations,
		organizationsLoading: state.organizations.isLoading,
		slideDeckTemplates: state.presentations.slideDeckTemplates.data,
		slideDecksLoading: state.presentations.slideDeckTemplates.isLoading,
		slideDecksLoaded: state.presentations.slideDeckTemplates.isLoaded,
		isCreatingPresentationTemplate: state.presentations.slideDeckTemplates.isCreatingPresentationTemplate,
		newPresentationTemplateOrganizationId:
			state.presentations.slideDeckTemplates.newPresentationTemplateOrganizationId,
		presentationTemplateErrors: state.presentations.slideDeckTemplates.presentationTemplateErrors,
		contentObjectTypes: state.general.contentObjectTypes.data,
		contentObjectTypesLoading: state.general.contentObjectTypes.isLoading,
		contentObjectTypesLoaded: state.general.contentObjectTypes.isLoaded,
		planningMonths: state.contentGeneration.planningMonths,
		isBulkUpdatingTemplates: state.reporting.isBulkUpdatingTemplates,
		didBulkUpdateTemplates: state.reporting.didBulkUpdateTemplates
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchSlideDeckTemplates: () => dispatch(presentations.fetchSlideDeckTemplates()),
		fetchOrganizations: () => dispatch(organizations.fetchOrganizations()),
		fetchContentObjectTypes: () => dispatch(general.fetchContentObjectTypes()),
		deleteSlideDeckTemplate: (id, message) => dispatch(presentations.deleteSlideDeckTemplate(id, message)),
		createReportPresentationTemplateFromMaster: (orgId) =>
			dispatch(reporting.createReportPresentationTemplateFromMaster(orgId))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportingPresentationTemplates);
