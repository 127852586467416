import React, { Component } from 'react';
import { connect } from 'react-redux';
import { organizations, reporting } from '../../../store/actions';
import './ReportEntries.css';
import { decamelize } from '../../../utility';
import moment from 'moment';
import { Table, Icon, Button, Select } from 'semantic-ui-react';
import _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { toPascalCase } from '../../../utility';
import ReportEmailEntries from './ReportEmailEntries/ReportEmailEntries';
import ahrefsIcon from '../../../assets/images/brandIcons/ahrefs_icon.png';
import googleAnalyticsIcon from '../../../assets/images/brandIcons/google_analytics-icon.png';
import whatConvertsIcon from '../../../assets/images/brandIcons/whatconverts_icon.png';

class ReportEntries extends Component {
	state = {
		column: 'planningMonth',
		data: this.props.entries && this.props.entries[0] && this.props.entries,
		direction: null,
		selectedReport: null
	};

	componentDidMount() {
		this.props.entries && this.props.entries[0] && !this.props.organizations[0] && this.props.fetchOrganizations();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.entries !== this.props.entries) {
			this.setState({ ...this.state, data: this.props.entries });
			if (prevState.selectedReport && this.state.selectedReport) {
				if (prevState.selectedReport.id === this.state.selectedReport.id) {
					this.handleSelectReport(this.state.selectedReport.id);
				}
			}
		}
	}

	handleSort = (clickedColumn) => () => {
		const { column, data, direction } = this.state;

		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				data: _.sortBy(data, [ clickedColumn ]),
				direction: 'ascending'
			});

			return;
		}

		this.setState({
			data: data.reverse(),
			direction: direction === 'ascending' ? 'descending' : 'ascending'
		});
	};

	handleSelectReport = (reportId) => {
		const report = this.props.entries && this.props.entries[0] && this.props.entries.find((e) => e.id === reportId);
		report && this.setState({ selectedReport: report });
	};

	handleClearReport = () => {
		this.setState({ selectedReport: null });
	};

	getStatus = (status, raw) => {
		return raw ? decamelize(status, ' ') : <span className="reportStatus caps">{decamelize(status, ' ')}</span>;
	};

	getOrganization = (orgID) => {
		const org = this.props.organizations[0] && this.props.organizations.find((org) => org.id === orgID);
		return org ? org.dba_name : null;
	};

	getOrganizationSlug = (orgID) => {
		const org = this.props.organizations[0] && this.props.organizations.find((org) => org.id === orgID);
		return org ? org.slug : null;
	};

	getPresentationUrl = (reportId) => {
		const report = this.props.entries && this.props.entries[0] && this.props.entries.find((e) => e.id === reportId);
		const orgSlug = report && this.getOrganizationSlug(report.organization);
		const month =
			this.props.planningMonths &&
			this.props.planningMonths[0] &&
			this.props.planningMonths.find((m) => m.id === report.month);
		return month && orgSlug ? `/reporting/${month.year}/${month.month}/${orgSlug}` : null;
	};

	getPlanningMonth = (monthId) => {
		const month =
			this.props.planningMonths &&
			this.props.planningMonths[0] &&
			this.props.planningMonths.find((m) => m.id === monthId);
		const monthVerbose = month && `${moment(month.month, 'MM').format('MMM')} ${month.year}`;
		return monthVerbose ? monthVerbose : null;
	};

	handleUpdateReport = async (field, value, reportID) => {
		if (reportID) {
			const report = (await this.props.entries[0]) && this.props.entries.find((e) => e.id === reportID);

			if (report) {
				return this.props.updateReport(report.id, { ...report, [field]: value });
			}
		}
	};

	handleReportSend = (reportID) => {
		if (reportID) {
			const report = this.props.entries[0] && this.props.entries.find((e) => e.id === reportID);
			this.props.sendReport(report.id);
		}
	};

	handleSelectChange = (e, results, reportID) => {
		const { name, value } = results;
		this.handleUpdateReport(name, value, reportID);
	};

	checkAhrefsData = (
		referring_domains_screenshot,
		referring_pages_screenshot,
		organic_keywords_screenshot,
		detailed_organic_keywords_screenshot
	) => {
		if (
			referring_domains_screenshot &&
			referring_pages_screenshot &&
			organic_keywords_screenshot &&
			detailed_organic_keywords_screenshot
		) {
			return true;
		} else {
			return false;
		}
	};

	render() {
		const { column, direction } = this.state;
		const data = this.state.selectedReport
			? this.state.data.filter((d) => d.id === this.state.selectedReport.id)
			: this.state.data;
		return (
			<div className="ReportEntries">
				<Table size="small" compact sortable celled>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell
								sorted={column === 'planningMonth' ? direction : null}
								onClick={this.handleSort('planningMonth')}
								rowSpan="2"
							>
								Month
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={column === 'status' ? direction : null}
								onClick={this.handleSort('status')}
								rowSpan="2"
							>
								Status
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={column === 'date_created' ? direction : null}
								onClick={this.handleSort('date_created')}
								rowSpan="2"
							>
								Created
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={column === 'last_updated' ? direction : null}
								onClick={this.handleSort('last_updated')}
								rowSpan="2"
							>
								Updated
							</Table.HeaderCell>
							<Table.HeaderCell textAlign="center" colSpan="3">
								Data Status
							</Table.HeaderCell>
							<Table.HeaderCell textAlign="center" rowSpan="2">
								Actions
							</Table.HeaderCell>
						</Table.Row>
						<Table.Row>
							<Table.HeaderCell className="tableBorderLeft">
								<div className="brandIcon">
									<img src={googleAnalyticsIcon} alt="Google Analytics Icon" />
								</div>
							</Table.HeaderCell>
							<Table.HeaderCell>
								<div className="brandIcon">
									<img src={whatConvertsIcon} alt="WhatConverts Icon" />
								</div>
							</Table.HeaderCell>
							<Table.HeaderCell>
								<div className="brandIcon">
									<img src={ahrefsIcon} alt="AHrefs Icon" />
								</div>
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{_.map(
							_.sortBy(data.filter((d) => this.getPlanningMonth(d.month)), [ this.state.column ]),
							({
								id,
								month,
								status,
								presentation,
								date_created,
								last_updated,
								isUpdating,
								creatingReport,
								reCreatingReport,
								google_analytics_data,
								what_converts_data,
								referring_domains_screenshot,
								referring_pages_screenshot,
								organic_keywords_screenshot,
								detailed_organic_keywords_screenshot,
								isSendingEmail
							}) => (
								<Table.Row key={id}>
									<Table.Cell>{this.getPlanningMonth(month)}</Table.Cell>
									<Table.Cell className="overflowVisible">
										{status !== 'sent' ? (
											<div className="ReportingMonthTable--status">
												<Select
													name="status"
													loading={isUpdating}
													className="ReportingMonthTable--statusDropdown"
													onChange={(e, results) => this.handleSelectChange(e, results, id)}
													selection
													size="mini"
													value={status}
													options={[
														{
															key: 'backlog',
															text: 'Backlog',
															value: 'backlog'
														},
														{
															key: 'assigned',
															text: 'Assigned',
															value: 'assigned'
														},
														{
															key: 'finalReview',
															text: 'Final Review',
															value: 'finalReview'
														},
														{
															key: 'sent',
															text: 'Sent',
															value: 'sent'
														}
													]}
												/>
											</div>
										) : (
											<span className="ReportingMonthTable--sentStatus">
												{toPascalCase(status)}
											</span>
										)}
									</Table.Cell>
									<Table.Cell>{moment(date_created).format('MMM D, YYYY')}</Table.Cell>
									<Table.Cell>{moment(last_updated).format('MMM D, YYYY')}</Table.Cell>
									<Table.Cell textAlign="center">
										{google_analytics_data ? (
											<Icon color="green" name="checkmark" />
										) : (
											<Icon color="red" name="times" />
										)}
									</Table.Cell>
									<Table.Cell textAlign="center">
										{what_converts_data ? (
											<Icon color="green" name="checkmark" />
										) : (
											<Icon color="red" name="times" />
										)}
									</Table.Cell>
									<Table.Cell textAlign="center">
										{this.checkAhrefsData(
											referring_domains_screenshot,
											referring_pages_screenshot,
											organic_keywords_screenshot,
											detailed_organic_keywords_screenshot
										) ? (
											<Icon color="green" name="checkmark" />
										) : (
											<Icon color="red" name="times" />
										)}
									</Table.Cell>
									<Table.Cell>
										{presentation ? (
											<div className="ReportingMonthTable-createActions">
												<NavLink to={this.getPresentationUrl(id)}>
													<Button
														color="blue"
														size="mini"
														loading={creatingReport || reCreatingReport}
														icon
														labelPosition="right"
													>
														View <Icon name="eye" />
													</Button>
												</NavLink>
												<Button
													onClick={() =>
														this.props.reCreateReportPresentation(id, 'Report Re-Created!')}
													loading={creatingReport || reCreatingReport}
													size="mini"
													basic
													icon
													labelPosition="right"
												>
													Re-Create <Icon name="redo" />
												</Button>
												{status === 'finalReview' && (
													<div className="ReportingMonthTable--sentButton">
														<Button
															onClick={() => this.handleReportSend(id)}
															color="green"
															size="mini"
															loading={isSendingEmail}
															icon
															labelPosition="right"
														>
															Send Report <Icon name="send" />
														</Button>
													</div>
												)}
												{status === 'sent' && (
													<div className="ReportingMonthTable--sentButton">
														<Button
															basic
															onClick={() => this.handleReportSend(id)}
															color="green"
															size="mini"
															loading={isSendingEmail}
															icon
															labelPosition="right"
														>
															Re-Send <Icon name="send" />
														</Button>
													</div>
												)}
												{this.state.selectedReport ? (
													<Button
														onClick={() => this.handleClearReport()}
														icon
														color="red"
														size="mini"
														labelPosition="right"
													>
														Close
														<Icon name="close" />
													</Button>
												) : (
													<Button
														onClick={() => this.handleSelectReport(id)}
														icon
														size="mini"
														labelPosition="right"
													>
														Notifications <Icon name="mail" />
													</Button>
												)}
											</div>
										) : (
											<Button
												onClick={() =>
													this.props.createReportPresentation(id, 'Report Created!')}
												loading={creatingReport || reCreatingReport}
												color="teal"
												size="mini"
												labelPosition='right'
												icon
											>
												Create Presentation
												<Icon name="area graph" />
											</Button>
										)}
									</Table.Cell>
								</Table.Row>
							)
						)}
					</Table.Body>
				</Table>
				{this.state.selectedReport && (
					<div className="ReportEntries--emailEntries">
						<ReportEmailEntries report={this.state.selectedReport} />
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		organizations: state.organizations.organizations,
		organizationsLoading: state.organizations.isLoading,
		organizationsLoaded: state.organizations.isLoaded,
		planningMonths: state.contentGeneration.planningMonths
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchOrganizations: () => dispatch(organizations.fetchOrganizations()),
		updateReport: (id, report) => dispatch(reporting.updateReport(id, report)),
		sendReport: (id) => dispatch(reporting.sendReport(id)),
		createReportPresentation: (id, message) => dispatch(reporting.createReportPresentation(id, message)),
		reCreateReportPresentation: (id, message) => dispatch(reporting.reCreateReportPresentation(id, message))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportEntries);
