import React, { Component } from 'react';
import './UserDataAccounts.css';
import { Table, Checkbox, Button, Icon, Transition, Select, Header, Divider } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { organizations, users } from '../../../store/actions';
import { hasPermissions } from '../../../utility';
import AccountForm from '../../../forms/AccountForm/AccountForm';

class UserDataAccounts extends Component {
	state = {
		isAddingAccount: false
	};
	componentDidMount() {
		!this.props.organizations[0] && this.props.fetchOrganizations();
	}

	renderGroupName = (groupId) => {
		const foundGroup = this.props.groups.find((group) => group.id === groupId);

		return foundGroup.name;
	};

	renderOrganizationName = (orgId) => {
		const foundOrganization = this.props.organizations.find((organization) => organization.id === orgId);

		return foundOrganization && foundOrganization.dba_name;
	};

	shouldShowAccount = (orgId) => {
		const foundOrganization = this.props.organizations.find((organization) => organization.id === orgId);

		return foundOrganization && foundOrganization.is_active;
	}

	handleAddingAccount = () => {
		this.setState({ isAddingAccount: true });
	};

	handleCancelAddingAccount = () => {
		this.setState({ isAddingAccount: false });
	};

	async handleChange(account, name, checked, type, value) {
		await this.props.updateAccount(
			account.id,
			{
				...account,
				[name]: type === 'checkbox' ? checked : value
			},
			'Account Updated.'
		);
	}

	handleSelectChange = async (account, name, value) => {
		await this.props.updateAccount(
			account.id,
			{
				...account,
				[name]: value
			},
			'Account Updated.'
		);
	};

	render() {
		const allowedRoles = [ 1, 3, 5, 8 ];
		const accounts = this.props.accounts && this.props.accounts[0] && this.props.accounts.filter(account => account.organization && this.shouldShowAccount(account.organization));
		return (
			<div className="UserDataAccounts">
				{accounts && accounts[0] ? (
					<section className="UserDataAccounts--table">
					<Table structured size="small" celled compact>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell textAlign="center" rowSpan="2">
									Account
								</Table.HeaderCell>
								<Table.HeaderCell textAlign="center" rowSpan="2">
									Role
								</Table.HeaderCell>
								<Table.HeaderCell className="muted" textAlign="center" colSpan="2">
									Notifications
								</Table.HeaderCell>
							</Table.Row>
							<Table.Row>
								<Table.HeaderCell textAlign="center">Reports</Table.HeaderCell>
								<Table.HeaderCell textAlign="center">Approval Reminders</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body className="extraPadding">
							{accounts.map((account) => (
								<Table.Row key={account.id}>
									<Table.Cell>{this.renderOrganizationName(account.organization)}</Table.Cell>
									<Table.Cell className={`${account.isUpdating ? 'isUpdating' : 'notUpdating'}`}>
										<Select
											id="role"
											selection
											required
											size="tiny"
											disabled={account.isUpdating}
											name="role"
											className='lowPadding'
											value={account.role}
											onChange={(e, data) => this.handleSelectChange(account,
												data.name,
												data.value)}
											options={
												this.props.groups[0] &&
												this.props.groups
													.filter((group) => allowedRoles.includes(group.id))
													.map((group) => ({
														key: group.id,
														text: this.renderGroupName(group.id),
														value: group.id
													}))
											}
										/>
									</Table.Cell>
									<Table.Cell
										className={`${account.isUpdating ? 'isUpdating' : 'notUpdating'}`}
										textAlign="center"
									>
										<Checkbox
											name="receive_reporting_email"
											onChange={(e, data) =>
												this.handleChange(
													account,
													data.name,
													data.checked,
													data.type,
													data.value
												)}
											className="alignMiddle"
											checked={account.receive_reporting_email}
											disabled={account.isUpdating}
										/>
									</Table.Cell>
									<Table.Cell
										className={`${account.isUpdating ? 'isUpdating' : 'notUpdating'}`}
										textAlign="center"
									>
										<Checkbox
											name="receive_approval_reminders"
											onChange={(e, data) =>
												this.handleChange(
													account,
													data.name,
													data.checked,
													data.type,
													data.value
												)}
											className="alignMiddle"
											checked={account.receive_approval_reminders}
											disabled={account.isUpdating}
										/>
									</Table.Cell>
								</Table.Row>
							))}
						</Table.Body>
					</Table>
				</section>
				) : (
					<div className="UserDataAccounts--noData">
						<Header as="h5">
						This user does not currently have any Accounts associated with them.
						<Header.Subheader>You can begin by adding an account below.</Header.Subheader>
						</Header>
						<Divider />
					</div>
				)}
				<Transition visible={this.state.isAddingAccount} animation="fade down" duration={500}>
					<section className="UserDataAccounts--form">
						<AccountForm
							size="small"
							accountUser={this.props.accountUser}
							withActions
							handleCancelAddingAccount={this.handleCancelAddingAccount}
						/>
					</section>
				</Transition>
				{hasPermissions(this.props.user, 'add_userorgrole') &&
				!this.state.isAddingAccount && (
					<section className="UserDataAccounts--actions">
						<Button onClick={() => this.handleAddingAccount()} positive icon>
							<Icon name="plus" />
						</Button>
					</section>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		groups: state.auth.groups,
		organizations: state.organizations.organizations
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchOrganizations: () => {
			dispatch(organizations.fetchOrganizations());
		},
		updateAccount: (id, data, message) => dispatch(users.updateAccount(id, data, message))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDataAccounts);
