import React from "react";
import "./AppList.css";
import AppListItem from "./AppListItem/AppListItem";
import { Loader } from "semantic-ui-react";

const AppList = ({ apps, isLoaded, isLoading }) => {
  return (
    <div className="AppList">
      {isLoaded && !isLoading ? (
          apps.filter(app => !app.scheduled_for_deletion).map(app => (
            <AppListItem key={app.id} app={app} />
          ))
      ) : (
          <Loader active />
      )}
    </div>
  );
};

export default AppList;
