import React from "react";
import "./CommentDetail.css";
import { Segment, Header } from "semantic-ui-react";
import moment from "moment";

const CommentDetail = ({ comment }) => {
  return (
    <Segment className="CommentDetail">
      <div className="CommentDetail--header">
        <Header as="h4">{comment.author.name}</Header>
        <span className="CommentDetail--header__date">
          {moment(comment.date_created).format("MMM Do, YY")}
        </span>
      </div>
      <div className="CommentDetail--comment">{comment.comment}</div>
    </Segment>
  );
};

export default CommentDetail;
