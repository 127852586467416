import React, { Component } from 'react';
import './BulkReportPresentationUpdateForm.css';
import { connect } from 'react-redux';
import { reporting, presentations } from '../../store/actions';
import { Form, Transition, Button, Checkbox, Loader, Dimmer } from 'semantic-ui-react';
import FormMessage from '../../components/FormMessage/FormMessage';

const initialFormState = {
	organizations: [],
	selectAll: false
};

class BulkReportPresentationUpdateForm extends Component {
	state = {
		form: initialFormState
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.state.form.selectAll !== prevState.form.selectAll) {
			if (this.state.form.selectAll) {
				this.setState({
					form: {
						...this.state.form,
						organizations: this.props.organizations
							.filter((organization) => organization.report_required)
							.map((org) => org.id)
					}
				});
			} else {
				this.clearForm();
			}
		}
	}

	clearForm = () => {
		this.setState({ form: initialFormState });
	};

	handleChange(e) {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
			}
		});
	}

	handleSelectChange = (event, { name, value }) => {
		this.setState({ form: { ...this.state.form, [name]: value } });
	};

	handleSubmit = (e) => {
		e.preventDefault();
		if (this.state.form.organizations && this.state.form.organizations[0]) {
			this.props.bulkReportPresentationTemplateUpdate(this.state.form.organizations);
		}
	};

	render() {
		return (
			<div className="BulkReportPresentationUpdateForm">
				{this.props.isBulkUpdatingTemplates && (
					<Dimmer active inverted>
						<Loader>Updating Templates</Loader>
					</Dimmer>
				)}
				<Transition.Group animation="slide down" duration={500}>
					{this.props.errors &&
						this.props.errors[0] &&
						this.props.errors.map((err) => <FormMessage>{err}</FormMessage>)}
				</Transition.Group>
				<Form onSubmit={this.handleSubmit}>
					<Form.Group>
						<Form.Field width={14}>
							<label htmlFor="organizations">Organizations</label>
							<Form.Select
								id="organization"
								selection
								multiple
								search
								required
								name="organizations"
								placeholder="Organizations"
								value={this.state.form.organizations}
								onChange={this.handleSelectChange}
								options={
									this.props.organizations[0] &&
									this.props.organizations
										.filter((organization) => organization.report_required)
										.map((organization) => ({
											key: organization.id,
											text: organization.dba_name,
											value: organization.id
										}))
								}
							/>
						</Form.Field>
						<Form.Field width={2} className="centerCheckbox">
							<label htmlFor="selectAll">Select All</label>
							<Checkbox
								id="selectAll"
								name="selectAll"
								onChange={(e) => this.handleChange(e)}
								checked={this.state.form.selectAll}
							/>
						</Form.Field>
					</Form.Group>
					<Form.Field>
						<Button type="submit" positive>
							Proceed
						</Button>
					</Form.Field>
				</Form>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		isBulkUpdatingTemplates: state.reporting.isBulkUpdatingTemplates,
		didBulkUpdateTemplates: state.reporting.didBulkUpdateTemplates,
		organizations: state.organizations.organizations
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchSlideDeckTemplates: () => dispatch(presentations.fetchSlideDeckTemplates()),
		bulkReportPresentationTemplateUpdate: (orgIds) =>
			dispatch(reporting.bulkReportPresentationTemplateUpdate(orgIds, 'Presentation Templates Updated.'))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkReportPresentationUpdateForm);
