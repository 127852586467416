import React, { Component } from 'react';
import './AhrefsScreenshot.css';
import { Image, Loader, Header } from 'semantic-ui-react';
import referringDomainsSample from './referring-domains-sample.png';
import referringPagesSample from './referring-pages-sample.png';
import organicKeywordsSample from './organic-keywords-sample.png';
import detailedOrganicKeywordsSample from './detailed-organic-keywords-sample.png';

class AhrefsScreenshot extends Component {
	state = {
		isLoading: true,
		errors: null
	};

	handleFinishLoad = () => {
		this.setState({ isLoading: false, errors: null });
	};

	handleError = () => {
		this.setState({ isLoading: false, errors: 'failed to load' });
	};

	render() {
		const { data, screenshotType, previewing } = this.props;

		const getScreenshotUrl = (screenshotType) => {
			if (!previewing) {
				const url = data && Object.keys(data).find((k) => k === screenshotType);
				return url ? data[url] : null;
			} else {
				switch (screenshotType) {
					case 'referring_domains_screenshot':
						return referringDomainsSample;
					case 'referring_pages_screenshot':
						return referringPagesSample;
					case 'organic_keywords_screenshot':
						return organicKeywordsSample;
					case 'detailed_organic_keywords_screenshot':
						return detailedOrganicKeywordsSample;
					default:
						return null;
				}
			}
		};

		return (
			<div className="AhrefsScreenshot">
				{getScreenshotUrl(screenshotType) ? (
					<React.Fragment>
						{this.state.isLoading && <Loader inline="centered" active />}
						{this.props.screenshotDescription && (
							<Header as="h5" content={this.props.screenshotDescription} />
						)}
						<Image
							centered
							src={getScreenshotUrl(screenshotType)}
							alt={screenshotType}
							onLoad={this.handleFinishLoad}
							onError={this.handleError}
						/>
					</React.Fragment>
				) : (
					<p>{screenshotType} Report not Found</p>
				)}
			</div>
		);
	}
}

export default AhrefsScreenshot;
