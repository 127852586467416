import React, { Component } from 'react';
import SortableTable from '../../../components/SortableTable/SortableTable';
import ActionHeader from '../../../components/ActionHeader/ActionHeader';
import { Button, Icon } from 'semantic-ui-react';
import FixedLoader from '../../../components/FixedLoader/FixedLoader';
import FormModal from '../../../components/FormModal/FormModal';
import ContentProjectForm from '../../../components/ContentProjectForm/ContentProjectForm';
import { connect } from 'react-redux';
import { contentGeneration, organizations } from '../../../store/actions';
import moment from 'moment';
import NotFound from '../../../components/NotFound/NotFound';
/*eslint eqeqeq: "off"*/

class ContentProjects extends Component {
	state = {
		updatedProject: false
	};

	componentDidMount() {
		this.props.project && this.props.fetchProjectArticles(this.props.project.id);
		!this.props.writers[0] && this.props.fetchWriters();
		!this.props.organizations[0] && this.props.fetchOrganizations();
		!this.props.comments[0] && this.props.fetchContentComments();
		this.props.contentStaff && !this.props.contentStaff[0] && this.props.fetchContentStaff();
		!this.props.contentStatuses[0] && this.props.fetchContentStatuses();
	}

	componentDidUpdate(prevProps) {
		if (this.props.project) {
			if (this.props.project.id !== prevProps.project.id) {
				this.props.fetchProjectArticles(this.props.project.id);
			}
			if (
				this.props.project.due_date !== prevProps.project.due_date ||
				this.props.project.name !== prevProps.project.name
			) {
				this.setState({ updatedProject: true });
			}
		}
	}

	render() {
		return (
			<div className="ContentProjects">
				{!this.props.isLoadingProjects &&
				this.props.contentProjects &&
				this.props.contentProjects[0] &&
				this.props.project ? (
					<div>
						<ActionHeader
							headerText={`${this.props.project && this.props.project.name}${this.props.project &&
								this.props.project.due_date &&
								` - Due: ${moment(this.props.project.due_date).format('MMM Do')}`}`}
						>
							<FormModal
								closeModal={this.state.updatedProject}
								headerText="Edit Project"
								headerIcon="pencil"
								actionTrigger={
									<Button icon color="blue" labelPosition="left" basic>
										<Icon name="pencil" /> Edit Project
									</Button>
								}
							>
								<ContentProjectForm {...this.props} data={this.props.project} />
							</FormModal>
							<FormModal
								headerText="Add Content Project"
								headerIcon="paperclip"
								actionTrigger={
									<Button icon labelPosition="left" basic>
										<Icon name="paperclip" /> Add Content Project
									</Button>
								}
							>
								<ContentProjectForm {...this.props} />
							</FormModal>
							{!this.props.isAddingArticle && (
								<Button
									color="green"
									icon
									labelPosition="right"
									size="medium"
									onClick={() => this.props.handleAddingContent()}
								>
									<Icon name="add" />
									Add Content Article
								</Button>
							)}
						</ActionHeader>
						{!this.props.isLoading && this.props.isLoaded ? (
							<SortableTable
								projects={true}
								selectedItem={this.props.articleToEdit}
								writers={this.props.writers}
								organizations={this.props.organizations}
								handleSelect={this.props.selectArticle}
								originalArticles={this.props.contentArticles}
								contentStaff={this.props.contentStaff}
								contentStatuses={this.props.contentStatuses}
								tableData={this.props.contentArticles.filter(
									(contentArticle) =>
										contentArticle.project && contentArticle.project.id == this.props.project.id
								)}
							/>
						) : (
							<FixedLoader />
						)}
					</div>
				) : (
					<NotFound />
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		contentArticles: state.contentGeneration.contentArticles,
		writers: state.contentGeneration.writers,
		isLoading: state.contentGeneration.isLoadingArticles,
		isLoaded: state.contentGeneration.articlesLoaded,
		isAddingArticle: state.contentGeneration.isAddingArticle,
		comments: state.contentGeneration.contentComments,
		organizations: state.organizations.organizations,
		isLoadingProjects: state.contentGeneration.isLoadingProjects,
		contentProjects: state.contentGeneration.contentProjects,
		contentStaff: state.contentGeneration.contentStaff,
		contentStatuses: state.contentGeneration.contentStatuses,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchProjectArticles: (projectID) => {
			dispatch(contentGeneration.fetchProjectArticles(projectID));
		},
		fetchContentComments: () => {
			return dispatch(contentGeneration.fetchContentComments());
		},
		fetchWriters: () => {
			return dispatch(contentGeneration.fetchWriters());
		},
		fetchOrganizations: () => {
			return dispatch(organizations.fetchOrganizations());
		},
		fetchContentStaff: () => dispatch(contentGeneration.fetchContentStaff()),
		fetchContentStatuses: () => {
			return dispatch(contentGeneration.fetchContentStatuses())
		  },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentProjects);
