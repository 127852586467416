import * as actionTypes from "../actions/actionTypes";
import axios from "../../axios-portal";

export const updateServiceWorker = () => {
  return {
    type: actionTypes.UPDATE_SERVICE_WORKER,
    serviceWorkerUpdated: true
  }
}

export const dismissNotification = (index) => {
  return {
    type: actionTypes.DISMISS_NOTIFICATION,
    index: index
  }
}

export const setNotifications = notifications => {
  return {
    type: actionTypes.SET_NOTIFICATIONS,
    notifications: notifications
  };
};

export const fetchNotificationsFailed = errors => {
  return {
    type: actionTypes.FETCH_NOTIFICATIONS_FAILED,
    errors: errors
  };
};

export const addNotificationSuccess = notification => {
  return {
    type: actionTypes.ADD_NOTIFICATION_SUCCESS,
    notification: notification
  };
};

export const updateNotificationSuccess = (notification, index) => {
  return {
    type: actionTypes.UPDATE_NOTIFICATION_SUCCESS,
    notification: notification,
    index: index
  };
};

export const deleteNotificationSuccess = index => {
  return {
    type: actionTypes.DELETE_NOTIFICATION_SUCCESS,
    index: index
  };
};

export const notificationFailure = errors => {
  return {
    type: actionTypes.NOTIFICATION_FAILURE,
    errors: errors
  };
};


export const fetchNotifications = () => {
  return dispatch => {
    axios
      .get("notifications.json")
      .then(response => {
        return dispatch(setNotifications(response.data));
      })
      .catch(err => {
        dispatch(fetchNotificationsFailed(err.response.data));
      });
  };
};

export const addNotification = text => {
  return (dispatch, getState) => {
    let body = JSON.stringify({ text });

    return axios
      .post("notifications.json", body)
      .then(response => {
        return dispatch(addNotificationSuccess(response.data));
      })
      .catch(err => {
        dispatch(notificationFailure(err.response.data));
      });
  };
};

export const updateNotification = (index, text) => {
  return (dispatch, getState) => {
    let body = JSON.stringify({ text });
    let notificationId = getState().notifications.notifications[index].id;

    return axios
      .put(`notifications/${notificationId}/`, body)
      .then(response => {
        return dispatch(updateNotificationSuccess(response.data, index));
      })
      .catch(err => {
        dispatch(notificationFailure(err.response.data));
      });
  };
};

export const deleteNotification = index => {
  return (dispatch, getState) => {
    let notificationId = getState().notifications.notifications[index].id;

    return axios
      .delete(`notifications/${notificationId}/`)
      .then(response => {
        dispatch(deleteNotificationSuccess(index));
      })
      .catch(err => {
        dispatch(notificationFailure(err.response.data));
      });
  };
};


// System Notifications

export const startFetchSystemNotifications = () => {
  return {
    type: actionTypes.START_FETCH_SYSTEM_NOTIFICATIONS,
  }
}

export const fetchSystemNotificationsSuccess = (notifications) => {
  return {
    type: actionTypes.FETCH_SYSTEM_NOTIFICATIONS_SUCCESS,
    notifications: notifications
  }
}

export const fetchSystemNotificationsFailure = (errors) => {
  return {
    type: actionTypes.FETCH_SYSTEM_NOTIFICATIONS_FAILURE,
    errors: errors
  }
}

export const fetchSystemNotifications = () => {
  return dispatch => {
    axios
      .get("system-notifications.json")
      .then(response => {
        return dispatch(fetchSystemNotificationsSuccess(response.data));
      })
      .catch(err => {
        dispatch(fetchSystemNotificationsFailure(err));
      });
  };
};