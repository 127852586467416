// Check if user has permissions
export const hasPermissions = (user, perm, critical) => {
  if (user) {
    if (!critical ? user.is_staff || user.is_superuser : user.is_superuser) {
      return true;
    } else if (perm) {
      const existing_perm = user.permissions.find(
        (permission) => permission.codename === perm
      );
      if (existing_perm) {
        return true;
      } else {
        return false;
      }
    }
  } else {
    return false;
  }
};

export const hasRole = (user, groupID, orgID) => {
  if (user) {
    if (user.is_staff || user.is_superuser) {
      return true;
    } else if (groupID && orgID) {
      const userOrgRoles = user.org_roles;

      if (userOrgRoles[0]) {
        const correspondingRole = userOrgRoles.find(
          (orgRole) =>
            orgRole.role === groupID && orgRole.organization === orgID
        );

        return correspondingRole ? true : false;
      }
    }
  } else {
    return false;
  }
};

export const hasMultipleAccounts = (user) => {
  if (user) {
    if (user.is_staff || user.is_superuser) {
      return true;
    } else {
      if (user.org_roles && user.org_roles[0]) {
        if (user.org_roles.length > 1) {
          return true;
        } else {
          return false;
        }
      }
    }
  }
  return false;
};

// Get only unique array values
export const uniqueOnly = (originalArray, key, { ...data }) => {
  const newArray = [];
  const map = new Map();
  for (const item of originalArray) {
    if (!map.has(item[key])) {
      map.set(item[key], true);
      newArray.push({
        data,
      });
    }
  }
  return newArray;
};

export const getUniqueByKey = (array, key) => {
  return [...new Map(array.map((item) => [item[key], item])).values()];
};

export const decamelize = (string, separator) => {
  separator = typeof separator === "undefined" ? "_" : separator;

  return string
    .replace(/([a-z\d])([A-Z])/g, "$1" + separator + "$2")
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, "$1" + separator + "$2")
    .toLowerCase();
};

export const isMobileDevice = () => {
  return (
    typeof window.orientation !== "undefined" ||
    navigator.userAgent.indexOf("IEMobile") !== -1
  );
};

export const toCamelCase = (str) =>
  str
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
    .replace(/^\w/, (c) => c.toLowerCase());

export const toPascalCase = (str) =>
  str
    .match(/[a-z]+/gi)
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    })
    .join("");

export const toTitleCase = (str) => {
  return str.replace(/(^|\s)\S/g, function (t) {
    return t.toUpperCase();
  });
};

export const moveArrayItem = (array, fromIndex, toIndex) => {
  array.splice(toIndex, 0, array.splice(fromIndex, 1)[0]);
  return array;
};

export const getSearchParams = (searchParams) => {
  if (searchParams) {
    const params = new URLSearchParams(searchParams);
    if (params) {
      let output = {};
      for (const [key, value] of params.entries()) {
        output[key] = value;
      }
      return output;
    } else {
      return null;
    }
  }
};

export const parseSearchParams = (searchParams, desiredParam, all) => {
  if (searchParams) {
    const params = new URLSearchParams(searchParams);

    if (params) {
      const foundParam = all
        ? params.getAll(desiredParam)
        : params.get(desiredParam);
      return foundParam && foundParam;
    }
  }
};

export const refreshCacheAndReload = () => {
  console.log("Clearing cache and hard reloading...");
  if (caches) {
    caches.keys().then(async function (names) {
      await Promise.all(names.map((name) => caches.delete(name)));
    });
  }
  window.location.reload(true);
};

export const copyToClipboard = (clip) => {
  navigator.clipboard.writeText(clip).then(
    () => {
      return true;
    },
    () => {
      return false;
    }
  );
};

export const parseStringParameters = (path, obj) => {
  return path.split(".").reduce((a, b) => a[b], obj);
};

export const capitalizeFirstLetter = (string) => {
  try {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } catch {
    return null;
  }
};

export const parseFirstNameLastInitial = (name) => {
  const names = name.split(" ");
  let firstNameLastInitial = names[0].substring(0)

  if (names.length > 1) {
    firstNameLastInitial += ` ${names[names.length - 1]
      .substring(0, 1)
      .toUpperCase()}`;
  }
  return firstNameLastInitial;
};
