import React, { Component } from "react";
import { connect } from "react-redux";
import { appManagement } from "../../store/actions";
import "./AppManagement.css";
import Navbar from "../../components/Navbar/Navbar";
import { Route, Switch } from "react-router-dom";
import AppManagementDirectory from "./AppManagementDirectory/AppManagementDirectory";
import AppView from "./AppView/AppView";

class AppManagement extends Component {
  componentDidMount() {
    !this.props.apps[0] && this.props.fetchApps();
  }
  render() {
    return (
      <div className="RouteContainer">
        <Navbar matchPath={this.props.matchPath} routeHeader="App Management" />
        <section className="AppManagement">
          <Switch>
            <Route
              exact
              path="/apps"
              render={props => (
                <AppManagementDirectory
                  {...props}
                  apps={this.props.apps}
                  isLoading={this.props.isLoading}
                  isLoaded={this.props.isLoaded}
                />
              )}
            />
            <Route
              exact
              path={`${this.props.match.path}/:appID`}
              render={props => (
                <AppView
                  {...props}
                />
              )}
            />
          </Switch>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    apps: state.appManagement.applications.apps,
    isLoading: state.appManagement.applications.isLoading,
    isLoaded: state.appManagement.applications.isLoaded,
    errors: state.appManagement.applications.errors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchApps: () => dispatch(appManagement.fetchApps())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppManagement);
