import React from "react";
import { Icon } from "semantic-ui-react";
import "./StarRating.css";

const StarRating = props => {
  return (
    <div className="StarRating">
      <label>
        <input onChange={e => props.handleChange(e)} type="radio" name="stars" value="1" />
        <span className="icon"><Icon name="star" /></span>
      </label>
      <label>
        <input onChange={e => props.handleChange(e)} type="radio" name="stars" value="2" />
        <span className="icon"><Icon name="star" /></span>
        <span className="icon"><Icon name="star" /></span>
      </label>
      <label>
        <input onChange={e => props.handleChange(e)} type="radio" name="stars" value="3" />
        <span className="icon"><Icon name="star" /></span>
        <span className="icon"><Icon name="star" /></span>
        <span className="icon"><Icon name="star" /></span>
      </label>
      <label>
        <input onChange={e => props.handleChange(e)} type="radio" name="stars" value="4" />
        <span className="icon"><Icon name="star" /></span>
        <span className="icon"><Icon name="star" /></span>
        <span className="icon"><Icon name="star" /></span>
        <span className="icon"><Icon name="star" /></span>
      </label>
      <label>
        <input onChange={e => props.handleChange(e)} type="radio" name="stars" value="5" />
        <span className="icon"><Icon name="star" /></span>
        <span className="icon"><Icon name="star" /></span>
        <span className="icon"><Icon name="star" /></span>
        <span className="icon"><Icon name="star" /></span>
        <span className="icon"><Icon name="star" /></span>
      </label>
    </div>
  );
};

export default StarRating;
