import React from 'react';
import './ArticleHistoryChartFigure.css';
import { Segment } from 'semantic-ui-react';

export const ArticleHistoryChartFigure = ({ count, countType, countTypeVerbose, offset }) => {
	return (
		<div className={`ArticleHistoryChartFigure ${countType}`}>
			<Segment>
				<div className="ArticleHistoryChartFigure--top">
					<span className="ArticleHistoryChartFigure--count">{count}</span>
                    <span className="ArticleHistoryChartFigure--indicator"></span>
				</div>
				<div className="ArticleHistoryChartFigure--bottom">
					<span className="ArticleHistoryChartFigure--name">{countTypeVerbose}</span>
					{/* {offset && <span className="ArticleHistoryChartFigure--offset">({offset} days)</span>} */}
				</div>
			</Segment>
		</div>
	);
};
