import * as actionTypes from "../actions/actionTypes";
import axios from "../../axios-portal";

export const addMessage = (message, negative) => {
    return {
      type: actionTypes.ADD_MESSAGE,
      message: message,
      negative: negative
    };
  };
  
  export const removeMessage = (message, timeToWait) => {
    return {
      type: actionTypes.REMOVE_MESSAGE,
      message: message,
      timeToWait: timeToWait
    };
  };


export const startLinkCheck = (url) => {
    return {
        type: actionTypes.START_LINK_CHECK,
        url: url
    }
}

export const linkCheckSuccess = (url, report) => {
    return {
        type: actionTypes.LINK_CHECK_SUCCESS,
        url: url,
        report: report,
    }
}

export const linkCheckFailure = (url, errors) => {
    return {
        type: actionTypes.LINK_CHECK_FAILURE,
        url: url,
        errors: errors,
    }
}

export const checkLinks = (url) => {
    return dispatch => {
      dispatch(startLinkCheck(url));
      let body = JSON.stringify({
        url: url,
      });
      axios
        .post(`utilities/check-broken-links/`, body)
        .then(response => {
          return dispatch(linkCheckSuccess(url, response.data));
        })
        .catch(err => {
          dispatch(linkCheckFailure(url, err));
        });
    };
  };