import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
	reports: [],
	isLoading: false,
	isLoaded: false,
	errors: null,
	messages: [],
	isBulkUpdatingTemplates: false,
	didBulkUpdateTemplates: false
};

const addMessage = (state, action) => {
	return updateObject(state, {
		messages: state.messages.concat(action.message)
	});
};

const removeMessage = (state, action) => {
	let messagesList = state.messages.slice();
	messagesList.splice(0, 1);
	return updateObject(state, { messages: messagesList });
};

const startFetchReports = (state, action) => {
	return updateObject(state, {
		isLoading: true,
		isLoaded: false,
		errors: null
	});
};
const fetchReportsSuccess = (state, action) => {
	return updateObject(state, {
		reports: action.reports,
		isLoading: false,
		isLoaded: true,
		errors: null
	});
};
const fetchReportsFailure = (state, action) => {
	return updateObject(state, {
		isLoading: false,
		isLoaded: false,
		errors: action.errors
	});
};
const startUpdateReport = (state, action) => {
	return updateObject(state, {
		reports: state.reports.map(
			(report, i) =>
				i === action.reportIndex
					? {
							...report,
							isUpdating: true
						}
					: report
		),
		isLoading: false,
		isLoaded: false,
		errors: null
	});
};

const updateReportSuccess = (state, action) => {
	let reportIndex = state.reports.findIndex((x) => x.id === action.id);
	return updateObject(state, {
		reports: state.reports.map(
			(report, i) =>
				i === reportIndex
					? {
							...action.report,
							isUpdating: false
						}
					: report
		),
		isLoading: false,
		isLoaded: false,
		errors: null
	});
};
const updateReportFailure = (state, action) => {
	return updateObject(state, {
		isLoading: false,
		isLoaded: false,
		errors: action.errors
	});
};
const startSendReport = (state, action) => {
	return updateObject(state, {
		reports: state.reports.map(
			(report, i) =>
				i === action.reportIndex
					? {
							...report,
							isSendingEmail: true,
							didSendEmails: false
						}
					: report
		),
		isLoading: false,
		isLoaded: false,
		errors: null
	});
};
const sendReportSuccess = (state, action) => {
	let reportIndex = state.reports.findIndex((x) => x.id === action.report.id);
	return updateObject(state, {
		reports: state.reports.map(
			(report, i) =>
				i === reportIndex
					? {
							...action.report,
							isSendingEmail: false,
							didSendEmails: true
						}
					: report
		),
		isLoading: false,
		isLoaded: false,
		errors: null
	});
};
const sendReportFailure = (state, action) => {
	return updateObject(state, {
		isLoading: false,
		isLoaded: false,
		errors: null
	});
};

const startCreateReportPresentation = (state, action) => {
	return updateObject(state, {
		reports: state.reports.map(
			(report, i) =>
				i === action.reportIndex
					? {
							...report,
							creatingReport: true
						}
					: report
		),
		errors: null
	});
};
const createReportPresentationSuccess = (state, action) => {
	return updateObject(state, {
		reports: state.reports.map(
			(report, i) =>
				i === action.reportIndex
					? {
							...action.report,
							creatingReport: false
						}
					: report
		),
		errors: null
	});
};
const createReportPresentationFailure = (state, action) => {
	return updateObject(state, {
		reports: state.reports.map(
			(report, i) =>
				i === action.reportIndex
					? {
							...report,
							creatingReport: false
						}
					: report
		),
		errors: action.errors
	});
};
const startReCreateReportPresentation = (state, action) => {
	return updateObject(state, {
		reports: state.reports.map(
			(report, i) =>
				i === action.reportIndex
					? {
							...report,
							reCreatingReport: true
						}
					: report
		),
		errors: null
	});
};
const reCreateReportPresentationSuccess = (state, action) => {
	return updateObject(state, {
		reports: state.reports.map(
			(report, i) =>
				i === action.reportIndex
					? {
							...action.report,
							reCreatingReport: false
						}
					: report
		),
		errors: null
	});
};
const reCreateReportPresentationFailure = (state, action) => {
	return updateObject(state, {
		reports: state.reports.map(
			(report, i) =>
				i === action.reportIndex
					? {
							...report,
							creatingReport: false
						}
					: report
		),
		errors: action.errors
	});
};

const startBulkReportPresentationTemplateUpdate = (state, action) => {
	return updateObject(state, {
		isBulkUpdatingTemplates: true,
		didBulkUpdateTemplates: false
	});
};
const bulkReportPresentationTemplateUpdateSuccess = (state, action) => {
	return updateObject(state, {
		isBulkUpdatingTemplates: false,
		didBulkUpdateTemplates: true
	});
};
const bulkReportPresentationTemplateUpdateFailure = (state, action) => {
	return updateObject(state, {
		isBulkUpdatingTemplates: false,
		didBulkUpdateTemplates: false
	});
};

const startFetchReportEmailEntries = (state, action) => {
	return updateObject(state, {
		reports: state.reports.map(
			(report, i) =>
				i === action.reportIndex
					? {
							...report,
							isFetchingEmailEntries: true,
							didFetchEmailEntries: false,
							emailEntries: null
						}
					: report
		)
	});
};
const fetchReportEmailEntriesSuccess = (state, action) => {
	return updateObject(state, {
		reports: state.reports.map(
			(report, i) =>
				i === action.reportIndex
					? {
							...report,
							isFetchingEmailEntries: false,
							didFetchEmailEntries: true,
							emailEntries: action.reportEmailEntries
						}
					: report
		)
	});
};
const fetchReportEmailEntriesFailure = (state, action) => {
	return updateObject(state, {
		reports: state.reports.map(
			(report, i) =>
				i === action.reportIndex
					? {
							...report,
							isFetchingEmailEntries: false,
							didFetchEmailEntries: false,
							emailEntries: null,
							emailEntriesErrors: action.errors
						}
					: report
		)
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.ADD_MESSAGE:
			return addMessage(state, action);
		case actionTypes.REMOVE_MESSAGE:
			return removeMessage(state, action);
		case actionTypes.START_FETCH_REPORTS:
			return startFetchReports(state, action);
		case actionTypes.FETCH_REPORTS_SUCCESS:
			return fetchReportsSuccess(state, action);
		case actionTypes.FETCH_REPORTS_FAILURE:
			return fetchReportsFailure(state, action);
		case actionTypes.START_UPDATE_REPORT:
			return startUpdateReport(state, action);
		case actionTypes.UPDATE_REPORT_SUCCESS:
			return updateReportSuccess(state, action);
		case actionTypes.UPDATE_REPORT_FAILURE:
			return updateReportFailure(state, action);
		case actionTypes.START_SEND_REPORT:
			return startSendReport(state, action);
		case actionTypes.SEND_REPORT_SUCCESS:
			return sendReportSuccess(state, action);
		case actionTypes.SEND_REPORT_FAILURE:
			return sendReportFailure(state, action);
		case actionTypes.START_CREATE_REPORT_PRESENTATION:
			return startCreateReportPresentation(state, action);
		case actionTypes.CREATE_REPORT_PRESENTATION_SUCCESS:
			return createReportPresentationSuccess(state, action);
		case actionTypes.CREATE_REPORT_PRESENTATION_FAILURE:
			return createReportPresentationFailure(state, action);
		case actionTypes.START_RE_CREATE_REPORT_PRESENTATION:
			return startReCreateReportPresentation(state, action);
		case actionTypes.RE_CREATE_REPORT_PRESENTATION_SUCCESS:
			return reCreateReportPresentationSuccess(state, action);
		case actionTypes.RE_CREATE_REPORT_PRESENTATION_FAILURE:
			return reCreateReportPresentationFailure(state, action);
		case actionTypes.START_BULK_REPORT_PRESENTATION_TEMPLATE_UPDATE:
			return startBulkReportPresentationTemplateUpdate(state, action);
		case actionTypes.BULK_REPORT_PRESENTATION_TEMPLATE_UPDATE_SUCCESS:
			return bulkReportPresentationTemplateUpdateSuccess(state, action);
		case actionTypes.BULK_REPORT_PRESENTATION_TEMPLATE_UPDATE_FAILURE:
			return bulkReportPresentationTemplateUpdateFailure(state, action);
		case actionTypes.START_FETCH_REPORT_ENTRIES:
			return startFetchReportEmailEntries(state, action);
		case actionTypes.FETCH_REPORT_ENTRIES_SUCCESS:
			return fetchReportEmailEntriesSuccess(state, action);
		case actionTypes.FETCH_REPORT_ENTRIES_FAILURE:
			return fetchReportEmailEntriesFailure(state, action);
		default:
			return state;
	}
};

export default reducer;
