import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/auth";
import { Redirect, Link } from "react-router-dom";
import { Header, Form, Button, Image, Divider, Loader } from "semantic-ui-react";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";
import logo from "../../../assets/images/321-logo-no-tagline-portal.png";
import "./PasswordResetRequest.css";

class PasswordResetRequest extends Component {
  state = {
    email: this.props.match.params.email ? this.props.match.params.email : ""
  };

  componentDidMount() {
    if (!this.props.isAuthenticated && this.props.authRedirectPath !== "/") {
      this.props.onSetAuthRedirectPath();
    }
  }

  onSubmit = async e => {
    e.preventDefault();
    await this.props.resetPasswordRequest(this.state.email);
  };

  render() {
    let authRedirect = null;
    if (this.props.isAuthenticated) {
      authRedirect = <Redirect to={this.props.authRedirectPath} />;
    }
    return (
      <div className="PasswordResetRequest">
        {authRedirect}
        <div className="AuthSidebar">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.321webmarketing.com/"
          >
            <Image centered size="mini" src={logo} alt="321 web marketing" />
          </a>
        </div>
        <div className="PasswordResetRequestForm">
          {this.props.isRequestingPasswordReset && <Loader size="small" active />}
          <Header as="h2">Request Password Reset</Header>
          <Divider />
          {this.props.passwordResetRequested ? (
            <div className="passwordResetRequested">
              <Header as="h4">Password Reset Requested!</Header>
              <p>
                If the provided email matches you will receive a link to reset
                your password
              </p>
            </div>
          ) : (
            <Form onSubmit={this.onSubmit}>
              {this.props.errors && <ErrorHandler errors={this.props.errors} />}
              <Form.Input
                required
                label="Email Address"
                type="email"
                icon="envelope"
                disabled={this.props.isRequestingPasswordReset}
                value={this.state.email}
                iconPosition="left"
                placeholder="Registered Email"
                id="email"
                name="email"
                onChange={e => this.setState({ email: e.target.value })}
              />
              <div className="buttonHolder">
                <Button color="purple" type="submit">
                  Submit
                </Button>
                <Link to="/auth/login/">
                  <Button basic type="button">
                    Nevermind
                  </Button>
                </Link>
              </div>
            </Form>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    errors: state.auth.errors,
    isAuthenticated: state.auth.isAuthenticated,
    authRedirectPath: state.auth.authRedirectPath,
    passwordResetRequested: state.auth.passwordResetRequested,
    passwordResetSuccessful: state.auth.passwordResetSuccessful,
    isRequestingPasswordReset: state.auth.isRequestingPasswordReset,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetPasswordRequest: email => {
      return dispatch(actions.resetPasswordRequest(email));
    },
    onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath("/"))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordResetRequest);
