import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  userFeedback: [],
  isLoading: false,
  isLoaded: false,
  isAdding: false,
  minimized: false,
  modalState: {
    open: false,
    feedbackType: null
  },
  errors: null,
  messages: []
};

const addMessage = (state, action) => {
  return updateObject(state, {
    messages: state.messages.concat(action.message)
  });
};

const removeMessage = (state, action) => {
  let messagesList = state.messages.slice();
  messagesList.splice(0, 1);
  return updateObject(state, { messages: messagesList });
};

const toggleMinize = (state, action) => {
  return updateObject(state, {
    minimized: !state.minimized
  })
}

const startUserFeedbackFetch = (state, action) => {
  return updateObject(state, {
    isLoading: true
  });
};

const fetchUserFeedbackFailure = (state, action) => {
  return updateObject(state, {
    isLoading: false,
    errors: action.errors
  });
};

const setUserFeedback = (state, action) => {
  return updateObject(state, {
    isLoading: false,
    isLoaded: true,
    userFeedback: action.userFeedback
  });
};

const startAddingUserFeedback = (state, action) => {
  return updateObject(state, {
    modalState: {
      open: true,
      feedbackType: action.feedbackType
    }
  });
};

const cancelAddingUserFeedback = (state, action) => {
  return updateObject(state, {
    modalState: { open: false, feedbackType: null }
  });
};

const addingUserFeedback = (state, action) => {
  return updateObject(state, {
    isAdding: true,
    errors: null
  });
};

const addUserFeedbackSuccess = (state, action) => {
  return updateObject(state, {
    userFeedback: state.userFeedback.concat(action.userFeedback),
    isAdding: false,
    errors: null
  });
};

const updateUserFeedbackSuccess = (state, action) => {
  let userFeedbackIndex = state.userFeedback.findIndex(x => x.id === action.id);
  return updateObject(state, {
    userFeedback: state.userFeedback.map((userFeedback, i) =>
      i === userFeedbackIndex ? action.userFeedback : userFeedback
    )
  });
};

const userFeedbackFailure = (state, action) => {
  return updateObject(state, {
    errors: action.errors,
    isAdding: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_MESSAGE:
      return addMessage(state, action);
    case actionTypes.REMOVE_MESSAGE:
      return removeMessage(state, action);
    case actionTypes.TOGGLE_MINIMIZE:
      return toggleMinize(state, action);
    case actionTypes.START_EDITORIAL_REQUIREMENTS_FETCH:
      return startUserFeedbackFetch(state, action);
    case actionTypes.FETCH_EDITORIAL_REQUIREMENTS_FAILURE:
      return fetchUserFeedbackFailure(state, action);
    case actionTypes.SET_EDITORIAL_REQUIREMENTS:
      return setUserFeedback(state, action);
    case actionTypes.ADDING_EDITORIAL_REQUIREMENT:
      return addingUserFeedback(state, action);
    case actionTypes.ADD_EDITORIAL_REQUIREMENT_SUCCESS:
      return addUserFeedbackSuccess(state, action);
    case actionTypes.UPDATE_EDITORIAL_REQUIREMENT_SUCCESS:
      return updateUserFeedbackSuccess(state, action);
    case actionTypes.EDITORIAL_REQUIREMENT_FAILURE:
      return userFeedbackFailure(state, action);
    case actionTypes.START_ADDING_USER_FEEDBACK:
      return startAddingUserFeedback(state, action);
    case actionTypes.CANCEL_ADDING_USER_FEEDBACK:
      return cancelAddingUserFeedback(state, action);
    default:
      return state;
  }
};

export default reducer;
