import * as actionTypes from '../actions/actionTypes';
import axios from '../../axios-portal';

export const addMessage = (message) => {
	return {
		type: actionTypes.ADD_MESSAGE,
		message: message
	};
};

export const removeMessage = (message, timeToWait) => {
	return {
		type: actionTypes.REMOVE_MESSAGE,
		message: message,
		timeToWait: timeToWait
	};
};

export const startFetchReports = () => {
	return {
		type: actionTypes.START_FETCH_REPORTS
	};
};

export const fetchReportsSuccess = (reports) => {
	return {
		type: actionTypes.FETCH_REPORTS_SUCCESS,
		reports: reports
	};
};

export const fetchReportsFailure = (errors) => {
	return {
		type: actionTypes.FETCH_REPORTS_FAILURE,
		errors: errors
	};
};

export const startUpdateReport = (reportIndex) => {
	return {
		type: actionTypes.START_UPDATE_REPORT,
		reportIndex: reportIndex
	};
};

export const updateReportSuccess = (id, report) => {
	return {
		type: actionTypes.UPDATE_REPORT_SUCCESS,
		id: id,
		report: report
	};
};

export const updateReportFailure = (errors) => {
	return {
		type: actionTypes.UPDATE_REPORT_FAILURE,
		errors: errors
	};
};

export const startSendReport = (reportIndex) => {
	return {
		type: actionTypes.START_SEND_REPORT,
		reportIndex: reportIndex
	};
};

export const sendReportSuccess = (report) => {
	return {
		type: actionTypes.SEND_REPORT_SUCCESS,
		report: report
	};
};

export const sendReportFailure = (errors) => {
	return {
		type: actionTypes.SEND_REPORT_FAILURE,
		errors: errors
	};
};

export const startCreateReportPresentation = (reportIndex) => {
	return {
		type: actionTypes.START_CREATE_REPORT_PRESENTATION,
		reportIndex: reportIndex
	};
};

export const createReportPresentationSuccess = (report, reportIndex) => {
	return {
		type: actionTypes.CREATE_REPORT_PRESENTATION_SUCCESS,
		report: report,
		reportIndex: reportIndex
	};
};

export const createReportPresentationFailure = (errors) => {
	return {
		type: actionTypes.CREATE_REPORT_PRESENTATION_FAILURE,
		errors: errors
	};
};

export const startReCreateReportPresentation = (reportIndex) => {
	return {
		type: actionTypes.START_RE_CREATE_REPORT_PRESENTATION,
		reportIndex: reportIndex
	};
};

export const reCreateReportPresentationSuccess = (report, reportIndex) => {
	return {
		type: actionTypes.RE_CREATE_REPORT_PRESENTATION_SUCCESS,
		report: report,
		reportIndex: reportIndex
	};
};

export const reCreateReportPresentationFailure = (errors) => {
	return {
		type: actionTypes.RE_CREATE_REPORT_PRESENTATION_FAILURE,
		errors: errors
	};
};

export const fetchReports = () => {
	return async (dispatch, getState) => {
		let isLoading = await getState().reporting.isLoading;
		dispatch(startFetchReports());
		!isLoading &&
			axios
				.get(`monthly-reports.json`)
				.then((response) => {
					return dispatch(fetchReportsSuccess(response.data));
				})
				.catch((err) => {
					dispatch(fetchReportsFailure(err));
				});
	};
};

export const updateReport = (id, report) => {
	return async (dispatch, getState) => {
		const reportIndex = await getState().reporting.reports.findIndex((r) => r.id === id);
		dispatch(startUpdateReport(reportIndex));
		let message = 'Report Updated';
		axios
			.put(`monthly-reports/${id}/`, report)
			.then((response) => {
				dispatch(addMessage(message));
				setTimeout(() => dispatch(removeMessage(message)), 1500);
				return dispatch(updateReportSuccess(id, response.data));
			})
			.catch((err) => {
				dispatch(updateReportFailure(err));
			});
	};
};

export const sendReport = (id) => {
	return (dispatch, getState) => {
		const reportIndex = getState().reporting.reports.findIndex((r) => r.id === id);
		let message = 'Report Sent!';
		dispatch(startSendReport(reportIndex))
		axios
			.post(`monthly-reports/${id}/send_report/`)
			.then((response) => {
				dispatch(addMessage(message));
				setTimeout(() => dispatch(removeMessage(message)), 1500);
				return dispatch(sendReportSuccess(response.data));
			})
			.catch((err) => {
				dispatch(sendReportFailure(err));
			});
	};
};

export const createReportPresentation = (id, message) => {
	return async (dispatch, getState) => {
		const reportIndex = await getState().reporting.reports.findIndex((r) => r.id === id);
		dispatch(startCreateReportPresentation(reportIndex));
		axios
			.post(`monthly-reports/${id}/create_report_presentation/`)
			.then((response) => {
				if (message) {
					dispatch(addMessage(message));
					setTimeout(() => dispatch(removeMessage(message)), 1500);
				}
				return dispatch(createReportPresentationSuccess(response.data, reportIndex));
			})
			.catch((err) => {
				dispatch(createReportPresentationFailure(err));
			});
	};
};

export const reCreateReportPresentation = (id, message) => {
	return async (dispatch, getState) => {
		const reportIndex = await getState().reporting.reports.findIndex((r) => r.id === id);
		dispatch(startReCreateReportPresentation(reportIndex));
		axios
			.post(`monthly-reports/${id}/recreate_report_presentation/`)
			.then((response) => {
				if (message) {
					dispatch(addMessage(message));
					setTimeout(() => dispatch(removeMessage(message)), 1500);
				}
				return dispatch(reCreateReportPresentationSuccess(response.data, reportIndex));
			})
			.catch((err) => {
				dispatch(reCreateReportPresentationFailure(err));
			});
	};
};

export const startCreateReportPresentationTemplateFromMaster = (orgIndex) => {
	return {
		type: actionTypes.START_CREATE_REPORT_PRESENTATION_FROM_MASTER,
		orgIndex: orgIndex
	};
};

export const createReportPresentationTemplateFromMasterSuccess = (orgIndex, orgId, presentationTemplate) => {
	return {
		type: actionTypes.CREATE_REPORT_PRESENTATION_FROM_MASTER_SUCCESS,
		orgIndex: orgIndex,
		orgId: orgId,
		presentationTemplate: presentationTemplate
	};
};

export const createReportPresentationTemplateFromMasterFailure = (orgIndex, errors) => {
	return {
		type: actionTypes.CREATE_REPORT_PRESENTATION_FROM_MASTER_FAILURE,
		orgIndex: orgIndex,
		errors: errors
	};
};

export const createReportPresentationTemplateFromMaster = (orgId) => {
	return async (dispatch, getState) => {
		const organizations = await getState().organizations.organizations;
		const orgIndex = await organizations.findIndex((org) => org.id === orgId);
		dispatch(startCreateReportPresentationTemplateFromMaster(orgIndex));
		orgIndex &&
			axios
				.post(`organizations/${orgId}/create_report_template_from_master/`)
				.then((response) => {
					return dispatch(createReportPresentationTemplateFromMasterSuccess(orgIndex, orgId, response.data));
				})
				.catch((err) => {
					dispatch(createReportPresentationTemplateFromMasterFailure(orgIndex, err));
				});
	};
};

export const startBulkReportPresentationTemplateUpdate = (organizationIndexes) => {
	return {
		type: actionTypes.START_BULK_REPORT_PRESENTATION_TEMPLATE_UPDATE,
		organizationIndexes: organizationIndexes,
	}
}

export const bulkReportPresentationTemplateUpdateSuccess = (updatedOrganizations) => {
	return {
		type: actionTypes.BULK_REPORT_PRESENTATION_TEMPLATE_UPDATE_SUCCESS,
		updatedOrganizations: updatedOrganizations,
	}
}

export const bulkReportPresentationTemplateUpdateFailure = (organizationIndexes, errors) => {
	return {
		type: actionTypes.BULK_REPORT_PRESENTATION_TEMPLATE_UPDATE_FAILURE,
		organizationIndexes: organizationIndexes,
		errors: errors,
	}
}

export const bulkReportPresentationTemplateUpdate = (orgIds, message) => {
	return async (dispatch, getState) => {
		const organizations = await getState().organizations.organizations;
		const organizationIndexes = await organizations.map(org => organizations.findIndex((o) => o.id === org.id));

		dispatch(startBulkReportPresentationTemplateUpdate(organizationIndexes));
		let body = JSON.stringify({
			organizations: orgIds
		});
		organizationIndexes &&
			axios
				.post(`recreate-report-templates-from-master/`, body)
				.then((response) => {
					if (message) {
						dispatch(addMessage(message));
						setTimeout(() => dispatch(removeMessage(message)), 1500);
					}
					return dispatch(bulkReportPresentationTemplateUpdateSuccess(response.data));
				})
				.catch((err) => {
					dispatch(bulkReportPresentationTemplateUpdateFailure(organizationIndexes, err));
				});
	};
};


export const startCreateMonthlyReports = (monthIndex) => {
	return {
		type: actionTypes.START_CREATE_MONTHLY_REPORTS,
		monthIndex: monthIndex
	};
};

export const createMonthlyReportsSuccess = (monthIndex) => {
	return {
		type: actionTypes.CREATE_MONTHLY_REPORTS_SUCCESS,
		monthIndex: monthIndex
	};
};

export const createMonthlyReportsFailure = (monthIndex, errors) => {
	return {
		type: actionTypes.CREATE_MONTHLY_REPORTS_FAILURE,
		monthIndex: monthIndex,
		errors: errors
	};
};

export const createMonthlyReports = (monthId) => {
	return async (dispatch, getState) => {
		const planningMonths = await getState().contentGeneration.planningMonths;
		const monthIndex = await planningMonths.findIndex((m) => m.id === monthId);

		dispatch(startCreateMonthlyReports(monthIndex));
		let body = JSON.stringify({
			planning_month: monthId
		});
		axios
			.post(`create-monthly-reports/`, body)
			.then((response) => {
				if (response.response) {
					dispatch(addMessage(response.response));
					setTimeout(() => dispatch(removeMessage(response.response)), 1500);
				}
				return dispatch(createMonthlyReportsSuccess(monthIndex));
			})
			.catch((err) => {
				dispatch(createMonthlyReportsFailure(monthIndex, err));
			});
	};
};

export const startValidateMonthlyReportData = (monthIndex) => {
	return {
		type: actionTypes.START_VALIDATE_MONTHLY_REPORT_DATA,
		monthIndex: monthIndex
	};
};

export const validateMonthlyReportDataSuccess = (monthIndex) => {
	return {
		type: actionTypes.VALIDATE_MONTHLY_REPORT_DATA_SUCCESS,
		monthIndex: monthIndex
	};
};

export const validateMonthlyReportDataFailure = (monthIndex, errors) => {
	return {
		type: actionTypes.VALIDATE_MONTHLY_REPORT_DATA_FAILURE,
		monthIndex: monthIndex,
		errors: errors
	};
};

export const validateMonthlyReportData = (monthId, debugMode) => {
	return async (dispatch, getState) => {
		const planningMonths = await getState().contentGeneration.planningMonths;
		const monthIndex = await planningMonths.findIndex((m) => m.id === monthId);
		dispatch(startValidateMonthlyReportData(monthIndex));
		let body = JSON.stringify({
			planning_month: monthId
		});
		axios
			.post(`validate-monthly-report-data${debugMode ? '/?debug_mode=True' : '/'}`, body)
			.then((response) => {
				if (response.response) {
					dispatch(addMessage(response.response));
					setTimeout(() => dispatch(removeMessage(response.response)), 1500);
				}
				return dispatch(validateMonthlyReportDataSuccess(monthIndex));
			})
			.catch((err) => {
				dispatch(validateMonthlyReportDataFailure(monthIndex, err));
			});
	};
};

export const startCreateMonthlyReportPresentations = (monthIndex) => {
	return {
		type: actionTypes.START_CREATE_MONTHLY_REPORT_PRESENTATIONS,
		monthIndex: monthIndex
	};
};

export const createMonthlyReportPresentationsSuccess = (monthIndex) => {
	return {
		type: actionTypes.CREATE_MONTHLY_REPORT_PRESENTATIONS_SUCCESS,
		monthIndex: monthIndex
	};
};

export const createMonthlyReportPresentationsFailure = (monthIndex, errors) => {
	return {
		type: actionTypes.CREATE_MONTHLY_REPORT_PRESENTATIONS_FAILURE,
		monthIndex: monthIndex,
		errors: errors
	};
};

export const createMonthlyReportPresentations = (monthId) => {
	return async (dispatch, getState) => {
		const planningMonths = await getState().contentGeneration.planningMonths;
		const monthIndex = await planningMonths.findIndex((m) => m.id === monthId);
		dispatch(startCreateMonthlyReportPresentations(monthIndex));
		let body = JSON.stringify({
			planning_month: monthId
		});
		axios
			.post(`create-monthly-report-presentations/`, body)
			.then((response) => {
				if (response.response) {
					dispatch(addMessage(response.response));
					setTimeout(() => dispatch(removeMessage(response.response)), 1500);
				}
				return dispatch(createMonthlyReportPresentationsSuccess(monthIndex));
			})
			.catch((err) => {
				dispatch(createMonthlyReportPresentationsFailure(monthIndex, err));
			});
	};
};

export const startQueryUnsentReports = (monthIndex) => {
	return {
		type: actionTypes.START_QUERY_UNSENT_REPORTS,
		monthIndex: monthIndex
	};
};

export const queryUnsentReportsSuccess = (monthIndex) => {
	return {
		type: actionTypes.QUERY_UNSENT_REPORTS_SUCCESS,
		monthIndex: monthIndex
	};
};

export const queryUnsentReportsFailure = (monthIndex, errors) => {
	return {
		type: actionTypes.QUERY_UNSENT_REPORTS_FAILURE,
		monthIndex: monthIndex,
		errors: errors
	};
};

export const queryUnsentReports = (monthId, debugMode) => {
	return async (dispatch, getState) => {
		const planningMonths = await getState().contentGeneration.planningMonths;
		const monthIndex = await planningMonths.findIndex((m) => m.id === monthId);
		dispatch(startQueryUnsentReports(monthIndex));
		let body = JSON.stringify({
			planning_month: monthId
		});
		axios
			.post(`query-unsent-reports${debugMode ? '/?debug_mode=True' : '/'}`, body)
			.then((response) => {
				if (response.response) {
					dispatch(addMessage(response.response));
					setTimeout(() => dispatch(removeMessage(response.response)), 1500);
				}
				return dispatch(queryUnsentReportsSuccess(monthIndex));
			})
			.catch((err) => {
				dispatch(queryUnsentReportsFailure(monthIndex, err));
			});
	};
};

export const startFetchReportEmailEntries = (reportIndex) => {
	return {
		type: actionTypes.START_FETCH_REPORT_ENTRIES,
		reportIndex: reportIndex
	}
}

export const fetchReportEmailEntriesSuccess = (reportIndex, reportEmailEntries) => {
	return {
		type: actionTypes.FETCH_REPORT_ENTRIES_SUCCESS,
		reportIndex: reportIndex,
		reportEmailEntries: reportEmailEntries
	}
}

export const fetchReportEmailEntriesFailure = (reportIndex, errors) => {
	return {
		type: actionTypes.FETCH_REPORT_ENTRIES_FAILURE,
		reportIndex: reportIndex,
		errors: errors
	}
}

export const fetchReportEmailEntries = (reportID) => {
	return async (dispatch, getState) => {
		const reports = await getState().reporting.reports;
		const reportIndex = await reports.findIndex((r) => r.id === reportID);
		dispatch(startFetchReportEmailEntries(reportIndex));
		axios
			.get(`report-email-entries${reportID ? `/?report=${reportID}` : '.json'}`)
			.then((response) => {
				return dispatch(fetchReportEmailEntriesSuccess(reportIndex, response.data));
			})
			.catch((err) => {
				dispatch(fetchReportEmailEntriesFailure(reportIndex, err));
			});
	};
};

export const confirmViewedReport = (reportID) => {
	return (dispatch, getState) => {
		axios
			.post(`report-email-entries/${reportID}/confirm_viewed/`)
			.then((response) => {
				return response.data
			})
			.catch((err) => {
				return err
			});
	};
};