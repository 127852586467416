import React from "react";
import "./OrganizationDetail.css";
import { Header, Grid } from "semantic-ui-react";

const OrganizationDetail = props => {
  const { organization } = props;
  return (
    <div className="OrganizationDetail">
      <Header as="h3">{organization.dba_name}</Header>
      <div className="OrganizationDetailContent">
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <Header as="h4">
                Domain Name
                <Header.Subheader>{organization.domain}</Header.Subheader>
              </Header>
            </Grid.Column>
            <Grid.Column width={8}>
              <Header as="h4">
                Phone
                <Header.Subheader>{organization.phone_number}</Header.Subheader>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <Header as="h4">
                Primary Address
                <Header.Subheader>
                  <div className="OrganizationDetail--Subheader">
                    <span className="OrganizationDetail--Subheader__item">
                      {organization.street_address_1}
                    </span>
                    {organization.street_address_2 && (
                      <span className="OrganizationDetail--Subheader__item">
                        {organization.street_address_2}
                      </span>
                    )}
                    <span className="OrganizationDetail--Subheader__item">
                      {organization.city}, {organization.state}{" "}
                      {organization.zipcode}
                    </span>
                  </div>
                </Header.Subheader>
              </Header>
            </Grid.Column>
            <Grid.Column width={8}>
              <Header as="h4">
                Business Email
                <Header.Subheader>
                  {organization.business_email}
                </Header.Subheader>
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
};

export default OrganizationDetail;
