import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Header, Image, Divider, Button } from "semantic-ui-react";
import { Link, Redirect } from "react-router-dom";
import "./Register.css";
import logo from "../../../assets/images/321-logo-no-tagline-portal.png";
import { auth } from "../../../store/actions";
import ErrorHandler from "../../../components/ErrorHandler/ErrorHandler";
import DimmedLoader from "../../../components/DimmedLoader/DimmedLoader";

class Register extends Component {
  state = {
    username: "",
    first_name: "",
    last_name: "",
    title: "",
    phone_number: "",
    password1: "",
    password2: "",
    email: this.props.match.params.email,
  };

  componentDidMount() {
    if (!this.props.isAuthenticated && this.props.authRedirectPath !== "/") {
      this.props.onSetAuthRedirectPath();
    }
  }

  onSubmit = e => {
    e.preventDefault();
    let key = this.props.match.params.key;
    this.props.register(
      this.state.username,
      this.state.first_name,
      this.state.last_name,
      this.state.title,
      this.state.phone_number,
      this.state.password1,
      this.state.password2,
      this.state.email,
      key
    );
  };

  render() {
    let authRedirect = null;
    if (this.props.isAuthenticated) {
      authRedirect = <Redirect to={this.props.authRedirectPath} />;
    }

    return (
      <div className="Register">
        {this.props.isRegistering && <DimmedLoader size="big" text="Setting Up Your Account" />}
        {authRedirect}
        <div className="AuthSidebar">
          <a target="_blank" rel="noopener noreferrer" href="https://www.321webmarketing.com/">
            <Image centered size="mini" src={logo} alt="321 web marketing" />
          </a>
        </div>
        <div className="RegisterForm">
          <Header as="h2">Register</Header>
          <Divider />
          <Form onSubmit={this.onSubmit}>
            <ErrorHandler errors={this.props.errors} />
            <Form.Group widths="equal">
              <Form.Field>
                <label htmlFor="username">Username</label>
                <input
                  type="text"
                  id="username"
                  onChange={e =>
                    this.setState({
                      username: e.target.value
                    })
                  }
                />
              </Form.Field>
              <Form.Field>
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  disabled
                  id="email"
                  value={this.state.email}
                  onChange={e =>
                    this.setState({
                      email: e.target.value
                    })
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label htmlFor="first_name">First Name</label>
                <input
                  type="text"
                  id="first_name"
                  onChange={e =>
                    this.setState({
                      first_name: e.target.value
                    })
                  }
                />
              </Form.Field>
              <Form.Field>
                <label htmlFor="last_name">Last Name</label>
                <input
                  type="text"
                  id="last_name"
                  onChange={e =>
                    this.setState({
                      last_name: e.target.value
                    })
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label htmlFor="title">Title</label>
                <input
                  type="text"
                  id="title"
                  onChange={e =>
                    this.setState({
                      title: e.target.value
                    })
                  }
                />
              </Form.Field>
              <Form.Field>
                <label htmlFor="phone_number">Phone Number</label>
                <input
                  type="text"
                  id="phone_number"
                  onChange={e =>
                    this.setState({
                      phone_number: e.target.value
                    })
                  }
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label htmlFor="password1">Password</label>
                <input
                  type="password"
                  id="password1"
                  onChange={e => this.setState({ password1: e.target.value })}
                />
              </Form.Field>
              <Form.Field>
                <label htmlFor="password2">Confirm Password</label>
                <input
                  type="password"
                  id="password2"
                  onChange={e => this.setState({ password2: e.target.value })}
                />
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <Button color="purple" type="submit">
                Register
              </Button>
            </Form.Field>
            <Form.Field>
              Already have an account? <Link to="/auth/login">Login</Link>
            </Form.Field>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    errors: state.auth.errors,
    isAuthenticated: state.auth.isAuthenticated,
    authRedirectPath: state.auth.authRedirectPath,
    isRegistering: state.auth.isRegistering
  };
};

const mapDispatchToProps = dispatch => {
  return {
    register: (
      username,
      first_name,
      last_name,
      title,
      phone_number,
      password1,
      password2,
      email,
      key
    ) =>
      dispatch(
        auth.register(
          username,
          first_name,
          last_name,
          title,
          phone_number,
          password1,
          password2,
          email,
          key
        )
      ),
    onSetAuthRedirectPath: () => dispatch(auth.setAuthRedirectPath("/"))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);
