import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import './Dashboard.css';
import { hasPermissions } from '../../utility';
import { Redirect } from 'react-router-dom';

class DashBoard extends Component {
	state = {
		keystroke: ''
	};

	audioRef = React.createRef();

	_handleKeyDown = (event) => {
		if (event.keyCode !== 27) {
			this.setState({ keystroke: this.state.keystroke.concat(event.key) });
		} else {
			this.setState({ keystroke: '' });
		}
	};

	componentDidMount() {
		document.addEventListener('keydown', this._handleKeyDown);
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this._handleKeyDown);
	}

	render() {
		let tbRef = this.audioRef.current;
		if (this.state.keystroke === 'balls') {
			tbRef.play();
		} else {
			tbRef && tbRef.pause();
		}

		return hasPermissions(this.props.user, 'view_contentarticle') &&
		(this.props.user.default_role &&
			(this.props.user.default_role.name === 'Customer' ||
				this.props.user.default_role.name === 'Contractor' ||
				this.props.user.default_role.name === 'Editor' ||
				this.props.user.default_role.name === 'Writer')) ? (
			<Redirect to="/content-generation/assignments" />
		) : (
			<Redirect to="/content-generation/" />
			// <div className="DashBoard">
			// 	<audio loop ref={this.audioRef} src={tsandbs} />
			// 	<div className="stripes">
			// 		<span />
			// 		<span />
			// 		<span />
			// 		<span />
			// 		<span />
			// 	</div>
			// 	<div className="DashBoard--content">
			// 		<div className="DashBoard--content__intro">
			// 			<Header className="DashBoard--header" inverted as="h1">
			// 				Welcome to the 321 Portal
			// 			</Header>
			// 		</div>
			// 		<div className="DashBoard--content__grid">
			// 			<Grid>
			// 				<Grid.Column width={8}>
			// 					<Link to="/content-generation">
			// 						<div className="DashBoard--content__grid--item">
			// 							<Header>Content Generation</Header>
			// 							<img src={typewriter} alt="Add user illustation" />
			// 						</div>
			// 					</Link>
			// 				</Grid.Column>
			// 				<Grid.Column width={8}>
			// 					<Link to="/users/directory">
			// 						<div className="DashBoard--content__grid--item">
			// 							<Header>User Management</Header>
			// 							<img src={addUser} alt="Add user illustation" />
			// 						</div>
			// 					</Link>
			// 				</Grid.Column>
			// 				<Grid.Column width={8}>
			// 					<Link to="/organizations/directory">
			// 						<div className="DashBoard--content__grid--item">
			// 							<Header>Organization Directory</Header>
			// 							<img src={building} alt="Add user illustation" />
			// 						</div>
			// 					</Link>
			// 				</Grid.Column>
			// 				<Grid.Column width={8}>
			// 					<Link to="/client-onboarding/website-builds">
			// 						<div className="DashBoard--content__grid--item">
			// 							<Header>Client Onboarding</Header>
			// 							<img src={interview} alt="Add user illustation" />
			// 						</div>
			// 					</Link>
			// 				</Grid.Column>
			// 			</Grid>
			// 		</div>
			// 	</div>

			// 	{this.state.keystroke === 'balls' && (
			// 		<div className="WashingtonRedskins">
			// 			<img
			// 				src="https://raw.githubusercontent.com/amandreatos/321_fantasy_writeups/master/static/img/washington-redskins.png"
			// 				alt="washington redskins"
			// 			/>
			// 		</div>
			// 	)}
			// 	{this.state.keystroke === 'butthole' && (
			// 		<div className="Ron">
			// 			<Header>Butthole!</Header>
			// 			<img
			// 				src="https://raw.githubusercontent.com/amandreatos/321_fantasy_writeups/master/static/img/swanson.png"
			// 				alt="Ron Swanson"
			// 			/>
			// 		</div>
			// 	)}
			// </div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		logout: () => {
			dispatch(actions.auth.logout());
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DashBoard);
