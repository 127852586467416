import React, { Component } from 'react';
import './PostingOverviewLoader.css';
import { Message, Icon } from 'semantic-ui-react';

export default class PostingOverviewLoader extends Component {
	renderLoadingMessage = (totalCount, currentCount) => {
		const completion = Number(currentCount) / Number(totalCount) * 100;
		const percentComplete = completion.toFixed(2);
		if (percentComplete >= 75) {
			return 'Almost there!';
		} else if (percentComplete >= 50) {
			return 'Wow we have a lot of accounts!';
		} else {
			return "This shouldn't take too long.";
		}
	};

	render() {
		const { totalCount, currentCount, showSuccessMessage } = this.props;
		return (
			<div className="PostingOverviewLoader">
                {showSuccessMessage ? (
                    <Message icon positive size="mini">
					<Icon size="tiny" name="check circle outline" />
					<Message.Content>
						<Message.Header>
							All Wordpress Posts Loaded
						</Message.Header>
						<div className="PostingOverviewLoader--message">
							<span>There were 0 errors while pulling data.</span>
						</div>
					</Message.Content>
				</Message>
                ) : (
                    <Message icon info size="mini">
					<Icon size="tiny" name="circle notched" loading />
					<Message.Content>
						<Message.Header>
							Loading{' '}
							<span className="PostingOverviewLoader--count PostingOverviewLoader--currentCount">
								{currentCount}
							</span>{' '}
							of{' '}
							<span className="PostingOverviewLoader--count PostingOverviewLoader--currentCount">
								{totalCount}
							</span>{' '}
							Account Posts
						</Message.Header>
						<div className="PostingOverviewLoader--message">
							<span>{this.renderLoadingMessage(totalCount, currentCount)}</span>
						</div>
					</Message.Content>
				</Message>
                )}
			</div>
		);
	}
}
