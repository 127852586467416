import React from 'react'
import "./TextBlock.css";

 const TextBlock = (props) => {
    return (
        <div style={{ textAlign: props.textAlign}} className="TextBlock">
            <p className="TextBlock--content">{props.content}</p>
        </div>
    )
}

export default TextBlock;
