import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userFeedback } from '../../store/actions';
import './UserFeedback.css';
import { Icon, Popup, Grid, Header } from 'semantic-ui-react';
import UserFeedbackModal from './UserFeedbackModal/UserFeedbackModal';
import UserFeedbackForm from './UserFeedbackForm/UserFeedbackForm';

class UserFeedback extends Component {
	constructor(props) {
		super(props);
		this.state = {
			popupOpen: false,
			modalHeaderText: null,
			modalHeaderIcon: null,
			modalBody: null,
			distanceToButton: null
		};
		this.userFeedbackButton = React.createRef();
	}

	componentDidMount() {
		let userFeedbackButton = this.userFeedbackButton.current;
		document.addEventListener('mousemove', (e) => this.handleMouseMove(e, userFeedbackButton));
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isLoggingOut !== this.props.isLoggingOut && this.props.isLoggingOut) {
			document.removeEventListener('mousemove', this.handleMouseMove);
		}
	}

	componentWillUnmount() {
		document.removeEventListener('mousemove', this.handleMouseMove);
	}

	handleMouseMove = (e, element) => {
		let mX = e.pageX;
		let mY = e.pageY;
		let distance = this.calculateMouseDistance(element, mX, mY);
		this.setState({ distanceToButton: distance });
	};

	calculateMouseDistance = (elem, mouseX, mouseY) => {
		return Math.floor(
			Math.sqrt(
				Math.pow(mouseX - (elem.getBoundingClientRect().left + elem.offsetWidth / 2), 2) +
					Math.pow(mouseY - (elem.getBoundingClientRect().top + elem.offsetHeight / 2), 2)
			)
		);
	};

	handlePopupOpen = () => {
		this.setState({ popupOpen: true });
	};

	handlePopupClose = () => {
		this.setState({ popupOpen: false });
	};

	handleModalOpen = (feedbackType, headerText, headerIcon, body) => {
		this.props.startAddingUserFeedback(feedbackType);
		this.setState({
			modalHeaderText: headerText,
			modalHeaderIcon: headerIcon,
			modalBody: body
		});
		this.handlePopupClose();
	};

	handleModalClose = (success) => {
		if (success) {
			setTimeout(() => {
				this.props.cancelAddingUserFeedback();
				this.setState({
					modalHeaderText: null,
					modalHeaderIcon: null,
					modalBody: null
				});
			}, 2000);
		} else {
			this.props.cancelAddingUserFeedback();
			this.setState({
				modalHeaderText: null,
				modalHeaderIcon: null,
				modalBody: null
			});
		}
		this.handlePopupClose();
	};

	handleMinimize = () => {
		if (this.props.minimized) {
			let userFeedbackButton = this.userFeedbackButton.current;
			document.addEventListener('mousemove', (e) => {
				let mX = e.pageX;
				let mY = e.pageY;
				let distance = this.calculateMouseDistance(userFeedbackButton, mX, mY);
				this.setState({ distanceToButton: distance });
			});
		} else {
			let userFeedbackButton = this.userFeedbackButton.current;
			document.removeEventListener('mousemove', (e) => {
				let mX = e.pageX;
				let mY = e.pageY;
				let distance = this.calculateMouseDistance(userFeedbackButton, mX, mY);
				this.setState({ distanceToButton: distance });
			});
		}
		this.props.toggleMinimize();
	};

	render() {
		return (
			<div className="UserFeedback">
				{!this.props.minimized &&
				this.state.distanceToButton <= 75 && (
					<div
						onClick={() => this.handleMinimize()}
						className={`UserFeedback--minimizeButton ${this.props.minimized ? 'minimized' : ''}`}
					>
						-
					</div>
				)}
				{this.props.minimized ? (
					<div
						onClick={() => this.props.toggleMinimize()}
						className={`UserFeedback--button ${this.props.minimized ? 'minimized' : ''}`}
					>
						<span className="UserFeedback--minimizedText">Feedback</span>{' '}
						<span className="UserFeedback--minimizedPlus">+</span>
					</div>
				) : (
					<Popup
						hoverable
						flowing
						open={this.state.popupOpen}
						onOpen={this.handlePopupOpen}
						onClose={this.handlePopupClose}
						on="click"
						trigger={
							<div
								ref={this.userFeedbackButton}
								className={`UserFeedback--button ${this.props.minimized ? 'minimized' : ''}`}
							>
								<Icon inverted circular size="big" color="pink" name="question" />
							</div>
						}
					>
						<Grid centered divided columns={3}>
							<Grid.Column
								className="UserFeedback--column"
								onClick={() =>
									this.handleModalOpen(
										'question',
										'Ask us a question',
										'question',
										'Have a question or are confused about something? Ask away!'
									)}
							>
								<div className="UserFeedback--choice">
									<Header icon as="h5">
										<Icon color="teal" name="question" />
										Question
									</Header>
								</div>
							</Grid.Column>
							<Grid.Column
								className="UserFeedback--column"
								onClick={() =>
									this.handleModalOpen(
										'suggestion',
										'Leave us a suggestion',
										'comment alternate',
										'We appreciate any and all suggestions, feel free to let us know your thoughts or ask for a new feature'
									)}
							>
								<div className="UserFeedback--choice">
									<Header icon as="h5">
										<Icon color="blue" name="comment alternate" />
										Suggestion
									</Header>
								</div>
							</Grid.Column>
							<Grid.Column
								className="UserFeedback--column"
								onClick={() =>
									this.handleModalOpen(
										'bug',
										'Report Bug',
										'bug',
										'If you have experiened a bug or something unusual please give us a detailed response'
									)}
							>
								<div className="UserFeedback--choice">
									<Header icon as="h5">
										<Icon color="red" name="bug" />
										Report Bug
									</Header>
								</div>
							</Grid.Column>
						</Grid>
					</Popup>
				)}
				<UserFeedbackModal
					headerText={this.state.modalHeaderText}
					headerIcon={this.state.modalHeaderIcon}
					body={this.state.modalBody}
					modalOpen={this.props.modalState.open}
					handleClose={this.props.cancelAddingUserFeedback}
					messages={this.props.messages}
				>
					<UserFeedbackForm
						errors={this.props.errors}
						isAdding={this.props.isAdding}
						feedbackType={this.props.modalState.feedbackType}
						addUserFeedback={this.props.addUserFeedback}
						handleClose={this.handleModalClose}
					/>
				</UserFeedbackModal>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		modalState: state.userFeedback.modalState,
		isAdding: state.userFeedback.isAdding,
		errors: state.userFeedback.errors,
		messages: state.userFeedback.messages,
		minimized: state.userFeedback.minimized,
		isLoggingOut: state.auth.isLoggingOut
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		startAddingUserFeedback: (feedbackType) => {
			return dispatch(userFeedback.startAddingUserFeedback(feedbackType));
		},
		cancelAddingUserFeedback: () => {
			return dispatch(userFeedback.cancelAddingUserFeedback());
		},
		addUserFeedback: (feedbackType, feedback) => {
			return dispatch(userFeedback.addUserFeedback(feedbackType, feedback));
		},
		toggleMinimize: () => {
			return dispatch(userFeedback.toggleMinimize());
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserFeedback);
