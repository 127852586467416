import _ from "lodash";
import React, { Component } from "react";
import { Table, Icon, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import ActionHeader from "../ActionHeader/ActionHeader";
import "./InvitationsTable.css";
import FixedLoader from "../FixedLoader/FixedLoader";
import moment from "moment";

export default class InvitationsTable extends Component {
  state = {
    column: null,
    data: this.props.tableData,
    direction: null
  };

  componentDidUpdate(prevProps) {
    if (prevProps.tableData !== this.props.tableData) {
      this.setState({ data: this.props.tableData });
    }
  }

  handleSort = clickedColumn => () => {
    const { column, data, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: _.sortBy(data, [clickedColumn]),
        direction: "ascending"
      });

      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending"
    });
  };

  ifData = data => {
    if (this.props.tableData[0]) {
      if (data in this.props.tableData[0]) {
        return true;
      } else {
        return false;
      }
    }
  };

  render() {
    const { column, data, direction } = this.state;

    return (
      <div className="InvitationsTable">
        <ActionHeader headerText="Invitations">
          <Link to="/users/directory">
            <Button size="small" primary>
              User Directory
            </Button>
          </Link>
        </ActionHeader>
        {!this.props.isLoading && this.props.isLoaded ? (
          <Table size="small" className="InvitationsTable--table" compact sortable celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={column === "email" ? direction : null}
                  onClick={this.handleSort("email")}
                >
                  Email
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "sent" ? direction : null}
                  onClick={this.handleSort("sent")}
                >
                  Sent
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "inviter.name" ? direction : null}
                  onClick={this.handleSort("inviter.name")}
                >
                  Inviter
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "organization.dba_name" ? direction : null}
                  onClick={this.handleSort("organization.dba_name")}
                >
                  Organization
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "role.name" ? direction : null}
                  onClick={this.handleSort("role.name")}
                >
                  Role
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "accepted" ? direction : null}
                  onClick={this.handleSort("accepted")}
                >
                  Accepted
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.data &&
                data.map(data => (
                  <Table.Row key={data.id}>
                    <Table.Cell>{data.email}</Table.Cell>
                    <Table.Cell>{moment(data.sent).format("MMM Do, YYYY")}</Table.Cell>
                    <Table.Cell>
                      {data.inviter && data.inviter.email}
                    </Table.Cell>
                    <Table.Cell>{data.organization.dba_name}</Table.Cell>
                    <Table.Cell>{data.role.name}</Table.Cell>
                    <Table.Cell>
                      {data.accepted ? (
                        <Icon name="check" color="green" />
                      ) : (
                        <div className="reinviteAction">
                          <Icon name="times" color="red" />
                          <Button
                            onClick={() =>
                              this.props.handleReinvite(
                                data.email,
                                data.organization,
                                data.role,
                                data.key
                              )
                            }
                            compact
                            className="centerButton"
                            size="mini"
                          >
                            Re-Invite
                          </Button>{" "}
                        </div>
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        ) : (
          <FixedLoader />
        )}
      </div>
    );
  }
}
