import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
	organizations: [],
	editorialRequirements: [],
	isLoadingEditorialRequirements: false,
	editorialRequirementsLoaded: false,
	isLoading: false,
	isLoaded: false,
	isAddingOrganization: false,
	errors: null,
	messages: []
};

const addMessage = (state, action) => {
	return updateObject(state, {
		messages: state.messages.concat(action.message)
	});
};

const removeMessage = (state, action) => {
	let messagesList = state.messages.slice();
	messagesList.splice(0, 1);
	return updateObject(state, { messages: messagesList });
};

const startOrganizationsFetch = (state, action) => {
	return updateObject(state, {
		isLoading: true
	});
};

const fetchOrganizationsFailure = (state, action) => {
	return updateObject(state, {
		isLoading: false,
		errors: action.errors
	});
};

const setOrganizations = (state, action) => {
	return updateObject(state, {
		isLoading: false,
		isLoaded: true,
		organizations: action.organizations
	});
};

const addingOrganization = (state, action) => {
	return updateObject(state, {
		isAddingOrganization: true,
		errors: null
	});
};

const updatingOrganization = (state, action) => {
	return updateObject(state, {
		organizations: state.organizations.map(
			(organization, i) =>
				i === action.organizationIndex
					? {
							...organization,
							isUpdating: true
						}
					: organization
		)
	});
};

const addOrganizationSuccess = (state, action) => {
	return updateObject(state, {
		organizations: state.organizations.concat(action.organization),
		isAddingOrganization: false,
		errors: null
	});
};

const updateOrganizationSuccess = (state, action) => {
	let organizationIndex = state.organizations.findIndex((x) => x.id === action.id);
	return updateObject(state, {
		organizations: state.organizations.map(
			(organization, i) =>
				i === organizationIndex
					? {
							...action.organization,
							isUpdating: false
						}
					: organization
		)
	});
};

const archiveOrganizationSuccess = (state, action) => {
	let organizationIndex = state.organizations.findIndex((x) => x.id === action.id);
	const organizations = state.organizations.slice();
	organizations.splice(organizationIndex, 1);
	return updateObject(state, {
		organizations: organizations
	});
}

const updateOrganizationFailure = (state, action) => {
	return updateObject(state, {
		organizations: state.organizations.map(
			(organization, i) =>
				i === action.organizationIndex
					? {
							...organization,
							isUpdating: false
						}
					: organization
		),
		errors: action.errors
	});
};

const updateOrganizationSlideDeckTemplate = (state, action) => {
	return updateObject(state, {
		organizations: state.organizations.map(
			(organization, i) =>
				i === action.orgIndex
					? {
							...organization,
							monthly_report_template: action.slideDeckId
						}
					: organization
		)
	});
};

const organizationFailure = (state, action) => {
	return updateObject(state, {
		errors: action.errors,
		isAddingOrganization: false
	});
};

const startGetOverdueContentApprovals = (state, action) => {
	return updateObject(state, {
		organizations: state.organizations.map((org) => org.id === action.orgId ? {
			...org,
			isFetchingOverdueContentApprovals: true,
			didFetchOverdueContentApprovals: false,
			overdueContentApprovals: null
		} : org)
	})
}
const getOverdueContentApprovalsSuccess = (state, action) => {
	return updateObject(state, {
		organizations: state.organizations.map((org) => org.id === action.orgId ? {
			...org,
			isFetchingOverdueContentApprovals: false,
			didFetchOverdueContentApprovals: true,
			overdueContentApprovals: action.pendingApprovals
		} : org)
	})
}
const getOverdueContentApprovalsFailure = (state, action) => {
	return updateObject(state, {
		organizations: state.organizations.map((org) => org.id === action.orgId ? {
			...org,
			isFetchingOverdueContentApprovals: false,
			didFetchOverdueContentApprovals: false,
			overdueContentApprovals: null
		} : org)
	})
}

const startCreateWhatConvertsAccount = (state, action) => {
	return updateObject(state, {
		organizations: state.organizations.map(
			(organization, i) =>
				i === action.orgIndex
					? {
							...organization,
							creatingWhatConvertsAccount: true,
							whatConvertsAccountErrors: null
						}
					: organization
		)
	});
};
const createWhatConvertsAccountSuccess = (state, action) => {
	return updateObject(state, {
		organizations: state.organizations.map(
			(organization, i) =>
				i === action.orgIndex
					? {
							...action.organization,
							creatingWhatConvertsAccount: false,
							whatConvertsAccountErrors: null
						}
					: organization
		)
	});
};
const createWhatConvertsAccountFailure = (state, action) => {
	return updateObject(state, {
		organizations: state.organizations.map(
			(organization, i) =>
				i === action.orgIndex
					? {
							...organization,
							creatingWhatConvertsAccount: false,
							whatConvertsAccountErrors: action.errors
						}
					: organization
		)
	});
};
const startUpdateWhatConvertsAccount = (state, action) => {
	return updateObject(state, {
		organizations: state.organizations.map(
			(organization, i) =>
				i === action.orgIndex
					? {
							...organization,
							updatingWhatConvertsAccount: true,
							whatConvertsAccountErrors: null
						}
					: organization
		)
	});
};
const updateWhatConvertsAccountSuccess = (state, action) => {
	return updateObject(state, {
		organizations: state.organizations.map(
			(organization, i) =>
				i === action.orgIndex
					? {
							...organization,
							what_converts_account: action.whatConvertsAccount,
							updatingWhatConvertsAccount: false,
							whatConvertsAccountErrors: null
						}
					: organization
		)
	});
};
const updateWhatConvertsAccountFailure = (state, action) => {
	return updateObject(state, {
		organizations: state.organizations.map(
			(organization, i) =>
				i === action.orgIndex
					? {
							...organization,
							updatingWhatConvertsAccount: false,
							whatConvertsAccountErrors: action.errors
						}
					: organization
		)
	});
};

const startEditorialRequirementsFetch = (state, action) => {
	return updateObject(state, {
		isLoadingEditorialRequirements: true
	});
};

const fetchEditorialRequirementsFailure = (state, action) => {
	return updateObject(state, {
		isLoadingEditorialRequirements: false,
		errors: action.errors
	});
};

const setEditorialRequirements = (state, action) => {
	return updateObject(state, {
		isLoadingEditorialRequirements: false,
		editorialRequirementsLoaded: true,
		editorialRequirements: action.editorialRequirements
	});
};

const addingEditorialRequirement = (state, action) => {
	return updateObject(state, {
		isAddingEditorialRequirement: true,
		errors: null
	});
};

const addEditorialRequirementSuccess = (state, action) => {
	return updateObject(state, {
		editorialRequirements: state.editorialRequirements.concat(action.editorialRequirement),
		isAddingEditorialRequirement: false,
		errors: null
	});
};

const updateEditorialRequirementSuccess = (state, action) => {
	let editorialRequirementIndex = state.editorialRequirements.findIndex((x) => x.id === action.id);
	return updateObject(state, {
		editorialRequirements: state.editorialRequirements.map(
			(editorialRequirement, i) =>
				i === editorialRequirementIndex ? action.editorialRequirement : editorialRequirement
		)
	});
};

const editorialRequirementFailure = (state, action) => {
	return updateObject(state, {
		errors: action.errors,
		isAddingEditorialRequirement: false
	});
};

const startCreateReportPresentationTemplateFromMaster = (state, action) => {
	return updateObject(state, {
		organizations: state.organizations.map(
			(organization, index) =>
				index === action.orgIndex
					? {
							...organization,
							isCreatingPresentationTemplate: true
						}
					: organization
		)
	});
};

const createReportPresentationTemplateFromMasterSuccess = (state, action) => {
	return updateObject(state, {
		organizations: state.organizations.map(
			(organization, index) =>
				index === action.orgIndex
					? {
							...organization,
							isCreatingPresentationTemplate: false,
							monthly_report_template: action.presentationTemplate.id
						}
					: organization
		)
	});
};

const createReportPresentationTemplateFromMasterFailure = (state, action) => {
	return updateObject(state, {
		organizations: state.organizations.map(
			(organization, index) =>
				index === action.orgIndex
					? {
							...organization,
							isCreatingPresentationTemplate: false,
							presentationTemplateErrors: action.errors
						}
					: organization
		)
	});
};

const startBulkReportPresentationTemplateUpdate = (state, action) => {
	return updateObject(state, {
		organizations: state.organizations.map((organization, index) => action.organizationIndexes.includes(index) ? {
			...organization,
			isRecreatingPresentationTemplate: true,
			didRecreatePresentationTemplate: false,
		} : organization)
	})
}
const bulkReportPresentationTemplateUpdateSuccess = (state, action) => {

	return updateObject(state, {
		organizations: state.organizations.map(organization => {
			const foundOrganization = action.updatedOrganizations.find(org => org.id === organization.id);
		
			return foundOrganization ? {
				...foundOrganization,
				isRecreatingPresentationTemplate: false,
				didRecreatePresentationTemplate: true,
			} : organization
		})
	})
}
const bulkReportPresentationTemplateUpdateFailure = (state, action) => {
	return updateObject(state, {
		organizations: state.organizations.map((organization, index) => action.organizationIndexes.includes(index) ? {
			...organization,
			isRecreatingPresentationTemplate: false,
			didRecreatePresentationTemplate: false,
		} : organization)
	})
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.ADD_MESSAGE:
			return addMessage(state, action);
		case actionTypes.REMOVE_MESSAGE:
			return removeMessage(state, action);
		case actionTypes.START_ORGANIZATIONS_FETCH:
			return startOrganizationsFetch(state, action);
		case actionTypes.FETCH_ORGANIZATIONS_FAILURE:
			return fetchOrganizationsFailure(state, action);
		case actionTypes.SET_ORGANIZATIONS:
			return setOrganizations(state, action);
		case actionTypes.ADDING_ORGANIZATION:
			return addingOrganization(state, action);
		case actionTypes.ADD_ORGANIZATION_SUCCESS:
			return addOrganizationSuccess(state, action);
		case actionTypes.UPDATING_ORGANIZATION:
			return updatingOrganization(state, action);
		case actionTypes.UPDATE_ORGANIZATION_SUCCESS:
			return updateOrganizationSuccess(state, action);
		case actionTypes.ARCHIVE_ORGANIZATION_SUCCESS:
			return archiveOrganizationSuccess(state, action);
		case actionTypes.UPDATE_ORGANIZATION_FAILURE:
			return updateOrganizationFailure(state, action);
		case actionTypes.UPDATE_ORGANIZATION_SLIDE_DECK_TEMPLATE:
			return updateOrganizationSlideDeckTemplate(state, action);
		case actionTypes.ORGANIZATION_FAILURE:
			return organizationFailure(state, action);
		case actionTypes.START_GET_OVERDUE_CONTENT_APPROVALS:
			return startGetOverdueContentApprovals(state, action);
		case actionTypes.GET_OVERDUE_CONTENT_APPROVALS_SUCCESS:
			return getOverdueContentApprovalsSuccess(state, action);
		case actionTypes.GET_OVERDUE_CONTENT_APPROVALS_FAILURE:
			return getOverdueContentApprovalsFailure(state, action);
		case actionTypes.START_CREATE_WHAT_CONVERTS_ACCOUNT:
			return startCreateWhatConvertsAccount(state, action);
		case actionTypes.CREATE_WHAT_CONVERTS_ACCOUNT_SUCCESS:
			return createWhatConvertsAccountSuccess(state, action);
		case actionTypes.CREATE_WHAT_CONVERTS_ACCOUNT_FAILURE:
			return createWhatConvertsAccountFailure(state, action);
		case actionTypes.START_UPDATE_WHAT_CONVERTS_ACCOUNT:
			return startUpdateWhatConvertsAccount(state, action);
		case actionTypes.UPDATE_WHAT_CONVERTS_ACCOUNT_SUCCESS:
			return updateWhatConvertsAccountSuccess(state, action);
		case actionTypes.UPDATE_WHAT_CONVERTS_ACCOUNT_FAILURE:
			return updateWhatConvertsAccountFailure(state, action);
		case actionTypes.START_EDITORIAL_REQUIREMENTS_FETCH:
			return startEditorialRequirementsFetch(state, action);
		case actionTypes.FETCH_EDITORIAL_REQUIREMENTS_FAILURE:
			return fetchEditorialRequirementsFailure(state, action);
		case actionTypes.SET_EDITORIAL_REQUIREMENTS:
			return setEditorialRequirements(state, action);
		case actionTypes.ADDING_EDITORIAL_REQUIREMENT:
			return addingEditorialRequirement(state, action);
		case actionTypes.ADD_EDITORIAL_REQUIREMENT_SUCCESS:
			return addEditorialRequirementSuccess(state, action);
		case actionTypes.UPDATE_EDITORIAL_REQUIREMENT_SUCCESS:
			return updateEditorialRequirementSuccess(state, action);
		case actionTypes.EDITORIAL_REQUIREMENT_FAILURE:
			return editorialRequirementFailure(state, action);
		case actionTypes.START_CREATE_REPORT_PRESENTATION_FROM_MASTER:
			return startCreateReportPresentationTemplateFromMaster(state, action);
		case actionTypes.CREATE_REPORT_PRESENTATION_FROM_MASTER_SUCCESS:
			return createReportPresentationTemplateFromMasterSuccess(state, action);
		case actionTypes.CREATE_REPORT_PRESENTATION_FROM_MASTER_FAILURE:
			return createReportPresentationTemplateFromMasterFailure(state, action);
		case actionTypes.START_BULK_REPORT_PRESENTATION_TEMPLATE_UPDATE:
			return startBulkReportPresentationTemplateUpdate(state, action);
		case actionTypes.BULK_REPORT_PRESENTATION_TEMPLATE_UPDATE_SUCCESS:
			return bulkReportPresentationTemplateUpdateSuccess(state, action);
		case actionTypes.BULK_REPORT_PRESENTATION_TEMPLATE_UPDATE_FAILURE:
			return bulkReportPresentationTemplateUpdateFailure(state, action);
		default:
			return state;
	}
};

export default reducer;
