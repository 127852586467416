import React, { Component } from "react";
import "./PostingOverview.css";
import { connect } from "react-redux";
import {
  wordpress,
  organizations,
  contentGeneration,
} from "../../../store/actions";
import {
  Loader,
  Button,
  Divider,
  Message,
  Transition,
} from "semantic-ui-react";
import WithSidebar from "../../../hoc/WithSidebar/WithSidebar";
import PostingOverviewTable from "./PostingOverviewTable/PostingOverviewTable";
import moment from "moment";
import ActionHeader from "../../../components/ActionHeader/ActionHeader";
import PostingOverviewDetails from "./PostingOverviewDetails/PostingOverviewDetails";
import PostingOverviewLoader from "./PostingOverviewLoader/PostingOverviewLoader";

class PostingOverview extends Component {
  state = {
    selectedAccount: null,
    showErrors: false,
    showSuccessMessage: false,
  };

  componentDidMount() {
    this.props.accounts &&
      !this.props.accounts[0] &&
      this.props.fetchOrganizations();
    this.props.contentStaff &&
      !this.props.contentStaff[0] &&
      this.props.fetchContentStaff();
    this.props.accounts &&
      this.props.accounts[0] &&
      this.fetchAllAccountPosts();
  }

  componentDidUpdate(prevProps) {
    this.props.accounts &&
      prevProps.accounts &&
      prevProps.accounts !== this.props.accounts &&
      this.props.accounts[0] &&
      // this.props.fetchAllAccountPosts(this.props.accounts.filter(account => account.report_required).slice(0, 12), { status: '[future,publish]' });
      this.fetchAllAccountPosts();
    if (
      this.props.allAccountPosts &&
      this.props.allAccountPosts[0] &&
      prevProps.allAccountPostsProgressSuccess === true &&
      this.props.allAccountPostsProgressSuccess === false
    ) {
      this.setState({ showSuccessMessage: true });
      setTimeout(() => {
        this.setState({ showSuccessMessage: false });
      }, 1250);
    }
  }

  isThreeMonthsOld = (date) => {
    const threeMonthsAgo = moment().subtract(3, "months");
    return moment(date) <= threeMonthsAgo ? true : false;
  };

  fetchAllAccountPosts = () => {
    this.props.allAccountPosts &&
      !this.props.allAccountPosts[0] &&
      this.props.asyncFetchBulkAccountPosts(
        this.props.accounts.filter(
          (account) =>
            account.report_required && this.isThreeMonthsOld(account.created_at)
        ),
        {
          status: "[future,publish]",
        }
      );
  };

  renderLoadingDetails = () => {
    if (this.props.allAccountPostsLoading) {
      return "Posts";
    } else if (this.props.accountsLoading) {
      return "Account Data";
    } else if (this.props.contentStaffLoading) {
      return "Content Data";
    } else {
      return "General Data";
    }
  };

  getLead = (account) => {
    return account && account.account_lead ? account.account_lead.name : null;
  };

  getPm = (account) => {
    return account && account.project_manager ? account.project_manager.name : null;
  };

  getUser = (userID) => {
    return this.props.contentStaff && this.props.contentStaff[0] && userID
      ? this.props.contentStaff.find((staff) => staff.id === userID)
      : null;
  };

  getPostsByWeeksBack = (accountPosts, weeksBack) => {
    const weeksBackDate = moment().subtract(weeksBack, "weeks");
    if (accountPosts && accountPosts[0]) {
      return accountPosts.filter(
        (posts) =>
          moment(posts.date) >= weeksBackDate && posts.status === "publish"
      );
    }
  };

  getScheduledPosts = (accountPosts) => {
    if (accountPosts && accountPosts[0]) {
      return accountPosts.filter((posts) => posts.status === "future");
    }
  };

  handleSelectAccountPosts = (e, accountPosts) => {
    this.setState({ selectedAccount: accountPosts });
  };

  handleClearAccountPosts = () => {
    this.setState({ selectedAccount: null });
  };

  renderStatusClass = (postCount, targetCount) => {
    if (postCount >= targetCount) {
      return "onTrack";
    } else {
      return "inDanger";
    }
  };

  handleShowErrors = () => {
    this.setState({ showErrors: true });
  };

  handleHideErrors = () => {
    this.setState({ showErrors: false });
  };

  showErrorBlock = () => {
    const accountPostsErrors =
      this.props.allAccountPosts &&
      this.props.allAccountPosts[0] &&
      this.props.allAccountPosts.filter((posts) => posts.errors);
    return accountPostsErrors && accountPostsErrors[0] && this.state.showErrors
      ? true
      : false;
  };

  // getOverdueContentApprovals = async (accountId) => {
  // 	await this.props.getOverdueContentApprovals(accountId)
  // 	const correspondingAccountData = this.props.accounts.find(account => account.id === accountId);

  // }

  getCorrespondingAccountData = (accountId) => {
    const correspondingAccountData = this.props.accounts.find(
      (account) => account.id === accountId
    );
    return correspondingAccountData ? correspondingAccountData : null;
  };

  render() {
    const {
      allAccountPosts,
      contentStaff,
      accounts,
      allAccountPostsProgressSuccess,
      allAccountPostsLoaded,
    } = this.props;
    const { selectedAccount, showErrors, showSuccessMessage } = this.state;
    const accountPosts = allAccountPosts
      .filter((posts) => !posts.errors && posts.data && posts.data[0])
      .map((posts) => ({
        ...posts,
        twelveWeeksBack: this.getPostsByWeeksBack(posts.data, 12),
        fourWeeksBack: this.getPostsByWeeksBack(posts.data, 4),
        scheduled: this.getScheduledPosts(posts.data),
        lead: this.getUser(posts.account.account_lead) && this.getUser(posts.account.account_lead).name,
        pm: this.getUser(posts.account.project_manager) && this.getUser(posts.account.project_manager).name,
        finalReview: posts.account && this.getCorrespondingAccountData(posts.account.id) && this.getCorrespondingAccountData(posts.account.id).overdueContentApprovals
      }));
    const accountPostsErrors = allAccountPosts.filter((posts) => posts.errors);

    return (
      <div className="PostingOverview">
        <ActionHeader headerText="Posting Overview">
          {(allAccountPostsProgressSuccess || showSuccessMessage) &&
          this.props.accounts &&
          this.props.accounts[0] ? (
            <PostingOverviewLoader
              currentCount={accountPosts.length}
              totalCount={
                this.props.accounts.filter(
                  (account) =>
                    account.report_required &&
                    this.isThreeMonthsOld(account.created_at)
                ).length
              }
              showSuccessMessage={showSuccessMessage}
            />
          ) : (
            accountPostsErrors &&
            accountPostsErrors[0] &&
            !showErrors && (
              <div
                onClick={() => this.handleShowErrors()}
                className="PostingOverview--errorMessage"
              >
                <Message negative size="tiny">
                  <p>
                    There were <strong>{accountPostsErrors.length}</strong>{" "}
                    account errors while pulling data. Click here to view them.
                  </p>
                </Message>
              </div>
            )
          )}
        </ActionHeader>
        <Transition
          visible={this.showErrorBlock()}
          animation="fade down"
          duration={500}
        >
          <div className="PostingOverview--errorList">
            <Message error onDismiss={() => this.handleHideErrors()}>
              <Message.Header>Errors Found While Pulling Data</Message.Header>
              <Message.Content>
                These errors are usually caused by the organization domain name
                being incorrect or Duo being forced on the editor role. Please
                ensure the domain name is the exact domain (i.e. www when it
                should be non-www) and that the Duo options for that site are
                ONLY check for the Admin role.
              </Message.Content>
              <Message.List>
                {accountPostsErrors.map((error, i) => (
                  <Message.Item key={i}>
                    <strong>{error.account.dba_name}: </strong>
                    <span>{error.errors}</span>
                  </Message.Item>
                ))}
              </Message.List>
            </Message>
          </div>
        </Transition>
        {allAccountPostsProgressSuccess || allAccountPostsLoaded ? (
          allAccountPosts && allAccountPosts[0] ? (
            <div className="PostingOverview--table">
              <WithSidebar
                leftWidth={this.state.selectedAccount ? 9 : 16}
                rightWidth={this.state.selectedAccount ? 7 : 0}
                isShowing={this.state.selectedAccount}
                sidebarComponent={
                  <div className="PostingOverview--sidebar">
                    <ActionHeader
                      size="h3"
                      headerText={
                        selectedAccount && selectedAccount.account.dba_name
                      }
                    >
                      <Button
                        size="tiny"
                        basic
                        compact
                        onClick={() => this.handleClearAccountPosts()}
                      >
                        Close
                      </Button>
                    </ActionHeader>
                    <Divider />
                    <PostingOverviewDetails
                      accountPosts={selectedAccount}
                      getCorrespondingAccountData={
                        this.getCorrespondingAccountData
                      }
                    />
                  </div>
                }
              >
                <PostingOverviewTable
                  accounts={accounts}
                  accountPosts={accountPosts}
                  contentStaff={contentStaff}
                  getUser={this.getUser}
                  getPostsByWeeksBack={this.getPostsByWeeksBack}
                  getScheduledPosts={this.getScheduledPosts}
                  handleRowSelect={this.handleSelectAccountPosts}
                  selectedAccountPosts={this.state.selectedAccount}
                  renderStatusClass={this.renderStatusClass}
                  isLoading={this.props.allAccountPostsLoading}
                  finishedLoading={this.props.allAccountPostsLoaded}
                  getCorrespondingAccountData={this.getCorrespondingAccountData}
                  getOverdueContentApprovals={
                    this.props.getOverdueContentApprovals
                  }
                />
              </WithSidebar>
            </div>
          ) : (
            <div className="PostingOverview--noData">
              <p>There are currently no Account Posts</p>
            </div>
          )
        ) : (
          <Loader size="large" active>
            Loading {this.renderLoadingDetails()}
          </Loader>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    messages: state.wordpress.messages,
    accounts: state.organizations.organizations,
    accountsLoading: state.organizations.isLoading,
    accountsLoaded: state.organizations.isLoaded,
    allAccountPosts: state.wordpress.allAccountPosts.data,
    allAccountPostsLoading: state.wordpress.allAccountPosts.isLoading,
    allAccountPostsLoaded: state.wordpress.allAccountPosts.isLoaded,
    allAccountPostsProgressSuccess:
      state.wordpress.allAccountPosts.progressSuccess,
    allAccountPostsErrors: state.wordpress.allAccountPosts.errors,
    contentStaff: state.contentGeneration.contentStaff,
    contentStaffLoading: state.contentGeneration.isContentDataLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrganizations: () => {
      dispatch(organizations.fetchOrganizations());
    },
    fetchContentStaff: () => dispatch(contentGeneration.fetchContentStaff()),
    fetchAllAccountPosts: (accounts, params) =>
      dispatch(wordpress.fetchBulkAccountPosts(accounts, params)),
    asyncFetchBulkAccountPosts: (accounts, params) =>
      dispatch(wordpress.asyncFetchBulkAccountPosts(accounts, params)),
    getOverdueContentApprovals: (orgId) =>
      dispatch(organizations.getOverdueContentApprovals(orgId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostingOverview);
