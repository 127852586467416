import React from 'react';
import './ReportPresentationOrgListItem.css';
import { List, Icon, Menu, Loader, Popup, Label } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

const ReportPresentationOrgListItem = ({
	org,
	template,
	path,
	isMasterTemplate,
	handleCreateReportTemplateFromMaster,
	getMostRecentReport
}) => {
	const isHandlingAction = () => {
		if (org && org.isCreatingPresentationTemplate) {
			return true;
		} else {
			return false;
		}
	};

	const renderLastUpdated = () => {
		const report = getMostRecentReport(org.id, true);
		if (report) {
			const lastUpdated = report.last_updated ? report.last_updated : report.date_created;
			if (report.last_updated) {
				if (moment(lastUpdated) > moment(template.last_updated)) {
					return moment(lastUpdated).format('MM/DD/YYYY');
				} else {
					return moment(template.last_updated).format('MM/DD/YYYY');
				}
			}
		} else {
			return moment(template.last_updated).format('MM/DD/YYYY');
		}
	};

	return (
		<List.Item
			className={`ReportPresentationOrgList--item${isMasterTemplate
				? ' ReportPresentationOrgList--item__master'
				: ''} ${isHandlingAction() ? ' isHandlingAction' : ''}`}
		>
			{isHandlingAction() && (
				<Loader active inline="centered" size="small" className="ReportPresentationOrgList--loader" />
			)}
			{/* <List.Icon
				name={isMasterTemplate ? 'star' : 'area chart'}
				size="large"
				color={template ? 'green' : 'grey'}
				verticalAlign="middle"
				className={`ReportPresentationOrgList--item__icon`}
			/> */}
			<List.Content>
				<List.Header as="h4" className={`ReportPresentationOrgList--header ${!template && ' mutedHeader'}`}>
					{isMasterTemplate ? 'Master Report Presentation Template' : org.dba_name}
				</List.Header>
				<List.Description className="ReportPresentationOrgList--description">
					<div className="ReportPresentationOrgList--description__left">
						{template ? isMasterTemplate ? (
							<span>Last Updated: {moment(template.last_updated).format('MM/DD/YYYY')}</span>
						) : (
							<div className="ReportPresentationOrgList--currentReportLink">
								{getMostRecentReport(org.id) ? (
									<NavLink
										to={org && getMostRecentReport(org.id) ? getMostRecentReport(org.id) : '/'}
									>
										<span className="ReportPresentationOrgList--currentReportLinkText">
											<Icon name="area chart" /> View Most Recent Report{' '}
											<span className="ReportPresentationOrgList--currentReportLinkTextInfo">
												(Last Updated: {renderLastUpdated()})
											</span>
										</span>
									</NavLink>
								) : (
									<span>No existing Report Presentations</span>
								)}
							</div>
						) : (
							<span>No report template</span>
						)}
					</div>
				</List.Description>
			</List.Content>
			<List.Content className="ReportPresentationOrgList--actions">
				<div className="ReportPresentationOrgList--actions ReportPresentationOrgList--description__right">
					{!isMasterTemplate &&
					(!org.what_converts_account || !org.google_analytics_id) && (
						<div className="ReportPresentationOrgList--missingDataContainer">
							<Popup
								size="tiny"
								header={`Missing ${!org.google_analytics_id && !org.what_converts_account
									? 'Google Analytics ID and WhatConverts Account Data'
									: !org.google_analytics_id ? 'Google Analytics ID' : 'WhatConverts Account Data'}`}
								content="Click this link to input this data"
								trigger={
									<NavLink to={`/organizations/directory?organization=${org.slug}`}>
										<Label size="tiny" horizontal color="red">
											<Icon name="exclamation circle" />
											Missing Data
										</Label>
									</NavLink>
								}
							/>
						</div>
					)}
					<div className="ReportPresentationOrgList--menuSection">
						<Menu size="small" className="ReportPresentationOrgList--menu" secondary>
							{template ? (
								<React.Fragment>
									<NavLink to={`${path}/update/${isMasterTemplate ? 'master' : org.id}`}>
										<Menu.Item name="Edit" link>
											<Icon color="blue" name="edit outline" />
											<span>Edit</span>
										</Menu.Item>
									</NavLink>
									{!isMasterTemplate && (
										<React.Fragment>
											<NavLink
												to={`${path}/update/${isMasterTemplate ? 'master' : org.id}/settings`}
											>
												<Menu.Item name="Settings" link>
													<Icon color="blue" name="cogs" />
													<span>Settings</span>
												</Menu.Item>
											</NavLink>
											<NavLink
												to={`${path}/update/${isMasterTemplate ? 'master' : org.id}/entries`}
											>
												<Menu.Item name="Entries" link>
													<Icon color="blue" name="content" />
													<span>Entries</span>
												</Menu.Item>
											</NavLink>
											<NavLink
												to={`${path}/update/${isMasterTemplate ? 'master' : org.id}/preview`}
											>
												<Menu.Item name="Preview" link>
													<Icon color="blue" name="eye" />
													<span>Preview</span>
												</Menu.Item>
											</NavLink>
											{/* <ConfirmationModal
												handleConfirm={() =>
													deleteSlideDeckTemplate(
														template.id,
														'Presentation Template Deleted.'
													)}
												basic
												compact
												headerText="Delete Presentation Template?"
												bodyText="Are you sure you want to delete this Presentation Template?"
											>
												<Menu.Item name="Delete" link>
													<Icon color="red" name="trash alternate" />
													<span>Delete Template</span>
												</Menu.Item>
											</ConfirmationModal> */}
										</React.Fragment>
									)}
								</React.Fragment>
							) : (
								<React.Fragment>
									<NavLink to={`${path}/create/${isMasterTemplate ? 'master' : org.id}`}>
										<Menu.Item name="New Template" link>
											<Icon color="teal" name="plus" />
											<span>{isMasterTemplate ? 'Create' : 'New Template'}</span>
										</Menu.Item>
									</NavLink>
									{!isMasterTemplate && (
										<Menu.Item
											onClick={() => handleCreateReportTemplateFromMaster(org.id)}
											name="Create from Master Template"
											link
										>
											<Icon color="green" name="plus square" />
											<span>Create from Master Template</span>
										</Menu.Item>
									)}
								</React.Fragment>
							)}
						</Menu>
					</div>
				</div>
			</List.Content>
		</List.Item>
	);
};

export default ReportPresentationOrgListItem;
