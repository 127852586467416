import React from "react";
import "./AccountHealthOverviewListItem.css";
import { Header, Grid, Icon } from "semantic-ui-react";
import ProgressCircle from "../../../../components/ProgressCircle/ProgressCircle";
import { NavLink } from "react-router-dom";
import moment from "moment";

export const AccountHealthOverviewListItem = (props) => {
  const overview = props.overview;

  // const parseLeadsOverTime = (leads, monthsBack) => {
  // 	if (leads && leads[0]) {
  // 		let leadCount = 0;
  // 		const leadMonths = leads.slice(0, monthsBack ? monthsBack : leads.length);
  // 		leadMonths.map((leadMonth) => {
  // 			let sourceCount = 0;
  // 			const leadSources = leadMonth.sources;
  // 			leadSources && leadSources.map((leadSource) => (sourceCount = sourceCount + Number(leadSource.value)));

  // 			return (leadCount = leadCount + sourceCount);
  // 		});

  // 		return leadCount;
  // 	} else {
  // 		return 'No Lead Data';
  // 	}
  // };

  const isPositive = (number) => {
    const outcome = Math.sign(number);
    if (outcome === 1) {
      return true;
    } else {
      return false;
    }
  };

  const renderUnapprovedArticleStatus = (count) => {
    if (count === 0) {
      return <Icon color="green" name="check circle" />;
    } else if (count <= 4) {
      return <Icon color="yellow" name="exclamation circle" />;
    } else {
      return <Icon color="red" name="warning sign" />;
    }
  };

  const countLeads = (sources) => {
    let count = 0;
    if (sources && sources[0]) {
      sources.map((source) => (count = count + Number(source.value)));
    }
    return typeof count === "number" ? count.toLocaleString() : count;
  };

  const convertToLocaleNumber = (number) => {
    if (number && typeof number === "number") {
      return number.toLocaleString();
    } else {
      return number;
    }
  };

  const getScoreColor = (score, colorType) => {
    const convertedScore = props.calculateDecimalScore(score);
    if (convertedScore >= 90) {
      if (colorType === "background") {
        return "hsl(134, 52%, 96%)";
      } else if (colorType === "text") {
        return "hsl(134, 52%, 50%)";
      } else {
        return "hsl(134, 52%, 55%)";
      }
    } else if (convertedScore >= 50) {
      if (colorType === "background") {
        return "hsl(44, 97%, 96%)";
      } else if (colorType === "text") {
        return "hsl(44, 97%, 47%)";
      } else {
        return "hsl(44, 97%, 55%)";
      }
    } else {
      if (colorType === "background") {
        return "hsl(360, 71%, 96%)";
      } else if (colorType === "text") {
        return "hsl(360, 71%, 60%)";
      } else {
        return "hsl(360, 71%, 63%)";
      }
    }
  };

  const {
    calculateLeadsOverTimeDifference,
    calculateTrafficDataDifference,
    sortValue,
    sortDirection,
    calculateMonthsIntoContract,
    calculateDecimalScore,
  } = props;

  return (
    <figure className="AccountHealthOverviewListItem">
      <section className="AccountHealthOverviewListItem--header">
        <div className="AccountHealthOverviewListItem--headerSection AccountHealthOverviewListItem--headerSectionLeft">
          <Header className="AccountHealthOverviewListItem--heading" as="h4">
            <Header.Content>{overview.account.dba_name}</Header.Content>
            <Header.Subheader>
              <a
                href={overview.account.domain}
                target="_blank"
                rel="noopener noreferrer"
                className="AccountHealthOverviewListItem--domain"
              >
                {overview.account.domain}
                <Icon name="external alternate" />
              </a>
            </Header.Subheader>
          </Header>
        </div>
        <div className="AccountHealthOverviewListItem--headerSection AccountHealthOverviewListItem--headerSectionRight">
          {/* <div className="AccountHealthOverviewListItem--actions">
						<Button size="tiny" color="blue" basic disabled>
							View Overview
						</Button>
					</div> */}
          <div className="AccountHealthOverviewListItem--reportLink">
            <NavLink
              to={`/reporting/${moment().year()}/${moment().format('M')}/${
                overview.account.slug
              }`}
            >
              <span className="ReportPresentationOrgList--currentReportLinkText">
                <Icon name="area chart" /> View Most Recent Report{" "}
              </span>
            </NavLink>
          </div>
          <div
            className={`AccountHealthOverviewListItem--monthsIntoContractOutput${
              sortValue === "account.properStartDate" ? " active" : ""
            }`}
          >
            {sortValue === "account.properStartDate" && (
              <Icon
                name={
                  sortDirection
                    ? "sort content ascending"
                    : "sort content descending"
                }
              />
            )}
            <Icon
              className="contractMonthsIcon"
              name="calendar alternate outline"
            />
            <span>
              {calculateMonthsIntoContract(overview.account.properStartDate)}{" "}
              Months into Contract
            </span>
          </div>
        </div>
      </section>
      <section className="AccountHealthOverviewListItem--body">
        <Grid divided>
          <Grid.Row>
            {/* <Grid.Column width={2}>
							<div
								className={`AccountHealthOverviewListItem--cell AccountHealthOverviewListItem--monthsIntoContract${sortValue ===
								'account.properStartDate'
									? ' active'
									: ''}`}
							>
								<div className="AccountHealthOverviewListItem--cellHeader">
									<Header as="h5">Months into Contract</Header>
									{sortValue === 'account.properStartDate' && (
										<Icon
											name={sortDirection ? 'sort content ascending' : 'sort content descending'}
										/>
									)}
								</div>
								<div className="AccountHealthOverviewListItem--cellContent">
									<span className="AccountHealthOverviewListItem--cellContentSingle">
										<span>{calculateMonthsIntoContract(overview.account.properStartDate)}</span>
										<Icon className="contractMonthsIcon" name="calendar alternate outline" />
									</span>
								</div>
							</div>
						</Grid.Column> */}
            <Grid.Column width={2}>
              <div
                className={`AccountHealthOverviewListItem--cell AccountHealthOverviewListItem--unapprovedArticles${
                  sortValue === "overdue_approvals.length" ? " active" : ""
                }`}
              >
                <div className="AccountHealthOverviewListItem--cellHeader">
                  <Header as="h5">Unapproved Articles</Header>
                  {sortValue === "overdue_approvals.length" && (
                    <Icon
                      name={
                        sortDirection
                          ? "sort content ascending"
                          : "sort content descending"
                      }
                    />
                  )}
                </div>
                <div className="AccountHealthOverviewListItem--cellContent">
                  <span className="AccountHealthOverviewListItem--cellContentSingle">
                    <span>
                      {overview.overdue_approvals &&
                        overview.overdue_approvals.length}
                    </span>
                    {renderUnapprovedArticleStatus(
                      overview.overdue_approvals.length
                    )}
                  </span>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column width={4}>
              <div
                className={`AccountHealthOverviewListItem--cell AccountHealthOverviewListItem--leadsOverTime${
                  sortValue === "overallLeadsDifference" ? " active" : ""
                }`}
              >
                <div className="AccountHealthOverviewListItem--cellHeader">
                  <Header as="h5">
                    Traffic <span className="smallText">(Monthly)</span>
                  </Header>
                  {sortValue === "overallLeadsDifference" && (
                    <Icon
                      name={
                        sortDirection
                          ? "sort content ascending"
                          : "sort content descending"
                      }
                    />
                  )}
                </div>
                <div className="AccountHealthOverviewListItem--cellContent">
                  {overview.leads_data ? (
                    <React.Fragment>
                      <span className="AccountHealthOverviewListItem--cellContentSingle">
                        <div className="AccountHealthOverviewListItem--dataCalculation">
                          <span>
                            {countLeads(overview.leads_data[0].sources)}
                          </span>
                          <span className="AccountHealthOverviewListItem--difference">
                            {isPositive(
                              calculateLeadsOverTimeDifference(
                                overview.leads_data
                              )
                            ) ? (
                              <span className="positive">
                                +
                                {convertToLocaleNumber(
                                  overview.oneMonthLeadsDifference
                                )}
                              </span>
                            ) : (
                              <span className="negative">
                                {convertToLocaleNumber(
                                  overview.oneMonthLeadsDifference
                                )}
                              </span>
                            )}
                          </span>
                        </div>
                        <div className="AccountHealthOverviewListItem--dataCalculation small">
                          <span className="AccountHealthOverviewListItem--listItemLabel">
                            Past Month
                          </span>
                          {/* <span className="AccountHealthOverviewListItem--listItemOutput">
														<span className="AccountHealthOverviewListItem--listItemOutputItem">
															{overview.leads_data[1] &&
																countLeads(overview.leads_data[1].sources)}
														</span>
													</span> */}
                        </div>
                      </span>
                      <div className="AccountHealthOverviewListItem--list">
                        <div className="AccountHealthOverviewListItem--listItem">
                          <span className="AccountHealthOverviewListItem--listItemLabel">
                            3 Months Ago
                          </span>
                          {overview.leads_data[2] ? (
                            <span className="AccountHealthOverviewListItem--listItemOutput">
                              <span className="AccountHealthOverviewListItem--listItemOutputItem">
                                {countLeads(overview.leads_data[2].sources)}
                              </span>
                              <span className="AccountHealthOverviewListItem--listItemOutputItem">
                                {isPositive(
                                  calculateLeadsOverTimeDifference(
                                    overview.leads_data,
                                    3
                                  )
                                ) ? (
                                  <span className="positive">
                                    +
                                    {convertToLocaleNumber(
                                      overview.threeMonthsLeadDifference
                                    )}
                                  </span>
                                ) : (
                                  <span className="negative">
                                    {convertToLocaleNumber(
                                      overview.threeMonthsLeadDifference
                                    )}
                                  </span>
                                )}
                              </span>
                            </span>
                          ) : (
                            <span className="AccountHealthOverviewListItem--listItemOutput noData">
                              -
                            </span>
                          )}
                        </div>
                        <div className="AccountHealthOverviewListItem--listItem">
                          <span className="AccountHealthOverviewListItem--listItemLabel">
                            6 Months Ago
                          </span>
                          {overview.leads_data[5] ? (
                            <span className="AccountHealthOverviewListItem--listItemOutput">
                              <span className="AccountHealthOverviewListItem--listItemOutputItem">
                                {countLeads(overview.leads_data[5].sources)}
                              </span>
                              <span className="AccountHealthOverviewListItem--listItemOutputItem">
                                {isPositive(
                                  calculateLeadsOverTimeDifference(
                                    overview.leads_data,
                                    6
                                  )
                                ) ? (
                                  <span className="positive">
                                    +
                                    {convertToLocaleNumber(
                                      overview.sixMonthsLeadDifference
                                    )}
                                  </span>
                                ) : (
                                  <span className="negative">
                                    {convertToLocaleNumber(
                                      overview.sixMonthsLeadDifference
                                    )}
                                  </span>
                                )}
                              </span>
                            </span>
                          ) : (
                            <span className="AccountHealthOverviewListItem--listItemOutput noData">
                              -
                            </span>
                          )}
                        </div>
                        <div className="AccountHealthOverviewListItem--listItem">
                          <span className="AccountHealthOverviewListItem--listItemLabel">
                            12 Months Ago
                          </span>
                          {overview.leads_data[11] ? (
                            <span className="AccountHealthOverviewListItem--listItemOutput">
                              <span className="AccountHealthOverviewListItem--listItemOutputItem">
                                {countLeads(overview.leads_data[11].sources)}
                              </span>
                              <span className="AccountHealthOverviewListItem--listItemOutputItem">
                                {isPositive(
                                  calculateLeadsOverTimeDifference(
                                    overview.leads_data,
                                    12
                                  )
                                ) ? (
                                  <span className="positive">
                                    +
                                    {convertToLocaleNumber(
                                      overview.twelveMonthsLeadDifference
                                    )}
                                  </span>
                                ) : (
                                  <span className="negative">
                                    {convertToLocaleNumber(
                                      overview.twelveMonthsLeadDifference
                                    )}
                                  </span>
                                )}
                              </span>
                            </span>
                          ) : (
                            <span className="AccountHealthOverviewListItem--listItemOutput noData">
                              -
                            </span>
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    <span className="AccountHealthOverviewListItem--noData">
                      No Traffic Data
                    </span>
                  )}
                </div>
              </div>
            </Grid.Column>
            <Grid.Column width={4}>
              <div
                className={`AccountHealthOverviewListItem--cell AccountHealthOverviewListItem--trafficOverTime${
                  sortValue === "overallTrafficDifference" ? " active" : ""
                }`}
              >
                <div className="AccountHealthOverviewListItem--cellHeader">
                  <Header as="h5">
                    Leads <span className="smallText">(Monthly)</span>
                  </Header>
                  {sortValue === "overallTrafficDifference" && (
                    <Icon
                      name={
                        sortDirection
                          ? "sort content ascending"
                          : "sort content descending"
                      }
                    />
                  )}
                </div>
                <div className="AccountHealthOverviewListItem--cellContent">
                  {overview.organic_traffic_data ? (
                    <React.Fragment>
                      <span className="AccountHealthOverviewListItem--cellContentSingle">
                        <div className="AccountHealthOverviewListItem--dataCalculation">
                          <span>
                            {overview.organic_traffic_data[0] &&
                              convertToLocaleNumber(
                                overview.organic_traffic_data[0].total_count
                              )}
                          </span>
                          <span className="AccountHealthOverviewListItem--difference">
                            {isPositive(
                              calculateTrafficDataDifference(
                                overview.organic_traffic_data
                              )
                            ) ? (
                              <span className="positive">
                                +
                                {convertToLocaleNumber(
                                  overview.oneMonthTrafficDifference
                                )}
                              </span>
                            ) : (
                              <span className="negative">
                                {convertToLocaleNumber(
                                  overview.oneMonthTrafficDifference
                                )}
                              </span>
                            )}
                          </span>
                        </div>
                        <div className="AccountHealthOverviewListItem--dataCalculation small">
                          <span className="AccountHealthOverviewListItem--listItemLabel">
                            Past Month
                          </span>
                          {/* <span className="AccountHealthOverviewListItem--listItemOutput">
														<span className="AccountHealthOverviewListItem--listItemOutputItem">
														{overview.organic_traffic_data[1] &&
																convertToLocaleNumber(
																	overview.organic_traffic_data[1].total_count
																)}
														</span>
													</span> */}
                        </div>
                      </span>
                      <div className="AccountHealthOverviewListItem--list">
                        <div className="AccountHealthOverviewListItem--listItem">
                          <span className="AccountHealthOverviewListItem--listItemLabel">
                            3 Months Ago
                          </span>
                          {overview.organic_traffic_data[2] ? (
                            <span className="AccountHealthOverviewListItem--listItemOutput">
                              <span className="AccountHealthOverviewListItem--listItemOutputItem">
                                {convertToLocaleNumber(
                                  overview.organic_traffic_data[2].total_count
                                )}
                              </span>
                              <span className="AccountHealthOverviewListItem--listItemOutputItem">
                                {isPositive(
                                  calculateTrafficDataDifference(
                                    overview.organic_traffic_data,
                                    3
                                  )
                                ) ? (
                                  <span className="positive">
                                    +
                                    {convertToLocaleNumber(
                                      overview.threeMonthTrafficDifference
                                    )}
                                  </span>
                                ) : (
                                  <span className="negative">
                                    {convertToLocaleNumber(
                                      overview.threeMonthTrafficDifference
                                    )}
                                  </span>
                                )}
                              </span>
                            </span>
                          ) : (
                            <span className="AccountHealthOverviewListItem--listItemOutput noData">
                              -
                            </span>
                          )}
                        </div>
                        <div className="AccountHealthOverviewListItem--listItem">
                          <span className="AccountHealthOverviewListItem--listItemLabel">
                            6 Months Ago
                          </span>
                          {overview.organic_traffic_data[5] ? (
                            <span className="AccountHealthOverviewListItem--listItemOutput">
                              <span className="AccountHealthOverviewListItem--listItemOutputItem">
                                {convertToLocaleNumber(
                                  overview.organic_traffic_data[5].total_count
                                )}
                              </span>
                              <span className="AccountHealthOverviewListItem--listItemOutputItem">
                                {isPositive(
                                  calculateTrafficDataDifference(
                                    overview.organic_traffic_data,
                                    6
                                  )
                                ) ? (
                                  <span className="positive">
                                    +
                                    {convertToLocaleNumber(
                                      overview.sixMonthTrafficDifference
                                    )}
                                  </span>
                                ) : (
                                  <span className="negative">
                                    {convertToLocaleNumber(
                                      overview.sixMonthTrafficDifference
                                    )}
                                  </span>
                                )}
                              </span>
                            </span>
                          ) : (
                            <span className="AccountHealthOverviewListItem--listItemOutput noData">
                              -
                            </span>
                          )}
                        </div>
                        <div className="AccountHealthOverviewListItem--listItem">
                          <span className="AccountHealthOverviewListItem--listItemLabel">
                            12 Months Ago
                          </span>
                          {overview.organic_traffic_data[11] ? (
                            <span className="AccountHealthOverviewListItem--listItemOutput">
                              <span className="AccountHealthOverviewListItem--listItemOutputItem">
                                {convertToLocaleNumber(
                                  overview.organic_traffic_data[11].total_count
                                )}
                              </span>
                              <span className="AccountHealthOverviewListItem--listItemOutputItem">
                                {isPositive(
                                  calculateTrafficDataDifference(
                                    overview.organic_traffic_data,
                                    12
                                  )
                                ) ? (
                                  <span className="positive">
                                    +
                                    {convertToLocaleNumber(
                                      overview.twelveMonthTrafficDifference
                                    )}
                                  </span>
                                ) : (
                                  <span className="negative">
                                    {convertToLocaleNumber(
                                      overview.twelveMonthTrafficDifference
                                    )}
                                  </span>
                                )}
                              </span>
                            </span>
                          ) : (
                            <span className="AccountHealthOverviewListItem--listItemOutput noData">
                              -
                            </span>
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    <span className="AccountHealthOverviewListItem--noData">
                      No Lead Data
                    </span>
                  )}
                </div>
              </div>
            </Grid.Column>
            <Grid.Column width={3}>
              <div
                className={`AccountHealthOverviewListItem--cell AccountHealthOverviewListItem--pageSpeed AccountHealthOverviewListItem--pageSpeedDesktop${
                  sortValue === "desktop_page_speed_data.scores.average_score"
                    ? " active"
                    : ""
                }`}
              >
                <div className="AccountHealthOverviewListItem--cellHeader">
                  <Header as="h5">Desktop Score</Header>
                  {sortValue ===
                    "desktop_page_speed_data.scores.average_score" && (
                    <Icon
                      name={
                        sortDirection
                          ? "sort content ascending"
                          : "sort content descending"
                      }
                    />
                  )}
                </div>
                <div className="AccountHealthOverviewListItem--cellContent">
                  {overview.desktop_page_speed_data ? (
                    <React.Fragment>
                      {/* <span className="AccountHealthOverviewListItem--cellContentSingle">
												<div className="AccountHealthOverviewListItem--score">
													<ProgressCircle
														progress={calculateDecimalScore(
															overview.desktop_page_speed_data.scores.average_score
														)}
														borderColor={getScoreColor(
															overview.desktop_page_speed_data.scores.average_score,
															'main'
														)}
														backgroundColor={getScoreColor(
															overview.desktop_page_speed_data.scores.average_score,
															'background'
														)}
														textColor={getScoreColor(
															overview.desktop_page_speed_data.scores.average_score,
															'text'
														)}
														size={50}
														borderWidth={6}
													>
														{calculateDecimalScore(
															overview.desktop_page_speed_data.scores.average_score
														)}
													</ProgressCircle>
												</div>
											</span> */}
                      <div className="AccountHealthOverviewListItem--scoreList">
                        <div className="AccountHealthOverviewListItem--scoreListItem">
                          <span className="AccountHealthOverviewListItem--scoreListItemLabel">
                            Performance
                          </span>
                          <span className="AccountHealthOverviewListItem--scoreListItemScore">
                            <ProgressCircle
                              progress={calculateDecimalScore(
                                overview.desktop_page_speed_data.scores
                                  .performance
                              )}
                              borderColor={getScoreColor(
                                overview.desktop_page_speed_data.scores
                                  .performance,
                                "main"
                              )}
                              backgroundColor={getScoreColor(
                                overview.desktop_page_speed_data.scores
                                  .performance,
                                "background"
                              )}
                              textColor={getScoreColor(
                                overview.desktop_page_speed_data.scores
                                  .performance,
                                "text"
                              )}
                              size={30}
                              borderWidth={4}
                            >
                              {calculateDecimalScore(
                                overview.desktop_page_speed_data.scores
                                  .performance
                              )}
                            </ProgressCircle>
                          </span>
                        </div>
                        <div className="AccountHealthOverviewListItem--scoreListItem">
                          <span className="AccountHealthOverviewListItem--scoreListItemLabel">
                            SEO
                          </span>
                          <span className="AccountHealthOverviewListItem--scoreListItemScore">
                            <ProgressCircle
                              progress={calculateDecimalScore(
                                overview.desktop_page_speed_data.scores.seo
                              )}
                              borderColor={getScoreColor(
                                overview.desktop_page_speed_data.scores.seo,
                                "main"
                              )}
                              backgroundColor={getScoreColor(
                                overview.desktop_page_speed_data.scores.seo,
                                "background"
                              )}
                              textColor={getScoreColor(
                                overview.desktop_page_speed_data.scores.seo,
                                "text"
                              )}
                              size={30}
                              borderWidth={4}
                            >
                              {calculateDecimalScore(
                                overview.desktop_page_speed_data.scores.seo
                              )}
                            </ProgressCircle>
                          </span>
                        </div>
                        <div className="AccountHealthOverviewListItem--scoreListItem">
                          <span className="AccountHealthOverviewListItem--scoreListItemLabel">
                            Accessibility
                          </span>
                          <span className="AccountHealthOverviewListItem--scoreListItemScore">
                            <ProgressCircle
                              progress={calculateDecimalScore(
                                overview.desktop_page_speed_data.scores
                                  .accessibility
                              )}
                              borderColor={getScoreColor(
                                overview.desktop_page_speed_data.scores
                                  .accessibility,
                                "main"
                              )}
                              backgroundColor={getScoreColor(
                                overview.desktop_page_speed_data.scores
                                  .accessibility,
                                "background"
                              )}
                              textColor={getScoreColor(
                                overview.desktop_page_speed_data.scores
                                  .accessibility,
                                "text"
                              )}
                              size={30}
                              borderWidth={4}
                            >
                              {calculateDecimalScore(
                                overview.desktop_page_speed_data.scores
                                  .accessibility
                              )}
                            </ProgressCircle>
                          </span>
                        </div>
                        <div className="AccountHealthOverviewListItem--scoreListItem">
                          <span className="AccountHealthOverviewListItem--scoreListItemLabel">
                            Standards
                          </span>
                          <span className="AccountHealthOverviewListItem--scoreListItemScore">
                            <ProgressCircle
                              progress={calculateDecimalScore(
                                overview.desktop_page_speed_data.scores[
                                  "best-practices"
                                ]
                              )}
                              borderColor={getScoreColor(
                                overview.desktop_page_speed_data.scores[
                                  "best-practices"
                                ],
                                "main"
                              )}
                              backgroundColor={getScoreColor(
                                overview.desktop_page_speed_data.scores[
                                  "best-practices"
                                ],
                                "background"
                              )}
                              textColor={getScoreColor(
                                overview.desktop_page_speed_data.scores[
                                  "best-practices"
                                ],
                                "text"
                              )}
                              size={30}
                              borderWidth={4}
                            >
                              {calculateDecimalScore(
                                overview.desktop_page_speed_data.scores[
                                  "best-practices"
                                ]
                              )}
                            </ProgressCircle>
                          </span>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    <span className="AccountHealthOverviewListItem--noData">
                      No Desktop Score Data
                    </span>
                  )}
                </div>
              </div>
            </Grid.Column>
            <Grid.Column width={3}>
              <div
                className={`AccountHealthOverviewListItem--cell AccountHealthOverviewListItem--pageSpeed AccountHealthOverviewListItem--pageSpeedMobile${
                  sortValue === "mobile_page_speed_data.scores.average_score"
                    ? " active"
                    : ""
                }`}
              >
                <div className="AccountHealthOverviewListItem--cellHeader">
                  <Header as="h5">Mobile Score</Header>
                  {sortValue ===
                    "mobile_page_speed_data.scores.average_score" && (
                    <Icon
                      name={
                        sortDirection
                          ? "sort content ascending"
                          : "sort content descending"
                      }
                    />
                  )}
                </div>
                <div className="AccountHealthOverviewListItem--cellContent">
                  {overview.mobile_page_speed_data ? (
                    <React.Fragment>
                      {/* <div className="AccountHealthOverviewListItem--score">
												<ProgressCircle
													progress={calculateDecimalScore(
														overview.mobile_page_speed_data.scores.average_score
													)}
													borderColor={getScoreColor(
														overview.mobile_page_speed_data.scores.average_score,
														'main'
													)}
													backgroundColor={getScoreColor(
														overview.mobile_page_speed_data.scores.average_score,
														'background'
													)}
													textColor={getScoreColor(
														overview.mobile_page_speed_data.scores.average_score,
														'text'
													)}
													size={50}
													borderWidth={6}
												>
													{calculateDecimalScore(
														overview.mobile_page_speed_data.scores.average_score
													)}
												</ProgressCircle>
											</div> */}
                      <div className="AccountHealthOverviewListItem--scoreList">
                        <div className="AccountHealthOverviewListItem--scoreListItem">
                          <span className="AccountHealthOverviewListItem--scoreListItemLabel">
                            Performance
                          </span>
                          <span className="AccountHealthOverviewListItem--scoreListItemScore">
                            <ProgressCircle
                              progress={calculateDecimalScore(
                                overview.mobile_page_speed_data.scores
                                  .performance
                              )}
                              borderColor={getScoreColor(
                                overview.mobile_page_speed_data.scores
                                  .performance,
                                "main"
                              )}
                              backgroundColor={getScoreColor(
                                overview.mobile_page_speed_data.scores
                                  .performance,
                                "background"
                              )}
                              textColor={getScoreColor(
                                overview.mobile_page_speed_data.scores
                                  .performance,
                                "text"
                              )}
                              size={30}
                              borderWidth={4}
                            >
                              {calculateDecimalScore(
                                overview.mobile_page_speed_data.scores
                                  .performance
                              )}
                            </ProgressCircle>
                          </span>
                        </div>
                        <div className="AccountHealthOverviewListItem--scoreListItem">
                          <span className="AccountHealthOverviewListItem--scoreListItemLabel">
                            SEO
                          </span>
                          <span className="AccountHealthOverviewListItem--scoreListItemScore">
                            <ProgressCircle
                              progress={calculateDecimalScore(
                                overview.mobile_page_speed_data.scores.seo
                              )}
                              borderColor={getScoreColor(
                                overview.mobile_page_speed_data.scores.seo,
                                "main"
                              )}
                              backgroundColor={getScoreColor(
                                overview.mobile_page_speed_data.scores.seo,
                                "background"
                              )}
                              textColor={getScoreColor(
                                overview.mobile_page_speed_data.scores.seo,
                                "text"
                              )}
                              size={30}
                              borderWidth={4}
                            >
                              {calculateDecimalScore(
                                overview.mobile_page_speed_data.scores.seo
                              )}
                            </ProgressCircle>
                          </span>
                        </div>
                        <div className="AccountHealthOverviewListItem--scoreListItem">
                          <span className="AccountHealthOverviewListItem--scoreListItemLabel">
                            Accessibility
                          </span>
                          <span className="AccountHealthOverviewListItem--scoreListItemScore">
                            <ProgressCircle
                              progress={calculateDecimalScore(
                                overview.mobile_page_speed_data.scores
                                  .accessibility
                              )}
                              borderColor={getScoreColor(
                                overview.mobile_page_speed_data.scores
                                  .accessibility,
                                "main"
                              )}
                              backgroundColor={getScoreColor(
                                overview.mobile_page_speed_data.scores
                                  .accessibility,
                                "background"
                              )}
                              textColor={getScoreColor(
                                overview.mobile_page_speed_data.scores
                                  .accessibility,
                                "text"
                              )}
                              size={30}
                              borderWidth={4}
                            >
                              {calculateDecimalScore(
                                overview.mobile_page_speed_data.scores
                                  .accessibility
                              )}
                            </ProgressCircle>
                          </span>
                        </div>
                        <div className="AccountHealthOverviewListItem--scoreListItem">
                          <span className="AccountHealthOverviewListItem--scoreListItemLabel">
                            Standards
                          </span>
                          <span className="AccountHealthOverviewListItem--scoreListItemScore">
                            <ProgressCircle
                              progress={calculateDecimalScore(
                                overview.mobile_page_speed_data.scores[
                                  "best-practices"
                                ]
                              )}
                              borderColor={getScoreColor(
                                overview.mobile_page_speed_data.scores[
                                  "best-practices"
                                ],
                                "main"
                              )}
                              backgroundColor={getScoreColor(
                                overview.mobile_page_speed_data.scores[
                                  "best-practices"
                                ],
                                "background"
                              )}
                              textColor={getScoreColor(
                                overview.mobile_page_speed_data.scores[
                                  "best-practices"
                                ],
                                "text"
                              )}
                              size={30}
                              borderWidth={4}
                            >
                              {calculateDecimalScore(
                                overview.mobile_page_speed_data.scores[
                                  "best-practices"
                                ]
                              )}
                            </ProgressCircle>
                          </span>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    <span className="AccountHealthOverviewListItem--noData">
                      No Mobile Score Data
                    </span>
                  )}
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </section>
    </figure>
  );
};

export default AccountHealthOverviewListItem;
