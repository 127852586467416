import React, { Component } from "react";
import { DateInput } from "semantic-ui-calendar-react";
import "./DatePickerWidget.css";
import moment from "moment";

export default class DatePickerWidget extends Component {
  state = {
    date: this.props.date ? this.props.date : null
  };

  handleDateFormatClass = date => {
    let today = moment();
    let targetDate = moment(date);
    if (targetDate > today) {
      return "onTime"
    } else if (targetDate.isSame(today, "day")) {
      return "today"
    } else {
      return "late"
    }
  };
  
  handleSelectChange = async (event, { name, value }) => {
    await this.setState({
      [name]: value
    });
    return this.props.handleChange(this.state.date);
  };
  render() {
    return (
      <div className="DatePickerWidget">
        <DateInput
          closable
          className={this.handleDateFormatClass(this.state.date)}
          icon="calendar alternate outline"
          name="date"
          transparent
          placeholder="Choose Date"
          dateFormat="YYYY-MM-DD"
          value={
            this.state.date ? this.state.date : ""
          }
          onChange={this.handleSelectChange}
        />
      </div>
    );
  }
}
