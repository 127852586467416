import React, { Component } from 'react';
import './ReportingMonthTable.css';
import { Table, Icon, Button, Select } from 'semantic-ui-react';
import _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { toPascalCase } from '../../../../utility';

export default class ReportingMonthTable extends Component {
	state = {
		column: 'organization',
		data: this.props.data.map((data) => ({
			...data,
			organizationID: data.organization,
			organization: this.props.renderOrganization(data.organization),
			statusValue: data.status,
			status: this.props.renderStatus(data.status, true)
		})),
		direction: null
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.data !== this.props.data) {
			this.setData();
		}
	}

	setData = () => {
		this.setState({
			data: this.props.data
				.map((data) => ({
					...data,
					organizationID: data.organization,
					organization: this.props.renderOrganization(data.organization),
					statusValue: data.status,
					status: this.props.renderStatus(data.status, true)
				}))
		});
	};

	handleSort = (clickedColumn) => () => {
		const { column, data, direction } = this.state;

		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				data: _.sortBy(data, [ clickedColumn ]),
				direction: 'ascending'
			});

			return;
		}

		this.setState({
			data: data.reverse(),
			direction: direction === 'ascending' ? 'descending' : 'ascending'
		});
	};

	handleSelectChange = (e, results, reportID) => {
		const { name, value } = results;
		this.props.handleUpdateReport(name, value, reportID);
	};

	render() {
		const { column, data, direction } = this.state;
		return (
			<div className="ReportingMonthTable">
				<Table size="small" compact sortable celled>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell
								sorted={column === 'organization' ? direction : null}
								onClick={this.handleSort('organization')}
							>
								Organization
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={column === 'status' ? direction : null}
								onClick={this.handleSort('status')}
							>
								Status
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={column === 'presentation' ? direction : null}
								onClick={this.handleSort('presentation')}
							>
								Presentation Ready?
							</Table.HeaderCell>
							<Table.HeaderCell />
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{_.map(
							_.sortBy(data, [ this.state.column ]),
							({
								id,
								organizationID,
								organization,
								statusValue,
								status,
								presentation,
								isUpdating,
								creatingReport,
								reCreatingReport
							}) => (
								<Table.Row key={id}>
									<Table.Cell>{organization}</Table.Cell>
									<Table.Cell className="overflowVisible">
										{statusValue !== 'sent' ? (
											<div className="ReportingMonthTable--status">
												<Select
													name="status"
													loading={isUpdating}
													className="ReportingMonthTable--statusDropdown"
													onChange={(e, results) => this.handleSelectChange(e, results, id)}
													selection
													size="mini"
													value={statusValue}
													options={[
														{
															key: 'backlog',
															text: 'Backlog',
															value: 'backlog'
														},
														{
															key: 'assigned',
															text: 'Assigned',
															value: 'assigned'
														},
														{
															key: 'finalReview',
															text: 'Final Review',
															value: 'finalReview'
														},
														{
															key: 'sent',
															text: 'Sent',
															value: 'sent'
														}
													]}
												/>
												{statusValue === 'finalReview' && (
													<div className="ReportingMonthTable--sentButton">
														<Button
															onClick={() => this.props.handleReportSend(id)}
															color="green"
															size="mini"
															icon
														>
															Sent Report <Icon name="send" />
														</Button>
													</div>
												)}
											</div>
										) : (
											<span className="ReportingMonthTable--sentStatus">{toPascalCase(status)}</span>
										)}
									</Table.Cell>
									<Table.Cell textAlign="center">
										{presentation ? (
											<Icon name="checkmark" color="green" />
										) : (
											<Icon name="times" color="red" />
										)}
									</Table.Cell>
									<Table.Cell>
										{presentation ? (
											<div className="ReportingMonthTable-createActions">
												<NavLink
													to={`${this.props.match.url}/${this.props.getOrganizationSlug(
														organizationID
													)}`}
												>
													<Button
														color="blue"
														size="mini"
														loading={creatingReport || reCreatingReport}
													>
														View Presentation
													</Button>
												</NavLink>
												<Button
													onClick={() =>
														this.props.reCreateReportPresentation(id, 'Report Re-Created!')}
													loading={creatingReport || reCreatingReport}
													size="mini"
												>
													Re-Create Presentation
												</Button>
											</div>
										) : this.props.handleHasOrgPresentationTemplate(organizationID) ? (
											<Button
												onClick={() =>
													this.props.createReportPresentation(id, 'Report Created!')}
												loading={creatingReport || reCreatingReport}
												color="teal"
												size="mini"
											>
												Create Presentation
											</Button>
										) : (
											<Button disabled size="mini">
												Presentation Template doesn't exist
											</Button>
										)}
									</Table.Cell>
								</Table.Row>
							)
						)}
					</Table.Body>
				</Table>
			</div>
		);
	}
}
