import _ from "lodash";
import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import "./KeywordTable.css";
import TableFilter from "../TableFilter/TableFilter";
import moment from "moment";

const valueOptions = [
  {
    key: 1,
    text: "Low",
    value: "Low"
  },
  {
    key: 2,
    text: "Medium",
    value: "Medium"
  },
  {
    key: 3,
    text: "High",
    value: "High"
  },
  {
    key: 4,
    text: "Very High",
    value: "Very High"
  }
];

export default class KeywordTable extends Component {
  state = {
    column: null,
    data: this.props.tableData,
    direction: null,
    filters: {
      seo_filter: null,
      business_filter: null
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.tableData !== this.props.tableData) {
      this.setState({ data: this.props.tableData });
    }
  }

  handleSort = clickedColumn => () => {
    const { column, data, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: _.sortBy(data, [clickedColumn]),
        direction: "ascending"
      });

      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending"
    });
  };

  handleFilter = (event, { name, value }) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [name]: value !== "" ? value : null
      }
    });
  };

  ifData = data => {
    if (this.props.tableData[0]) {
      if (data in this.props.tableData[0]) {
        return true;
      } else {
        return false;
      }
    }
  };

  handleTableFilter = data => {
    if (this.state.filters.seo_filter && this.state.filters.business_filter) {
      return data.filter(
        d =>
          d.seo_value === this.state.filters.seo_filter &&
          d.business_value === this.state.filters.business_filter
      );
    } else if (this.state.filters.seo_filter) {
      return data.filter(d => d.seo_value === this.state.filters.seo_filter);
    } else if (this.state.filters.business_filter) {
      return data.filter(
        d => d.business_value === this.state.filters.business_filter
      );
    } else {
      return data;
    }
  };

  findAndFormatMonth = monthId => {
    const month = this.props.planningMonths.find(pm => pm.id === monthId);
    let monthOutput = null;
    if (month) {
      monthOutput = `${moment(month.month, "MM").format("MMMM")} ${month.year}`;
    }
    return monthOutput;
  };

  render() {
    const { column, data, direction } = this.state;

    return (
      <div className="KeywordTable">
        <div className="KeywordTableFilters">
          <TableFilter
            options={valueOptions}
            filterName="seo_filter"
            placeholder="Filter SEO Value"
            handleChange={this.handleFilter}
          />
          <TableFilter
            options={valueOptions}
            filterName="business_filter"
            placeholder="Filter Business Value"
            handleChange={this.handleFilter}
          />
        </div>
        <Table size="small" compact sortable celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={
                  column === "keyword.parent_keyword.name" ? direction : null
                }
                onClick={this.handleSort("keyword.parent_keyword.name")}
              >
                Parent Keyword
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "keyword.name" ? direction : null}
                onClick={this.handleSort("keyword.name")}
              >
                Keyword
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "keyword.difficulty" ? direction : null}
                onClick={this.handleSort("keyword.difficulty")}
              >
                Difficulty
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "keyword.volume" ? direction : null}
                onClick={this.handleSort("keyword.volume")}
              >
                Volume
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "keyword.cost_per_click" ? direction : null}
                onClick={this.handleSort("keyword.cost_per_click")}
              >
                Cost Per Click
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "seo_value" ? direction : null}
                onClick={this.handleSort("seo_value")}
              >
                SEO Value
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "business_value" ? direction : null}
                onClick={this.handleSort("business_value")}
              >
                Business Value
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === "planning_months" ? direction : null}
                onClick={this.handleSort("planning_months")}
              >
                Last Month Used
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data &&
              this.handleTableFilter(data).map(d => (
                <Table.Row
                  onClick={() => this.props.handleSelect(d)}
                  key={d.id}
                >
                  <Table.Cell>
                    {d.keyword.parent_keyword && d.keyword.parent_keyword.name}
                  </Table.Cell>
                  <Table.Cell>{d.keyword.name}</Table.Cell>
                  <Table.Cell>{d.keyword.difficulty}</Table.Cell>
                  <Table.Cell>{d.keyword.volume}</Table.Cell>
                  <Table.Cell>
                    {d.keyword.cost_per_click
                      ? `$${d.keyword.cost_per_click}`
                      : "-"}
                  </Table.Cell>
                  <Table.Cell>{d.seo_value}</Table.Cell>
                  <Table.Cell>{d.business_value}</Table.Cell>
                  <Table.Cell>
                    {d.planning_months && d.planning_months[0]
                      ? this.findAndFormatMonth(
                          d.planning_months[d.planning_months.length - 1]
                        )
                      : "-"}
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}
