import * as actionTypes from '../actions/actionTypes';
import axios from '../../axios-portal';

export const setMenuItems = (menuItems, route) => {
	return {
		type: actionTypes.SET_MENU_ITEMS,
		menuItems: menuItems.filter((item) => item.permission !== undefined && item.permission !== false),
		route: route
	};
};

export const startFetchUIComponents = () => {
	return {
		type: actionTypes.START_FETCH_UI_COMPONENTS
	};
};

export const setUIComponents = (components) => {
	return {
		type: actionTypes.SET_UI_COMPONENTS,
		components: components
	};
};

export const fetchUIComponentsFailure = (errors) => {
	return {
		type: actionTypes.FETCH_UI_COMPONENTS_FAILURE,
		errors: errors
	};
};

export const fetchUIComponents = () => {
	return async (dispatch, getState) => {
		let isLoading = await getState().ui.components.isLoading;
		dispatch(startFetchUIComponents());
		!isLoading &&
			axios
				.get(`ui-components.json`)
				.then((response) => {
					return dispatch(setUIComponents(response.data));
				})
				.catch((err) => {
					dispatch(fetchUIComponentsFailure(err));
				});
	};
};
