import React, { Component } from 'react';
import './ReportPresentationCreate.css';
import { connect } from 'react-redux';
import { presentations } from '../../../../store/actions';
import PresentationCreator from '../../../../containers/PresentationCreator/PresentationCreator';
import ReportSettings from '../../ReportSettings/ReportSettings';
import ReportEntries from '../../ReportEntries/ReportEntries';

class ReportPresentationCreate extends Component {
	state = {
		organization: null
	};

	componentDidMount() {
		!this.props.isMasterTemplate &&
			this.props.organizations &&
			this.props.organizations[0] &&
			this.setOrganization();
	}

	componentDidUpdate(prevProps, prevState) {
		if (!this.props.isMasterTemplate) {
			if (this.props.match.params.orgId !== prevProps.match.params.orgId) {
				this.setOrganization();
			}
			if (this.props.isAddingSlideDeckTemplate === true && prevProps.isAddingSlideDeckTemplate === false) {
				this.setOrganization();
			}
		}

		if (this.props.slideDeckTemplates[0]) {
			if (this.state.organization) {
				const selectedSlideDeckTemplate = this.props.slideDeckTemplates.find(
					(slideDeckTemplate) => slideDeckTemplate.id === this.state.organization.monthly_report_template
				);
				if (selectedSlideDeckTemplate && this.props.creating) {
					this.props.history.push(`/reporting/presentation-templates/update/${this.state.organization.id}`);
				}
				if (!selectedSlideDeckTemplate && this.props.updating) {
					this.props.history.push(`/reporting/presentation-templates/create/${this.state.organization.id}`);
				}
			} else if (this.props.isMasterTemplate) {
				const selectedSlideDeckTemplate = this.props.organizationContentObject && this.props.slideDeckTemplates.find(
					(slideDeckTemplate) =>
						slideDeckTemplate.is_master_template &&
						slideDeckTemplate.content_type === this.props.organizationContentObject.id
				);
				if (selectedSlideDeckTemplate && this.props.creating) {
					this.props.history.push(`/reporting/presentation-templates/update/master`);
				}
				if (!selectedSlideDeckTemplate && this.props.updating) {
					this.props.history.push(`/reporting/presentation-templates/create/master`);
				}
			}
		}
	}

	setOrganization = async () => {
		const organization = await this.props.organizations.find(
			(org) => org.id === Number(this.props.match.params.orgId)
		);
		this.setState({
			organization: organization
		});
	};

	handleCancel = () => {
		this.props.history.push(`/reporting/presentation-templates`);
	};

	render() {
		const selectedSlideDeckTemplate =
			this.props.slideDeckTemplates[0] && !this.props.isMasterTemplate
				? this.state.organization &&
					this.props.slideDeckTemplates.find(
						(slideDeckTemplate) => slideDeckTemplate.id === this.state.organization.monthly_report_template
					)
				: this.props.organizationContentObject &&
					this.props.slideDeckTemplates.find(
						(slideDeckTemplate) =>
							slideDeckTemplate.is_master_template &&
							slideDeckTemplate.content_type === this.props.organizationContentObject.id
					);

		return (
			<div className="ReportPresentationCreate">
				<section className="ReportPresentationCreate--creator">
					<PresentationCreator
						{...this.props}
						isMasterTemplate={this.props.isMasterTemplate}
						masterTemplateContentObject={this.props.organizationContentObject}
						defaultName={`${this.props.isMasterTemplate
							? 'Master'
							: this.state.organization && this.state.organization.dba_name} Monthly Report Template`}
						derivedKeys={{ organization: this.state.organization && this.state.organization.id }}
						cancelCreation={this.handleCancel}
						presentation={selectedSlideDeckTemplate}
						updating={this.props.updating}
						creating={this.props.creating}
						primaryDerivedKey={this.state.organization && this.state.organization.id}
						primarySettingsObject={this.state.organization}
						settingsComponent={ReportSettings}
						correspondingContentType="MonthlyReport"
						entriesComponent={ReportEntries}
						entries={this.props.reports.filter(
							(report) => report.organization === Number(this.props.match.params.orgId)
						)}
					/>
				</section>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		isAddingSlideDeckTemplate: state.presentations.slideDeckTemplates.isAdding,
		newSlideDeckTemplate: state.presentations.slideDeckTemplates.newSlideDeckTemplate,
		slideDeckPresentationErrors: state.presentations.slideDeckTemplates.errors
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchSlideDeckTemplates: () => dispatch(presentations.fetchSlideDeckTemplates())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportPresentationCreate);
