import React, { Component } from "react";
import { connect } from "react-redux";
import { ui } from "../../store/actions";
import { Route, Switch } from "react-router-dom";
import "./ClientOnboarding.css";
import Navbar from "../../components/Navbar/Navbar";
import ClientOnboardingDashboard from "./ClientOnboardingDashboard/ClientOnboardingDashboard";
import WebsiteBuilds from "./WebsiteBuilds/WebsiteBuilds";
import OnboadingChecklists from "./OnboadingChecklists/OnboadingChecklists";
import {hasPermissions} from "../../utility";

class ClientOnboarding extends Component {
  state = {
    menuItems: [
      {
        id: 1,
        path: `${this.props.match.path}/onboarding-checklists`,
        exact: true,
        navbarName: "Onboarding Checklists",
        menuLabel: "Onboarding Checklists",
        icon: null,
        component: null,
        protected: true,
        permission: hasPermissions(this.props.user, "update_checklisttemplate")
      },
      {
        id: 2,
        path: `${this.props.match.path}/website-builds`,
        navbarName: "Website Builds",
        menuLabel: "Website Builds",
        icon: null,
        component: null,
        protected: true,
        permission: hasPermissions(this.props.user, "view_websitebuild")
      }
    ]
  }
  componentDidMount() {
    this.props.setMenuItems(this.state.menuItems, "client-onboarding")
  }
  render() {
    return (
      <div className="RouteContainer">
        <Navbar
          noMenuPrefix
          matchPath={this.props.matchPath}
          menuItems={this.state.menuItems}
          routeHeader="Client Onboarding"
        />
        <section className="ClientOnboarding">
          <Switch>
                <Route exact path="/client-onboarding" render={props => <ClientOnboardingDashboard {...props} />} />
                <Route path={`${this.props.match.path}/onboarding-checklists`} render={props => <OnboadingChecklists {...props} />} />
                <Route path={`${this.props.match.path}/website-builds`} render={props => <WebsiteBuilds {...props} />} />
          </Switch>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    messages: state.clientOnboarding.messages,
    errors: state.clientOnboarding.errors,
    user: state.auth.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setMenuItems: (items, route) => dispatch(ui.setMenuItems(items, route))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientOnboarding);
