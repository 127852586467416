import * as actionTypes from '../actions/actionTypes';
import axios from '../../axios-portal';

export const addMessage = (message) => {
	return {
		type: actionTypes.ADD_MESSAGE,
		message: message
	};
};

export const removeMessage = (message, timeToWait) => {
	return {
		type: actionTypes.REMOVE_MESSAGE,
		message: message,
		timeToWait: timeToWait
	};
};

export const setUsers = (users, internal) => {
	return {
		type: actionTypes.SET_USERS,
		users: users,
		internal: internal
	};
};

export const startUsersFetch = () => {
	return {
		type: actionTypes.START_USERS_FETCH
	};
};

export const fetchUsersFailed = (errors) => {
	return {
		type: actionTypes.FETCH_USERS_FAILED,
		errors: errors
	};
};

export const startInviteUser = () => {
	return {
		type: actionTypes.START_INVITE_USER,
	}
}

export const inviteUserSuccess = (user, key, created) => {
	return {
		type: actionTypes.INVITE_USER_SUCCESS,
		user: user,
		key: key,
		created: created
	};
};

export const updateCurrentUserSuccess = (user, id) => {
	return {
		type: actionTypes.UPDATE_CURRENT_USER_SUCCESS,
		user: user,
		id: id
	};
};

export const updateUserSuccess = (user, id) => {
	return {
		type: actionTypes.UPDATE_USER_SUCCESS,
		user: user,
		id: id
	};
};

export const updateCurrentUserPreferencesSuccess = (userPreferences, id, userIndex) => {
	return {
		type: actionTypes.UPDATE_CURRENT_USER_PREFERENCES_SUCCESS,
		userPreferences: userPreferences,
		id: id,
		userIndex: userIndex,
	};
};

export const updateUserPreferencesSuccess = (userPreferences, id, userIndex) => {
	return {
		type: actionTypes.UPDATE_USER_PREFERENCES_SUCCESS,
		userPreferences: userPreferences,
		id: id,
		userIndex: userIndex,
	};
};

export const deleteUserInProgress = (userId) => {
	return {
		type: actionTypes.DELETE_USER_IN_PROGRESS,
		userId: userId,
	};
};

export const deleteUserSuccess = (userId, resp) => {
	return {
		type: actionTypes.DELETE_USER_SUCCESS,
		userId: userId,
		resp: resp
	};
};


export const setInvitations = (invitations) => {
	return {
		type: actionTypes.SET_INVITATIONS,
		invitations: invitations
	};
};

export const startInvitationsFetch = () => {
	return {
		type: actionTypes.START_INVITATIONS_FETCH
	};
};

export const userFailure = (errors) => {
	return {
		type: actionTypes.USER_FAILURE,
		errors: errors
	};
};

export const fetchUsers = (internal, organization) => {
	return (dispatch, getState) => {
		if (!getState().users.isLoading) {
			dispatch(startUsersFetch());
			axios
				.get(`users${internal || organization ? internal ? '/?internal=true' : `/?org=${organization}` : '.json'}`)
				.then((response) => {
					return dispatch(setUsers(response.data, internal));
				})
				.catch((err) => {
					dispatch(fetchUsersFailed(err));
				});
		}
	};
};

export const inviteUser = (formData) => {
	const price = parseFloat(formData.price);
	return (dispatch, getState) => {
		dispatch(startInviteUser())
		let body = JSON.stringify({
			email: formData.email,
			organization: formData.organization,
			role: formData.role,
			is_writer: formData.isWriter,
			price: price,
			first_name: formData.firstName,
			last_name: formData.lastName,
			title: formData.title,
			phone_number: formData.phoneNumber,
			receive_reporting_email: formData.receive_reporting_email,
			receive_approval_reminders: formData.receive_approval_reminders,
			send_invite: formData.sendInvite,
			create_user: formData.createUser
		});
		let message = formData.sendInvite ? 'User Invited!' : 'User Registered!';
		return axios
			.post('invitations/create-and-send/', body)
			.then((response) => {
				dispatch(fetchInvitations());
				dispatch(addMessage(message));
				setTimeout(() => dispatch(removeMessage(message)), 2500);
				return dispatch(inviteUserSuccess(response.data, null, formData.createUser ? true : false));
			})
			.catch((err) => {
				dispatch(userFailure(err));
			});
	};
};

export const reinviteUser = (email, organization, role, key) => {
	return (dispatch, getState) => {
		let body = JSON.stringify({
			email: email,
			organization: organization.id,
			role: role.id,
			key: key
		});
		let message = 'User Reinvited!';
		return axios
			.post('invitations/create-and-send/', body)
			.then((response) => {
				dispatch(fetchInvitations());
				dispatch(addMessage(message));
				setTimeout(() => dispatch(removeMessage(message)), 2500);
				return dispatch(inviteUserSuccess(response.data, key));
			})
			.catch((err) => {
				dispatch(userFailure(err.response.data));
			});
	};
};

export const updateUser = (id, data, message) => {
	return (dispatch, getState) => {
		const isSelf = getState().auth.user.id === id;

		let body = JSON.stringify(data);

		return axios
			.patch(`users/${id}/`, body)
			.then((response) => {
				if (message) {
					dispatch(addMessage(message));
					setTimeout(() => dispatch(removeMessage(message)), 2000);
				}

				return isSelf
					? dispatch(updateCurrentUserSuccess(response.data, id))
					: dispatch(updateUserSuccess(response.data, id));
			})
			.catch((err) => {
				dispatch(userFailure(err.response.data));
			});
	};
};

export const updateUserPreferences = (id, data, message) => {
	return (dispatch, getState) => {
		const isSelf = getState().auth.user.id === id;
		const userIndex = getState().users.users.findIndex((x) => x.id === id);
		let body = JSON.stringify(data);
		let sucessMessage = message ? message : 'Preferences Updated';
		return axios
			.put(`user-preferences/${id}/`, body)
			.then((response) => {
				dispatch(addMessage(sucessMessage));
				setTimeout(() => dispatch(removeMessage(sucessMessage)), 2000);
				return isSelf
					? dispatch(updateCurrentUserPreferencesSuccess(response.data, id, userIndex))
					: dispatch(updateUserPreferencesSuccess(response.data, id, userIndex));
			})
			.catch((err) => {
				dispatch(userFailure(err.response.data));
			});
	};
};

// export const updateUser = (id, firstName, lastName, title, phoneNumber, active) => {
//   return (dispatch, getState) => {
//     let body = JSON.stringify({
//       first_name: firstName,
//       last_name: lastName,
//       title: title,
//       phone_number: phoneNumber,
//       active: active,
//      });

//     return axios
//       .put(`users/${id}/`, body)
//       .then(response => {
//         return dispatch(updateUserSuccess(response.data, id));
//       })
//       .catch(err => {
//         dispatch(userFailure(err.response.data));
//       });
//   };
// };

export const deleteUser = (userId) => {
	return async (dispatch, getState) => {
		dispatch(deleteUserInProgress(userId));
		let body = JSON.stringify({
			is_active: false,
		});
		return await axios
			.patch(`users/${userId}/`, body)
			.then((response) => {
				dispatch(deleteUserSuccess(userId, response));
			})
			.catch((err) => {
				dispatch(userFailure(err));
			});
	};
};

export const startFetchCurrentAssignments = () => {
	return {
		type: actionTypes.START_FETCH_CURRENT_ASSIGNMENTS,
	}
}

export const fetchCurrentAssignmentsSuccess = (assignments) => {
	return {
		type: actionTypes.FETCH_CURRENT_ASSIGNMENTS_SUCCESS,
		assignments:assignments
	}
}

export const fetchCurrentAssignmentsFailure = (errors) => {
	return {
		type: actionTypes.FETCH_CURRENT_ASSIGNMENTS_FAILURE,
		errors:errors
	}
}

export const fetchCurrentAssignments = (countOnly) => {
	return (dispatch, getState) => {
		const userId = getState().auth.user.id
		if (userId) {
			dispatch(startFetchCurrentAssignments);
			axios
				.get(`users/${userId}/get_current_assignments${countOnly ? '/?countOnly=True' : '.json'}`)
				.then((response) => {
					return dispatch(fetchCurrentAssignmentsSuccess(response.data));
				})
				.catch((err) => {
					dispatch(fetchCurrentAssignmentsFailure(err));
				});
		}
	}
}

// Current Late Assignments
export const startFetchLateAssignments = () => {
	return {
		type: actionTypes.START_FETCH_LATE_ASSIGNMENTS,
	}
}

export const fetchLateAssignmentsSuccess = (assignments) => {
	return {
		type: actionTypes.FETCH_LATE_ASSIGNMENTS_SUCCESS,
		assignments:assignments
	}
}

export const fetchLateAssignmentsFailure = (errors) => {
	return {
		type: actionTypes.FETCH_LATE_ASSIGNMENTS_FAILURE,
		errors:errors
	}
}

export const fetchLateAssignments = (countOnly) => {
	return (dispatch, getState) => {
		const userId = getState().auth.user.id
		if (userId) {
			dispatch(startFetchLateAssignments);
			axios
				.get(`users/${userId}/get_late_assignments${countOnly ? '/?countOnly=True' : '.json'}`)
				.then((response) => {
					return dispatch(fetchLateAssignmentsSuccess(response.data));
				})
				.catch((err) => {
					dispatch(fetchLateAssignmentsFailure(err));
				});
		}
	}
}

// Current Due Soon Assignments

export const startFetchDueSoonAssignments = () => {
	return {
		type: actionTypes.START_FETCH_DUE_SOON_ASSIGNMENTS,
	}
}

export const fetchDueSoonAssignmentsSuccess = (assignments) => {
	return {
		type: actionTypes.FETCH_DUE_SOON_ASSIGNMENTS_SUCCESS,
		assignments:assignments
	}
}

export const fetchDueSoonAssignmentsFailure = (errors) => {
	return {
		type: actionTypes.FETCH_DUE_SOON_ASSIGNMENTS_FAILURE,
		errors:errors
	}
}

export const fetchDueSoonAssignments = (countOnly) => {
	return (dispatch, getState) => {
		const userId = getState().auth.user.id
		if (userId) {
			dispatch(startFetchDueSoonAssignments);
			axios
				.get(`users/${userId}/get_due_soon_assignments${countOnly ? '/?countOnly=True' : '.json'}`)
				.then((response) => {
					return dispatch(fetchDueSoonAssignmentsSuccess(response.data));
				})
				.catch((err) => {
					dispatch(fetchDueSoonAssignmentsFailure(err));
				});
		}
	}
}

export const fetchInvitations = () => {
	return (dispatch) => {
		dispatch(startInvitationsFetch());
		axios
			.get('invitations.json')
			.then((response) => {
				return dispatch(setInvitations(response.data));
			})
			.catch((err) => {
				dispatch(userFailure(err));
			});
	};
};

// Accounts

export const startFetchAccounts = () => {
	return {
		type: actionTypes.START_FETCH_ACCOUNTS
	};
};

export const setAccounts = (accounts) => {
	return {
		type: actionTypes.SET_ACCOUNTS,
		accounts: accounts
	};
};

export const fetchAccountsFailure = (errors) => {
	return {
		type: actionTypes.FETCH_ACCOUNTS_FAILURE,
		errors: errors
	};
};

export const addAccountSuccess = (account, userIndex) => {
	return {
		type: actionTypes.ADD_ACCOUNT_SUCCESS,
		account: account,
		userIndex: userIndex,
	};
};

export const addAccountFailure = (errors) => {
	return {
		type: actionTypes.ADD_ACCOUNT_FAILURE,
		errors: errors
	};
};

export const startUpdateAccount = (id, account, accountIndex, userIndex) => {
	return {
		type: actionTypes.START_UPDATE_ACCOUNT,
		id: id,
		account: account,
		accountIndex: accountIndex,
		userIndex: userIndex,
	}
}

export const updateAccountSuccess = (id, account, userIndex, accountIndex) => {
	return {
		type: actionTypes.UPDATE_ACCOUNT_SUCCESS,
		id: id,
		account: account,
		userIndex: userIndex,
		accountIndex: accountIndex,
	};
};

export const updateAccountFailure = (id, errors) => {
	return {
		type: actionTypes.UPDATE_ACCOUNT_FAILRE,
		id: id,
		errors: errors
	};
};

export const deleteAccountSuccess = (id) => {
	return {
		type: actionTypes.DELETE_ACCOUNT_SUCCESS,
		id: id
	};
};

export const deleteAccountFailure = (id, errors) => {
	return {
		type: actionTypes.DELETE_ACCOUNT_FAILURE,
		id: id,
		errors: errors
	};
};

export const fetchAccounts = () => {
	return (dispatch) => {
		dispatch(startFetchAccounts());
		axios
			.get('roles.json')
			.then((response) => {
				return dispatch(setAccounts(response.data));
			})
			.catch((err) => {
				dispatch(fetchAccountsFailure(err));
			});
	};
};

export const addAccount = (data, message) => {
	return (dispatch, getState) => {
		const userIndex = getState().users.users.findIndex(user => user.id === data.user);
		let body = JSON.stringify({
			user: data.user,
			organization: data.organization,
			role: data.role,
			receive_reporting_email: data.receive_reporting_email,
			receive_approval_reminders: data.receive_approval_reminders
		});
		axios
			.post(`roles.json`, body)
			.then((response) => {
				if (message) {
					dispatch(addMessage(message));
					setTimeout(() => dispatch(removeMessage(message)), 2500);
				}
				return dispatch(addAccountSuccess(response.data, userIndex));
			})
			.catch((err) => {
				dispatch(addAccountFailure(err));
			});
	};
};

export const updateAccount = (id, data, message) => {
	return (dispatch, getState) => {
		const userIndex = getState().users.users.findIndex(user => user.id === data.user);
		const foundAccount = getState().users.users
		.find((user) => user.id === data.user)
		.org_roles.find((role) => role.id === data.id);
		const accountIndex = getState().users.users
		.find((user) => user.id === data.user)
		.org_roles.findIndex((role) => role.id === data.id);
		dispatch(startUpdateAccount(id, foundAccount, accountIndex, userIndex));
		let body = JSON.stringify({
			user: data.user,
			organization: data.organization,
			role: data.role,
			receive_reporting_email: data.receive_reporting_email,
			receive_approval_reminders: data.receive_approval_reminders
		});
		axios
			.put(`roles/${id}/`, body)
			.then((response) => {
				if (message) {
					dispatch(addMessage(message));
					setTimeout(() => dispatch(removeMessage(message)), 2500);
				}
				return dispatch(updateAccountSuccess(id, response.data, userIndex, accountIndex));
			})
			.catch((err) => {
				dispatch(updateAccountFailure(id, err));
			});
	};
};

export const deleteAccount = (id, message) => {
	return (dispatch) => {
		return axios
			.delete(`roles/${id}/`)
			.then((response) => {
				if (message) {
					dispatch(addMessage(message));
					setTimeout(() => dispatch(removeMessage(message)), 2500);
				}
				return dispatch(deleteAccountSuccess(id));
			})
			.catch((err) => {
				dispatch(deleteAccountFailure(id, err));
			});
	};
};

// User Password Change NOTE: We should not implement this later down the line
export const startUserPasswordChange = (id, userIndex) => {
	return {
		type: actionTypes.START_USER_PASSWORD_CHANGE,
		id: id,
		userIndex: userIndex,
	}
}

export const userPasswordChangeSuccess = (id, userIndex) => {
	return {
		type: actionTypes.USER_PASSWORD_CHANGE_SUCCESS,
		id: id,
		userIndex: userIndex,
	}
}

export const userPasswordChangeFailure = (id, errors) => {
	return {
		type: actionTypes.USER_PASSWORD_CHANGE_FAILURE,
		id: id,
		errors: errors,
	}
}

export const changeUserPassword = (id, password1, password2, message) => {
	return (dispatch, getState) => {
		const userIndex = getState().users.users.findIndex(user => user.id === id);
		dispatch(startUserPasswordChange(id, userIndex))
		let body = JSON.stringify({
			user_id: id,
			password1: password1,
			password2: password2,
		});
		axios
			.post(`change-user-password/`, body)
			.then((response) => {
				if (message) {
					dispatch(addMessage(message));
					setTimeout(() => dispatch(removeMessage(message)), 2500);
				}
				return dispatch(userPasswordChangeSuccess(id, userIndex));
			})
			.catch((err) => {
				dispatch(userPasswordChangeFailure(id, err));
			});
	};
};