import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reporting, presentations } from '../../../store/actions';
import { Button, Dropdown, Icon } from 'semantic-ui-react';
import './ReportingAdminBar.css';

export class ReportingAdminBar extends Component {
	state = {
		isPerformingAction: false
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.props.planningMonth) {
			if (prevProps.planningMonth !== this.props.planningMonth) {
				if (
					this.props.planningMonth.isCreatingReports ||
					this.props.planningMonth.isValidatingReports ||
					this.props.planningMonth.isCreatingReportPresentations ||
					this.props.planningMonth.isQueryingUnsentReports
				) {
					this.setState({
						isPerformingAction: true
					});
				} else {
					this.setState({
						isPerformingAction: false
					});
				}

				if (
					!this.props.planningMonth.isCreatingReportPresentations &&
					!prevProps.planningMonth.createdReportPresentations &&
					this.props.planningMonth.createdReportPresentations
				) {
					this.props.fetchSlideDeckTemplates();
				}
			}
		}
	}

	handleCreateMonthlyReportPresentations = (monthId) => {
		this.props.createMonthlyReportPresentations(monthId);
	};

	render() {
		return (
			<div className="ReportingAdminBar">
				<Button
					onClick={() => this.props.createMonthlyReports(this.props.planningMonth.id)}
					loading={this.state.isPerformingAction}
					disabled={this.state.isPerformingAction}
					color="blue"
					size="tiny"
					basic
				>
					Create Monthly Reports
				</Button>
				<Dropdown
					id="ReportingAdminBarReportDataValidate"
					className="tiny blue basic"
					button
					floating
					text="Validate Report Data"
					loading={this.state.isPerformingAction}
					disabled={this.state.isPerformingAction}
				>
					<Dropdown.Menu>
						<Dropdown.Item
							name="runMode"
							onClick={() => this.props.validateMonthlyReportData(this.props.planningMonth.id)}
						>
							<div className="DropdownItemContainer">
								<Icon color="blue" name="play circle outline" />
								<span>Run</span>
							</div>
						</Dropdown.Item>
						<Dropdown.Item
							name="debugMode"
							onClick={() => this.props.validateMonthlyReportData(this.props.planningMonth.id, true)}
						>
							<div className="DropdownItemContainer">
								<Icon color="teal" name="bug" />
								<span>Debug Mode</span>
							</div>
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
				<Button
					onClick={() => this.handleCreateMonthlyReportPresentations(this.props.planningMonth.id)}
					loading={this.state.isPerformingAction}
					disabled={this.state.isPerformingAction}
					color="blue"
					size="tiny"
					basic
				>
					Create Report Presentations
				</Button>
				<Dropdown
					className="tiny blue basic"
					button
					floating
					text="Check Unsent Reports"
					loading={this.state.isPerformingAction}
					disabled={this.state.isPerformingAction}
				>
					<Dropdown.Menu>
						<Dropdown.Item onClick={() => this.props.queryUnsentReports(this.props.planningMonth.id)}>
							<div className="DropdownItemContainer">
								<Icon color="blue" name="play circle outline" />
								<span>Run</span>
							</div>
						</Dropdown.Item>
						<Dropdown.Item onClick={() => this.props.queryUnsentReports(this.props.planningMonth.id)}>
							<div className="DropdownItemContainer">
								<Icon color="teal" name="bug" />
								<span>Debug Mode</span>
							</div>
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		planningMonths: state.contentGeneration.planningMonths
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		createMonthlyReports: (monthId) => dispatch(reporting.createMonthlyReports(monthId)),
		validateMonthlyReportData: (monthId, debugMode) =>
			dispatch(reporting.validateMonthlyReportData(monthId, debugMode)),
		createMonthlyReportPresentations: (monthId) => dispatch(reporting.createMonthlyReportPresentations(monthId)),
		queryUnsentReports: (monthId) => dispatch(reporting.queryUnsentReports(monthId)),
		fetchSlideDeckTemplates: () => dispatch(presentations.fetchSlideDeckTemplates())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportingAdminBar);
