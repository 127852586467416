export const colorOptions = [
	'#2eb67d', // light green
	'#3a4ba6', // dark blue
	'#d6d61e', // yellow
	'#ff3c1a', // tomato
	'#029ce3', // light blue
];

export const alternateColorOptions = [
	'#0088FE', // light blue
	'#82CA9D', // mint green
	'#8884D8', // light purple
	'#FF7F42', // pumpkin orange
	'#FFBB28', // bright yellow
	'#413FA0', // deep purple
	'#A4DE6C', // lime green
]