import React, {Component } from "react";
import { Dropdown } from "semantic-ui-react";
import { contentGeneration } from "../../store/actions";
import { connect } from "react-redux";

class ContentProjectSelect extends Component {

  handleSelect = (event, { value }) => {
    let url = `/content-generation/projects/${value}`;
    this.props.history.push(url);
  };

  render() {
    return (
      <div>
        {this.props.contentProjects[0] && (
          <Dropdown
            selectOnBlur={false}
            selection
            labeled
            button
            loading={this.props.isLoading ? true : false}
            className="icon"
            icon={this.props.icon ? this.props.icon : 'calendar'}
            size="tiny"
            placeholder={this.props.placeholder}
            options={this.props.contentProjects.filter(project => !project.complete).map(project => ({
              key: project.id,
              text: project.name,
              value: project.id
            }))}
            onChange={this.handleSelect}
          />
        )}
      </div>
    );
  }
};

const mapStateToProps = state => {
  return {
    contentProjects: state.contentGeneration.contentProjects,
    isAddingProject: state.contentGeneration.isAddingProject,
    isLoading: state.contentGeneration.isLoadingArticles,
    addedProject: state.contentGeneration.addedProject,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchContentProjects: () => {
      return dispatch(contentGeneration.fetchContentProjects());
    },
    addContentProject: formData => {
      return dispatch(contentGeneration.addContentProject(formData));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentProjectSelect);
