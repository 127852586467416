import React from 'react';
import { Message } from 'semantic-ui-react';
import './ErrorHandler.css';

const ErrorHandler = (props) => {
	// if (props.errors) {
	//   console.log(props.errors)
	//   let errors = Object.values(props.errors);
	//   if (errors.length > 0) {
	//     errors.map(error => (
	//       console.log(error.toString())
	//     ))
	//   }
	//   console.log(Object.values(props.errors).length)
	// }

	const tryWithoutBreak = (func) => {
		try {
			const attemptedFunction = func;
			return attemptedFunction;
		} catch (error) {
			console.error('Function unsuccessful: ', error);
			return null;
		}
	};

	const parseError = (value) => {
		let output = null;
		try {
			output = value.toString();
		} catch (error) {
			output = `Error parse unsuccessful: ${error}`;
			console.log(output);
		}

		return output;
	};

	const errors = props.errors;
	// errors && console.log('errors are: ', errors);
	return (
		<div className="errorHandler">
			{props.errors && tryWithoutBreak(props.errors.length > 0) ? tryWithoutBreak(
				typeof props.errors === 'object'
			) ? (
				props.errors.map((error) => (
					<Message
						id={`fieldError-${error.field}`}
						key={error.field}
						negative
						size={props.size ? props.size : 'small'}
					>
						<Message.Header>{error.message}</Message.Header>
					</Message>
				))
			) : props.errors.field ? (
				<Message
					id={`fieldError-${props.errors.field}`}
					key={props.errors.field}
					negative
					size={props.size ? props.size : 'small'}
				>
					<Message.Header>{props.errors.message}</Message.Header>
				</Message>
			) : (
				<Message id={`fieldError-generic`} key={props.errors} negative size={props.size ? props.size : 'small'}>
					<Message.Header>{props.errors}</Message.Header>
				</Message>
			) : (
				<div>
					{props.errors.response && props.errors.response.data ? (
						<Message
							id={`singleErrorResponse-${errors.response.status}`}
							key={errors.response.status}
							negative
							size={props.size ? props.size : 'small'}
						>
							<Message.Header>{errors.response.statusText}</Message.Header>
							{errors.response.data && errors.response.data.detail ? typeof errors.response.data
								.detail === 'object' ? (
								Object.entries(errors.response.data.detail).map(([ k, v ]) => (
									<p key={k}>
										{k} {v}
									</p>
								))
							) : (
								<p>{errors.response.data.detail}</p>
							) : typeof errors.response.data === 'object' ? (
								tryWithoutBreak(Object.entries(errors.response.data)).map(([ k, v ]) => (
									<p key={k}>
										{k} {v}
									</p>
								))
							) : (
								<p>{errors.response.data}</p>
							)}
						</Message>
					) : (
						Object.entries &&
						tryWithoutBreak(Object.entries(props.errors).length) > 0 &&
						tryWithoutBreak(Object.entries(props.errors)).map(([ key, value ], i) => (
							<Message
								id={`serverError-${key}`}
								key={i}
								negative
								size={props.size ? props.size : 'small'}
							>
								{key === 'non_field_errors' ? (
									<Message.Header>{parseError(value)}</Message.Header>
								) : (
									<React.Fragment>
										<Message.Header>{key}</Message.Header>
										<p>{parseError(value)}</p>
									</React.Fragment>
								)}
							</Message>
						))
					)}
				</div>
			)}
		</div>
	);
};

export default ErrorHandler;
