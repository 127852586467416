import React, { Component } from "react";
import { contentGeneration } from "../../store/actions";
import { connect } from "react-redux";
import "./FeedbackForm.css";
import { Form, Popup, Icon, Segment, List } from "semantic-ui-react";
import StarRating from "../StarRating/StarRating";
import ErrorHandler from "../ErrorHandler/ErrorHandler";

const initialState = {
  satisfaction: "",
  feedbackBody: "",
  approved: null,
};

class FeedbackForm extends Component {
  state = {
    form: initialState,
    validationMessage: {
      code: "",
      message: "",
    },
  };

  componentDidUpdate(prevProps) {
    if (this.props.articleID !== prevProps.articleID) {
      this.clearForm();
    }
  }

  clearForm = () => {
    this.setState({ form: initialState });
  };

  handleChange(e) {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleSelectChange = (event, { name, value }) => {
    this.setState({ form: { ...this.state.form, [name]: value } });
  };

  handleRatingChange = (e, value) => {
    this.setState({
      form: {
        ...this.state.form,
        satisfaction: e.target.value,
      },
    });
  };

  handleApprovedChange = (e) => {
    const isApproved = e.target.value === "true" ? true : false;
    this.setState({
      form: {
        ...this.state.form,
        approved: isApproved,
      },
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    await this.props.addFeedback(this.props.articleID, this.state.form);
    return (
      !this.props.errors &&
      this.props.handleStatusUpdate(this.state.form.approved)
    );
  };

  validateForm = () => {
    if (this.props.satisfaction && !this.state.form.satisfaction) {
      return false;
    }

    if (!this.props.approval === false && this.state.form.approved === null) {
      return false;
    }

    if (!this.state.form.feedbackBody && this.needsFeedback()) {
      return false;
    } else {
      return true;
    }
  };

  needsFeedback = () => {
    if (this.props.feedbackRequired === false) {
      return false;
    } else if (this.props.satisfaction) {
      if (
        this.state.form.satisfaction <= 4 ||
        this.state.form.approved !== true
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (!this.state.form.approved) {
        return true;
      } else {
        return false;
      }
    }
  };

  render() {
    return (
      <div className="FeedbackForm">
        {this.props.errors && <ErrorHandler errors={this.props.errors} />}
        <Form onSubmit={this.handleSubmit} size="tiny">
          {!this.props.approval === false && (
            <React.Fragment>
              <Segment className="FeedbackForm--instructions-container">
              <div className="FeedbackForm--instructions">
                <p className="FeedbackForm--instructions__label">To Submit Feedback:</p>
                <List as="ol" className="FeedbackForm--instructions-list">
                  <List.Item as="li">
                      Select Approve or Reject
                  </List.Item>
                  <List.Item as="li">
                      Provide a rating
                  </List.Item>
                  <List.Item as="li">
                      Provide feedback (required if rating is not 5 stars)
                  </List.Item>
                  <List.Item as="li">
                      Click Submit
                  </List.Item>
                </List>
                </div>
              </Segment>
            <Form.Group inline>
              <Form.Field>
                <label>
                  <Popup
                    size="mini"
                    content="This will submit the copy to 321 Web Marketing to publish onto the website"
                    trigger={<Icon color="grey" name="info circle" />}
                  />
                  Approve
                </label>
                <input
                  onChange={(e) => this.handleApprovedChange(e)}
                  value={true}
                  checked={this.state.form.approved === true}
                  type="radio"
                  name="approved"
                />
              </Form.Field>
              <Form.Field>
                <label>
                  <Popup
                    size="mini"
                    content="This will submit the copy to the writer with your feedback for another round of edits."
                    trigger={<Icon color="grey" name="info circle" />}
                  />
                  Reject
                </label>
                <input
                  onChange={(e) => this.handleApprovedChange(e)}
                  value={false}
                  checked={this.state.form.approved === false}
                  type="radio"
                  name="approved"
                />
              </Form.Field>
            </Form.Group>
            </React.Fragment>
          )}
          {this.props.satisfaction && (
            <Form.Field>
              <label>
                <Popup
                  size="mini"
                  content="Rate your satisfaction with the copy before you made edits based on the following criteria: adherence to the instructions, good English, proper grammar, and whether or not additional edits needed to be made. In general, if the article required negligible edits prior to being submitted to the client, it should be five stars. Please note that you are responsible for the client providing positive feedback and approving the copy after you approve and submit for review."
                  trigger={<Icon color="grey" name="info circle" />}
                />
                Your Rating
              </label>
              <StarRating
                handleChange={this.handleRatingChange}
                currentValue={this.state.form.satisfaction}
              />
            </Form.Field>
          )}
          <Form.Field>
            <label>
              <Popup
                size="mini"
                content="Provide actionable feedback that corresponds with why the satisfaction rating was provided."
                trigger={<Icon color="grey" name="info circle" />}
              />
              Your Feedback
            </label>
            <Form.TextArea
              required={this.needsFeedback()}
              name="feedbackBody"
              value={this.state.form.feedbackBody}
              onChange={(e) => this.handleChange(e)}
              placeholder="Your Feedback"
            />
          </Form.Field>
          <Form.Button
            disabled={!this.validateForm()}
            size="mini"
            type="submit"
            positive
          >
            Submit
          </Form.Button>
          
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    errors: state.contentGeneration.errors,
    messages: state.contentGeneration.messages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchContentStatuses: () => {
      return dispatch(contentGeneration.fetchContentStatuses());
    },
    addFeedback: (articleId, formData) => {
      return dispatch(contentGeneration.addFeedback(articleId, formData));
    },
    updateFeedback: (feedback, feedbackId) => {
      return dispatch(contentGeneration.updateFeedback(feedback, feedbackId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackForm);
