import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { matchPath } from "react-router";
import { contentGeneration, organizations, ui } from "../../store/actions";
import { connect } from "react-redux";
import SingleMonthView from "../../components/SingleMonthView/SingleMonthView";
import ArticleTemplatesView from "../../components/ArticleTemplatesView/ArticleTemplatesView";
import { Grid, Transition, Loader } from "semantic-ui-react";
import moment from "moment";
import ContentArticleForm from "../../components/ContentArticleForm/ContentArticleForm";
import "./ContentGeneration.css";
import PageMessage from "../../components/PageMessage/PageMessage";
import KeywordView from "../../components/KeywordView/KeywordView";
import KeywordDashboard from "../../routes/ContentGeneration/Keywords/KeywordDashboard/KeywordDashboard";
import KeywordForm from "../../components/KeywordForm/KeywordForm";
import Assignments from "../ContentGeneration/Assignments/Assignments";
import Navbar from "../../components/Navbar/Navbar";
import { hasPermissions } from "../../utility";
import PlanningMonthSelect from "../../components/PlanningMonthSelect/PlanningMonthSelect";
import ContentDashboard from "./ContentDashboard/ContentDashboard";
import ArticleSearchBar from "../../components/ArticleSearchBar/ArticleSearchBar";
import ContentProjects from "./ContentProjects/ContentProjects";
import FixedLoader from "../../components/FixedLoader/FixedLoader";
import ContentProjectSelect from "../../components/ContentProjectSelect/ContentProjectSelect";
import BulkContentArticleForm from "../../components/BulkContentArticleForm/BulkContentArticleForm";
import SingleOrganizationView from "./SingleOrganizationView/SingleOrganizationView";
import OrganizationSelect from "../../components/OrganizationSelect/OrganizationSelect";
import ArticleList from "./ArticleList/ArticleList";
import ClientDashboard from "./ClientDashboard/ClientDashboard";
/*eslint eqeqeq: "off"*/

class ContentGeneration extends Component {
  state = {
    selectedArticles: [],
    isAddingContent: false,
    isAddingTemplate: false,
    isAddingKeyword: false,
    articleToEdit: null,
    templateToEdit: null,
    keywordToEdit: null,
    menuItems: [
      {
        id: 1,
        path: `${this.props.match.path}/`,
        exact: true,
        navbarName: "Dashboard",
        menuLabel: "Dashboard",
        icon: "grid layout",
        component: null,
        protected: true,
        permission: hasPermissions(this.props.user, "view_articletemplate"),
      },
      // {
      //   id: 2,
      //   path: `${this.props.match.path}/feedback`,
      //   navbarName: "Feedback",
      //   menuLabel: "Feedback",
      //   icon: "thumbs up",
      //   component: null,
      //   protected: true,
      //   permission: hasPermissions(this.props.user, "add_feedback"),
      // },
      {
        id: 3,
        path: `${this.props.match.path}/templates`,
        navbarName: "Template",
        menuLabel: "Template",
        icon: "list",
        component: null,
        protected: true,
        permission: hasPermissions(this.props.user, "add_articletemplate"),
      },
      {
        id: 4,
        path: null,
        component: <ArticleSearchBar {...this.props} />,
        protected: true,
        permission: hasPermissions(this.props.user, "add_planningyearmonth"),
      },
      {
        id: 5,
        path: null,
        navbarName: "Add Planning Month",
        component: (
          <PlanningMonthSelect
            {...this.props}
            iconSize="small"
            placeholder="Select Month"
          />
        ),
        protected: true,
        permission: hasPermissions(this.props.user, "add_planningyearmonth"),
      },
      {
        id: 6,
        path: null,
        component: (
          <ContentProjectSelect
            {...this.props}
            iconSize="small"
            icon="paperclip"
            placeholder="Select Project"
          />
        ),
        protected: true,
        permission: hasPermissions(this.props.user, "add_contentproject"),
      },
      {
        id: 7,
        path: null,
        component: (
          <OrganizationSelect
            {...this.props}
            iconSize="small"
            icon="building"
            placeholder="Select Organization"
          />
        ),
        protected: true,
        permission: hasPermissions(this.props.user, "add_articletemplate"),
      },
    ],
  };

  componentDidMount() {
    !this.props.planningMonths[0] && this.props.fetchPlanningMonths();
    !this.props.contentProjects[0] && this.props.fetchContentProjects();
    !this.props.organizations[0] && this.props.fetchOrganizations();
    if (
      this.props.planningMonths[0] &&
      !this.props.planningMonths[0].article_count
    ) {
      this.props.fetchPlanningMonths();
    }
    this.props.setMenuItems(this.state.menuItems, "content-generation");
  }

  componentDidUpdate(prevProps) {
    this.props.location.pathname !== prevProps.location.pathname &&
      this.cancelAction();

    if (this.props.contentArticles !== prevProps.contentArticles) {
      if (this.state.articleToEdit) {
        let updatedArticle = this.props.contentArticles.find(
          (article) => article.id === this.state.articleToEdit.id
        );
        this.setState({ articleToEdit: updatedArticle });
      }
    }
  }

  cancelAction = () => {
    this.setState({
      isAddingContent: false,
      articleToEdit: false,
      templateToEdit: null,
      isAddingTemplate: false,
      isAddingKeyword: false,
      keywordToEdit: null,
      selectedArticles: [],
    });
  };

  handleAddingContent = () => {
    this.setState({
      isAddingContent: true,
      isAddingTemplate: false,
      isAddingKeyword: false,
      articleToEdit: null,
      templateToEdit: null,
      keywordToEdit: null,
      selectedArticles: [],
    });
  };

  handleAddingTemplate = () => {
    this.setState({
      isAddingTemplate: true,
      isAddingContent: false,
      isAddingKeyword: false,
      articleToEdit: null,
      templateToEdit: null,
      keywordToEdit: null,
      selectedArticles: [],
    });
  };

  handleAddingKeyword = () => {
    this.setState({
      isAddingTemplate: false,
      isAddingContent: false,
      isAddingKeyword: true,
      articleToEdit: null,
      templateToEdit: null,
      keywordToEdit: null,
      selectedArticles: [],
    });
  };

  handleArticleSelect = (article) => {
    this.setState({
      articleToEdit: article,
      templateToEdit: null,
      keywordToEdit: null,
      isAddingTemplate: false,
      isAddingContent: false,
      isAddingKeyword: false,
      selectedArticles: [],
    });
  };

  handleTemplateSelect = (template) => {
    this.setState({
      templateToEdit: template,
      articleToEdit: null,
      keywordToEdit: null,
      isAddingTemplate: false,
      isAddingContent: false,
      isAddingKeyword: false,
      selectedArticles: [],
    });
  };

  handleKeywordSelect = (keyword) => {
    this.setState({
      keywordToEdit: keyword,
      articleToEdit: null,
      templateToEdit: null,
      isAddingTemplate: false,
      isAddingContent: false,
      isAddingKeyword: false,
      selectedArticles: [],
    });
  };

  handleMultiSelect = (articles, meta, remove) => {
    if (remove) {
      this.setState({
        selectedArticles: meta
          ? this.state.selectedArticles.filter(
              (article) => article.id !== articles.id
            )
          : this.state.selectedArticles.filter((article) =>
              articles.map((a) => a.id).includes(article.id)
            ),
        articleToEdit: null,
        templateToEdit: null,
        keywordToEdit: null,
        isAddingTemplate: false,
        isAddingContent: false,
        isAddingKeyword: false,
      });
    } else {
      this.setState({
        selectedArticles: meta
          ? this.state.selectedArticles.concat(articles)
          : articles,
        articleToEdit: null,
        templateToEdit: null,
        keywordToEdit: null,
        isAddingTemplate: false,
        isAddingContent: false,
        isAddingKeyword: false,
      });
    }
  };

  selectArticles = (e, article, data) => {
    let inBetween = false;
    if (e.shiftKey) {
      const articlesToAdd = [];
      data.forEach((a) => {
        const exists = this.state.selectedArticles.find(
          (article) => article.id === a.id
        );
        if (a.id === article.id || a === this.state.articleToEdit) {
          inBetween = !inBetween;
        }
        if (inBetween && !exists) {
          articlesToAdd.push(a);
        }
      });
      let isInArray = this.state.selectedArticles.find(
        (a) => a.id === article.id
      );
      !isInArray && articlesToAdd.push(article);
      articlesToAdd[0] &&
        this.setState({
          selectedArticles: this.state.selectedArticles.concat(articlesToAdd),
          templateToEdit: null,
          keywordToEdit: null,
          isAddingTemplate: false,
          isAddingContent: false,
          isAddingKeyword: false,
        });
    } else if (e.metaKey) {
      const foundArticle =
        this.state.selectedArticles[0] &&
        this.state.selectedArticles.find((a) => a.id === article.id);
      if (foundArticle) {
        let updatedArticles = this.state.selectedArticles.filter(
          (a) => a.id !== article.id
        );
        return this.setState({
          selectedArticles: updatedArticles,
          templateToEdit: null,
          keywordToEdit: null,
          isAddingTemplate: false,
          isAddingContent: false,
          isAddingKeyword: false,
        });
      } else {
        return this.setState({
          selectedArticles: this.state.selectedArticles.concat(article),
          templateToEdit: null,
          keywordToEdit: null,
          isAddingTemplate: false,
          isAddingContent: false,
          isAddingKeyword: false,
        });
      }
    } else {
      this.setState({
        selectedArticles: [],
        templateToEdit: null,
        keywordToEdit: null,
        isAddingTemplate: false,
        isAddingContent: false,
        isAddingKeyword: false,
      });
    }
    this.setState({
      articleToEdit: article,
      templateToEdit: null,
      keywordToEdit: null,
      isAddingTemplate: false,
      isAddingContent: false,
      isAddingKeyword: false,
    });
  };

  shouldContentFormShow = () => {
    if (
      (this.state.isAddingContent ||
        this.state.isAddingTemplate ||
        this.state.articleToEdit ||
        this.state.templateToEdit) &&
      !this.state.selectedArticles[0]
    ) {
      return true;
    } else {
      return false;
    }
  };

  shouldKeywordFormShow = () => {
    if (this.state.isAddingKeyword || this.state.keywordToEdit) {
      return true;
    } else {
      return false;
    }
  };

  shouldBulkArticleFormShow = () => {
    if (this.state.selectedArticles[0]) {
      return true;
    } else {
      return false;
    }
  };

  shouldSidebarShow = () => {
    if (
      this.state.isAddingContent ||
      this.state.isAddingTemplate ||
      this.state.articleToEdit ||
      this.state.templateToEdit ||
      this.state.keywordToEdit ||
      this.state.isAddingKeyword ||
      (this.state.selectedArticles && this.state.selectedArticles[0])
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleTitle = () => {
    if (this.state.isAddingContent || this.state.isAddingTemplate) {
      return "Add Content";
    } else if (this.state.selectedArticles[0]) {
      return `${this.state.selectedArticles.length} Articles Selected`;
    } else {
      return "Update Content";
    }
  };

  handleArchiveShow = () => {
    if (
      this.state.articleToEdit ||
      this.state.templateToEdit ||
      this.state.keywordToEdit
    ) {
      return true;
    } else {
      return false;
    }
  };

  shouldRouteSidebarShow = (route) => {
    if (this.props.location.pathname === route) {
      return true;
    } else {
      return false;
    }
  };

  findOrg = (orgSlug) => {
    const foundOrganization =
      this.props.organizations &&
      this.props.organizations[0] &&
      this.props.organizations.find((org) => org.slug === orgSlug);
    return foundOrganization ? foundOrganization : null;
  };

  renderLoadingDetails = () => {
    if (this.props.isLoadingOrgs) {
      return "Organizational Data";
    } else if (this.props.isLoadingMonths) {
      return "Monthly Data";
    } else if (this.props.isLoadingProjects) {
      return "Project Data";
    } else {
      return "General Data";
    }
  };

  requiredDataLoaded = () => {
    if (
      this.props.planningMonths &&
      this.props.planningMonths[0] &&
      !this.props.isLoadingMonths &&
      !this.props.isLoadingOrgs &&
      !this.props.isLoadingProjects
    ) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const monthPathParams = matchPath(this.props.location.pathname, {
      path: `/content-generation/articles/:year/:month`,
      exact: true,
      strict: false,
    });
    const projectPathParams = matchPath(this.props.location.pathname, {
      path: `/content-generation/projects/:projectID`,
      exact: true,
      strict: false,
    });
    const organizationArticlesPathParams = matchPath(
      this.props.location.pathname,
      {
        path: `/content-generation/articles/:organizationSlug`,
        exact: true,
        strict: false,
      }
    );
    const organizationPathParams = matchPath(this.props.location.pathname, {
      path: `/content-generation/keywords/:organizationSlug`,
      exact: true,
      strict: false,
    });
    const { articleTemplates, keywords } = this.props;

    return (
      <div className="RouteContainer">
        <Navbar
          noMenuPrefix
          matchPath={this.props.match.path}
          routeHeader={`${
            this.props.location.pathname === "/content-generation/assignments"
              ? this.props.user.default_role &&
                this.props.user.default_role.name === "Customer"
                ? "Requires Attention"
                : "Assignments"
              : "Content Generation"
          }${
            !hasPermissions(this.props.user, "add_contentarticle") &&
            this.props.location.pathname !== "/content-generation/assignments"
              ? " Dashboard"
              : ""
          } `}
          menuItems={this.state.menuItems}
        />
        {!this.requiredDataLoaded() ? (
          <Loader active>Loading {this.renderLoadingDetails()}</Loader>
        ) : (
          <section className="ContentGeneration">
            <Transition.Group animation="slide down" duration={500}>
              {this.props.messages[0] && (
                <PageMessage>{this.props.messages}</PageMessage>
              )}
            </Transition.Group>
            <Grid>
              <Grid.Row>
                <Grid.Column width={this.shouldSidebarShow() ? 11 : 16}>
                  <Switch>
                    <Route
                      exact
                      path={`${this.props.match.path}/assignments`}
                      render={(props) => (
                        <Assignments
                          {...props}
                          isLoading={this.props.isLoadingArticles}
                          isLoaded={this.props.articlesLoaded}
                        />
                      )}
                    />
                    {hasPermissions(this.props.user, "add_contentarticle") ? (
                      <Route
                        exact
                        path={`${this.props.match.path}/`}
                        render={(props) => (
                          <ContentDashboard
                            {...props}
                            handleAddingContent={this.props.handleAddingContent}
                          />
                        )}
                      />
                    ) : (
                      <Route
                        path={`/content-generation/`}
                        render={(props) => <ClientDashboard {...props} />}
                      />
                    )}
                    <Route
                      path={`${this.props.match.path}/templates`}
                      render={(props) => (
                        <ArticleTemplatesView
                          {...props}
                          fetchTemplates={this.props.fetchArticleTemplates}
                          contentData={articleTemplates}
                          isLoading={this.props.isLoadingTemplates}
                          isLoaded={this.props.templatesLoaded}
                          handleAddingTemplate={this.handleAddingTemplate}
                          isAddingTemplate={this.state.isAddingTemplate}
                          planningMonths={this.props.planningMonths}
                          selectTemplate={this.handleTemplateSelect}
                          templateToEdit={this.state.templateToEdit}
                          addedMonth={this.props.addedMonth}
                          isAdding={this.props.isAddingMonth}
                          isAdded={this.props.monthAdded}
                        />
                      )}
                    />
                    <Route
                      path={`${this.props.match.path}/articles/list`}
                      render={(props) => (
                        <ArticleList
                          {...props}
                          isLoadingPlanningMonths={this.props.isLoadingMonths}
                          handleMultiSelect={this.handleMultiSelect}
                          selectedArticles={this.state.selectedArticles}
                          handleAddingContent={this.handleAddingContent}
                          isAddingContent={this.state.isAddingContent}
                          selectArticle={this.selectArticles}
                          multiSelectEnabled
                          articleToEdit={this.state.articleToEdit}
                          planningMonths={this.props.planningMonths}
                        />
                      )}
                    />
                    <Route
                      path={`${this.props.match.path}/articles/:year/:month`}
                      render={(props) => (
                        <SingleMonthView
                          {...props}
                          month={moment(
                            monthPathParams.params.month,
                            "MM"
                          ).format("MMMM")}
                          currentPlanningMonth={
                            monthPathParams &&
                            this.props.planningMonths[0] &&
                            this.props.planningMonths.find(
                              (month) =>
                                monthPathParams.params.month == month.month &&
                                monthPathParams.params.year == month.year
                            )
                          }
                          isLoadingPlanningMonths={this.props.isLoadingMonths}
                          handleMultiSelect={this.handleMultiSelect}
                          selectedArticles={this.state.selectedArticles}
                          year={monthPathParams.params.year}
                          monthInt={monthPathParams.params.month}
                          isLoading={this.props.isLoadingArticles}
                          isLoaded={this.props.articlesLoaded}
                          handleAddingContent={this.handleAddingContent}
                          isAddingContent={this.state.isAddingContent}
                          // selectArticle={this.handleArticleSelect}
                          selectArticle={this.selectArticles}
                          multiSelectEnabled
                          articleToEdit={this.state.articleToEdit}
                          planningMonths={this.props.planningMonths}
                        />
                      )}
                    />
                    <Route
                      path={`${this.props.match.path}/articles/:organizationSlug`}
                      render={(props) => (
                        <SingleOrganizationView
                          {...props}
                          organization={
                            organizationArticlesPathParams &&
                            this.props.organizations[0] &&
                            this.props.organizations.find(
                              (org) =>
                                organizationArticlesPathParams.params
                                  .organizationSlug == org.slug
                            )
                          }
                          handleMultiSelect={this.handleMultiSelect}
                          selectedArticles={this.state.selectedArticles}
                          isLoading={this.props.isLoadingArticles}
                          isLoaded={this.props.articlesLoaded}
                          handleAddingContent={this.handleAddingContent}
                          isAddingContent={this.state.isAddingContent}
                          selectArticle={this.handleArticleSelect}
                          articleToEdit={this.state.articleToEdit}
                          planningMonths={this.props.planningMonths}
                        />
                      )}
                    />
                    <Route
                      path={`${this.props.match.path}/projects/:projectID`}
                      render={(props) =>
                        this.props.contentProjects[0] ? (
                          <ContentProjects
                            {...props}
                            project={
                              projectPathParams &&
                              this.props.contentProjects[0] &&
                              this.props.contentProjects.find(
                                (project) =>
                                  project.id ==
                                  projectPathParams.params.projectID
                              )
                            }
                            isLoading={this.props.isLoadingArticles}
                            isLoaded={this.props.articlesLoaded}
                            handleAddingContent={this.handleAddingContent}
                            isAddingContent={this.state.isAddingContent}
                            selectArticle={this.handleArticleSelect}
                            articleToEdit={this.state.articleToEdit}
                          />
                        ) : (
                          <FixedLoader />
                        )
                      }
                    />
                    <Route
                      exact
                      path={`${this.props.match.path}/keywords`}
                      render={(props) => (
                        <KeywordDashboard
                          {...props}
                          fetchOrganizations={this.props.fetchOrganizations}
                          organizations={this.props.organizations}
                          isLoadingOrgs={this.props.isLoadingOrgs}
                          orgsLoaded={this.props.orgsLoaded}
                        />
                      )}
                    />
                    <Route
                      path={`${this.props.match.path}/keywords/:organizationSlug`}
                      render={(props) => (
                        <KeywordView
                          {...props}
                          keywords={keywords.filter(
                            (keyword) =>
                              keyword.organization ===
                              this.findOrg(
                                organizationPathParams.params.organizationSlug
                              ).id
                          )}
                          planningMonths={this.props.planningMonths}
                          organizations={this.props.organizations}
                          foundOrg={this.findOrg(
                            organizationPathParams.params.organizationSlug
                          )}
                          fetchOrganizations={this.props.fetchOrganizations}
                          fetchKeywords={this.props.fetchContentKeywords}
                          isLoading={this.props.isLoadingKeywords}
                          isLoaded={this.props.keywordsLoaded}
                          handleAddingKeyword={this.handleAddingKeyword}
                          isAddingKeyword={this.state.isAddingKeyword}
                          selectKeyword={this.handleKeywordSelect}
                          keywordToEdit={this.props.keywordToEdit}
                        />
                      )}
                    />
                  </Switch>
                </Grid.Column>
                {this.shouldSidebarShow() && (
                  <Grid.Column width={5}>
                    <section className="sidebarFormContainer">
                      {this.shouldContentFormShow() && (
                        <ContentArticleForm
                          articleToEdit={this.state.articleToEdit}
                          templateToEdit={this.state.templateToEdit}
                          addingContent={this.state.isAddingContent}
                          addingTemplate={this.state.isAddingTemplate}
                          closeForm={this.cancelAction}
                          handleTitle={this.handleTitle}
                          handleArchiveShow={this.handleArchiveShow}
                          location={this.props.location}
                          project={
                            projectPathParams &&
                            this.props.contentProjects[0] &&
                            this.props.contentProjects.find(
                              (project) =>
                                project.id == projectPathParams.params.projectID
                            )
                          }
                          planningYearMonth={
                            monthPathParams &&
                            this.props.planningMonths[0] &&
                            this.props.planningMonths.find(
                              (month) =>
                                monthPathParams.params.month == month.month &&
                                monthPathParams.params.year == month.year
                            )
                          }
                        />
                      )}
                      {this.shouldKeywordFormShow() &&
                        organizationPathParams && (
                          <KeywordForm
                            closeForm={this.cancelAction}
                            keywordToEdit={this.state.keywordToEdit}
                            addingKeyword={this.state.isAddingKeyword}
                            orgSlug={
                              organizationPathParams.params.organizationSlug
                            }
                            location={this.props.location}
                          />
                        )}
                      {this.shouldBulkArticleFormShow() && (
                        <BulkContentArticleForm
                          articles={this.state.selectedArticles}
                          closeForm={this.cancelAction}
                          handleTitle={this.handleTitle}
                          location={this.props.location}
                        />
                      )}
                    </section>
                  </Grid.Column>
                )}
              </Grid.Row>
            </Grid>
          </section>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isLoadingMonths: state.contentGeneration.isLoadingMonths,
    isLoadingProjects: state.contentGeneration.isLoadingProjects,
    isContentDataLoading: state.contentGeneration.isContentDataLoading,
    contentArticles: state.contentGeneration.contentArticles,
    articleTemplates: state.contentGeneration.articleTemplates,
    isLoadingArticles: state.contentGeneration.isLoadingArticles,
    articlesLoaded: state.contentGeneration.articlesLoaded,
    isLoadingTemplates: state.contentGeneration.isLoadingTemplates,
    templatesLoaded: state.contentGeneration.templatesLoaded,
    isAddingTemplate: state.contentGeneration.isAddingTemplate,
    messages: state.contentGeneration.messages,
    planningMonths: state.contentGeneration.planningMonths,
    contentProjects: state.contentGeneration.contentProjects,
    isAddingMonth: state.contentGeneration.isAddingMonth,
    addedMonth: state.contentGeneration.addedMonth,
    keywords: state.contentGeneration.keywords,
    isLoadingKeywords: state.contentGeneration.isLoadingKeywords,
    keywordsLoaded: state.contentGeneration.keywordsLoaded,
    organizations: state.organizations.organizations,
    isLoadingOrgs: state.organizations.isLoading,
    orgsLoaded: state.organizations.isLoaded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchArticleTemplates: () => {
      dispatch(contentGeneration.fetchArticleTemplates());
    },
    fetchPlanningMonths: () => {
      return dispatch(contentGeneration.fetchPlanningMonths());
    },
    fetchContentProjects: () => {
      return dispatch(contentGeneration.fetchContentProjects());
    },
    fetchContentKeywords: (organizationId) => {
      return dispatch(contentGeneration.fetchContentKeywords(organizationId));
    },
    fetchOrganizations: () => {
      return dispatch(organizations.fetchOrganizations());
    },
    setMenuItems: (items, route) => dispatch(ui.setMenuItems(items, route)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentGeneration);
