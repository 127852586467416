import React, { Component } from 'react';
import { Segment, Header } from 'semantic-ui-react';
import { dynamicComponents } from '../../../dynamicComponentMap';
import './PresentationCreatorToolbar.css';

export default class PresentationCreatorToolbar extends Component {
	transformComponentData = (component) => {
		return {
			name: component.name,
			verbose_name: component.verbose_name,
			fields: component.attribute_fields.map((field) => ({
				name: field.attributeName,
				data: field.derivedFrom
					? this.props.derivedKeys &&
						Object.entries(this.props.derivedKeys).find(([ key, val ]) => key === field.derivedFrom) ?
						this.props.derivedKeys[field.derivedFrom] : null
					: field.initialValue !== undefined ? field.initialValue : field.required ? '' : null
			}))
		};
	};

	handleAddComponent = async (component) => {
		const data = this.transformComponentData(component);
		return this.props.handleAddComponent(data);
	};

	render() {
		return (
			<div className="PresentationCreatorToolbar">
				<Segment>
					<Header as="h4">Components</Header>
					<div className="PresentationCreatorToolbar--components-list">
						{dynamicComponents.map((comp) => (
							<div
								key={comp.name}
								onClick={() => this.handleAddComponent(comp)}
								className="PresentationCreatorToolbar--component"
							>
								<Segment size="tiny">
									<p>{comp.verbose_name}</p>
								</Segment>
							</div>
						))}
					</div>
				</Segment>
			</div>
		);
	}
}
