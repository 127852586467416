import React from "react";
import {
  Menu,
  Icon,
  Header,
  Message,
  TransitionGroup
} from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import "./Navbar.css";
import UserMenu from "../UserMenu/UserMenu";

const Navbar = props => {
  return (
    <div className="NavBar">
      <div className="NavBarContainer">
        <Header>{props.routeHeader}</Header>
        <Menu className="NavBarMenu" secondary pointing>
          {props.menuItems &&
            props.menuItems.map(menuItem => {
              return menuItem.protected ? (
                menuItem.permission ? (
                  menuItem.path ? (
                    <NavLink
                      key={menuItem.id}
                      exact={menuItem.exact}
                      to={
                        props.noMenuPrefix
                          ? menuItem.path
                          : props.matchPath + menuItem.path
                      }
                    >
                      <Menu.Item link>
                        {menuItem.icon && <Icon name={menuItem.icon} />}
                        {menuItem.navbarName}
                      </Menu.Item>
                    </NavLink>
                  ) : (
                    <Menu.Item key={menuItem.id} className="noLink">
                      {" "}
                      {menuItem.icon && <Icon name={menuItem.icon} />}
                      {menuItem.component ? (
                        menuItem.component
                      ) : (
                        <React.Fragment>
                          {" "}
                          {menuItem.icon && <Icon name={menuItem.icon} />}{" "}
                          {menuItem.navbarName}
                        </React.Fragment>
                      )}
                    </Menu.Item>
                  )
                ) : null
              ) : menuItem.path ? (
                <NavLink
                  key={menuItem.id}
                  exact={menuItem.exact}
                  to={props.matchPath + menuItem.path}
                >
                  <Menu.Item link>
                    {menuItem.icon && <Icon name={menuItem.icon} />}
                    {menuItem.navbarName}
                  </Menu.Item>
                </NavLink>
              ) : (
                <Menu.Item key={menuItem.id} className="noLink">
                  {" "}
                  {menuItem.icon && <Icon name={menuItem.icon} />}
                  {menuItem.component ? (
                    menuItem.component
                  ) : (
                    <React.Fragment>
                      {" "}
                      {menuItem.icon && <Icon name={menuItem.icon} />}{" "}
                      {menuItem.navbarName}
                    </React.Fragment>
                  )}
                </Menu.Item>
              );
            })}
        </Menu>
        {props.messages && (
          <TransitionGroup animation="scale" duration={400}>
            {props.messages[0] && <Message className="NavbarMessage" size="mini" positive compact>{props.messages}</Message>}
          </TransitionGroup>
        )}
      </div>
      <UserMenu />
    </div>
  );
};

export default Navbar;
