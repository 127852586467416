import React, { Component } from "react";
import { contentGeneration } from "../../store/actions";
import { connect } from "react-redux";
import { Form, Select, Button, Transition } from "semantic-ui-react";
import FormHeader from "../../components/FormHeader/FormHeader";
import "./BulkContentArticleForm.css";
import ErrorHandler from "../ErrorHandler/ErrorHandler";
import { DateInput } from "semantic-ui-calendar-react";
import FormMessage from "../FormMessage/FormMessage";
import UpdateIndicator from "../UpdateIndicator/UpdateIndicator";
import _ from "lodash";
import { hasPermissions } from "../../utility";
import moment from "moment";

const initialFormState = {
  status: "",
  project: "",
  planningYearMonth: "",
  contentType: "",
  lead: "",
  final_approver: "",
  editor: "",
  poster: "",
  writer: "",
  duedate_write: "",
  archived: ""
};

class BulkContentArticleForm extends Component {
  state = {
    form: initialFormState,
    updating: false,
    preparingUpdate: false,
    readyForUpdate: false,
    didUpdate: false,
    dropdownUpdated: false
  };

  componentDidMount() {
    if (!this.props.contentTypes[0]) {
      this.fetchContentData();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Take snapshot of old form if updating
    // if the onChange event is an input
    if (
      !this.state.preparingUpdate &&
      !this.state.readyForUpdate &&
      this.state.updating !== prevState.updating
    ) {
      this.setState({ oldForm: prevState.form });
    }
    // else if the onChange is a dropdown
    else if (
      this.state.dropdownUpdated &&
      this.state.readyForUpdate &&
      !_.isEqual(this.state.oldForm, this.state.form)
    ) {
      this.setState({ oldForm: prevState.form });
    }
    // if there is an existing snapshot, the form is not being updated and it is ready for updates
    if (
      this.state.oldForm &&
      !this.state.updating &&
      this.state.readyForUpdate &&
      !this.state.preparingUpdate &&
      !this.state.didUpdate
    ) {
      // check to make sure the old form is not the same as the new changes
      if (
        this.state.oldForm &&
        !_.isEqual(this.state.oldForm, this.state.form) &&
        this.state.readyForUpdate &&
        !this.state.preparingUpdate &&
        !this.state.didUpdate &&
        !this.state.updating
      ) {
        this.handleSubmit();
        this.setState({
          readyForUpdate: false,
          didUpdate: true,
          preparingUpdate: false,
          dropdownUpdated: false,
          updating: false,
          oldForm: null
        });
        // else if there weren't any changes do not make an update
      } else if (_.isEqual(this.state.oldForm, this.state.form)) {
        this.setState({ updating: false, oldForm: null });
      }
    }

    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.closeForm();
    }
    if (this.props.articles !== prevProps.articles) {
      this.setState({ oldForm: null });
    }
  }

  //   setArticleData = () => {
  //     const isDataSimilar = (field, data) => {
  //         console.log(this.props.articles[0][field])
  //         this.props.articles.forEach(article => (
  //             article[field] === data ? true : false
  //         ))
  //     }
  //     this.setState({
  //       form: {
  //         mileStone: isDataSimilar('milestone', this.props.articles[0].milestone) ? this.props.articles[0].milestone : '',
  //         contentType: this.props.articles.forEach(article => article.content_type === this.props.articles[0].content_type) ? this.props.articles[0].content_type : '',
  //         // final_approver: this.props.articleToEdit.final_approver.id,
  //         // lead: this.props.articleToEdit.lead.id,
  //         // editor: this.props.articleToEdit.editor.id,
  //         // writer: this.props.articleToEdit.writer.id,
  //         // poster: this.props.articleToEdit.poster.id,
  //         // status: this.props.articleToEdit.status.id,
  //         // duedate_write: this.props.articleToEdit.duedate_write,
  //         // archived: this.props.articleToEdit.archived
  //       }
  //     });
  //   };

  fetchContentData = () => {
    if (!this.props.isLoading) {
      !this.props.contentTypes[0] && this.props.fetchContentTypes();
      !this.props.contentStatuses[0] && this.props.fetchContentStatuses();
      !this.props.writers[0] && this.props.fetchWriters();
      !this.props.contentStaff[0] && this.props.fetchContentStaff();
    }
  };

  clearForm = () => {
    this.setState({ form: initialFormState });
  };

  handleChange(e, isUpdating) {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      },
      updating: true,
      preparingUpdate: false,
      readyForUpdate: false,
      didUpdate: false
    });
    if (!isUpdating) {
      setTimeout(() => {
        if (
          !this.state.preparingUpdate &&
          !this.state.readyForUpdate &&
          this.state.updating &&
          !this.state.didUpdate
        ) {
          this.setState({
            ...this.state,
            preparingUpdate: true,
            updating: false,
            readyForUpdate: false
          });
          setTimeout(() => {
            if (
              this.state.preparingUpdate &&
              !this.state.readyForUpdate &&
              !this.state.updating &&
              !this.state.didUpdate
            ) {
              this.setState(...this.state, {
                readyForUpdate: true,
                preparingUpdate: false
              });
            } else if (!this.state.didUpdate && !this.state.preparingUpdate) {
              this.setState(...this.state, {
                updating: true,
                readyForUpdate: false,
                preparingUpdate: false
              });
            }
          }, 1000);
        } else if (!this.state.didUpdate && !this.state.preparingUpdate) {
          this.setState({
            ...this.state,
            preparingUpdate: false,
            updating: true,
            readyForUpdate: false
          });
        }
      }, 2000);
    }
  }

  handleSelectChange = async (event, { name, value }) => {
    if (this.state.form[name] !== value) {
      await this.setState({
        form: { ...this.state.form, [name]: value },
        readyForUpdate: true,
        dropdownUpdated: true,
        didUpdate: false
      });
    }
  };

  handleSubmit = async e => {
    e && e.preventDefault();
    await this.props.updateBulkContentArticle(
      this.props.articles,
      this.state.form
    );
    !this.props.errors && this.props.closeForm();
  };

  render() {
    const clientOptions = [];
    const clientOptionsResult = [];
    if (this.props.contentStaff && this.props.contentStaff[0]) {
      this.props.contentStaff.forEach(staff =>
        clientOptions.push({
          key: staff.id,
          text: staff.name,
          value: staff.id
        })
      );
    }
    if (this.props.approvalStaff && this.props.approvalStaff[0]) {
      this.props.approvalStaff.forEach(staff =>
        clientOptions.push({
          key: staff.id,
          text: staff.name,
          value: staff.id
        })
      );
    }
    const map = new Map();
    for (const item of clientOptions) {
      if (!map.has(item.key)) {
        map.set(item.key, true);
        clientOptionsResult.push({
          key: item.key,
          text: item.text,
          value: item.value
        });
      }
    }

    return (
      this.props.contentStatuses && (
        <div className="ContentArticleForm">
          <Transition.Group animation="slide down" duration={500}>
            {this.props.formMessages[0] && (
              <FormMessage>{this.props.formMessages}</FormMessage>
            )}
          </Transition.Group>
          {(this.state.updating || this.state.preparingUpdate) && (
            <UpdateIndicator />
          )}
          <FormHeader headerText={this.props.handleTitle()}>
            <Button compact size="mini" onClick={() => this.props.closeForm()}>
              Close
            </Button>
          </FormHeader>
          {this.props.errors && <ErrorHandler errors={this.props.errors} />}
          <Form
            className="ContentArticleForm--Form"
            loading={!this.props.contentStatuses[0]}
            size="tiny"
            onSubmit={e => this.handleSubmit(e)}
          >
            {hasPermissions(this.props.user) && (
              <Form.Field inline>
                <label>Planning Month</label>
                <Select
                  selection
                  placeholder="Change Planning Month"
                  onChange={this.handleSelectChange}
                  name="planningYearMonth"
                  options={this.props.planningYearMonths.map(month => ({
                    key: month.id,
                    text: `${moment(month.month, "MM").format("MMMM")} ${
                      month.year
                    }`,
                    value: month.id
                  }))}
                  value={this.state.form.planningYearMonth}
                />
              </Form.Field>
            )}
            {hasPermissions(this.props.user) && (
              <Form.Field inline>
                <label>Project</label>
                <Select
                  selection
                  placeholder="Change Project"
                  onChange={this.handleSelectChange}
                  name="project"
                  options={this.props.contentProjects.map(project => ({
                    key: project.id,
                    text: project.name,
                    value: project.id
                  }))}
                  value={this.state.form.project}
                />
              </Form.Field>
            )}
            <Form.Field inline>
              <label>Type</label>
              <Select
                selection
                placeholder="Change Type"
                onChange={this.handleSelectChange}
                name="contentType"
                options={this.props.contentTypes.map(type => ({
                  key: type.id,
                  text: type.name,
                  value: type.id
                }))}
                value={this.state.form.contentType}
              />
            </Form.Field>
            <Form.Field inline>
              <label>Status</label>
              <Select
                selection
                placeholder="Change Status"
                onChange={this.handleSelectChange}
                name="status"
                options={this.props.contentStatuses
                  .filter(status => status.is_active === true)
                  .map(status => ({
                    key: status.id,
                    text: status.name,
                    value: status.id
                  }))}
                value={this.state.form.status}
              />
            </Form.Field>
            <Form.Field inline>
              <label>Writer</label>
              <Select
                selection
                placeholder="Change Writer"
                onChange={this.handleSelectChange}
                name="writer"
                options={this.props.writers.map(writer => ({
                  key: writer.id,
                  text: writer.user.name,
                  value: writer.id
                }))}
                value={this.state.form.writer}
              />
            </Form.Field>
            <Form.Field inline>
              <label>Editor</label>
              <Select
                selection
                placeholder="Change Editor"
                onChange={this.handleSelectChange}
                name="editor"
                options={clientOptionsResult[0] ? clientOptionsResult : []}
                value={this.state.form.editor}
              />
            </Form.Field>
            <Form.Field inline>
              <label>Lead</label>
              <Select
                selection
                placeholder="Change Lead"
                onChange={this.handleSelectChange}
                name="lead"
                options={clientOptionsResult[0] ? clientOptionsResult : []}
                value={this.state.form.lead}
              />
            </Form.Field>
            <Form.Field inline>
              <label>Final Reviewer</label>
              <Select
                selection
                placeholder="Change Final Reviewer"
                onChange={this.handleSelectChange}
                name="final_approver"
                options={clientOptionsResult[0] ? clientOptionsResult : []}
                value={this.state.form.final_approver}
              />
            </Form.Field>
            <Form.Field inline>
              <label>Poster</label>
              <Select
                selection
                placeholder="Change Poster"
                onChange={this.handleSelectChange}
                name="poster"
                options={clientOptionsResult[0] ? clientOptionsResult : []}
                value={this.state.form.poster ? this.state.form.poster : ""}
              />
            </Form.Field>
            <Form.Field inline>
              <label>Draft Due Date</label>
              <DateInput
                closable
                placeholder="Change Due Date"
                name="duedate_write"
                iconPosition="left"
                dateFormat="YYYY-MM-DD"
                value={
                  this.state.form.duedate_write
                    ? this.state.form.duedate_write
                    : ""
                }
                onChange={this.handleSelectChange}
              />
            </Form.Field>
          </Form>
        </div>
      )
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.contentGeneration.isLoading,
    isLoaded: state.contentGeneration.isLoaded,
    contentTypes: state.contentGeneration.contentTypes,
    writers: state.contentGeneration.writers,
    contentStaff: state.contentGeneration.contentStaff,
    approvalStaff: state.contentGeneration.approvalStaff,
    contentStatuses: state.contentGeneration.contentStatuses,
    planningYearMonths: state.contentGeneration.planningMonths,
    contentProjects: state.contentGeneration.contentProjects,
    messages: state.contentGeneration.messages,
    formMessages: state.contentGeneration.formMessages,
    errors: state.contentGeneration.errors,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateBulkContentArticle: (articles, formData) => {
      return dispatch(
        contentGeneration.updateBulkContentArticle(articles, formData)
      );
    },
    fetchContentTypes: () => {
      return dispatch(contentGeneration.fetchContentTypes());
    },
    fetchContentStatuses: () => {
      return dispatch(contentGeneration.fetchContentStatuses());
    },
    fetchWriters: () => {
      return dispatch(contentGeneration.fetchWriters());
    },
    fetchContentStaff: () => {
      return dispatch(contentGeneration.fetchContentStaff());
    },
    fetchApprovalStaff: orgID => {
      return dispatch(contentGeneration.fetchApprovalStaff(orgID));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BulkContentArticleForm);
