import React from 'react';
import { Loader } from 'semantic-ui-react';
import "./FixedLoader.css"

const FixedLoader = props => {
    return (
        <div className="FixedLoader"><Loader size='large' active>{props.text && props.text}</Loader></div>
    );
};

export default FixedLoader;