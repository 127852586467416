import _ from 'lodash';
import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';
import './UserTable.css';

export default class UserTable extends Component {
	state = {
		column: null,
		data: this.props.tableData,
		direction: null
	};

	componentDidUpdate(prevProps) {
		if (prevProps.tableData !== this.props.tableData) {
			this.setState({ data: this.props.tableData });
		}
	}

	handleSort = (clickedColumn) => () => {
		const { column, data, direction } = this.state;

		if (column !== clickedColumn) {
			this.setState({
				column: clickedColumn,
				data: _.sortBy(data, [ clickedColumn ]),
				direction: 'ascending'
			});

			return;
		}

		this.setState({
			data: data.reverse(),
			direction: direction === 'ascending' ? 'descending' : 'ascending'
		});
	};

	ifData = (data) => {
		if (this.props.tableData[0]) {
			if (data in this.props.tableData[0]) {
				return true;
			} else {
				return false;
			}
		}
	};

	render() {
		const { column, data, direction } = this.state;

		return (
			<div className="UserTable">
				<Table size="small" attached={this.props.attached} compact sortable celled={this.props.celled} basic={this.props.basic} selectable>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell
								sorted={column === 'name' ? direction : null}
								onClick={this.handleSort('name')}
							>
								Name
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={column === 'email' ? direction : null}
								onClick={this.handleSort('email')}
							>
								Title
							</Table.HeaderCell>
							<Table.HeaderCell
								sorted={column === 'user_preferences.default_organization.dba_name' ? direction : null}
								onClick={this.handleSort('user_preferences.default_organization.dba_name')}
							>
								Primary Organization
							</Table.HeaderCell>
							{/* <Table.HeaderCell
								sorted={column === 'default_role.name' ? direction : null}
								onClick={this.handleSort('default_role.name')}
							>
								Role
							</Table.HeaderCell> */}
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{this.state.data &&
							data.map((data) => (
								<Table.Row onClick={() => this.props.handleSelect(data)} key={data.id}>
									<Table.Cell>{data.name}</Table.Cell>
									<Table.Cell>{data.title}</Table.Cell>
									<Table.Cell>{data.user_preferences && data.user_preferences.default_organization && data.user_preferences.default_organization.dba_name}</Table.Cell>
									{/* <Table.Cell>{data.default_role  ? data.default_role.name : <span className="UserTable--noData">-</span>}</Table.Cell> */}
								</Table.Row>
							))}
					</Table.Body>
				</Table>
			</div>
		);
	}
}
