import React, { Component } from 'react';
import './AccountHealthOverviewList.css';
import AccountHealthOverviewListItem from './AccountHealthOverviewListItem/AccountHealthOverviewListItem';
import _ from 'lodash';
import { Search, Dropdown, Icon, Header, Checkbox } from 'semantic-ui-react';
import { sortOptions } from './constants';
import moment from 'moment';
import AccountOverviewFilter from './AccountOverviewFilter/AccountOverviewFilter';
import { parseStringParameters } from '../../../utility';

const initialSortParams = {
	value: null,
	text: null,
	asc: null
};

export class AccountHealthOverviewList extends Component {
	state = {
		isSearching: false,
		searchValue: '',
		searchResults: [],
		sortParams: initialSortParams,
		filterParams: [],
		showAll: false
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevState.filterParams !== this.state.filterParams) {
			if (this.state.filterParams && this.state.filterParams[0]) {
				const newestFilterParam = this.state.filterParams[this.state.filterParams.length - 1];
				if (newestFilterParam) {
					const correspondingOption = sortOptions.find(
						(option) => option.value === newestFilterParam.parameter
					);
					this.handleSortParams(correspondingOption, true);
				}
			} else if (prevState.filterParams[0] && !this.state.filterParams[0]) {
				if (this.state.sortParams && this.state.sortParams.artificial) {
					this.clearSortParams();
				}
			}
		}
	}

	resetSearch = () => this.setState({ isSearching: false, searchResults: [], searchValue: '' });

	handleResultSelect = (e, { result }) => {
		// TODO
		console.log('Handle view change here');
	};

	handleSearchChange = (e, { value }) => {
		this.setState({ isSearching: true, searchValue: value });

		setTimeout(() => {
			if (this.state.searchValue.length < 1) return this.resetSearch();

			const re = new RegExp(_.escapeRegExp(this.state.searchValue), 'i');
			const isMatch = (result) => re.test(result.account.dba_name);

			this.setState({
				isSearching: false,
				searchResults: _.filter(this.props.overviews, isMatch)
			});
		}, 300);
	};

	handleSortParams = (option, artificial) => {
		this.setState({
			...this.state,
			sortParams: {
				text: option.text,
				value: option.value,
				asc: option.asc,
				artificial: artificial
			}
		});
	};

	clearSortParams = () => {
		this.setState({ ...this.state, sortParams: initialSortParams });
	};

	calculateLeadsOverTimeDifference = (leads, monthsBack) => {
		const sortedLeads =
			leads &&
			leads[0] &&
			leads.sort((a, b) => moment([ b.month_year, b.month_month ]) - moment([ a.month_year, a.month_month ]));

		const countLeads = (sources) => {
			let count = 0;
			if (sources && sources[0]) {
				sources.map((source) => (count = count + Number(source.value)));
			}
			return count;
		};

		if (sortedLeads && sortedLeads[0]) {
			const thisMonth = sortedLeads[0];
			const monthToCompare = sortedLeads[monthsBack ? monthsBack - 1 : 1];

			if (monthToCompare) {
				const thisMonthLeadCount = countLeads(thisMonth.sources);
				const monthToCompareLeadCount = countLeads(monthToCompare.sources);
				const difference = thisMonthLeadCount - monthToCompareLeadCount;

				return difference;
			} else {
				return null;
			}
		} else {
			return null;
		}
	};

	calculateTrafficDataDifference = (leads, monthsBack) => {
		const sortedLeads =
			leads &&
			leads[0] &&
			leads.sort((a, b) => moment([ b.month_year, b.month_month ]) - moment([ a.month_year, a.month_month ]));

		if (sortedLeads && sortedLeads[0]) {
			const thisMonth = sortedLeads[0];
			const monthToCompare = sortedLeads[monthsBack ? monthsBack - 1 : 1];

			if (monthToCompare) {
				const difference = Number(thisMonth.total_count) - Number(monthToCompare.total_count);

				return difference;
			} else {
				return null;
			}
		} else {
			return null;
		}
	};

	calculateOverallDataDifference = (data, params, calculator) => {
		if (data && params && params[0] && calculator) {
			let overallDifference = 0;
			params.map(
				(param) => (overallDifference = overallDifference + calculator(data, param === 1 ? null : param))
			);

			return overallDifference;
		} else {
			return null;
		}
	};

	calculateMonthsIntoContract = (startDate) => {
		moment.relativeTimeThreshold('M', 999);
		moment.relativeTimeThreshold('d', 10);
		moment.updateLocale('en', {
			relativeTime: {
				dd: '%d',
				M: '%d',
				MM: '%d'
			}
		});
		const startDateParsed = moment(startDate);
		const difference = startDateParsed.fromNow(true);
		return difference;
	};

	handleFilterSubmit = async (parameterName, valueFrom, valueTo, helper, paramHelper) => {
		await this.handleClearSingleFilter(parameterName)
		return this.setState({
			...this.state,
			filterParams: this.state.filterParams.concat({
				parameter: parameterName,
				min: valueFrom ? valueFrom : "0",
				max: valueTo ? valueTo : Infinity,
				helper: helper,
				paramHelper: paramHelper
			})
		});
	}

	handleClearSingleFilter = (filterParameter) => {
		if (filterParameter && this.state.filterParams && this.state.filterParams[0]) {
			const foundParam = this.state.filterParams.find((param) => param.parameter === filterParameter);
			if (foundParam) {
				this.setState({
					filterParams: this.state.filterParams.filter(
						(filterParam) => filterParam.parameter !== foundParam.parameter
					)
				});
			}
		}
	};

	handleClearFilters = () => {
		this.setState({ ...this.state, filterParams: [] });
	};

	handleFilters = (obj, parameter) => {
		const helper =
			this.state.filterParams &&
			this.state.filterParams[0] &&
			this.state.filterParams.find((param) => param.parameter === parameter).helper;
		const targetPropertyValue = parseStringParameters(parameter, obj);

		if (obj) {
			if (helper) {
				const outcome = Number(helper(targetPropertyValue));
				return outcome;
			} else {
				const outcome = Number(targetPropertyValue);
				return outcome;
			}
		}
	};

	handleWithHelper = (value, parameter) => {
		const paramHelper =
			this.state.filterParams &&
			this.state.filterParams[0] &&
			this.state.filterParams.find((param) => param.parameter === parameter).paramHelper;
		if (value) {
			if (paramHelper) {
				const outcome = Number(paramHelper(value));
				return outcome;
			} else {
				const outcome = Number(value);
				return outcome;
			}
		}
	};

	handleOverviewFilters = (currentOverviews) => {
		const overviews = currentOverviews.filter(ov => this.state.showAll ? ov : ov.account.report_required)
		if (this.state.filterParams && this.state.filterParams[0]) {
			let filteredOverviews = overviews;
			this.state.filterParams.map((param) => {
				return (filteredOverviews = filteredOverviews.filter(
					(ov) =>
						this.handleFilters(ov, param.parameter) >= this.handleWithHelper(param.min, param.parameter) &&
						this.handleFilters(ov, param.parameter) <= this.handleWithHelper(param.max, param.parameter)
				));
			});
			return filteredOverviews;
		} else {
			return overviews;
		}
	};

	calculateDecimalScore = (decimalScore) => {
		return Math.floor(decimalScore * 100)
	}

	render() {
		const overviewsRaw = this.state.searchResults[0] ? this.state.searchResults : this.props.overviews;
		const { value, asc } = this.state.sortParams;
		const overviewsResults = overviewsRaw.map((overview) => ({
			...overview,
			leads_data:
				overview.leads_data &&
				overview.leads_data[0] &&
				overview.leads_data.sort(
					(a, b) => moment([ b.month_year, b.month_month ]) - moment([ a.month_year, a.month_month ])
				),
			organic_traffic_data:
				overview.organic_traffic_data &&
				overview.organic_traffic_data[0] &&
				overview.organic_traffic_data.sort(
					(a, b) => moment([ b.month_year, b.month_month ]) - moment([ a.month_year, a.month_month ])
				),
			leads_data_sorted:
				overview.leads_data &&
				overview.leads_data[0] &&
				overview.leads_data.sort(
					(a, b) =>
						new Date(`${b.month_year}-${b.month_month}-1`) - new Date(`${a.month_year}-${a.month_month}-1`)
				),
			organic_traffic_data_sorted:
				overview.organic_traffic_data &&
				overview.organic_traffic_data[0] &&
				overview.organic_traffic_data.sort(
					(a, b) =>
						new Date(`${b.month_year}-${b.month_month}-1`) - new Date(`${a.month_year}-${a.month_month}-1`)
				),
			overallLeadsDifference: this.calculateOverallDataDifference(
				overview.leads_data,
				[ 1, 3, 6, 12 ],
				this.calculateLeadsOverTimeDifference
			),
			oneMonthLeadsDifference: this.calculateLeadsOverTimeDifference(overview.leads_data),
			threeMonthsLeadDifference: this.calculateLeadsOverTimeDifference(overview.leads_data, 3),
			sixMonthsLeadDifference: this.calculateLeadsOverTimeDifference(overview.leads_data, 6),
			twelveMonthsLeadDifference: this.calculateLeadsOverTimeDifference(overview.leads_data, 12),
			overallTrafficDifference: this.calculateOverallDataDifference(
				overview.organic_traffic_data,
				[ 1, 3, 6, 12 ],
				this.calculateTrafficDataDifference
			),
			oneMonthTrafficDifference: this.calculateTrafficDataDifference(overview.organic_traffic_data),
			threeMonthTrafficDifference: this.calculateTrafficDataDifference(overview.organic_traffic_data, 3),
			sixMonthTrafficDifference: this.calculateTrafficDataDifference(overview.organic_traffic_data, 6),
			twelveMonthTrafficDifference: this.calculateTrafficDataDifference(overview.organic_traffic_data, 12),
			account: {
				...overview.account,
				properStartDate: overview.account.contract_start_date
					? overview.account.contract_start_date
					: overview.account.created_at
			}
		}));
		const overviews = value
			? asc ? _.sortBy(overviewsResults, [ value ]) : _.sortBy(overviewsResults, [ value ]).reverse()
			: overviewsResults;

		return (
			<div className="AccountHealthOverviewList">
				<div className="AccountHealthOverviewList--headerArea">
					<div className="AccountHealthOverviewList--headerAreaHalf AccountHealthOverviewList--headerAreaLeft">
						<div className="AccountHealthOverviewList--search">
							<Search
								fluid
								size="small"
								placeholder="Search Account Overviews"
								loading={this.state.isSearching || !overviews[0]}
								disabled={!overviews[0]}
								onResultSelect={this.handleResultSelect}
								onSearchChange={_.debounce(this.handleSearchChange, 500, {
									leading: true
								})}
								results={this.state.searchResults.map((result) => ({
									key: result.id,
									title: result.account.dba_name
								}))}
								value={this.state.searchValue}
							/>
						</div>
						<AccountOverviewFilter
							filterFor="properStartDate"
							parameterName="account.properStartDate"
							handleClearFilter={this.handleClearSingleFilter}
							buttonText="Months into Contract"
							currentFilters={this.state.filterParams}
							correspondingFilter={
								this.state.filterParams &&
								this.state.filterParams[0] &&
								this.state.filterParams.find((param) => param.parameter === 'account.properStartDate')
							}
							handleSubmit={this.handleFilterSubmit}
							helperFunction={this.calculateMonthsIntoContract}
						/>
						<AccountOverviewFilter
							filterFor="overdue_approvals"
							parameterName="overdue_approvals.length"
							handleClearFilter={this.handleClearSingleFilter}
							buttonText="Pending Approvals"
							currentFilters={this.state.filterParams}
							correspondingFilter={
								this.state.filterParams &&
								this.state.filterParams[0] &&
								this.state.filterParams.find((param) => param.parameter === 'overdue_approvals.length')
							}
							handleSubmit={this.handleFilterSubmit}
						/>
						<div className="AccountHealthOverviewList--showAllCheckbox">
							<Checkbox toggle label="SEO Accounts Only" checked={!this.state.showAll} onChange={() => this.setState({ showAll: !this.state.showAll })} />
						</div>
					</div>
					<div className="AccountHealthOverviewList--headerAreaHalf AccountHealthOverviewList--headerAreaLeft">
						<div className="AccountHealthOverviewList--sortBy">
							<Dropdown
								direction="left"
								text={value ? `Sorting by: ${this.state.sortParams.text}` : 'Sort Account Overviews By'}
								floating
							>
								<Dropdown.Menu>
									<Dropdown.Item
										onClick={() => this.clearSortParams()}
										className="AccountHealthOverviewList-sortDropdownOptionClear"
									>
										<Icon color="red" name="close" />
										<span>Cancel Sorting</span>
									</Dropdown.Item>
									<Dropdown.Divider />
									{sortOptions.map((option) => (
										<Dropdown.Item
											disabled={value === option.value && asc === option.asc}
											onClick={() => this.handleSortParams(option)}
											className="AccountHealthOverviewList-sortDropdownOption"
											key={option.key}
										>
											<Icon
												className={option.asc ? 'ascending' : 'descending'}
												name={option.asc ? 'sort content ascending' : 'sort content descending'}
											/>
											<span>{option.text}</span>
										</Dropdown.Item>
									))}
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>
				</div>
				<section className={`AccountHealthOverviewList--list`}>
						{this.handleOverviewFilters(overviews) && this.handleOverviewFilters(overviews)[0] ? (
							this.handleOverviewFilters(overviews).map((overview) => (
								<AccountHealthOverviewListItem
									key={overview.id}
									overview={overview}
									calculateLeadsOverTimeDifference={this.calculateLeadsOverTimeDifference}
									calculateTrafficDataDifference={this.calculateTrafficDataDifference}
									calculateMonthsIntoContract={this.calculateMonthsIntoContract}
									sortValue={this.state.sortParams.value}
									sortDirection={this.state.sortParams.asc}
									calculateDecimalScore={this.calculateDecimalScore}
								/>
							))
						) : (
							<div className="AccountHealthOverviewList--noData">
								<Header as="h3">
									<Icon color="blue" name="search" />
									<Header.Content>
										No Matching Overviews Found
										<Header.Subheader>
											Try searching with different parameters or{' '}
											<span
												onClick={() => this.handleClearFilters()}
												className="AccountHealthOverviewList--clearSearch"
											>
												click here
											</span>{' '}
											to clear your search filters.
										</Header.Subheader>
									</Header.Content>
								</Header>
							</div>
						)}
				</section>
			</div>
		);
	}
}

export default AccountHealthOverviewList;
