import React, { Component } from 'react';
import './PresentationSlide.css';
import PresentationComponent from '../PresentationComponent/PresentationComponent';
import { Dimmer, Loader, Header } from 'semantic-ui-react';

class PresentationSlide extends Component {
	state = {
		loadingData: false
	};

	handleStartLoad = () => {
		this.setState({
			loadingData: true
		});
	};

	handleFinishLoad = () => {
		this.setState({
			loadingData: false
		});
    };
    

	render() {
		const slide = this.props.slide;
		return (
			<div className="PresentationSlide">
                {(this.state.loadingData || this.props.loadingObjectInstance) && (
						<Dimmer active inverted>
							<Loader size="large">Loading Slide Data</Loader>
						</Dimmer>
					)}
				<Header className="PresentationSlide-header" as='h2' content={slide.title} />
				{slide.components && slide.components[0] ? (
					slide.components.map((component, i) => (
						<PresentationComponent
							key={i}
							withPDF={this.props.withPDF}
							loadingData={this.state.loadingData}
							loadedDataObjectInstance={this.props.loadedObjectInstance}
							loadingObjectInstance={this.props.loadingObjectInstance}
                            component={component}
                            presentation={this.props.presentation}
                            objectInstance={this.props.objectInstance}
							handleStartLoad={this.handleStartLoad}
							handleFinishLoad={this.handleFinishLoad}
							storePresentationData={this.props.storePresentationData}
							storedData={this.props.storedData}
						/>
					))
				) : (
					<div className="PresentationSlide--noData">
						<p>This slide is currently empty.</p>
					</div>
				)}
			</div>
		);
	}
}

export default PresentationSlide;
