import React, { Component } from "react";
import "./Checklist.css";
import { List, Button, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { checklists } from "../../store/actions";
import ChecklistItem from "./ChecklistItem/ChecklistItem";

const initialNewItem = {
  name: "",
  order: null,
  description: "",
  department: "",
  assignee: "",
  due: "",
  completed: false,
};

class Checklist extends Component {
  state = {
    selectedItem: null,
    newItem: null,
    isShifting: false,
    isMeta: false
  };


  startAddingItem = async (
    index,
    order,
    parentIndex,
    parentID,
    itemID,
    isTab
  ) => {
    if (isTab && !parentID) {
      this.setState({
        newItem: {
          index: null,
          parentIndex: index,
          newChild: true,
          childIndex: this.findChildCount(itemID)
            ? this.findChildCount(itemID)
            : null,
          data: {
            ...initialNewItem,
            order: this.findLastOrder(itemID)
              ? this.findLastOrder(itemID)
              : null,
            parent: itemID
          }
        }
      });
    } else {
      this.setState({
        newItem: {
          index: parentIndex !== undefined ? null : index,
          parentIndex: parentIndex !== undefined ? parentIndex : null,
          childIndex: parentIndex !== undefined ? index : null,
          data: {
            ...initialNewItem,
            order: order !== undefined ? order + 1 : null,
            parent: parentID ? parentID : initialNewItem.parent
          }
        }
      });
    }
  };

  handleIsAddingItem = () => {
    this.setState({
      newItem: { ...this.state.newItem, isAdding: true, added: false }
    });
  };

  handleAddedItem = () => {
    this.setState({
      newItem: { ...this.state.newItem, isAdding: false, added: true }
    });
  };

  cancelAddingItem = () => {
    this.setState({ newItem: null });
  };

  shouldRenderChildren = children => {
    if (children[0] || (this.state.newItem && this.state.newItem.newChild)) {
      return true;
    }
  };

  findChildCount = itemID => {
    if (this.props.items[0] && itemID) {
      let parent = this.props.items.find(item => item.id === itemID);
      if (parent.checklist_item_children) {
        return parent.checklist_item_children.length + 1;
      }
    }
  };

  findLastOrder = itemID => {
    if (this.props.items[0] && itemID) {
      let childItems = this.props.items.filter(item => item.parent === itemID);
      if (childItems && childItems.length > 0) {
        return (
          this.props.items
            .filter(item => item.parent === itemID)
            .sort((a, b) => a.order - b.order)
            .slice(-1)[0].order + 1
        );
      }
    }
  };

  nextSibling = currentItem => {
    if (currentItem.parent) {
      let sibling = this.props.items
        .filter(item => item.parent === currentItem.parent)
        .sort((a, b) => a.order - b.order)
        .find(item => item.order > currentItem.order);
      return sibling ? sibling.id : null;
    } else {
      let sibling = this.props.items
        .filter(item => !item.parent)
        .sort((a, b) => a.order - b.order)
        .find(item => item.order > currentItem.order);
      return sibling ? sibling.id : null;
    }
  };

  prevSibling = currentItem => {
    if (currentItem.parent) {
      let sibling = this.props.items
        .filter(item => item.parent === currentItem.parent)
        .sort((a, b) => b.order - a.order)
        .find(item => item.order < currentItem.order);
      return sibling ? sibling.id : null;
    } else {
      let sibling = this.props.items
        .filter(item => !item.parent)
        .sort((a, b) => b.order - a.order)
        .find(item => item.order < currentItem.order);
      return sibling ? sibling.id : null;
    }
  };

  handleReOrder = (itemToMove, direction, parent) => {
    let nextHighest = this.props.items
      .sort((a, b) => a.order - b.order)
      .find(item => item.order > itemToMove.order);
    let nextHighestSibling = parent
      ? this.props.items
          .filter(item => item.parent === parent)
          .sort((a, b) => a.order - b.order)
          .find(item => item.order > itemToMove.order)
      : null;
    let nextLowest = this.props.items
      .sort((a, b) => b.order - a.order)
      .find(item => item.order < itemToMove.order);
    let nextLowestSibling = parent
      ? this.props.items
          .filter(item => item.parent === parent)
          .sort((a, b) => b.order - a.order)
          .find(item => item.order < itemToMove.order)
      : null;
    if (direction === "up") {
      if (nextLowestSibling) {
        this.props.moveChecklistItem(
          itemToMove,
          this.props.list.id,
          nextLowestSibling.order
        );
      } else {
        nextLowest !== null &&
          this.props.moveChecklistItem(
            itemToMove,
            this.props.list.id,
            nextLowest.order
          );
      }
    }
    if (direction === "down") {
      if (nextHighestSibling) {
        this.props.moveChecklistItem(
          itemToMove,
          this.props.list.id,
          nextHighestSibling.order
        );
      } else {
        nextHighest !== null &&
          this.props.moveChecklistItem(
            itemToMove,
            this.props.list.id,
            nextHighest.order
          );
      }
    }
  };

  render() {
    return (
      <div className="Checklist">
        {this.props.items[0] ? (
          <List ordered verticalAlign="middle">
            {this.props.items
              .filter(item => !item.parent)
              .sort((a, b) => a.order - b.order)
              .map((item, i) => {
                return (
                  <React.Fragment key={item.tempID ? item.tempID : item.id}>
                    <ChecklistItem
                      key={item.tempID ? item.tempID : item.id}
                      index={i}
                      allItems={this.props.items && this.props.items[0] && this.props.items}
                      addingNewChild={
                        this.state.newItem && this.state.newItem.newChild
                      }
                      handleReOrder={this.handleReOrder}
                      isAddingItem={this.handleIsAddingItem}
                      handleAddedItem={this.handleAddedItem}
                      startAddingItem={this.startAddingItem}
                      nextSibling={this.nextSibling}
                      prevSibling={this.prevSibling}
                      item={item}
                      list={this.props.list}
                      deleteChecklistItem={
                        this.props.deleteChecklistItem
                      }
                      updateChecklistItem={
                        this.props.updateChecklistItem
                      }
                    >
                      {item.checklist_item_children &&
                        item.checklist_item_children[0] &&
                        this.props.items
                          .filter(child =>
                            item.checklist_item_children.includes(
                              child.id || child.tempID
                            )
                          )
                          .sort((a, b) => a.order - b.order)
                          .map((child, childIndex) => {
                            return (
                              <React.Fragment
                                key={child.tempID ? child.tempID : child.id}
                              >
                                <ChecklistItem
                                  key={child.id ? child.id : child.tempID}
                                  child
                                  parentIndex={i}
                                  parentID={item.id}
                                  index={childIndex}
                                  handleReOrder={this.handleReOrder}
                                  isAddingItem={this.handleIsAddingItem}
                                  handleAddedItem={this.handleAddedItem}
                                  startAddingItem={this.startAddingItem}
                                  nextSibling={this.nextSibling}
                                  prevSibling={this.prevSibling}
                                  item={child}
                                  list={this.props.list}
                                  deleteChecklistItem={
                                    this.props.deleteChecklistItem
                                  }
                                  updateChecklistItem={
                                    this.props.updateChecklistItem
                                  }
                                />
                                {this.state.newItem &&
                                  (this.state.newItem.childIndex ===
                                    childIndex &&
                                    this.state.newItem.parentIndex === i) && (
                                    <ChecklistItem
                                      new
                                      child
                                      parentIndex={i}
                                      newIndex={this.state.newItem.index}
                                      startAddingItem={this.startAddingItem}
                                      isAddingItem={this.handleIsAddingItem}
                                      handleAddedItem={this.handleAddedItem}
                                      item={this.state.newItem.data}
                                      itemMeta={this.state.newItem}
                                      list={this.props.list}
                                      cancelAddingItem={this.cancelAddingItem}
                                      addChecklistItem={
                                        this.props.addChecklistItem
                                      }
                                    />
                                  )}
                              </React.Fragment>
                            );
                          })}
                      {this.state.newItem &&
                        this.state.newItem.newChild &&
                        this.state.newItem.data.parent === item.id && (
                          <ChecklistItem
                            new
                            child
                            parentIndex={i}
                            newIndex={this.state.newItem.index}
                            startAddingItem={this.startAddingItem}
                            isAddingItem={this.handleIsAddingItem}
                            handleAddedItem={this.handleAddedItem}
                            item={this.state.newItem.data}
                            itemMeta={this.state.newItem}
                            list={this.props.list}
                            cancelAddingItem={this.cancelAddingItem}
                            addChecklistItem={
                              this.props.addChecklistItem
                            }
                          />
                        )}
                    </ChecklistItem>
                    {this.state.newItem &&
                      (this.state.newItem.index === i &&
                        !this.state.newItem.parentIndex) && (
                        <ChecklistItem
                          new
                          newIndex={this.state.newItem.index}
                          item={this.state.newItem.data}
                          startAddingItem={this.startAddingItem}
                          itemMeta={this.state.newItem}
                          isAddingItem={this.handleIsAddingItem}
                          handleAddedItem={this.handleAddedItem}
                          list={this.props.list}
                          cancelAddingItem={this.cancelAddingItem}
                          addChecklistItem={
                            this.props.addChecklistItem
                          }
                        />
                      )}
                  </React.Fragment>
                );
              })}
          </List>
        ) : this.state.newItem ? (
          <List celled verticalAlign="middle">
            <ChecklistItem
              new
              newIndex={this.state.newItem.index}
              item={this.state.newItem.data}
              startAddingItem={this.startAddingItem}
              itemMeta={this.state.newItem}
              isAddingItem={this.handleIsAddingItem}
              handleAddedItem={this.handleAddedItem}
              list={this.props.list}
              cancelAddingItem={this.cancelAddingItem}
              addChecklistItem={this.props.addChecklistItem}
            />
          </List>
        ) : (
          <Button
            basic
            size="small"
            icon
            labelPosition="left"
            fluid
            onClick={() => this.startAddingItem()}
          >
            <Icon name="plus" />
            Add New Item
          </Button>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    checklists: state.checklists.checklists.lists,
    errors: state.checklists.checklists.errors,
    messages: state.checklists.checklists.messages,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addChecklistItem: (formData, checklistID, itemIndex) =>
      dispatch(
        checklists.addChecklistItem(
          formData,
          checklistID,
          itemIndex
        )
      ),
    updateChecklistItem: (formData, id, checklistID) =>
      dispatch(
        checklists.updateChecklistItem(
          formData,
          id,
          checklistID
        )
      ),
    moveChecklistItem: (id, checklistID, newOrder) =>
      dispatch(
        checklists.moveChecklistItem(id, checklistID, newOrder)
      ),
    deleteChecklistItem: (id, checklistID) =>
      dispatch(checklists.deleteChecklistItem(id, checklistID))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Checklist);
