import React, { Component } from 'react';
import './ContentOverview.css';
import axios from '../../axios-portal';
import ErrorHandler from '../../components/ErrorHandler/ErrorHandler';
import { Loader, Header, Table } from 'semantic-ui-react';
import moment from 'moment';
import { connect } from 'react-redux';

export class ContentOverview extends Component {
	state = {
		contentArticles: {
			data: null,
			isLoading: false,
			isLoaded: false
		},
		presentationPlanningMonth: {
			data: null,
			isLoading: false,
			isLoaded: false
		},
		errors: null
	};

	componentDidMount() {
		const planningMonthID = this.props.data && this.props.data.month;
		if (planningMonthID) {
			this.fetchPlanningMonth(planningMonthID);
		}
		if (!this.props.organization) {
			this.setState({
				errors: {
					error: 'No Organization Provided.'
				}
			});
		}
		if (this.props.contentArticles && this.props.contentArticles[0]) {
			this.setState({ contentArticles: {
				data: this.props.contentArticles,
				isLoading: false,
				isLoaded: true
			} })
		}
		if (this.props.previewing && this.props.planningMonths[0]) {
			const currentMonth = moment().format('M');
			const currentYear = moment().format('YYYY');

			const currentPlanningMonth = this.props.planningMonths.find(
				(month) => month.month === Number(currentMonth) && month.year === Number(currentYear)
			);
			if (currentPlanningMonth) {
				this.fetchPlanningMonth(currentPlanningMonth.id);
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.state.presentationPlanningMonth.data !== prevState.presentationPlanningMonth.data &&
			this.state.presentationPlanningMonth.data
		) {
			const planningMonthMonth = Number(this.state.presentationPlanningMonth.data.month);
			const planningMonthYear = Number(this.state.presentationPlanningMonth.data.year);

			if (this.props.monthsBack) {
				this.fetchContentArticles(
					this.calculateMonthsBack(planningMonthYear, planningMonthMonth, this.props.monthsBack).format(
						'YYYY'
					),
					this.calculateMonthsBack(planningMonthYear, planningMonthMonth, this.props.monthsBack).format('M')
				);
			} else {
				this.fetchContentArticles(planningMonthYear, planningMonthMonth);
			}
		}
	}

	fetchPlanningMonth = async (planningMonthID) => {
		const correspondingMonth =
			this.props.planningMonths &&
			this.props.planningMonths[0] &&
			this.props.planningMonths.find((pm) => pm.id === planningMonthID);


		if (correspondingMonth) {
			this.setState({
				...this.state,
				presentationPlanningMonth: {
					data: correspondingMonth,
					isLoaded: true,
					isLoading: false
				}
			});
		} else {
			await this.props.handleStartLoad && this.props.handleStartLoad();
			await this.setState({
				...this.state,
				presentationPlanningMonth: {
					data: null,
					isLoading: false,
					isLoaded: false
				}
			});
	
			axios
				.get(`planning-months/${planningMonthID}/`)
				.then((response) => {
					this.props.storePresentationData('presentationPlanningMonth', response.data);
					this.props.handleFinishLoad();
					return this.setState({
						...this.state,
						presentationPlanningMonth: {
							data: response.data,
							isLoading: false,
							isLoaded: true
						}
					});
				})
				.catch((err) => {
					this.props.handleFinishLoad();
					return this.setState({
						...this.state,
						presentationPlanningMonth: {
							data: null,
							isLoading: false,
							isLoaded: false
						},
						errors: {
							error: 'Failed to fetch planning month'
						}
					});
				});
		}
	};

	fetchContentArticles = async (year, month) => {
		await this.props.handleStartLoad();
		await this.setState({
			...this.state,
			contentArticles: {
				data: null,
				isLoading: true,
				isLoaded: false
			}
		});
		axios
			.get(`content-articles/?year=${year}&month=${month}&organization=${this.props.organization}`)
			.then((response) => {
				this.props.handleFinishLoad();
				return this.setState({
					...this.state,
					contentArticles: {
						data: response.data,
						isLoading: false,
						isLoaded: true
					}
				});
			})
			.catch((err) => {
				this.props.handleFinishLoad();
				return this.setState({
					...this.state,
					contentArticles: {
						data: null,
						isLoading: false,
						isLoaded: false
					},
					errors: {
						error: 'Failed to fetch articles'
					}
				});
			});
	};

	calculateMonthsBack = (currentYear, currentMonth, monthsBack, presentationalOnly) => {
		const currentPlanningMonth = moment(`${currentYear}-${currentMonth}-01`);
		const newPlanningMonth = presentationalOnly ? currentPlanningMonth.add((Number(monthsBack) + 1), 'months') : currentPlanningMonth.add(Number(monthsBack), 'months');
		return newPlanningMonth;
	};

	render() {
		const planningMonthMonth =
			this.state.presentationPlanningMonth.data && Number(this.state.presentationPlanningMonth.data.month);
		const planningMonthYear =
			this.state.presentationPlanningMonth.data && Number(this.state.presentationPlanningMonth.data.year);
		return (
			<div className="ContentOverview">
				{this.state.errors ? (
					<ErrorHandler errors={this.state.errors} />
				) : this.state.presentationPlanningMonth.isLoading ? (
					<Loader active>Loading Month Data</Loader>
				) : this.state.contentArticles.isLoading ? (
					<Loader size="tiny" />
				) : this.state.contentArticles.data ? (
					<div className="ContentOverview--output">
						{this.props.tableHeader && (
							<Header as="h3" className="ContentOverview--header">
								{this.props.tableHeader}
								<Header.Subheader>
									{this.props.monthsBack ? (
										<div className="ContentOverview--header__monthsBack">
											<span>
												{moment(
													this.calculateMonthsBack(
														planningMonthYear,
														planningMonthMonth,
														this.props.monthsBack,
														true
													)
												).format('MMMM')}
											</span>
											<span>
												{this.calculateMonthsBack(
													planningMonthYear,
													planningMonthMonth,
													this.props.monthsBack,
													true
												).format('YYYY')}
											</span>
										</div>
									) : (
										<div className="ContentOverview--header__currentMonth">
											<span>{moment(planningMonthMonth, 'MM').format('MMMM')}</span>
											<span>{planningMonthYear}</span>
										</div>
									)}
								</Header.Subheader>
							</Header>
						)}
						<div className="ContentOverview--table">
							<Table celled>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell id="title" width={8}><span>Title</span></Table.HeaderCell>
										<Table.HeaderCell id="type" width={2}><span>Type</span></Table.HeaderCell>
										<Table.HeaderCell id="wordCount" width={3}><span>Word Count</span></Table.HeaderCell>
										<Table.HeaderCell id="status" width={3}><span>Status</span></Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body className="ContentOverview--tableBody">
									{this.state.contentArticles.data ? this.state.contentArticles.data[0] ? (
										this.state.contentArticles.data
											.filter((article) => article.content_type)
											.sort((a, b) => a.content_type.id - b.content_type.id)
											.map((article) => (
												<Table.Row key={article.id}>
													<Table.Cell name="title">{article.title}</Table.Cell>
													<Table.Cell name="content_type">{article.content_type.name}</Table.Cell>
													<Table.Cell name="min_word_count">{article.min_word_count}</Table.Cell>
													<Table.Cell name="min_word_count">{article.status.name}</Table.Cell>
												</Table.Row>
											))
									) : (
										<Table.Row>
											<Table.Cell colSpan="3">No Articles Currently Available</Table.Cell>
										</Table.Row>
									) : (
										<Table.Row>
											<Table.Cell colSpan="3">No Articles Currently Available</Table.Cell>
										</Table.Row>
									)}
								</Table.Body>
							</Table>
						</div>
					</div>
				) : !this.props.loadingData && this.state.presentationPlanningMonth.data ? (
					<h3>Data failed to load</h3>
				) : null}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		planningMonths: state.contentGeneration.planningMonths
	};
};

export default connect(mapStateToProps, null)(ContentOverview);
