import React, { Component } from "react";
import "./ReportingDashboardTable.css";
import { Table} from "semantic-ui-react";
import moment from "moment";
import FixedLoader from "../FixedLoader/FixedLoader";
import ActionHeader from "../ActionHeader/ActionHeader";

class ReportingDashboardTable extends Component {
  render() {
    const getPercentage = (total, value, raw) => {
      let percentage = (value / total) * 100;
      percentage = parseFloat(percentage).toFixed(0);
      return raw ? percentage : `${percentage}%`;
    };

    // const handleStatus = (dueDate, percentComplete) => {
    //   const today = moment();
    //   console.log(`today is ${today}`);
    //   const date = moment(dueDate);
    //   console.log(`duedate is ${date}`);
    //   const twoDaysOut = moment().add(7, "d");
    //   console.log(`two days is ${twoDaysOut}`);
    //   if (date < today) {
    //     return "#e26464";
    //   } else if (date < twoDaysOut) {
    //     return "#f3bb35";
    //   } else if (percentComplete >= 98) {
    //     return "#353535";
    //   } else {
    //     return "#5bd85b";
    //   }
    // };

    return (
      <div className="ReportingDashboardTable">
        <ActionHeader headerText={this.props.headerText}>
          {this.props.headerContent}
        </ActionHeader>
        { this.props.reports ? (
          <Table celled compact selectable>
            <Table.Header>
              <Table.Row textAlign="center">
                <Table.HeaderCell width={3}>Month</Table.HeaderCell>
                <Table.HeaderCell>Total</Table.HeaderCell>
                <Table.HeaderCell>Backlog</Table.HeaderCell>
                <Table.HeaderCell>Assigned</Table.HeaderCell>
                <Table.HeaderCell>Final Review</Table.HeaderCell>
                <Table.HeaderCell>Complete</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {this.props.planningMonths[0] ? (
              <Table.Body>
                {this.props.planningMonths.map(pm => {
                  const reports = this.props.reports.filter(
                    report => report.month === pm.id
                  );
                  const total = reports.length;
                  const sent = reports.filter(
                    report => report.status === "sent"
                  ).length;
                  const finalReview = reports.filter(
                    report => report.status === "finalReview"
                  ).length;
                  const assigned = reports.filter(
                    report => report.status === "assigned"
                  ).length;
                  const backlog = reports.filter(
                    report => report.status === "backlog"
                  ).length;
                  const sentComplete = sent;
                  const finalReviewComplete = sentComplete + finalReview;
                  const assignedComplete = finalReviewComplete + assigned;
                  const backlogComplete = assignedComplete + backlog;

                  return (
                    reports[0] ? <Table.Row
                    key={pm.id}
                    onClick={() => this.props.viewMonth(pm)}
                    textAlign="center"
                  >
                    <Table.Cell className="ReportingDashboardTable--title">
                      {`${moment(pm.month, "MM").format("MMM")} ${pm.year}`}
                    </Table.Cell>
                    <Table.Cell>
                      <span className="ReportingDashboardTable--articleCount">
                        {total}
                      </span>
                    </Table.Cell>
                    <Table.Cell>
                      {getPercentage(total, backlogComplete)}{" "}
                      <span className="ReportingDashboardTable--articleCount">
                        ({backlog})
                      </span>
                    </Table.Cell>
                    <Table.Cell>
                      {getPercentage(total, assignedComplete)}{" "}
                      <span className="ReportingDashboardTable--articleCount">
                        ({assigned})
                      </span>
                    </Table.Cell>
                    <Table.Cell>
                      {getPercentage(total, finalReviewComplete)}{" "}
                      <span className="ReportingDashboardTable--articleCount">
                        ({finalReview})
                      </span>
                    </Table.Cell>
                    <Table.Cell>
                      {getPercentage(total, sentComplete)}{" "}
                      <span className="ReportingDashboardTable--articleCount">
                        ({sent})
                      </span>
                    </Table.Cell>
                  </Table.Row> : null
                  );
                })}
              </Table.Body>
            ) : (
              <FixedLoader />
            )}
          </Table>
        ) : (
          <FixedLoader />
        )}
      </div>
    );
  }
}

export default ReportingDashboardTable;
