import React, { Component } from 'react';
import { connect } from 'react-redux';
import { users } from '../../../store/actions';
import InvitationsTable from '../../../components/InvitationsTable/InvitationsTable';

class InvitationsView extends Component {
	componentDidMount() {
        this.props.cancelAction()
		!this.props.invitations[0] && this.props.fetchInvitations();
	}
	render() {
		return (
			<div className="InvitationsView">
				<InvitationsTable
					tableData={this.props.invitations}
					handleReinvite={this.handleReinvite}
					isLoading={this.props.isLoadingInvitations}
					isLoaded={this.props.invitationsLoaded}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		invitations: state.users.invitations,
		isLoadingInvitations: state.users.isLoadingInvitations,
		invitationsLoaded: state.users.invitationsLoaded,
		errors: state.users.errors,
		messages: state.users.messages
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchInvitations: () => {
			dispatch(users.fetchInvitations());
		},
		reinviteUser: (email, organization, role, key) => {
			dispatch(users.reinviteUser(email, organization, role, key));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(InvitationsView);
