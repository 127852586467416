import * as actionTypes from "../actions/actionTypes";
import axios from "../../axios-portal";

export const startFetchContentObjectTypes = () => {
    return {
        type: actionTypes.START_FETCH_CONTENT_OBJECT_TYPES,
    }
}

export const fetchContentObjectTypesSuccess = (contentObjectTypes) => {
    return {
        type: actionTypes.FETCH_CONTENT_OBJECT_TYPES_SUCCESS,
        contentObjectTypes: contentObjectTypes
    }
}

export const fetchContentObjectTypesFailure = (errors) => {
    return {
        type: actionTypes.FETCH_CONTENT_OBJECT_TYPES_FAILURE,
        errors: errors,
    }
}

export const fetchContentObjectTypes = () => {
    return async (dispatch, getState) => {
		let isLoading = await getState().general.contentObjectTypes.isLoading;
		dispatch(startFetchContentObjectTypes());
		!isLoading &&
			axios
				.get(`content-object-types.json`)
				.then((response) => {
					return dispatch(fetchContentObjectTypesSuccess(response.data));
				})
				.catch((err) => {
					dispatch(fetchContentObjectTypesFailure(err));
				});
	};
}