import React, { Component } from "react";
import "./AssignmentListItem.css";
import { List, Icon } from "semantic-ui-react";
import StatusPill from "../../../components/StatusPill/StatusPill";
import moment from "moment";
import { DateInput } from "semantic-ui-calendar-react";

class AssignmentListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      writerDueDate: this.props.article.writer_planned_duedate
        ? moment(this.props.article.writer_planned_duedate).format("YYYY-MM-DD")
        : "",
      picking: false
    };
    this.datePickerRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.picking) {
      if (this.datePickerRef.current.state.popupIsClosed) {
        setTimeout(() => this.setState({ picking: false }), 100);
      }
    }
  }

  handleSelectChange = (event, { name, value }) => {
    const article = this.props.article;
    let date = value !== "" ? value : null;

    this.props.updateArticleStatus(
      article.id,
      // article.status.order,
      article.status.uid,
      null,
      {scheduled_date: date}
      // date
    );

    if (this.state[name] !== value) {
      return this.setState({
        [name]: value ? moment(value).format("MMM Do") : ""
      });
    }
  };

  render() {
    const { article, selected } = this.props;
    return (
      <React.Fragment>
        {!this.props.completed ? (
          <List.Item
            onClick={() =>
              setTimeout(
                () => !this.state.picking && this.props.handleSelect(article),
                100
              )
            }
            className={`selectable ${selected && "selected"}`}
          >
            <div className="AssignmentListItem">
              <div className="AssignmentListItemContent">{article.title}</div>
              <div className="AssignmentListItemMeta">
                {(article.status.uid === 'editing' || article.status.uid === 'reedit') &&
                  !this.props.readOnly &&
                  article.duedate_edit && (
                    <div className="AssignmentListItemMeta--item assignmentDueDate">
                      <span className="dd">
                        <Icon color="grey" name="calendar alternate" />
                        <span className="dd-due">Due:</span>
                        <span className="dd-date">
                          {moment(article.duedate_edit).format(
                            "MMM Do"
                          )}
                        </span>
                      </span>
                    </div>
                  )}
                {article.content_type && (
                  <div className="AssignmentListItemMeta--item assignmentPlanningYearMonth">
                    <Icon name="write square" />
                    <span className="AssignmentListItemMeta--item__content">
                      {article.content_type.name}
                    </span>
                  </div>
                )}
                {article.planning_year_month && article.planning_year_month.id && (
                  <div className="AssignmentListItemMeta--item assignmentPlanningYearMonth">
                    <Icon name="calendar outline" />
                    <span className="AssignmentListItemMeta--item__content">{`${moment(
                      article.planning_year_month.month,
                      "MM"
                    ).format("MMMM")} ${
                      article.planning_year_month.year
                    }`}</span>
                    {article.milestone && (
                      <span> - Milestone {article.milestone}</span>
                    )}
                  </div>
                )}
                {article.project && article.project.id && (
                  <div className="AssignmentListItemMeta--item assignmentPlanningYearMonth">
                    <StatusPill
                      content={article.project.name}
                      color="#07c3f2"
                    />
                  </div>
                )}
                {article.client && !article.project.id && (
                  <div className="AssignmentListItemMeta--item assignmentStatus">
                    <StatusPill
                      content={article.client.dba_name}
                      color={article.status.color}
                    />
                  </div>
                )}
                {(article.status.uid === 'writing' || article.status.uid === 'rewrite') &&
                  !this.props.readOnly && (
                    <div className="AssignmentListItemMeta--item assignmentDueDate">
                      <div
                        onClick={() => this.setState({ picking: true })}
                        className="customDatepicker"
                      >
                        <DateInput
                          ref={this.datePickerRef}
                          transparent
                          size="mini"
                          closable
                          clearable
                          placeholder="Schedule"
                          name="writerDueDate"
                          iconPosition="left"
                          dateFormat="YYYY-MM-DD"
                          value={this.state.writerDueDate}
                          onChange={this.handleSelectChange}
                        />
                      </div>
                      <span className="dd">
                        <Icon color="grey" name="calendar alternate" />
                        <span className="dd-due">Due:</span>
                        <span className="dd-date">
                          {moment(article.duedate_write).format("MMM Do")}
                        </span>
                      </span>
                    </div>
                  )}
              </div>
            </div>
          </List.Item>
        ) : (
          <List.Item
            onClick={
              this.props.readOnly
                ? () =>
                    setTimeout(
                      () =>
                        !this.state.picking && this.props.handleSelect(article),
                      100
                    )
                : null
            }
            className={
              this.props.readOnly
                ? `selectable ${selected && "selected"}`
                : `completed`
            }
          >
            <div className="AssignmentListItem">
              <div className="AssignmentListItemContent">{article.title}</div>
              <div className="AssignmentListItemMeta">
                {article.content_type && (
                  <div className="AssignmentListItemMeta--item assignmentPlanningYearMonth">
                    <Icon name="write square" />
                    <span className="AssignmentListItemMeta--item__content">
                      {article.content_type.name}
                    </span>
                  </div>
                )}
                {article.planning_year_month && article.planning_year_month.id && (
                  <div className="AssignmentListItemMeta--item assignmentPlanningYearMonth">
                    <Icon name="calendar outline" />
                    <span className="AssignmentListItemMeta--item__content">{`${moment(
                      article.planning_year_month.month,
                      "MM"
                    ).format("MMMM")} ${
                      article.planning_year_month.year
                    }`}</span>
                  </div>
                )}
                <div className="AssignmentListItemMeta--item assignmentStatus">
                  <StatusPill
                    content={article.status.name}
                    color={article.status.color}
                  />
                </div>
                {!this.props.readOnly && (
                  <div className="AssignmentListItemMeta--item assignmentDueDate">
                    <span className="dd">Completed</span>
                    <Icon color="grey" name="check" />
                  </div>
                )}
              </div>
            </div>
          </List.Item>
        )}
      </React.Fragment>
    );
  }
}

export default AssignmentListItem;
