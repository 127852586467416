import React, { Component } from "react";
import "./DimmedLoader.css";
import { Dimmer, Loader, Header } from "semantic-ui-react";

class DimmedLoader extends Component {
  state = {
    interactiveText: "",
    timeToWait: 3000
  };

  componentDidMount() {
    if (this.props.interactiveText && this.props.interactiveText[0]) {
      this.setState({
        interactiveText: this.props.interactiveText[0].text,
        timeToWait: this.props.interactiveText[0].time
      });
      this.props.interactiveText.slice(1).sort((a, b) => a.order - b.order).forEach(text => {
         setTimeout(() => {
          this.setState({ interactiveText: text.text });
          this.setState({ timeToWait: this.state.timeToWait + text.time });
        }, text.time );
      });
    }
  }
  render() {
    return (
      <Dimmer active inverted>
        <Loader
          size={this.props.size ? this.props.size : "small"}
          inverted
          indeterminate
        >
          <Header>
            {this.props.text ? this.props.text : "Loading"}
            <Header.Subheader>{this.props.interactiveText && this.state.interactiveText}</Header.Subheader>
          </Header>
        </Loader>
      </Dimmer>
    );
  }
}

export default DimmedLoader;
