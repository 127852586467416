import React from 'react';
import './PresentationCreatorPreviewComponent.css';
import { dynamicComponents } from '../../../../dynamicComponentMap';

const PresentationCreatorPreviewComponent = ({ componentData, handleStartLoad, handleFinishLoad }) => {
	const foundComponent = dynamicComponents.find((c) => c.name === componentData.name);
	const TagName = foundComponent ? foundComponent.component : null;

	return (
		<div className="PresentationCreatorPreviewComponent">
			{TagName ? (
				<TagName
					previewing
					handleStartLoad={handleStartLoad}
					handleFinishLoad={handleFinishLoad}
					{...foundComponent.attribute_fields.reduce((result, field) => {
						const dataField = componentData.fields.find((f) => f.name === field.attributeName);
						const data = dataField ? dataField.data : null;
						result[field.attributeName] = data;
						return result;
					}, {})}
				/>
			) : (
				<p>Component Not Found</p>
			)}
		</div>
	);
};

export default PresentationCreatorPreviewComponent;
