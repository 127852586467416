import React from "react";
import "./FeedbackDetail.css";
import { Card } from "semantic-ui-react";
import moment from "moment";
import { Icon } from "semantic-ui-react";

export default function FeedbackDetail(props) {
  let { feedback, user } = props;

  function renderStars(starRating) {
    var result = [];
    for (var i = 1; i <= starRating; i++) {
      result.push(
        <span key={i} className="icon">
          <Icon name="star" />
        </span>
      );
    }
    return result;
  }

  function renderName(name) {
    if (feedback.given_by && user) {
      const feedbackGiver = feedback.given_by;
      const feedbackGiverOrg =
        feedbackGiver.user_preferences &&
        feedbackGiver.user_preferences.default_organization;
      const userOrg =
        user.user_preferences && user.user_preferences.default_organization;

      if (feedback.given_by.id === user.id) {
        return name;
      } else if (
        feedbackGiverOrg &&
        userOrg &&
        feedbackGiverOrg.id === userOrg.id
      ) {
        return name;
      } else if (user.is_staff) {
        return name;
      } else if (feedback.feedback_type === "FinalReviewer") {
        return "Final Reviewer";
      } else {
        return "Editor";
      }
    } else {
      return name;
    }
  }

  function shouldShowFeedback() {
    const feedbackGiver = feedback.given_by;
    const feedbackGiverOrg =
      feedbackGiver.user_preferences &&
      feedbackGiver.user_preferences.default_organization;
    const userOrg = user && user.user_preferences && user.user_preferences.default_organization;

    if (user && user.is_staff) {
      return true;
    } else if (
      feedbackGiverOrg &&
      userOrg &&
      feedbackGiverOrg.id === userOrg.id
    ) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div className="FeedbackDetail">
      {shouldShowFeedback() && <Card fluid color={feedback.approved ? "green" : "red"}>
        <Card.Content>
          <Card.Header>
            <div className="FeedbackDetail--header">
              <span className="FeedbackDetail--author">
                {renderName(feedback.given_by.name)}
              </span>
              <span
                className={`FeedbackDetail--status ${
                  feedback.approved ? "approved" : "rejected"
                }`}
              >
                {feedback.approved ? "Approved" : "Rejected"}
              </span>
            </div>
          </Card.Header>
          <Card.Meta>
            <span className="FeedbackDetail-date">
              {moment(feedback.date_created).format("MMM Do, YYYY")}
            </span>
          </Card.Meta>
          <Card.Description>
            <div className="FeedbackDetail--rating">
              {renderStars(feedback.satisfaction)}
            </div>
            <p className="FeedbackDetail--body">{feedback.feedback_body}</p>
          </Card.Description>
        </Card.Content>
      </Card>}
    </div>
  );
}
