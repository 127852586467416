import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
	slideDeckTemplates: {
		data: [],
		isAdding: false,
		isLoading: false,
		isLoaded: false,
		errors: null,
		isCreatingPresentationTemplate: false,
		newPresentationTemplateOrganizationId: null,
		presentationTemplateErrors: null,
	},
	slide_templates: {
		data: [],
		isAdding: false,
		isLoading: false,
		isLoaded: false,
		errors: null
	},
	slideDecks: {
		data: [],
		isAdding: false,
		isLoading: false,
		isLoaded: false,
		errors: null
	},
	slides: {
		data: [],
		isAdding: false,
		isLoading: false,
		isLoaded: false,
		errors: null
	},
	messages: []
};

const addMessage = (state, action) => {
	return updateObject(state, {
		messages: state.messages.concat(action.message)
	});
};

const removeMessage = (state, action) => {
	let messagesList = state.messages.slice();
	messagesList.splice(0, 1);
	return updateObject(state, { messages: messagesList });
};

// Slide Deck Templates
const startSlideDeckTemplateFetch = (state, action) => {
	return updateObject(state, {
		slideDeckTemplates: {
			...state.slideDeckTemplates,
			isLoading: true,
			isLoaded: false,
			errors: null
		}
	});
};
const setSlideDeckTemplates = (state, action) => {
	return updateObject(state, {
		slideDeckTemplates: {
			...state.slideDeckTemplates,
			data: action.slideDeckTemplates,
			isLoading: false,
			isLoaded: true,
			isAdding: false,
			errors: null
		}
	});
};
const startAddSlideDeckTemplate = (state, action) => {
	return updateObject(state, {
		slideDeckTemplates: {
			...state.slideDeckTemplates,
			isAdding: true,
			errors: null
		}
	});
};
const addSlideDeckTemplateSuccess = (state, action) => {
	return updateObject(state, {
		slideDeckTemplates: {
			...state.slideDeckTemplates,
			data: state.slideDeckTemplates.data.concat(action.slideDeckTemplate),
			newSlideDeckTemplate: action.slideDeckTemplate,
			isAdding: false,
			errors: null
		}
	});
};

const startUpdateSlideDeckTemplate = (state, action) => {
	return updateObject(state, {
		slideDeckTemplates: {
			...state.slideDeckTemplates,
			data: state.slideDeckTemplates.data.map(
				(d, i) =>
					i === action.index
						? {
								...d,
								isUpdating: true
							}
						: d
			),
			errors: null
		}
	});
};

const updateSlideDeckTemplateSuccess = (state, action) => {
	return updateObject(state, {
		slideDeckTemplates: {
			...state.slideDeckTemplates,
			data: state.slideDeckTemplates.data.map(
				(d, i) =>
					i === action.index
						? {
								...action.slideDeckTemplate,
								isUpdating: false
							}
						: d
			),
			errors: null
		}
	});
};
const deleteSlideDeckTemplateSuccess = (state, action) => {
	const slideDeckTemplates = state.slideDeckTemplates.data.slice();
	slideDeckTemplates.splice(action.index, 1);
	return updateObject(state, {
		slideDeckTemplates: {
			...state.slideDeckTemplates,
			data: slideDeckTemplates,
			errors: null
		}
	});
};
const slideDeckTemplateError = (state, action) => {
	return updateObject(state, {
		slideDeckTemplates: {
			...state.slideDeckTemplates,
			errors: action.errors
		}
	});
};

// Slide Templates
const startSlideTemplateFetch = (state, action) => {
	return updateObject(state, {
		slide_templates: {
			...state.slide_templates,
			isLoading: true,
			isLoaded: false,
			errors: null
		},
		slideDeckTemplates: {
			...state.slideDeckTemplates,
			data: state.slideDeckTemplate.data.map(
				(d, i) =>
					i === action.action.slideDeckIndex
						? {
								...d,
								slideTemplatesLoading: true,
								slideTemplatesLoaded: false
							}
						: d
			)
		}
	});
};
const setSlideTemplates = (state, action) => {
	return updateObject(state, {
		slide_templates: {
			...state.slide_templates,
			data: action.slide_templates,
			isLoading: false,
			isLoaded: true,
			errors: null
		},
		slideDeckTemplates: {
			...state.slideDeckTemplates,
			data: state.slideDeckTemplate.data.map(
				(d, i) =>
					i === action.action.slideDeckIndex
						? {
								...d,
								slide_templates: action.slide_templates,
								slideTemplatesLoading: false,
								slideTemplatesLoaded: true
							}
						: d
			)
		}
	});
};
const startAddSlideTemplate = (state, action) => {
	return updateObject(state, {
		slide_templates: {
			...state.slide_templates,
			isAdding: true,
			errors: null
		},
		slideDeckTemplates: {
			...state.slideDeckTemplates,
			data: state.slideDeckTemplate.data.map(
				(d, i) =>
					i === action.action.slideDeckIndex
						? {
								...d,
								addingSlideTemplate: true
							}
						: d
			)
		}
	});
};
const addSlideTemplateSuccess = (state, action) => {
	return updateObject(state, {
		slide_templates: {
			...state.slide_templates,
			data: action.slide_templates,
			errors: null
		},
		slideDeckTemplates: {
			...state.slideDeckTemplates,
			data: state.slideDeckTemplate.data.map(
				(d, i) =>
					i === action.action.slideDeckIndex
						? {
								...d,
								slide_templates: action.slide_templates,
								addingSlideTemplate: false
							}
						: d
			)
		}
	});
};
const updateSlideTemplateSuccess = (state, action) => {
	return updateObject(state, {
		slide_templates: {
			...state.slide_templates,
			data: action.slide_templates,
			errors: null
		},
		slideDeckTemplates: {
			...state.slideDeckTemplates,
			data: state.slideDeckTemplate.data.map(
				(d, i) =>
					i === action.action.slideDeckIndex
						? {
								...d,
								slide_templates: action.slide_templates,
								addingSlideTemplate: false
							}
						: d
			)
		}
	});
};
const deleteSlideTemplateSuccess = (state, action) => {
	let slide_templates = state.slide_templates.data.slice();
	slide_templates.splice(action.index, 1);
	return updateObject(state, {
		slide_templates: {
			...state.slide_templates,
			data: slide_templates,
			errors: null
		}
	});
};
const slideTemplateError = (state, action) => {
	return updateObject(state, {
		slide_templates: {
			...state.slide_templates,
			errors: action.errors
		}
	});
};

// Slide Decks

const startSlideDeckFetch = (state, action) => {
	return updateObject(state, {
		slideDecks: {
			...state.slideDecks,
			data: [],
			isLoading: true,
			isLoaded: false,
			errors: null
		}
	});
};
const setSlideDecks = (state, action) => {
	return updateObject(state, {
		slideDecks: {
			...state.slideDecks,
			data: state.slideDecks.data.concat(action.slideDecks),
			isLoading: false,
			isLoaded: true,
			errors: null
		}
	});
};
const slideDeckError = (state, action) => {
	return updateObject(state, {
		slideDecks: {
			...state.slideDecks,
			errors: action.errors
		}
	});
};

const startSlideFetch = (state, action) => {
	return updateObject(state, {
		slides: {
			...state.slides,
			isLoading: true,
			isLoaded: false,
			errors: null
		}
	});
};
const setSlides = (state, action) => {
	return updateObject(state, {
		slides: {
			...state.slides,
			isLoading: false,
			isLoaded: true,
			errors: null
		}
	});
};
const slideError = (state, action) => {
	return updateObject(state, {
		slides: {
			...state.slides,
			errors: null
		}
	});
};

const startCreateReportPresentationTemplateFromMaster = (state, action) => {
	return updateObject(state, {
		slideDeckTemplates: {
			...state.slideDeckTemplates,
			isCreatingPresentationTemplate: true
		}
	});
};

const createReportPresentationTemplateFromMasterSuccess = (state, action) => {
	return updateObject(state, {
		slideDeckTemplates: {
			...state.slideDeckTemplates,
			data: state.slideDeckTemplates.data.concat(action.presentationTemplate),
			isCreatingPresentationTemplate: false,
			newPresentationTemplateOrganizationId: action.orgId
		}
	});
};

const createReportPresentationTemplateFromMasterFailure = (state, action) => {
	return updateObject(state, {
		slideDeckTemplates: {
			...state.slideDeckTemplates,
			isCreatingPresentationTemplate: false,
			presentationTemplateErrors: action.errors
		}
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.ADD_MESSAGE:
			return addMessage(state, action);
		case actionTypes.REMOVE_MESSAGE:
			return removeMessage(state, action);
		// Slide Deck Templates
		case actionTypes.START_SLIDE_DECK_TEMPLATE_FETCH:
			return startSlideDeckTemplateFetch(state, action);
		case actionTypes.SET_SLIDE_DECK_TEMPLATES:
			return setSlideDeckTemplates(state, action);
		case actionTypes.START_ADD_SLIDE_DECK_TEMPLATE:
			return startAddSlideDeckTemplate(state, action);
		case actionTypes.ADD_SLIDE_DECK_TEMPLATE_SUCCESS:
			return addSlideDeckTemplateSuccess(state, action);
		case actionTypes.START_UPDATE_SLIDE_DECK_TEMPLATE:
			return startUpdateSlideDeckTemplate(state, action);
		case actionTypes.UPDATE_SLIDE_DECK_TEMPLATE_SUCCESS:
			return updateSlideDeckTemplateSuccess(state, action);
		case actionTypes.DELETE_SLIDE_DECK_TEMPLATE_SUCCESS:
			return deleteSlideDeckTemplateSuccess(state, action);
		case actionTypes.SLIDE_DECK_TEMPLATE_ERROR:
			return slideDeckTemplateError(state, action);
		// Slide Templates
		case actionTypes.START_SLIDE_TEMPLATE_FETCH:
			return startSlideTemplateFetch(state, action);
		case actionTypes.SET_SLIDE_TEMPLATES:
			return setSlideTemplates(state, action);
		case actionTypes.START_ADD_SLIDE_TEMPLATE:
			return startAddSlideTemplate(state, action);
		case actionTypes.ADD_SLIDE_TEMPLATE_SUCCESS:
			return addSlideTemplateSuccess(state, action);
		case actionTypes.UPDATE_SLIDE_TEMPLATE_SUCCESS:
			return updateSlideTemplateSuccess(state, action);
		case actionTypes.DELETE_SLIDE_TEMPLATE_SUCCESS:
			return deleteSlideTemplateSuccess(state, action);
		case actionTypes.SLIDE_TEMPLATE_ERROR:
			return slideTemplateError(state, action);
		// Slide Decks
		case actionTypes.START_SLIDE_DECK_FETCH:
			return startSlideDeckFetch(state, action);
		case actionTypes.SET_SLIDE_DECKS:
			return setSlideDecks(state, action);
		case actionTypes.SLIDE_DECK_ERROR:
			return slideDeckError(state, action);
		// Slides
		case actionTypes.START_SLIDE_FETCH:
			return startSlideFetch(state, action);
		case actionTypes.SET_SLIDES:
			return setSlides(state, action);
		case actionTypes.SLIDE_ERROR:
			return slideError(state, action);
		case actionTypes.START_CREATE_REPORT_PRESENTATION_FROM_MASTER:
			return startCreateReportPresentationTemplateFromMaster(state, action);
		case actionTypes.CREATE_REPORT_PRESENTATION_FROM_MASTER_SUCCESS:
			return createReportPresentationTemplateFromMasterSuccess(state, action);
		case actionTypes.CREATE_REPORT_PRESENTATION_FROM_MASTER_FAILURE:
			return createReportPresentationTemplateFromMasterFailure(state, action);
		default:
			return state;
	}
};

export default reducer;
