import React, { Component } from 'react';
import { Button, Icon, Modal, Header } from 'semantic-ui-react';

export default class ConfirmationModal extends Component {
	state = { modalOpen: false };

	componentDidMount() {
		this.props.open && this.setState({ modalOpen: true });
	}

	handleOpen = () => this.setState({ modalOpen: true });

	handleClose = (confirm) => {
		if (confirm === true) {
			this.props.handleConfirm(this.props.isReWrite ? true : false);
		}
		this.setState({ modalOpen: false });
	};

	render() {
		return (
			<Modal
				trigger={
					this.props.children ? (
						<div className="ConfirmationModal--trigger" onClick={this.handleOpen}>
							{this.props.children}
						</div>
					) : (
						<Button
							icon={this.props.buttonIcon ? true : false}
							size={this.props.buttonSize}
							color={this.props.color}
							basic={this.props.basic}
							compact={this.props.compact}
							onClick={this.handleOpen}
							inverted={this.props.buttonInverted}
							className={this.props.buttonClass}
						>
							{this.props.buttonText && this.props.buttonText}{' '}
							{this.props.buttonIcon && <Icon name={this.props.buttonIcon} />}
						</Button>
					)
				}
				open={this.state.modalOpen}
				onClose={this.handleClose}
				size="mini"
			>
				<Modal.Header>
					<Header icon={this.props.headerIcon && this.props.headerIcon} content={this.props.headerText} />
				</Modal.Header>
				<Modal.Content>
					<p>{this.props.bodyText}</p>
				</Modal.Content>
				<Modal.Actions>
					<Button onClick={() => this.handleClose()} negative>
						No
					</Button>
					<Button
						positive
						icon="checkmark"
						onClick={() => this.handleClose(true)}
						labelPosition="right"
						content="Yes"
					/>
				</Modal.Actions>
			</Modal>
		);
	}
}
