import React, { Component } from "react";
import { connect } from "react-redux";
import { users, contentGeneration } from "../../store/actions";
import { Form, Button, Transition, Loader, Dimmer, Message } from "semantic-ui-react";
import "./UserReAssignForm.css";
import FormMessage from "../../components/FormMessage/FormMessage";

const initialState = {
  prevUserId: "",
  newUserId: "",
};

class UserReAssignForm extends Component {
  state = {
    form: initialState,
  };

  componentDidMount() {
    !this.props.users[0] && this.props.fetchUsers();
    this.props.prevUser &&
      this.setState({
        form: {
          ...this.state.form,
          prevUserId: this.props.prevUser.id,
        },
      });
  }

  clearForm = () => {
    this.setState({ form: initialState });
  };

  handleChange(e) {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      },
    });
  }

  handleSelectChange = (event, { name, value }) => {
    this.setState({ form: { ...this.state.form, [name]: value } });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.form.prevUserId && this.state.form.newUserId) {
      this.props.reAssignArticles(
        this.state.form.prevUserId,
        this.state.form.newUserId
      );
    }
  };

  render() {
    return (
      <div className="UserReAssignForm">
        {(this.props.reAssigningInProgress || this.props.isDeletingUser) && (
          <Dimmer active inverted>
            <Loader>
              {this.props.reAssigningInProgress
                ? "Re-Assigning Content"
                : "Deleting User"}
            </Loader>
          </Dimmer>
        )}
        <Transition.Group animation="slide down" duration={500}>
          {this.props.errors &&
            this.props.errors[0] &&
            this.props.errors.map((err) => <FormMessage>{err}</FormMessage>)}
        </Transition.Group>
        {this.props.showSuccessMessage && this.props.reAssignMessage ? (
            <div className="UserReAssignForm-successMessage">
                <Message icon="checkmark" header="Articles Successfully Re-Assigned!" positive list={[
                    `${this.props.reAssignMessage.article_update_count} Articles Updated.`,
                    `${this.props.reAssignMessage.template_update_count} Templates Updated.`
                ]} />
            </div>
        ) : <Form size={this.props.size} className="UserReAssignForm--form">
          <Form.Group widths="equal">
            <Form.Field width={5}>
              <label htmlFor="prevUserId">Previous User</label>
              <Form.Select
                id="prevUserId"
                selection
                disabled={this.props.prevUser ? true : false}
                search
                required
                name="prevUserId"
                placeholder="Previous User"
                value={this.state.form.prevUserId}
                onChange={this.handleSelectChange}
                options={
                  this.props.users[0] &&
                  this.props.users.map((user) => ({
                    key: user.id,
                    text: user.name,
                    value: user.id,
                  }))
                }
              />
            </Form.Field>
            <Form.Field width={5}>
              <label htmlFor="newUserId">New User</label>
              <Form.Select
                id="newUserId"
                selection
                search
                required
                name="newUserId"
                placeholder="New User"
                value={this.state.form.newUserId}
                onChange={this.handleSelectChange}
                options={
                  this.props.users[0] &&
                  this.props.users
                    .filter(
                      (user) =>
                        this.state.form.prevUserId &&
                        user.id !== this.state.form.prevUserId
                    )
                    .map((user) => ({
                      key: user.id,
                      text: user.name,
                      value: user.id,
                    }))
                }
              />
            </Form.Field>
          </Form.Group>
          {this.props.withActions && (
            <section className="UserReAssignForm--actions">
              <Button
                onClick={(e) => this.handleSubmit(e)}
                positive
                disabled={!this.state.form.newUserId ? true : false}
              >
                Re-Assign Content
              </Button>
              <Button
                onClick={() => this.props.handleCancelReAssign()}
                negative
              >
                Cancel
              </Button>
            </section>
          )}
        </Form>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    users: state.users.users,
    reAssigningInProgress: state.contentGeneration.reAssigningInProgress,
    didReAssign: state.contentGeneration.didReAssign,
    reAssignMessage: state.contentGeneration.reAssignMessage,
    isDeletingUser: state.users.isDeletingUser,
    didDeleteUser: state.users.didDeleteUser,
    errors: state.users.errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsers: () => dispatch(users.fetchUsers()),
    reAssignArticles: (prevUserId, newUserId) =>
      dispatch(contentGeneration.reAssignArticles(prevUserId, newUserId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserReAssignForm);
