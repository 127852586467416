import React, { Component } from "react";
import { connect } from "react-redux";
import { notifications } from "../../store/actions";
import "./NotificationStack.css";
import { Transition, Header } from "semantic-ui-react";

class NotificationStack extends Component {
  // componentDidMount() {
  //   this.props.updateServiceWorker();
  // }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.notifications !== prevProps.locations &&
      this.props.notifications[0]
    ) {
      this.props.notifications.forEach((notification, i) => {
        setTimeout(() => {
          this.props.dismissNotification(i);
        }, notification.dismissAfter);
      });
    }
  }

  render() {
    return (
      <div className="NotificationStack">
        <Transition.Group animation="slide right" duration={800}>
          {this.props.notifications.map((notification, i) => (
            <figure key={i} className="NotificationCard">
              <div className="NotificationCard--heading">
                <Header className="NotificationCard--header" as="h5">
                  {notification.heading}
                </Header>
                <span
                  onClick={() => this.props.dismissNotification(i)}
                  className="NotificationCard--close"
                >
                  &times;
                </span>
              </div>
              <p className="NotificationCard--content">
                {notification.message}
              </p>
            </figure>
          ))}
        </Transition.Group>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    notifications: state.notifications.notifications
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateServiceWorker: () => {
      dispatch(notifications.updateServiceWorker());
    },
    dismissNotification: index => {
      return dispatch(notifications.dismissNotification(index));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationStack);
