import React, { Component } from 'react';
import './PresentationComponent.css';
import { dynamicComponents } from '../../../dynamicComponentMap';
import { Loader, Image } from 'semantic-ui-react';
import htmlToImage from 'html-to-image';

class PresentationComponent extends Component {
	state = {
		convertedImage: null
	};
	dynamicComponent = React.createRef();

	componentDidMount() {
		if (this.props.withPDF) {
			if (!this.props.loadingData && !this.props.loadingObjectInstance) {
				this.dynamicComponent.current.focus();
				setTimeout(() => this.convertComponentToImage(this.dynamicComponent.current), 1000);
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.withPDF) {
			if (!this.props.loadingData && prevProps.loadingData) {
				this.dynamicComponent.current.focus();
				setTimeout(() => this.convertComponentToImage(this.dynamicComponent.current), 1000);
			}
		}
	}

	saveImageToState = (dataUrl) => {
		this.setState({ convertedImage: dataUrl });
	};

	convertComponentToImage = async (node) => {
		const imgData = htmlToImage
			.toPng(node)
			.then(function(dataUrl) {
				return dataUrl;
			})
			.catch(function(error) {
				console.error('oops, something went wrong!', error);
				return;
			});
		const data = await imgData;
		return data && this.saveImageToState(data);
	};

	shouldShowConvertedImage = (component) => {
		if (component && component.PDFConvertToImage && this.state.convertedImage) {
			return true;
		} else {
			return false;
		}
	};

	render() {
		const {
			loadingData,
			component,
			presentation,
			handleStartLoad,
			handleFinishLoad,
			objectInstance,
			loadingObjectInstance,
			storePresentationData,
			storedData,
			withPDF
		} = this.props;
		const foundComponent = dynamicComponents.find((c) => c.name === component.name);
		const TagName = foundComponent ? foundComponent.component : null;

		return (
			<div className="PresentationComponent">
				{!loadingObjectInstance ? TagName ? (
					<React.Fragment>
						<div
							ref={this.dynamicComponent}
							className={`PresentationComponent--container${component &&
								component.PDFConvertToImage ?
								' toConvert' : ''}${this.shouldShowConvertedImage(foundComponent) && ' withConvertedImage'}`}
						>
							<TagName
								withPDF={withPDF}
								data={objectInstance}
								loadingData={loadingData}
								presentation={presentation}
								handleStartLoad={handleStartLoad}
								handleFinishLoad={handleFinishLoad}
								storePresentationData={storePresentationData}
								storedData={storedData}
								{...foundComponent.attribute_fields.reduce((result, field) => {
									const dataField = component.fields.find((f) => f.name === field.attributeName);
									const data = dataField ? dataField.data : null;
									result[field.attributeName] = data;
									return result;
								}, {})}
							/>
						</div>
						{this.shouldShowConvertedImage(foundComponent) && (
							<div className="PresentationComponent--convertedImage">
								<Image src={this.state.convertedImage} alt={foundComponent.verbose_name} />
							</div>
						)}
					</React.Fragment>
				) : (
					<p>Component Not Found</p>
				) : (
					<Loader active>Loading...</Loader>
				)}
			</div>
		);
	}
}

export default PresentationComponent;
