import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
	contentObjectTypes: {
		data: [],
		isLoading: false,
		isLoaded: false,
		errors: null
	},
	messages: []
};

const startFetchContentObjectTypes = (state, action) => {
	return updateObject(state, {
		contentObjectTypes: {
			...state.contentObjectTypes,
			isLoading: true,
			isLoaded: false
		}
	});
};
const fetchContentObjectTypesSuccess = (state, action) => {
	return updateObject(state, {
		contentObjectTypes: {
			...state.contentObjectTypes,
			data: state.contentObjectTypes.data.concat(action.contentObjectTypes),
			isLoading: false,
			isLoaded: true
		}
	});
};
const fetchContentObjectTypesFailure = (state, action) => {
	return updateObject(state, {
		contentObjectTypes: {
			...state.contentObjectTypes,
			isLoading: false,
			isLoaded: false,
			errors: action.errors
		}
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.START_FETCH_CONTENT_OBJECT_TYPES:
			return startFetchContentObjectTypes(state, action);
		case actionTypes.FETCH_CONTENT_OBJECT_TYPES_SUCCESS:
			return fetchContentObjectTypesSuccess(state, action);
		case actionTypes.FETCH_CONTENT_OBJECT_TYPES_FAILURE:
			return fetchContentObjectTypesFailure(state, action);
		default:
			return state;
	}
};

export default reducer;
