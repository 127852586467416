import React, {Component } from "react";
import { Dropdown } from "semantic-ui-react";
import { contentGeneration } from "../../store/actions";
import { connect } from "react-redux";
import moment from "moment";

class PlanningMonthSelect extends Component {

  handleSelect = (event, { value }) => {
      const url = `/content-generation/articles/${value}`;
      this.props.history.push(url);
  };

  render() {
    return (
      <div>
        {this.props.planningMonths[0] && (
          <Dropdown
            selectOnBlur={false}
            selection
            labeled
            button
            loading={this.props.isLoading ? true : false}
            className="icon"
            icon="calendar"
            size="tiny"
            placeholder={this.props.placeholder}
            options={this.props.planningMonths.map(month => ({
              key: month.id,
              text: `${moment(month.month, "MM").format("MMMM")} ${month.year}`,
              value: `${month.year}/${month.month}`
            }))}
            onChange={this.handleSelect}
          />
        )}
      </div>
    );
  }
};

const mapStateToProps = state => {
  return {
    planningMonths: state.contentGeneration.planningMonths,
    isAddingMonth: state.contentGeneration.isAddingMonth,
    isLoading: state.contentGeneration.isLoadingArticles,
    addedMonth: state.contentGeneration.addedMonth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchContentArticles: () => {
      dispatch(contentGeneration.fetchContentArticles());
    },
    fetchPlanningMonths: () => {
      return dispatch(contentGeneration.fetchPlanningMonths());
    },
    addPlanningMonth: formData => {
      return dispatch(contentGeneration.addPlanningMonth(formData));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanningMonthSelect);
