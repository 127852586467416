import React, { Component } from 'react';
import './PasswordChangeForm.css';
import { auth, users } from '../../store/actions';
import { connect } from 'react-redux';
import ErrorHandler from '../ErrorHandler/ErrorHandler';
import { Form, Divider, Message } from 'semantic-ui-react';
import PageMessage from '../../components/PageMessage/PageMessage';

const initialState = {
	oldPassword: '',
	newPassword1: '',
	newPassword2: ''
};

class PasswordChangeForm extends Component {
	state = {
		form: initialState,
		successfullyUpdated: false
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.props.updatingPassword !== undefined) {
			if (this.props.updatingPassword === false && prevProps.updatingPassword === true) {
				this.setState({ ...this.state, successfullyUpdated: true });
			}
		}
	}

	handleSubmit = async (e) => {
		e.preventDefault();
		const handleChangePassword = () =>
			this.props.userID
				? this.props.changeUserPassword(
						this.props.userID,
						this.state.form.newPassword1,
						this.state.form.newPassword2,
						'User password updated.'
					)
				: this.props.changePassword(
						this.state.form.oldPassword,
						this.state.form.newPassword1,
						this.state.form.newPassword2
					);
		await handleChangePassword();
		return !this.props.errors && this.clearForm();
	};

	clearForm = () => {
		this.setState({ form: initialState });
  };
  
  handleChange(e) {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  }

	render() {
		return (
			<div className="PasswordChangeForm">
				{(this.props.errors || this.props.userErrors) && (
					<ErrorHandler errors={this.props.errors ? this.props.errors : this.props.userErrors} />
				)}
        {this.props.messages[0] && <PageMessage>{this.props.messages}</PageMessage>}
        {this.state.successfullyUpdated && this.props.userID ? (
          <div className="PasswordChangeForm-success">
            <Message positive icon="checkmark" header="User Password Updated!" content="Please make sure to let them know it has been changed and give them the new password." />
          </div>
        ) : (
          <Form size="small" onSubmit={this.handleSubmit}>
					{!this.props.userID && (
						<Form.Field inline>
							<label htmlFor="oldPassword">Current Password</label>
							<input
								name="oldPassword"
								type="password"
								placeholder="Current Password"
								id="oldPassword"
								value={this.state.form.oldPassword}
                onChange={(e) => this.handleChange(e)}
                disabled={this.props.updatingPassword}
							/>
						</Form.Field>
					)}
					<Form.Field inline>
						<label htmlFor="newPassword1">New Password</label>
						<input
							name="newPassword1"
							type="password"
							placeholder="New Password"
							id="newPassword1"
							value={this.state.form.newPassword1}
              onChange={(e) => this.handleChange(e)}
              disabled={this.props.updatingPassword}
						/>
					</Form.Field>
					<Form.Field inline>
						<label htmlFor="newPassword2">Confirm Password</label>
						<input
							name="newPassword2"
							type="password"
							placeholder="Confirm Password"
							id="newPassword2"
							value={this.state.form.newPassword2}
              onChange={(e) => this.handleChange(e)}
              disabled={this.props.updatingPassword}
						/>
					</Form.Field>
					{this.props.userID && <Divider />}
					<Form.Button
						disabled={this.props.updatingPassword}
						className="PasswordChangeForm-button"
						size="medium"
						type="submit"
						positive
					>
						Submit
					</Form.Button>
				</Form>
        )}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		errors: state.auth.errors,
		userErrors: state.users.errors,
		didUpdate: state.auth.didUpdate,
		isChangingPassword: state.auth.isChangingPassword,
		messages: state.auth.messages
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		changePassword: (oldPassword, newPassword1, newPassword2) => {
			return dispatch(auth.changePassword(oldPassword, newPassword1, newPassword2));
		},
		cancelPasswordChange: () => {
			return dispatch(auth.changePasswordCancel());
		},
		changeUserPassword: (id, newPassword1, newPassword2, message) => {
			return dispatch(users.changeUserPassword(id, newPassword1, newPassword2, message));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordChangeForm);
