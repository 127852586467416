import React from 'react';
import './PostingOverviewDetails.css';
import PostingOverviewDetailsBlock from './PostingOverviewDetailsBlock/PostingOverviewDetailsBlock';

const PostingOverviewDetails = ({ accountPosts, getCorrespondingAccountData }) => {
	const correspondingAccountData = getCorrespondingAccountData(
		accountPosts.account.id
	  );
	return (
		<div className="PostingOverviewDetails">
			{accountPosts && <PostingOverviewDetailsBlock posts={accountPosts.scheduled} title="Scheduled Posts" />}
			{accountPosts && (
				<PostingOverviewDetailsBlock
					posts={accountPosts.twelveWeeksBack}
					title="Posts from the last 12 weeks"
				/>
			)}
			{accountPosts && correspondingAccountData && (
				<PostingOverviewDetailsBlock
					posts={correspondingAccountData.overdueContentApprovals}
					title="Posts in Final Review Status"
					finalReview
					isLoading={correspondingAccountData.isFetchingOverdueContentApprovals}
				/>
			)}
		</div>
	);
};

export default PostingOverviewDetails;
