import React, { Component } from 'react';
import './GoogleAnalyticsChart.css';
import { BarChart, CartesianGrid, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { mockData } from './mockData';
import { alternateColorOptions } from './colorOptions';
import { Table, Icon, Header } from 'semantic-ui-react';

export default class GoogleAnalyticsChart extends Component {
	getTotal = (sources) => {
		let totalCount = 0;
		sources.forEach((source) => {
			totalCount += Number(source.value);
		});
		return totalCount;
	};

	render() {
		const data =
			this.props.data && !this.props.previewing
				? this.props.data.google_analytics_data ? this.props.data.google_analytics_data : null
				: mockData;
		const formattedData =
			data &&
			data.map((d) => ({
				...d,
				...d.sources.reduce((result, source) => {
					result[source.source] = Number(source.value);
					return result;
				}, {}),
				total: this.getTotal(d.sources)
			}));

		const mostLeadTypesIndex =  data && data[0] && data.reduce(function(maxI,el,i,arr) {return el.sources.length>arr[maxI].sources.length ? i : maxI;}, 0);

		// const dataSources = data && data[0] && data[0].sources.sort((a, b) => b.value - a.value).map((source) => source.source);
		const dataSources = data && data[mostLeadTypesIndex] && data[mostLeadTypesIndex].sources.sort((a, b) => b.value - a.value).map((source) => source.source);
		return (
			<div className="GoogleAnalyticsChart">
				{data ? (
					<div className="GoogleAnalyticsChart--container">
						<div className="GoogleAnalyticsChart--header">
							{this.props.chartDescription && <Header as="h5" content={this.props.chartDescription} />}
						</div>
						<div className="GoogleAnalyticsChart--chart">
						<div className="GoogleAnalyticsChart--chartLabel">
								<span className="GoogleAnalyticsChart--chartLabelText">Users</span>
							</div>
							<ResponsiveContainer width="100%" height={300}>
								<BarChart data={formattedData} barGap={3}>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="month" />
									<YAxis  />
									<Tooltip cursor={{ fill: 'transparent' }} />
									<Legend />
									{dataSources.map((source, i) => (
										<Bar
											stackId="x"
											key={source}
											dataKey={source}
											fill={alternateColorOptions[i]}
											fillOpacity={0.8}
											isAnimationActive={true}
										/>
									))}
								</BarChart>
							</ResponsiveContainer>
						</div>
						{!this.props.includeTable && (
							<div className="GoogleAnalyticsChart--table">
								<Table striped celled size="small">
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>Traffic Source</Table.HeaderCell>
											{formattedData.map((d) => (
												<Table.HeaderCell key={d.month}>{d.month}</Table.HeaderCell>
											))}
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{dataSources.map((source, index) => (
											<Table.Row key={source}>
												<Table.Cell>
													<div className="GoogleAnalyticsChart--table__source">
														<div
															style={{ background: alternateColorOptions[index] }}
															className="GoogleAnalyticsChart--colorLabel"
														/>
														<span>{source}</span>
													</div>
												</Table.Cell>
												{formattedData.map((d, i) => {
													const foundData = d.sources.find((s) => s.source === source);
													if (foundData) {
														return (
															foundData && (
																<Table.Cell key={i} textAlign='center'>{foundData.value}</Table.Cell>
															)
														);
													} else {
														return <Table.Cell key={i} textAlign='center'>0</Table.Cell>;
													}
												})}
											</Table.Row>
										))}
										<Table.Row className="GoogleAnalyticsChart--table__total">
											<Table.Cell>
												<div className="GoogleAnalyticsChart--table__source">
													<div className="GoogleAnalyticsChart--chartIcon">
														<Icon name="chart bar" />
													</div>
													<span>Total</span>
												</div>
											</Table.Cell>
											{formattedData.map((d, i) => {
												return <Table.Cell key={i} textAlign='center'>{d.total}</Table.Cell>;
											})}
										</Table.Row>
									</Table.Body>
								</Table>
							</div>
						)}
					</div>
				) : (
					<div className="GoogleAnalyticsChart-noData">
						<Header as="h2">
							<Icon name="question" />
							<Header.Content>
								There doesn't appear to be any data yet.
								<Header.Subheader>
									Please check back soon to view this analytics report.
								</Header.Subheader>
							</Header.Content>
						</Header>
					</div>
				)}
			</div>
		);
	}
}
