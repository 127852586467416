import React, { Component } from 'react';
import './PresentationCreatorSlideTemplate.css';
import { Step, Icon, Message, Form, Header, Segment } from 'semantic-ui-react';
import { dynamicComponents } from '../../../dynamicComponentMap';
import PresentationCreatorComponent from '../PresentationCreatorComponent/PresentationCreatorComponent';

class PresentationCreatorSlideTemplate extends Component {
	render() {
		const slideTemplate = this.props.slideTemplate;
		return (
			<div className="PresentationCreatorSlideTemplate">
				<Segment>
					<Header as="h4">
						<section className="PresentationCreatorSlideTemplate--title">
							<Form.Input
								id="title"
								name="title"
								type="text"
								transparent
								fluid
								onChange={(e) => this.props.handleTitleChange(e, this.props.index)}
								value={slideTemplate.title}
							/>
						</section>
					</Header>
				</Segment>
				<section className="PresentationCreatorSlideTemplate--main">
					{slideTemplate.components[0] ? (
						<section className="PresentationCreatorSlideTemplate--builder">
							{slideTemplate.components.map((comp, i) => (
								<PresentationCreatorComponent
									key={i}
									index={i}
									component={dynamicComponents.find((component) => component.name === comp.name)}
									componentData={comp}
									handleDeleteComponent={this.props.handleDeleteComponent}
									handleComponentDataChange={this.props.handleComponentDataChange}
									handleComponentMoveUp={i !== 0 && this.props.handleComponentMoveUp}
									handleComponentMoveDown={
										i !== slideTemplate.components.length - 1 && this.props.handleComponentMoveDown
									}
								/>
							))}
						</section>
					) : (
						<section className="PresentationCreatorSlideTemplate--empty">
							<div className="PresentationCreatorSlideTemplate--emptyMessage">
								<Message
									info
									header="This Slide doesn't have any Components yet."
									content="Begin by following the steps below to get started!"
								/>
							</div>
							<Step.Group
								size="tiny"
								className="PresentationCreatorSlideTemplate--steps"
								vertical
								ordered
								fluid
							>
								<Step>
									<Icon name="code" />
									<Step.Content>
										<Step.Title>Find a Component you would like to use</Step.Title>
										<Step.Description>
											Start by browsing the available Components from the Toolbar on the right
										</Step.Description>
									</Step.Content>
								</Step>
								<Step>
									<Icon name="mouse pointer" />
									<Step.Content>
										<Step.Title>Select a Component you want to use</Step.Title>
										<Step.Description>
											Once you've found the Component you want to use, click to add it to the
											slide.
										</Step.Description>
									</Step.Content>
								</Step>
								<Step>
									<Icon name="edit" />
									<Step.Content>
										<Step.Title>Configure the options.</Step.Title>
										<Step.Description>
											Click on the Component once it's been added to the slide to begin
											conifguring the various field options.
										</Step.Description>
									</Step.Content>
								</Step>
							</Step.Group>
						</section>
					)}
				</section>
			</div>
		);
	}
}

export default PresentationCreatorSlideTemplate;
