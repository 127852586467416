import React, { Component } from "react";
import { contentGeneration } from "../../store/actions";
import { connect } from "react-redux";
import { Form } from "semantic-ui-react";
import "./CommentForm.css";
import ErrorHandler from "../ErrorHandler/ErrorHandler";

const initialState = {
  comment: ""
};

class CommentForm extends Component {
  state = {
    form: initialState
  };

  clearForm = () => {
    this.setState({ form: initialState });
  };

  handleChange(e) {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  }

  handleSubmit = async e => {
    e.preventDefault();
    await this.props.addContentComment(
      this.state.form.comment,
      this.props.articleId
    );
    if (!this.props.errors) {
      this.clearForm();
    }
  };

  render() {
    return (
      <div className="CommentForm">
        <Form onSubmit={this.handleSubmit}>
          {this.props.errors && <ErrorHandler errors={this.props.errors} />}
          <Form.Field>
            <Form.TextArea
            placeholder="Enter comment here"
              onChange={e => this.handleChange(e)} 
              name="comment"
              value={this.state.form.comment}
            />
          </Form.Field>
          <Form.Button color="blue" size="mini" type="submit">
            Comment
          </Form.Button>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    errors: state.contentGeneration.errors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addContentComment: (comment, articleId) => {
      return dispatch(
        contentGeneration.addContentComment(comment, articleId)
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommentForm);
