import React, { Component } from "react";
import SortableTable from "../SortableTable/SortableTable";
import ActionHeader from "../ActionHeader/ActionHeader";
import { Button, Icon } from "semantic-ui-react";
import FixedLoader from "../../components/FixedLoader/FixedLoader";
import FormModal from "../../components/FormModal/FormModal";
import PlanningMonthForm from "../../components/PlanningMonthForm/PlanningMonthForm";

class ArticleTemplatesView extends Component {
  state = {
    selectedArticle: null
  };

  componentDidMount() {
    if (!this.props.contentData[0]) {
      this.props.fetchTemplates();
    }
  }

  render() {
    return (
      <div>
        <ActionHeader headerText="Planning Template - Monthly">
          <FormModal
            headerText="Add Planning Month"
            headerIcon="calendar alternate"
            actionTrigger={
              <Button icon labelPosition="left" basic>
                <Icon name="calendar alternate" /> Add Planning Month
              </Button>
            }
          >
            <PlanningMonthForm {...this.props} />
          </FormModal>
          {!this.props.isAddingTemplate && (
            <Button
              color="green"
              icon
              labelPosition="right"
              size="medium"
              onClick={() => this.props.handleAddingTemplate()}
            >
              <Icon name="add" />
              Add Template
            </Button>
          )}
        </ActionHeader>
        {!this.props.isLoading && this.props.isLoaded ? (
          <SortableTable
            selectedItem={this.props.templateToEdit}
            handleSelect={this.props.selectTemplate}
            tableData={this.props.contentData}
            originalTemplates={this.props.contentData}
            handleTemplateSelect={this.props.selectTemplate}
          />
        ) : (
          <FixedLoader />
        )}
      </div>
    );
  }
}

export default ArticleTemplatesView;
