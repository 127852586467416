import _ from "lodash";
import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import "./SortedTable.css";

export default class SortedTable extends Component {
  state = {
    column: null,
    data: this.props.data,
    direction: null,
    search: {
      isLoading: false,
      results: [],
      value: ""
    }
  };

  componentDidUpdate(prevProps, prevState) {
    this.props.data !== prevProps.data &&
      this.setState({ data: _.sortBy(this.props.data, [this.state.column]) });
  }

  // resetSearch = () =>
  //   this.setState({
  //     search: {
  //       isLoading: false,
  //       results: [],
  //       value: ""
  //     }
  //   });

  // handleResultSelect = (e, { result }) => {
  //   let selectedItem = this.state.data.find(d => d.id === result.key);
  //   this.props.handleSearchSelect(selectedItem);
  //   this.setState({ search: { value: result[this.props.primarySearchField] } });
  // };

  // handleSearchChange = (e, { value }) => {
  //   this.setState({
  //     search: {
  //       ...this.state.search,
  //       isLoading: true,
  //       value: value
  //     }
  //   });

  //   setTimeout(() => {
  //     if (this.state.search.value.length < 1) return this.resetSearch();

  //     const re = new RegExp(_.escapeRegExp(this.state.search.value), "i");
  //     console.log(`re is ${re}`)
  //     const isMatch = result => re.test(result[this.props.primarySearchField]);
  //     console.log(`isMatch is ${isMatch}`)

  //     this.setState({
  //       search: {
  //         ...this.state.search,
  //         isLoading: false,
  //         results: _.filter(this.state.data, isMatch)
  //       }
  //     });
  //   }, 300);
  // };

  handleSort = clickedColumn => () => {
    const { column, data, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: _.sortBy(data, [clickedColumn]),
        direction: "ascending"
      });

      return;
    }

    this.setState({
      data: data.reverse(),
      direction: direction === "ascending" ? "descending" : "ascending"
    });
  };

  render() {
    const { column, data, direction } = this.state;
    return (
      <div className="SortedTable">
        <Table
          size={this.props.size ? this.props.size : "medium"}
          compact={this.props.compact ? this.props.compact : false}
          sortable
          celled
          basic={this.props.basic ? this.props.basic : false}
          fixed
          selectable={this.props.handleRowSelect ? true : false}
        >
          <Table.Header>
            <Table.Row>
              {this.props.data[0] && this.props.fields &&
                Object.keys(data[0]).map(
                  key =>
                    Object.keys(this.props.fields).includes(key) && (
                      <Table.HeaderCell
                        key={key}
                        sorted={column === key ? direction : null}
                        onClick={this.handleSort(key)}
                      >
                        {
                          Object.entries(this.props.fields).find(
                            ([k, v]) => k === key
                          )[1]
                        }
                      </Table.HeaderCell>
                    )
                )}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map(d => (
              <Table.Row
                key={d.id}
                className={
                  this.props.selectedItem || this.props.selectedItems[0]
                    ? this.props.selectedItem.id === d.id ||
                      this.props.selectedItems.find(i => i.id === d.id)
                      ? "selected"
                      : "notSelected"
                    : "notSelected"
                }
                onClick={
                  this.props.handleRowSelect
                    ? (e) => this.props.handleRowSelect(e, d, data)
                    : null
                }
              >
                {this.props.fields &&
                  Object.keys(d).map(
                    (key, i) =>
                      Object.keys(this.props.fields).includes(key) && (
                        <Table.Cell key={`${d.id}__${d[key]}__${i}`}>
                          {Object.keys(this.props.transforms).includes(key)
                            ? Object.entries(this.props.transforms).find(
                                ([k, v]) => k === key
                              )[1](d[key])
                            : d[key]}
                        </Table.Cell>
                      )
                  )}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}
