import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
	menuItems: {},
	components: {
		data: [],
		isLoading: false,
		isLoaded: false,
		errors: {}
	}
};

const setMenuItems = (state, action) => {
	return updateObject(state, {
		menuItems: {
			[action.route]: action.menuItems
		}
	});
};

const startFetchUIComponents = (state, action) => {
	return updateObject(state, {
		components: {
			...state.components,
			isLoading: true,
			isLoaded: false,
			errors: {}
		}
	});
};
const setUIComponents = (state, action) => {
	return updateObject(state, {
		components: {
			...state.components,
			data: action.components,
			isLoading: false,
			isLoaded: true,
			errors: {}
		}
	});
};
const fetchUIComponentsFailure = (state, action) => {
	return updateObject(state, {
		components: {
			...state.components,
			isLoading: false,
			isLoaded: false,
			errors: action.errors
		}
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_MENU_ITEMS:
			return setMenuItems(state, action);
		case actionTypes.START_FETCH_UI_COMPONENTS:
			return startFetchUIComponents(state, action);
		case actionTypes.SET_UI_COMPONENTS:
			return setUIComponents(state, action);
		case actionTypes.FETCH_UI_COMPONENTS_FAILURE:
			return fetchUIComponentsFailure(state, action);
		default:
			return state;
	}
};

export default reducer;
