import * as actionTypes from "../actions/actionTypes";
import axios from "../../axios-portal";
import {getRandomInt} from "../utility";

export const addMessage = (message, isItem) => {
  return {
    type: actionTypes.ADD_MESSAGE,
    message: message,
    isItem: isItem
  };
};

export const removeMessage = (message, timeToWait, isItem) => {
  return {
    type: actionTypes.REMOVE_MESSAGE,
    message: message,
    timeToWait: timeToWait,
    isItem: isItem
  };
};

// Master Templates

export const startMasterChecklistTemplatesFetch = () => {
  return {
    type: actionTypes.START_MASTER_CHECKLIST_TEMPLATES_FETCH
  };
};

export const setMasterChecklistTemplates = masterChecklistTemplates => {
  return {
    type: actionTypes.SET_MASTER_CHECKLIST_TEMPLATES,
    masterChecklistTemplates: masterChecklistTemplates
  };
};

export const updateMasterChecklistTemplateSuccess = masterChecklistTemplates => {
  return {
    type: actionTypes.UPDATE_MASTER_CHECKLIST_TEMPLATE_SUCCESS,
    masterChecklistTemplates: masterChecklistTemplates
  };
};

export const masterChecklistTemplatesFailure = errors => {
  return {
    type: actionTypes.MASTER_CHECKLIST_TEMPLATES_FAILURE,
    errors: errors
  };
};

export const fetchMasterChecklistTemplates = () => {
  return dispatch => {
    dispatch(startMasterChecklistTemplatesFetch());
    axios
      .get("master-checklist-templates.json")
      .then(response => {
        return dispatch(setMasterChecklistTemplates(response.data));
      })
      .catch(err => {
        return dispatch(masterChecklistTemplatesFailure(err));
      });
  };
};

export const updateMasterChecklistTemplate = (
  websiteBuild,
  websiteDeployment
) => {
  return dispatch => {
    let body = JSON.stringify({
      website_build: websiteBuild,
      website_deployment: websiteDeployment
    });
    let message = "Master Templates Updated!";
    axios
      .put(`master-checklist-templates/${1}/`, body)
      .then(response => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message)), 2500);
        return dispatch(updateMasterChecklistTemplateSuccess(response.data));
      })
      .catch(err => {
        dispatch(masterChecklistTemplatesFailure(err.response.data));
      });
  };
};

// Checklist Templates

export const startChecklistTemplatesFetch = () => {
  return {
    type: actionTypes.START_CHECKLIST_TEMPLATES_FETCH
  };
};

export const setChecklistTemplates = (checklistTemplates, id, currentIndex) => {
  return {
    type: actionTypes.SET_CHECKLIST_TEMPLATES,
    checklistTemplates: checklistTemplates,
    id: id,
    currentIndex: currentIndex
  };
};

export const startAddingChecklistTemplate = () => {
  return {
    type: actionTypes.START_ADDING_CHECKLIST_TEMPLATE
  };
};

export const addChecklistTemplateSuccess = checklistTemplate => {
  return {
    type: actionTypes.ADD_CHECKLIST_TEMPLATE_SUCCESS,
    checklistTemplate: checklistTemplate
  };
};

export const updateChecklistTemplateSuccess = (checklistTemplate, id) => {
  return {
    type: actionTypes.UPDATE_CHECKLIST_TEMPLATE_SUCCESS,
    checklistTemplate: checklistTemplate,
    id: id
  };
};

export const deleteChecklistTemplateSuccess = id => {
  return {
    type: actionTypes.DELETE_CHECKLIST_TEMPLATE_SUCCESS,
    id: id
  };
};

export const checklistTemplatesFailure = errors => {
  return {
    type: actionTypes.CHECKLIST_TEMPLATES_FAILURE,
    errors: errors
  };
};

export const fetchChecklistTemplates = id => {
  return async (dispatch, getState) => {
    dispatch(startChecklistTemplatesFetch());
    const findCurrentIndex = (id, array) => {
      if (id) {
        const extistingChecklistIndex = array.findIndex(x => x.id === id);
        if (extistingChecklistIndex > -1) {
          return extistingChecklistIndex;
        }
      } else {
        return null;
      }
    };
    const currentChecklistTemplates = await getState().checklists
      .checklistTemplates.templates;
    const currentIndex = await findCurrentIndex(id, currentChecklistTemplates);
    axios
      .get(`checklist-templates${id ? `/${id}` : ".json"}`)
      .then(response => {
        return dispatch(setChecklistTemplates(response.data, id, currentIndex));
      })
      .catch(err => {
        return dispatch(checklistTemplatesFailure(err));
      });
  };
};

export const addChecklistTemplate = data => {
  return async dispatch => {
    dispatch(startAddingChecklistTemplate());
    let body = JSON.stringify(data);
    let message = "Template Created!";
    await axios
      .post("checklist-templates.json", body)
      .then(response => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message)), 2500);
        return dispatch(addChecklistTemplateSuccess(response.data));
      })
      .catch(err => {
        dispatch(checklistTemplatesFailure(err));
      });
  };
};

export const updateChecklistTemplate = (id, data) => {
  return dispatch => {
    let body = JSON.stringify(data);
    let message = "Template Updated!";
    axios
      .put(`checklist-templates/${id}/`, body)
      .then(response => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message)), 2500);
        return dispatch(updateChecklistTemplateSuccess(response.data));
      })
      .catch(err => {
        dispatch(checklistTemplatesFailure(err));
      });
  };
};

export const deleteChecklistTemplate = id => {
  return (dispatch, getState) => {
    let message = "Template Deleted.";

    return axios
      .delete(`checklist-templates/${id}/`)
      .then(response => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message)), 2500);
        return dispatch(deleteChecklistTemplateSuccess(id));
      })
      .catch(err => {
        dispatch(checklistTemplatesFailure(err));
      });
  };
};

// Checklists

export const startChecklistsFetch = () => {
  return {
    type: actionTypes.START_CHECKLISTS_FETCH
  };
};

export const setChecklists = (checklists, id, currentIndex) => {
  return {
    type: actionTypes.SET_CHECKLISTS,
    checklists: checklists,
    id: id,
    currentIndex: currentIndex
  };
};

export const startAddingChecklist = () => {
  return {
    type: actionTypes.START_ADDING_CHECKLIST
  };
};

export const addChecklistSuccess = checklist => {
  return {
    type: actionTypes.ADD_CHECKLIST_SUCCESS,
    checklist: checklist
  };
};

export const updateChecklistSuccess = (checklist, id) => {
  return {
    type: actionTypes.UPDATE_CHECKLIST_SUCCESS,
    checklist: checklist,
    id: id
  };
};

export const deleteChecklistSuccess = id => {
  return {
    type: actionTypes.DELETE_CHECKLIST_SUCCESS,
    id: id
  };
};

export const checklistsFailure = errors => {
  return {
    type: actionTypes.CHECKLISTS_FAILURE,
    errors: errors
  };
};

export const fetchChecklists = id => {
  return async (dispatch, getState) => {
    dispatch(startChecklistsFetch());
    const findCurrentIndex = (id, array) => {
      if (id) {
        const extistingChecklistIndex = array.findIndex(x => x.id === id);
        if (extistingChecklistIndex > -1) {
          return extistingChecklistIndex;
        }
      } else {
        return null;
      }
    };
    const currentChecklists = await getState().checklists.checklists.lists;
    const currentIndex = await findCurrentIndex(id, currentChecklists);
    axios
      .get(`checklists${id ? `/${id}` : ".json"}`)
      .then(response => {
        return dispatch(setChecklists(response.data, id, currentIndex));
      })
      .catch(err => {
        return dispatch(checklistsFailure(err));
      });
  };
};

export const addChecklist = name => {
  return dispatch => {
    dispatch(startAddingChecklist());
    let body = JSON.stringify({
      name: name
    });
    let message = "Template Created!";
    axios
      .post("checklists.json", body)
      .then(response => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message)), 2500);
        return dispatch(addChecklistSuccess(response.data));
      })
      .catch(err => {
        dispatch(checklistsFailure(err.response.data));
      });
  };
};

export const updateChecklist = (id, name, archived) => {
  return dispatch => {
    let body = JSON.stringify({
      name: name,
      archived: archived
    });
    let message = "Template Updated!";
    axios
      .put(`checklists/${id}/`, body)
      .then(response => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message)), 2500);
        return dispatch(updateChecklistSuccess(response.data));
      })
      .catch(err => {
        dispatch(checklistsFailure(err.response.data));
      });
  };
};

export const deleteChecklist = id => {
  return (dispatch, getState) => {
    let message = "Template Deleted.";

    return axios
      .delete(`checklists/${id}/`)
      .then(response => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message)), 2500);
        return dispatch(deleteChecklistSuccess(id));
      })
      .catch(err => {
        dispatch(checklistsFailure(err));
      });
  };
};

// Checklist Items

export const moveChecklistItemSuccess = (checklistID, updatedChecklistItems) => {
  return {
    type: actionTypes.MOVE_CHECKLIST_ITEM_SUCCESS,
    checklistID: checklistID,
    updatedChecklistItems: updatedChecklistItems,
  }
}

export const initialAddChecklistItem = (item, checklistID, itemIndex) => {
  return {
    type: actionTypes.INITIAL_ADD_CHECKLIST_ITEM,
    item: item,
    checklistID: checklistID,
    itemIndex: itemIndex,
  };
};

export const addChecklistItemSuccess = (updatedItems, checklistID) => {
  return {
    type: actionTypes.ADD_CHECKLIST_ITEM_SUCCESS,
    updatedItems: updatedItems,
    checklistID: checklistID
  };
};

export const updateChecklistItemSuccess = (updatedItems, checklistID) => {
  return {
    type: actionTypes.UPDATE_CHECKLIST_ITEM_SUCCESS,
    updatedItems: updatedItems,
    checklistID: checklistID
  };
};

export const deleteChecklistItemSuccess = (updatedItems, checklistID) => {
  return {
    type: actionTypes.DELETE_CHECKLIST_ITEM_SUCCESS,
    updatedItems: updatedItems,
    checklistID: checklistID
  };
};

export const checklistItemFailure = errors => {
  return {
    type: actionTypes.CHECKLIST_ITEM_FAILURE,
    errors: errors
  };
};


export const addChecklistItem = (formData, checklistID, itemIndex) => {
  return dispatch => {
    const item = {
      tempID: getRandomInt(100000),
      name: formData.name,
      order: formData.order,
      description: formData.description,
      department: formData.department,
      due: formData.dueDate,
      completed: formData.completed,
      checklist: checklistID,
      parent: formData.parent,
      assignee: formData.assignee
    };

    dispatch(initialAddChecklistItem(item, checklistID, itemIndex))

    let body = JSON.stringify({
      name: formData.name,
      order: formData.order,
      description: formData.description,
      department: formData.department,
      due: formData.dueDate,
      completed: formData.completed,
      checklist: checklistID,
      parent: formData.parent,
      assignee: formData.assignee
    });
    let message = "Item Added!";
    axios
      .post("checklist-items.json", body)
      .then(response => {
        dispatch(addMessage(message, true));
        setTimeout(() => dispatch(removeMessage(message, null, true)), 1500);
        return dispatch(addChecklistItemSuccess(response.data, checklistID));
      })
      .catch(err => {
        dispatch(checklistItemFailure(err.response.data));
      });
  };
};

export const updateChecklistItem = (formData, id, checklistID) => {
  return dispatch => {
    let body = JSON.stringify({
      name: formData.name,
      order: formData.order,
      description: formData.description,
      department: formData.department,
      due: formData.dueDate,
      completed: formData.completed,
      checklist: checklistID,
      parent: formData.parent,
      assignee: formData.assignee && formData.assignee.id
      ? formData.assignee.id
      : formData.assignee
    });
    let message = "Item Updated!";
    axios
      .put(`checklist-items/${id}/`, body)
      .then(response => {
        dispatch(addMessage(message, true));
        setTimeout(() => dispatch(removeMessage(message, null, true)), 1000);
        return dispatch(updateChecklistItemSuccess(response.data, checklistID));
      })
      .catch(err => {
        dispatch(checklistItemFailure(err.response.data));
      });
  };
};

export const deleteChecklistItem = (id, checklistID) => {
  return (dispatch, getState) => {
    let message = "Item Deleted.";

    return axios
      .delete(`checklist-items/${id}/`)
      .then(response => {
        dispatch(addMessage(message));
        setTimeout(() => dispatch(removeMessage(message, null, true)), 2500);
        return dispatch(deleteChecklistItemSuccess(response.data, checklistID));
      })
      .catch(err => {
        dispatch(checklistItemFailure(err.response.data));
      });
  };
};

// Checklist Template Items

export const initialMoveChecklistTemplateItem = (item, checklistTemplateID, newOrder) => {
  return {
    type: actionTypes.INITIAL_MOVE_CHECKLIST_TEMPLATE_ITEM,
    item: item,
    checklistTemplateID: checklistTemplateID,
    newOrder: newOrder,
  }
}

export const moveChecklistTemplateItemSuccess = (checklistTemplateID, updatedChecklistTemplateItems) => {
  return {
    type: actionTypes.MOVE_CHECKLIST_TEMPLATE_ITEM_SUCCESS,
    checklistTemplateID: checklistTemplateID,
    updatedChecklistTemplateItems: updatedChecklistTemplateItems,
  }
}

export const initialAddChecklistTemplateItem = (item, checklistTemplateID, itemIndex) => {
  return {
    type: actionTypes.INITIAL_ADD_CHECKLIST_TEMPLATE_ITEM,
    item: item,
    checklistTemplateID: checklistTemplateID,
    itemIndex: itemIndex,
  };
};

export const addChecklistTemplateItemSuccess = (
  updatedItems,
  checklistTemplateID
) => {
  return {
    type: actionTypes.ADD_CHECKLIST_TEMPLATE_ITEM_SUCCESS,
    updatedItems: updatedItems,
    checklistTemplateID: checklistTemplateID
  };
};

export const updateChecklistTemplateItemSuccess = (
  updatedItems,
  checklistTemplateID
) => {
  return {
    type: actionTypes.UPDATE_CHECKLIST_TEMPLATE_ITEM_SUCCESS,
    updatedItems: updatedItems,
    checklistTemplateID: checklistTemplateID
  };
};

export const deleteChecklistTemplateItemSuccess = (
  updatedItems,
  checklistTemplateID
) => {
  return {
    type: actionTypes.DELETE_CHECKLIST_TEMPLATE_ITEM_SUCCESS,
    updatedItems: updatedItems,
    checklistTemplateID: checklistTemplateID
  };
};

export const checklistTemplateItemFailure = errors => {
  return {
    type: actionTypes.CHECKLIST_TEMPLATE_ITEM_FAILURE,
    errors: errors
  };
};

export const moveChecklistTemplateItem = (item, checklistTemplateID, newOrder) => {
  return dispatch => {
    dispatch(initialMoveChecklistTemplateItem(item, checklistTemplateID, newOrder))

    axios
    .post(`checklist-template-items/${item.id}/move/`, {order: newOrder})
    .then(response => {
      return dispatch(moveChecklistTemplateItemSuccess(checklistTemplateID, response.data))
    })
    .catch(err => {
      dispatch(checklistTemplateItemFailure(err));
    })
  }
}

export const addChecklistTemplateItem = (formData, checklistTemplateID, itemIndex) => {
  return (dispatch, getState) => {
    const item = {
      tempID: getRandomInt(100000),
      name: formData.name,
      order: formData.order,
      description: formData.description,
      department: formData.department,
      checklist: checklistTemplateID,
      parent: formData.parent
    };

    dispatch(initialAddChecklistTemplateItem(item, checklistTemplateID, itemIndex))

    let body = JSON.stringify({
      name: formData.name,
      order: formData.order,
      description: formData.description,
      department: formData.department,
      checklist: checklistTemplateID,
      parent: formData.parent,
      days_out: formData.days_out,
      default_assignee: formData.default_assignee.id
    });
    let message = "Item Added!";
    axios
      .post("checklist-template-items.json", body)
      .then(response => {
        dispatch(addMessage(message, true));
        setTimeout(() => dispatch(removeMessage(message, null, true)), 1500);
        return dispatch(
          addChecklistTemplateItemSuccess(response.data, checklistTemplateID)
        );
      })
      .catch(err => {
        dispatch(checklistTemplateItemFailure(err.response.data));
      });
  };
};

export const updateChecklistTemplateItem = (
  formData,
  id,
  checklistTemplateID
) => {
  return dispatch => {
    let body = JSON.stringify({
      name: formData.name,
      order: formData.order,
      description: formData.description,
      department: formData.department,
      checklist: checklistTemplateID,
      parent: formData.parent,
      days_out: formData.days_out,
      default_assignee:
        formData.default_assignee && formData.default_assignee.id
          ? formData.default_assignee.id
          : formData.default_assignee
    });
    let message = "Item Updated!";
    axios
      .put(`checklist-template-items/${id}/`, body)
      .then(response => {
        dispatch(addMessage(message, true));
        setTimeout(() => dispatch(removeMessage(message, null, true)), 1500);
        return dispatch(
          updateChecklistTemplateItemSuccess(response.data, checklistTemplateID)
        );
      })
      .catch(err => {
        dispatch(checklistTemplateItemFailure(err));
      });
  };
};

export const deleteChecklistTemplateItem = (id, checklistTemplateID) => {
  return (dispatch, getState) => {
    let message = "Item Deleted.";

    axios
      .delete(`checklist-template-items/${id}/`)
      .then(response => {
        dispatch(addMessage(message, true));
        setTimeout(() => dispatch(removeMessage(message, null, true)), 1500);
        return dispatch(
          deleteChecklistTemplateItemSuccess(response.data, checklistTemplateID)
        );
      })
      .catch(err => {
        dispatch(checklistTemplateItemFailure(err));
      });
  };
};
