import React from 'react';
import "./EditorialRequirementsDetail.css";
import { List } from 'semantic-ui-react';

const EditorialRequirementsDetail = ({requirements}) => {
    return (
        <div className="EditorialRequirementsDetail">
            <List>
            {requirements.map(req => (
                <List.Item key={req.id}>
                <div className="EditorialRequirementsDetail--item">{req.requirement}</div>
                </List.Item>
            ))}
            </List>
        </div>
    );
};

export default EditorialRequirementsDetail;