import React, {Component} from "react";
import { Header, Icon, Loader } from "semantic-ui-react";
import "./WebsiteBuildList.css";
import WebsiteBuildCard from "./WebsiteBuildCard/WebsiteBuildCard";

class WebsiteBuildList extends Component {
  state = {
    listRef: null
  }
  listRef = React.createRef();

  componentDidMount() {
    this.setState({listRef: this.listRef.current})
  }

  render() {
    let currentListRef = this.listRef.current
    return (
      <div ref={this.listRef} className="WebsiteBuildList">
        {(this.props.loaded && !this.props.loading) && currentListRef ? (
          this.props.builds && this.props.builds[0] ? (
            this.props.builds
              .filter(build => build.is_active === !this.props.archives)
              .map(build => (
                <WebsiteBuildCard
                  key={build.id}
                  listRef={this.listRef.current}
                  build={build}
                  archives={this.props.archives}
                  selectForEdit={this.props.selectForEdit}
                  handleListSelect={this.props.handleListSelect}
                />
              ))
          ) : (
            <Header className="WebsiteBuildList--noContentHeader" as="h4">
              <Icon size="small" circular color="blue" name="exclamation" /> No
              active builds
            </Header>
          )
        ) : (
          <Loader active>Loading...</Loader>
        )}
      </div>
    );
  }
};

export default WebsiteBuildList;
