import * as actionTypes from '../actions/actionTypes';
import axios from '../../axios-portal';

// Messages
export const addMessage = (message) => {
	return {
		type: actionTypes.ADD_MESSAGE,
		message: message
	};
};

export const removeMessage = (message, timeToWait) => {
	return {
		type: actionTypes.REMOVE_MESSAGE,
		message: message,
		timeToWait: timeToWait
	};
};

// Slide Deck Templates
export const startSlideDeckTemplateFetch = () => {
	return {
		type: actionTypes.START_SLIDE_DECK_TEMPLATE_FETCH
	};
};

export const setSlideDeckTemplates = (id, slideDeckTemplates) => {
	return {
		type: actionTypes.SET_SLIDE_DECK_TEMPLATES,
		id: id,
		slideDeckTemplates: slideDeckTemplates
	};
};

export const startAddSlideDeckTemplate = () => {
	return {
		type: actionTypes.START_ADD_SLIDE_DECK_TEMPLATE
	};
};

export const addSlideDeckTemplateSuccess = (slideDeckTemplate) => {
	return {
		type: actionTypes.ADD_SLIDE_DECK_TEMPLATE_SUCCESS,
		slideDeckTemplate: slideDeckTemplate
	};
};

export const updateOrganizationSlideDeckTemplate = (orgIndex, slideDeckId) => {
	return {
		type: actionTypes.UPDATE_ORGANIZATION_SLIDE_DECK_TEMPLATE,
		orgIndex: orgIndex,
		slideDeckId: slideDeckId,
	}
}

export const startUpdateSlideDeckTemplate = (index) => {
	return {
		type: actionTypes.START_UPDATE_SLIDE_DECK_TEMPLATE,
		index: index,
	}
}

export const updateSlideDeckTemplateSuccess = (id, index, slideDeckTemplate) => {
	return {
		type: actionTypes.UPDATE_SLIDE_DECK_TEMPLATE_SUCCESS,
		id: id,
		index: index,
		slideDeckTemplate: slideDeckTemplate
	};
};

export const deleteSlideDeckTemplateSuccess = (id, index) => {
	return {
		type: actionTypes.DELETE_SLIDE_DECK_TEMPLATE_SUCCESS,
		id: id,
		index: index
	};
};

export const slideDeckTemplateError = (errors) => {
	return {
		type: actionTypes.SLIDE_DECK_TEMPLATE_ERROR,
		errors: errors
	};
};

export const fetchSlideDeckTemplates = (id) => {
	return async (dispatch, getState) => {
		let isLoading = await getState().presentations.slideDeckTemplates.isLoading;
		dispatch(startSlideDeckTemplateFetch());
		!isLoading &&
			axios
				.get(`slide-deck-templates${id ? `/${id}/` : '.json'}`)
				.then((response) => {
					return dispatch(setSlideDeckTemplates(id, response.data));
				})
				.catch((err) => {
					dispatch(slideDeckTemplateError(err));
				});
	};
};

export const addSlideDeckTemplate = (data, message) => {
	return (dispatch, getState) => {
		dispatch(startAddSlideDeckTemplate());
		const relatedOrganizationIndex = getState().organizations.organizations.findIndex(org => org.id === data.object_id)
		let body = JSON.stringify(data);
		axios
			.post(`slide-deck-templates.json`, body)
			.then((response) => {
				if (message) {
					dispatch(addMessage(message));
					setTimeout(() => dispatch(removeMessage(message)), 2500);
				}
				relatedOrganizationIndex && dispatch(updateOrganizationSlideDeckTemplate(relatedOrganizationIndex, response.data.id))
				return dispatch(addSlideDeckTemplateSuccess(response.data));
			})
			.catch((err) => {
				dispatch(slideDeckTemplateError(err));
			});
	};
};

export const updateSlideDeckTemplate = (id, data, message) => {
	return async (dispatch, getState) => {
		const index = await getState().presentations.slideDeckTemplates.data.findIndex(
			(slideDeckTemplate) => slideDeckTemplate.id === id
		);
		dispatch(startUpdateSlideDeckTemplate(index))
		let body = JSON.stringify(data);
		axios
			.put(`slide-deck-templates/${id}/`, body)
			.then((response) => {
				if (message) {
					dispatch(addMessage(message));
					setTimeout(() => dispatch(removeMessage(message)), 2500);
				}
				return dispatch(updateSlideDeckTemplateSuccess(id, index, response.data));
			})
			.catch((err) => {
				dispatch(slideDeckTemplateError(err));
			});
	};
};

export const deleteSlideDeckTemplate = (id, message) => {
	return (dispatch, getState) => {
		const index = getState().presentations.slideDeckTemplates.data.findIndex(
			(slideDeckTemplate) => slideDeckTemplate.id === id
		);
		return axios
			.delete(`slide-deck-templates/${id}/`)
			.then((response) => {
				if (message) {
					dispatch(addMessage(message));
					setTimeout(() => dispatch(removeMessage(message)), 2500);
				}
				return dispatch(deleteSlideDeckTemplateSuccess(id, index));
			})
			.catch((err) => {
				dispatch(slideDeckTemplateError(err));
			});
	};
};

// Slide Templates
export const startSlideTemplateFetch = (slideDeckIndex) => {
	return {
		type: actionTypes.START_SLIDE_TEMPLATE_FETCH,
		slideDeckIndex: slideDeckIndex
	};
};

export const setSlideTemplates = (id, slide_templates, slideDeckIndex) => {
	return {
		type: actionTypes.SET_SLIDE_TEMPLATES,
		id: id,
		slide_templates: slide_templates,
		slideDeckIndex: slideDeckIndex
	};
};

export const startAddSlideTemplate = (slideDeckIndex) => {
	return {
		type: actionTypes.START_ADD_SLIDE_TEMPLATE,
		slideDeckIndex: slideDeckIndex
	};
};

export const addSlideTemplateSuccess = (slideDeckIndex, slide_templates) => {
	return {
		type: actionTypes.ADD_SLIDE_TEMPLATE_SUCCESS,
		slideDeckIndex: slideDeckIndex,
		slide_templates: slide_templates
	};
};

export const updateSlideTemplateSuccess = (id, index, slideDeckIndex, slide_templates) => {
	return {
		type: actionTypes.UPDATE_SLIDE_TEMPLATE_SUCCESS,
		id: id,
		index: index,
		slideDeckIndex: slideDeckIndex,
		slide_templates: slide_templates
	};
};

export const deleteSlideTemplateSuccess = (id, slideDeckIndex) => {
	return {
		type: actionTypes.DELETE_SLIDE_TEMPLATE_SUCCESS,
		id: id,
		slideDeckIndex: slideDeckIndex
	};
};

export const slideTemplateError = (slideDeckIndex, errors) => {
	return {
		type: actionTypes.SLIDE_TEMPLATE_ERROR,
		slideDeckIndex: slideDeckIndex,
		errors: errors
	};
};

export const fetchSlideTemplates = (slideDeckId, id) => {
	return async (dispatch, getState) => {
		let isLoading = await getState().presentations.slide_templates.isLoading;
		const slideDeckIndex = getState().presentations.slideDeckTemplates.data.findIndex(
			(slideDeckTemplate) => slideDeckTemplate.id === slideDeckId
		);
		dispatch(startSlideTemplateFetch(slideDeckIndex));
		!isLoading &&
			axios
				.get(`slide-templates${id ? `/${id}/` : `/?slideDeckTemplate=${slideDeckId}`}`)
				.then((response) => {
					return dispatch(setSlideTemplates(id, slideDeckIndex, response.data));
				})
				.catch((err) => {
					dispatch(slideTemplateError(err));
				});
	};
};

export const addSlideTemplate = (slideDeckId, data, message) => {
	return (dispatch, getState) => {
		const slideDeckIndex = getState().presentations.slideDeckTemplates.data.findIndex(
			(slideDeckTemplate) => slideDeckTemplate.id === slideDeckId
		);
		let body = JSON.stringify(data);
		axios
			.post(`slide-templates.json`, body)
			.then((response) => {
				if (message) {
					dispatch(addMessage(message));
					setTimeout(() => dispatch(removeMessage(message)), 2500);
				}
				return dispatch(addSlideTemplateSuccess(slideDeckIndex, response.data));
			})
			.catch((err) => {
				dispatch(slideTemplateError(err));
			});
	};
};

export const updateSlideTemplate = (id, slideDeckId, data, message) => {
	return (dispatch, getState) => {
		const index = getState().presentations.slide_templates.data.findIndex(
			(slideTemplate) => slideTemplate.id === id
		);
		const slideDeckIndex = getState().presentations.slideDeckTemplates.data.findIndex(
			(slideDeckTemplate) => slideDeckTemplate.id === slideDeckId
		);
		let body = JSON.stringify(data);
		axios
			.put(`slide-templates/${id}/`, body)
			.then((response) => {
				if (message) {
					dispatch(addMessage(message));
					setTimeout(() => dispatch(removeMessage(message)), 2500);
				}
				return dispatch(updateSlideTemplateSuccess(id, index, slideDeckIndex, response.data));
			})
			.catch((err) => {
				dispatch(slideTemplateError(err));
			});
	};
};

export const deleteSlideTemplate = (id, slideDeckId, message) => {
	return (dispatch, getState) => {
		const slideDeckIndex = getState().presentations.slideDeckTemplates.data.findIndex(
			(slideDeckTemplate) => slideDeckTemplate.id === slideDeckId
		);
		return axios
			.delete(`slide-templates/${id}/`)
			.then((response) => {
				if (message) {
					dispatch(addMessage(message));
					setTimeout(() => dispatch(removeMessage(message)), 2500);
				}
				return dispatch(deleteSlideTemplateSuccess(id, slideDeckIndex));
			})
			.catch((err) => {
				dispatch(slideTemplateError(err));
			});
	};
};

// Slide Decks
export const startSlideDeckFetch = () => {
	return {
		type: actionTypes.START_SLIDE_DECK_FETCH
	};
};

export const setSlideDecks = (id, slideDecks) => {
	return {
		type: actionTypes.SET_SLIDE_DECKS,
		id: id,
		slideDecks: slideDecks
	};
};

export const slideDeckError = (errors) => {
	return {
		type: actionTypes.SLIDE_DECK_ERROR,
		errors: errors
	};
};

export const fetchSlideDecks = (id) => {
	return async (dispatch, getState) => {
		let isLoading = await getState().presentations.slideDecks.isLoading;
		dispatch(startSlideDeckFetch());
		!isLoading &&
			axios
				.get(`slide-decks${id ? `/${id}/` : '.json'}`)
				.then((response) => {
					return dispatch(setSlideDecks(id, response.data));
				})
				.catch((err) => {
					dispatch(slideDeckError(err));
				});
	};
};

// Slides
export const startSlideFetch = (slideDeckIndex) => {
	return {
		type: actionTypes.START_SLIDE_FETCH,
		slideDeckIndex: slideDeckIndex
	};
};

export const setSlides = (id, slides, slideDeckIndex) => {
	return {
		type: actionTypes.SET_SLIDES,
		id: id,
		slides: slides,
		slideDeckIndex: slideDeckIndex
	};
};

export const slideError = (errors, slideDeckIndex) => {
	return {
		type: actionTypes.SLIDE_ERROR,
		slideDeckIndex: slideDeckIndex,
		errors: errors
	};
};

export const fetchSlides = (id, slideDeckId) => {
	return async (dispatch, getState) => {
		let isLoading = await getState().presentations.slides.isLoading;
		const slideDeckIndex = getState().presentations.slideDeckTemplates.data.findIndex(
			(slideDeckTemplate) => slideDeckTemplate.id === slideDeckId
		);
		dispatch(startSlideFetch(slideDeckIndex));
		!isLoading &&
			axios
				.get(`slides${id ? `/${id}/` : '.json'}`)
				.then((response) => {
					return dispatch(setSlides(id, slideDeckIndex, response.data));
				})
				.catch((err) => {
					dispatch(slideError(err, slideDeckIndex));
				});
	};
};
